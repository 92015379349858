import { BASE_URL } from "constants/Endpoints";
import axios from "../../core/axios";
import { API_V1_SMS } from "constants/Endpoints";

export const TeleGroupServiceAPI = {
    ///phone/group/bulk/consent/status
    getConsentStatusForRecipient : async({token,payload}) => {
        return await axios.post(`${BASE_URL}/sms/phone/group/consent/status`,JSON.stringify(payload),{
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
              }
        });
    },

    uploadSheetRecipients : async({payload,token}) => {
        return await axios.post(`${BASE_URL}${API_V1_SMS}/group/recipient/sheet/upload`,JSON.stringify(payload),{
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        });
    },

    getConsentStatusForUrl : async({token,payload}) => {
        return await axios.post(`${BASE_URL}/sms/phone/group/sheet/bulk/consent/status`,JSON.stringify(payload),{
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type':'application/json'
            }
        });
    },

    getConsentStatusForFile : async({token,formData}) => {
        return await axios.post(`${BASE_URL}/sms/phone/group/bulk/consent/status`,formData,{
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type':'multipart/form-data'
            }
        });
    },
    
    addRecipientToGroup : async({token,payload}) => {
        return await axios.post(`${BASE_URL}${API_V1_SMS}/group/recipient/add`,JSON.stringify(payload),{
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
              }
        });
    },

    removeRecipientFromGroup : async({token,payload}) => {
        return await axios.post(`${BASE_URL}${API_V1_SMS}/group/recipient/remove`,JSON.stringify(payload),{
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        });
    },

    uploadGroupRecipients : async({formData,token}) => {
        return await axios.post(`${BASE_URL}${API_V1_SMS}/group/recipient/upload`,formData,{
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        });
    },
    broadcastAvailable : async({token,payload}) => {
        return await axios.post(`${BASE_URL}${API_V1_SMS}/group`,JSON.stringify(payload),{
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type':'application/json'
              }
        });
    }

}