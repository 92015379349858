import CreateUserForm from "components/User/CreateUserForm";
import { memo, useState } from "react";
import AssignPhoneV2 from "./AssignPhoneV2";

const UserPhoneAssignFlowWrapper = ({onCreateUserHandler,setAction}) => {

    const [showPhoneMapping,setShowPhoneMapping] = useState(false);

    return(
        <>
          {!showPhoneMapping && <CreateUserForm onCreate={(res) => {
            onCreateUserHandler(res);
            setShowPhoneMapping(true);
          }} />}    
          {showPhoneMapping && <AssignPhoneV2 onClose={ () => setAction("none")}/>}    
        </>
    )
}
export default memo(UserPhoneAssignFlowWrapper);