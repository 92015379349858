import { BASE_URL } from "constants/Endpoints";
import fetch from "core/fetch";

export const createPhone = async(phone) => {
    const payload = {
        ...phone, token: undefined
    }
    const response = await fetch(`${BASE_URL}/phone`,{
        method : 'POST',
        body : JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + phone.token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const deletePhone = async({phoneId,token}) => {
   
    const response = await fetch(`${BASE_URL}/phone/${phoneId}`,{
        method : 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
export const updatePhone = async(phone) => {
    // console.log(phone)
    const payload = {
        ...phone, token: undefined
    }
    const response = await fetch(`${BASE_URL}/phone`,{
        method : 'PATCH',
        body : JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + phone.token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data;
}
export const getPhoneList = async(phone) => {
    let payload = {
        ...phone,
        token : undefined
    }
    const response = await fetch(`${BASE_URL}/phone/company`,{
        method : 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + phone.token,
          },
        body : JSON.stringify(payload)
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data;
} 

export const getUserNotAssignedPhone = async({token,phone}) => {
    // console.log()
    const response = await fetch(`${BASE_URL}/phone/user/unassigned`,{
        method : 'POST',
        body : JSON.stringify({"phone":phone}),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
export const assignPhoneToUser = async(phoneUserData) => {
    const payload = {
        ...phoneUserData, token: undefined
    }
    const response = await fetch(`${BASE_URL}/phone/user/assign`,{
        method : 'POST',
        body : JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + phoneUserData.token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const removePhoneFromUser = async(phoneUserData) => {
    const payload = {
        ...phoneUserData, token: undefined
    }
    const response = await fetch(`${BASE_URL}/phone/user/remove`,{
        method : 'POST',
        body : JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + phoneUserData.token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
export const getTotalPhoneCount = async(usr) => {
    let payload = {
        ...usr,
        token : undefined
    }
    const response = await fetch(`${BASE_URL}/phone/count`,{
        method : 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + usr.token,
          },
        body : JSON.stringify(payload)
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
export const getUserListOfAssignedPhone = async({phoneId,token}) => {
    const response = await fetch(`${BASE_URL}/phone/${phoneId}/users/assigned`,{
        method : 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}