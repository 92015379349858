import { Mic } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { memo } from "react";
const RecordUsingWave = ({audioMessage,setAudioMessage,sx={}}) => {

    return(
        <>
            <Tooltip title="Hold down to record">
                <Mic
                    onClick={() => setAudioMessage(true)}
                    sx={{ cursor: "pointer", marginLeft: "6px",...sx }}
                />
            </Tooltip>        
        </>
    )
};
export default memo(RecordUsingWave);