import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Avatar, Divider, Icon, Menu, Typography } from "@mui/material";
import NotificationItem from "lib/layouts/Items/NotificationItem";
import { memo, useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { userAction } from "store/user-slice";
import { Archive, Settings, Tour } from "@mui/icons-material";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Preferences from "components/Settings/Preferences";
import { chatAction } from "store/chat-slice";
import { chatVariablesAction } from "store/chat-variables-slice";
import { IChatAction } from "store/IChat/Ichat-slice";
import { useCallback } from "react";
import { disconnect } from "util/WebSocketUtility";
import ImportChatWrapper from "components/ImportChat/ImportChatWrapper";
import AppModal from "ui/AppModal";
import ImportHistory from "components/ImportChat/ImportHistory";
import HistoryIcon from '@mui/icons-material/History';
import { ichatVariablesAction } from "store/IChat/Ichat-variables-slice";
import { useDispatch, useSelector } from "react-redux";
import { ACTIONS } from "constants/ChatConstants";
const UserSettings = ({onEventPerform}) => {

  const stompClient = useSelector(state => state.app.stompClient);

  const [openMenu, setOpenMenu] = useState(false);
  const currentUser = useSelector(state => state.userDetails.details);
  const token = useSelector((state) => state.user.token);
  const role = useSelector(state => state.user.role);
  
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const [action, setAction] = useState("");

  const appDispatch = useDispatch();
  const navigate = useNavigate();

  const onLogoutHandler = useCallback(() => {
    localStorage.removeItem("blip_user_data");
    appDispatch(userAction.logout());
    stompClient?.disconnect();
    disconnect();
    appDispatch(userAction.logout());
    appDispatch(chatAction.reset());
    appDispatch(chatVariablesAction.reset());
    appDispatch(IChatAction.reset());
    navigate("/login",{ replace: true });;    

  },[stompClient,appDispatch]);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem style={{pointerEvents:'none'}} icon={<AccountCircleIcon/>} title={currentUser?.fullName}/>
      <Typography fontSize={'12px'} fontWeight={'medium'} fontStyle={'italic'} sx={{paddingLeft:'12px',paddingRight:'12px'}}>{currentUser?.email}</Typography>
      {currentUser?.company && <Typography fontSize={'12px'} fontStyle={'italic'} fontWeight={'medium'} sx={{paddingLeft:'12px',paddingRight:'12px'}}>{"Company: "}{currentUser?.company?.companyName}</Typography>}
      <Divider variant="middle" />      
        <NotificationItem
          onClick={() => setAction("CHAT_IMPORT")}
          icon={
            <Icon>
              <Archive />
            </Icon>
          }
          title="Import chat"
        />
        <NotificationItem
          onClick={() => setAction("CHAT_IMPORT_HISTORY")}
          icon={
            <Icon>
              <HistoryIcon />
            </Icon>
          }
          title="Import history"
        /> 
      <NotificationItem
        onClick={() => setAction("NOTIFICATIONS_SETTINGS")}
        icon={
          <Icon>
            <Settings />
          </Icon>
        }
        title="Preferences"
      /> 
      {(role === 'SUPER_ADMIN' || role === 'ADMIN') &&  
      <NotificationItem
        onClick={() => navigate("/admin/dashboard")}
        icon={
          <Icon>
            <AdminPanelSettingsIcon />
          </Icon>
        }
        title="Admin panel"
      />}
      <NotificationItem title="Take a tour" icon={
        <Icon onClick={() => appDispatch(ichatVariablesAction.updateTour(true))}>
          <Tour/>
        </Icon>
      }/>
      <NotificationItem
        onClick={onLogoutHandler}
        icon={
          <Icon>
            <LogoutIcon />
          </Icon>
        }
        title="Log out"
      />      
    </Menu>
  );

  return (
    <>
      <Avatar
        id={"profile__icon"}
        src={currentUser?.profilePic}
        sx={{ marginLeft:'8px' }}
        onClick={handleOpenMenu}
        alt={currentUser?.fullName}
        shadow="md"
        className="navbar-icon user-settings-icon"
      />
      {renderMenu()}
      {action === 'CHAT_IMPORT' && <ImportChatWrapper onImported={() => onEventPerform(ACTIONS.RELOAD_RECIPIENT)} onClose={() => {setAction("");handleCloseMenu();}}/>}
      {action === 'NOTIFICATIONS_SETTINGS' && <Preferences onClose={() => setAction("")} />}
      {
        action === 'CHAT_IMPORT_HISTORY' && (
          <AppModal
            customStyle={{width:'100%',height:'100%',overflowY:'scroll',border:'unset'}} 
            rootStyle = {
                {
                  "&.MuiModal-root":{
                    width:'60%',
                    left: '40%'
                  }
                }
            }
            onModalClose={() => setAction("")} 
            closeOnClick={true}
            heading={'Import history'}
          >
            <ImportHistory onUndo={() => onEventPerform(ACTIONS.RELOAD_RECIPIENT)}/>
          </AppModal>
        )
      }
    </>
  );
};
export default memo(UserSettings);
