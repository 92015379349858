import MDBox from "lib/components/MDBox";
import AddUser from "./AddUser";
import { Typography } from "@mui/material";

const CreateUsers = ({setUsers,users}) => {
    return(
        <MDBox display="flex" flexDirection="column">
            <Typography>Add Users</Typography>
            <Typography fontSize={"12px"} fontWeight={"500"}>{"Once your account gets activated, users will be sent an email with login instructions."}</Typography>
            <AddUser users={users} setUsers={setUsers}/>
        </MDBox>
    )
}
export default CreateUsers;