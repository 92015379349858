import { createCampaignMsg } from "api/campaign";
import AppConstants from "constants/AppConstants";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const EditCampaignMessage = ({campaignId,message,onEdited}) => {

    const [editData, setEditData] = useState();
    const token = useSelector(state => state.user.token);
    /// Same logic as create for 
    const {sendRequest : createMsgFn, status, data, error} = useHttp(createCampaignMsg,false);

    const [editMsgValidation, setEditMsgValidation] = useState({
        title :'',
        message : '',
        percentage: '',
    })

    const onEditSubmit = () => {

      if(editData.title.trim() === 0 || editData.title === ''){
        setEditMsgValidation(prev => {
          return{
            ...prev,
            title : "Title can not be empty"
          }
        });
        return;
      }
      else if(editData.percentage === '' || !(AppConstants.TWO_DIGIT_REGEX).test(editData.percentage)){
        setEditMsgValidation(prev => {
          return{
            ...prev,
            percentage : "Enter a valid value"
          }
        });
        return;
      } 
      else if(editData.message.trim() === 0 || editData.message === ''){
        setEditMsgValidation(prev => {
          return{
            ...prev,
            message : "Message can not be empty"
          }
        });
        return;
      }    
      else{
        /// Everything fine continue with message creation.... 
        const msgPayload = {
          token,
          campaign_id : campaignId,
          payload : editData
        }   
        createMsgFn(msgPayload);
      }
  }
    useEffect(() => {
        setEditData(message);
    },[message]);

    useEffect(() => {
      if(status === 'completed'){
        if(data){
          onEdited(data);
        }
        else{
          toast.error("Something went wrong.");
        }
      }
    },[status, data, error])

    return (
      <>
        <MDBox component="form" role="form">
          <MDBox pt={4} pb={3} px={3}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Title"
                variant="standard"
                placeholder="Enter a unique title"
                fullWidth
                onChange={(e) => {
                  setEditData(prev => {
                    return {
                      ...prev,
                      title : e.target.value
                    }
                  })
                }}
                value={editData?.title}
              />
            </MDBox>
            <MDTypography
              variant="overline"
              fontWeight="medium"
              color="error"
            >
              {editMsgValidation.title}
            </MDTypography>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Goal amount"
                variant="standard"
                placeholder="Enter a particular amount"
                fullWidth
                onChange={(e) => {
                  setEditData(prev => {
                    return {
                      ...prev,
                      percentage : e.target.value
                    }
                  })
                }}
                value={editData?.percentage}
              />
            </MDBox>
            <MDTypography
              variant="overline"
              fontWeight="medium"
              color="error"
            >
              {editMsgValidation.percentage}
            </MDTypography>
            <MDBox mb={1}>
              <MDInput
                type="text"
                label="Message"
                variant="standard"
                fullWidth
                multiline
                onChange={(e) => {
                  setEditData(prev => {
                    return {
                      ...prev,
                      message : e.target.value
                    }
                  })
                }}                  
                placeholder="Enter a  custom message"
                value={editData?.message}
              />
            </MDBox>
            <MDTypography
              variant="overline"
              fontWeight="medium"
              color="error"
            >
              {editMsgValidation.message}
            </MDTypography>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={onEditSubmit}
              >
                Update
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </>
    );
}
export default memo(EditCampaignMessage);