import { Download } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { useState } from "react";

/** This is a general component that can be used to choose file from the users pc
 * @param
 * fileType : What should be file type
 * showTemplate : show download template
 * templateUrl : downloadble link for templateUrl
 * onFileSelected : when file successfully chosen
 * templateTooltipMsg : Message to show on download icon
 */
const ChooseFile = ({fileType, onFileSelected, showTemplate = false, templateUrl, templateTooltipMsg = ' Download template'}) => {
    
    const [file,setFile] = useState();
    const [fileErr, setFileErr] = useState('');

    const fileInputChange = (e) => {
        setFile(e.target.files[0]);
    }
    const onFileUploadClick = () => {
        if(file){
            onFileSelected(file);
        }
        else{
            setFileErr("Please select a valid file.")
        }
    }
    return(
        <>
            <MDBox display="flex" alignItems="center" justifyContent="center" flexDirection="row">
                <MDInput type="file" label="" onChange={fileInputChange} />
                {showTemplate && <><Tooltip title={templateTooltipMsg ? templateTooltipMsg : ''}>
                        <a style={{textDecoration:'none',marginLeft:'6px'}} href={templateUrl}>
                            <Download/>
                        </a>
                    </Tooltip></>
                }       
            </MDBox>
            <MDTypography variant="subtitle2" fontWeight="regular" color="error" mt={1}>
                    <span>{fileErr}</span>
            </MDTypography>  
            <MDBox style={{marginTop:'12px'}} display="flex" alignItems="center" justifyContent="center">
                {file && <MDButton onClick={onFileUploadClick}  size={'medium'} color='info' variant="outlined">
                    upload
                </MDButton>}
            </MDBox>            
        </>
    )
}   
export default ChooseFile;