import { BASE_URL } from "constants/Endpoints";
import fetch from "core/fetch";

export const createShortcut = async(shortcut) => {
    let payload = {
        ...shortcut,
        token : undefined
    }
    const response = await fetch(`${BASE_URL}/shortcuts`,{
        method : 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + shortcut.token,
          },
        body : JSON.stringify(payload)
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
} 
export const editShortcut = async(shortcut) => {
    let payload = {
        ...shortcut,
        token : undefined
    }
    const response = await fetch(`${BASE_URL}/shortcuts`,{
        method : 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + shortcut.token,
          },
        body : JSON.stringify(payload)
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
} 
export const deleteShortcut = async(shortcut) => {
    let payload = {
        ...shortcut,
        token : undefined
    }
    const response = await fetch(`${BASE_URL}/shortcuts/delete`,{
        method : 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + shortcut.token,
          },
        body : JSON.stringify(payload)
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
} 
export const getUserShortcut = async(shortcut) => {
    let payload = {
        ...shortcut,
        token : undefined
    }
    const response = await fetch(`${BASE_URL}/shortcuts/user`,{
        method : 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + shortcut.token,
          },
        body : JSON.stringify(payload)
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
export const getCompanyShortcuts = async(shortcut) => {
    let payload = {
        ...shortcut,
        token : undefined
    }
    const response = await fetch(`${BASE_URL}/shortcuts/company`,{
        method : 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + shortcut.token,
          },
        body : JSON.stringify(payload)
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}