import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VIEWS } from "constants/ChatConstants";
import { ConversationAPI } from "services/ConversationAPI";
import { messageAction } from "store/message-slice";

//Manage and send the chat messages....
const useChat = () => {
  const dispatch = useDispatch();
  const currentActivePhone = useSelector((state) => state.chat.current_active_phone);
  const currentActiveRecipient = useSelector(state => state.chat.current_active_recipient);
  const currentActiveView = useSelector(state => state.chat.current_active_view);
  const currentMsgView = "ALL";
  const rConversationState = useSelector((state) => state.message.conversation[currentActiveRecipient?.id]);
  const fetched = useSelector((state) => state.message.conversation[currentActiveRecipient?.id]?.fetched);
  const token = useSelector((state) => state.user.token);
  
  const loadMoreConversation = useCallback((customCurrentPage) => {
    ConversationAPI.getConversations({
      token,
      phone_id: currentActivePhone?.phoneId,
      recipient_id: currentActiveRecipient.id,
      pageNo: customCurrentPage,
      current_message_type: currentMsgView,
    })
      .then(res => {
        const fetchedConversations = res.data;
        if (fetchedConversations.currentPage === 0) {
          const conArr = fetchedConversations?.messages;
          // if(conArr && conArr.length > 0){
          //   conArr.reverse();
          // }
          dispatch(messageAction.setConversation({
            totalPages : fetchedConversations.totalPages,
            messages: conArr,
            recipientId: fetchedConversations.recipientId}));
        } else {
          const newCon = rConversationState['messages']?.concat(fetchedConversations?.messages);
          // newCon.reverse();
          dispatch(
            messageAction.setConversation({
              messages: newCon,
              recipientId: fetchedConversations.recipientId,
              totalPages : fetchedConversations?.totalPages
            })
          );
        }
      }).catch(e => {
        console.log(e)
      }) 
    },[currentActivePhone,currentActiveRecipient,currentMsgView,rConversationState]);

  useEffect(() => {
    if (rConversationState && rConversationState?.currentPage > 0 && rConversationState?.totalPages > 1 && rConversationState?.currentPage < rConversationState?.totalPages && currentActiveView === VIEWS.NORMAL_VIEW) {
      loadMoreConversation(rConversationState?.currentPage);
    }
  }, [rConversationState?.currentPage,rConversationState?.totalPages,currentActiveView]);

  useEffect(() => {
    if (currentActiveView !== VIEWS.GROUP_VIEW) {
      if (!fetched) {
        loadMoreConversation(0);
      }
    }
  }, [currentActiveRecipient?.id,currentActiveView,fetched]);
};
export default useChat;
