import { useDispatch, useSelector } from "react-redux";
import React, { memo, useCallback, useEffect } from "react";
import { connectWebSocket } from "util/WebSocketUtility";
import { COMPANY_ONLINE_STATUS } from "constants/WsConstants";
import { BLIP_WS_URL } from "constants/Endpoints";
import useIevent from "hooks/IChat/use-ievent";
import { IChatAction } from "store/IChat/Ichat-slice";
import { WEBSOCKET_EVENT_TYPE } from "constants/ChatConstants";
import { IGroupAction } from "store/IChat/igroup-slice";

import { UserAPI } from "services/UserAPI";
import { parseWSData } from "util/UtilityFunctions";
import useApp from "hooks/use-app";
import { userAction } from "store/user-slice";
import { messageAction } from "store/message-slice";
import { appAction } from "store/app-slice";
import { chatAction } from "store/chat-slice";
import { userStatusService } from "services/Background";
import { stopUserStatusService } from "services/Background";
import { USER_QUEUE } from "constants/WsConstants";
import { USER_ANNOUNCEMENT } from "constants/WsConstants";
import { USER_SESSION } from "constants/WsConstants";
import authPopup from 'services/AuthPopup/LoginInfoPopup';
import { SessionAPI } from "services/Session/SessionAPI";

const header = {
  // 'auto-delete': true,
  // 'durable' : false
  // "x-ha-queues" : true,
  ack: 'client'
};

const AuthenticatedPath = () => {
    const stompClient = useSelector(state => state.app.stompClient);
    const appDispatch = useDispatch();
    
    const currUser = useSelector(state => state.user);
    const {onEventReceive} = useIevent();
    const {onAppEvent} = useApp();
  
    const onCompanyEvent = (res) => {
      const data = res.body;
      console.log(data);
      // Check if the data appears to be JSON
      if (data.startsWith('{') && data.endsWith('}')) {
        try {
          const payload = JSON.parse(data);
          // Process the payload
          if(payload.ws_type === WEBSOCKET_EVENT_TYPE.ONLINE_STATUS) {
            if(payload.userId === currUser.id) return;
            appDispatch(IChatAction.updateRecipientStatus({ recipientId: payload.userId, online: payload.online }));
          }
          else if(payload.ws_type === WEBSOCKET_EVENT_TYPE.NEW_TAG){
            appDispatch(messageAction.newTag(payload.tag));
            // add to company existings....
            appDispatch(appAction.newCompanyTag(payload.tag));
          }
          else if(payload.ws_type === WEBSOCKET_EVENT_TYPE.REMOVE_TAG){
            console.log(payload)
            appDispatch(messageAction.removeTag(payload.tag));
          }
          else if(payload.ws_type === WEBSOCKET_EVENT_TYPE.CONTACT_BLOCKED){
            appDispatch(chatAction.blockContact(payload.id));
          }
          else if(payload.ws_type === WEBSOCKET_EVENT_TYPE.CONTACT_UNBLOCKED){
            const data = {
              id : payload.id
            }
            appDispatch(chatAction.unblockRecipients(data));
          }
          // if(payload.type === WEBSOCKET_EVENT_TYPE.PHONE_NEW_MESSAGE) {
          //   console.log(payload)
          //   onEventReceiveTele(WEBSOCKET_EVENT_TYPE.PHONE_NEW_MESSAGE,payload.message);
          //     favico?.badge(0,{ dotColor: '#ff0000' });
          // }
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
      } else {
        console.error('Received data is not valid JSON:', data);
      }    
    }
  
    const onMineEvents = (res) => {
      console.log("Response : ",res);
      const data = res.body;
      // Check if the data appears to be JSON
      if (data.startsWith('{') && data.endsWith('}')) {
          try {
            const payload = JSON.parse(data);
            // Process the payload
            if(payload.ws_type === WEBSOCKET_EVENT_TYPE.USER_TYPING) {
              console.log(payload);
              if(payload.groupChat){
                appDispatch(IGroupAction.updateEvent(payload));
              }
              else{
                appDispatch(IChatAction.updateEvent(payload));
              }
            }
            else if(payload.ws_type === WEBSOCKET_EVENT_TYPE.NEW_MESSAGE){
              console.log(payload);
              onEventReceive(WEBSOCKET_EVENT_TYPE.NEW_MESSAGE,payload.message);
            }
            else if(payload.ws_type === WEBSOCKET_EVENT_TYPE.MESSAGE_ACK) {
              console.log(payload);
              onEventReceive(WEBSOCKET_EVENT_TYPE.MESSAGE_ACK,payload.acks);
            }
            else if(payload.ws_type === WEBSOCKET_EVENT_TYPE.ADDED_TO_GROUP) {
              console.log(payload);
              onEventReceive(WEBSOCKET_EVENT_TYPE.ADDED_TO_GROUP,payload);
            }
            else if(payload.ws_type === WEBSOCKET_EVENT_TYPE.CREDIT_UPDATE){
              appDispatch(userAction.setCredit(payload));
            }
          } catch (error) {
            console.error('Error parsing JSON:', error);
          }
      } 
      else {
        console.error('Received data is not valid JSON:', data);
      }
    }  

    const onAnnouncement = useCallback((res) => {
      console.log(res);
      const payload = parseWSData(res.body);
      if(payload) {
        onAppEvent(payload);
        res.ack();
      }
    },[onAppEvent]);

  // Get users notifications
  useEffect(() => {
    if(currUser){
      UserAPI.getUserPendingNotifications({token : currUser.token,userId : currUser.id}).then(res => {
        console.log(res);
      }).catch(e => console.log(e));
    }
  },[currUser]);

  //WEBSOCKET CONNECTIONS:::--------------
    useEffect(() => {
        if(currUser.id && currUser.company){
        // Once user is authenticated....
          console.log("Invoking connectWebSocket....");
          connectWebSocket(currUser.id,currUser?.company?.company_id,BLIP_WS_URL,appDispatch);
          // Remove queue messages, it is initial all messages are going to fetch from DB.
        }
    },[currUser.id,currUser?.company?.company_id]);  
  
    useEffect(() => {
      /** client side
          var header = {
            'auto-delete': true
          };
          client.subscribe("/user/queue/{QUEUE_NAME}", function (message) {
          ...
          }, header);
      ***/
        let subscription;
        let subscribeToMe;
        let subscribeToAnnouncement = undefined;
        if(currUser && stompClient && currUser.id && currUser.company){
          try{
            subscription  = stompClient?.subscribe(COMPANY_ONLINE_STATUS(currUser.company.company_id),onCompanyEvent,{userId: currUser.id });
            subscribeToMe = stompClient?.subscribe(USER_QUEUE(currUser.id),onMineEvents,{userId: currUser.id });
            subscribeToAnnouncement = stompClient?.subscribe(USER_ANNOUNCEMENT(currUser.id),onAnnouncement,header);
          }
          catch(ex){
            console.log(ex)
          }
        }
        return () => {
            if (subscription) {
              subscription?.unsubscribe({simpDestination : `${COMPANY_ONLINE_STATUS(currUser?.company?.company_id)}`});
              // UtilAPI.markOffline({token:currUser.token,userId : currUser.id, destination: COMPANY_ONLINE_STATUS(currUser.company.company_id)})
              // .then().catch(e => {});
            }
            if(subscribeToMe){
              subscribeToMe?.unsubscribe({simpDestination : `${USER_QUEUE(currUser.id)}`});
            }
            if(subscribeToAnnouncement){
              subscribeToAnnouncement?.unsubscribe();
            }
        }
    },[currUser?.id,stompClient,currUser?.company]);    

    useEffect(() => {
      let intId = undefined;
      if(currUser.company?.company_id && currUser.token){
        intId = userStatusService({companyId : currUser.company?.company_id, token : currUser.token});
      }
      return () => {
        if(intId){
          stopUserStatusService(intId);
        }
      }
    },[currUser?.company,currUser?.token]);
    return(
        <>
        </>
    )
}
export default memo(AuthenticatedPath);