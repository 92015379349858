import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import UploadIcon from "@mui/icons-material/Upload";
import InfoIcon from "@mui/icons-material/Info";
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Icon, Menu } from '@mui/material';
import NotificationItem from 'lib/layouts/Items/NotificationItem';
import BulkLogs from 'components/Chat/BulkLogs';
import BulkUpload from 'components/Chat/BulkUpload';
import { Message } from '@mui/icons-material';
import { GROUP_MENU_ACTION } from 'constants/groupConstants';
import GroupJoinedLogs from 'components/Logs/GroupJoinedLogs';
import AppModal from 'ui/AppModal';

const GroupMenu = ({popoverTarget,onActionPerformed,currentActivePhone}) => {
    
    const [action, setAction] = useState("");

    const creditAvailable = useSelector(state => state.user.credit);

    const [openMenu1, setOpenMenu1] = useState(false);
    
    const handleCloseMenu1 = () => {
      setOpenMenu1(false);
      onActionPerformed(GROUP_MENU_ACTION.GROUP_MENU_CLOSED);
    }

    const uploadBulk = (type) => {
        setAction("");
        onActionPerformed(GROUP_MENU_ACTION.BULK_UPLOAD, type);
    };

    useEffect(() => {
        setOpenMenu1(popoverTarget.target)
    },[popoverTarget])
    
    const renderBulkMenu = () => (
        <Menu
          anchorEl={openMenu1}
          anchorReference={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={Boolean(openMenu1)}
          onClose={handleCloseMenu1}
          sx={{ mt: 2 }}
        >
          <NotificationItem
            style={{pointerEvents:'none'}}
            icon={<AccountBalanceWalletIcon />}
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>Credits available</span>
                <span style={{ marginLeft: "6px", fontSize: "32px" }}>
                  {creditAvailable ? creditAvailable : 0}
                </span>
              </div>
            }
          ></NotificationItem>
          <NotificationItem
            onClick={() => setAction(GROUP_MENU_ACTION.BULK_UPLOAD)}
            icon={
              <Icon>
                <UploadIcon />
              </Icon>
            }
            title="Upload a one-time broadcast"
          />
          <NotificationItem
            onClick={() => setAction(GROUP_MENU_ACTION.JOIN_LOGS)}
            icon={
              <Icon>
                <InfoIcon />
              </Icon>
            }
            title="Join logs"
          />
          <NotificationItem
            onClick={() => setAction(GROUP_MENU_ACTION.BULK_LOGS)}
            icon={
              <Icon>
                <Message />
              </Icon>
            }
            title="SMS stats"
          />          
        </Menu>
      );    
    return (
      <>
        {renderBulkMenu()}
        {action === GROUP_MENU_ACTION.BULK_UPLOAD && (
          <BulkUpload
            onClose={() => setAction("")}
            currentPhone={currentActivePhone}
            onCsvAdded={uploadBulk}
          />
        )}
        {action === GROUP_MENU_ACTION.BULK_LOGS && (
          <BulkLogs
            currentActivePhone={currentActivePhone}
            onClose={() => setAction("")}
          />
        )}
        {action === GROUP_MENU_ACTION.JOIN_LOGS && (
          <AppModal 
            rootStyle = {
                {
                "&.MuiModal-root":{
                    width:'90%',
                    left: '10%'
                }
              }
            }
            customStyle={{width:'100%',height:'100%',overflowY:'scroll',border:'unset'}} 
            onModalClose={() => setAction("")} 
            heading={'Logs'}
          >
              <GroupJoinedLogs/>
          </AppModal>    
        )}
      </>
    );
}
export default GroupMenu;