import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import { Stack } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { useState } from "react";
import { useEffect } from "react";
import { formatAudioTimer } from "util/UtilityFunctions";
import WaveSurfer from "wavesurfer.js";
import RecordPlugin from "wavesurfer.js/dist/plugins/record.esm";
import DeleteIcon from "@mui/icons-material/Delete";
import { useRef } from "react";
import { Pause, PlayArrowSharp } from "@mui/icons-material";
import { toast } from "react-toastify";
import { Audio as AudioService} from "services/Audio/Audio";
import AppModal from "ui/AppModal";
import AudioPermission from "./AudioPermission";

let record = undefined;
let wavesurfer = undefined;
const status = {
  INITIAL : 'initial',
  RECORDING: 'recording',
  PAUSED : 'paused',
  RESUME : 'resume',
  DONE : 'done',
  ERROR : 'error'
}
const init = (startTimer,setMessageState,onError) => {
  wavesurfer = WaveSurfer.create({
    container: "#mic",
    waveColor: 'rgb(68, 133, 190)',
    progressColor: 'rgb(100, 0, 100)',
    autoCenter: true,
    height: '48px',
    barHeight: 0.25
});

  // Initialize the Record plugin
  record = wavesurfer.registerPlugin(RecordPlugin.create());

  record.on('record-end',(blob) => {
    // chunksRef.current.push(blob);
    wavesurfer.loadBlob(blob);
    setMessageState(prev => {
      return{
        ...prev,
        attachement : blob
      }
    })
  });
  // Check if mic available
  AudioService.checkMicrophoneAvailability().then(res => {
    record.startRecording().then(res => {
      startTimer();
    }).catch(e => {
      onError(e);
    });
    
  }).catch(e => onError(e));

  wavesurfer.on("pause",(blob) => {
    console.log("Paused");
  })
}

const Audio = ({setAudioMessage,setMessageState}) => {
    
    const intervalRef = useRef();

    const [playBtn,setPlayBtn] = useState(true);
    
    const [showPermissionModel,setShowPermissionModel] = useState(false);

    const [time, setTime] = useState(0);
    const [currStatus, setCurrStatus] = useState(status.INITIAL);

    const startTimer = () => {
      intervalRef.current = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 10);
    };

    const onError = (e)  => {
      toast.error("We are not able to detect a microphone. Please make sure you have microphone connected to your device.");
      setCurrStatus(status.PAUSED);
    }

    const pauseRecording = () => {
      record.destroy();
      setCurrStatus(status.PAUSED);
      if(intervalRef?.current){
        clearInterval(intervalRef.current);
      }
    };
    
    const resumeRecording = () => {
      record.startRecording();
      setCurrStatus(status.RECORDING);
      startTimer();
    }

    const stopTimer = () => {
      clearInterval(intervalRef.current);
      setTime(0);
    };

    useEffect(() => {
      AudioService.askMicroPhonePermission().then(res => {
        if(res.state === 'granted' || res.state === 'prompt'){
          setCurrStatus(status.RECORDING);
          init(startTimer,setMessageState,onError);
        }  
        else if(res.state === 'denied'){
          setShowPermissionModel(true);
        }
      }).catch(err => {
        toast.error(err);
      })       
      return () => {
        wavesurfer?.destroy();
        if(intervalRef.current){
          clearInterval(intervalRef.current);
          intervalRef.current = undefined;
        }
      }
    },[setMessageState])

    return(
      <>
        <MDBox className="chat-footer" display="flex" padding="12px" flexDirection="row" width="100%" justifyContent="center" alignItems="center">
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            {currStatus === status.PAUSED && <DeleteIcon
              className="sidebar-icons"
              onClick={() => {
                record.destroy();
                setAudioMessage(null);
                setMessageState(prev => {
                  return{
                    ...prev,
                    attachement : undefined
                  }
                })
              }}
              style={{ cursor: "pointer", marginLeft: "6px" }}
            />}
            <MDBox sx={{borderRadius:'20px', backgroundColor:'#e5eaf0',height:'48px',padding:'12px'}} display="flex" flexDirection="row" alignItems="center">
              <h4>{formatAudioTimer(time)}</h4>
              <div id="mic" style={{width:'120px'}}></div>
                {currStatus === status.RECORDING && (
                  <PauseCircleOutlineIcon
                    style={{ cursor: "pointer", marginLeft: "6px" }}
                    onClick={pauseRecording}
                    className="sidebar-icons"
                  />
                  )}
                  {currStatus === status.PAUSED && playBtn && <PlayArrowSharp className="sidebar-icons" sx={{cursor: "pointer"}} onClick={() => {setPlayBtn(false);wavesurfer.playPause()}}/>}
                  {currStatus === status.PAUSED && !playBtn && <Pause className="sidebar-icons" sx={{cursor: "pointer"}} onClick={() => {setPlayBtn(true);wavesurfer.playPause()}}/>}
            </MDBox>
          </Stack>            
        </MDBox>
        {showPermissionModel && 
        
          <AppModal
            customStyle={{ width: "500px",padding: '16px !important' }}
            onModalClose={() => setShowPermissionModel(false)}
            showCloseButton = {false}
          > 
            <AudioPermission/>
          </AppModal>
        }
      </>
    )
}
export default Audio;