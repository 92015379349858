import { createSlice } from "@reduxjs/toolkit";

const initialAppState = {
    dlc_categories: null,
    companyTags : [],
    onlyIcAccess : false,
    snackBarMessage : '',
    openSnackBar : false,
    newAnnouncement : undefined,
    mergeViewActive : false,
    snackbar : undefined,
    stompClient : undefined,
    chatDynamicComponent : undefined,
    preventRefresh : {
        eventInProgress : false,
        message : ''
    }
};

const AppSlice = createSlice({
    name : 'app',
    initialState : initialAppState,
    reducers : {
        setCompanyTags : (state,action) => {
            state.companyTags = action.payload; 
        },
        addCompanyTag : (state,action) => {
            state.companyTags = state.companyTags.concat(action.payload);
        },
        newCompanyTag : (state, action) => {
            const tag = action.payload;
            console.log(tag);
            const tagExist = state.companyTags.find(itm => itm.tagId === tag?.tag?.tagId);
            if(!tagExist){
                state.companyTags = state.companyTags.concat(tag.tag);
            }
        },

        setChatDynamicComponent : (state,action) => {
            state.chatDynamicComponent = action.payload;
        },

        setDlcCategories : (state,action) => {
            state.dlc_categories = action.payload;
        },
        setOnlyIcAccess : (state) => {
            state.onlyIcAccess = true;
        },
        setSnackbarMessage : (state,action) => {
            state.snackBarMessage = action.payload;
        },
        openSnackbar: (state) => {
            state.openSnackBar = true;
        },
        setCloseSnackBar : (state) => {
            state.openSnackBar = false;
        },
        setAnnouncement : (state,action) => {
            state.newAnnouncement = action.payload;
        },
        setMergeViewActive : (state,action) => {
            state.mergeViewActive = action.payload;
        },
        setSnackbar : (state,action) => {
            state.snackbar = action.payload;
        },
        setSnackbarContent : (state,action) => {
            state.snackbar.content = action.payload;
        },
        setPreventRefresh : (state,action) => {
            state.preventRefresh = action.payload;
        },
        updateStompClient : (state, action) => {
            state.stompClient = action.payload;
        },  
      
        resetStomp : (state) => {
            state.stompClient = undefined;
        }, 
    }
})

export const appAction = AppSlice.actions;
export default AppSlice;