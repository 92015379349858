import { Button, Divider, FormControlLabel, FormGroup, Switch, TextField, Tooltip, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { WASettingsAPI } from "services/WASettingsAPI";

const WASettings = ({ company }) => {

  const token = useSelector(state => state.user.token);
  const [settingEditing, setSettingEditing] = useState(false);
  const [waSettings,setWaSettings] = useState({
    company,
    bridgeEnable : false,
    customerReadAllowed : false,
    applicationId : ''
  });

  const updateSettings  = useCallback((settings) => {
    const newSettings = settings ? settings : waSettings;
    WASettingsAPI.updateWASetting({wa : newSettings,token}).then(res => {
      toast.success("Settings saved successfully");
      setSettingEditing(false);
    }).catch(err => {
      toast.error("Something went wrong, please try again.")
    })
  },[waSettings]);

  useEffect(() => {
    if(company){
      WASettingsAPI.getWASettings({companyId : company.companyId,token}).then(res => {
        if(res.data){
          setWaSettings(res.data);
        }
      }).catch(err => {

      })
    }
  },[company]);
  
  return (
    <>
      <MDBox
        sx={{
          padding: "12px",
        }}
        display="flex"
        flexDirection="column"
      >
        <MDBox display="flex" width="100%" justifyContent="center" mb={2}>
          <Typography>WA Settings</Typography>
        </MDBox>
        <MDBox display="flex" flexDirection="column">
          <MDBox display="flex" >
              <MDBox>
                <Typography variant="button" fontWeight={500}>
                  WA Application Id
                  <Tooltip title="Get application id from here">
                    <a href="https://developers.facebook.com/apps/" target="_blank"><OpenInNewIcon sx={{height:'18px', width:'18px'}}/></a>
                  </Tooltip>
                </Typography>
              </MDBox>
          </MDBox>
          <TextField
            sx={{
              width: "100%",
            }}
            value={waSettings?.applicationId}
            onChange={(e) =>{
              if (!settingEditing) {
                setSettingEditing(true);
              }
              setWaSettings(prev => {
                return{
                  ...prev,
                  applicationId : e.target.value
                }
              })
            }}
          />
          <Typography fontSize={"12px"}>{"Application ID is required to create whatsapp templates."}</Typography>  

          {settingEditing && (
          <MDBox
            display="flex"
            justifyContent="flex-start"
            onClick={() => updateSettings()}
            mt={2}
          >
            <Button
              variant="contained"
              sx={{
                borderRadius: "0px",
                color: "white!important",
                border: "unset",
                marginLeft: "12px",
                height: "32px",
                minHeight: "unset",
              }}
            >
              Save
            </Button>
          </MDBox>
        )}
        </MDBox>
        <Divider component="div"
          sx={{
            "&.MuiDivider-root": {
              backgroundColor: "#e9d5e9",
            },
          }}/>
        <MDBox display="flex" flexDirection="column">
          <MDBox display="flex" justifyContent="center" mb={1}><Typography>{"Others settings"}</Typography></MDBox>
          <MDBox>
            <FormGroup>
              <FormControlLabel onChange={(e) => {
                const tmpSettings = {
                  ...waSettings,
                  bridgeEnable : e.target.checked
                }
                setWaSettings(prev => {
                  return{
                    ...prev,
                    bridgeEnable : e.target.checked
                  }});
                  updateSettings(tmpSettings);
              }} control={<Switch/>} checked={waSettings.bridgeEnable} label="Enable Whatsapp Bridge" />                
            </FormGroup>
          </MDBox>
          <MDBox>
            <FormGroup>
              <FormControlLabel onChange={(e) => {
                const tmpSettings = {
                  ...waSettings,
                  customerReadAllowed : e.target.checked
                }
                setWaSettings(prev => {
                return{
                  ...prev,
                  customerReadAllowed : e.target.checked
                }});
                updateSettings(tmpSettings);
              }} checked={waSettings.customerReadAllowed} control={<Switch/>} label="Customer can see if message is read" />                
            </FormGroup>
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
};
export default WASettings;
