import { Divider, List, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import TemplatePreview from "./TemplatePreview";
import { Info } from "@mui/icons-material";

const CreateTemplate = ({onTemplateCreated,company,waCredentials}) => {

    return(
        <>
            <MDBox display="flex" flexDirection="column">
                <MDBox display="flex" justifyContent="start" flexDirection="column" padding="8px">
                    <Typography>Submit Message Template for Approval</Typography>
                    <Typography sx={{fontSize:'14px'}}>Submit your message templates for approval to WhatsApp here. Once approved (around 24 hours), the template can be used to send messages to your contacts.</Typography>
                </MDBox>
                <MDBox 
                    display="flex"
                    flexDirection="Column"
                    sx={{
                        backgroundColor: '#E2F2FD',
                        padding:'12px',
                        marginTop : '8px',
                        border: 'solid 1px #fbfbfb',
                        borderRadius:'22px',
                        width:'100%'
                    }}
                >
                    <MDBox display="flex">
                        <Info sx={{marginRight:'8px'}}/>
                        <Typography fontSize={'16px'}>{"Limitations"}</Typography>
                    </MDBox>
                    <MDBox display="flex" flexDirection="column" padding={"12px"}>
                        <List>
                            <Typography fontSize={"12px"}>{"1. The message template name field is limited to 512 characters."}</Typography>
                        </List>
                        <List>
                            <Typography fontSize={"12px"}>{"2. The message template content field is limited to 1024 characters."}</Typography>
                        </List>   
                        <List>
                            <Typography fontSize={"12px"}>{"3. A template can only be edited when it is in a state of Approved, Rejected, or Paused. A template can be edited once per day, up to 10 times per month."}</Typography>
                        </List>   
                        <List>
                            <Typography fontSize={"12px"}>{"4. WhatsApp Business Accounts can only create 100 message templates per hour."}</Typography>
                        </List>                                                                        
                    </MDBox>
                </MDBox>                
                <Divider
                    variant="horizontal"
                    component="div"
                    sx={{
                    "&.MuiDivider-root": {
                        backgroundColor: "#e9d5e9",
                    },
                    }}
                />
                <MDBox width="100%">
                    <MDBox display="flex">
                        <TemplatePreview waCredentials={waCredentials} onTemplateCreated={onTemplateCreated} company={company}/>
                    </MDBox>
                </MDBox>
            </MDBox>
        </>
    )
}
export default CreateTemplate;