import { Navbar } from "pages/Chat/ChatStyles";
import { NavbarOuterWrapper } from "pages/Chat/ChatStyles";
import { FlexContainer } from "pages/Chat/ChatStyles";
import { SearchOuterWrapper } from "pages/Chat/ChatStyles";
import ContactSearch from "./ContactSearch";
import { useRef } from "react";
import UserSettings from "components/Chat/UserSettings";
const ILeftChatNavBar = () => {

    const searchRef = useRef();
    return(
        <>
            <NavbarOuterWrapper  ref={searchRef} id={"nav__bar"}>
                <Navbar padding="13px 10px" bgColor="#f5f5f5" borderBottom="1px solid #f5f5f5" style={{display:"flex",alignItems:"center"}}>
                    <FlexContainer justifyContent="space-between" height="auto">
                        <UserSettings/>
                    </FlexContainer>
                </Navbar>
                <FlexContainer
                    height="auto"
                    justifyContent="space-between"
                    paddingLeftRight="10px"
                    className="full-width-searchbar"
                >
                    <SearchOuterWrapper>
                        <ContactSearch searchRef={searchRef}/>
                    </SearchOuterWrapper>
                </FlexContainer>      
        </NavbarOuterWrapper>
        </>
    )
}
export default ILeftChatNavBar;