import { useState } from "react";

const usePopup = () => {
  const [anchorEl, setAnchorEl] = useState();
  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? "simple-popover" : undefined;

  const handleOpenPopup = (event) => {
    if(event && event.currentTarget){
      setAnchorEl(event.currentTarget);
    }
    else{
      setAnchorEl(document.body)
    }
  };

  const handleClosePopup = () => {
    setAnchorEl(null);
  };

  return {
    popoverId,
    anchorEl,
    openPopover,
    handleOpenPopup,
    handleClosePopup,
    setAnchorEl
  };
};
export default usePopup;
