import { countryCode } from "constants/countryCode";

const SelectCountryCode = ({onSelected,customStyle = {}}) => {

    return(
        <select style={{...customStyle}} onClick={e => e.stopPropagation()} onChange={(e) => {
            onSelected(e.target.value)
        }}>
            {countryCode.map(itm => <option key={itm.code} selected={itm.code === "US"} value={itm.dial_code}>{itm.name}&nbsp;(+{itm.dial_code})</option>) }
        </select>
    )    
}
export default SelectCountryCode;