import CompanyProfile from "components/IFrame/CompanyProfile";
import MDBox from "lib/components/MDBox";
import { useEffect } from "react";

const IFrame = () => {

  useEffect(() => {
    document.body.style="background-color : white";
  },[])

  return (
    <MDBox display="flex" width="100%" justifyContent="center" alignItems="center" sx={{backgroundColor:'white!important'}}>
        <MDBox width="600px" display="flex" justifyContent="center" alignItems="center">
            <CompanyProfile/>
        </MDBox>
    </MDBox>
  );
};
export default IFrame;
