

import { BASE_URL } from "constants/Endpoints";
import Swal from "sweetalert2";

import { disconnect } from "util/WebSocketUtility";

import { chatVariablesAction } from "store/chat-variables-slice";
import { draftsMessageAction } from "store/draft-message-slice";
import { IChatAction } from "store/IChat/Ichat-slice";
import { userAction } from "store/user-slice";
import { chatAction } from "store/chat-slice";

import {store} from 'store/index';

const authPopup = () => {
  // const stompClient =  store.getState().Ichat.stompClient;
  // const smsAppStomp =  store.getState().chat.stompClient;

  const handlePopup =(err)  =>  {
    let isLogin = false;
    try{
      let last = window.location.href.split("/");
      last = last[last.length - 1];
      if(last === 'login'){
        isLogin = true;
      }
    }catch(ex){}  
    if(isLogin){
      return;
    }
    const handleLogin = () => {
        store.dispatch(draftsMessageAction.setDraftsMessage({message:"check111111111111"}));
        localStorage.clear();
        store.dispatch(userAction.logout());
        store.dispatch(userAction.logout());
        store.dispatch(chatAction.reset());
        store.dispatch(chatVariablesAction.reset());
        store.dispatch(IChatAction.reset());
        console.log(store.getState()?.Ichat.stompClient, store.getState(), "state ---check error111 " , err.request?.responseURL);
        store.getState()?.Ichat.stompClient?.disconnect();
        store.getState()?.chat.stompClient?.disconnect();
        disconnect();
      };
    
    
    if(err?.response?.status === 401 && err.request?.responseURL.includes(BASE_URL))
      Swal.fire({
        title: "You are logged out",
        text: "Please login again",
        icon: "info",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Login",
      }).then((result) => {
        if (result.isConfirmed) {
              handleLogin()
        }
      });
  }
  return handlePopup
};
export default authPopup;
