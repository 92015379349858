import { OpenInNew } from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/material";
import ChooseFileGeneric from "components/CommonUI/ChooseFileGeneric";
import Accordian from "components/util/Accordian";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import { useCallback, useState } from "react";
import DonationPagePreview from "./DonationPagePreview";
import { useDispatch, useSelector } from "react-redux";
import { CompanyAPI } from "services/CompanyAPI";
import { uploadAsset } from "util/upload-media-helper";
import { toast } from "react-toastify";
import AppConstants from "constants/AppConstants";
import { useNavigate } from "react-router-dom";
import { loaderAction } from "store/loader-slice";

const CompanyBannerSetting = ({company,campaign}) => {

    const token = useSelector(state => state.user.token);
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [previewData,setPreviewData] = useState({
        bgImageFile : undefined,
        bgImageUrl : undefined,
        companyName : company?.company_name,
        company_banner : company?.company_banner
    });

    const uploadBanner = async({file,token,domain,companyId}) => {
        const uploaded = await uploadAsset({file,token,domain});
        // if success save this to the DB
        const payload = {
            id : companyId,
            updated_value : uploaded?.asset?.uri
        }
        CompanyAPI.updateCompanyBanner({payload,token}).then(res => {
            toast.success(AppConstants.UPDATED_SUCCESS);
            dispatch(loaderAction.hide());
        }).catch(e => {
            console.log(e);
            dispatch(loaderAction.hide());
        })
    }
    const onUpdate = useCallback(() => {
        const payload = {
            file : previewData.bgImageFile,
            domain : company?.slug ? company?.slug : "bliq",
            token,
            companyId : company?.company_id
        }   
        uploadBanner(payload);
        dispatch(loaderAction.show());
    },[previewData,company,token,dispatch])

    const onFileSelected = useCallback((f) => {
        setPreviewData( p => {
            return{
                ...p,
                bgImageUrl : URL.createObjectURL(f),
                bgImageFile : f,
                company_banner : undefined
            }
        })
    },[setPreviewData])

    return(
        <MDBox display="flex" sx={{margin: '12px 0 0 0'}}>
            <Accordian key={"donation_page"} style={{width:"100%"}} heading={"Donation page "}>
                {console.log({previewData})}
                <MDBox display="flex" width="100%" flexDirection="row" height="100%" justifyContent="space-between">
                    <MDBox width="100%" margin={"8px"} display="flex">
                        <MDBox display="flex" width="100%" flexDirection="column">
                            <Typography fontSize={"14px"}>{"Choose banner to update."}</Typography>
                            <MDBox display="flex">
                                <ChooseFileGeneric onFileSelected={onFileSelected}/>
                                {previewData.bgImageUrl && <MDBox display="flex" ml={2} width="100%">
                                    <MDButton onClick={onUpdate} sx={{maxWidth:'120px'}} variant="gradient" color="info">Update</MDButton>
                                </MDBox>}
                            </MDBox>
                            <MDBox width="100%" justifyContent="center" display="flex" mb={2}>
                                <Typography alignContent={"center"}>{"Donation page preview"}</Typography>
                            </MDBox>
                            <MDBox display="flex" justifyContent="center" sx={{width:"100%"}}>
                                <DonationPagePreview key={"donation_preview"} companyName= {previewData.companyName} backgroundImage={previewData.company_banner ? previewData.company_banner : previewData.bgImageUrl}/>
                                <Tooltip title="open in new tab">
                                    <a target="_blank"><OpenInNew sx={{cursor:"pointer"}} onClick={() => {
                                        navigate(`/donate/${company?.slug}/crowdfunding/preview`,{ state : {company : {...company,company_banner : previewData.bgImageUrl}} })
                                    }}/></a>
                                </Tooltip>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </Accordian>
        </MDBox>
    )
}
export default CompanyBannerSetting;