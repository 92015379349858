
import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import MDProgress from 'lib/components/MDProgress';
import { Typography } from '@mui/material';

function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ minWidth: 35 }} mr={1}>
          <Typography fontWeight="500" variant="body2" color="text.secondary">{props.currenttime}s</Typography>
        </Box>
        <Box sx={{ width: '160px', mr: 1, overflow:"hidden", marginBottom:'3px' }}>
            <MDProgress sx={{scale: "-1 1"}} variant="determinate" {...props} />
        </Box>
      </Box>
    );
  }
  
  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

const MessageExpireTime = ({progress,currenttime}) => {
    return (
      <Box sx={{ width: '100%',display: 'flex', alignItems: 'center' }}>
        {currenttime > 0 && <LinearProgressWithLabel value={progress} currenttime={currenttime}/>}
      </Box>
    );
}
export default MessageExpireTime;