import React, { Component } from 'react';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import style from "./AppTextEditor.module.css";
import MDBox from 'lib/components/MDBox';
import { Button } from '@mui/material';
import htmlToDraft from 'html-to-draftjs';

export class AppTextEditor extends Component {
  state = {
    editorState: EditorState.createEmpty(),
    isEditing: false,
  };

  componentDidMount() {
    console.log("this.props.initialContent")
    if(this.props.initialContent){
      const blocksFromHtml = htmlToDraft(this.props.initialContent);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      this.setState({
        editorState : EditorState.createWithContent(contentState),
        isEditing : false
      })
    }
  }
  onSave = () => {
    let html = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
    this.props.onPolicySaved(html);
  };

  uploadImage = (file, callback) => {
    console.log(file);
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader();
      console.log(reader);
      reader.onloadend = async () => {
        // const form_data = new FormData();
        // form_data.append("file", file);
        // const res = await uploadFile(form_data);
        // setValue("thumbnail", res.data);
        resolve();
      };
      reader.readAsDataURL(file);
    });
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
      isEditing : true
    });
  };

  render() {
    const { editorState, isEditing } = this.state;
    return (
      <div>
        {isEditing && (
          <MDBox
            onClick={this.onSave}
            width="100%"
            display="flex"
            justifyContent="end"
          >
            <Button
              variant="contained"
              sx={{
                borderRadius: "0px",
                color: "white!important",
                border: "unset",
                marginLeft: "12px",
                height: "32px",
                minHeight: "unset",
              }}
            >
              Save
            </Button>
          </MDBox>
        )}
        <Editor
          editorState={editorState}
          wrapperClassName={`${style["wrapper-class-name"]}`}
          editorClassName={`${style["editor-class-name"]}`}
          toolbar={{
            image: {
              uploadCallback: this.uploadImage,
              uploadEnabled: true,
              previewImage: true,
              alignmentEnabled: "RIGHT",
            },
          }}
          onEditorStateChange={this.onEditorStateChange}
        />
      </div>
    );
  }
}