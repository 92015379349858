import { Chip, ListItem, Paper } from "@mui/material";
import { memo } from "react";
import { useEffect, useState } from "react";

const AppChip = ({data,onRemoved = () => {}}) => {
    
    const [chipData, setChipData] = useState([]);

    const handleDelete = (chipToDelete) => () => {
      setChipData((chips) =>
        chips.filter((chip) => chip.key !== chipToDelete.key)
      );
      onRemoved(chipToDelete);
    };
    useEffect(() => {
        if(data){
          setChipData(data);
        }
    },[data])

    return (
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          flexDirection: 'row',
          p: 0.5,
          m: 0,
        }}
      >
        {chipData?.map((d) => {
          return (
              <Chip
                key={'chip_' + d.key}
                icon={d?.icon}
                label={d.label}
                onDelete={
                  !d.delete ? undefined : handleDelete(d)
                }
              />
          );
        })}
      </Paper>
    );
}
export default memo(AppChip);