import { createSlice } from "@reduxjs/toolkit";

const initialThreadStates = {
    conversation : {},
    fetched : false,
};

const IThreadSlice = createSlice({
    name: "Ithread",
    initialState: initialThreadStates,
    reducers : {
        addReply : (state,action) => {
            const { parentId, reply } = action.payload;
            if (!state.conversation[parentId]) {
              state.conversation[parentId] = [];
            }
            state.conversation[parentId].push(reply);
        },
        updateReply : (state,action) => {
            const {parentId,message} = action.payload;
            const sender = message.recipients.find(itm => itm.userId === message.senderId);
            const replyIdx = state.conversation[parentId].findIndex(itm => itm?.tempMessageId === message.tempMessageId);
            const oldMessage = state.conversation[parentId][replyIdx];
            if(replyIdx !== -1){
                state.conversation[parentId][replyIdx] = {
                    ...oldMessage,
                    sender : sender,
                    messageId : message.messageId
                };
            }
        },
        setReplies : (state,action) => {
            const { parentId, replies } = action.payload;
            if (!state.conversation[parentId]) {
              state.conversation[parentId] = [];
            }
            state.conversation[parentId] = replies;
            state.fetched = true;
        }
    }
})
export const IThreadAction = IThreadSlice.actions;
export default IThreadSlice;