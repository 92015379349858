import { BASE_URL } from "constants/Endpoints";
import fetch from "core/fetch";

export const getAutoReply = async(token) => {
    const response = await fetch(`${BASE_URL}/settings/autoreply`,{
        method : 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

// update email credentials
export const setEmailCred = async(changeData) => {
    const payload = {
        ...changeData,
        token : undefined
    }
    const response = await fetch(`${BASE_URL}/settings/email`,{
        method : 'POST',
        body : JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + changeData.token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

//// GET existing keywords and corresponding reply
export const getKeywords = async({token,companyId}) => {
    const response = await fetch(`${BASE_URL}/settings/keywords/${companyId}`,{
        method : 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const AddKeywords = async(reply) => {
    const payload = {
        ...reply,
        token: undefined
    }
    const response = await fetch(`${BASE_URL}/settings/keywords`,{
        method : 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + reply.token,
          },
        body : JSON.stringify(payload)  
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
export const removeKeywords = async(reply) => {
    const response = await fetch(`${BASE_URL}/settings/keywords`,{
        method : 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + reply.token,
          },
        body : JSON.stringify(reply.reply)  
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
export const unscheduleEmailNotifications = async(notificationPayload) => {
    const payload = {
        ...notificationPayload,
        token: undefined
    }
    const response = await fetch(`${BASE_URL}/email/unschedule`,{
        method : 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + notificationPayload.token,
          },
        body : JSON.stringify(payload)  
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

//// GET DLC CATEGORIES
export const getDLC = async(token) => {
    const response = await fetch(`${BASE_URL}/settings/dlc`,{
        method : 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}