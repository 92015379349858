import { Button, Typography } from "@mui/material";
import { getTermsAndConditions,acceptTermsAndConditions } from "api/poolSettings";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IChatAction } from "store/IChat/Ichat-slice";
import { chatAction } from "store/chat-slice";
import { chatVariablesAction } from "store/chat-variables-slice";
import { userAction } from "store/user-slice";
import { disconnect } from "util/WebSocketUtility";

const AcceptTermsAndConditions = ({onAccepted}) => {

    const appDispatch = useDispatch();
    const navigate = useNavigate();
    const stompClient = useSelector(state => state.app.stompClient);

    const currUser = useSelector(state => state.user);
    const [convertedContent, setConvertedContent] = useState(null);
    const { sendRequest, status, data, error } = useHttp(getTermsAndConditions,false);
    const {sendRequest : acceptTermsAndConditionsFn, status: acceptStatus, data : acceptData, error: acceptError} = useHttp(acceptTermsAndConditions,false);
    
    function createMarkup(html) {
        return {
          __html: html,
        };
    }

    const onLogoutHandler = useCallback(() => {
        localStorage.removeItem("blip_user_data");
        appDispatch(userAction.logout());
        stompClient?.disconnect();
        disconnect();
        appDispatch(userAction.logout());
        appDispatch(chatAction.reset());
        appDispatch(chatVariablesAction.reset());
        appDispatch(IChatAction.reset());
        navigate("/login",{ replace: true });;    
    
    },[stompClient,appDispatch,navigate]);

    const accept = () => {
        acceptTermsAndConditionsFn({token : currUser.token, userId : currUser.id});
    }

    useEffect(() => {
        sendRequest(currUser.token);
    },[currUser,sendRequest]);

    useEffect(() => {
        if(status === 'completed'){
          if(data){
            const html = data.appTermsConditions ? data.appTermsConditions : data.appTermsConditions;
            setConvertedContent(html);
          }
        }
    },[status,data,error])

    useEffect(() => {
        if(acceptStatus === 'completed'){
            if(acceptData > 0){
                onAccepted();
                appDispatch(userAction.updateTermsAndConditions());
                // put in localstorage as well
                localStorage.setItem("terms_accepted",true);
            }
            else{
                toast.error(acceptError);
            }
        }
    },[acceptStatus,acceptData,acceptError]);


    return(
        <MDBox width="100%" display="flex" flexDirection="column" height="100%">
            <MDBox display="flex" justifyContent="center">
                <Typography>{"Terms and Conditions"}</Typography>
            </MDBox>
            <MDBox sx={{minHeight:'600px',maxHeight:'600px',overflow:'auto'}}>
                <div dangerouslySetInnerHTML={createMarkup(convertedContent)}></div>
            </MDBox>
            <MDBox display="flex" justifyContent="flex-end" width="100%">
                <Button
                    onClick={accept}
                    variant="contained"
                    sx={{
                    borderRadius: "0px",
                    color: "white!important",
                    border: "unset",
                    marginLeft: "12px",
                    height: "32px",
                    minHeight: "unset",
                }}>
                    {"Yes, I Agree"}
                </Button>
                <Button onClick={onLogoutHandler}>{"No, Logout"}</Button>
            </MDBox>
        </MDBox>
    )
}
export default AcceptTermsAndConditions;