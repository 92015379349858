import CustomerList from "components/Customer/CustomerList";
import DashboardLayout from "lib/layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "lib/layouts/Navbars/DashboardNavbar";

const Customer = ({}) => {
    return(
        <>
            <DashboardLayout>
                <DashboardNavbar />
                <CustomerList key={"customers"}/>
            </DashboardLayout>
        </>
    )
}
export default Customer;