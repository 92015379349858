import RightSideBarContainer from "components/CommonUI/RightSideBarContainer";
import MDBox from "lib/components/MDBox";
import { useDispatch, useSelector } from "react-redux";
import { ichatVariablesAction } from "store/IChat/Ichat-variables-slice";
import Contact from "../Contact";
import { Button, Tooltip, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import Swal from "sweetalert2";
import { ChatAPI } from "services/IChat/ChatAPI";
import { useState } from "react";
import AppModal from "ui/AppModal";
import IAddParticipant from "./IAddParticipant";
import MDTypography from "lib/components/MDTypography";
import { memo } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { formatGroupChat } from "util/IChat/IChatUtil";
import { IGroupAction } from "store/IChat/igroup-slice";
import { toast } from "react-toastify";

const IGroupDetails = ({group}) => {

    const dispatch = useDispatch();
    const token = useSelector(state => state.user.token);
    const currUser = useSelector(state => state.user);
    const [currGroup,setCurrGroup] = useState();
    const [addParticipants,setAddParticipants] = useState(false);

    const handleDelete  = () => {
        Swal.fire({
            title: 'Are you sure?',
            text : 'All the data related to this group will be deleted.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            customClass: {
              container : {
                zIndex : '10000 !important'
              }
            }
          }).then((result) => {
            if (result.isConfirmed) {
              const c = {
                chatId : group.chatId,
                token
              }
              ChatAPI.deleteChat(c).then(res => {
                const deletedId =  res.data;
                dispatch(IGroupAction.groupDeleted({chatId : deletedId}));
              }).catch(e => toast.error("Not able to delete."));
            }
        })
    }

    const handleOnRemove = useCallback((info) => {

      Swal.fire({
        title: 'Are you sure?',
        text : `${info?.fullName} will be removed.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        customClass: {
          container : {
            zIndex : '10000 !important'
          }
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            participants : [info.participantId],
            chatId : currGroup.chatId
          }
          ChatAPI.deleteParticipants({token:currUser.token,payload}).then(res => {
            const formatChat = formatGroupChat(res.data,currUser.id);
            setCurrGroup(formatChat);
            dispatch(IGroupAction.updateGroup({chatId : res.data.chatId, currGroup : formatChat}));
          }).catch(e => console.log(e));
          console.log(info);
        }
    })      
    },[currGroup])

    useEffect(() => {
      if(group) {
        setCurrGroup(group);
      }
    },[group]);

    return(
        <>
            <RightSideBarContainer heading={currGroup?.name} onClose={() => dispatch(ichatVariablesAction.updateRightSideBar({open: false, content : undefined}))}>
                <MDBox padding="16px" display="flex" flexDirection="column">
                    <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                        <Typography fontSize={"14px"}>{"All Participants"}</Typography>
                        <Button onClick={() => setAddParticipants(true)}>
                            <Add/> Add
                        </Button>
                    </MDBox>
                    {currGroup && <MDBox display="flex" flexDirection="column" minHeight="400px" maxHeight="calc(100% - 40px)" sx={{overflowY:'auto'}}>
                      <MDBox display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                        <Contact key={currGroup?.meParticipant?.fullName} info={currGroup?.meParticipant}/>
                        {currGroup?.meParticipant?.admin && <MDTypography variant="caption" sx={{fontWeight:"600"}} color={"info"} fontSize={"12px"}>{"Group Admin"}</MDTypography>}
                      </MDBox>
                        {currGroup.participants.map(item => {
                          return <>
                            {/* <MDBox display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center"> */}
                              <Contact actionText={currGroup?.adminId === currUser.id ? "Remove": undefined} onActionClick={handleOnRemove} key={item.fullName} info={item} />
                              {item?.admin && <MDTypography variant="caption" sx={{fontWeight:"600"}} color={"info"}  fontSize={"12px"}>{"Group Admin"}</MDTypography>}
                            {/* </MDBox> */}
                          </>
                        })}
                    </MDBox>}
                    {currGroup?.adminId === currUser.id && <MDBox mt={2} display="flex" flexDirection = "row" justifyContent="flex-end" width="100%">
                        <Button
                            onClick={handleDelete}
                            variant="outlined" color="error" sx={{color:'red!important'}}
                        >Delete group</Button>
                    </MDBox>}
                </MDBox>
            </RightSideBarContainer>
            {addParticipants && 
             <AppModal customStyle={{padding:'12px'}} rootStyle={{overflow:'hidden',height:'100%'}} heading={"Add participants"} onModalClose={() => setAddParticipants(undefined)} closeOnClick={false}>
                 <IAddParticipant group={currGroup} onParticipantsAdded={(updatedGroup) => {
                  if(updatedGroup){
                    setCurrGroup(updatedGroup);
                  }
                  setAddParticipants(undefined)
                }}/>
             </AppModal>
            }
        </>
    )
}
export default memo(IGroupDetails);