import { createSlice } from "@reduxjs/toolkit";
import { updateMessage_ } from "util/IChat/IMessageUtil";

const initialGroupChatStates = {
    conversation : {},
    groups : [],
    currentActiveGroup : undefined,
    fetched : false,
    totalUnreadDmCount : 0
};

const IGroupSlice = createSlice({
    name: "Igroup",
    initialState: initialGroupChatStates,
    reducers : {

        setTotalUnreadDmCount : (state, action) => {
            const {count} = action.payload;
            const oldCount = state.totalUnreadDmCount;
            state.totalUnreadDmCount = oldCount + count;
        },

        addGroup : (state,action) => {
            state.groups.unshift(action.payload);
        },
        concateGroup : (state, action) => {
            state.groups = state.groups.concat(action.payload);
        },
        toggleMute : (state,action) => {
            const {chatId,muted} = action.payload;
            const chatIndex = state.groups.findIndex(c => c.chatId === chatId);
            if (chatIndex !== -1) {
                state.groups[chatIndex].muted = muted;
            }
        },
        updateCurrentActiveGroup : (state,action) => {
            state.currentActiveGroup = action.payload;
        },

        addMessage: (state, action) => {
            const { chatId, messages } = action.payload;
            if (!state.conversation[chatId]) {
              state.conversation[chatId] = [];
            }
            state.conversation[chatId] = [...state.conversation[chatId], ...messages];
            const message = messages[messages.length - 1];
            const latestMessage = {
                messageId: message.messageId,
                text : message.text,
                createdAt : message.createdAt,
                type : message.type
            }
            const groupIdx = state.groups.findIndex(g => g.chatId === chatId);
            if(groupIdx !== -1){
                state.groups[groupIdx].latestMessage = latestMessage;
                state.groups[groupIdx].receivedFrom = message?.sender?.fullName;
            }
        },
        updateMessage : (state,action) => {
            const {chatId, message } = action.payload;
            
            const latestMessage = {
                messageId: message.messageId,
                text : message.text,
                createdAt : new Date(),
                type : 'text' 
            }
            // If state.conversation[chatId] undefined means, messages never fetch, in that scenario ignore message update.
            if(state.conversation[chatId]) {
                const messages = state.conversation[chatId];
                const newMessages = updateMessage_(messages,message);
                state.conversation[chatId] = [...newMessages];
            }
            const chatIndex = state.groups.findIndex(c => c.chatId === chatId);
            if (chatIndex !== -1) {
                state.groups[chatIndex].latestMessage = latestMessage;
                state.groups[chatIndex].receivedFrom = message.sender?.fullName;
            }

            if(message.senderId !== state.groups[chatIndex].meId) {
                const old = state.groups[chatIndex].unreadCount;
                if(old === 0){
                    state.totalUnreadDmCount = state.totalUnreadDmCount + 1; 
                } 
                state.groups[chatIndex].unreadCount = state.groups[chatIndex].unreadCount + 1; 
            }
        },  
        updateEvent : (state,action) => {
            const payload = action.payload;
            const chatIndex = state.groups.findIndex(c => c.chatId === payload.chatId);
            if (chatIndex !== -1) {
                if(payload.status === 'typing') {
                    const newStatus = {
                        userId : payload.userId,
                        userName : payload.userName
                    }
                    const findIfExists = state.groups[chatIndex].usersTyping?.find(itm => itm.userId === payload.userId);
                    if(!findIfExists){
                        const oldState = state.groups[chatIndex].usersTyping;
                        oldState.push(newStatus); 
                        state.groups[chatIndex].usersTyping = oldState;
                        if(state.currentActiveGroup?.chatId === payload.chatId) {
                            state.currentActiveGroup.usersTyping = oldState;
                        }
                    }
                }
                else{
                    const filtered = state.groups[chatIndex].usersTyping?.filter(itm => itm.userId !== payload.userId);
                    state.groups[chatIndex].usersTyping = filtered;
                    if(state.currentActiveGroup?.chatId === payload.chatId) {
                        state.currentActiveGroup.usersTyping = filtered;
                    }
                }
            }
        },        
        updateReplyCounts : (state,action) => {
            const {message,chatId} = action.payload;
            const chatIndex = state.groups.findIndex(c => c.chatId === chatId);
            if(chatIndex !== -1){
                const messages = state.conversation[chatId];
                const messageIdx = messages.findIndex(itm => itm.messageId === message.parentMessage.messageId);
                if(messageIdx !== -1){
                    const m = state.conversation[chatId][messageIdx];
                    const newCount = m.repliesCount;
                    if(newCount === 0 || isNaN(newCount)) {
                        // Have to put recipients as profiled
                        const profile = message?.recipients?.find(itm => itm.userId === message.senderId);
                        state.conversation[chatId][messageIdx].repliedProfiles = [profile];
                    }
                    state.conversation[chatId][messageIdx].repliesCount = newCount + 1 ;
                }
            }
        },
        setUnreadCount : (state,action) => {
            const {chatId} = action.payload;
            const chatIndex = state.groups.findIndex(c => c.chatId === chatId);
            if(chatIndex !== -1){
                const old = state.groups[chatIndex].unreadCount;
                if(old <= 1){
                    state.totalUnreadDmCount = state.totalUnreadDmCount - 1; 
                } 
                state.groups[chatIndex].unreadCount = 0;
            }
        }, 
        updateGroup : (state,action) => {
            const {chatId,currGroup} = action.payload;
            const chatIndex = state.groups.findIndex(c => c.chatId === chatId);
            if(chatIndex !== -1){
                state.groups[chatIndex] = currGroup;
            }
            state.currentActiveGroup = currGroup;
        },         
        groupDeleted : (state,action) => {
            const {chatId} = action.payload;
            const chatIndex = state.groups.findIndex(c => c.chatId === chatId);
            if(chatIndex !== -1){
                state.groups[chatIndex] = undefined;
                state.conversation[chatId] = undefined;
                state.currentActiveGroup = undefined
            }
        },
        setFetchedToTrue : (state) => {
            state.fetched = true;
        },
        reset: () => initialGroupChatStates        
    }
})

export const IGroupAction = IGroupSlice.actions;
export default IGroupSlice;