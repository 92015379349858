// useDebouncedWatch.js
import { useRef } from 'react'
import useWatch from './use-watch'

export default function useDebouncedWatch( callback, deps, delay = 1000 ) {
  const timeoutHandler = useRef(null)

  useWatch(() => {
    if (timeoutHandler.current) {
      clearTimeout(timeoutHandler.current)
    }

    timeoutHandler.current = setTimeout(() => {
      callback();
    }, delay)
    return () => {
      clearTimeout(timeoutHandler.current);
    }
  }, deps)
}