import { useCallback, useEffect } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IChatAction } from "store/IChat/Ichat-slice";
import style from "components/IChat/IChat.module.css";
import { useState } from "react";
import useDebouncedWatch from "hooks/use-debounce-watch";
import { MessageAPI } from "services/IChat/MessageAPI";
import { IGroupAction } from "store/IChat/igroup-slice";

const useIscrollToBottom = (messageGroups = {},chatId,groupChat = false,lastReadMessageId) => {

  const userId = useSelector(state => state.user.id);
  const token = useSelector(state => state.user.token);
  const icSettings = useSelector(state => state.userDetails.details.icSettings);
  const activeChat = useSelector(state => state.Ichat.currentActiveChat);
  const [messageRead,setMessageRead] = useState([]);

  const scrollRef = useRef(null);

  const dispatch = useDispatch();

  // Function to scroll to the bottom of the container
  const scrollToBottom = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      // Scrolled to bottom reduce count to zero
      if(groupChat) {
        dispatch(IGroupAction.setUnreadCount({chatId}))
      }
      else{
        dispatch(IChatAction.setUnreadCount({chatId}))
      }
    }
  },[groupChat,scrollRef]);

  //mark as read
  useDebouncedWatch(() => {
    if(activeChat) {
      const ids = messageRead.map(itm => itm.messageId);
      if(ids.length > 0) {
        const ackPayload = {
          chatId : activeChat.chatId,
          messageIds : ids,
          status : 'read',
          userId : activeChat.meId,
          senderId : messageRead[0]?.senderId
        }
        // Mark READ AS LOCAL ALSO
        dispatch(IChatAction.updateMessageAck({chatId : chatId, status : 'read',messageIds : ids}));
        dispatch(IChatAction.updateUnreadCount({chatId : activeChat.chatId,changeBy : -(ids.length)}))

        if(!icSettings) {
          MessageAPI.markMessageStatus({token,payload : ackPayload}).then(res => {
            setMessageRead([]);
          })
        }
        // Check if read recipiet is enabled only, ack
        else if(icSettings?.read_receipt?.enabledByAdmin && icSettings.read_receipt?.enabledByMe){
          MessageAPI.markMessageStatus({token,payload : ackPayload}).then(res => {
            setMessageRead([]);
          })
        }
      }
    }
  },[messageRead,activeChat]);


 // Helper function to mark a message as read
  const markMessageAsRead = (message) => {
    setMessageRead(prev => [...prev,message]);
  };  

  // Function to handle scroll events and mark messages as read
  const handleScroll = () => {
    if (scrollRef.current) {
      const containerHeight = scrollRef.current.clientHeight;
      const containerScrollTop = scrollRef.current.scrollTop;

      for(const key in messageGroups) {
        const messages = messageGroups[key];
        messages.forEach((message) => {
          const messageElement = scrollRef.current.querySelector(`[data-message-id="${message.messageId}"]`);
          if (messageElement) {
            const messageTop = messageElement.offsetTop;
            const messageHeight = messageElement.clientHeight;

            // Check if the message is visible in the container and not already marked as read
            if (messageTop >= containerScrollTop && messageTop + messageHeight <= containerScrollTop + containerHeight && (message.status !== 'read' && message.senderId !== userId)) {
              markMessageAsRead(message);
            }
          }
        });
      }
    }
  };  
  // Helper function to find the index of the last read message in the message groups
  const findLastReadMessageIndex = () => {
    let lastReadIndex = -1;

    for(const key  in messageGroups) {

      const messages  = messageGroups[key];

      const index = messages?.findIndex((message) => message.messageId === lastReadMessageId);

      if (index !== -1) {
        lastReadIndex = index;
        return lastReadIndex;
      }
    };
    return lastReadIndex;
  }
  // Scroll to the last read message or bottom on component update
  useEffect(() => {
    if (lastReadMessageId) {
      // Find the index of the last read message in the messages array
      const lastReadMessageIndex = findLastReadMessageIndex();
      
      // If the last read message is found, scroll to it
      if (lastReadMessageIndex !== -1) {
        const lastReadMessageElement = document.getElementById("message_" + lastReadMessageId);
        if (lastReadMessageElement) {
          setTimeout(() => {
            lastReadMessageElement.scrollIntoView({behavior:'smooth'});
            lastReadMessageElement.classList.add(style['message_highlight']);
          },[1000]);
        }
      } else {
        scrollToBottom();
      }
    } else {
      scrollToBottom();
    }
  }, [messageGroups, lastReadMessageId]);

  useEffect(() => {
    // Calculate which messages are in the viewport
    let t = undefined;
    if(messageGroups && Object.entries(messageGroups).length > 0){
      t = setTimeout(() => {
        for(const key  in messageGroups) {
          const messages  = messageGroups[key];
          messages.forEach((message) => {
            const messageElement = scrollRef.current.querySelector(`[data-message-id="${message.messageId}"]`);
            if (messageElement) {
              const { top, bottom } = messageElement.getBoundingClientRect();
              if (top >= 0 && bottom <= window.innerHeight) {
                // Message is fully in the viewport
                if(message.status !== 'read' && message.senderId !== userId){
                  markMessageAsRead(message);
                }
              }
            }
          });
        };
      },100);
    }
    return () => clearTimeout(t);
  },[messageGroups,scrollRef]);

    // Add scroll event listener on mount
    useEffect(() => {
      scrollRef.current.addEventListener('scroll', handleScroll);
      // Remove scroll event listener on unmount
      return () => {
        if(scrollRef.current){
          scrollRef.current.removeEventListener('scroll', handleScroll);
        }
      };
    }, [scrollRef]);

  return scrollRef;
};

export default useIscrollToBottom;