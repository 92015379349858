import { Download } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { ERRORS } from "constants/AppConstants";
import MDBox from "lib/components/MDBox";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { useState } from "react";
import { toast } from "react-toastify";
import { isValidSize } from "util/MediaUtil";

/** This is a general component that can be used to choose file from the users pc
 * @param
 * fileType : What should be file type
 * showTemplate : show download template
 * templateUrl : downloadble link for templateUrl
 * onFileSelected : when file successfully chosen
 * templateTooltipMsg : Message to show on download icon
 */
const ChooseFileGeneric = ({fileType, onFileSelected, showTemplate = false, templateUrl, templateTooltipMsg = ' Download template'}) => {
    
    const [fileErr, setFileErr] = useState('');

    const fileInputChange = (e) => {
        const f = e.target.files[0];
        if(!isValidSize(f,5)){
            toast.error(ERRORS.TELE_FILE_SIZE_ERR);
            return;
        }
        onFileSelected(f);
    }
    return(
        <>
            <MDBox display="flex" alignItems="center" justifyContent="center" flexDirection="row">
                <MDInput type="file" label="" onChange={fileInputChange} />
                {showTemplate && <><Tooltip title={templateTooltipMsg ? templateTooltipMsg : ''}>
                        <a style={{textDecoration:'none',marginLeft:'6px'}} download href={templateUrl}>
                            <Download/>
                        </a>
                    </Tooltip></>
                }       
            </MDBox>
            <MDTypography variant="subtitle2" fontWeight="regular" color="error" mt={1}>
                    <span>{fileErr}</span>
            </MDTypography>        
        </>
    )
}   
export default ChooseFileGeneric;