import { BASE_URL } from "constants/Endpoints"
import axios from "../core/axios";

export const IframeAPI = {
    saveCustomerDetails : async(data) => {
        return await axios.post(`${BASE_URL}/customer/register`,JSON.stringify(data),{
            headers : {
                "Content-Type": "application/json"
            }
        });
    }
}