import { Typography } from "@mui/material";
import {ReactComponent as SuccessSvg} from "assets/svg/success.svg";
import MDBox from "lib/components/MDBox";
const SuccessUI = ({title,desc}) => {
    return(
        <MDBox display="flex" alignItems="center" padding="24px" justifyContent="center" width="100%" flexDirection="column">
            <SuccessSvg/>
            <Typography>{title}</Typography>
            <Typography fontSize={"16px"}>{desc}</Typography>
        </MDBox>
    )
}
export default SuccessUI;