import { Avatar } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";

const UserItem = ({ image, name, email, children }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <Avatar alt={name} src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDBox ml={2} lineHeight={1} sx={{display:'flex',margin:0}}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {name}
          </MDTypography>{children}
        </MDBox>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );
export default UserItem;