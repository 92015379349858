import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState, memo } from "react";
import AppModal from "ui/AppModal";
import {Tooltip} from "@mui/material";
import MDBox from "lib/components/MDBox";

import EditFundraiserForm from 'components/Fundraiser/EditFundraiserForm';

const EditFundRaiser = ({fundraiser,onFundraiserEdited,campaign}) => {
  
  const [action, setAction] = useState();

  const handleClick = (event) => {
      setAction(true);
  }

  useEffect(() => {
    if (action === -1) {
    //   onChange();
    }
  }, [action]);
  return (
    <>
     <MDBox
        sx={{}}
        mt={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Tooltip title="Edit fundraiser">
          <EditIcon
            onClick={handleClick}
            sx={{ width: "1.2rem", height: "1.2rem"}}
            style={{ cursor: "pointer", fontSize: "40px!important" }}
          />
        </Tooltip>

      </MDBox>
      {action === true && (
        <AppModal
          rootStyle = {
            {
              "&.MuiModal-root":{
                width:'40%',
                left: '60%'
              }
            }
          }
          customStyle={{ 
            width: "100%",
            height: "100%",
          }}
          onModalClose={() => {
            setAction(false);
          }}
          heading={"Edit fundraiser"}
        >
          <EditFundraiserForm fundraiser={fundraiser} onFundraiserEdited={(res) => {
            onFundraiserEdited(res);
            setAction(false);
          }} campaign={campaign}/>
        </AppModal>
      )}
    </>
  );
};
export default memo(EditFundRaiser);
