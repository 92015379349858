import { Close } from "@mui/icons-material";
import { Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";

const RightSideBarContainer = ({heading,children,onClose}) => {
    return(
        <MDBox display="flex" flexDirection="column" height="50px" sx={{borderBottom: '1px solid #ddd'}}>
            <MDBox display="flex" justifyContent="space-between" flexDirection="column">
                <MDBox display="flex" justifyContent="space-between" padding="12px">
                    <Typography sx={{fontWeight : "600!important"}} fontSize={"16px"}>{heading}</Typography>
                    <Close sx={{cursor:'pointer'}} onClick={onClose}/>
                </MDBox>
                {children}
            </MDBox>
        </MDBox>
    )
}
export default RightSideBarContainer;