import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { useState } from 'react';
import { Button } from '@mui/material';

const CustomDateRangePicker = ({children,onCancel,onRangeApply}) => {
    
    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: null,
          key: 'selection'
        }
      ]);
    const formatDate = () => {
        onRangeApply({startDate : state[0].startDate, endDate : new Date(state[0].endDate.setDate(state[0].endDate.getDate()+1))});
    }  
    const handleSelect = (ranges) => {
        // console.log(ranges)
        setState([ranges.selection]);
        // if(isDateMoreThanCurrent(ranges.selection.startDate)){
        //     toast.error("Please select a valid date");
        //     return;
        // }
        // if(isDateMoreThanCurrent(ranges.selection.endDate)){
        //     toast.error("Please select a valid date");
        //     return;
        // }
        // selectionRange = {...selectionRange,startDate:ranges.selection.startDate, endDate:ranges.selection.endDate}
        // selectionRange.;
        // selectionRange.;
        // {
        //   selection: {
        //     startDate: [native Date Object],
        //     endDate: [native Date Object],
        //   }
        // }
    }    
    return (
        <>
            <div style={{padding:'8px'}}>            
                <DateRangePicker
                ranges={state}
                maxDate={new Date()}
                onChange={handleSelect}
            />
            </div>
            <div style={{display:'flex',flexDirection:'row'}}>
                <Button color="info" onClick={onCancel}>Cancel</Button>
                <Button onClick={formatDate}>Apply</Button>     
            </div>
        </>
    )
}
export default CustomDateRangePicker;