import { BASE_URL } from "constants/Endpoints"
import axios from "../core/axios";

export const SystemSettingsAPI = {
    setSecondFactorAuthPhoneCredentials : async({token,credentials}) => {
        return await axios.post(`${BASE_URL}/admin/settings`,JSON.stringify(credentials),{
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        })
    },
    getAdminSettings : async({token}) => {
        return await axios.get(`${BASE_URL}/admin/settings`,{
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
    },
    getAppSettings : async({token}) => {
        return await axios.get(`${BASE_URL}/app/settings`,{
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
    }
}