import { useDispatch, useSelector } from "react-redux";
import { MessageAPI } from "services/IChat/MessageAPI";
import { MessageReplyAPI } from "services/IChat/MessageReplyAPI";
import { IChatAction } from "store/IChat/Ichat-slice";
import { IThreadAction } from "store/IChat/ithread-slice";

const useIsend = () => {

    const dispatch = useDispatch(); 
    const userId = useSelector(state => state.user.id);

    const createMessage = (payload) => {
        MessageAPI.createMessage(payload).then(res => {
            //Is it new chat
            if(res.data.newChat){
                // update chat as well along with message
                // const tmpChats = updateChat(chats,res.data);
                // const newMessages = updateMessage(conversations[res.data.chatId],res.data);
                dispatch(IChatAction.updateChatAndMessage({message : res.data})); 
            }
            else{
                // update message only....
                dispatch(IChatAction.updateMessage({message : res.data, chatId : res.data.chatId}));
            }
        }).catch(e => console.log(e));
    }
    
    const sendMessage = ({payload,token}) => {
        dispatch(IChatAction.addMessage({messages : [payload],chatId : payload.chatId}))
        if(payload.newChat){
            payload = {
                ...payload,
                chatId : undefined
            }
        }
        //Call the endpoint...
        createMessage({payload,token});
    }

    const sendReply = ({payload,token}) => {
        // Dispatch for thread.....
        // Also increase count for replies count of parent message...
        dispatch(IThreadAction.addReply({parentId : payload.parentMessage.messageId,reply : payload}));
        
        MessageReplyAPI.createReply({payload,token}).then(res => {
            dispatch(IChatAction.updateReplyCounts({chatId : payload.chatId,message : payload}));
            dispatch(IThreadAction.updateReply({parentId : res.data.parentMessage.messageId,message:res.data}));
        }).catch(e => console.log(e));
    } 

    return {sendMessage,sendReply}
}
export default useIsend;