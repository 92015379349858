import { Send, WhatsApp } from "@mui/icons-material";
import { Popover, TextField } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import NotificationItem from "lib/layouts/Items/NotificationItem";
import { memo, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import AppModal from "ui/AppModal";
import moment from "moment-timezone";

const ScheduleMessage = ({onDone,onClose}) => {

    const [value, onChange] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const currentMsgView = useSelector(state => state.chat.current_message_view); 
    const currentActivePhone = useSelector(state => state.chat.current_active_phone);

    const onDateChange = (e) => {
        const d = moment(e.target.value);
        onChange(d)
    }
    const scheduleHandler = (type) => {
        setAnchorEl(null)
        if(value === '' || value === undefined) return;
        const payload = {
            scheduledAt : value,
            type
        }
        handleClose();
        onDone(payload);
    }
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <AppModal closeOnClick={false} onModalClose={() => onClose()} heading={"Select date and time"}>
            <MDBox display="flex" alignItems="center" justifyContent="center">
                <TextField
                    id="datetime-local"
                    label="Schedule Message"
                    type="datetime-local"
                    defaultValue={value}
                    onChange={onDateChange}
                    sx={{ width: 250 }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </MDBox>
            <MDBox style={{marginTop:'12px'}} display="flex" alignItems="center" justifyContent="center">
            <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}
                anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
                }}
                sx={{
                "& .MuiPopover-paper": {
                    backgroundColor: "white!important",
                    width: "200px",
                },
                }}
            >
                <NotificationItem icon={<Send/>} onClick={() => scheduleHandler("SMS")} title={"SMS"}></NotificationItem>
                <NotificationItem icon={<WhatsApp/>} onClick={() => {
                if(currentActivePhone.whatsappCred){
                    scheduleHandler("WHATSAPP");
                }
                else{
                    toast.error("This number does not have any whatsapp account. Please contact the administrator.")
                }
                }} title={"WHATSAPP"}></NotificationItem>
            </Popover>                
                <MDButton onClick={(e) =>setAnchorEl(e.currentTarget)} size={'medium'} color='info' variant="outlined">
                    schedule Send
                </MDButton>
            </MDBox>
        </AppModal>
    )
}
export default memo(ScheduleMessage);