import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { getAllCarrier } from "api/carrier";
import { SelectStyle } from "components/ImportChat/MappedColumns";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ShowCarrier = ({onCarrierSelected,customStyle,labelText = undefined,selected=undefined}) => {

    const {sendRequest, data, status , error} = useHttp(getAllCarrier, false);
    const token = useSelector(state => state.user.token);
    const [currSelected,setCurrSelected] = useState();
    const onChange = (e) => {
        onCarrierSelected(e.target.value);
        setCurrSelected(e.target.value);    
    }

    useEffect(() => {
       sendRequest(token);
    },[])

    useEffect(() => {
        if(selected) {
            setCurrSelected(selected);
        }
    },[selected]);

    return (
        <MDBox sx={{
            width:'100%',
            ...customStyle
        }}>
            <FormControl fullWidth>
                <InputLabel id="carrier">{labelText ? labelText: 'Choose carrier'}</InputLabel>
                {status === 'completed' && <SelectStyle
                    labelId="carrier"
                    id="carrier-select"
                    sx={{
                        height:'40px',
                        // marginRight:'8px'
                    }}
                    label={labelText ? labelText : 'Choose carrier'}
                    onChange={onChange}
                    value={currSelected ? currSelected : ''}
                >
                    {data?.length === 0 ? <MenuItem value=''>No carrier available</MenuItem> : data?.map(item => <MenuItem key={item.id} value={item.id}>{item.carrier_name}</MenuItem>)}
                </SelectStyle>}
                <Typography color={"info"} margin={'8px'} fontSize={'12px'}>You can add new carrier from the external API section.</Typography>
            </FormControl>
        </MDBox>         
    )
}
export default memo(ShowCarrier);