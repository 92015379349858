import { Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import {ReactComponent as SuccessSvg} from "assets/svg/success.svg";
import {ReactComponent as ErrorSvg} from "assets/svg/error.svg";
const CustomerResponse = ({response}) => {

    return(
        <>
            {response?.errors?.map(item => {
                const ui = <MDBox display="flex" flexDirection="column">
                    <Typography>{item?.type}</Typography>
                    <MDBox display="flex" mt={1} justifyContent="flex-start" p={2} alignItems="center">
                        {item?.status ? <SuccessSvg height={'28px'} width={'28px'}/> : <ErrorSvg height={'28px'} width={'28px'}/>}
                        <Typography m={2} fontSize={"14px"}>{item?.message}</Typography>
                    </MDBox>
                </MDBox>
                return ui;
            })}
        </>
    )
}
export default CustomerResponse;