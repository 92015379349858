/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "lib/components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "lib/layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "lib/layouts/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "lib/layouts/Cards/StatisticsCards/ComplexStatisticsCard";
import { useSelector } from "react-redux";
import useHttp from "hooks/use-http";
import { getTotalUserCount } from "api/user";
import { useEffect } from "react";
import { getTotalPhoneCount } from "api/phoneCredentials";

function Dashboard() {

  const token = useSelector(state => state.user.token);
  const company = useSelector(state => state.user.company);
  const userId = useSelector(state => state.user.id);
  const {sendRequest : totalUserCountFn , data : totalUser} = useHttp(getTotalUserCount,false);
  const {sendRequest : totalPhoneCountFn , data : totalPhone} = useHttp(getTotalPhoneCount,false);

  useEffect(() => {
    totalUserCountFn({token,companyId : company?.companyId,userId});
    totalPhoneCountFn({token,companyId : company?.companyId,userId});
  },[])
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person"
                title="Users"
                count={totalUser ? totalUser : 0}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="phone"
                title="Phone numbers"
                count={totalPhone ? totalPhone : 0}
              />
            </MDBox>
          </Grid> 
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Companies"
                count="34k"
              />
            </MDBox>
          </Grid>                   
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
