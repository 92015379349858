export const DEFAULT_DAYS_SETTINGS = [
    {name : "SUNDAY",enabled : false, schedule : [{from:null,to:null}]},
    {name : "MONDAY",enabled : false, schedule : [{from:null,to:null}]},
    {name : "TUESDAY",enabled : false, schedule : [{from:null,to:null}]},
    {name : "WEDNESDAY",enabled : false, schedule : [{from:null,to:null}]},
    {name : "THURSDAY",enabled : false, schedule : [{from:null,to:null}]},
    {name : "FRIDAY",enabled : false, schedule : [{from:null,to:null}]},
    {name : "SATURDAY",enabled : false, schedule : [{from:null,to:null}]},   
]

export const DEFAULT_CUSTOM_DATE = {
    from : null,
    to : null
}

/**
 * {
    "id": "65db2f3548b27118e35ed453",
    "companyId": 1,
    "companyWide": true,
    "phoneSettings": [],
    "companyWideSettings": {
        "scheduleType": "weekly",
        "days": [
            {
                "name": "SUNDAY",
                "enabled": true,
                "schedule": [
                    {
                        "to": null,
                        "from": null
                    }
                ]
            },
            {
                "name": "MONDAY",
                "enabled": false,
                "schedule": [
                    {
                        "to": null,
                        "from": null
                    }
                ]
            },
            {
                "name": "TUESDAY",
                "enabled": false,
                "schedule": [
                    {
                        "to": null,
                        "from": null
                    }
                ]
            },
            {
                "name": "WEDNESDAY",
                "enabled": false,
                "schedule": [
                    {
                        "to": null,
                        "from": null
                    }
                ]
            },
            {
                "name": "THURSDAY",
                "enabled": false,
                "schedule": [
                    {
                        "to": null,
                        "from": null
                    }
                ]
            },
            {
                "name": "FRIDAY",
                "enabled": false,
                "schedule": [
                    {
                        "to": null,
                        "from": null
                    }
                ]
            },
            {
                "name": "SATURDAY",
                "enabled": false,
                "schedule": [
                    {
                        "to": null,
                        "from": null
                    }
                ]
            }
        ],
        "customDate": {
            "to": null,
            "from": null
        }
    },
    "zoneId": "Asia/Calcutta"
}
 * 
 */