import { useState } from "react";
import MDBox from "lib/components/MDBox";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Divider,
  Menu,
  Paper,
  Tooltip,
} from "@mui/material";
import AddIndividualFundRaiser from "./AddIndividualFundRaiser";
import AddBulkFundraiser from "./AddBulkFundraiser";
import NotificationItem from "lib/layouts/Items/NotificationItem";
import { Upload } from "@mui/icons-material";

const CREATE_TYPE = {
  INDIVIDUAL : 'individual',
  BULK : 'bulk'
}
const AddFundRaiser = ({campaign,onFundraiserCreated,onFundraiserCreatedBulk}) => {

  const [action, setAction] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
 
  return (
    <>
      <MDBox
        sx={{}}
        mt={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Tooltip title="Add fundraiser">
          <AddCircleIcon
            onClick={handleClick}
            sx={{ width: "1.2rem", height: "1.2rem"}}
            style={{ cursor: "pointer", fontSize: "40px!important" }}
          />
        </Tooltip>
      </MDBox>
      <Paper>
        <Menu
          onClose={handleClose}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          sx={{
            "& .MuiPopover-paper": {
              backgroundColor: "white!important",
              display: "flex",
              justifyContent: "center",
            },
          }}
        >
          <NotificationItem onClick={() => setAction(CREATE_TYPE.INDIVIDUAL)}  icon={<AccountCircleIcon/>} title={"Create Fundraiser"}></NotificationItem>
          <Divider variant="middle" />
          <NotificationItem onClick={() => setAction(CREATE_TYPE.BULK)} title={"Upload fundraisers"} icon={<Upload/>}></NotificationItem>
        </Menu>
      </Paper>
      {action === CREATE_TYPE.INDIVIDUAL && <AddIndividualFundRaiser campaign={campaign} onChange={() => setAction('')} onFundraiserCreated = {(res) => {
        setAction('');
        onFundraiserCreated(res);
        handleClose()}}/>}
      {action === CREATE_TYPE.BULK && <AddBulkFundraiser campaign={campaign} onCancel={() => setAction('')} onUploaded={(res) => {
        setAction('');
        onFundraiserCreatedBulk(res);
        handleClose();
        }} />}
    </>
  );
};
export default AddFundRaiser;
