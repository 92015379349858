import { Avatar, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import Moment from "react-moment";
import React from "react";
import parse from 'html-react-parser'
import { useSelector } from "react-redux";

const ThreadMessage = ({message}) => {

    const timezone = useSelector(state => state.userDetails?.details?.userSettings?.timezone)

    return(
        <MDBox sx={{paddingLeft:'6%',paddingRight:'6px'}} key={message?.sender?.fullName} component="li" flexDirection="column" display="flex" alignItems="center" mb={1}>
            <MDBox mr={2} display="flex" justifyContent="space-start" width="100%" alignItems="center">
                <Avatar sx={{
                    // "&.MuiAvatar-root" : {
                    //     background : '#f2f2f2'
                    // }
                }} src={message?.sender?.profilePic} alt={message?.sender?.fullName} shadow="md" />
                <MDBox marginLeft="12px" display="flex" flexDirection="row">
                    <MDTypography fontSize={"14px"} sx={{fontWeight:'600'}}>{message?.sender?.fullName}</MDTypography>
                    <MDTypography fontSize={"12px"} sx={{marginLeft:'12px'}}><Moment tz={timezone} fromNow>{message?.createdAt}</Moment></MDTypography>
                </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" justifyContent="flex-start" width="100%">
                <MDBox width="40px"></MDBox>
                <MDBox marginLeft="12px">
                    <Typography variant="subtitle1" fontSize={"14px"}>
                        { message && parse(message.text)}
                    </Typography>
                </MDBox>
            </MDBox>
        </MDBox>
    )
}
export default React.memo(ThreadMessage);