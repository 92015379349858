import { Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { useEffect, useState } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import Table from "ui/Table";

const templateColumns = [
    { Header: "Template Name", accessor: "name",align: "center" },
    { Header: "Language", accessor: "lang",align: "center" },
    {Header : "Original category",accessor : 'org_category',align : 'center'},
    {Header : "Current category",accessor : 'current_category',align : 'center'},
    { Header: "Status", accessor: "status", },
    { Header: "Rejection Reason", accessor: "reason",align: "center"},
    { Header: "Created At", accessor: "created_at", align: "center"},
    // { Header: "Actions", accessor: "action",align: "center"},
  ]
const WTemplates = ({data}) => {
    
    const [rows,setRows] = useState([]);
    const timezone = useSelector(state => state.userDetails?.details?.userSettings?.timezone)
    const formatRows = (d) => {
        const tempRow = d.map(item => {
            return{
                name : item.templateName,
                lang : 'ENGLISH',
                // org_category : ,
                // current_category : ,
                status : item.status,
                reason : 'N/A',
                created_at : <Moment tz={timezone} format="L">{item.createdAt}</Moment>,
                // action : <ViewTemplate template={item.template} />
            }
        })
        setRows(tempRow);
    }

    useEffect(() => {
        if(data){
            formatRows(data);
        }
    },[data])

    return (
        <MDBox width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography>Templates</Typography>
            <Table
                heading={"Templates"}
                rows={rows}
                columns={templateColumns}
                canSearch={true}
                showBanner={false}
            />
        </MDBox>
    )
}
export default WTemplates;