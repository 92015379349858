import { Avatar, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useSelector } from "react-redux";

const IChatItem = ({onSelected,chat}) => {

    const userId = useSelector(state => state.user.id);

    const [selected,setSelected] = useState(false);
    const [recipient,setRecipient] = useState();
    
   useEffect(() => {
        if(chat.groupChat) {
            setRecipient({
                name : chat.chatName,
                profilePic : chat?.profilePic,
                chatId : chat.chatId
            })
        }
        else{
            const findRecipient = chat.participants.find(itm => itm.userId !== userId);
            setRecipient({
                name : findRecipient?.user.fullName,
                profilePic : findRecipient?.user.profilePic,
                chatId : chat.chatId
            })
        }
    },[chat]);

    return(
        <MDBox key={chat.chatId} component="li" display="flex" alignItems="center" py={1} mb={1}>
            <MDBox mr={2}>
                <Avatar src={recipient?.profilePic} alt={recipient?.fullName} shadow="md"  sx={{ width: 42, height: 42 }} />
            </MDBox>
            <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center" width="100%">
                <MDTypography variant="button" fontWeight="medium">
                {recipient?.name}
                </MDTypography>

                {/* <MDTypography variant="caption" color="text">
                {user?.email}
                </MDTypography> */}
            </MDBox>
            <MDBox sx={{cursor:'pointer'}} display="flex" justifyContent="flex-end" width="100%">
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={selected} onChange={(e) => {
                    setSelected(e.target.checked);
                    onSelected({
                        checked: e.target.checked,
                        chat : recipient
                    });
                }}  sx={{color:'#1A73E8'}} />} label="" />
                </FormGroup>
            </MDBox>
        </MDBox>
    )
}
export default IChatItem;