import ISearch from "./ISearch";
import RightSideBarContainer from "components/CommonUI/RightSideBarContainer";

const GlobalSearch = ({onSearchClose}) => {

    return(
        <RightSideBarContainer heading={"Search messages"} onClose={onSearchClose}>
            <ISearch onSearchClose={onSearchClose}/>
        </RightSideBarContainer>
    )
}
export default GlobalSearch;