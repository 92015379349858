import React, { useEffect, useState } from 'react'
import Table from "ui/Table";
import {messageColumns} from "constants/Columns"
import MDBox from 'lib/components/MDBox';
import { Delete, Edit, Save } from '@mui/icons-material';
import { TextField, Tooltip, Typography } from '@mui/material';
import AppModal from 'ui/AppModal';
import EditCampaignMessage from './EditCampaignMessage';
import useHttp from 'hooks/use-http';
import { deleteCampaignMsg } from 'api/campaign';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import MDTypography from 'lib/components/MDTypography';

const formatRow = (messages,onEdit,onDelete) => {

  return messages?.map((msg, index) => {
    const currentMessage = {
      title: <Typography key={msg?.title + index}>{msg?.title}</Typography>,
      percentage: <Typography key={msg?.percentage + index}>{msg?.percentage}</Typography>,
      message: <Typography width={"400px"} key={msg?.message + index}>{msg?.message}</Typography>,
      action: (
        <>
          <MDBox key={index}>
          <Tooltip title="Edit this">
            <Edit onClick={onEdit.bind(null,index)} sx={{cursor: 'pointer', height:'1.2rem', width: '1.2rem', marginRight:'8px'}}/>
          </Tooltip>
          <Tooltip title="Delete this entry">
            <Delete onClick={onDelete.bind(null,msg.id)} sx={{cursor: 'pointer', height:'1.2rem', width: '1.2rem'}}/>
          </Tooltip>
          </MDBox>
        </>
      )
    }
    return currentMessage;
  })
}
const MessageTable = ({messages,campaignId}) => {
  
  const [rows, setRows] = useState([]);
  const [currMessages, setCurrentMessages] = useState([]);
  const [editMsg, setEditMsg] = useState(undefined)
  const token = useSelector(state => state.user.token);
  const {sendRequest, status, data, error } = useHttp(deleteCampaignMsg,false);

  const onEdit = (idx) => {
    setEditMsg(currMessages?.[idx]);
  }
  const onEdited = (res) => {
    const updatedMsg = currMessages?.map(item => {
      if(res.id === item.id){
        return res;
      }
      else return item;
    });
    setCurrentMessages(updatedMsg);
    setEditMsg(undefined);
  }
  
  const onDelete = (id) => {
    const itemToDelete = currMessages.find(item => item.id === id);
    sendRequest({token,payload : itemToDelete});
  }

  useEffect(() => {
    if(currMessages){
      const newCurr = currMessages.sort((a,b) => a.percentage-b.percentage);
      const tmp = formatRow(newCurr,onEdit,onDelete);
      setRows(tmp);
    }
  },[currMessages])
  
  useEffect(() => {
    if(messages)
      setCurrentMessages(messages);
  },[messages]);

  useEffect(() => {
    if(status === 'completed'){
      if(data){
        const filtered = currMessages?.filter(item => item.id !== data.id);
        setCurrentMessages(filtered);
      }
      else{
        toast.error("Something went wrong.");
      }
    }
  },[status, data ,error])
  return (
    <>
      <MDTypography variant="h6" color="dark" sx={{marginBottom : '-42px',marginLeft : '18px'}}>
        {"Messages"}
      </MDTypography>
      <Table
        heading={"Messages"}
        showBanner={false}
        columns={messageColumns ? messageColumns : []}
        rows={rows}
      />
      {editMsg && (
        <AppModal 
          heading={" "}
          rootStyle = {
            {
              "&.MuiModal-root":{
                width:'30%',
                left: '70%'
              }
            }
          }
          customStyle={{ 
              width: "100%",
              height: "100%",
          }}       
          onModalClose={() => setEditMsg(undefined)}
        >
          <EditCampaignMessage
            campaignId={campaignId}
            message={editMsg}
            onEdited={onEdited}
          />
        </AppModal>
      )}
    </>
  );
}

export default MessageTable