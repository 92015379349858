import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useCallback } from "react";
import useItyping from "hooks/IChat/use-ityping";
import { useEffect } from "react";
import { USER_QUEUE } from "constants/WsConstants";
import useIgroupsend from "hooks/IChat/use-igroup-send";
import MDBox from "lib/components/MDBox";
import IChatFooter from "../IChatFooter";
import MDTypography from "lib/components/MDTypography";
import { ReactComponent as TypingSmallSvg } from "assets/svg/typing-small.svg";

const IGroupChatFooterWrapper = ({ messageState, setMessageState }) => {

    const currentActiveGroup = useSelector(state => state.Igroup.currentActiveGroup);
    const stompClient = useSelector(state => state.app.stompClient);
    const token = useSelector(state => state.user.token);
    const currentUser = useSelector(state => state.user);
    const mounted = useRef(false);
    //custom HOOK..
    const { sendGroupMessage: sendMessage } = useIgroupsend();

    const { isTyping, startTyping, stopTyping } = useItyping();

    const buildPayload = useCallback(() => {
        return {
            type: 'typing',
            chatId: currentActiveGroup.chatId,
            status: 'typing',
            groupChat: true,
        }
    }, [currentActiveGroup])

    const onMessageSend = (payload) => {
        const newPayload = { token, payload };
        sendMessage(newPayload);
    }

    const handleOnTyping = (payload) => {
        if (payload === 'START_TYPING') {
            startTyping();
        }
        else {
            stopTyping()
        }
    }

    useEffect(() => {
        // inform the end user....
        if (!mounted.current) {
            mounted.current = true;
            return;
        }
        console.log("Broadcasting typing status....")
        const payload = buildPayload();
        if (!isTyping) {
            payload.status = 'stopped';
        }
        for (let i = 0; i < currentActiveGroup.participants.length; i++) {
            const participant = currentActiveGroup.participants[i];
            if (participant.userId !== currentUser.id) {
                payload.userName = currentUser.name;
                payload.userId = currentUser.id;
                stompClient?.send(USER_QUEUE(participant.userId), {}, JSON.stringify(payload));
            }

        }

    }, [isTyping]);

    return (
        <MDBox sx={{ position: 'relative' }}>
            {/* {currentActiveChat?.iEvent && currentActiveChat?.iEvent.type === WEBSOCKET_EVENT_TYPE.USER_TYPING &&  */}
            <MDBox display="flex" sx={{ position: 'absolute', bottom: "100%", width: "calc(100% - 140px)",justifyContent:'center', zIndex: 99999, height: "18px" }} alignItems="center">
                {currentActiveGroup?.usersTyping.map((itm, indx) => <MDTypography fontSize={"14px"} color={"info"} sx={{ marginRight: indx === currentActiveGroup?.usersTyping?.length - 1 ? '-12px' : '' }}>
                    {itm.userName}
                    {(indx !== currentActiveGroup?.usersTyping?.length - 1) && <span>,&nbsp;</span>}
                </MDTypography>)}
                {currentActiveGroup?.usersTyping?.length > 0 && <TypingSmallSvg />}
            </MDBox>
            {/* } */}
            <IChatFooter key={"group_"}
                messageState={messageState}
                setMessageState={setMessageState} onMessageSend={onMessageSend} onTyping={handleOnTyping} activeChat={currentActiveGroup} />
        </MDBox>
    )
}
export default React.memo(IGroupChatFooterWrapper);