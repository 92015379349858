import { Avatar, AvatarGroup, Button, List, ListItem, Typography } from "@mui/material";
import parse from 'html-react-parser'
import { MessageText } from "pages/Chat/ChatStyles";
import { Message } from "pages/Chat/ChatStyles";
import { LeftMessageBubble } from "pages/Chat/ChatStyles";
import { DateTimeInfo } from "pages/Chat/ChatStyles";
import MDTypography from "lib/components/MDTypography";
import Moment from "react-moment";
import React from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import MDBox from "lib/components/MDBox";
import { useState } from "react";
import IHover from "../IHover";
import AppModal from "ui/AppModal";
import MessageForward from "../MessageForward";
import { useSelector } from "react-redux";

const GroupInboundMessage = ({message,onRepliesClick}) => {
    const [showoptions,setShowOptions] = useState(false);
    const [forwardModal,setForwardModal] = useState(false);
    const [targetElement,setTargetElement] = useState(undefined);

    const timezone = useSelector(state => state.userDetails?.details?.userSettings?.timezone)

    const handleOptionSelection = (option) => {
        if(option === 'reply'){
          onRepliesClick(message);  
          onClose();
        }
        else if(option === 'forward') {
            setForwardModal(true);
            onClose();
        }          
    }

    const onClose = () => {
        setTargetElement(undefined);
        setShowOptions(false);
    }
    
    return(
        <MDBox 
            justifyContent="space-between"
            alignItems="center"
            height="auto"
            spacing="10px"
            flexDirection="column"
            id={"message_" + message?.messageId}
            data-message-id={message.messageId}
            // marginBottom="6px"
            key={"msg_container_" + message?.messageId}
        >
            <MDBox key={"imsg_div" + message?.messageId} style={{ marginTop: "2px",width:"100%" }}>
                <MDBox display="flex" justifyContent="flex-start" alignItems="center" sx={{marginTop:'8px',marginBottom:'2px'}}>
                    <Avatar alt={message?.sender?.fullName} src={message?.sender?.profilePic} sx={{ width: 24, height: 24,marginRight:'8px' }}/>
                    <Typography fontSize={"12px"}>{message?.sender?.fullName}</Typography>
                    <DateTimeInfo className="left-date-info" style={{marginLeft:'8px'}}>
                    <MDTypography variant="button" fontWeight="light" fontSize={"12px"}><Moment tz={timezone} fromNow>{message?.createdAt}</Moment> (<Moment tz={timezone} format="LT">{message?.createdAt}</Moment>, <Moment tz={timezone} format="MMM D, YYYY">{message?.createdAt}</Moment>)</MDTypography>    
                    </DateTimeInfo>                
                </MDBox>
                <Message key={"msg" + message?.id} className="left-msg">
                    <LeftMessageBubble 
                     onMouseEnter={() => setShowOptions(true)} 
                     onMouseLeave={() => setShowOptions(false)}
                     key={"msg_img" + message?.messageId}
                    >
                    <MDBox display="flex" alignItems="center" flexDirection="column">
                        <MessageText attachmentWithText={(message?.messageAttchement && message?.text)}>
                            {message?.text && parse(message?.text)}
                        </MessageText>
                        <MDBox sx={{position:'absolute',top:0,right:2}}>
                            {showoptions &&
                                <>
                                    <KeyboardArrowDown onClick={e => setTargetElement(e.currentTarget)} sx={{width:'1.5em',height:'1.5em',cursor:'pointer'}}/>
                                    <IHover onClose={() => {setTargetElement(undefined);setShowOptions(false)}} target={targetElement}>
                                        <List>
                                            <ListItem 
                                                onClick={() => handleOptionSelection('reply')}
                                                sx={{
                                                fontSize:'14px',
                                                cursor:'pointer',
                                                width : '100%'
                                                }}>
                                                <span style={{fontSize:'14px !important',fontWeight:'400'}}>{message?.repliesCount > 0 ? 'Threads' : 'Start thread'}</span>
                                            </ListItem>

                                            <ListItem 
                                                onClick={() => handleOptionSelection('forward')}
                                                sx={{
                                                fontSize:'14px',
                                                cursor:'pointer',
                                                width : '100%'
                                                }}>
                                                <span style={{fontSize:'14px !important',fontWeight:'400'}}>{'Forward'}</span>
                                            </ListItem>
                                        </List>
                                    </IHover>
                                </>
                            }
                        </MDBox>
                    </MDBox>
                    </LeftMessageBubble>                
                </Message>
            </MDBox>
            {message?.repliesCount > 0 && <MDBox display="flex" justifyContent="flex-end" sx={{maxWidth: "270px"}}>
            <AvatarGroup max={3}>
              {message?.repliedProfiles?.map(itm => <Avatar key={itm?.userId} alt={itm?.fullName} src={itm?.profilePic} sx={{ width: 18, height: 18 }}/>)}                                                       
            </AvatarGroup>            
            <Button onClick={() =>  onRepliesClick(message)} sx={{textTransform:'unset',padding:'unset',minHeight:'unset'}}>{message?.repliesCount} {message?.repliesCount === 1 ? 'reply' : 'replies'}</Button> 
            <MDTypography variant="button" fontWeight="light" fontSize={"12px"}>Last reply <Moment fromNow tz={timezone}>{message?.repliedProfiles[0]?.time}</Moment></MDTypography>                           
          </MDBox>} 
          {forwardModal && 
            <AppModal heading={"Forward To"} onModalClose={() => setForwardModal(false)} closeOnClick={false}>
                <MessageForward message={message} setForwardModal={setForwardModal} />
            </AppModal>
          }                    
        </MDBox>
    )
}
export default React.memo(GroupInboundMessage);