import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RecipientAPI } from "services/Chat/RecipientAPI";
import { chatAction } from "store/chat-slice";
import { chatVariablesAction } from "store/chat-variables-slice";
import { loaderAction } from "store/loader-slice";
import { getRecipientMap,getCheckedRecipientMap } from "util/RecipientUtil";
import useHttp from "./use-http";
import { AddRecipient } from "api/chatNavBar";
import { toast } from "react-toastify";
import { isRecipientAlreadyAdded } from "util/UtilityFunctions";
import { VIEWS } from "constants/ChatConstants";
import { UserSettingsAPI } from "services/UserSettingsAPI";
import { ChatAPI } from "services/Chat/ChatAPI";
import { getAllCheckedRecipientMap } from "util/RecipientUtil";

const useRecipient = () => {

    const dispatch = useDispatch();
    const token = useSelector(state => state.user.token);
    const userId = useSelector(state => state.user.id);
    const currentActiveView = useSelector(state => state.chat.current_active_view);
    const currenActivePhone = useSelector(state => state.chat.current_active_phone);
    const allConversationSelected = useSelector(state => state.chatVariables.allConversationSelected);
    // const isMergeView = useSelector(state => state.app.mergeViewActive);
    const storedRecipientList = useSelector(state => state.chat[currentActiveView]);
    const activeCheckbox = useSelector(state => state.chatVariables.activeCheckbox);
    const alreadyFetched = useSelector(state => state.chat[currentActiveView]?.fetched);
    const {sendRequest : addRecipientFn , status, data , error} = useHttp(AddRecipient,false)

    ////// Load more recipient => pagination:::
    const fetchMoreRecipient = () => {
        if(currentActiveView === VIEWS.MERGED_VIEW){
            getMergedView({ userId:userId, token,pageNo: storedRecipientList.currentPage,pageSize:60 })
        }
        else if(currentActiveView === VIEWS.NORMAL_VIEW) {
            getContactedRecipient({ phoneId: currenActivePhone.phoneId, token,pageNo: storedRecipientList.currentPage ? storedRecipientList.currentPage: 0,pageSize:60 })
        }
        else if(currentActiveView === VIEWS.UNREAD_VIEW){
            getContactedRecipientHavingUnreadMsg({ phoneId: currenActivePhone.phoneId, token,pageNo: storedRecipientList.currentPage ? storedRecipientList.currentPage: 0,pageSize:60, userId })
        }
    }

    const addRecipient = useCallback((recipientToAdd,code) => {
        recipientToAdd = recipientToAdd.replace(/\D/g, "");
        if(!(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/).test(recipientToAdd)){
            toast.error("Number is not valid.")
            return;
        }
        const val = {
            "phone_id" : currenActivePhone.phoneId,
            "recipient_phone" : recipientToAdd,"token" : 
            token,
            "company_id" : currenActivePhone?.company?.company_id,
            "country_code" : code
        }
        addRecipientFn(val);

    },[currenActivePhone])

    const setRecipientListMap = (recipients) => {
        if(recipients && recipients.length > 0){
          const currMap = allConversationSelected?getAllCheckedRecipientMap(recipients):getCheckedRecipientMap(recipients,activeCheckbox);
          dispatch(chatVariablesAction.setActiveCheckbox(currMap));
        }
    }

    const getContactsHaveScheduleMsg = (obj) => {
        ChatAPI.getContactedRecipientHaveScheduledMsg(obj)
        .then(res => {
            dispatch(chatVariablesAction.setRecipientContainerLoader(false));
            if(res.data){
                const payload = {
                    view : currentActiveView,
                    recipients : res.data.recipients,
                    currentPage : res.data.currentPage,
                    totalPages : res.data.totalPages,
                    phoneId : res.data.phoneId
                }
                if(res.data.currentPage === 0){
                    dispatch(chatAction.setPhoneRecipients(payload));
                }
                else{
                    /// Add more recipient.....
                    dispatch(chatAction.concatRecipients(payload));
                }
            }
        }).catch(err => {
            dispatch(chatVariablesAction.setRecipientContainerLoader(false));
            console.log(err);
        })
    }

    const getContactedRecipient = (obj) => {
        RecipientAPI.getContactedRecipient(obj)
            .then(res => {
                dispatch(chatVariablesAction.setRecipientContainerLoader(false));
                if(res.data){
                    const payload = {
                        view : currentActiveView,
                        recipients : res.data.recipients,
                        currentPage : res.data.currentPage,
                        totalPages : res.data.totalPages,
                        phoneId: res.data.phoneId
                    }
                    if(res.data.currentPage === 0){
                        console.log(payload)
                        dispatch(chatAction.setPhoneRecipients(payload));
                        dispatch(chatAction.setTotalUnreadCount({ count: res.data.totalUnreadCount }));
                    }
                    else{
                        /// Add more recipient.....
                        dispatch(chatAction.concatRecipients(payload));
                    }
                }
            }).catch(err => {
                dispatch(chatVariablesAction.setRecipientContainerLoader(false));
                console.log(err);
            })
    }

    const getHippaMsgs = (obj) => {
        ChatAPI.getContactedRecipientHaveHipaaWaiting(obj)
        .then(res => {
            if(res.data){
                const payload = {
                    view : currentActiveView,
                    recipients : res.data.recipients,
                    currentPage : res.data.currentPage,
                    totalPages : res.data.totalPages,
                    phoneId: res.data.phoneId
                }
                if(res.data.currentPage === 0){
                    dispatch(chatAction.setPhoneRecipients(payload));
                }
                else{
                    /// Add more recipient.....
                    dispatch(chatAction.concatRecipients(payload));
                }
            }
        }).catch(err => {
            console.log(err);
        })        
    }

    const getContactedRecipientHavingUnreadMsg = (obj) => {
        RecipientAPI.getContactedRecipientHavingUnreadMsg(obj)
        .then(res => {
            dispatch(chatVariablesAction.setRecipientContainerLoader(false));
            if(res.data){
                const payload = {
                    view : currentActiveView,
                    recipients : res.data.recipients,
                    currentPage : res.data.currentPage,
                    totalPages : res.data.totalPages
                }
                if(res.data.currentPage === 0){
                    dispatch(chatAction.setPhoneRecipients(payload));
                }
                else{
                    /// Add more recipient.....
                    dispatch(chatAction.concatRecipients(payload));
                }
            }
        }).catch(err => {
            dispatch(chatVariablesAction.setRecipientContainerLoader(false));
            console.log(err);
        })
    }

    const getMergedView = (obj) => {
        dispatch(loaderAction.show());
        RecipientAPI.getMergedRecipient(obj).then(res => {
            dispatch(chatVariablesAction.setRecipientContainerLoader(false));
            if(res.data){
                const payload = {
                    view : currentActiveView,
                    recipients : res.data.recipients,
                    currentPage : res.data.currentPage,
                    totalPages : res.data.totalPages,
                    phoneId : -100000
                }
                if(res.data.currentPage === 0){
                    dispatch(chatAction.setPhoneRecipients(payload));
                    dispatch(chatAction.setTotalUnreadCount({ count: res.data.totalUnreadCount }));
                }
                else{
                    /// Add more recipient.....
                    dispatch(chatAction.concatRecipients(payload));
                }
            }
            dispatch(loaderAction.hide());
        }).catch(err => {
            dispatch(chatVariablesAction.setRecipientContainerLoader(false));
        })
    }
    const reloadRecipients = useCallback(() => {
        if(currenActivePhone && currentActiveView){
            getContactedRecipient({phoneId :currenActivePhone.phoneId, token,userId});
        }
    },[currenActivePhone,currentActiveView,userId]);

    useEffect(() => {
        //if group view don't do anthing
        if(currentActiveView !== VIEWS.GROUP_VIEW) {
            if(currenActivePhone && storedRecipientList?.currentPage > 0)
                fetchMoreRecipient();
        }
    },[storedRecipientList?.currentPage,currentActiveView,currenActivePhone,userId])    

    useEffect(() => {       
        if(storedRecipientList)
        {
            setRecipientListMap(storedRecipientList.recipients);
        }
    },[storedRecipientList]);

    useEffect(() => {
        if(currenActivePhone && userId) {
            //if recipient data is already fetch don't call the endpoint
            if(currentActiveView === VIEWS.NORMAL_VIEW && !alreadyFetched.includes(currenActivePhone.phoneId)) {
                dispatch(chatVariablesAction.setRecipientContainerLoader(true));
                getContactedRecipient({phoneId : currenActivePhone.phoneId,token,userId});
            }
            else if(currentActiveView === VIEWS.MERGED_VIEW && !alreadyFetched.includes(currenActivePhone.phoneId)){
                dispatch(chatVariablesAction.setRecipientContainerLoader(true));
                getMergedView({userId,token})
            }
            else if(currentActiveView === VIEWS.UNREAD_VIEW && !alreadyFetched.includes(currenActivePhone.phoneId)) {
                dispatch(chatVariablesAction.setRecipientContainerLoader(true));
                getContactedRecipientHavingUnreadMsg({phoneId : currenActivePhone.phoneId,token,userId});
            }
            else if(currentActiveView === VIEWS.HIPAA_VIEW) {
                getHippaMsgs({phoneId : currenActivePhone.phoneId,token});
            }
            else if(currentActiveView === VIEWS.SCHEDULED_VIEW){
                getContactsHaveScheduleMsg({phoneId : currenActivePhone.phoneId,token,userId});
            }
        }
    },[currentActiveView,currenActivePhone,userId])

    useEffect(() => {
        if(currenActivePhone) {
            try{
                const payload = {
                    lastActivePhone : currenActivePhone,
                    userId : userId
                }
                UserSettingsAPI.setUserCache({payload,token}).then(res => {}).catch(e =>{})
            }catch{}
        }
    },[currenActivePhone])

    useEffect(() => {
        if(status === 'completed'){
            if(data){
                if (isRecipientAlreadyAdded(data.id, [...storedRecipientList.recipients])) {
                    dispatch(chatAction.setCurrentActiveRecipient(data));
                    dispatch(chatAction.bringRecipientToTop(data));
                }
                else{
                    dispatch(chatAction.addRecipientsToFirst(data));
                    dispatch(chatAction.setCurrentActiveRecipient(data));
                }
                const searchState = {
                    search: false,
                    pattern: "",
                };
                dispatch(chatAction.setCurrentActiveView(VIEWS.NORMAL_VIEW));
                dispatch(chatVariablesAction.recipientSearch(searchState));
            }
            else if(error){
                toast.error(error);
            }
        }
    },[status,data])

    return { reloadRecipients,addRecipient};
}
export default useRecipient;