import { FormControl, MenuItem, Select, TextField, Typography } from "@mui/material";
import useDebouncedWatch from "hooks/use-debounce-watch";
import MDBox from "lib/components/MDBox";
import { useEffect, useState } from "react";

const WCallToActionsBtn = ({data,onAdded}) => {
  const[showSave,setShowSave] = useState(false);
  /**      {
    text : '',
    type : '',
    value : ''
  },
  */
  const [actionState,setActionState] = useState({
    text : '',
    type : '',
    value : ''
  });

  useEffect(() => {
    setActionState(data);
  },[data]);
  
  useDebouncedWatch(() => {
    if(actionState.type.trim().length > 0 && actionState.value.trim().length > 0 && actionState.text.trim().length > 0){
      onAdded(actionState);
    }
  },[actionState])

  return (
    <MDBox display="flex" mt={2}>

      <MDBox m={2} display="flex" flexDirection="column" minWidth="156px">
        <Typography fontSize="16px">Type of action</Typography>
        <FormControl variant="standard" sx={{ marginTop: "10px" }}>
          <Select
            labelId="w-category-standard-label"
            id="w-category"
            value={actionState?.type}
            sx={{
              "& .MuiSvgIcon-root": {
                color: "black!important",
                display: "inline-block",
                fontSize: "24px!important",
                right: "0",
              },
              "& .MuiSelect-iconOpen": {
                fontSize: "24px!important",
              },
            }}
            onChange={e => {
              // if(!showSave){
              //   setShowSave(true);
              // }
              setActionState(prev => {
                return {
                  ...prev,
                  type : e.target.value
                }
              })
            }}
          >
            <MenuItem value="phone">Call Phone Number</MenuItem>
            <MenuItem value="website">Visit Website</MenuItem>
          </Select>
        </FormControl>
      </MDBox>

      <MDBox m={2} display="flex" flexDirection="column">
        <Typography fontSize="16px">Button Text</Typography>
        <TextField value={actionState?.text} variant="standard" onChange={e => {
          // if(!showSave){
          //   setShowSave(true);
          // }
          setActionState(prev => {
            return {
              ...prev,
              text : e.target.value
            }
          })
        }} />
      </MDBox>
      <MDBox m={2} display="flex" flexDirection="column">
        <Typography fontSize="16px">{actionState?.type === 'phone' ? "Phone Number" : "Link" }</Typography>
        <TextField variant="standard" onChange={(e) => {
            // if(!showSave){
            //   setShowSave(true);
            // }          
            setActionState(prev => {
              return {
                ...prev,
                value : e.target.value
              }
            })
        }} />
      </MDBox>
      {/* {showSave && <MDBox onClick={() => {onAdded(actionState);setShowSave(false);}} display="flex" justifyContent="center" alignItems="center">
        <Tooltip title="Add this button">
          <Done sx={{height:'1.5rem',width:'1.5rem',cursor:'pointer'}}/>
        </Tooltip>
      </MDBox>} */}
    </MDBox>
  );
};
export default WCallToActionsBtn;
