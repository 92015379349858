import { Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { processPromotionalGroupConversation } from "api/group";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import { useEffect, useState } from "react";
import AppModal from "ui/AppModal";
import ScheduleMessage from "../Chat/ScheduleMessage";
import { useSelector } from "react-redux";

const SEND_ACTION = [
    {text : 'Cancel all',value : 'cancel'},
    {text : 'Send all later',value : 'later'},
    {text : 'Send all now',value : 'now'},
    {text : 'Send all according to area codes',value : 'area_code'},
]
const PromotionalGpMsg = ({gpData,onPromotionalActionPerformed}) => {
    
    const [sendAction, setSendAction] = useState(SEND_ACTION[0]);
    const [showScheduleModal,setShowScheduleModal] = useState(false);
    const {sendRequest,status,data,error} = useHttp(processPromotionalGroupConversation,false);
    const token = useSelector(state => state.user.token);

    const onDoneEvent = (input) => {
        setShowScheduleModal(false);
        const payload = {
            ...gpData,
            action : sendAction.value,
            message : {
                ...gpData.message,
                // Intl.DateTimeFormat().resolvedOptions().timeZone
                timeZone:"utc",
                scheduledAt : input
            }
        }
        sendRequest({payload,token});
    }

    const OnSendAction = () => {
        if(sendAction.value === 'cancel'){
            onPromotionalActionPerformed();
        }
        else{
            const payload = {
                ...gpData,
                action : sendAction.value
            }
            sendRequest({payload,token});
        }
    }
    useEffect(() => {
        if(sendAction.value === 'later'){
            setShowScheduleModal(true);
        }
        else{
            setShowScheduleModal(false);
        }
    },[sendAction]);

    useEffect(() => {
        if(status === 'completed'){
            if(data){
                onPromotionalActionPerformed(data);
            }
        }
    },[status,error,data])

    return(
        <>
            <AppModal
                closeOnClick={false}
                showCloseButton={false}
                rootStyle = {
                {
                    "&.MuiModal-root":{
                    width:'50%',
                    left: '50%'
                    }
                }
                }
                customStyle={{ 
                width: "100%",
                height: "100%",
                overflowY: "auto",
                border: "unset",
                }}
                onModalClose={onPromotionalActionPerformed}
            >
                <MDBox display="flex" flexDirection="column">
                    <MDBox display="flex">
                        <Typography sx={{color:'#f5a20a',fontSize:'22px!important'}}>Some recipients seem to be outside 10DLC allowed sending hours.</Typography>
                    </MDBox>
                    <MDBox display="flex" flexDirection="column" sx={{
                        marginTop : '16px'
                    }} width="100%" alignItem="center" justifyContent="center">
                        <FormControl fullWidth sx={{marginRight:'8px'}}>
                            <InputLabel id="send-type">Send Action</InputLabel>
                            <Select
                                labelId="send-type"
                                id="send-type-select"
                                value={sendAction}
                                sx={{
                                    height:'40px',
                                    width:'100%',
                                    marginRight:'8px'
                                }}
                                label="Send Action"
                                onChange={(e) =>{
                                    setSendAction(e.target.value)
                                }}
                            >
                                {SEND_ACTION.map((item,i) => <MenuItem key={i} value={item}>{item.text}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <Button onClick={OnSendAction} sx={{marginTop:'12px'}} variant="contained" size={'medium'} color='info'>
                                {sendAction.text}
                        </Button>
                    </MDBox>
                    <MDBox display="flex" flexDirection="column">
                            <MDBox display="flex" m={3} justifyContent="start" width="100%">
                                <Typography variant="h6" sx={{fontSize:'16px'}}>List of recipients outside of allowed hours : </Typography>
                            </MDBox>
                            {gpData?.recipients?.map((r,i) => <MDBox ml={3}>
                                {!r.send_allowed_now ? <Typography>{r.recipient.phone}</Typography>: undefined}
                            </MDBox>) }
                    </MDBox>
                </MDBox>
            </AppModal>
            {showScheduleModal && <ScheduleMessage onDone={onDoneEvent} onClose={() => setSendAction(SEND_ACTION[0])}/>}
        </>
    )
}
export default PromotionalGpMsg;