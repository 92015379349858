import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";

const UserItem = ({ user }) => {
  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt={user.name} src="assets/image/user.png" />
        </ListItemAvatar>
        <ListItemText
          primary={user.name}
          secondary={
            <>
              <p>
                {user?.email}
                </p>
                <p>
                {user?.contact}
              </p>
            </>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </List>
  );
};
export default UserItem;
