import { createSlice } from "@reduxjs/toolkit";

const initialChatStates = {
    unreadCount : 0,
    groups : [],
    conversation : {},
    current_active_group : undefined,
    groupFetched : false
};

const groupSlice = createSlice({
    name : 'group',
    initialState : initialChatStates,
    reducers : {
        addGroup : (state,action) => {
            const {group} = action.payload;
            state.groups.unshift(group);
        },
        concateGroups : (state, action) => {
            state.groups = state.groups.concat(action.payload);
        },
        setGroups : (state,action) => {
            state.groups = action.payload;
        },
        setGroupMessage : (state,action) => {
            const {groupId,messages = [] } = action.payload;
            if(!state.conversation[groupId]) {
                state.conversation[groupId] = [];
            }
            state.conversation[groupId] = [...state.conversation[groupId],...messages]
        },
        addGroupMessage : (state,action) => {
            const {message,groupId} = action.payload;
            if(!state.conversation[groupId]) {
                state.conversation[groupId] = [];
            }
            state.conversation[groupId] = [...state.conversation[groupId],message];
        },
        setCurrentActiveGroup : (state,action) => {
            state.current_active_group = action.payload;
        },
        updateConversation : (state,action) => {
            const {groupId,message} = action.payload;
            if(state.conversation[groupId]) {
              const findIdx = state.conversation[groupId].findIndex(itm => itm.tempId === message.tempId);
              state.conversation[groupId][findIdx] = message;
            }      
        },    
        setGroupFetched : (state,action) => {
            state.groupFetched = action.payload;
        },
        deleteGroup : (state,action) => {
            const groupId = action.payload;
            const newGroup = state.groups.filter(itm => itm.groupId !== groupId);
            state.groups = newGroup;
            delete state.conversation[groupId];
            state.current_active_group = undefined;
        },
        addRecipients : (state,action) => {
            const {groupId,recipients} = action.payload;
            const groupIdx = state.groups.findIndex(itm => itm.groupId === groupId);
            if(groupIdx !== -1){
                state.groups[groupIdx].recipientPhone = (state.groups[groupIdx].recipientPhone ?? []).concat(recipients);
            }
        },
        scheduledMsgReceived : (state,action) => {
            const message = action.payload;
            const groupId = message.groupId;
            //if exists, update else ignore
            const groupIdx = state.groups.findIndex(itm => itm.groupId === groupId);
            if(groupIdx !== -1 && state.conversation[groupId]){
              // if messages are fetched..
              const findMsgIdx = state.conversation[groupId].findIndex(itm => itm.id === message.id);
              if(findMsgIdx !== -1){
                state.conversation[groupId][findMsgIdx] = message;
              }
            }
          },        
        removeRecipient : (state,action) => {
            const {groupId,recipientId} = action.payload;
            const groupIdx = state.groups.findIndex(itm => itm.groupId === groupId);
            if(groupIdx !== -1){
                let oldRecipients = state.groups[groupIdx].recipientPhone;
                oldRecipients = oldRecipients.filter(itm => itm.contact.id !== recipientId);
                state.groups[groupIdx].recipientPhone = oldRecipients;
            }
        },
        
        deleteMessage : (state,action) => {
            const {msgId,groupId} = action.payload;
            const newMessages = state.conversation[groupId]?.filter(itm => itm.id !== msgId);
            state.conversation[groupId] = newMessages;
        }, 
        removeMessageUsingTempMsgId : (state,action) => {
            const {message,groupId} = action.payload;
            if(state.conversation[groupId]){
                const findIdx = state.conversation[groupId]?.findIndex(itm => itm.tempId === message.tempId);
                state.conversation[groupId].splice(findIdx,1);
            }
        }, 
        resetGroup : () => initialChatStates
    }
});
export const groupAction = groupSlice.actions; 
export default groupSlice;