import { BASE_URL } from "constants/Endpoints"
import axios from "../../core/axios";

export const IUserSettingsAPI = {

    saveIcSettings : async({token,payload,userId}) => {
        return await axios.post(`${BASE_URL}/user/settings/notification/ic/settings/${userId}`,JSON.stringify(payload),{
            headers:{
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',                
            }
        })
    },
    getIcSettings : async({token,userId}) => {
        return await axios.get(`${BASE_URL}/user/settings/notification/ic/settings/${userId}`,{
            headers:{
                'Authorization': 'Bearer ' + token,                
            }
        })
    }, 
}