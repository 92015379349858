import { FormControl, InputLabel, MenuItem } from "@mui/material";
import { SelectStyle } from "components/ImportChat/MappedColumns";
import { timezones } from "constants/timezones";
import MDBox from "lib/components/MDBox";
import { useEffect } from "react";
import { memo, useState } from "react";

const SelectTimeZone = ({onSelected}) => {

    const [timezone,setTimezone] = useState('');

    useEffect(() => {
        setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    },[]);

    useEffect(() => {
        onSelected(timezone);
    },[timezone]);

    return(
        <MDBox display="flex" flexDirection="column">
            <MDBox display="flex" justifyContent="flex-start" flexDirection="column" width="100%">
                <FormControl fullWidth>
                    <InputLabel id="time-type">Timezone</InputLabel>
                    <SelectStyle
                        labelId="time-type"
                        id="time-type-select"
                        label="Timezone"
                        onChange={e => {
                            setTimezone(e.target.value);
                        }}
                        sx={{
                            height:'40px',
                            marginRight:'8px'
                        }}
                        value={timezone}
                    >
                        {timezones.map((item,index) => <MenuItem key={index} value={item.region}>{`${item.name} (${item.utc})`}</MenuItem>)}
                    </SelectStyle>
                </FormControl>
            </MDBox>
        </MDBox>
    )
}
export default memo(SelectTimeZone);