import { Typography } from "@mui/material";
import ShowCarrier from "components/Carrier/ShowCarrier";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import { memo, useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { SystemSettingsAPI } from "services/SystemSettingsAPI";

const SecondFactorAuthSettings = ({adminSettings = undefined,onUpdated = () => {}}) => {

  const token = useSelector(state => state.user.token);

  const[credentials,setCredentials] = useState({
    phone_number : '',
    user_name : '',
    password : '',
    auth_header : '',
    carrier_id : '',
  });

  const onCarrierSelected = (res) => {
    setCredentials(prev => {
      return{
        ...prev,
        carrier_id : res
      }
    });
  }

  const onFormSubmitHandler = useCallback((e) => {
    e.preventDefault();
    let payload = {};
    if(adminSettings){
      payload = adminSettings;
    }
    payload.authPhone = credentials;
    SystemSettingsAPI.setSecondFactorAuthPhoneCredentials({token,credentials : payload}).then(res => {
      toast.success("Settings saved.");
      onUpdated(res.data);
    }).catch(e => toast.error(e.response.data.message));
  },[credentials,adminSettings,token,onUpdated]);

  useEffect(() => {
    if(adminSettings && adminSettings.authPhone){
      setCredentials(adminSettings.authPhone);
    }
  },[adminSettings]);

    return(
        <>
          <MDBox height="100%" display="flex" justifyContent="center" mt={2} p={2} flexDirection="column">
            <Typography variant="h6">Set phone number credentials for 2FA</Typography>
            <MDBox pt={4} pb={3} px={3 } onSubmit={onFormSubmitHandler}>
              <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput onChange={e => {
                  setCredentials(prev => {
                    return{
                      ...prev,
                      phone_number : e.target.value
                    }
                  })
                }} sx={{minWidth:'200px'}} value={credentials.phone_number} type="tel" label="NUMBER" variant="standard" />
              </MDBox>
              
              <MDBox mb={2}>
                <MDInput onChange={e => {
                  setCredentials(prev => {
                    return{
                      ...prev,
                      user_name : e.target.value
                    }
                  })
                }} sx={{minWidth:'200px'}} value={credentials.user_name} type="text" label="API USERNAME" variant="standard" />
              </MDBox>

              <MDBox mb={2}>
                <MDInput onChange={e => {
                  setCredentials(prev => {
                    return{
                      ...prev,
                      password : e.target.value
                    }
                  })
                }} sx={{minWidth:'200px'}} value={credentials.password} type="text" label="API PASSWORD/TOKEN" variant="standard" />
              </MDBox>

              <MDBox mb={2}>
                <MDInput onChange={e => {
                  setCredentials(prev => {
                    return{
                      ...prev,
                      auth_header : e.target.value
                    }
                  })
                }} sx={{minWidth:'200px'}} value={credentials.auth_header} type="text" label="BASIC AUTH HEADER" variant="standard" />
              </MDBox>
              
              <MDBox mb={2} width="100%">
                <ShowCarrier customStyle={{width:'200px!important'}} selected={credentials.carrier_id} onCarrierSelected={onCarrierSelected} />
              </MDBox>  
              
              <MDBox mt={4} mb={1}>
                  <MDButton sx={{minWidth:'200px'}} type="submit" variant="gradient" color="info">
                    Submit
                  </MDButton>
                </MDBox>
              </MDBox>
              
            </MDBox>

          </MDBox>        
        </>
    )
} 
export default memo(SecondFactorAuthSettings);