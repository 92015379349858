import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { getDateConvertedFromUTC } from "util/UtilityFunctions";
import { FormatPhoneNumber } from "util/UtilityFunctions";
const ReceivedBy = ({recipientphone,countryCode,date}) => {

  const timezone = useSelector(state => state.userDetails?.details?.userSettings?.timezone);

  return (
      <MDBox
        component="li"
        display="flex"
        alignItems="center"
        mb={1}
      >
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <MDTypography variant="button" fontWeight="medium" fontSize={"12px"}>
              {FormatPhoneNumber(recipientphone,countryCode)}, <Moment tz={timezone} fromNow>{getDateConvertedFromUTC(date)}</Moment> (<Moment tz={timezone} format="LT">{getDateConvertedFromUTC(date)}</Moment>, <Moment tz={timezone} format="ll">{getDateConvertedFromUTC(date)}</Moment>)
          </MDTypography>
        </MDBox>
      </MDBox>
    );
}
export default ReceivedBy;