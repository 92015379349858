import { Avatar, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import {memo, useEffect, useState } from "react";

const IUser = ({user,onSelected,select=false}) => {

    const [selected,setSelected] = useState(false);

    useEffect(() => {
        setSelected(select);
    },[select])

    return (
        <MDBox key={user.fullName} component="li" display="flex" alignItems="center" py={1} mb={1}>
            <MDBox mr={2}>
                <Avatar src={user.profilePic} alt={user.fullName} shadow="md"  sx={{ width: 42, height: 42 }} />
            </MDBox>
            <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
                <MDTypography variant="button" fontWeight="medium">
                {user.fullName}
                </MDTypography>

                <MDTypography variant="caption" color="text">
                {user?.email}
                </MDTypography>
            </MDBox>
            <MDBox sx={{cursor:'pointer'}} display="flex" justifyContent="flex-end" width="100%">
                <FormGroup>
                    <FormControlLabel control={<Checkbox key={user?.userId} checked={selected} onChange={(e) => {
                    setSelected(e.target.checked);
                    onSelected({
                        checked: e.target.checked,
                        user : user
                    });
                }}  sx={{color:'#1A73E8'}} />} label="" />
                </FormGroup>
            </MDBox>
        </MDBox>
    )
}
export default memo(IUser);