import { BASE_URL } from "constants/Endpoints"
import axios from "../core/axios";

export const CustomerAPI = {
    getAllCustomersWithGivenStatus : async(token) => {
        return await axios.get(`${BASE_URL}/customer`,{
            headers : {
                'Authorization': 'Bearer ' + token
            }
        });
    },
    
    getAllCustomers : async(token) => {
        return await axios.get(`${BASE_URL}/customer/all`,{
            headers : {
                'Authorization': 'Bearer ' + token
            }
        });
    },

    activateCustomer : async({token,id}) => {
        return await axios.get(`${BASE_URL}/customer/${id}/activate`,{
            headers : {
                'Authorization': 'Bearer ' + token
            }
        });
    }
}