import axios from "../../core/axios";
import { GET_MESAGE_REPLY } from "constants/Endpoints"
import { CREATE_GROUP_REPLY } from "constants/Endpoints"
import { GET_GROUP_MESSAGE_REPLY } from "constants/Endpoints"
import { REPLY } from "constants/Endpoints"
import { MESSAGE } from "constants/Endpoints"
import { CHAT_STRING } from "constants/Endpoints"

export const MessageReplyAPI = {

    createReply : async({token,payload}) => {
        return await axios.post(CHAT_STRING + MESSAGE + REPLY,JSON.stringify(payload),{
            headers:{
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',                
            }
        })
    },
    createGroupReply : async({token,payload}) => {
        return await axios.post(CREATE_GROUP_REPLY,JSON.stringify(payload),{
            headers:{
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',                
            }
        })
    },  
    getGroupReplies : async({token,messageId}) => {
        return await axios.get(GET_GROUP_MESSAGE_REPLY(messageId),{
            headers:{
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',                
            }
        })
    },  
    getReplies : async({token,messageId}) => {
        return await axios.get(GET_MESAGE_REPLY(messageId),{
            headers:{
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',                
            }
        })
    }
}