import { MEDIA_TYPE } from "constants/ChatConstants";
import { useEffect, useState } from "react";
import { getFileType } from "util/MediaUtil";
import RenderUnknownMediaFormat from "./RenderUnknownMediaFormat";
import MDBox from "lib/components/MDBox";
import { Typography } from "@mui/material";

const RenderComposeMedia = ({file}) => {

    const [type,setType] = useState();

    useEffect(() => {
        if(file) {
            console.log(file)
            const t = getFileType(file.type);
            setType(t);
        }
    },[file]);

    return(
        <>
            {type === MEDIA_TYPE.AUDIO && 
                <>
                    <audio style={{height:'150px', width:'150px'}} controls>
                        <source src={URL.createObjectURL(file)} type="audio/webm"/>
                    </audio>
                </>
            }
            {type === MEDIA_TYPE.VIDEO && 
                <>
                    <video style={{height:'150px', width:'150px'}} controls>
                        <source src={URL.createObjectURL(file)}/>
                    </video>
                </>
            }
            {type === MEDIA_TYPE.IMAGE && <img style={{height:'150px', width:'150px',objectFit:'contain'}} src={URL.createObjectURL(file)}/>}

            {type === MEDIA_TYPE.DOCS && <MDBox display="flex" alignItems="center" justifyContent="space-between" sx={{borderRadius : '20px',maxWidth:'200px',height:'48px'}}>
                 <Typography fontSize={'14px'} sx={{maxWidth:'200px',overflow: 'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>{file?.name}</Typography>
              </MDBox>
            }
             {type === MEDIA_TYPE.VCF && <MDBox display="flex" alignItems="center" justifyContent="space-between" sx={{borderRadius : '20px',maxWidth:'200px',height:'48px'}}>
                 <Typography fontSize={'14px'} sx={{maxWidth:'200px',overflow: 'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>{file?.name}</Typography>
              </MDBox>
            }
            
            {type === MEDIA_TYPE.UNKNOWN &&
                <>
                    <RenderUnknownMediaFormat filename={file?.name}/>                
                </>
            }
        </>
    )
}
export default RenderComposeMedia;