import {
  Autocomplete,
  Button,
  Chip,
  createFilterOptions,
  Paper,
  TextField,
} from "@mui/material";
import TagUI from "components/CommonUI/TagUI";
import MDBox from "lib/components/MDBox";
import { memo, useCallback, useEffect, useState } from "react";
import { TwitterPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RecipientAPI } from "services/Chat/RecipientAPI";
import { CompanyAPI } from "services/CompanyAPI";
import { appAction } from "store/app-slice";
import { chatAction } from "store/chat-slice";
import { loaderAction } from "store/loader-slice";

const tagAlreadyUsed = (tags, name) => {
  const filtered = tags?.filter((tag) => tag?.tag?.name === name);
  return filtered?.length > 0;
};

const CreateNRecipientTag = ({ style, recipient }) => {
  const availableTags = useSelector((state) => state.app.companyTags);

  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.user);

  const [companyTags, setCompanyTags] = useState([]);
  const [tagsGiven, setTagsGiven] = useState(recipient?.tags);

  const [newTag, setNewTag] = useState();

  const filter = createFilterOptions();

  const createTagFn = (payload) => {
    RecipientAPI.createTag(payload)
      .then((response) => {
        dispatch(loaderAction.hide());
        dispatch(
          chatAction.addRecipientTags({
            recipientId: recipient?.id,
            tag: response.data,
          })
        );
      })
      .catch((e) => {
        dispatch(loaderAction.hide());
        console.log(e);
      });
  };

  const handleDelete = useCallback(
    (chipToDelete) => {
      dispatch(loaderAction.show());
      const payload = {
        ...chipToDelete,
        contact: {
          id: recipient.id,
        },
      };
      RecipientAPI.removeTag({ token: currentUser.token, payload })
        .then((res) => {
          dispatch(loaderAction.hide());
          dispatch(
            chatAction.removeRecipientTags({
              recipientId: recipient?.id,
              tag: payload,
            })
          );
          const newChips = tagsGiven?.filter(
            (chip) => chip?.tag?.name !== chipToDelete?.tag?.name
          );
          setTagsGiven(newChips);
        })
        .catch((e) => {
          console.log(e?.response?.data?.message);
          dispatch(loaderAction.hide());
        });
    },
    [tagsGiven, setTagsGiven, recipient]
  );

  const saveTag = useCallback(() => {
    const payload = {
      ...newTag,
      company: {
        company_id: currentUser.company?.company_id,
      },
    };
    CompanyAPI.createCompanyTag({ token: currentUser.token, payload })
      .then((res) => {
        dispatch(appAction.addCompanyTag(res.data));
        setCompanyTags((prev) => {
          if (prev) {
            return [res.data, ...prev];
          } else return [res.data];
        });
        const contactTagPayload = {
          tag: res.data,
          contact: {
            id: recipient.id,
          },
          createdBy: currentUser.id,
        };
        createTagFn({ token: currentUser.token, payload: contactTagPayload });
        setNewTag(undefined);
      })
      .catch((e) => {
        setNewTag(undefined);
        toast.error(e?.response?.data?.message);
      });
  }, [
    currentUser?.token,
    currentUser?.company,
    dispatch,
    setCompanyTags,
    newTag,
    recipient,
  ]);

  const handleChangeComplete = (color, event) => {
    setNewTag((prev) => {
      return {
        ...prev,
        tag: {
          ...prev.tag,
          color: color.hex,
        },
      };
    });
  };

  const onTagChangeHandler = useCallback(
    (newValue) => {
      // If it is new tag which is just getting created
      ///if it has tagId means it is getting selected from dropdown, else we are going to create new one
      let val = "";
      if (newValue?.tagId) {
        if (typeof newValue === "string") {
          val = newValue;
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          val = { name: newValue.inputValue };
        } else {
          val = newValue;
        }

        if (val?.name !== "" && !tagAlreadyUsed(tagsGiven, val?.name)) {
          //Save to DB
          const payload = {
            tag: val,
            contact: {
              id: recipient.id,
            },
            createdBy: currentUser.id,
          };
          dispatch(loaderAction.show());
          createTagFn({ token: currentUser.token, payload });
        } else {
          dispatch(
            chatAction.addRecipientTags({
              recipientId: recipient?.id,
              tag: val,
            })
          );
        }
        setNewTag(undefined);
      } else {
        const cTag = {
          tag: {
            name: newValue?.inputValue,
          },
        };
        setNewTag(cTag);
      }
    },
    [recipient, dispatch]
  );

  useEffect(() => {
    {
      console.log({ all: recipient?.tags });
    }
    setTagsGiven(recipient?.tags);
  }, [recipient?.tags, setTagsGiven]);

  useEffect(() => {
    if (availableTags) {
      setCompanyTags(availableTags);
    }
  }, [availableTags, setCompanyTags]);

  return (
    <MDBox display="flex" sx={{ ...style }} flexDirection="column">
      <MDBox display="flex" flexDirection="row">
        {tagsGiven?.map((item, index) => (
          <TagUI
            key={`tag_${item.tag?.name}_${index}`}
            tag={item?.tag}
            onDelete={handleDelete.bind(null, item)}
          />
        ))}
      </MDBox>
      <Autocomplete
        disableClearable
        options={Array.isArray(companyTags) ? companyTags : []}
        getOptionLabel={(option) => option.name}
        autoHighlight={true}
        style={{
          minWidth: "150px",
          maxWidth: "300px",
        }}
        renderOption={(props, option) => {
          const { name, color } = option;
          return (
            <span {...props} style={{ backgroundColor: color, margin: "4px" }}>
              {name}
            </span>
          );
        }}
        onChange={(event, newValue) => onTagChangeHandler(newValue)}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) => inputValue === option.title
          );
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              name: `+ Create "${inputValue}"`,
            });
          }
          return filtered;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label=""
            placeholder="tag"
          />
        )}
      />

      {/**For new Tag which is going to be created*/}
      {console.log("new tag", newTag)}
      {newTag && (
        <Chip
          label={newTag?.tag?.name}
          id={`tag_${newTag.tag?.name}`}
          key={`tag_${newTag.tag?.name}}`}
          sx={{
            overflow: "unset",
            height: "22px",
            margin: "4px",
          }}
          style={
            newTag?.tag?.color && {
              backgroundColor: newTag.tag?.color,
              color: "white",
              "& .MuiChipDeleteIcon": {
                color: "white!important",
              },
            }
          }
        />
      )}
      {newTag && (
        <div style={{ position: "relative" }}>
          <MDBox
            display="flex"
            flexDirection="row-reverse"
            style={{ position: "relative" }}
          >
            <Paper elevation={3}>
              <MDBox display="flex" justifyContent="end">
                <Button
                  varient="outlined"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    saveTag();
                  }}
                >
                  Save
                </Button>
              </MDBox>
              <TwitterPicker
                styles={{ position: "unset" }}
                onChangeComplete={handleChangeComplete}
              />
            </Paper>
          </MDBox>
        </div>
      )}
    </MDBox>
  );
};
export default memo(CreateNRecipientTag);
