export const columns = [
  { id: "1", key: "id", label: "id", print: false },
  { id: "2", key: "fundraiser_name", label: "Name", print: true },
  { id: "3", key: "fundraiser_email", label: "Email", print: true },
  { id: "4", key: "fundraiser_number", label: "Contact Number", print: true },
  { id: "5", key: "goal_amount", label: "Goal Amount", print: true },
  { id: "6", key: "raised_so_far", label: "Amount Raised", print: true },
  { id: "7", key: "assigned_phone", label: "Assigned Phone", print: true },
  { id: "8", key: "status", label: "Progress", print: true },
  { id: "9", key: "actions", label: "Actions", print: false },
];
