import axios from "core/axios";

import { USER_STATUS } from "constants/Endpoints"

export const ChatBackgroundAPI = {
    getAllUserStatus : async({token,companyId}) => {
        return await axios.get(USER_STATUS(companyId),{
            headers:{
                'Authorization': 'Bearer ' + token                
            }
        })
    },
}