import { BASE_URL } from "constants/Endpoints";
import axios from "../../core/axios";

export const ChatAPI = {
    getContactedRecipientHaveScheduledMsg : async ({phoneId,token,pageNo,pageSize,userId}) => {
        return await axios.get(`${BASE_URL}/phone/${phoneId}/unscheduled?pageNo=${pageNo | 0}&pageSize=${pageSize | 20}&userId=${userId}`,{
            headers : {
                'Authorization': 'Bearer ' + token,
            }
        });
    },
    getContactedRecipientHaveHipaaWaiting : async ({phoneId,token,pageNo,pageSize,userId}) => {
        return await axios.get(`${BASE_URL}/phone/${phoneId}/hipaa?pageNo=${pageNo | 0}&pageSize=${pageSize | 20}&userId=${userId}`,{
            headers : {
                'Authorization': 'Bearer ' + token,
            }
        });
    },
    markParticularMessageAsUnread : async({token,payload}) => {
        return await axios.post(`${BASE_URL}/sms/conversation/unread`,JSON.stringify(payload),{
            headers : {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            }
        })
    },
    getTotalUnreadForPhone : async ({phoneId,token}) => {
        return await axios.get(`${BASE_URL}/sms/phone/${phoneId}/unread/count`,{
            headers : {
                'Authorization': 'Bearer ' + token,
            }
        });
    },
};