import { Typography } from "@mui/material";
import EnhancedTable from "components/CommonUI/EnhancedTable";
import MDBox from "lib/components/MDBox";
import { useEffect, useState } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";

let columns = [
    {   id : 1, key : 'sno', label : 'SNo.' },
    {    id : 2, key : 'recipient', label : 'Recipient'},
    {   id : 3, key : 'created_at', label : 'Sent At'}
]
const PendingGroupConsent = ({group,consents}) => {
    
    const [rows,setRows] = useState([]);
    const timezone = useSelector(state => state.userDetails?.details?.userSettings?.timezone)

    useEffect(() => {
        if(consents && consents != null && consents.length > 0){
            const newRows = consents?.map((item,idx) => {
                return {
                    id : item?.id,
                    sno : idx + 1,
                    recipient : item.recipient,
                    created_at :<>
                    {item.consentSentAt ?<>
                        <Moment tz={timezone} format="ll">{item.consentSentAt}</Moment>
                          (<Moment tz={timezone} format="LT">{item.consentSentAt}</Moment>)</>
                         : 'Will send just before texting'
                    }
                </>
                }
            })
            setRows(newRows);
        }
    },[consents])

    return(
        <>
            <MDBox display="flex" flexDirection="column">
                {rows.length > 0 ? 
                <MDBox>
                    <EnhancedTable
                        columns={columns}
                        rows={rows}
                        showFilter={false}
                        heading={"Pending consents"}
                        selectEnabled={false}
                    />
                </MDBox> :
                <MDBox
                    padding="28px"
                    height="200px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    >
                    <Typography>No data found.</Typography>
                </MDBox>}
            </MDBox>
        </>
    )
}
export default PendingGroupConsent;