import MDBox from "lib/components/MDBox";
import { Navbar } from "pages/Chat/ChatStyles";
import MDTypography from "lib/components/MDTypography";
import { Avatar, Tooltip } from "@mui/material";
import { Search } from "@mui/icons-material";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ichatVariablesAction } from "store/IChat/Ichat-variables-slice";
import GlobalSearch from "../GlobalSearch";
import IGroupDetails from "./IGroupDetails";
import { useMediaQuery } from "react-responsive";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { IGroupAction } from "store/IChat/igroup-slice";

const GMiddleNavBar = () => {

    const dispatch = useDispatch();
    const currentActiveGroup = useSelector(state => state.Igroup.currentActiveGroup);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const onSearchHandle = () => {
        const sideBarContent = {
            open : true,
            content : <GlobalSearch onSearchClose={() => dispatch(ichatVariablesAction.updateRightSideBar({open:false,content:undefined}))}/>
        }
        dispatch(ichatVariablesAction.updateRightSideBar(sideBarContent));
    }

    const showGroupDetails = useCallback(() => {
        const sideBarContent = {
            open : true,
            content : <IGroupDetails key={currentActiveGroup?.chatId} group={currentActiveGroup}/>
        }
        dispatch(ichatVariablesAction.updateRightSideBar(sideBarContent));
    },[currentActiveGroup,dispatch])


    return (
        <Navbar borderLeft="none">
            <MDBox display="flex" width="100%" justifyContent="space-between" alignItems="center">
                {isMobile && <MDBox sx={{marginLeft:'12px'}}>
                    <ArrowBackIosIcon onClick={() => {
                        dispatch(IGroupAction.updateCurrentActiveGroup(undefined));
                    }}/>
                    </MDBox>
                }
                <MDBox onClick={showGroupDetails} width="40%" ml={1} display="flex" alignItems="center" pl={1} pr={1} pb={1} pt={.5} sx={{cursor : 'pointer'}}>
                    <MDBox mr={2}>
                        <Avatar src={currentActiveGroup?.displayProfile} alt={currentActiveGroup?.name} shadow="md" />
                    </MDBox>
                    <MDBox
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        justifyContent="center"
                    >
                        <MDTypography variant="button" fontWeight="medium">
                        {currentActiveGroup?.name}
                        </MDTypography>
                        {currentActiveGroup?.description && <MDTypography sx={{marginTop : '4px',height:'16px',overflow:'hidden',textOverflow:'ellipsis'}} variant="caption" color="text">
                            {currentActiveGroup.description}
                        </MDTypography>}    
                    </MDBox>
                </MDBox>
                <MDBox mr={2} pl={1} pr={1} pb={1} pt={.5} display="flex" alignItems="center" height="100%">
                    <MDBox display="flex" justifyContent="flex-start" alignItems="center">
                        <Tooltip title="Search chat"><Search onClick={onSearchHandle} className="navbar-icon" sx={{height: "24px",width:"24px",marginRight:"8px",cursor:"pointer"}}/></Tooltip>
                        {/* <Tooltip title="Menu"><MoreVert className="navbar-icon" sx={{height: "24px",width:"24px",cursor:"pointer"}}/></Tooltip> */}
                    </MDBox>
                </MDBox>
            </MDBox>
        </Navbar>
    )
}
export default React.memo(GMiddleNavBar);