import { createSlice } from "@reduxjs/toolkit";

const initialDraftsStates = {
   draftsMessage:{}
}

const DraftsMessageSlice = createSlice({
    name : 'DraftsMessage',
    initialState: initialDraftsStates,
    reducers : {
        setDraftsMessage : (state,action) => {
            state.draftsMessage = action.payload;
        },
      
    }
})
export const draftsMessageAction = DraftsMessageSlice.actions;
export default DraftsMessageSlice;