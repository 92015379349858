import { Download } from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { memo } from "react";

const RenderUnknownMediaFormat = ({filename,url,showDownloadBtn = false, customStyle = {}}) => {
    const _fileName = url?.split('/')?.[url?.split('/')?.length-1]
    return(
        <>
            <MDBox style={{height:'200px',width:'200px',...customStyle}} display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Typography sx={{padding:'8px'}} fontSize={'12px'} textAlign={'center'}>{_fileName}</Typography>
                    <Typography sx={{padding:'8px'}} fontSize={'12px'} textAlign={'center'}>{filename}</Typography>
                    {showDownloadBtn && <Tooltip title={"Download"}><a style={{textDecoration:'none'}} target="_blank" href={url} download><Download sx={{height:'28px',width:'28px',cursor:'pointer'}}/></a></Tooltip>}
                </MDBox>
            </MDBox>
        </>
    )
}
export default memo(RenderUnknownMediaFormat);