import { VIEWS } from "constants/ChatConstants";
import { useSelector } from "react-redux";
import { isPromotionalGroup } from "util/GroupUtil";

const useTyping = (setMessageState) => {

    const currentUser = useSelector(state => state.userDetails.details);
    const view = useSelector(state => state.chat.current_active_view);

    const onTyping = (e,currentActiveGroup) => {
        if(view === VIEWS.GROUP_VIEW && isPromotionalGroup(currentActiveGroup)){
            let ctext = currentUser.company?.tenDlcName ? currentUser.company?.tenDlcName : currentUser.company.companyName
            ctext = ctext + ": ";
            if(e.target.value.length === 1){
                setMessageState(prev => {
                return{
                    ...prev,
                    message : ctext  + e.target.value
                }
                })
            }
            else{
                setMessageState(prev => {
                return{
                    ...prev,
                    message : ctext + e.target.value.substr(ctext.length)
                }
                })
            }
            }
            else{
            setMessageState(prev => {
                return{
                    ...prev,
                    message : e.target.value
                }
            })
        }        
    }
    return{onTyping}
}
export default useTyping;