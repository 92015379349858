import { Tooltip } from "@mui/material";
import MDBox from "lib/components/MDBox";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import GoalTimeline from "./GoalTimeline";
import AppDrawer from "ui/AppDrawer";
import { useState } from "react";
const AddBonus = ({campaign}) => {

    const [openDrawer, setOpenDrawer] = useState(false);

    return (
      <>
        <MDBox>
          <MDBox
            sx={{}}
            mt={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Tooltip title="Set fundraising goals">
              <AttachMoneyIcon
                onClick={() => setOpenDrawer(true)}
                sx={{ cursor: "pointer",width: "1.2rem", height: "1.2rem" }}
              />
            </Tooltip>
          </MDBox>
        </MDBox>
        <AppDrawer openDrawer={openDrawer} style={{
            margin:'unset !important',
            height:'100% !important',
            borderRadius:'unset!important',
            width:'35%'
        }}
        onClose = {() => setOpenDrawer(null)}
        >
            <GoalTimeline campaign={campaign}/>
        </AppDrawer> 
      </>
    );
}
export default AddBonus;