import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import AppConstants from "constants/AppConstants";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RecipientAPI } from "services/Chat/RecipientAPI";
import { chatAction } from "store/chat-slice";
import { loaderAction } from "store/loader-slice";

const EditContact = ({recipient,onEdited}) => {
    
    const dispatch= useDispatch();

    const currentUser = useSelector(state => state.user);

    const [updatedData,setUpdatedData] = useState({
        name : recipient?.displayName,
        pushToGoogle : false
    });

    const editHandler = useCallback(() => {
        if(updatedData.name?.trim().length === 0){
            toast.error("Name is required.");
            return;
        }
        dispatch(loaderAction.show());
        const payload = {
            ...updatedData,
            companyId : currentUser.company?.company_id,
            contactId : recipient.id,
            phone: recipient.phone
        }
        RecipientAPI.editRecipientName({token: currentUser.token, payload}).then(res => {
            dispatch(loaderAction.hide());
            toast.success(AppConstants.UPDATED_SUCCESS);
            dispatch(chatAction.updateContactName({contact : res.data}));
            onEdited();        
        }).catch(e => {
            dispatch(loaderAction.hide());
            console.log(e);
            onEdited();
            toast.error(AppConstants.REQUEST_FAIL);
        });
    },[updatedData,currentUser,recipient]);

    return(
        <MDBox width="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            <MDBox width="100%" display="flex" alignItems="center" justifyContent="center">
                <MDInput onChange={(e) => {
                    setUpdatedData(prev => {
                        return{
                            ...prev,
                            name : e.target.value
                        }
                    })
                }} label={"Name"} value={updatedData.name} fullWidth type="text" />
            </MDBox> 
            <FormGroup sx={{width:'100%'}}>
                <FormControlLabel
                    control={
                    <Checkbox
                        onChange={(e) => {
                            setUpdatedData(prev => {
                                return{
                                    ...prev,
                                    pushToGoogle : e.target.checked
                                }
                            })                                
                        }}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    />
                    }
                    label={"Also update to google contacts."}
                />
            </FormGroup>               
            <MDBox style={{marginTop:'12px'}} display="flex" alignItems="center" justifyContent="center">
                <MDButton onClick={editHandler} size={'medium'} color='info' variant="outlined">
                    Update
                </MDButton>
            </MDBox>
        </MDBox> 
    )
}
export default memo(EditContact);