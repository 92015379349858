import { Avatar, AvatarGroup, Button, List, ListItem, Tooltip, Typography } from "@mui/material";
import { FlexContainer } from "pages/Chat/ChatStyles";
import parse from 'html-react-parser'
import { MessageText } from "pages/Chat/ChatStyles";
import { RightMessageBubble } from "pages/Chat/ChatStyles";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { Message } from "pages/Chat/ChatStyles";
import { DateTimeInfo } from "pages/Chat/ChatStyles";
import MDTypography from "lib/components/MDTypography";
import Moment from "react-moment";
import React from "react";
import { MESSAGE_STATUS } from "constants/ChatConstants";
import DoneIcon from '@mui/icons-material/Done';
import MDBox from "lib/components/MDBox";
import { KeyboardArrowDown } from "@mui/icons-material";
import IHover from "../IHover";
import { useState } from "react";
import MessageForward from "../MessageForward";
import AppModal from "ui/AppModal";
import { useSelector } from "react-redux";
import { MESSAGE_STATUS_TEXT } from "constants/ChatConstants";

const OMessage = ({message,onRepliesClick}) => {
    
  const timezone = useSelector(state => state.userDetails?.details?.userSettings?.timezone)

  const [showoptions,setShowOptions] = useState(false);

  const [forwardModal,setForwardModal] = useState(false);

  const [targetElement,setTargetElement] = useState(undefined);

  const handleOptionSelection = (option) => {
    if(option === 'reply'){
      onRepliesClick(message);
      onClose();
    }
    else if(option === 'forward') {
      setForwardModal(true);
      onClose();
    }    
  }

  const onClose = () => {
    setTargetElement(undefined);
    setShowOptions(false);
  }

    const renderMsgStatus = (status) => {
      switch (status) {
        case MESSAGE_STATUS.READ:
          return (
            <Tooltip title={MESSAGE_STATUS_TEXT.READ}>
              <DoneAllIcon sx={{height: '16px', width:'16px',color: "#1876F2"}} fontSize="small" />
            </Tooltip>
          );
    
        case MESSAGE_STATUS.DELIVERED:
          return (
            <Tooltip title={MESSAGE_STATUS_TEXT.DELIVERED}>
              <DoneAllIcon sx={{height: '16px', width:'16px',color: "#8d9095"}} fontSize="small" />
            </Tooltip>
          );
    
        case MESSAGE_STATUS.PENDING:
          return (
            <Tooltip title={MESSAGE_STATUS_TEXT.PENDING}>
              <DoneIcon sx={{height: '16px', width:'16px',color: "#8d9095"}} fontSize="small" />
            </Tooltip>
          );
    
        default:
          return null;
      }
    };
    
    return(
        <MDBox 
          alignItems="center"
          justifyContent="space-between"
          height="auto"
          spacing="10px"
          key={"msg_container_" + message?.messageId}
          id={"message_" + message?.messageId}
          data-message-id={message.messageId}
          marginBottom="10px"
          flexDirection="column"
          sx={{position:'relative'}}
        >
          <MDBox key={"imsg_div" + message?.messageId} sx={{ marginTop: "2px",width:"100%" }}>
            <DateTimeInfo className="right-date-info">
                <MDTypography variant="button" fontWeight="light" fontSize={"12px"}><Moment tz={timezone} fromNow>{message?.createdAt}</Moment> (<Moment tz={timezone} format="LT">{message?.createdAt}</Moment>, <Moment tz={timezone} format="MMM D, YYYY">{message?.createdAt}</Moment>)</MDTypography>                           
            </DateTimeInfo>              
            <Message key={"msg_" + message?.messageId} className="right-msg">
                <FlexContainer
                    style={{
                        marginLeft: "4px",
                        fontSize: "14px",
                        cursor: "pointer",
                    }}
                >
                    {renderMsgStatus(message.status)}
                </FlexContainer>
                <RightMessageBubble
                    key={"msg_img" + message?.messageId}
                    chatImage={false}
                    onMouseEnter={() => setShowOptions(true)} 
                    onMouseLeave={() => {
                      if(!targetElement){
                        setShowOptions(false)
                      }
                    }}
                >
                  <MDBox color={'white'} display="flex" alignItems="center" flexDirection="column">
                    <MessageText attachmentWithText={false}>
                        {message?.text && parse(message?.text)}
                    </MessageText>
                    <MDBox sx={{color:'white!important',position:'absolute',top:0,right:2}}>
                      {showoptions &&
                          <>
                              <KeyboardArrowDown onClick={e => setTargetElement(e.currentTarget)} sx={{height: '1.5em!important',width:'1.5em!important',cursor:'pointer',color:'white'}}/>
                              <IHover onClose={onClose} target={targetElement}>
                                <List>
                                  <ListItem 
                                      onClick={() => handleOptionSelection('reply')}
                                      sx={{
                                      fontSize:'14px',
                                      cursor:'pointer',
                                      width : '100%'
                                      }}>
                                      <span style={{fontSize:'14px !important',fontWeight:'400'}}>{message?.repliesCount > 0 ?  'Threads' : 'Start thread'}</span>
                                  </ListItem>

                                  <ListItem 
                                      onClick={() => handleOptionSelection('forward')}
                                      sx={{
                                      fontSize:'14px',
                                      cursor:'pointer',
                                      width : '100%'
                                      }}>
                                      <span style={{fontSize:'14px !important',fontWeight:'400'}}>{'Forward'}</span>
                                  </ListItem>
                                </List>
                              </IHover>
                          </>
                      }
                    </MDBox>                        
                  </MDBox>
                </RightMessageBubble>                
            </Message>
          </MDBox>
          {message?.repliesCount > 0 && <MDBox display="flex" justifyContent="flex-end" sx={{width:"100%"}}>
            <AvatarGroup max={3}>
              {message?.repliedProfiles?.map(itm => <Avatar key={itm?.userId} alt={itm?.fullName} src={itm?.profilePic} sx={{ width: 18, height: 18 }}/>)}                                                       
            </AvatarGroup>            
            <Button onClick={() =>  onRepliesClick(message)} sx={{textTransform:'unset',padding:'unset',minHeight:'unset'}}>{message?.repliesCount} {message?.repliesCount === 1 ? 'reply' : 'replies'}</Button> 
            <MDTypography variant="button" fontWeight="light" fontSize={"12px"}>Last reply <Moment tz={timezone} fromNow>{message?.repliedProfiles[0]?.time}</Moment></MDTypography>                           
          </MDBox>} 
          {forwardModal && 
            <AppModal heading={"Forward To"} onModalClose={() => setForwardModal(false)} closeOnClick={false}>
                <MessageForward message={message} setForwardModal={setForwardModal}/>
            </AppModal>
          }          
        </MDBox>
    )
}
export default React.memo(OMessage);