import { Typography } from "@mui/material";
import AppConstants from "constants/AppConstants";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import { useEffect, useState } from "react";
import OutboundConfig from "./OutboundConfig";
import InboundConfig from "./InboundConfig";

const CarrierForm = ({onSubmit,carrier}) => {

    const [carrierData, setCarrierData] = useState({
        carrier_name : '',
        http_method : 'POST',
        encoding_type : 'DEFAULT',
        content_type : 'application/json',
        max_length : 160,
        auth_val : '',
        send_url : '',
        request_body : 'text={{text}}&from={{from}}&to={{to}}',
        example_request_body : '',
        response_body : '',
        limit : 0
    }) 

    const onCarrierNameChange = (e) => {
        setCarrierData(prev => {
            return {
                ...prev,
                carrier_name : e.target.value
            }
        })
    }

    const onLimitChange = (e) => {
        setCarrierData(prev => {
            return {
                ...prev,
                limit : e.target.value
            }
        })
    }

    const submitFormData = () => {
        onSubmit(carrierData);
    }
    useEffect(() => {
        if(carrier){
            setCarrierData(carrier);
        }
    },[carrier]);
    return (
      <MDBox margin={"12px"} display="flex" flexDirection="column" width="100%">
        <MDBox margin={'8px'}>
            <MDInput sx={{ marginRight:'8px'}}  fullWidth onChange={onCarrierNameChange} type="text" label="Carrier Name" value={carrierData.carrier_name} />
            <Typography margin={'8px'} fontSize={'12px'}>A unique name for the carrier</Typography>
        </MDBox>    

        <MDBox margin={'8px'}>
            <MDInput type="number" fullWidth label={AppConstants.CARRIER_DEDAULT_MSG_INFO} value={carrierData.limit} onChange={onLimitChange} />
            <Typography margin={'8px'} fontSize={'12px'}>{"If the carrier does not limit the throughput on this number, then leave this field blank. If you're unsure, you can enter 25. This amount can be updated later."}</Typography>
        </MDBox>
        <OutboundConfig/>
        <InboundConfig/>

        <MDBox margin={'12px'} display="flex" justifyContent="flex-end">
            <MDButton onClick={submitFormData} variant="gradient" color="info">Save</MDButton>
        </MDBox>   

      </MDBox>
    );
}
export default CarrierForm;