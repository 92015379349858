import MDBox from "lib/components/MDBox";
import style from "./MicAnimation.module.css";
import { memo } from "react";
const ShowTag = ({tags}) => {

    return(
        <>
            {tags?.length > 0 && <MDBox display="flex" flexDirection="row" sx={{
                marginTop:'4px'
            }}>
                {tags?.map(item => <span key={item?.id} className={style['show-tag']} style={{
                    backgroundColor:`${item?.tag?.color}`
                }}>{item?.tag?.name}</span>)}
            </MDBox>
            }
        </>
    )
}
export default memo(ShowTag);