import { Button, Switch, Typography } from "@mui/material"
import AppConstants from "constants/AppConstants"
import MDBox from "lib/components/MDBox"
import { memo, useCallback, useState } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { PhoneAPI } from "services/PhoneAPI"

const VoipCallConfig = ({phoneId,onCellConfigUpdated = () => {}}) => {

    const token = useSelector(state => state.user.token);

    const [showSaveBtn,setShowSaveBtn] = useState(false);

    const toggleCellCallConfig = useCallback((e) => {
        PhoneAPI.toggleCellCallConfig({phoneId,token,enabled : e.target.checked}).then(res => {
            if(res.data === 1){
                toast.success(AppConstants.UPDATED_SUCCESS);
                onCellConfigUpdated({rowsUpdated : res.data,phoneId});
            }
            else{
                toast.error(AppConstants.SOMETHING_WENT_WRONG);
            }
        }).catch(e => {
            console.log(e);
            toast.error(AppConstants.SOMETHING_WENT_WRONG);
        })
    },[phoneId,token]);

    return(
        <MDBox display="flex" flexDirection="column" width="100%">
            <MDBox display="flex">
                <Switch color="info" onChange={toggleCellCallConfig}/>
                <Typography>{AppConstants.ENABLE_VOIP_CALL}</Typography>
            </MDBox>  
            {showSaveBtn && <MDBox display="flex" justifyContent="flex-end">
                <Button
                    variant="contained"
                    sx={{
                        borderRadius: "0px",
                        color: "white!important",
                        border: "unset",
                        marginLeft: "12px",
                        height: "32px",
                        minHeight: "unset",
                    }}                
                >{AppConstants.SAVE_BTN}</Button>
            </MDBox>} 

        </MDBox>
    )
}
export default memo(VoipCallConfig);