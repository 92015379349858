import { memo, useState } from "react";
import AppModal from "ui/AppModal";
import { useDispatch, useSelector} from "react-redux";
import { IMPORT_TYPE } from "constants/ChatConstants";
import SmsImport from "./SmsImport";
import WhatsappImport from "./WhatsappImport";
import MDBox from "lib/components/MDBox";
import CustomSelect from "ui/Select";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import UserPhoneList from "components/Phone/UserPhoneList";

const ImportChatWrapper = ({onClose,onImported}) => {

    const dispatch= useDispatch();

    const phones = useSelector(state => state.userDetails.details.phones);
    
    const [selectedPhone,setSelectedPhone] = useState();

    const [steps,setSteps] = useState(0);

    const [importType,setImportType] = useState({
        type: IMPORT_TYPE.SMS,
        totalSteps : 4
    });

    return(
        <AppModal       
            heading={' '}           
            rootStyle = {
                {
                    "&.MuiModal-root":{
                    width:'95%',
                    left: '5%'
                    }
                }
            }
            customStyle={{ 
                width: "100%",
                height: "100%",
            }}
            closeOnClick={false}
            onModalClose={onClose}>
            <>
                {steps === 0 && 
                    <MDBox display="flex" flexDirection="column" sx={{marginLeft:'12px'}} justifyContent="center"alignItems="center" width="100%">
                        <MDBox display="flex" justifyContent="center" alignItems="center" width="40%">
                            <FormControl fullWidth>
                                <InputLabel id="import-label">{"Import type"}</InputLabel>
                                <CustomSelect value={importType.type} onChange={(e) => {
                                    const value = e.target.value;
                                    if(value === IMPORT_TYPE.SMS){
                                        setImportType({
                                            type : value,
                                            totalSteps : 4
                                        })
                                    }
                                    else if(value === IMPORT_TYPE.WHATSAPP){
                                        setImportType({
                                            type : value,
                                            totalSteps : 2
                                        })
                                    }
                                }} sx={{height:'42px'}} labelId="import-label" label={"Import type"}>
                                <MenuItem value={IMPORT_TYPE.SMS}>{"SMS"}</MenuItem>
                                <MenuItem value={IMPORT_TYPE.WHATSAPP}>{"WHATSAPP"}</MenuItem>
                                </CustomSelect>
                            </FormControl>
                        </MDBox>
                        {importType.type === IMPORT_TYPE.WHATSAPP && <UserPhoneList customStyle={{width:'40%', margin:'12px'}} phones={phones} nativeFetch={false} onPhoneSelected={(phone) => setSelectedPhone(phone)}/>}
                    </MDBox>
                }
                <MDBox display="flex" flexDirection="column" mt={2}>
                    <MDBox display="flex" alignItems="center" justifyContent="center">
                        {importType.type === IMPORT_TYPE.SMS && <SmsImport onImported={onImported} steps={steps} setSteps={setSteps} onClose={onClose} totalSteps={importType.totalSteps} />}    
                        {importType.type === IMPORT_TYPE.WHATSAPP && <WhatsappImport onImported={onImported} onUploaded={() => onClose()} currentActivePhone={selectedPhone} steps={steps} setSteps={setSteps} onClose={onClose} totalSteps={importType.totalSteps}/>}    
                    </MDBox>
                </MDBox>
            </>    
        </AppModal>
    )
}
export default memo(ImportChatWrapper);