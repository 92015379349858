import { Select } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    color: "black!important",
    display: "inline-block",
    fontSize: "24px!important",
    right: "0",
  },
  "& .MuiSelect-iconOpen": {
    fontSize: "24px!important",
  },
}));
export default CustomSelect;
