import { BASE_URL } from "constants/Endpoints";
import axios from "../core/axios";

export const WASettingsAPI = {
    getWASettings : async ({companyId,token}) => {
        return await axios.get(`${BASE_URL}/company/${companyId}/wa/settings`,{
            headers : {
                'Authorization': 'Bearer ' + token
            }
        })
    },
    updateWASetting : async ({wa,token}) => {
        return await axios.post(`${BASE_URL}/company/wa/settings`,JSON.stringify(wa),{
            headers : {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token
            }
        });
    }
}