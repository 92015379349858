import { Typography } from "@mui/material";
import NoContent from "components/Chat/NoContent";
import Bliq from "assets/images/logos/BLIQ/longtaglines-01.jpg";
import MDBox from "lib/components/MDBox";
import { memo } from "react";

const NoRecipientSelected = () => {
    return <>
            <NoContent style={{height: "100%",backgroundColor:"#fff",minHeight:'100%'}}>
                <MDBox display="flex" width="100%" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
                    {/* <Typography variant="h4">{AppConstants.WELCOME_BLIQ}</Typography> */}
                    <MDBox maxWidth='400px'>
                        <img src={Bliq} height={"100%"} width={"100%"}/>
                    </MDBox>
                </MDBox>
            </NoContent>
        </>
}
export default memo(NoRecipientSelected);