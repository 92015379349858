import { Button, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import Recipient from "../NChat/Recipient";
import { useState } from "react";
import { useEffect } from "react";
import { Add } from "@mui/icons-material";
import AppModal from "ui/AppModal";
import ICreateGroup from "./ICreateGroup";
import useIgroup from "hooks/IChat/use-igroup";
import Group from "./Group";
import { useSelector } from "react-redux";

const IGroupChat = () => {

    const groups = useSelector(state => state.Igroup.groups);
    const [groupModal,setGroupModal] = useState(undefined);

    /// Custom hook
    const {fetchGroupChat} = useIgroup();

    useEffect(() => {
        if(groups.length === 0){
            fetchGroupChat();
        }
    },[]);

    return(
        <>
            {groups?.length > 0 && <MDBox display="flex" justifyContent="flex-end"><Button onClick={() => setGroupModal(true)}><Add/> Start new group</Button></MDBox>}
            <MDBox diplay="flex" width="auto" height="auto">
                {groups?.length > 0 ? groups.map(g => <Group key={g?.chatId} chat={g}  latestMessage={g?.latestMessage}/>) 
                : <MDBox width="100%" height="calc(100vh - 114px)" flexDirection="column" display="flex" alignItems="center" justifyContent="center">
                    <Typography>{"No groups found."}</Typography>
                    <Button onClick={() => setGroupModal(true)}><Add/> Start a group chat</Button>
                </MDBox>
                }
            </MDBox>
            {groupModal && 
             <AppModal customStyle={{padding:'12px'}} rootStyle={{overflow:'hidden',height:'100%'}} heading={"Create a group"} onModalClose={() => setGroupModal(undefined)} closeOnClick={false}>
                 <ICreateGroup onGroupCreated={() => {setGroupModal(undefined)}}/>
             </AppModal>
            }
        </>
    )
}
export default IGroupChat;