import { Sync } from "@mui/icons-material";
import { Card,Tooltip,Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { memo } from "react";
import Moment from "react-moment";
import { getDateConvertedFromUTC } from "util/UtilityFunctions";

const ContactSyncInfo = ({syncInfo,phone,onSync}) => {

    return(
        <Card key={syncInfo?.id} sx={{margin:'8px 8px 8px 0px'}}>
            <Tooltip title={"click to sync"}>
                <MDBox onClick={() => onSync(phone)} sx={{cursor:'pointer'}} display="flex" flexDirection="column" p={1} justifyContent="center">
                    <MDBox display="flex" alignItems="center">
                        <Sync sx={{cursor:'pointer',height:'28px',width:'28px'}}/>
                        <Typography fontWeight={600}>{phone.phone}</Typography>
                    </MDBox>
                    {(syncInfo === null || !syncInfo) ? 
                        <Typography fontSize={'12px'} textAlign={'start'}>
                            Never synced. Sync it now.
                        </Typography>
                        : 
                        <Typography fontSize={'12px'} textAlign={'start'}>
                            Last sync <Moment fromNow>{getDateConvertedFromUTC(syncInfo?.lastSyncedOn)}</Moment>
                        </Typography>
                    }
                </MDBox>
            </Tooltip>
        </Card>
    )
}
export default memo(ContactSyncInfo);