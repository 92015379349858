import { Card, Checkbox, Divider, MenuItem, Select, TextField, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import {useEffect, useState } from "react";
import brandLogo from "assets/images/logos/e.png";
import ReCAPTCHA from "react-google-recaptcha";
import GoalCompletionUI from "components/CommonUI/GoalCompletionUI";
import { useLocation } from "react-router-dom";

const DonationPagePreview = ({backgroundImage,companyName = "Company Name"}) => {
  
  const {state} = useLocation();
  
  const [currData,setCurrData] = useState({
    companyName : companyName,
    backgroundImage : backgroundImage
  });

  const [recurringPayment, setRecurringPayment] = useState({
    recurring : false,
    end : 'never',
    number_of_payments : 12,
    frequency : 1
  });

  useEffect(() => {
    if(backgroundImage){
      setCurrData(prev => {
        return{
          ...prev,
          backgroundImage : backgroundImage
        }
      })
    }
  },[backgroundImage]);

  useEffect(() => {
    if(state && state?.company_banner) {
      setCurrData((p) => {
        return{
          ...p,
          backgroundImage : state?.company_banner,
          companyName : companyName
        }
      })
    }
  },[state,companyName]);

    return (
      <>
        <MDBox
          display="flex"
          flexDirection="column"
          sx={{ height: "100vh", overflowY: "hidden"}}
        >
          <MDBox height="80px" width="100%" sx={{ backgroundColor: "#f5f5f5" }} display="flex" justifyContent="space-between">
            <MDBox display="flex" height="100%" flexDirection="row">
              <MDBox
                display="flex"
                ml={4}
                p={4}
                flexDirection="row"
                alignItems="center"
              >
                <img
                  src={brandLogo}
                  style={{ width: "40px", height: "40px" }}
                />
                <Typography variant="h4">
                  {"WayMore Payments"}
                  <Typography
                    sx={{
                      fontSize: "0.65rem",
                      height: "0px",
                      lineHeight: "unset!important",
                    }}
                  >
                    Powered by BLIQ
                  </Typography>
                </Typography>
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center">
                <Typography sx={{padding:'12px'}}>{companyName}</Typography>
              </MDBox>
          </MDBox>
          <MDBox sx={{backgroundColor:'#f9f9f9',backgroundRepeat:"no-repeat", backgroundImage: `url(${currData.backgroundImage})`}}>
            <MDBox display="flex" flexDirection="row" p={6} sx={{}}>
              <MDBox
                width="50%"
                display="flex"
                justifyContent="center"
              >
                <MDBox display="flex" alignItems="start" flexDirection="column">
                  <Card sx={{padding:'22px'}}>
                    <Typography sx={{margin:'16px'}} variant="h4">
                      {"Campaign name"}
                    </Typography>
                    <MDBox display="flex" alignItems="center" flexDirection="column" justifyContent="center">
                      <Typography sx={{padding:'8px'}} fontSize={'14px'}>Campaign goal raised</Typography>
                      <GoalCompletionUI  value={10} color={"info"}/>
                    </MDBox>
                    <MDBox display="flex" flexDirection="column">
                      <MDBox sx={{margin:'16px'}}>
                        <Typography variant="h5">
                          Welcome to {"Fundraiser name"}
                          {"'s"} donation page
                        </Typography>
                      </MDBox>
                    </MDBox>
                    <MDBox display="flex" alignItems="center" flexDirection="column" justifyContent="center">
                      <Typography  sx={{padding:'8px'}} fontSize={'14px'}>Fundraiser goal raised</Typography>
                      <GoalCompletionUI  value={10} color={"success"}/>
                    </MDBox>
                  </Card>

                </MDBox>
              </MDBox>
              <MDBox sx={{ marginRight: "12px" }}>
                <Divider
                  orientation="vertical"
                  component="div"
                  sx={{
                    "&.MuiDivider-root": {
                      backgroundColor: "#e9d5e9",
                      height: "100%",
                    },
                  }}
                />
              </MDBox>
              <MDBox
                width="50%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <MDBox
                  sx={{
                    overflowY: "auto",
                    height: "calc(100vh - 115px)",
                    padding: "12px",
                    "&::-webkit-scrollbar": {
                      width: "0.3em",
                      height: "0.4em",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      borderRadius: "4px",
                      outline: "1px solid slategrey",
                      backgroundColor: "#8b8b8b",
                    },
                  }}
                >
                  <Card sx={{ padding: "22px", marginBottom: "16px" }}>
                    <Typography
                      sx={{
                        justifyContent: "center",
                        textAlign: "center",
                        marginBottom: "12px",
                      }}
                    >
                      Secure Payment Form{" "}
                    </Typography>
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        label="Name"
                        fullWidth
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        label="Phone Number"
                        fullWidth
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput
                        type="email"
                        label="Email"
                        fullWidth
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput
                        style={{ width: "100%" }}
                        placeholder="Amount"
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput
                        style={{ width: "100%" }}
                        placeholder={"Card Number"}
                      />
                    </MDBox>
                    <MDBox mb={2} display="flex">
                      <MDBox>
                        <MDInput placeholder="MM/YY"/>
                      </MDBox>
                      <MDBox ml={2}>
                        <MDInput  placeholder="Cvv"/>
                      </MDBox>
                    </MDBox>
                    <MDBox display="flex" alignItems="center" ml={-1}>
                      <Checkbox
                        value={recurringPayment.recurring}
                        onChange={(e) => {
                          setRecurringPayment((prev) => {
                            return {
                              ...prev,
                              recurring: e.target.checked,
                            };
                          });
                        }}
                      />
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                      >
                        &nbsp;&nbsp;Recurring donation&nbsp;
                      </MDTypography>
                    </MDBox>
                    {recurringPayment.recurring && (
                      <MDBox display="flex" flexDirection="column">
                        <MDBox
                          display="flex"
                          flexDirection="row"
                          mt={2}
                          alignItems="center"
                        >
                          <Typography
                            variant="button"
                            fontWeight={"400"}
                            color="text"
                          >
                            Every
                          </Typography>
                          <TextField
                            variant="outlined"
                            value={recurringPayment.frequency}
                            onChange={(e) => {
                              setRecurringPayment(prev => {
                                return {
                                  ...prev,
                                  frequency : e.target.value
                                }
                              })
                            }}
                            sx={{
                              height: "24px",
                              marginLeft: "12px",
                              width: "48px",
                              "& .MuiOutlinedInput-root": {
                                height: "32px!important",
                              },
                            }}
                          />
                          <Select
                            sx={{
                              width: "150px",
                              marginTop: "8px",
                              height: "32px!important",
                              marginLeft: "12px",
                            }}
                            value={"month"}
                          >
                            <MenuItem value="month">Month(s)</MenuItem>
                          </Select>
                        </MDBox>
                        <MDBox display="flex" flexDirection="column" mt={2}>
                          <MDBox display="flex" flexDirection="column">
                            <Typography
                              variant="button"
                              fontWeight={"400"}
                              color="text"
                            >
                              End
                            </Typography>
                            <Select
                              value={recurringPayment.end}
                              onChange={(e) => {
                                setRecurringPayment((prev) => {
                                  return {
                                    ...prev,
                                    end: e.target.value,
                                  };
                                });
                              }}
                              sx={{
                                height: "42px",
                                "& .MuiSvgIcon-root": {
                                  display: "inline-block",
                                  fontSize: "24px!important",
                                },
                              }}
                            >
                              <MenuItem value="never">Never</MenuItem>
                              <MenuItem value="number_of_payments">
                                Number of Payments{" "}
                              </MenuItem>
                            </Select>
                            {recurringPayment.end === "number_of_payments" && (
                              <TextField
                                sx={{
                                  marginTop: "12px",
                                }}
                                value={recurringPayment.number_of_payments}
                                onChange={(e) => {
                                  setRecurringPayment((prev) => {
                                    return {
                                      ...prev,
                                      number_of_payments: e.target.value,
                                    };
                                  });
                                }}
                                type="number"
                              />
                            )}
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    )}
                    <MDBox mt={2} display="flex" justifyContent="center">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}
                        onChange={() => {}}
                      />
                    </MDBox>
                    <MDBox mt={2} mb={1}>
                      <MDButton
                        type="button"
                        variant="gradient"
                        color="info"
                        fullWidth
                      >
                        Donate
                      </MDButton>
                    </MDBox>
                  </Card>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </>
    );
}
export default DonationPagePreview;