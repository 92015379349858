import axios from "core/axios";
import { CROWDFUNDING_FUNDRAISER } from "constants/Endpoints";
import { CROWDFUNDING_ENDPOINT } from "constants/Endpoints";
import { CAMPAIGN } from "constants/Endpoints";
import { PROCESS_DONATION_PAYMENT } from "constants/Endpoints";
import { CROWDFUNDING_SETTINGS } from "constants/Endpoints";

export const CrowdfundingAPI = {

    getDonationData : async({token,fundraiser_id}) => {
        return await axios.get(`${CROWDFUNDING_FUNDRAISER}/load/crowdfunding/${fundraiser_id}`,{
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
    },
    deleteCampaign : async({campaignId,token}) => {
        return await axios.delete(`${CROWDFUNDING_ENDPOINT}${CAMPAIGN}/${campaignId}`,{
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
    },
    
    processPayment : async({payload}) => {
        return await axios.post(`${PROCESS_DONATION_PAYMENT}`,JSON.stringify(payload),{
            headers: {
                'Content-Type': 'application/json',
            }
        });
    },
    createCampaign : async({payload,token}) => {
        return await axios.post(`${CROWDFUNDING_ENDPOINT}${CAMPAIGN}`,JSON.stringify(payload),{
            headers: {
                'Content-Type': 'application/json',
            }
        });
    },
    giveUIAccessBulk : async({token,uiAccess,campaign_id}) => {
        return await axios.post(`${CROWDFUNDING_ENDPOINT}${CAMPAIGN}/uiaccess/${campaign_id}`,JSON.stringify(uiAccess),{
            headers: {
                'Content-Type': 'application/json',
            }
        });
    },
    updateCampaignSetting : async({payload}) => {
        return await axios.post(`${CROWDFUNDING_SETTINGS}`,JSON.stringify(payload),{
            headers: {
                'Content-Type': 'application/json',
            }
        });
    } 
}