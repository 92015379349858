import EmailSetting from "components/EmailSetting";
import SettingHeader from "components/SettingHeader";
import DashboardLayout from "lib/layouts/LayoutContainers/DashboardLayout";
import { memo, useState } from "react";
import SettingCard from "ui/SettingCard";
import { useSelector } from "react-redux";
import KeywordBasedResponse from "components/KeywordBasedResponse";
import DashboardNavbar from "lib/layouts/Navbars/DashboardNavbar";
import CompanyWidePolicy from "components/CompanyWidePolicy";
import AppConstants from "constants/AppConstants";
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import AutoReplyWrapper from "../../components/AutoReply/AutoReplyWrapper";
import { ROLE } from "constants/AppConstants";

const AutoReply = () => {
  
  const [tab, setTab] = useState(0);
  const user = useSelector(state => state.user);
  const role = useSelector(state => state.user.role);

  const onTabChange = (val) => {
    setTab(val);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        {user.company != null && user.company ? 
        <>
          <SettingHeader onTabChange={onTabChange}></SettingHeader>
          <SettingCard>
            {tab === 0 && <AutoReplyWrapper/>}
            {tab === 1 && <EmailSetting/>}
            {tab === 2 && <KeywordBasedResponse/>}
            {/* {tab === 3 && <CompanyDetails/> } */}
            {tab === 3 && <>
              {role === ROLE.SUPER_ADMIN && <CompanyWidePolicy/>}
            </>}
          </SettingCard>
        </>
          :<MDBox display="flex" justifyContent="center" alignItems="center">
            <MDTypography color={"info"}>
              {AppConstants.ACTION_NOT_ALLOWED}
            </MDTypography>
          </MDBox>
        }
      </DashboardLayout>
    </>
  );
};
export default memo(AutoReply);
