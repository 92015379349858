import { BASE_URL } from "constants/Endpoints"
import axios from "../core/axios";

export const SearchAPI = {
    searchMessages : async({payload,token,queryParams}) => {
        return await axios.post(`${BASE_URL}/sms/search?${queryParams}`,JSON.stringify(payload),{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }            
        })
    },
}