import MDBox from "lib/components/MDBox";
import CancelIcon from '@mui/icons-material/Cancel';
import style from "pages/Chat/Chat.module.css";

const InputImage = ({messageState,setMessageState}) => {

    return (
        <>
            {messageState.attachement &&<MDBox display="flex" flexDirection="row" justifyContent="space-evenly">
            <MDBox display="flex" flexDirection="column" width="200px" className={style["msg-attachment"]}>
                <CancelIcon onClick={() => setMessageState(prev => {
                    return {
                    ...prev,
                    attachement : undefined
                    }
                })}
                className={style["cancel_icon"]}
                />
                <img style={{height:'150px', width:'150px',objectFit:'contain'}} src={URL.createObjectURL(messageState.attachement)}/>
                </MDBox>
            </MDBox>}        
        </>
    )
}
export default InputImage;