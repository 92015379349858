import { FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import { useEffect } from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { ImportChatUtil } from "util/ImportChatUtil";
import SelectTimeZone from "components/util/SelectTimeZone";
import AppConstants from "constants/AppConstants";

export const SelectStyle = styled(Select)(({ theme }) => ({
    '& .MuiSvgIcon-root': {
        color: 'black!important',
        display : 'inline-block',
        fontSize:'22px!important',
        right: '0'
      },
      '& .MuiSelect-iconOpen':{
          fontSize:'22px!important'
      }
}));

const MappedColumns = ({data,onMapped}) => {

    const [mapped,setMapped] = useState({
        phone: '',
        recipient : '',
        message : '',
        mediaUrl : '',
        date : '',
        time : '',
        timeFormat : 24,
        timezone : '',
        messageType: 'SMS',
        inbound : {
            mapped : '',
            value : '',
            ui : undefined
        },
        outbound : {
            mapped: '',
            value : '',
            ui : undefined
        },
        status : {
            key : undefined,
            value : '',
            ui : undefined
        }
    })
    const [columns,setColumns] = useState([]);

    const onStatusMapped = (e) => {
        const target = e.target.value;
        const statusSet = ImportChatUtil.getAllUniqueForAColumn(data,target);
        let ui =  undefined;
        if(statusSet?.size > 1) {
            ui = <MDBox display="flex" flexDirection="column">
                <MDTypography fontSize={"14px"} color={'info'}>{AppConstants.IMPORT_STATUS_FIELD_MAP_COLUMN}</MDTypography>
                <MDBox>
                    <FormControl fullWidth sx={{marginRight:'8px'}}>
                        <InputLabel id="status-value">Status value</InputLabel>
                        <SelectStyle
                            labelId="status-value"
                            id="status-value-select"
                            // value={}
                            sx={{
                                height:'40px',
                                marginRight:'8px',
                                width: '100%',
                            }}
                            label="Status value"
                            onChange={(e) => {
                                setMapped(prev => {
                                    return{
                                        ...prev,
                                        status : {
                                            ...prev.status,
                                            value : e.target.value
                                        }
                                    }
                                })
                            }}
                        >
                            {Array.from(statusSet).map(itm => <MenuItem value={itm}>{itm}</MenuItem>)}
                        </SelectStyle>
                    </FormControl>
                </MDBox> 
            </MDBox>
        }
        else{
            const [first] = statusSet;
            ui = <MDBox display="flex" flexDirection="column">
                <MDTypography fontSize={"14px"} color={'info'}>{`Value will be mapped: ${first}`}</MDTypography>
            </MDBox>
        }  
        setMapped(prev => {
            return{
                ...prev,
                status : {
                    key : target,
                    value : '',
                    ui : ui
                }
            }
        });
    }

    const onPhoneNumberMapped = (e) => {
        const target = e.target.value;
        setMapped(prev => {
            return{
                ...prev,
                phone : target
            }
        });
    }

    const onRecipientMapped = (e) => {
        const target = e.target.value;
        setMapped(prev => {
            return{
                ...prev,
                recipient : target
            }
        });
    }

    const onContentMapped = (e) => {
        const target = e.target.value;
        setMapped(prev => {
            return{
                ...prev,
                message : target
            }
        });
    }
    const onDateMapped = (e) => {
        const target = e.target.value;
        setMapped(prev => {
            return{
                ...prev,
                date : target
            }
        });
    }

    const onMessageType = (e) => {
        setMapped(prev => {
            return{
                ...prev,
                messageType : e.target.value
            }
        })
    }

    const onTimeMapped = (e) => {
        const target = e.target.value;
        setMapped(prev => {
            return{
                ...prev,
                time : target
            }
        });
    } 

    const onMediaMapped = (e) => {
        const target = e.target.value;
        setMapped(prev => {
            return{
                ...prev,
                mediaUrl : target
            }
        });
    }

    const onInboundMapped = (e) => {
        const target = e.target.value;
        const inboundSet = ImportChatUtil.getAllUniqueForAColumn(data,target);
        let ui =  undefined;
        if(inboundSet?.size > 1) {
            ui = <MDBox display="flex" flexDirection="column">
                <MDTypography fontSize={"14px"} color={'info'}>{"Please select which value within this column indicates Inbound."}</MDTypography>
                <MDBox>
                    <FormControl fullWidth sx={{marginRight:'8px'}}>
                        <InputLabel id="inbound-value">Inbound value</InputLabel>
                        <SelectStyle
                            labelId="inbound-value"
                            id="inbound-value-select"
                            // value={}
                            sx={{
                                height:'40px',
                                marginRight:'8px',
                                width: '100%',
                            }}
                            label="Inbound value"
                            onChange={(e) => {
                                setMapped(prev => {
                                    return{
                                        ...prev,
                                        inbound : {
                                            ...prev.inbound,
                                            value : e.target.value
                                        }
                                    }
                                })
                            }}
                        >
                            {Array.from(inboundSet).map(itm => <MenuItem value={itm}>{itm}</MenuItem>)}
                        </SelectStyle>
                    </FormControl>
                </MDBox> 
            </MDBox>
        }
        else{
            const [first] = inboundSet;
            ui = <MDBox display="flex" flexDirection="column">
                <MDTypography fontSize={"14px"} color={'info'}>{`Value will be mapped: ${first}`}</MDTypography>
            </MDBox>
        }   
        setMapped(prev => {
            return{
                ...prev,
                inbound : {
                    ui : ui,
                    mapped : target
                }
            }
        });
    }

    const onOutboundMapped = (e) => {
        const target = e.target.value;
        const outboundSet = ImportChatUtil.getAllUniqueForAColumn(data,target);
        let ui =  undefined;
        if(outboundSet?.size > 1) {
            ui = <MDBox display="flex" flexDirection="column" width="100%">
                <MDTypography fontSize={"14px"} color={'info'}>{"Please select which value within this column indicates Outbound."}</MDTypography>
                <MDBox width="100%">
                    <FormControl fullWidth sx={{marginRight:'8px'}}>
                        <InputLabel id="outbound-value">Outbound value</InputLabel>
                        <SelectStyle
                            labelId="outbound-value"
                            id="outbound-value-select"
                            // value={}
                            sx={{
                                height:'40px',
                                width: '100%',
                                marginRight:'8px'
                            }}
                            label="Outbound value"  
                            onChange={(e) => {
                                setMapped(prev => {
                                    return{
                                        ...prev,
                                        outbound : {
                                            ...prev.outbound,
                                            value : e.target.value
                                        }
                                    }
                                })
                            }}                  
                        >
                            {Array.from(outboundSet).map(itm => <MenuItem value={itm}>{itm}</MenuItem>)}
                        </SelectStyle>
                    </FormControl>
                </MDBox>
            </MDBox>
        }
        else{
            const [first] = outboundSet;
            ui = <MDBox display="flex" flexDirection="column">
                <MDTypography fontSize={"14px"} color={'info'}>{`Value will be mapped: ${first}`}</MDTypography>
            </MDBox>
        }   
        setMapped(prev => {
            return{
                ...prev,
                outbound : {
                    ui : ui,
                    mapped : target
                }
            }
        });
    }

    useEffect(() => {
        if(mapped)
            onMapped({
                ...mapped,
                inbound : {
                    ...mapped.inbound,
                    ui : undefined
                },
                outbound : {
                    ...mapped.outbound,
                    ui : undefined
                },
                status : {
                    ...mapped.status,
                    ui : undefined
                }        
        });
    },[mapped]);

    useEffect(() => {
        if(data){
            // { id: 1, key :  "number", label: "Number",},
            const tmpCol = [];
            for(const itm in data){
                tmpCol.push(itm);
            }
            setColumns(tmpCol);
        }
    },[data]);
    return(
        <>
            {data && <MDBox display="flex" flexDirection="column" width="100%">
                <MDBox margin={'8px'}>
                    <MDTypography sx={{textAlign:'center'}}>{"Map the columns with respective data"}</MDTypography>
                </MDBox>
                <MDBox margin={'8px'}>
                    <FormControl fullWidth sx={{marginRight:'8px'}}>
                        <InputLabel id="phone-number">Phone number</InputLabel>
                        <SelectStyle
                            labelId="phone-number"
                            id="phone-number-select"
                            // value={}
                            sx={{
                                height:'40px',
                                marginRight:'8px'
                            }}
                            label="Phone Number"
                            onChange={onPhoneNumberMapped}
                        >
                            {columns.map(itm => <MenuItem key={"phone_" + itm} value={itm}>{itm}</MenuItem>)}
                        </SelectStyle>
                        <Typography margin={'8px'} fontSize={'12px'}>{" Company phone number that has been used for this message."}</Typography>
                    </FormControl>
                </MDBox>

                <MDBox margin={'8px'}>
                    <FormControl fullWidth sx={{marginRight:'8px'}}>
                        <InputLabel id="recipient-number">Recipient number</InputLabel>
                        <SelectStyle
                            labelId="recipient-number"
                            id="recipient-number-select"
                            // value={}
                            sx={{
                                height:'40px',
                                marginRight:'8px'
                            }}
                            label="Recipient Nunber"
                            onChange={onRecipientMapped}
                        >
                            {columns.map(itm => <MenuItem key={"recipient_" + itm} value={itm}>{itm}</MenuItem>)}
                        </SelectStyle>
                        <Typography margin={'8px'} fontSize={'12px'}>{"Recipient number that the message has been sent to/from."}</Typography>
                    </FormControl>
                </MDBox> 

                <MDBox margin={'8px'}>
                    <FormControl fullWidth sx={{marginRight:'8px'}}>
                        <InputLabel id="message">Message Content</InputLabel>
                        <SelectStyle
                            labelId="message"
                            id="message-select"
                            // value={}
                            sx={{
                                height:'40px',
                                marginRight:'8px'
                            }}
                            label="Message Content"
                            onChange={onContentMapped}
                        >
                            {columns.map(itm => <MenuItem key={"message_" + itm} value={itm}>{itm}</MenuItem>)}
                        </SelectStyle>
                        <Typography margin={'8px'} fontSize={'12px'}>Message Content.</Typography>
                    </FormControl>
                </MDBox> 

                <MDBox margin={'8px'}>
                    <FormControl fullWidth sx={{marginRight:'8px'}}>
                        <InputLabel id="message-media">Media Url</InputLabel>
                        <SelectStyle
                            labelId="message-media"
                            id="message-media-select"
                            // value={}
                            sx={{
                                height:'40px',
                                marginRight:'8px'
                            }}
                            label="Media Url"
                            onChange={onMediaMapped}
                        >
                            {columns.map(itm => <MenuItem key={"media_message_" + itm} value={itm}>{itm}</MenuItem>)}
                        </SelectStyle>
                        <Typography margin={'8px'} fontSize={'12px'}>{"Map the media column if it exists."}</Typography>
                    </FormControl>
                </MDBox> 

                <MDBox margin={'8px'}>
                    <FormControl fullWidth sx={{marginRight:'8px'}}>
                        <InputLabel id="inbound">Inbound</InputLabel>
                        <SelectStyle
                            labelId="inbound"
                            id="inbound-select"
                            // value={}
                            sx={{
                                height:'40px',
                                marginRight:'8px'
                            }}
                            label="Inbound"
                            onChange={onInboundMapped}
                        >
                            {columns.map(itm => <MenuItem key={"inbound_" + itm} value={itm}>{itm}</MenuItem>)}
                        </SelectStyle>
                        <Typography margin={'4px'} fontSize={'12px'}>{"Map inbound value."}</Typography>
                        <MDBox>
                            {mapped?.inbound?.ui}
                        </MDBox>
                    </FormControl>
                </MDBox>

                <MDBox margin={'8px'}>
                    <FormControl fullWidth sx={{marginRight:'8px'}}>
                        <InputLabel id="outbound">Outbound</InputLabel>
                        <SelectStyle
                            labelId="outbound"
                            id="outbound-select"
                            // value={}
                            sx={{
                                height:'40px',
                                marginRight:'8px'
                            }}
                            label="Outbound"
                            onChange={onOutboundMapped}
                        >
                            {columns.map(itm => <MenuItem key={"outbound_" + itm} value={itm}>{itm}</MenuItem>)}
                        </SelectStyle>
                        <Typography margin={'8px'} fontSize={'12px'}>{"Map outbound value."}</Typography>
                        <MDBox>
                            {mapped?.outbound?.ui}
                        </MDBox>                        
                    </FormControl>
                </MDBox> 

                <MDBox margin={'8px'}>
                    <FormControl fullWidth sx={{marginRight:'8px'}}>
                        <InputLabel id="date">Date</InputLabel>
                        <SelectStyle
                            labelId="date"
                            id="date-select"
                            // value={}
                            sx={{
                                height:'40px',
                                marginRight:'8px'
                            }}
                            label="Date"
                            onChange={onDateMapped}
                        >
                            {columns.map(itm => <MenuItem key={"date_" + itm} value={itm}>{itm}</MenuItem>)}
                        </SelectStyle>
                        <Typography margin={'8px'} fontSize={'12px'}>{"Map the date field if it exists."}</Typography>
                    </FormControl>
                </MDBox>  

                <MDBox margin={'8px'}>
                    <FormControl fullWidth sx={{marginRight:'8px'}}>
                        <InputLabel id="time">Time</InputLabel>
                        <SelectStyle
                            labelId="time"
                            id="time-select"
                            // value={}
                            sx={{
                                height:'40px',
                                marginRight:'8px'
                            }}
                            label="Time"
                            onChange={onTimeMapped}
                        >
                            {columns.map(itm => <MenuItem key={"time_" + itm} value={itm}>{itm}</MenuItem>)}
                        </SelectStyle>
                        <Typography margin={'8px'} fontSize={'12px'}>{"Map the time field if it exists."}</Typography>
                    </FormControl>
                </MDBox>  
                {
                    mapped.time.trim().length > 0 &&
                <MDBox margin={'8px'}>
                    <FormControl fullWidth sx={{marginRight:'8px'}}>
                        <InputLabel id="time-format">Time Format</InputLabel>
                        <SelectStyle
                            labelId="time-format"
                            id="time-format-select"
                            value={mapped.timeFormat}
                            sx={{
                                height:'40px',
                                marginRight:'8px'
                            }}
                            label="Time Format"
                            onChange={(e) => {
                                setMapped(prev => {
                                    return{
                                        ...prev,
                                        timeFormat : e.target.value
                                    }
                                })
                            }}
                        >
                            <MenuItem key={'12hours'} value={12}>12 Hour (AM/PM)</MenuItem>
                            <MenuItem key={'24hours'} value={24}>24 Hour</MenuItem>
                        </SelectStyle>
                        <Typography margin={'8px'} fontSize={'12px'}>{"Select the time format."}</Typography>
                    </FormControl>
                </MDBox>                  
                }
            {/**
             * Message Type
             */}

                <MDBox margin={'8px'}>
                    <FormControl fullWidth sx={{marginRight:'8px'}}>
                        <InputLabel id="message-type">Message Type</InputLabel>
                        <SelectStyle
                            labelId="message-type"
                            id="message-type-select"
                            // value={}
                            sx={{
                                height:'40px',
                                marginRight:'8px'
                            }}
                            label="Message Type"
                            onChange={onMessageType}
                        >
                            <MenuItem key={"sms"} value={"SMS"}>{"SMS"}</MenuItem>
                            <MenuItem key={"wa"} value={"WHATSAPP"}>{"WA (WhatsApp)"}</MenuItem>
                        </SelectStyle>
                        <Typography margin={'8px'} fontSize={'12px'}>{"Select message type"}</Typography>
                    </FormControl>
                </MDBox> 
                {/**Status => sent or failed */}
                <MDBox margin={'8px'}>
                    <FormControl fullWidth sx={{marginRight:'8px'}}>
                        <InputLabel id="message-media">Message status</InputLabel>
                        <SelectStyle
                            labelId="message-status"
                            id="message-status-select"
                            // value={}
                            sx={{
                                height:'40px',
                                marginRight:'8px'
                            }}
                            label="Message status"
                            onChange={onStatusMapped}
                        >
                            {columns.map(itm => <MenuItem key={"message_status_" + itm} value={itm}>{itm}</MenuItem>)}
                        </SelectStyle>
                        <Typography margin={'8px'} fontSize={'12px'}>{"Map the status column (it indicates whether message got delivered successfully.)"}</Typography>
                    </FormControl>
                    <MDBox>
                        {mapped?.status?.ui}
                    </MDBox>
                </MDBox> 

                <MDBox margin={'8px'}>
                    <SelectTimeZone onSelected={res => {
                        setMapped(prev => {
                            return{
                                ...prev,
                                timezone : res
                            }
                        })
                    }}/>
                </MDBox>
            </MDBox>}
        </>
    )
}
export default MappedColumns;