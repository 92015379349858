import { BASE_URL } from "constants/Endpoints";
import ShowCarrier from "components/Carrier/ShowCarrier";
import ChooseFile from "components/CommonUI/ChooseFile";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { PhoneAPI } from "services/PhoneAPI";
import AppModal from "ui/AppModal";
import {useDispatch} from 'react-redux';
import {loaderAction} from 'store/loader-slice';

let carrier_id = undefined;
const UploadWhatsAppBridgeNumbers = ({onCancel,onUploaded}) => {

    const token = useSelector(state => state.user.token);
    const appDispatch = useDispatch();

    const onCarrierSelected = (res) => {
        carrier_id = res;
    }

    const onFileSelected = (file) => {
        if(file){
            const formData = new FormData();
            formData.append('file',file);
            formData.append("carrier_id",carrier_id)
            if(carrier_id){
                 // show loader
                appDispatch(loaderAction.show());
               PhoneAPI.uploadWhatsAppBridgeNumbers({formData,token}).then(res => {
                    appDispatch(loaderAction.hide());
                    toast.success("uploaded successfully.");
                    onUploaded();
               }).catch(e => {
                    appDispatch(loaderAction.hide());
                    toast.error(e.message);
                    onCancel();
               })         
            }
            else{
                toast.error("Please select a carrier.")
            }
            // onCsvAdded(file);
        }
    }      
    return(
        <AppModal
            onModalClose={onCancel}
            heading={"Choose csv file."}
        >
            <ShowCarrier onCarrierSelected={onCarrierSelected}/>
            <ChooseFile onFileSelected={onFileSelected} templateTooltipMsg="Download template" showTemplate={true} templateUrl= {BASE_URL + "/download/template/bulk_numbers.xlsx"} />
        </AppModal>
    )
}
export default UploadWhatsAppBridgeNumbers;