import { Button } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { useState } from "react";
import AppModal from "ui/AppModal";
import CustomerDetails from "./CustomerDetails";
import { useDispatch, useSelector } from "react-redux";
import { CustomerAPI } from "services/CustomerAPI";
import { loaderAction } from "store/loader-slice";
import { toast } from "react-toastify";
import CustomerResponse from "./CustomerResponse";
import GeneratePassword from "./GeneratePassword";

const CustomerAction = ({customer}) => {
    const [showDetails, setShowDetails] = useState(false);
    const [generatePass, setGeneratePass] = useState(false);
    const [showResModal,setShowResModal] = useState(undefined);
    const token = useSelector(state => state.user.token);
    const dispatch = useDispatch();

    const activatePlan = () => {
        dispatch(loaderAction.show());
        CustomerAPI.activateCustomer({token,id : customer.id}).then(res => {
            dispatch(loaderAction.hide());
            setShowResModal(res.data);
        }).catch(err => {
            dispatch(loaderAction.hide());
            toast.error(err |err.message);
        });
    }
    return(
        <>
            <MDBox display="flex" flexDirection="row">
                <MDBox sx={{ marginLeft: "8px" }} display="flex">
                    {customer?.status === 'pending' && <Button onClick={activatePlan}>Activate</Button>}
                    {(customer?.status === 'active' ||  customer?.status === 'partial') && <Button onClick={() => setGeneratePass(true)}>Send password</Button>}
                    <Button onClick={() => setShowDetails(true)}>Details</Button>
                </MDBox>
            </MDBox>
            
            {showDetails && 
                <AppModal
                    rootStyle = {
                        {
                        "&.MuiModal-root":{
                            width:'90%',
                            left: '10%'
                        }
                        }
                    }
                    customStyle={{ 
                        width: "100%",
                        height: "100%",
                        overflowY: "auto",
                        border: "unset",
                    }}
                    heading={" "}
                  onModalClose={() => setShowDetails(false)}                   
                >
                    <CustomerDetails customer={customer}/>
                </AppModal>
            } 
            {showResModal !== undefined && 
                <AppModal
                    onModalClose={() => setShowResModal(undefined)} 
                    heading={'Activation report'}
                >
                    <CustomerResponse response={showResModal}/>
                </AppModal>
            }
            {generatePass && 
                <AppModal
                    rootStyle = {
                        {
                        "&.MuiModal-root":{
                            width:'90%',
                            left: '10%'
                        }
                        }
                    }
                    customStyle={{ 
                        width: "100%",
                        height: "100%",
                        overflowY: "auto",
                        border: "unset",
                    }}
                    heading={"Regenerate password"}
                    onModalClose={() => setGeneratePass(false)}                
                >
                    <GeneratePassword users={customer?.users}/>
                </AppModal>
            }
        </> 
    )
}
export default CustomerAction;