import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { removePhoneFromUser } from 'api/phoneCredentials';
import useHttp from 'hooks/use-http';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const RemoveUserFromPhone = ({phoneId,userId,onUserRemoved,token}) => {

    const {sendRequest : removeUserFromPhoneFn , status, error, data} = useHttp(removePhoneFromUser,false);
    const onUserRemoveHandler = () => {
        removeUserFromPhoneFn({phoneId,userId,token});
    }
    useEffect(() => {
        if(status === 'completed'){
            if(error === null){
                toast.success("Phone number unassigned to the user successfully");
                onUserRemoved(data);
            }
            else{
                toast.error("something went wrong");
            }
        }
    },[status])
    return (
        <HighlightOffIcon onClick={onUserRemoveHandler} sx={{margin:'12px',cursor:'pointer',fontSize:'32px!important'}}/>
    )
}
export default RemoveUserFromPhone;