import { MenuItem, Select } from "@mui/material";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import AppModal from "ui/AppModal";
import { getGroups } from "api/group";
import LogDetailsCollapsibleTable from "./LogDetailsCollapsibleTable";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getGroupLogs } from "api/Logs";

const BulkLogs = ({onClose,currentActivePhone}) => {

    const token = useSelector(state => state.user.token);

    const [groups, setGroups] = useState([]);

    const [currentSelected, setCurrentSelected] = useState("");

    const [logs, setLogs] = useState([]);

    const {sendRequest : getGroupsFn, data : groupsData, status : gstatus, error : gerror} = useHttp(getGroups,false)
    
    const {sendRequest : getGroupLogsFn , data : groupLogs, error : grpLogsFetchErr, status : grpLogsFetchStatus}
    = useHttp(getGroupLogs,false);

    const onGroupChangeHandler = (e) => {
        setCurrentSelected(e.target.value);
    }
    useEffect(() => {
        if(currentActivePhone){
            getGroupsFn({phoneId : currentActivePhone.phoneId,token});
        }
    },[currentActivePhone]);

    useEffect(() => {
        if(gstatus === 'completed'){
            if(groupsData){
                setGroups(groupsData);
                console.log(groupsData[0]?.groupId)
                setCurrentSelected(groupsData[0]?.groupId);
            }
        }
    },[gstatus,gerror,groupsData]);    

    useEffect(() => {
        if(currentSelected){
            getGroupLogsFn({token,group_id:currentSelected})
        }
    },[currentSelected]);

    useEffect(() => {
        if(grpLogsFetchStatus === 'completed'){
          if(groupLogs){
            setLogs(groupLogs);
          }
        }
      },[grpLogsFetchErr,grpLogsFetchStatus,groupLogs]);

    return(
        <AppModal 
            rootStyle = {
                {
                "&.MuiModal-root":{
                    width:'90%',
                    left: '10%'
                }
                }
            }
            customStyle={{width:'100%',height:'100%',overflowY:'scroll',border:'unset'}} 
            onModalClose={() => onClose()} 
            heading={'Bulk Logs'}
        >
            <MDBox display="flex" flexDirection="column">
                <MDBox sx={{padding:'16px'}} display="flex" flexDirection="row" justifyContent="space-around">
                <MDTypography>Select group:</MDTypography>
                <Select
                    value={currentSelected}
                    onChange={onGroupChangeHandler}
                    sx={{ 
                        width: "200px",
                        height: "32px",
                        '& .MuiSvgIcon-root': {
                            color: 'black!important',
                            display : 'inline-block',
                            fontSize:'24px!important',
                            right: '0'
                        },
                        '& .MuiSelect-iconOpen':{
                            fontSize:'24px!important'
                        }
                    }}                 
                >
                    {groups?.map(group => 
                        <MenuItem 
                            key={group.groupId} 
                            value={group.groupId}>
                            {group.name}
                        </MenuItem>)}
                </Select>
                </MDBox>
            </MDBox>
            <LogDetailsCollapsibleTable logsMeta={logs}/>
        </AppModal>
    )
}
export default BulkLogs;