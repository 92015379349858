import { getCampaign } from "api/campaign";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AppModal from "ui/AppModal";
import SettingTable from "./SettingTable";
import { Button, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { saveCampaignDonationLink } from "api/campaign";
import Swal from "sweetalert2";
import { CrowdfundingAPI } from "services/Crowdfunding/CrowdfundingAPI";
const SettingMenu = ({ onClose, campaign,onCampaignDeleted}) => {

  const location = useLocation();
  const [donationLink, setDonationLink] = useState();

  const token = useSelector(state => state.user.token);
  const {sendRequest,status, data, error} = useHttp(getCampaign,false);
  const {sendRequest : saveDonationLinkFn , status : savedStatus, data : savedData , error : linkSavedErr} = useHttp(saveCampaignDonationLink,false);
  
  const generateDonationLink = () => {
    const base1 = window.location.origin;
    const encodedCamp = encodeURIComponent(campaign?.campaign_id);
    const base = base1 + `/donate/${encodedCamp}`;

    setDonationLink(base);
    const payload = {
      donation_link : base,
      campaign_id : campaign.campaign_id
    }
    const payloadData = {
      payload : payload,
      token 
    }
    saveDonationLinkFn(payloadData)
  }

  const deleteCampaignHandler = useCallback(() => {
    Swal.fire({
      title: 'Delete this campaign?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      customClass: {
        container : {
          zIndex : '10000 !important'
        }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        CrowdfundingAPI.deleteCampaign({token,campaignId :  campaign.campaign_id}).then(res => {
          toast.success("Deleted successfully.");
          onCampaignDeleted(res.data);
        }).catch(e => console.log(e));
      }
    })
  },[campaign,token])

  useEffect(() => {
    if(campaign)
      sendRequest({token,campaignId : campaign.campaign_id});
  },[campaign]);
  
  useEffect(() => {
    if(status === 'completed'){
      if(data && data?.donation_link){
        setDonationLink(data?.donation_link);
      }
    }
  },[status,data,error])
  return (
    <AppModal
      heading={"Campaign Settings"}
      rootStyle = {
        {
          "&.MuiModal-root":{
            width:'80%',
            left: '20%'
          }
        }
      }
      customStyle={{ 
        width: "100%",
        height: "100%",
        overflowY: "auto",
        border: "unset",
      }}
      onModalClose={() => onClose()}
    >
      <MDBox display="flex" flexDirection="column">
        <MDBox
          sx={{ padding: "12px" }}
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
        >
        </MDBox>
      </MDBox>
      {/* <MDBox mb={2} display="flex" flexDirection="row" alignItems="center">
        {!donationLink && <><Typography mr={1}>Generated donation url </Typography><Tooltip title="Click here to generate donation link for this campaign">
          <AddLinkIcon onClick={generateDonationLink} sx={{height:'1.5rem', width:'1.5rem',cursor:'pointer'}}/>
        </Tooltip>
        </>}
        {donationLink && 
          <MDBox ml={2} width="90%">
            <Typography>Use this link for donation</Typography>
            <TextField
              multiline
              variant="standard"
              type="text"
              fullWidth
              value = {donationLink}
            />
          </MDBox>
        }
      </MDBox> */}
      {status==='completed' && <SettingTable campaign={data} />}
      <MDBox>
        <Typography>Actions</Typography>
        <Button onClick={deleteCampaignHandler} variant="outlined" color="error" sx={{color:'red!important'}}>Delete Campaign</Button>
      </MDBox>
    </AppModal>
  );
};
export default SettingMenu;
