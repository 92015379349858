import { SearchInput } from "pages/Chat/ChatStyles";
import { AddPhoneontainer } from "pages/Chat/ChatStyles";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from "@mui/icons-material/Search";
import { memo} from "react";
import { ChatAPI } from "services/IChat/ChatAPI";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import SearchList from "./Search/SearchList";
import Contact from "./Contact";
import MDBox from "lib/components/MDBox";
import { Typography } from "@mui/material";
import useDebouncedWatch from "hooks/use-debounce-watch";
import { IChatAction } from "store/IChat/Ichat-slice";
import { isChatAlreadyAdded } from "util/IChat/IChatUtil";
import { bringToTop } from "util/IChat/IChatUtil";
import { formatChat } from "util/IChat/IChatUtil";
import { loaderAction } from "store/loader-slice";
import { v4 as uuidv4 } from 'uuid';
import { ichatVariablesAction } from "store/IChat/Ichat-variables-slice";
import { IVIEWS } from "constants/ChatConstants";

const ContactSearch = ({searchRef}) => {

    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const chats = useSelector(state => state.Ichat.chats);

    const [isContactSearch,setIsContactSearch] = useState(undefined);
    const [recipients,setRecipients] = useState([]);
    const [searchPattern,setSearchPattern] = useState('');

    const handleContactSearch = (e) => {
        const searchText = e.target.value;
        setSearchPattern(searchText);
        setIsContactSearch(searchText.trim().length > 0 ? e : undefined);
    }

    const handleOnMessageClick = async(recipient) => {
        setIsContactSearch(undefined);
        dispatch(loaderAction.show());
        dispatch(ichatVariablesAction.setActiveView(IVIEWS.INORMAL_VIEW));
        let newChat = {};
        await ChatAPI.getChatByParticipants({token : user.token,authorId : user.id,userId : recipient.userId})
        .then(res => {
            newChat = formatChat(res.data,user.id);
        }).catch(e => {
            const tempChatId = uuidv4();
            const participant = {
                profilePic : recipient.profilePic,
                fullName : recipient.fullName,
                userId : recipient.userId,
                email : recipient.email,
                contactNumber : recipient.contactNumber,
                company : recipient.company,
                chatId : tempChatId,
                newChat : true
            }
            newChat = {
                chatId : tempChatId,
                meParticipantId : user.id,
                iEvent : undefined,
                participants : [participant],
                profilePic : recipient.profilePic,
                newChat : true,
                name : participant.fullName,
                recipientId : recipient.userId,
                tempChatId : tempChatId
            }
            
        });
        setSearchPattern('');
        //Only add if the recipient|chat is not already added, if added just set current active chat and bring it to top:
        const added = isChatAlreadyAdded(chats,newChat.participants[0].userId);
        if(added.length === 0){
            dispatch(IChatAction.addChat(newChat));
        }else{
            const newArr = bringToTop(chats,added);
            dispatch(IChatAction.resetChat([...newArr]))
        }
        dispatch(IChatAction.updateCurrentActiveChat(newChat));
        dispatch(loaderAction.hide());
    }

    useDebouncedWatch(() => {
        if(searchPattern.trim().length > 0){
            ChatAPI.searchMyCompanyUsersExceptMe({token : user.token, companyId : user.company?.company_id, userId : user.id,pattern : searchPattern})
            .then(res => {
                setRecipients(res.data.users);
            }).catch(e => console.log(e));
        }
    },[searchPattern]);

    return(
        <>
            <AddPhoneontainer>
                <SearchInput value={searchPattern} onChange={handleContactSearch} placeholder="Search or start a new chat"/>
            </AddPhoneontainer>
            {isContactSearch && (
                <SearchList popoverTarget={searchRef.current}>
                    <MDBox minWidth="30%" maxWidth="420px">
                        {recipients.length > 0 ? recipients.map(item => user.id !== item.userId ? <Contact  actionText={"Message"} onActionClick={handleOnMessageClick} key={item.fullName} info={item} /> : undefined) 
                            : <Typography padding={"12px"}>No Result found...</Typography>                       
                        }
                    </MDBox>
                </SearchList>
            )}
            <SearchIcon
                fontSize="medium"
                className="navbar-icon icon-inside-search"
            />
            {isContactSearch && 
                <CloseIcon
                    sx={{cursor:'pointer'}}
                    fontSize="medium"
                    className="navbar-icon cross-inside-search"
                    onClick={() => {
                        setIsContactSearch(undefined);
                        setSearchPattern('');
                    }}
                />
            }
        </>
    )
}
export default memo(ContactSearch);