import MDBox from "lib/components/MDBox";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ChatAPI } from "services/IChat/ChatAPI";
import IUser from "../IUser";
import { Avatar, Button, Checkbox, Chip, FormControlLabel, IconButton, InputAdornment, InputBase, Stack, TextField, Typography } from "@mui/material";
import { Add, Search, Send, VisibilityOff } from "@mui/icons-material";
import { useRef } from "react";
import { IGroupAPI } from "services/IChat/IGroupAPI";
import SearchIcon from '@mui/icons-material/Search';
import { formatGroupChat } from "util/IChat/IChatUtil";
import { IGroupAction } from "store/IChat/igroup-slice";
import { filterUser } from "util/IChat/IChatUtil";
import { memo } from "react";
import { loaderAction } from "store/loader-slice";

const ICreateGroup = ({onGroupCreated}) => {

    const dispatch = useDispatch();

    const groupNameRef = useRef();
    const groupDescRef = useRef();

    const [allUsers,setAllUsers] = useState([]);

    const [users,setUsers] = useState([]);
    const currUser = useSelector(state => state.user);
    const [selectedUsers,setSelectedUsers] = useState([]);
    const [allSelected,setAllSelected] = useState([]);

    const createGroup = useCallback(() => {
        if(groupNameRef?.current?.value?.trim().length === 0){
            toast.error("Group name is required.");
            return;
        }
        dispatch(loaderAction.show());
        const payload  = {
            groupChat : true,
            participants : [...selectedUsers,{userId : currUser.id}],
            chatName : groupNameRef?.current?.value,
            companyId : currUser.company.company_id,
            description : groupDescRef?.current?.value,
            adminId : currUser.id
        }
        IGroupAPI.createGroup({token : currUser.token,payload}).then(res => {
            const formatChat = formatGroupChat(res.data,currUser.id);
            dispatch(IGroupAction.addGroup(formatChat));
            onGroupCreated();
            dispatch(loaderAction.hide());
        }).catch(ex => {
            dispatch(loaderAction.hide());
            toast.error(ex);
        });
    },[currUser,dispatch])

    const handleSearch = useCallback((e) => {
        const filteredUsers = filterUser(allUsers,e.target.value);
        setUsers(filteredUsers);
    },[allUsers])

    const onSelected = useCallback((res) => {
        if(res.checked) {
            // if user is not then add
            const exists = selectedUsers.find(itm => itm.userId === res.user.userId);
            console.log(exists)
            if(!exists){
                setSelectedUsers(prev => [...prev,res.user]);
            }
        }
        else{
            const newSelection = selectedUsers.filter(item => item.userId !== res.user.userId);
            setSelectedUsers(newSelection);
        }
    },[selectedUsers,setSelectedUsers])

    const onSelectAll = useCallback((e) => {
        if(e.target.checked) {
            setSelectedUsers(users);
            setAllSelected(true);
        }
        else{
            setSelectedUsers([]);
            setAllSelected(false);
        }
    },[users,setAllSelected]);

    useEffect(() => {
        ChatAPI.getMyCompanyUsersExceptMe({token : currUser.token, userId : currUser.id,companyId : currUser.company.company_id})
            .then(res => {
                const fUser = res.data.map(itm => {
                    return{
                        ...itm,
                        checked : false
                    }
                })
                setAllUsers(fUser);
                setUsers(fUser);
            }).catch(e => toast.error(e))
    },[]);
    
    useEffect(() => {
        if(selectedUsers.length === 0){
            setAllSelected(false);
        }
    },[selectedUsers]);

    return (
        <>
            <MDBox display="flex" flexDirection="column"> 
                <MDBox mb={1} width="100%" display="flex" flexDirection="column">
                    <TextField inputRef={groupNameRef} variant="standard" placeholder="Group name" sx={{width:'100%',padding:'8px',marginBottom:'8px'}}/>
                    <TextField inputRef={groupDescRef} variant="standard" placeholder="Description (Optional)" sx={{width:'100%',padding:'8px'}}/>
                </MDBox>
                <MDBox width="100%">
                    <TextField
                        placeholder="Search"
                        sx={{width:'100%',padding:'8px'}}
                        onChange={handleSearch}
                        variant="standard"
                        InputProps={{
                            endAdornment: 
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="search"
                                    edge="end"
                                >
                                    {<SearchIcon/>}
                                </IconButton>
                            </InputAdornment>
                        }}
                        
                    />
                </MDBox>                 
            </MDBox>  
            <MDBox display="flex" sx={{margin:'12px'}}>
                <FormControlLabel onChange={onSelectAll} checked={allSelected} control={<Checkbox />} label="Select All" />              
            </MDBox>      
            <MDBox maxHeight='328px' sx={{overflowY:'auto',margin:'12px'}}>
                {selectedUsers?.length > 0 && <Typography fontSize={"14px"} sx={{marginBottom:'4px'}} color={"blue"}>{"Participants"}</Typography>}
                <Stack direction="row" spacing={1} flexWrap={"wrap"}>
                    {selectedUsers && 
                        selectedUsers.map(chip => {
                            return(
                                <Chip
                                    key={"chip_" + chip?.userId}
                                    avatar={<Avatar alt={chip.fullName} src={chip.profilePic} />}
                                    label={chip.fullName}
                                    variant="outlined"
                                    // onDelete={() => removeUser(chip)}
                                    sx={{margin:'4px'}}
                                />
                            )
                        })
                    }       
                </Stack>                
                {users.map(u => <IUser key={u.userId} onSelected={onSelected} user={u} select={allSelected ? true : u.checked}/>)}  
            </MDBox>
            <MDBox display="flex" width="100%" alignItems="center" justifyContent="flex-end">
                <Button
                    onClick={createGroup}
                    disabled={selectedUsers.length === 0}
                    sx={{
                        color: "white!important",
                        border: "unset",
                        width: "96px",
                        padding: "10px",
                        height: "35px",
                        backgroundColor: "#1876F2!important",
                    }}
                    variant="contained"
                    size="medium"
                    endIcon={<Add/>}
                >
                    Create
                </Button>
            </MDBox>
        </>
    )
}
export default memo(ICreateGroup);