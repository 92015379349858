import React, { useEffect, useState, useCallback } from 'react';
import style from "./Preview.module.css";
import { styled } from '@mui/material/styles';

import MDBox from 'lib/components/MDBox';
import { Button, Card, FormControl, Grid, InputLabel, Link, MenuItem, Paper, Select, TextField, Tooltip, Typography } from '@mui/material';
import { languages_list } from 'constants/LanguageList';
import Accordian from 'components/util/Accordian';
import WOptionalComponent from './WOptionalComponent';
import placeholder from "assets/images/image_placeholder.png";
import video_placeholder from "assets/images/play-button.svg";
import doc_placeholder from "assets/images/document-image.svg";
import NotificationsIcon from '@mui/icons-material/Notifications';

import { createWTemplate } from 'api/whatsapp';
import { useSelector } from 'react-redux';
import AppModal from 'ui/AppModal';
import ExampleTemplate from './ExampleTemplate';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import KeyIcon from '@mui/icons-material/Key';
import { Campaign } from '@mui/icons-material';
import { WHATSAPP_INS } from 'constants/AppConstants';
import { getVariablesCount } from "util/WhatsTemplateUtil";
import useHttp from "hooks/use-http";
import { toast } from "react-toastify";
import AppConstants from 'constants/AppConstants';
import { formatWAName } from 'util/UtilityFunctions';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0.25),
  textAlign: 'center',
  color: '#0fa6f4',
}));

const wCategories = [
  { name: 'Utilities', icon: <NotificationsIcon />, value: 'UTILITY', text: 'Messages about a specific transaction, account, order or customer request.' },
  { name: "Authentication", icon: <KeyIcon />, value: 'AUTHENTICATION', text: 'One time passwords that your customers use to authenticate a transaction or login.' },
  { name: "Marketing", icon: <Campaign />, value: 'MARKETING', text: 'Promotions or information about your business, product or services. Or any message that isn\'t utility or authentication.' },
]
const TemplatePreview = ({ onTemplateCreated, company, waCredentials }) => {
  const currUser = useSelector(state => state.user);
  const [showExampleModal, setShowExampleModal] = useState(false);
  const [templateVariables, setTemplateVariables] = useState({
    template_name: '',
    category: '',
    language: 'en_US',
    body: '',
    header: {
      type: 'text',
      value: ''
    },
    footer: '',
    btn: {
      type: 'call',
      isPresent: false,
      callActions: [
        {
          text: '',
          type: '',
          value: '',
        },
        {
          text: '',
          type: '',
          value: '',
        }
      ],
      reply: ['', '', '']
    }
  });
  const { sendRequest: createWTemplateFn, status, data, error } = useHttp(createWTemplate, false);

  const handleTemplateSubmission = useCallback(() => {
    const variables = getVariablesCount(templateVariables);
    createWTemplateFn({
      token: currUser.token,
      template: {
        templatePreviewVariables:templateVariables,
        variables,
        companyId: company?.company_id,
        credentials: { ...waCredentials }
      }
    });
  }, [currUser, templateVariables, waCredentials, company])
  const handleTemplateSubmit = () => {
    const ans = getVariablesCount(templateVariables);
    if (ans.body?.length > 0) {
      setShowExampleModal(true)
    } else {
      handleTemplateSubmission()
    }
  }

  const handleCategorySelect = (value) => {
    setTemplateVariables(prev => {
      return {
        ...prev,
        category: value
      }
    })
  }
  const renderPreview = () => {
    // let previewText = templateVariables.body;
    // for (const [variable, value] of Object.entries(templateVariables)) {
    //   previewText = previewText.replace(`{{${variable}}}`, value);
    // }
    return (
      <div className={style["whatsapp-message"]}>
        <div className={style["whatsapp-avatar"]} />
        <div className={style["whatsapp-message-content"]}>
          <div className={style["whatsapp-sender"]}>Preview</div>
          <div className={style["whatsapp-text"]}>
            {templateVariables.header.type === 'text' &&
              <div style={{ fontSize: '18px', fontWeight: 'bold' }}>{templateVariables.header.value}</div>
            }
            {templateVariables.header.type === 'image' &&
              <div>
                <img src={placeholder} height="100" width="200" style={{ objectFit: 'contain' }} />
              </div>
            }
            {templateVariables.header.type === 'docs' &&
              <div>
                <img src={doc_placeholder} height="100" width="200" style={{ objectFit: 'contain' }} />
              </div>
            }
            {templateVariables.header.type === 'video' &&
              <div>
                <img src={video_placeholder} height="100" width="200" style={{ objectFit: 'contain' }} />
              </div>
            }
            <div><pre style={{ whiteSpace: 'pre-line' }}>{templateVariables.body}</pre></div>
            <div style={{ marginTop: '6px', color: '#818181', fontSize: '14px', fontWeight: '100' }}>{templateVariables.footer}</div>
          </div>
          <div style={{ width: '200px' }}>
            {templateVariables.btn?.type === 'call' && <Grid container>
              {templateVariables.btn?.callActions?.map(entry => {
                return entry.text !== '' ? <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                  <Item>{entry.text}</Item>
                </Grid> : undefined;
              })}
            </Grid>
            }
            {templateVariables.btn?.type === 'reply' && <Grid container spacing={1}>
              {templateVariables.btn?.reply?.map(item => {
                return item !== '' ? <Grid sx={{ marginBottom: '8px' }} item xs={12}><Item>{item}</Item></Grid> : undefined;
              })}
            </Grid>
            }
          </div>
          <div className={style["whatsapp-timestamp"]}>Just now</div>
        </div>
      </div>
    );
  };
  useEffect(() => {
    if (status === 'completed') {
      if (data) {
        toast.success("Template is submitted for approval.");
        onTemplateCreated(data);
      }
      else {
        toast.error(error);
      }
    }
  }, [status, data, error])
  return (
    <>
      <MDBox display="flex" width="100%">
        <MDBox width="70%" marginLeft="8px" sx={{
          height: 'calc(100vh - 25px)',
          overflowY: 'auto',
          marginRight: '18px',
          "&::-webkit-scrollbar": {
            width: "0.3em",
            height: "0.4em"
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: " 4px",
            backgroundColor: "#8b8b8b"
          }
          }}>

          <MDBox display="flex" flexDirection="column" width="70%" mt={3}>
              <Typography fontWeight={600}>Category</Typography>
            <Card width="100%" sx={{padding:'12px',marginTop:'8px'}}>
              <Typography fontSize={"14px"}>Choose a category that best describes your message template. <a href='https://developers.facebook.com/docs/whatsapp/updates-to-pricing/new-template-guidelines/' target='_blank'>Learn more about categories</a></Typography>
              {wCategories.map((item, index) => {

                return (
                  <MDBox onClick={handleCategorySelect.bind(null, item.value)} mt={index === 0 ? 2 : 0} sx={{ cursor: 'pointer', backgroundColor: item.value === templateVariables.category ? '#c4c4ec' : 'ghostwhite', marginBottom: '8px' }} key={item.value} display="flex" padding="12px">
                    <MDBox mr={2} display="flex" alignItems="center">
                      {item.icon}
                    </MDBox>

                    <MDBox display="flex" flexDirection="column">
                      <Typography>{item.name}</Typography>
                      <Typography fontSize={"12px"}>{item.text}</Typography>
                    </MDBox>
                  </MDBox>
                )

              })}

            </Card>
          </MDBox>

          <MDBox mt={3} display="flex" flexDirection="column" width="70%">
              <Typography fontWeight={600}>Template name</Typography>    
              <TextField
                variant='standard'
                placeholder='Enter template name'
                onChange={(e) => {
                  let name = formatWAName(e.target.value);
                  
                  setTemplateVariables(prev => {
                    return {
                      ...prev,
                      template_name : name
                    }
                  })
                }}
                value={templateVariables.template_name}
              />
              <Typography marginTop='8px' fontSize={'12px'}>{AppConstants.WA_TEMPLATE_NAME_INS}</Typography>
          </MDBox>

          <MDBox display="flex" flexDirection="column" width="70%" mt={3}>
            <Typography fontSize="16px">Language</Typography>
            <FormControl variant="standard" sx={{ minWidth: 120 }}>
              <Select
                labelId="w-category-standard-label"
                id="w-category"
                value={templateVariables.language}
                onChange={e => {
                  setTemplateVariables(prev => {
                    return {
                      ...prev,
                      language: e.target.value
                    }
                  })
                }}
                sx={{
                  '& .MuiSvgIcon-root': {
                    color: 'black!important',
                    display: 'inline-block',
                    fontSize: '24px!important',
                    right: '0'
                  },
                  '& .MuiSelect-iconOpen': {
                    fontSize: '24px!important'
                  }
                }}
              >
                {languages_list.map(item => <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>)}
              </Select>
            </FormControl>
          </MDBox>
          <MDBox display="flex" flexDirection="column" mt={2} width="100%">
            <MDBox display="flex" width="70%" flexDirection="column">
              <Typography fontWeight={600}>Template Content</Typography>
              <Typography fontSize={'12px'}>{"Enter the text for the body of the template below."}</Typography>
              <TextField
                variant='standard'
                rows={2}
                multiline
                value={templateVariables.body}
                fullWidth
                label={"Text body"}
                onChange={(e) => setTemplateVariables(prev => {
                  let bodyText = e.target.value;
                  bodyText = bodyText.replace(/\r\n/g, '<br>');
                  return {
                    ...prev,
                    body: bodyText
                  }
                })}
              />
            </MDBox>
            <Typography width={"70%"} sx={{ marginTop: '8px' }} fontSize={"12px"}><span style={{ fontWeight: 600 }}>TIP: </span>{WHATSAPP_INS.TIP}</Typography>
            <Typography fontWeight={"600"} width={"70%"} sx={{ marginTop: '4px' }} fontSize={"12px"}><span>Example:</span> {WHATSAPP_INS.TIP_EXAMPLE}
              <Tooltip title="Learn more">
                <a href='https://developers.facebook.com/docs/whatsapp/api/messages/message-templates' target='_blank'><OpenInNewIcon sx={{ height: '18px', width: '18px' }} /></a>
              </Tooltip>
            </Typography>

          </MDBox>
          <MDBox width="70%" mt={2}>
            <Accordian width="100%" heading={"Optional Components"} headingStyle={{ fontSize: '16px' }}>
              <WOptionalComponent templateVariables={templateVariables} setTemplateVariables={setTemplateVariables} />
            </Accordian>
          </MDBox>
          <MDBox display="flex" justifyContent="start" mt={2}>
            <Button
              onClick={handleTemplateSubmit}
              variant="contained"
              disabled={(templateVariables.template_name?.trim().length === 0 || templateVariables.body?.trim().length === 0)}
              sx={{
                borderRadius: "0px",
                color: "white!important",
                border: "unset",
                marginLeft: "12px",
                height: "32px",
                minHeight: "unset",
              }}
            >
              Submit
            </Button>
          </MDBox>
        </MDBox>
        <div className={style["preview-container"]}>
          <div className={style["mobile-frame"]}>
            <div className={style["mobile-screen"]}>{renderPreview()}</div>
          </div>
        </div>
      </MDBox>
      {showExampleModal &&
        <AppModal
          onModalClose={() => setShowExampleModal(false)}
          customStyle={{ minWidth: '700px' }}
          closeOnClick={false}
          heading={" "}
        >
          <ExampleTemplate waCredentials={waCredentials} company={company} templatePreviewVariables={templateVariables} onTemplateCreated={onTemplateCreated} />
        </AppModal>}
    </>
  );
};

export default TemplatePreview;
