import { Card, InputLabel, MenuItem, Select } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { useState } from "react";

const UserForm = ({onCreated}) => {

    const [user,setUser] = useState({
        name : '',
        email : '',
        contact : '',
        role : ''
    });

    const onFormSubmit = e => {
        e.preventDefault();
    }
    return(
        <Card>
            <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                p={1}
                textAlign="center"
            >
                <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
                New User
                </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3} onSubmit={onFormSubmit}>
                <MDBox component="form" role="form">
                <MDBox mb={2}>
                    <MDInput type="text" label="Name" variant="standard" fullWidth value={user.name} onChange={e => {
                        setUser(prev => {
                            return{
                                ...prev,
                                name : e.target.value
                            }
                        })
                    }} />
                </MDBox>
                            
                <MDBox mb={2}>
                    <MDInput type="email" label="Email" variant="standard" fullWidth value={user.email} onChange={e => {
                        setUser(prev => {
                            return{
                                ...prev,
                                email : e.target.value
                            }
                        })
                    }} />
                </MDBox>
               
                <MDBox mb={2}>
                    <MDInput type="number" label="Contact Number" variant="standard" fullWidth value={user.contact} onChange={e => {
                        setUser(prev => {
                            return{
                                ...prev,
                                contact : e.target.value
                            }
                        })
                    }} />
                </MDBox>                               
                
                <>
                    <InputLabel id="demo-select-small">Role</InputLabel>
                    <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={user.role}
                    sx={{
                        width: "300px", height: "40px",marginTop:'8px',
                        "& .MuiSvgIcon-root": {
                            color: "black!important",
                            display: "inline-block",
                            fontSize: "24px!important",
                            right: "0",
                          },
                    }} 
                    onChange={e => {
                        setUser(prev => {
                            return{
                                ...prev,
                                role : e.target.value
                            }
                        })
                    }}
                    >
                        <MenuItem key={'admin'} value={'ADMIN'}>ADMIN</MenuItem>
                        <MenuItem key={'user'} value={'USER'}>USER</MenuItem>
                    </Select>
                </>

                <MDBox mt={4} mb={1} onClick={() => onCreated(user)}>
                    <MDButton type="submit" variant="gradient" color="info" fullWidth>
                    Create
                    </MDButton>
                </MDBox>
                </MDBox>
            </MDBox>
        </Card>        
    )
}
export default UserForm;