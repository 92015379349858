import { UploadSharp, VolumeOff, VolumeUp } from "@mui/icons-material";
import { Button, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, Switch, Tooltip } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import { Typography } from "@mui/material";
import MessageSettings from "../MessageSettings";
import { useDispatch, useSelector } from "react-redux";
import style from "../Notification.module.css";
import { setPrefereNotificationSound } from "api/notificationSettings";
import { memo, useCallback, useRef, useState } from "react";
import { useEffect } from "react";
import useHttp from "hooks/use-http";
import { toast } from "react-toastify";
import { TELE_NOTIFICATION_CHOICE } from "constants/ChatConstants";
import { userDetailAction } from "store/user-details-slice";
import { UserAPI } from "services/UserAPI";
import Divider from '@mui/material/Divider';
import AppConstants from "constants/AppConstants";
import { UserSettingsAPI } from "services/UserSettingsAPI";

const TelecommunicationSettings = () => {
    const audioRef = useRef()
    const dispatch = useDispatch();
    const [isUpdating, setIsUpdating] = useState(false);
    const user = useSelector(state => state.user);
    const teleSettings = useSelector(state => state.userDetails.details.userSettings?.teleUserSettings);
    const [showApiGeneratedCon, setApiGeneratedCon] = useState(teleSettings?.api_generated_conversation);

    const token = useSelector(state => state.user.token);
    const outBoundNF = useSelector((state) => state.userDetails.details.userSettings.outboundSounds)

    const [currSettings, setCurrSettings] = useState();
    const [outBoundNFSound, setOutBoundNFSound] = useState({ sourceUrl: "https://app--media.s3.amazonaws.com/system_default_sounds/message_sent.mp3" });
    const [show, setShow] = useState(false);

    const { sendRequest: setPrefereNotificationSoundFn, data: preferedSound, status: updateSoundStatus } = useHttp(setPrefereNotificationSound, false);

    const onSoundChange = (index) => {
        const newSounds = currSettings.notification_sounds.map((itm, idx) => {
            if (idx === index) {
                return {
                    ...itm,
                    enabled: true
                }
            }
            else {
                return {
                    ...itm,
                    enabled: false
                }
            }
        })
        setCurrSettings(prev => {
            return {
                ...prev,
                notification_sounds: newSounds
            }
        });
        setIsUpdating(true);
    }

    const handleApiTriggerConversationSetting = (e) => {
        const tmp  = e.target.checked;
        setApiGeneratedCon(tmp);
        const payload = {
            api_generated_conversations: tmp,
            userId: user.id
        }
        UserSettingsAPI.updateApiGeneratedMessageSettings({ payload, token: user.token }).then(res => {
            toast.success(res.data.message);
            if (res.data.settings) {
                dispatch(userDetailAction.updateTeleSettings(res.data.settings));
            }
            else {
                const newSettings = { ...teleSettings, api_generated_conversation: tmp };
                dispatch(userDetailAction.updateTeleSettings(newSettings));
            }
        }).catch(e => toast.error(e.message | e));
    }
    
    const updateSound = useCallback(() => {
        setPrefereNotificationSoundFn({ payload: currSettings, token: user.token, userId: user.id });
    }, [currSettings]);

    const toggleMute = useCallback((value) => {
        //if it is enabling, check if we have browser permission if not, ask user to give
        if (!value) {
            if (Notification.permission !== "granted") {
                Notification.requestPermission().then((permission) => {
                    if (permission === 'granted') {
                        const newSettings = {
                            ...currSettings,
                            notification_enabled: value,
                        }
                        setPrefereNotificationSoundFn({ payload: newSettings, token: user.token, userId: user.id });
                    }
                }).catch(e => toast.error(e))
            }
            else {
                const newSettings = {
                    ...currSettings,
                    notification_enabled: value,
                }
                setPrefereNotificationSoundFn({ payload: newSettings, token: user.token, userId: user.id });
            }
        }
        else {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    const newSettings = {
                        ...currSettings,
                        notification_enabled: value,
                    }
                    setPrefereNotificationSoundFn({ payload: newSettings, token: user.token, userId: user.id });
                }else{
                    toast.error('Please allow browser permissions!')
                }
            })
        }
    }, [currSettings])

    useEffect(() => {
        if (updateSoundStatus === 'completed') {
            if (preferedSound) {
                dispatch(userDetailAction.updateTeleSettings(preferedSound));
                toast.success("Setting saved successfully.");
                // onClose();
            }
            else {
                toast.error("Currently we are not able to save, your settings");
            }
        }
    }, [updateSoundStatus, preferedSound])

    useEffect(() => {
        if (teleSettings) {
            setCurrSettings(teleSettings);
        }
    }, [teleSettings]);

    useEffect(() => {
        if (outBoundNF?.length > 0) {
            setOutBoundNFSound({ sourceUrl: outBoundNF?.[0]?.sourceUrl || "https://app--media.s3.amazonaws.com/system_default_sounds/message_sent.mp3" });
            audioRef?.current?.load()
            setShow(false)
        }
    }, [outBoundNF])

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (event) => {
                const base64String = event.target.result;
                setOutBoundNFSound({
                    sourceUrl: base64String,
                    file: e.target.files[0]
                })
                audioRef.current?.load()
                setShow(true);
            };

            reader.readAsDataURL(file);
        }
    }

    const removeOutMsgSound = (removeIndex) => {
        // outBoundNFSound.filter((item, index) => index !== removeIndex)
        setOutBoundNFSound({ sourceUrl: "https://app--media.s3.amazonaws.com/system_default_sounds/message_sent.mp3" });
    }

    const uploadOutMSgSound = () => {
        const formData = new FormData();
        formData.append('files', outBoundNFSound.file);
        let payload = {
            userId: user.id,
            files: formData,
            token: token
        }

        console.log("check it first  -> ", formData, outBoundNFSound)
        UserAPI.uploadOutbountNotification(payload).then((res) => {
            dispatch(userDetailAction.setOutBoundNFSound(res.data));
        })
        setShow(false);
    }

    const notificationsLayout = (item, index) => {
        return <MDBox width="100%" key={item.id} display="flex" flexDirection="row" className={style['sound_container']} justifyContent="space-between">
            <Switch sx={{ marginLeft: '-10px' }} checked={item.enabled} onChange={onSoundChange.bind(null, index)} />
            <MDTypography variant="subtitle2" sx={{ ml: 1, mr: 1, flex: 1, fontSize: '14px' }}>
                {item.name}
            </MDTypography>
            <audio controls style={{ height: '40px', maxWidth: '312px' }}>
                <source src={item.sourceUrl} type="audio/mpeg" />
            </audio>
        </MDBox>
    }

    return (
        <>
            <MDBox display="flex" flexDirection="column" width="100%">
                <MDBox display="flex" flexDirection="column" justifyContent="center" width="100%">
                    <MDBox display="flex" flexDirection="column" justifyContent="space-between">
                        {currSettings?.notification_enabled && Notification.permission === 'granted' && <>
                            <Tooltip title="Disable it.">
                                <Button onClick={() => toggleMute(false)} sx={{
                                    width: '100%',
                                    height: '22px',
                                    cursor: 'pointer',
                                    backgroundColor: "#1876F2!important",
                                    color: "white!important",
                                    textTransform: 'unset'
                                }} startIcon={
                                    <VolumeUp />
                                }>
                                    Disable
                                </Button>

                            </Tooltip>
                            <MDTypography fontWeight={"medium"} variant="button">{"Telecommunication notifications are enabled."}</MDTypography>
                        </>
                        }
                        {(!currSettings?.notification_enabled || Notification.permission !== 'granted') &&
                            <>
                                <Tooltip title="Enable now.">
                                    <Button onClick={() => toggleMute(true)}
                                        sx={{
                                            width: '100%',
                                            height: '22px',
                                            cursor: 'pointer',
                                            backgroundColor: "#1876F2!important",
                                            color: "white!important",
                                            textTransform: 'unset'
                                        }}
                                        startIcon={
                                            <VolumeOff />
                                        }
                                    >
                                        Enable
                                    </Button>

                                </Tooltip>
                                <MDTypography fontWeight={"small"} variant="button" color={"warning"}>{"Telecommunication notifications are disabled."}</MDTypography>
                            </>
                        }
                    </MDBox>
                    <Divider></Divider>
                    {currSettings && <MDBox mt={1} display="flex" width="100%">
                        <FormGroup>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={(e) => {
                                        const old = { ...currSettings, allowed_choice: e.target.value };
                                        setCurrSettings(prev => {
                                            return {
                                                ...prev,
                                                allowed_choice: e.target.value
                                            }
                                        });
                                        setPrefereNotificationSoundFn({ payload: old, token: user.token, userId: user.id });
                                    }}
                                    value={currSettings ? currSettings.allowed_choice : TELE_NOTIFICATION_CHOICE.all}
                                >
                                    <FormControlLabel
                                        key={"all"}
                                        value={TELE_NOTIFICATION_CHOICE.all}
                                        control={<Radio key={"radio_all"} />}
                                        label="Get notifications for all telecommunication messages."
                                        sx={{ display: "flex" }}
                                    />
                                    <FormControlLabel
                                        key={"assigned"}
                                        value={TELE_NOTIFICATION_CHOICE.assigned}
                                        control={<Radio key={"radio_assigned"} />}
                                        label="Get notifications only for conversations which are assigned to me."
                                        sx={{ display: "flex" }}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </FormGroup>
                    </MDBox>}
                    <Divider></Divider>
                    {currSettings?.notification_sounds &&
                        <MDBox mt={1}>
                            <Typography fontWeight={'medium'} fontSize={14}>
                                What would you like to hear when you get a new message?
                            </Typography>
                        </MDBox>
                    }
                    {currSettings?.notification_sounds?.map((item, index) => notificationsLayout(item, index))}
                    <Divider></Divider>
                    <MDBox mt={1}>
                        <Typography fontWeight={'medium'} fontSize={14}>What would you like to hear when you send a new message?</Typography>
                        <MDBox sx={{ display: "flex", justifyContent: "space-between", height: "40px", marginTop: '20px', marginBottom: '20px' }}>
                            <Button
                                variant="contained"
                                component="label"
                                sx={{ color: "#fff" }}>
                                <UploadSharp /> Upload
                                <input
                                    type="file"
                                    accept="audio/*"
                                    hidden
                                    onChange={(event) => {
                                        handleFileChange(event)
                                    }}
                                />
                            </Button>
                            <MDBox sx={{
                                display: "flex",
                                alignItems: 'center',
                                justifyContent: "center",
                                gap: "8px",
                                padding: "15px 0"
                            }}>
                                <audio ref={audioRef} id={"new_" + outBoundNFSound.sourceUrl} controls style={{ height: '40px', maxWidth: '312px' }}>
                                    <source src={outBoundNFSound.sourceUrl} type="audio/mpeg" />
                                </audio>
                                {/* {show && <DeleteForeverIcon onClick={() => removeOutMsgSound()} style={{ color: '#a89c9c', cursor: 'pointer' }} />} */}
                            </MDBox>

                        </MDBox>
                        {show &&
                            <MDBox width="100%" display="flex" justifyContent="center">
                                <Tooltip title="Press This Button to set new out bound message sound" arrow>
                                    <Button
                                        sx={{
                                            borderRadius: "0px",
                                            color: "white!important", border: "unset", borderRadius: "5px"
                                        }}
                                        variant="contained"
                                        onClick={uploadOutMSgSound}
                                    >Update</Button>
                                </Tooltip>
                            </MDBox>
                        }
                    </MDBox>

                </MDBox>
                {isUpdating &&
                    <MDBox width="100%" display="flex" justifyContent="flex-end">
                        <Button onClick={updateSound} sx={{ borderRadius: "0px", color: "white!important", border: "unset", }} variant="contained">update</Button>
                    </MDBox>
                }
                <Divider></Divider>
            </MDBox>
            <MessageSettings />
            <Divider></Divider>
            <MDBox display="flex" mt={1} width="100%" flexDirection="row" alignItems="center">
                <Switch onChange={handleApiTriggerConversationSetting} checked={Boolean(showApiGeneratedCon)}/>
                <Typography fontWeight={'medium'} fontSize={14}>{AppConstants.API_SETTING_TEXT}</Typography>
            </MDBox>
        </>
    )
}
export default memo(TelecommunicationSettings);