import { Checkbox, Typography } from "@mui/material";
import { MessageText, MessageLinkText,UrlLinK } from "pages/Chat/ChatStyles";
import { Message } from "pages/Chat/ChatStyles";
import { RightMessageBubble } from "pages/Chat/ChatStyles";
import { DateTimeInfo } from "pages/Chat/ChatStyles";
import { FlexContainer } from "pages/Chat/ChatStyles";
import SentBy from "./SentBy";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchTextFormated ,searchTextByTagFormated, searchTextByUserFormated} from "util/UtilityFunctions";
import parse from 'html-react-parser'
import Tags from "components/Company/Tags";
import ShowTag from "./ShowTag";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MessageMenu from "components/Message/MessageMenu";
import AppModal from "ui/AppModal";
import MessageDeliveryPending from "components/GroupChat/MessageDeliveryPending";
import MessageActions from "./MessageActions";
import GMessageActions from "./GMessageActions";
import { getBlobFromUrl } from "util/MediaUtil";
import { APP_COLOR } from "constants/Color";
import TeleMessageStatus from "./Util/TeleMessageStatus";
import { messageAction } from "store/message-slice";
import { ConversationAPI } from "services/ConversationAPI";
import { groupAction } from "store/group-slice";
import MediaMessage from "components/CommonUI/MediaMessage";
import { useLongPress } from 'use-long-press';
import { TELE_MESSAGE_STATUS } from "constants/ChatConstants";
import useOuterClick from "hooks/use-outer-click";
import useWAT from "hooks/use-wat";
import AppConstants from "constants/AppConstants";
import MessageUrlLink from './Util/MessageUrlLink';
import MDBox from "lib/components/MDBox";

const SingleRightMessage = ({
    message,
    checkboxSelected,
    user,
    check,
    searchActive,
    setMessageState,
    onEventPerform
  }) => {
    
    const rightNavChecked = useSelector(state => state.chatVariables.rightNavChecked);

    const [checkForWAT] = useWAT()
    const dispatch = useDispatch();
    const [showTag, setShowTag] = useState(false);

    const currRef = useRef('mesaage'+message.id)
    const bubbleRef = useOuterClick('mesaage_bubble'+message.id,showTag,() => {setShowTag(false)});
    
    const [openMessageMenu, setOpenMessageMenu] = useState(null);
    const [parentMessage,setParentMessage] = useState(undefined);
    const [isHovered, setIsHovered] = useState(false);
    const [isPressMoving, setIsPressMoving] = useState(false);
    const [pendingRecipientModal,setPendingRecipientModal] = useState(false);

    const token = useSelector(state => state.user.token);

    const [currMessage, setCurrMessage] = useState();

    const [checked,setChecked] = useState(false);

    const callback = useCallback(event => {
      if(!openMessageMenu && !isPressMoving){
        if(event.target.className.includes('MuiBackdrop-root')){
          setOpenMessageMenu(null)
        }else{
          setOpenMessageMenu(event.target)
        }
      }
      if(isPressMoving){
        setOpenMessageMenu(null)
      }
    }, []);
    const OnLongPress = useLongPress(callback, {
      onStart: event => {setIsPressMoving(false)},
      onMove: event =>{setIsPressMoving(true)},
      onFinish: event => {setIsPressMoving(false)},
      filterEvents: event => true, // All events can potentially trigger long press (same as 'undefined')
      threshold: 500, // In milliseconds
      captureEvent: true, // Event won't get cleared after React finish processing it
      cancelOnMovement: 25, // Square side size (in pixels) inside which movement won't cancel long press
      cancelOutsideElement: true, // Cancel long press when moved mouse / pointer outside element while pressing
      detect: 'pointer', // Default option
    });

    const renderMessageAction = (msg) => {
      if(msg?.status === TELE_MESSAGE_STATUS.prepare && !msg?.scheduledAt){
        if(msg?.groupId && msg?.groupId !== 0){
          return <GMessageActions setMessageState={setMessageState} key={msg.tempId} message={msg}/>
        }
        else return <MessageActions setMessageState={setMessageState} key={msg?.tempId} message={msg}/>
      }
      else{
        <></>
      }
    }

    const onEventPerformInMessageMenu = (action,data) => {
      
      if(action === 'SCHEDULE_EDIT') {
        // Remove the message and and unschedule it now:::
        ConversationAPI.unscheduleMessage({messageId:data.id,token}).then(res => {
          // remove from store
          if(res.groupId != 0){
            dispatch(groupAction.deleteMessage({msgId : message?.id, groupId : message.groupId}));
          }
          else{
            console.log("Else part of")
            dispatch(messageAction.deleteMessage({msgId : res.id,recipientId : res?.sendTo.id}));            
          }
        }).catch(e => console.log(e));
        // dispatch action to remove message::
        if(data.messageAttchement) {
          getBlobFromUrl(data.messageAttchement).then(
            res => {
              setMessageState({
                message : data.message,
                attachement : res,
              }) 
            }
          )
        }
        else{
          setMessageState({
            message : data.message,
            attachement : undefined 
          }) 
        }
      }

      if(action === 'OPEN_TAG'){
        setShowTag(true);
        setOpenMessageMenu(null);
      }
      if(action === 'POPOVER_CLOSED'){
        setOpenMessageMenu(null);
      }
      if(action === 'SHARE_MESSAGE_LINK'){
        setOpenMessageMenu(null);
      }
    }
    const onCheckHandler = (event) => {
      setChecked(event.target.checked);
      const checkedData = {checked : event.target.checked,id: message.id};
      onEventPerform("CHECKBOX",checkedData);
    }
    const onTagChange = (data) => {
      setShowTag(false);
    }

    useEffect(() => {
      if(searchActive.search){
        let formated;
        if(searchActive.criterion === 'NORMAL_SEARCH')
          formated = searchTextFormated(message.message,searchActive.pattern);
        else if(searchActive.criterion === 'SEARCH_BY_TAG'){
          formated = searchTextByTagFormated(message,searchActive.pattern);
        }
        else if(searchActive.criterion === 'SEARCH_BY_USER'){
          formated = searchTextByUserFormated(message,searchActive.pattern);
        }

        if(formated === ''){
          setCurrMessage(undefined);
        }
        else{
            setCurrMessage(prev => {
            const val = {
              ...prev, message:formated
            }
            return val;
          });
          }
      }
      else
        setCurrMessage(message);
    },[message,searchActive])

    useEffect(() => {
      if(message && message.parentWamid){
        ConversationAPI.getWaParentMessage({token,wamid : message.parentWamid}).then(res => {
          setParentMessage(res.data);
        }).catch(e => {console.log(e)})
      }
    },[message])

    /// select check box
    useEffect(()=> {
      setChecked(check || rightNavChecked);
    },[checkboxSelected,check,rightNavChecked])
      
  useEffect(() => {
    if(!openMessageMenu){
      setIsHovered(false);
    }
  },[openMessageMenu])

  let _longPress = {}  
  
  if(window.innerWidth < 700){
    _longPress = OnLongPress()
  }
    return (
      <>
        {currMessage ? (
          <FlexContainer
            key={currMessage?.id}
            alignItems="center"
            justifyContent="space-between"
            height="auto"
            spacing="10px"
            id={"message_" + currMessage?.id}
            ref={currRef}
            data-message-id={currMessage?.id}
            marginBottom="10px"
            {..._longPress}
          >
            <div key={"check_div" + currMessage?.id} className="custom-checkbox">
              {(checkboxSelected || rightNavChecked) && (
                <Checkbox
                  key={"check" + currMessage?.id}
                  checked={checked}
                  onChange={onCheckHandler}
                />
              )}
            </div>
            <div key={"msg_div" + currMessage?.id} style={{ marginTop: "8px" }}>
              <Message key={"msg" + currMessage?.id} className="right-msg">
                <TeleMessageStatus currMessage={currMessage} setCurrMessage={setCurrMessage} setPendingRecipientModal={setPendingRecipientModal}/>
                <RightMessageBubble
                  ref={bubbleRef}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => {
                    if(!openMessageMenu){
                      setIsHovered(false)
                    }
                  }}
                  key={"msg_img" + currMessage?.id}
                  chatImage={currMessage?.messageAttchement ? true : false}
                >
                  {
                    (currMessage?.messageAttchement || (currMessage?.media && currMessage.media.length > 0)) &&
                    <MediaMessage message={currMessage}/>
                  }
                  {isHovered && (
                    <>
                      <MoreVertIcon
                        key={currMessage?.id}
                        sx={{
                          position: "absolute",
                          right: 0,
                          top: 0,
                          cursor: "pointer",
                          color: "#fff",
                          marginTop: "3px",
                          height: "18px",
                          width: "18px",
                          background:'rgb(24, 118, 242,0.5)',
                          borderRadius:'50%',
                          paddingRigth:'2px',
                          boxShadow: '0px 1px 2px 2px rgb(24, 118, 242,0.5)'
                        }}
                        onClick={(e) => setOpenMessageMenu(e.currentTarget)}
                      />
                    </>
                  )}
                  {parentMessage && <MDBox sx={{padding:'4px',borderRadius:'5%',background:'white',marginBottom:'2px'}}>
                    <Typography fontSize={"12px"} color={"#1A73E8!important"}>{parentMessage?.message}</Typography>
                  </MDBox>}
                  <MessageText attachmentWithText={(currMessage?.messageAttchement && currMessage?.message)}>
                    <MessageLinkText inbound={currMessage?.inbound}><UrlLinK component={MessageUrlLink} regex={AppConstants.URL_REGEX}>{currMessage?.message && parse(checkForWAT(message))}</UrlLinK></MessageLinkText>
                  </MessageText>
                  {currMessage.importId && currMessage.importId !== 0 && <span style={{fontSize:'12px',color:currMessage.messageAttchement ? APP_COLOR.RIGHT_IMPORT_MSG_ATTACHMENT_COLOR :  APP_COLOR.RIGHT_IMPORT_MSG_COLOR,fontStyle:'italic'}}>{"Imported message"}</span>}         
                  <Tags
                    onTagChange={onTagChange}
                    message={currMessage}
                    onClose={() => setShowTag(false)}
                    showTagInput={showTag}
                  />
                  {!showTag && <ShowTag tags={currMessage?.tags} />}
                  {openMessageMenu && (
                    <MessageMenu
                      messageId={currMessage?.id}
                      message={message}
                      onEventPerform={onEventPerformInMessageMenu}
                      targetEle={openMessageMenu}
                    />
                  )}
                </RightMessageBubble>
              </Message>
              {renderMessageAction(currMessage)}
              <DateTimeInfo className="right-date-info">
                <SentBy
                  key={currMessage?.createdAt}
                  image={user?.profilePic}
                  name={user?.fullName}
                  date={currMessage?.createdAt}
                />
              </DateTimeInfo>
            </div>
          </FlexContainer>
        ) : (
          <></>
        )}
        {pendingRecipientModal && <AppModal
          rootStyle={{
            "&.MuiModal-root": {
              width: "30%",
              left: "70%",
            },
          }}
          customStyle={{
            width: "100%",
            height: "100%",
            overflowY: "auto",
            border: "unset",
          }}
          onModalClose={() => setPendingRecipientModal(false)}
        >
          <MessageDeliveryPending key={currMessage?.id} messageId={currMessage?.id} />
        </AppModal>}
      </>
    );
}
export default React.memo(SingleRightMessage);