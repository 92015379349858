import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";

const IconButton = ({onClick,customStyle,children}) => {

    return (
        <MDBox
          pt={2}
          px={2}
          sx={customStyle}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <MDButton onClick={onClick} variant="gradient" color="dark">
            {children}
          </MDButton>
        </MDBox>
      );

}
export default IconButton;