import axios from "../core/axios";
import { API_UPLOAD_ASSET } from "constants/Endpoints";

export const uploadAsset = async ({file,token,domain}) => {
    const CHUNK_SIZE = 5242880; // less than < 1MB
    const RETRY_THRESHOLD = 3;
    const TOTAL_CHUNKS = Math.ceil(file.size / CHUNK_SIZE);
    //formdata : file,domain,total_chunks, chunk_number,filename
    /**
     * {
    chunk_number : ,
    total_chunks : ,
    status : 'success' or 'failed',
    domain : '',
    asset : {
        name : '',
        uri : '',
        type : '',
        size : '',
        uid : ''
    }    
}
     */
    let response = {};
    // for (let i = 0; i < TOTAL_CHUNKS; i++) {
        // const start = i * CHUNK_SIZE;
        // const end = Math.min(start + CHUNK_SIZE, file.size);
        const formData = new FormData();
        // formData.append("chunk_number",i + 1);
        formData.append("chunk_number",1);        
        formData.append("total_chunks",TOTAL_CHUNKS);
        // formData.append("file", file.slice(start, end));
        formData.append("file", file);
        formData.append("domain",domain);
        formData.append("filename", file.name);
        const { data } = await axios.post(API_UPLOAD_ASSET, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization" : "Bearer " + token
            }
        });
        response = data;
    // }
    return response;
};
// internal chat => 50 MB
// WA  => 25MB
// Telecom => 6MB