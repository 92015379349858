import { CALENDAR_STRING } from "constants/ChatConstants";
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import { memo } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";

const SearchItem = ({searchData}) => {

    const timezone = useSelector(state => state.userDetails?.details?.userSettings?.timezone)
    return(
        <>
            <MDBox key={searchData.id} component="li" display="flex" alignItems="center" py={1} mb={1} 
                sx={{
                    padding:'14px',
                    cursor:'pointer',
                    width : '100%',
                    margin:'8px',
                    "&:hover" : {
                        backgroundColor: "#d6d3d35e"
                    }
                }}>
                <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
                    {searchData?.sendTo?.displayName && 
                        <MDTypography variant="button" fontWeight="medium">
                            {searchData?.sendTo?.displayName}
                        </MDTypography>
                    }                    
                    <MDTypography variant="button" fontWeight={searchData?.sendTo?.displayName ? 'light' : 'medium'}>
                        {searchData?.sendTo?.phone}
                    </MDTypography>
                    <MDTypography 
                        sx={{
                            maxHeight:'120px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }} 
                        variant="caption" 
                        color="text"
                    >
                        {searchData?.message}
                    </MDTypography>
                </MDBox>
                <MDBox ml="auto">
                    <MDTypography fontSize={"12px"}>
                        <Moment tz={timezone} calendar={CALENDAR_STRING}>
                            {searchData?.createdAt}
                        </Moment>
                    </MDTypography>
                </MDBox>
            </MDBox>
        </>
    )
}
export default memo(SearchItem);