import { phoneColumns } from "constants/Columns";
import Table from "ui/Table";
import AssignedDetails from "./AssignedDetails";
import PhoneActions from "components/Phone/PhoneActions";
import { useEffect, useState } from "react";
import PhoneCompany from "./PhoneCompany";

const PhoneCredentialsList = ({data,onPhoneAssigned,onPhoneEdited,onCellConfigUpdated,onUnassignment}) => {
    
    const [rows,setRows] = useState([]);

    useEffect(() => {
        if(data && data?.length > 0) {
            const newRow = data?.map(phone => {
                return {
                    phone: phone?.phone,
                    apiuser : phone?.apiUsername,
                    company : <PhoneCompany phoneId={phone.phoneId} company={phone?.company} onUnassignment={onUnassignment}/>,
                    type : phone?.whatsappCred?.accessToken ? (phone.whatsappOnly ? 'WHATSAPP': 'WHATSAPP | SMS') : 'SMS', 
                    apikey : <div style={{
                        maxWidth:'200px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}>{phone?.apiKey ? phone.apiKey : 'XXXX-xxxx-XXX.'}</div>,
                    auth: <div style={{
                        maxWidth:'200px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}>{phone?.basicAuthHeader}</div>,
                    assigned : <AssignedDetails phoneId={phone?.phoneId}/>,
                    action : <PhoneActions  onCellConfigUpdated={onCellConfigUpdated} key={phone?.phoneId} onPhoneAssigned={(res) => {onPhoneAssigned(res)}} phone={phone} onPhoneEdited={(res) => onPhoneEdited(res)}/>
                }
            });
            setRows(newRow);
        }
    },[data,onCellConfigUpdated,onPhoneAssigned,onPhoneEdited])
    return(
        <Table canSearch={true} heading={"Numbers"} columns={phoneColumns?phoneColumns : []} rows={rows?rows : []} />
    )
}
export default PhoneCredentialsList;