import VisibilityIcon from "@mui/icons-material/Visibility";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import React, { useState } from "react";
import UserPhoneAssignedList from "../User/UserPhoneAssgnedList";
import { styled } from '@mui/material/styles';


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip placement="left" {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 600,
    marginRight: '40px',
    boxShadow: theme.shadows[1],
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const AssignedDetails = ({phoneId}) => {
   
    return (
      <>
        {/* <Tooltip title="View assigned users."> */}
        <HtmlTooltip
        title={
          <React.Fragment>
             <UserPhoneAssignedList phoneId={phoneId} />
          </React.Fragment>
        }
      >          
        <VisibilityIcon
          style={{ cursor: "pointer", width:'1.5rem', height:'1.5rem' }}
        />
      </HtmlTooltip>
        {/* </Tooltip> */}
        {/* {modalOpen && (
          <AppModal
            onModalClose={() => {
              setModalOpen(false);
            }}
          >
           
          </AppModal>
        )} */}
      </>
    );
};
export default AssignedDetails;
