import { Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { useState } from "react";

const PROMPT_OPTIONS = [
    {text : 'Send opt-in prompt now',value : 'right_away'},
    {text : 'Send just before recipient gets the first marketing text', value : 'before_text'}
]
const ConsentOptions = ({OnConsentOption}) => {

  const [consentOption,setConsentOption] = useState(PROMPT_OPTIONS[0]);

  return (
    <MDBox display="flex" flexDirection="column">
      <MDBox display="flex">
        <Typography sx={{ color: "#f5a20a", fontSize: "20px!important" }}>
          {"When should we send the opt-in text?"}
        </Typography>
      </MDBox>
      <MDBox
        display="flex"
        flexDirection="column"
        sx={{
          marginTop: "16px",
        }}
        width="100%"
        alignItem="center"
        justifyContent="center"
      >
        <FormControl fullWidth sx={{ marginRight: "8px" }}>
          <InputLabel id="send-type">Choose option</InputLabel>
          <Select
            labelId="send-type"
            id="send-type-select"
            value={consentOption}
            sx={{
              height: "40px",
              width: "100%",
              marginRight: "8px",
              "& .MuiSvgIcon-root": {
                color: "black!important",
                display: "inline-block",
                fontSize: "24px!important",
                right: "0",
              },            
            }}
            label="Choose option"
            onChange={(e) => {
              setConsentOption(e.target.value);
            }}
          >
            {PROMPT_OPTIONS.map((item, i) => (
              <MenuItem key={i} value={item}>
                {item.text}
              </MenuItem>
            ))}

          </Select>
        </FormControl>
        <Button
          onClick={() => OnConsentOption(consentOption)}
          sx={{ marginTop: "12px",color:"white!important" }}
          variant="contained"
          size={"medium"}
        >
          {"Save"}
        </Button>
      </MDBox>
    </MDBox>
  );
};
export default ConsentOptions;
