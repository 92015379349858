import MDBox from "lib/components/MDBox";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { useCallback } from "react";

//    {phoneId : 2, reply : ''},
const PhoneSpecificAutoReply = ({autoReply = [],setAutoReply,selectedPhone}) => {

    const getInitialValue = useCallback(() => {
        const findPhone = autoReply?.phone?.find(itm => itm?.phoneId === selectedPhone?.phoneId);
        return findPhone?.reply || '';
    },[autoReply]);

    const onAutoReplyHandler = useCallback((e) => {
        const newPhoneReply = autoReply.phone?.map(item => {
            if(item.phoneId === selectedPhone.phoneId){
                return {
                    ...item,
                    reply : e.target.value
                }
            }
            else{
                return item;
            }
        });
        setAutoReply(prev => {
            return{
                ...prev,
                phone : newPhoneReply
            }
        });
    },[selectedPhone,autoReply.phone]);
    
    return(
        <>
            <MDBox display="flex" flexDirection="row">
                <MDInput
                    style={{ width: "60%", margin: "8px" }}
                    type="text"
                    label="Enter auto reply"
                    multiline
                    rows={5}
                    value={getInitialValue()}
                    onChange={onAutoReplyHandler}
                />
            </MDBox>          
        </>
    )
}
export default PhoneSpecificAutoReply;