import { createSlice } from "@reduxjs/toolkit";
import { TELE_MESSAGE_STATUS } from "constants/ChatConstants";

const initialMessageStates = {
    conversation : {},
}

const messageSlice = createSlice({
    name : 'message',
    initialState: initialMessageStates,
    reducers : {
      updateMessageAsRead : (state,action) => {
        const {ids,recipientId,readByUser,seenAt} = action.payload;
        state.conversation[recipientId]['messages'].forEach(message => {
            ids?.forEach(m => {
                if(message.id === m){
                  message.messageRead = true;
                  message.readByUser = readByUser;
                  message.seenAt = seenAt
                }
                else{
                  message.messageRead = true;
                }
            })
        });
      },        
      markLastMessageReadByNull : (state,action) => {
        const recipientId = action.payload;
        const last = state.conversation[recipientId]?.['messages'];
        if(last && last.length > 0){
          let indexToUpdate = last.length - 1;
          const messages = state.conversation[recipientId]['messages'];
          while(indexToUpdate >= 0 && messages[indexToUpdate].messageEvent){
            indexToUpdate--;
          }
          if(indexToUpdate >= 0){
            state.conversation[recipientId]['messages'][indexToUpdate].readByUser = null;
            // state.conversation[recipientId]['messages'][indexToUpdate].messageRead = false;
          }
        }
      },        
      updateConversationCurrentPage : (state,action) => {
        const {recipientId} = action.payload;
        if(state.conversation[recipientId]) {
          const oldPage = state.conversation[recipientId].currentPage;
          state.conversation[recipientId].currentPage = oldPage + 1;
        }
      }, 
      updateConversation : (state,action) => {
        const {recipientId,message} = action.payload;
        if(state.conversation[recipientId]) {
          const findIdx = state.conversation[recipientId]['messages'].findIndex(itm => itm.tempId === message.tempId);
          if(message.status === TELE_MESSAGE_STATUS.failed){
            state.conversation[recipientId]['messages'][findIdx].status = TELE_MESSAGE_STATUS.failed;
          }
          else{
            state.conversation[recipientId]['messages'][findIdx] = message;
          }
        }
      },
      msgCreatedByOther : (state,action) => {
        const message = action.payload;
        const recipientId = message.sendTo.id;
        console.log("Message created by other user...")
        if(state.conversation[recipientId]) {
          console.log("recipient exists...");
          if(state.conversation[recipientId].fetched){
            console.log("updating messages...");
            const oldMessages = state.conversation[recipientId]['messages'];
            console.log({oldMessages});
            state.conversation[recipientId]['messages'] = [message,...oldMessages];
          }
        }      
      },
      removeConversation : (state,action) => {
        const {recipientId,message} = action.payload;
        if(state.conversation[recipientId]['messages']) {
          const findIdx = state.conversation[recipientId]['messages'].findIndex(itm => itm.tempId === message.tempId);
          state.conversation[recipientId]['messages'].splice(findIdx,1);
        }
      },
      setConversation: (state, action) => {
        const {recipientId,totalPages,messages = []} = action.payload;
        if (!state.conversation[recipientId]) {
          state.conversation[recipientId] = {
            messages : [],
            fetched : true,
            currentPage : 0,
            totalPages : totalPages,
          };
        }
        state.conversation[recipientId]['messages'] = [...messages];
      },        
      setConversationFetchedStatus : (state,action) => {
        const {recipientId,status} = action.payload;
        if(!state.conversation[recipientId]){
          state.conversation[recipientId] = {
            messages : [],
            fetched : status,
            currentPage : 0
          }; 
        }
        else{
          state.conversation[recipientId].fetched = status;
        }
      }, 
      addMessage: (state, action) => {
        const { recipientId, message } = action.payload;
        if (!state.conversation[recipientId]) {
          state.conversation[recipientId] = {
            messages : [],
            fetched : true
          };
        }
        // If message and tempId matches...
        const findMessage = state.conversation[recipientId]['messages'].findIndex(itm => itm.tempId === message.tempId);
        if(findMessage !== -1) {
          state.conversation[recipientId]['messages'][findMessage] = message;
        }
        else{
          state.conversation[recipientId]['messages'].push(message);
        }
      },
      removeMessage : (state,action) => {
        const {messageId} = action.payload;
        const recipientId = state.current_active_recipient?.id;
        if(recipientId){
          const newMessages = state.conversation[recipientId]['messages'].filter(itm => itm.id !== messageId);
          state.conversation[recipientId]['messages'] = [...newMessages];
        }
      },   
      addConversation: (state, action) => {
        const {recipientId,message} = action.payload;
        if (!state.conversation[recipientId]) {
          state.conversation[recipientId] = {
            messages : [],
            fetched : false
          };
        }     
         
        // state.conversation[recipientId]['messages'] = [message,...state.conversation[recipientId]['messages']];
        // Create a new copy of messages array with the new message added
        // const updatedMessages = [message, ...state.conversation[recipientId].messages];
        // state.conversation[recipientId].messages = updatedMessages;
        state.conversation[recipientId].messages.unshift(message);
      }, 
      deleteRecipientConversation : (state,action) => {
        const {ids} = action.payload;
        for(const id of ids) {
          delete state.conversation[id];
        }            
      },

      deleteMessage : (state,action) => {
        const {msgId,recipientId} = action.payload;
        const newMessages = state.conversation[recipientId]['messages'].filter(itm => itm.id !== msgId);
        state.conversation[recipientId]['messages'] = newMessages;
      },   
      scheduledMsgReceived : (state,action) => {
        const message = action.payload;
        const recipientId = message.sendTo.id;
        if(state.conversation[recipientId]){
          // if messages are fetched..
          const findMsgIdx = state.conversation[recipientId]['messages'].findIndex(itm => itm.id === message.id);
          if(findMsgIdx !== -1){
            state.conversation[recipientId]['messages'][findMsgIdx]['status'] = message.status;
          }
        }
      },      
      handleInbound : (state,action) => {
        const message = action.payload;
        const recipientId = message.sendTo.id;
        // if (!state.conversation[recipientId]) {
        //     state.conversation[recipientId] = {
        //         messages : [],
        //         fetched : false,
        //     };
        // }      
        if(state.conversation[recipientId] && state.conversation[recipientId]?.fetched){
          state.conversation[recipientId]['messages'] = [message,...state.conversation[recipientId]['messages']];
        }
      },     
      newTag : (state,action) => {
        const tag = action.payload;
        //tag has messageId,recipientId,phoneId
        const idx = state.conversation[tag.recipientId]['messages'].findIndex(itm => itm.id === tag.messageId);
        if(idx !== -1){
          const tags = state.conversation[tag.recipientId]['messages'][idx].tags;
          tags.push(tag);
          state.conversation[tag.recipientId]['messages'][idx].tags = tags;
        }
      },
      removeTag : (state,action) => {
        const tag = action.payload;
        //tag has messageId,recipientId,phoneId
        const idx = state.conversation[tag.recipientId]['messages'].findIndex(itm => itm.id === tag.messageId);
        if(idx !== -1){
          const tags = state.conversation[tag.recipientId]['messages'][idx]['tags'];
          const newTags = tags?.filter(itm => itm.id !== tag.id);
          console.log({tags,newTags});
          state.conversation[tag.recipientId]['messages'][idx].tags = newTags;
        }
      },
      updateMessageStatusToSent : (state,action) => {
        // Find by MessageId
        try{
          const message = action.payload;
          const recipientId =  message.sendTo.id;
          const findMsgIdx = state.conversation[recipientId]['messages'].findIndex(itm => itm.tempId === message.tempId);
          if(findMsgIdx !== -1){
            state.conversation[recipientId]['messages'][findMsgIdx]['status'] = message.status;
          }
        }
        catch(e){}
      },     
      updateMessageStatus : (state,action) => {
        // Find by MessageId
        try{
          const message = action.payload;
          const recipientId =  message.sendTo.id;
          const findMsgIdx = state.conversation[recipientId]['messages'].findIndex(itm => itm.id === message.id);
          if(findMsgIdx !== -1){
            state.conversation[recipientId]['messages'][findMsgIdx]['status'] = message.status;
          }
        }
        catch(e){}
      },                     
      resetChats : (state) => {
        state.conversation = {};
      }
    }
})
export const messageAction = messageSlice.actions;
export default messageSlice;