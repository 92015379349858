import { Add, Info, Settings, Sync } from "@mui/icons-material";
import { Button, Divider, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import WTemplates from "./WTemplates";
import CreateTemplate from "./CreateTemplate";
import { useCallback, useEffect, useState } from "react";
import AppModal from "ui/AppModal";
import WASettings from "./WASettings";
import useHttp from "hooks/use-http";
import { useSelector } from "react-redux";
import { syncWATemplates } from "api/whatsapp";
import Moment from "react-moment";
import { getWACredentials } from "api/whatsapp";
import { FormatPhoneNumber } from "util/UtilityFunctions";
import { WhatsappAPI } from "services/WhatsappAPI";
import { toast } from "react-toastify";
import AppConstants from "constants/AppConstants";

const Whatsapp = ({company}) => {

    const timezone = useSelector(state => state.userDetails?.details?.userSettings?.timezone)
    const [createTemplate, setCreateTemplate] = useState(false);
    const [credentials,setCredentials] = useState([]);
    const [currCred,setCurrCred] = useState();
    const token = useSelector(state => state.user.token);
    const [openWASettings, setWASettings] = useState(false);


    const [templates, setTemplates] = useState([]);
    
    const {sendRequest : getWACredentialsFn, status : waCredStatus, data : waCredData, error : waCredErr} = useHttp(getWACredentials,false);

    const {sendRequest : syncTemplateFn, status:syncStatus,data : syncData, error: syncError} = useHttp(syncWATemplates,false);
    
    const onTemplateCreated = (tData) => {
        setCreateTemplate(false);
        setTemplates(prev => {
            return{
                tData,
                ...prev
            }
        })
    }
   
    const onWACredChange = (e) => {

    }

    const formatPhone = (phones) => {
        let str = '';
        for(let phone of phones){
            str += FormatPhoneNumber(phone);
        }
        return str;
    }

    const syncTemplates = useCallback(() => {
        if(company) {
            const payload = {
                waBusinessId : currCred.waBusinessId,
                token : currCred.token,
                companyId : company.company_id
            }
            syncTemplateFn({token,companyId : company.company_id,payload})
        }
    },[currCred,company,syncTemplateFn,token])

    useEffect(() => {
        if(currCred && company){
            const payload = {
                waBusinessId : currCred.waBusinessId,
                token : currCred.token,
                companyId : company.company_id
            }
            WhatsappAPI.getWATemplates({token,payload}).then(res => {
                setTemplates(res.data);
            })
        }
    },[currCred,company])

    useEffect(() => {
        if(company){
            getWACredentialsFn({token,companyId : company.company_id})
        }
    },[company])

    useEffect(() => {
        if(waCredStatus === 'completed'){
            if(waCredData){
                setCredentials(waCredData);
                if(waCredData.length > 0){
                    setCurrCred(waCredData[0]);
                }
            }
        }
    },[waCredData,waCredStatus,waCredErr]);

    useEffect(() => {
        if(syncStatus === 'completed'){
            if(syncData){
                toast.success(AppConstants.WA_TEMPLATE_SYNCED);
                setTemplates(syncData);
            }
            else if(syncError){
                toast.error(syncError);
            }
        }
    },[syncStatus,syncData,syncError])
    
    return (
        <>
            <MDBox display="flex" flexDirection="column">
                <MDBox display="flex" justifyContent="start" flexDirection="column" padding="8px">
                    <Typography>WhatsApp Message Templates (WhatsApp Business)</Typography>
                    <Typography sx={{fontSize:'14px'}}>To start conversations with WhatsApp users, you must use an approved sender and the outbound message must  follow a message template.</Typography>
                </MDBox>
                <MDBox display="flex" justifyContent="flex-end">
                    <Button
                        onClick={() => setWASettings(true)}
                        variant="contained"
                        sx={{
                            borderRadius: "0px",
                            color: "white!important",
                            border: "unset",
                            marginLeft: "12px",
                            height: "32px",
                            minHeight: "unset",
                            marginBottom:'12px'
                        }}
                        startIcon={<Settings/>}
                        >
                        WA Settings
                    </Button>                
                </MDBox>
                {currCred && <MDBox display="flex" justifyContent="flex-end">
                    <FormControl fullWidth sx={{marginRight:'8px',width:'350px'}}>
                        <InputLabel id="wa-cred">WA Business Credentials</InputLabel>
                         <Select
                            labelId="wa-cred"
                            id="http-method-select"
                            value={currCred}
                            sx={{
                                height:'40px',
                                marginRight:'8px'
                            }}
                            label="WA Business Credentials"
                            onChange={onWACredChange}
                        >
                            {credentials.map(item => <MenuItem key={item.waBusinessId} value={item}>{formatPhone(item.phones)}</MenuItem>)}
                        </Select>
                    </FormControl>                    
                </MDBox>}
                <Divider
                    variant="horizontal"
                    component="div"
                    sx={{
                    "&.MuiDivider-root": {
                        backgroundColor: "#e9d5e9",
                    },
                    }}
                />
                <MDBox 
                    sx={{
                        backgroundColor: '#E2F2FD',
                        padding:'12px',
                        marginTop : '8px',
                        border: 'solid 1px #fbfbfb',
                        borderRadius:'22px',
                        width:'100%'
                    }}
                >
                    <MDBox display="flex">
                        <Info sx={{marginRight:'8px'}}/>
                        <Typography fontSize={'14px'}>{"WhatsApp Message Templates must be submitted and approved before they can be sent to your contacts. Templates may take around 24 hours to be approved by WhatsApp. Click \'Sync Templates\' to view the latest status."}</Typography>
                    </MDBox>
                </MDBox>
                <MDBox display="flex" sx={{marginTop:'12px'}} flexDirection="row" justifyContent="flex-end">
                    <MDBox display="flex" alignItems="center">
                        {templates && <Typography fontSize={"12px"}>Last sync <Moment tz={timezone} fromNow>{templates?.last_sync?.syncAt}</Moment></Typography>}
                    </MDBox>
                    <Button
                        onClick={syncTemplates}
                        variant="contained"
                        disabled={credentials?.length === 0}
                        sx={{
                            borderRadius: "0px",
                            color: "white!important",
                            border: "unset",
                            marginLeft: "12px",
                            height: "32px",
                            minHeight: "unset",
                        }}
                        startIcon={<Sync/>}
                        >
                        Sync Templates
                    </Button>
                    <Button
                        onClick={() => setCreateTemplate(true)}
                        variant="contained"
                        disabled={credentials?.length === 0}
                        sx={{
                            borderRadius: "0px",
                            color: "white!important",
                            border: "unset",
                            marginLeft: "12px",
                            backgroundColor:'gray',
                            height: "32px",
                            minHeight: "unset",
                        }}
                        startIcon={<Add/>}
                        >
                        Create Template
                    </Button>                    
                </MDBox>
                {credentials?.length === 0 &&
                    <MDBox display="flex" width="100%" justifyContent="flex-end">
                        <Typography color={"error"} fontSize={'14px'}>{"No WhatsApp number found, hence these buttons are disabled."}</Typography>
                    </MDBox>   
                }
                <Divider
                    variant="horizontal"
                    component="div"
                    sx={{
                    "&.MuiDivider-root": {
                        backgroundColor: "#e9d5e9",
                    },
                    }}
                /> 
                <MDBox width="100%">
                    <WTemplates data={templates.templates}/>               
                </MDBox>    
            </MDBox>
            {createTemplate && <AppModal
                rootStyle = {
                {
                    "&.MuiModal-root":{
                    width:'90%',
                    left: '10%'
                    }
                }
                }
                closeOnClick={false}
                customStyle={{ 
                width: "100%",
                height: "100%",
                overflowY: "auto",
                border: "unset",
                }}
                heading={" "}
                onModalClose={() => setCreateTemplate(false)}        
            >
                <CreateTemplate waCredentials={currCred} company={company} onTemplateCreated={onTemplateCreated}/>
            </AppModal>}

            {openWASettings &&
                <AppModal
                    rootStyle = {
                        {
                            "&.MuiModal-root":{
                            width:'30%',
                            left: '70%'
                            }
                        }
                    }
                    customStyle={{ 
                    width: "100%",
                    height: "100%",
                    overflowY: "auto",
                    border: "unset",
                    }}
                    heading={" "}
                    onModalClose={() => setWASettings(false)}  
                >
                    <WASettings company={company}/>
                </AppModal>
            }

        </>
    )
}
export default Whatsapp;