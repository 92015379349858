import React from "react";
import style from "pages/NotFound.module.css";
import { Refresh } from "@mui/icons-material";
export class AppErrorHandler extends React.Component{
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }      
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className={style["not-found"]}>
          <h2>{`OOPS Something went wrong. It may be due to session expiration. Please click on the icon below to refresh.`}</h2>
          <Refresh onClick={() => window.location.reload()} className={style["not-found__img"]} style={{fontSize:'40px',cursor:'pointer',color:'white'}}/>
        </div>
      );
    }
    return this.props.children; 
  }      
} 