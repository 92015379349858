import { Paper, Typography } from "@mui/material";
import { saveCarrier } from "api/carrier";
import useHttp from "hooks/use-http";
import MDBadge from "lib/components/MDBadge";
import MDBox from "lib/components/MDBox";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import BackButton from "ui/BackButton";
import CarrierForm from "./CarrierForm";

const EditCarrierForm = ({carrier,onEdited}) => {


    const token = useSelector(state => state.user.token);

    const {sendRequest : saveCarrierFn, data, status, error} = useHttp(saveCarrier,false);


    const onSubmit = (req) => {
        saveCarrierFn({req, token});
    }

    useEffect(() => {
        if(status === 'completed'){
            if(data){
                toast.success("Carrier data updated successfully.");
                onEdited(data);
            }
        }
    },[data, status, error]);

    return(
        <Paper sx={{
            padding:'12px'
        }}>
            <MDBox display="flex" justifyContent="center" sx={{
                backgroundColor: '#fbfbfb',
                padding:'12px',
                marginTop : '8px'
            }}>
                <Typography>Edit API configuration </Typography>                                         
            </MDBox>
            <CarrierForm carrier={carrier} onSubmit={onSubmit}/>
        </Paper>
    )
}
export default EditCarrierForm;