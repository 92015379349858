import { Close, Upload } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { BASE_URL } from "constants/Endpoints";
import EnhancedTable from "components/CommonUI/EnhancedTable";
import MDBox from "lib/components/MDBox";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { appAction } from "store/app-slice";

const t = {
    "success_count":1,
    "failure_count":0,
    "processed":[
        {"file_name":"+91 6239 974 510 - Copy (6).html","success":true}
    ],
    "total_files":1,"import_id":86
}
const columns = [
    { id: "1", key: "file_name", label: "File name" },
    { id: "2", key: "success", label: "Status" },
    {id : "3", key: "total_record",label : "Total records"}
]
const WhatsappMediaImport = ({result = [],initial = true}) => {
    
    const dispatch = useDispatch();

    const token = useSelector(state => state.user.token);

    const inputRef = useRef();

    const [media,setMedia] = useState();

    const [rows,setRows] = useState([]);
    
    const [totalRecords,setTotalRecords] = useState();

    const handleClickImage = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const startMediaUpload = useCallback(() => {

        const worker = new Worker("/worker/whatsappMediaImportWorker.js");
        dispatch(appAction.setChatDynamicComponent(undefined));
        dispatch(appAction.setPreventRefresh({
            eventInProgress : true,
            message : 'Import is in progress. Are you sure want to leave?'
        }));
        const  snackBarState = {
            show: true,
            heading: `Processing ${media.length} files...`,
            type: "info",
            content: <p>Starting upload...</p>,
            horizontal: 'center',
            vertical: 'top',
            icon : <Upload/>
        };
        dispatch(appAction.setSnackbar(snackBarState))                
        // Define an event listener for messages from the worker
        worker.onmessage = (event) => {
            let d = event.data;
            if(d.phase === 'start') {
                dispatch(appAction.setSnackbarContent(<p>Started file processing....</p>))
            }

            else if(d.phase === 'finished') {
                // Clean up the worker
                dispatch(appAction.setSnackbar(undefined));
                worker.terminate();
                const resultD = d.data;

                // show reports...
                let contnt = {
                    show: true,
                    heading: `Successfully processed ${resultD.success_count} media files.`,
                    type: "success",
                    content: <MDBox display="flex">
                    <a style={{cursor:'pointer',color:'white'}} onClick={() => {
                        dispatch(appAction.setChatDynamicComponent(<WhatsappMediaImport initial={false} result={resultD}/>))
                        dispatch(appAction.setSnackbar(undefined));  
                    }}><Typography fontSize={'16px'} color={"white"}>{"View report"}</Typography></a>
                    </MDBox>,
                    horizontal: 'center',
                    vertical : 'top'
                };
                dispatch(appAction.setSnackbar(contnt));                
            }

            else if(d.phase === 'error'){
                toast.error(d.data);
                dispatch(appAction.setSnackbar(undefined));  
            }
            else if(d.phase === "processing"){
                dispatch(appAction.setSnackbarContent(<p>Uploaded {d.data}</p>))
            }

        };                
        
        // Send a message to the worker to start the file upload
        worker.postMessage({files : media,domain: 'whatsapp', token,base_url : BASE_URL,import_id : result.import_id});
    
    },[media,token,result])

    const handleChangeFileInput = useCallback((event) => {
        if (event.target) {
            const target = event.target;
            const files = target.files;
            if (files) {
                setMedia(files);
            }
        }
    }, [setMedia]);

    useEffect(() => {
        let records = 0;
        const newRows = result.processed?.map((itm,indx) => {
            records += itm.total_record;
            return{
                id: indx + 1,
                file_name : itm.file_name,
                total_record : itm.total_record,
                success : itm.success ? 'Imported successfully': itm?.reason
            }
        });
        setRows(newRows);
        setTotalRecords(records);
    },[result,setRows,setTotalRecords]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.addEventListener("change", handleChangeFileInput);
        }
        return () => {
            if (inputRef.current) {
                inputRef.current.removeEventListener("change", handleChangeFileInput);
            }
        };
    }, []);

    return <>
        <MDBox display="flex" flexDirection="column" sx={{overflowY:'auto'}}>
            <MDBox display="flex" justifyContent="flex-end" width="100%">
                <Close sx={{cursor:'pointer',height:'1.75rem',width:'1.75rem',margin:'12px'}} onClick={() => {
                    if(initial){
                        if(window.confirm("You have not uploaded any media. Are you sure want to exit?")){
                            dispatch(appAction.setChatDynamicComponent(undefined))    
                        }
                    }
                    else{
                        dispatch(appAction.setChatDynamicComponent(undefined))
                    }
                }} />
            </MDBox>
            {initial && <MDBox onClick={handleClickImage} flexDirection="column" width="100%" display="flex" alignItems="center" justifyContent="center" height="150px" sx={{border:'2px dashed rgb(239, 239, 239)',cursor:'pointer'}}>
                <input ref={inputRef} type="file" id="upload-wa-media" multiple hidden/>
                <Typography>{"Click to upload media for this import."}</Typography>
                {media &&  <>
                    <Typography fontSize={'14px'} fontWeight={600} fontStyle={'italic'}>{"Total files to upload : "} {media.length}</Typography>
                    <MDBox onClick={e => {
                        e.stopPropagation();
                        startMediaUpload();
                    }}>
                        <Button sx={{border:'2px dashed blue'}}>{"Upload"}</Button>
                    </MDBox>
                </>}
            </MDBox>}
            {result?.report && 
                <MDBox display="flex" mt={2} flexDirection="column" justifyContent="center" alignItems="center">
                    <Typography variant="h5" m={2}>Reports</Typography>
                    <MDBox>
                        <Typography fontSize={'18px'} variant="subtitle1" >Total files processed : {result?.total_files}</Typography>
                    </MDBox>
                    <MDBox>
                        <Typography fontSize={'18px'} variant="subtitle1" >Successful : {result?.success_count}</Typography>
                    </MDBox>
                    <MDBox>
                        <Typography fontSize={'18px'} variant="subtitle1" >Failed : {result?.failure_count}</Typography>
                    </MDBox>
                    {totalRecords && totalRecords > 0 && <MDBox>
                        <Typography fontSize={'18px'} variant="subtitle1" >Total records inserted : {totalRecords}</Typography>
                    </MDBox>}
                    <MDBox sx={{maxHeight:'500px',overflowY:"auto"}}>
                        <EnhancedTable showPagination={true} showFilter={false} columns={columns} selectEnabled={false} rows={rows}/>
                    </MDBox>
                </MDBox>
            }
        </MDBox>
    </>
} 
export default WhatsappMediaImport;