import { useDispatch, useSelector } from "react-redux";
import { IGroupAPI } from "services/IChat/IGroupAPI";
import { MessageAPI } from "services/IChat/MessageAPI";
import { MessageReplyAPI } from "services/IChat/MessageReplyAPI";
import { IChatAction } from "store/IChat/Ichat-slice";
import { IGroupAction } from "store/IChat/igroup-slice";
import { IThreadAction } from "store/IChat/ithread-slice";

const useIgroupsend = () => {

    const dispatch = useDispatch(); 
    const userId = useSelector(state => state.user.id);

    const createMessage = (payload) => {
        IGroupAPI.createGroupMessage(payload).then(res => {
            // update message only....
            dispatch(IGroupAction.updateMessage({message : res.data, chatId : res.data.chatId}));
        }).catch(e => console.log(e));
    }
    
    const sendGroupMessage = ({payload,token}) => {
        dispatch(IGroupAction.addMessage({messages : [payload],chatId : payload.chatId}))
        if(payload.newChat){
            payload = {
                ...payload,
                chatId : undefined
            }
        }
        //Call the endpoint...
        createMessage({payload,token});
    }

    const sendGroupReply = ({payload,token}) => {
        // Dispatch for thread.....
        dispatch(IThreadAction.addReply({parentId : payload.parentMessage.messageId,reply : payload}));
        MessageReplyAPI.createGroupReply({payload,token}).then(res => {
            dispatch(IGroupAction.updateReplyCounts({chatId : payload.chatId,message : payload}));
            dispatch(IThreadAction.updateReply({parentId : res.data.parentMessage.messageId,message:res.data}));
        }).catch(e => console.log(e));
    } 

    return {sendGroupMessage,sendGroupReply}
}
export default useIgroupsend;