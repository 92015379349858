import { Paper, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import BackButton from "ui/BackButton";
import CarrierForm from "./CarrierForm";
import MDBadge from "lib/components/MDBadge";
import { saveCarrier } from "api/carrier";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";
import useHttp from "hooks/use-http";
import AppConstants from "constants/AppConstants";

const AddCarrier = ({onBack,onCreated}) => {

    const token = useSelector(state => state.user.token);

    const {sendRequest : saveCarrierFn, data, status, error} = useHttp(saveCarrier,false);

    const onSubmit = (req) => {
        //Validate fields
        if(!req.carrier_name || req.carrier_name.length === 0){
            toast.error("Please fill all the required fields.");
            return;
        }
        saveCarrierFn({req, token});
    }

    useEffect(() => {
        if(status === 'completed'){
            if(data){
                toast.success("Carrier data saved successfully.");
                onCreated(data);
            }
        }
    },[data, status, error]);
    
    return(
        <Paper sx={{
            padding:'12px'
        }}>
            <MDBox>
                <MDBox display="flex" justifyContent="flex-end">
                    <BackButton onClick={onBack} text={"back"}/>                    
                </MDBox>
            </MDBox>
            <MDBox sx={{
                backgroundColor: '#fbfbfb',
                padding:'12px',
                marginTop : '8px'
            }}>
                <Typography>Connect External API </Typography>
                <Typography fontSize={'16px'}>{AppConstants.CARRIER_INFO}</Typography>                                             
            </MDBox>
            <CarrierForm onSubmit={onSubmit}/>
        </Paper>
    )
}
export default AddCarrier;