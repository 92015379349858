import { Download } from "@mui/icons-material";
import { Paper, Tooltip, Typography } from "@mui/material";
import { exportGroupLog } from "api/groupLogs";
import { getGroupLog } from "api/groupLogs";
import EnhancedTable from "components/CommonUI/EnhancedTable";
import AppConstants from "constants/AppConstants";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import { useCallback, useEffect, useState } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

let columns = [
    {
      id: "1",
      key: "group_name",
      label: "Group name",
    },
    {
      id: "2",
      key: "phone",
      label: "Phone",
    },
    {
      id: "3",
      key: "recipient",
      label: "Recipient",
    },
    {
      id: "4",
      key: "keyword",
      label: "Keyword",
    },
    {
      id: "5",
      key: "joined_via",
      label: "Joined Via",
    },
    {
      id: "6",
      key: "created_at",
      label: "Joined At",
    },
  ];
const GroupJoinedLogs = () => {

    const timezone = useSelector(state => state.userDetails.details?.userSettings?.timezone)

    const currUser = useSelector(state => state.user);

    const [rows,setRows] = useState([]);

    const {sendRequest : getLogs, status, data, error} = useHttp(getGroupLog,false);

    const {sendRequest : exportFn, status : exportStatus, data : exportData, error : exportErr} = useHttp(exportGroupLog,false)

    const onFilterClick = () => {}
    
    const exportLogs = useCallback(() => {
      if(currUser.company != null && currUser.company){
        exportFn({companyId: currUser.company.company_id,token : currUser.token})
      }
      else{
        toast.error(AppConstants.ACTION_NOT_ALLOWED)
      }
    },[currUser]);
    
    useEffect(() => {
      if(currUser.company != null && currUser.company){
        getLogs({companyId: currUser.company.company_id,token : currUser.token});
      }
    },[currUser])

    useEffect(() => {
        if(status === 'completed'){
            if(data){
                const newRows = data?.map((item) =>{
                    return{
                        ...item,
                        id : item.id,
                        keyword : item?.keyword,
                        created_at : <>
                            <Moment tz={timezone} format="ll">{item.createdAt}</Moment>
                            {" "}(<Moment tz={timezone} format="LT">{item.createdAt}</Moment>)
                        </>
                    }
                })
                setRows(newRows);
            }
        }
    },[status,data,error]);

    return (
      <>
        <Paper>
          <MDBox
            sx={{
              margin: "12px",
              padding: "12px",
            }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDBox width="100%" display="flex" justifyContent="flex-end">
              <MDBox display="flex" justifyContent="flex-end" sx={{marginRight:'8px',height:'40px', width:'150px',padding:'12px'}} >
                <Tooltip title="Export">
                  <Download sx={{cursor:'pointer',height:'1.5rem',width:'1.5rem'}} onClick={exportLogs}/>
                </Tooltip>
              </MDBox>
            </MDBox>
          </MDBox>
            <MDBox m={2}>
                <EnhancedTable
                onFilterClick={onFilterClick}
                rows={rows}
                heading={"Logs"}
                columns={columns}
                showFilter={false}
                selectEnabled={false}
                />
            </MDBox>
        </Paper>
      </>
    );
}
export default GroupJoinedLogs;