import { Info } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { useState } from "react";
import AppModal from "ui/AppModal";
import Transactions from "./Transactions";

let ACTION = {
    VIEW : 'view',
    NONE : 'none'
}
const TransactionInfo = ({campaignId}) => {
    const [action, setAction] = useState(ACTION.NONE);
    return(
        <>
            <MDBox
                sx={{}}
                mt={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Tooltip title="View transactions details">
                <Info
                    onClick={() => setAction(ACTION.VIEW)}
                    sx={{ width: "1.2rem", height: "1.2rem" }}
                    style={{ cursor: "pointer", fontSize: "40px!important" }}
                />
                </Tooltip>
            </MDBox>
            {action === ACTION.VIEW && 
                <AppModal rootStyle={{height: '100vh', left:'5%'}} heading={"Transactions"} customStyle={{width:'100%',height:'100%',overflowY:'hidden',border:'unset'}} onModalClose={() => setAction(ACTION.NONE)}>
                    <Transactions campaignId={campaignId}/>
            </AppModal>}
        </>
    )
}
export default TransactionInfo;