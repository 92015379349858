import { Button, Icon, TextField, Tooltip, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import Table from "ui/Table";
import { myShortcutsColumns as columns } from "constants/Columns";
import MDButton from "lib/components/MDButton";
import { useEffect, useState } from "react";
import useHttp from "hooks/use-http";
import { getUserShortcut } from "api/shortcut";
import CreateShortcut from "./CreateShortcut";
import EditShorcut from "./EditShortcut";

const MyShortcuts = ({currentUser,onCompanyShorcutCreated,onEdited,onDeleted,showCreateBtn=true}) => {

    
    const [createShortcut, setCreateShortcut] = useState(false);
    const [userShortcuts, setUserShortcuts] = useState([]);

    const {sendRequest : getUserShortcutFn, status : userShortcutStatus , data : fetchedUserShortcuts} = useHttp(getUserShortcut,false);

    const onShorcutEdited = (data) => {
        const newUserShorcuts = userShortcuts.map(item => {
            if(item.id === data.id){
                return data;
            }
            else return item;
        })
        setUserShortcuts(newUserShorcuts);
        if(onEdited){
            onEdited(data);
        }
    }
    const shortcutDeleted = (data) => {
        const newUserShorcuts = userShortcuts.filter(item => item.id !== data.id)
        setUserShortcuts(newUserShorcuts);
        if(onDeleted){
            onDeleted(data);
        }
    }
    const formatRows_ = (shortcuts) => {
        const rows = shortcuts.map((item,index) => {
            const row = {
                "sno": index + 1,
                "key" : item.key,
                "value" : <div 
                   style={{
                   maxWidth:'200px'}}> 
                   {item.value}
               </div>,
               "type" : item.privateShortcut ? 'Just me': 'Whole company',
                "created_by" : item.user?.email,
                "action" : <EditShorcut key={item?.id} onEdited={onShorcutEdited} onDeleted={shortcutDeleted} shortcut={item}  currentUser={currentUser}/>
            }
            return row;
       });
       return rows;
    }
    useEffect(() => {
        getUserShortcutFn({
            userId : currentUser.id,
            token : currentUser.token
        })
    },[]);

    useEffect(() => {

        if(userShortcutStatus === 'completed'){
            if(fetchedUserShortcuts){
                setUserShortcuts(fetchedUserShortcuts);
            }
        }
    },[userShortcutStatus,fetchedUserShortcuts]);

    return(
        <>
            <MDBox>
                {showCreateBtn && <MDBox display="flex" flexDirection="row" justifyContent="flex-end">
                    <MDButton  onClick={() => {
                        setCreateShortcut(true)
                    }} variant="gradient" color="info">
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;create shortcut
                    </MDButton>
                </MDBox>}
                <Table heading={"My shortcuts"} columns={columns? columns : []} rows={userShortcuts ? formatRows_(userShortcuts) : []} />
            </MDBox>
            {createShortcut && 
                <CreateShortcut onCreated={(shortcutCreated) => {
                    setCreateShortcut(false);
                    if(shortcutCreated.privateShortcut){
                        setUserShortcuts(prev => [shortcutCreated,...prev]);
                    }
                    onCompanyShorcutCreated(shortcutCreated)    
                }} 
                currentUser={currentUser}
                create={createShortcut}
                onCancel={() => setCreateShortcut(false)}
                />
            }
        </>
    )
}
export default MyShortcuts;