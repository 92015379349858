import MDBox from "lib/components/MDBox";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";

const CompanyWideAutoReplyMessage = ({autoReply,setAutoReply}) => {

    const onAutoReplyHandler = (e) => {
        setAutoReply(prev =>{
            return {
                ...prev,
                reply : e.target.value
            }
        });
    }

    return(
        <>
            <MDBox display="flex" flexDirection="row">
                <MDInput
                    style={{ width: "60%", margin: "8px" }}
                    type="text"
                    label="Enter auto reply"
                    multiline
                    rows={5}
                    value={autoReply.reply}
                    onChange={onAutoReplyHandler}
                />
            </MDBox>        
        </>
    )
}
export default CompanyWideAutoReplyMessage;