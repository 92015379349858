import { Autocomplete, Box, Divider, List, Popover, Stack, TextField, Tooltip, Typography } from "@mui/material";
import ShortcutIcon from '@mui/icons-material/Shortcut';
import { useEffect, useState } from "react";
import MDBox from "lib/components/MDBox";
import { Add, Delete, Edit } from "@mui/icons-material";
import CreateShortcut from "./CreateShortcut";
import { useSelector } from "react-redux";
import useHttp from "hooks/use-http";
import { getUserShortcut } from "api/shortcut";
import { getCompanyShortcuts } from "api/shortcut";
import AppModal from "ui/AppModal";
import MyShortcuts from "./MyShorcuts";
import { memo } from "react";
import AdminShortcuts from "./AdminShortcuts";

const UserShortcuts = ({ onShortcutSelected, mobile = false, sx = {} }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [createShortcut, setCreateShortcut] = useState(false);
    const currentUser = useSelector(state => state.user);
    const [showDialog, setShowDialog] = useState(false);
    const [companyShortcuts, setCompanyShortcuts] = useState([]);
    const [userShortcuts, setUserShortcuts] = useState([]);
    const { sendRequest: getUserShortcutFn, status: userShortcutStatus, data: fetchedUserShortcuts } = useHttp(getUserShortcut, false);
    const { sendRequest: getCompanyShortcutsFn, status: companyShortcutStatus, data: fetchedCompanyShortcuts } = useHttp(getCompanyShortcuts, false);
    const onShortcutEdited = (data) => {
        const newUserShorcuts = userShortcuts.map(item => {
            if (item.id === data.id) {
                return data;
            }
            else return item;
        })
        setUserShortcuts(newUserShorcuts);
    }

    const onShortcutDeleted = (data) => {
        const newUserShorcuts = userShortcuts.filter(item => item.id !== data.id);
        setUserShortcuts(newUserShorcuts);

        if (!data.privateShortcut) {
            const newCompanyShorcuts = companyShortcuts.filter(item => item.id !== data.id);
            setCompanyShortcuts(newCompanyShorcuts);
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        getUserShortcutFn({
            userId: currentUser.id,
            token: currentUser.token
        });
        getCompanyShortcutsFn({
            companyId: currentUser.company?.company_id,
            token: currentUser.token
        })
    }, []);
    useEffect(() => {

        if (userShortcutStatus === 'completed') {
            if (fetchedUserShortcuts) {
                setUserShortcuts(fetchedUserShortcuts);
            }
        }
    }, [userShortcutStatus, fetchedUserShortcuts]);
    useEffect(() => {

        if (companyShortcutStatus === 'completed') {
            if (fetchedCompanyShortcuts) {
                setCompanyShortcuts(fetchedCompanyShortcuts);
            }
        }
    }, [companyShortcutStatus, fetchedCompanyShortcuts]);
    return (
        <>
            {
                mobile ? <ShortcutIcon style={{ cursor: 'pointer', marginLeft: '6px', ...sx }} onClick={handleClick} />
                    :
                    <Tooltip title={"Quick Reply"}>
                        <ShortcutIcon style={{ cursor: 'pointer', marginLeft: '6px', }} onClick={handleClick} />
                    </Tooltip>
            }
            <Popover
                onClose={handleClose}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                sx={{
                    "& .MuiPopover-paper": {
                        backgroundColor: "white!important",
                    },
                }}
            >
                <MDBox width="300px" display="flex" flexDirection="column">
                    <MDBox display="flex" flexDirection="column">
                        <MDBox style={{ padding: '4px' }} display="flex" justifyContent="space-between" flexDirection="row" alignItem="center">
                            <Typography varient="h6">My shortcuts</Typography>
                            <MDBox>
                                <Tooltip title="Create shortcut">
                                    <Add style={{
                                        cursor: 'pointer',
                                        width: '22px',
                                        height: '22px'
                                    }}
                                        onClick={() => setCreateShortcut(true)}
                                    />
                                </Tooltip>
                                <Tooltip title="Edit shortcut">
                                    <Edit style={{
                                        cursor: 'pointer',
                                        width: '20px',
                                        height: '20px'
                                    }}
                                        onClick={() => setShowDialog(true)}
                                    />
                                </Tooltip>
                            </MDBox>
                        </MDBox>
                        <Stack>
                            <Autocomplete
                                id="my-shortcuts"
                                disableClearable
                                style={{
                                    padding: '4px'
                                }}
                                onChange={(event, val) => {
                                    onShortcutSelected(val.value);
                                    handleClose();
                                }}
                                renderOption={(props, option) => {
                                    return (
                                        <>
                                            <Box {...props} sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <Box width={"100%"}>
                                                    {option.key + " - " + option.value}
                                                </Box>
                                            </Box>
                                            <Divider component={"div"}
                                                sx={{
                                                    "&.MuiDivider-root": {
                                                        backgroundColor: "#e9d5e9",
                                                    },
                                                    width: "100%"
                                                }}
                                            />
                                        </>
                                    )
                                }}
                                options={userShortcuts}
                                getOptionLabel={(option) => option.value}
                                renderInput={(params) => (

                                    <TextField
                                        {...params}
                                        label="Search input"
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                    />
                                )}
                            />
                        </Stack>
                    </MDBox>
                    <MDBox display="flex" flexDirection="column">
                        <MDBox style={{ padding: '4px' }} display="flex" justifyContent="space-between" flexDirection="row">
                            <Typography varient="h6">Company shortcuts</Typography>
                        </MDBox>
                        <Stack>
                            <Autocomplete
                                style={{
                                    padding: '4px'
                                }}
                                id="other-shortcuts"
                                onChange={(event, val) => {
                                    onShortcutSelected(val.value);
                                    handleClose();
                                }}
                                getOptionLabel={(option) => option.value}
                                renderOption={(props, option) => {
                                    return (
                                        <>
                                            <Box {...props} sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <Box width={"100%"}>
                                                    {option.key + " - " + option.value}
                                                </Box>
                                            </Box>
                                            <Divider component={"div"}
                                                sx={{
                                                    "&.MuiDivider-root": {
                                                        backgroundColor: "#e9d5e9",
                                                    },
                                                    width: "100%"
                                                }}
                                            />
                                        </>
                                    )
                                }}
                                disableClearable
                                options={companyShortcuts}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Search input"
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                    />
                                )}
                            />
                        </Stack>
                    </MDBox>
                </MDBox>
            </Popover>
            {createShortcut &&
                <CreateShortcut onCreated={(shortcutCreated) => {
                    setCreateShortcut(false);
                    if (shortcutCreated.privateShortcut) {
                        setUserShortcuts(prev => [shortcutCreated, ...prev])
                    } else {
                        setCompanyShortcuts(prev => [shortcutCreated, ...prev]);
                    }
                }}
                    currentUser={currentUser}
                    create={createShortcut}
                    onCancel={() => setCreateShortcut(false)}
                />
            }
            {showDialog && <AppModal
                show={showDialog}
                heading="My shortcuts"
                customStyle={{ width: '100%', height: '100%', overflowY: 'scroll', border: 'unset' }}
                onModalClose={() => {
                    setShowDialog(false);
                }}>
                <MyShortcuts showCreateBtn={false} currentUser={currentUser} onCompanyShorcutCreated={() => { }} onEdited={onShortcutEdited} onDeleted={onShortcutDeleted} />

                {(currentUser?.role == "ADMIN" || currentUser?.role == "SUPERADMIN") && <AdminShortcuts currentUser={currentUser} companyShorcutCreated={() => { }}></AdminShortcuts>}
            </AppModal>
            }
        </>
    )
}
export default memo(UserShortcuts);