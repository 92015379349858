import { BASE_URL } from "constants/Endpoints";
import fetch from "core/fetch";

export const createCompany = async(company) => {
    const response = await fetch(`${BASE_URL}/company`,{
        method : 'POST',
        body : company.d,
        headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization' : 'Bearer ' + company.token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
} 
export const createCompanyTag = async(company) => {
    let payload = {
        ...company,
        token : undefined
    }
    const response = await fetch(`${BASE_URL}/company/create/tag`,{
        method : 'POST',
        body : JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + company.token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
} 
export const getCompanyTag = async({companyId,token}) => {
    const response = await fetch(`${BASE_URL}/company/${companyId}/tags`,{
        method : 'GET',
        body : JSON.stringify(companyId),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
export const updateCompany = async({d,token}) => {
    const response = await fetch(`${BASE_URL}/company`,{
        method : 'PATCH',
        body : d,
        headers: {
            // 'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data;
}
export const findCompanyById = async({companyId,token}) =>{
    const response = await fetch(`${BASE_URL}/company/${companyId}`,{
        method : 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const getCompaniesList = async(token) => {
    const response = await fetch(`${BASE_URL}/company`,{
        method : 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
export const getUserCompany = async(company) => {
    const payload = {
        userId : company.userId
    }
    const response = await fetch(`${BASE_URL}/company/user`,{
        method : 'POST',
        body : JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + company.token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
} 

export const updateCardknoxKey = async(company) => {
    const response = await fetch(`${BASE_URL}/company/cardknox/update`,{
        method : 'POST',
        body : JSON.stringify(company.payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + company.token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
} 

export const getCompanyPrivacyPolicy = async({token,companyId}) => {

    const response = await fetch(`${BASE_URL}/company/${companyId}/privacy-policy`,{
        method : 'GET',
        headers: {
            'Authorization' : 'Bearer ' + token,
          }  
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const getCompanyPrivacyPolicyPublic = async(slug) => {

    const response = await fetch(`${BASE_URL}/download/template/${slug}/privacy-policy`,{
        method : 'GET', 
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const setCompanyPrivacyPolicy = async({token,privacyPolicy}) => {

    const response = await fetch(`${BASE_URL}/company/privacy-policy`,{
        method : 'POST',
        body : JSON.stringify(privacyPolicy),
        headers: {
            'Authorization' : 'Bearer ' + token,
            'Content-Type': 'application/json'
          }  
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}