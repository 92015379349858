import { BASE_URL } from "constants/Endpoints";
import fetch from "core/fetch";

export const getPhoneHistoryById = async({phoneId,token}) =>{
    const response = await fetch(`${BASE_URL}/phone/history/${phoneId}`,{
        method : 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}