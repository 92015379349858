import { Close } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import MDBox from "lib/components/MDBox";

const TagUI = ({tag,onDelete}) => {

    return(
        <MDBox display="flex" flexDirection="row" justifyContent="center" alignItems="center" sx={{backgroundColor : tag?.color,borderRadius:'12px',padding:'2px',margin:'2px'}}>
            <span style={{color : 'white',fontSize:'12px',marginLeft:'4px'}}>{tag?.name}</span>
            <Tooltip title="Click to remove.">
                <Close onClick={onDelete} sx={{height : '18px', color : 'white!important',cursor: 'pointer'}}/>
            </Tooltip>
        </MDBox>
    )
}
export default TagUI;