import { createSlice } from "@reduxjs/toolkit";

const initialLoaderStates = {
    showLoader : false,
}

const loaderSlice = createSlice({
    name : 'loader',
    initialState: initialLoaderStates,
    reducers : {
        show:  state => {
            state.showLoader = true;
        },
        hide: state => {
            state.showLoader = false
        },
    }
})
export const loaderAction = loaderSlice.actions;
export default loaderSlice;