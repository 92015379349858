import Picker from '@emoji-mart/react'
import data from '@emoji-mart/data';

const emojisToHide = ['leg', "pregnant_woman","pregnant_man","pregnant_person", 'bride_with_veil', 'breast-feeding', 'fairy', 'male_fairy', 'female_fairy', 'merperson', 'mermaid',
'female_genie', 'woman-running', 'dancer', 'dancers', 'woman-with-bunny-ears-partying', 'woman_climbing', 'woman-surfing','woman-swimming',
'woman-bouncing-ball','woman-lifting-weights','woman-mountain-biking','woman-cartwheeling','woman-wrestling','woman-playing-water-polo',
'woman-playing-handball','person_in_lotus_position','woman_in_lotus_position','couple','couplekiss','woman-kiss-man','man-kiss-man',
'woman-kiss-woman','couple_with_heart','woman-heart-man','man-heart-man','woman-heart-woman','kiss','briefs','bikini',
"om_symbol","star_of_david","wheel_of_dharma","yin_yang","latin_cross","orthodox_cross","star_and_crescent",
"peace_symbol","menorah_with_nine_branches","six_pointed_star","aries","taurus","gemini","cancer","leo",
"virgo","libra","scorpius","sagittarius","capricorn","aquarius","pisces","ophiuchus"];
const IEmoji = ({ addEmoji }) => {
    let _data = { ...data }

    _data.categories[0].emojis = data?.categories?.[0]?.emojis?.filter((emoji) => !emojisToHide.includes(emoji));
    _data.categories[5].emojis = data?.categories?.[5]?.emojis?.filter((emoji) => !emojisToHide.includes(emoji));
    _data.categories[6].emojis = data?.categories?.[6]?.emojis?.filter((emoji) => !emojisToHide.includes(emoji));
    _data.emojis = Object.keys(data.emojis)
  .filter(key => !emojisToHide.includes(key))
  .reduce((obj, key) => {
    obj[key] = data.emojis[key];
    return obj;
  }, {});

    return (
        <Picker data={_data} onEmojiSelect={addEmoji} />
    )
}
export default IEmoji;

