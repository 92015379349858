import { forgotPassword } from "api/user";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ResetPassword = ({email}) => {
  const codeRef = useRef();
  const navigate = useNavigate();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const [codeErr, setCodeErr] = useState();
  const { sendRequest, data, status, error } = useHttp(forgotPassword, false);
  const [passwordValidation, setPasswordValidation] = useState("");
  const onSubmitHandler = (e) => {
    e.preventDefault();
    const code = codeRef.current.value;
    const password = passwordRef.current.value;
    const confirmPass = confirmPasswordRef.current.value;
    if (code === "" || code.trim().length === 0) {
      setCodeErr("Reset code can not be empty.");
      return;
    }
    if (password !== confirmPass) {
      setPasswordValidation("Password and confirm password does not match.");
      return;
    }
    sendRequest({ code, password,email});
  };
  useEffect(() => {
    if (status === "completed") {
      if (error === null) {
        toast.success("Password reset successfully. You can login now.");
        navigate("/login");
      } else {
        setCodeErr(error);
      }
    }
  }, [status]);
  return (
    <MDBox pt={4} pb={3} px={3}>
      <MDBox component="form" role="form" onSubmit={onSubmitHandler}>
        <MDBox mb={2}>
          <MDInput
            type="text"
            label="Reset Code"
            fullWidth
            inputRef={codeRef}
          />
        </MDBox>
        <MDBox display="flex" alignItems="center" justifyContent="center">
          <MDTypography
            variant="subtitle2"
            fontWeight="regular"
            color="error"
          >
            {codeErr}
          </MDTypography>
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="password"
            label="Password"
            fullWidth
            inputRef={passwordRef}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="password"
            label="Confirm Password"
            fullWidth
            inputRef={confirmPasswordRef}
          />
        </MDBox>
        <MDBox display="flex" alignItems="center" justifyContent="center">
          <MDTypography
            variant="subtitle2"
            fontWeight="regular"
            color="error"
            mt={1}
          >
            <span>{passwordValidation}</span>
          </MDTypography>
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton type="submit" variant="gradient" color="info" fullWidth>
            submit
          </MDButton>
        </MDBox>
        <MDBox display="flex" alignItems="center" justifyContent="center">
          <MDTypography
            variant="subtitle2"
            fontWeight="regular"
            color="error"
            mt={2}
          >
            <Link to="/login">Back to login</Link>
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};
export default ResetPassword;
