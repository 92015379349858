import { Button } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import ShowData from "./ShowData";
import MappedColumns from "./MappedColumns";
import { loaderAction } from "store/loader-slice";
import { ImportChatAPI } from "api/importChat";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { chatAction } from "store/chat-slice";
import useHttp from "hooks/use-http";
import { uploadZip } from "api/importChat";
import { UploadFile } from "@mui/icons-material";

const SmsImport = ({steps,setSteps,totalSteps,onImported,onClose,currentActivePhone,onUploaded}) => {
    
    const token = useSelector(state => state.user.token);
    const userId = useSelector(state => state.user.id);
    const companyId = useSelector(state => state.user.company.company_id);
    const currUser = useSelector(state => state.user);

    const dispatch = useDispatch();

    const [processedData,setProcessedData] = useState();
    const [disableNext, setDisableNext] = useState(false);


    const [mapped,setMapped] = useState();
    const [file,setFile] = useState();

    const {sendRequest : uploadZipFn, status, data , error} = useHttp(uploadZip,false);


    const fileInputChange = e => {
        if (e.target.files && e.target.files[0]) {
            setFile(e.target.files[0]);
        }
    }

    const onFileUploadClick = useCallback((e) => {
        e.preventDefault();
        if(file){           
            const formData = new FormData();
            formData.append("file", file);
            formData.append("userId", JSON.stringify(userId));
            uploadZipFn({ formData, token });
        }
        // onFileAdded();
    },[file,userId,token,companyId,currentActivePhone]);

    const nextHandler = () => {
        setSteps(prev => prev + 1);
    }

    const prevhandler = () => {
        setDisableNext(false);
        setSteps(prev => prev - 1);
    }

    const onSubmitHandler = useCallback(() => {
        if(!mapped || mapped.phone.length === 0){
            toast.error("Phone number is not mapped");
            return;
        }
        else if(!mapped || mapped.recipient.length === 0){
            toast.error("Recipient number is not mapped");
            return;
        }
        else if(!mapped || mapped.message.length === 0){
            toast.error("Message content is not mapped");
            return;
        }
        else if(!mapped || mapped.inbound.mapped.length === 0){
            toast.error("Please map whether the message is inbound or outbound.");
            return;
        }
        const payload = {
            payload : processedData,
            ...mapped,
            userId : currUser.id
        }
        dispatch(loaderAction.show());
        ImportChatAPI.processMappedData({payload,token : currUser.token}).then(res => {
            dispatch(loaderAction.hide());
            toast.success("Chat imported.");
            onClose();
            dispatch(chatAction.setCurrentActiveRecipient(undefined));
            onImported();
            //Reload recipients for the given chat....
            // dispatch(chatAction.setCurrentActivePhone(undefined));
        }).catch(e => {
            dispatch(loaderAction.hide());
            toast.error(e.response?.data?.message);
        });
    },[mapped])

    useEffect(() => {   
        if(status === 'completed'){
            if(data){
                setProcessedData(data);
                setSteps(prev => prev + 1);
            }
            else{
                toast.error(error);
            }
        }
    },[status,data,error])

    return(
        
        <MDBox sx={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <MDTypography variant={"h4"} sx={{textAlign:'center'}}>{`Step ${steps + 1} of ${totalSteps} `}</MDTypography>
            {steps === 0 && <MDBox>
                <MDTypography sx={{textAlign:"center",marginTop:'12px',marginBottom:'12px'}}>{"Choose file to continue"}</MDTypography>
                <label for="inputFile" class="inputLabelButton">
                    <UploadFile></UploadFile>
                    Upload&nbsp;
                    <input id="inputFile" style={{display:'none', border:'2px solid #ddd',width:'100%', maxWidth:'300px'}} accept="text/html,application/zip,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/json,application/xml,text/xml,text/csv,text/plain" type="file" label="" onChange={fileInputChange} />
                </label>
                {file && 
                    <MDBox style={{marginTop:'12px'}} display="flex" alignItems="center" justifyContent="center">
                        <Button 
                            onClick={onFileUploadClick} 
                            variant="contained"
                            sx={{
                                borderRadius: "0px",
                                color: "white!important",
                                border: "unset",
                                marginLeft: "12px",
                                height: "32px",
                                minHeight: "unset",
                            }}
                        >
                            upload
                        </Button>
                    </MDBox>
                }            
                </MDBox>
            }
            <MDBox sx={{
                height: "calc(100vh - 180px)",
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                    width: "0.3em",
                    height: "0.4em"
                    },
                    "&::-webkit-scrollbar-thumb" : {
                    borderRadius:" 4px",
                    backgroundColor: "#8b8b8b"
                    }            
                }}
                pt={2}
                pb={3}
                px={3}
            >
                {steps === 1 && <ShowData data={processedData} setDisableNext={setDisableNext}/>}
                {steps === 2 && <MappedColumns data={processedData} onMapped={setMapped}/> }
            </MDBox>
            <MDBox display="flex" justifyContent="flex-end" mt={2} mb={2} mr={2}>
            {steps > 0 && steps < 3 && <Button 
                sx={{
                    borderRadius: "3px",
                    color: "black!important",
                    border: "unset",
                }}
                onClick={prevhandler}
                >
                {'Back'}
                </Button>
            }
            {(steps !== 3 && steps !== 0) && <Button 
                disabled={disableNext}
                variant="contained"
                sx={{
                    borderRadius: "3px",
                    color: "white!important",
                    border: "unset",
                }}
                onClick={steps == 2 ?  onSubmitHandler : nextHandler }
            >
                {steps == 2 ? "Submit" : 'Next'}
            </Button>}
            </MDBox>
        </MDBox>           
    )
}
export default SmsImport;