import { getUserListOfAssignedPhone } from "api/phoneCredentials";
import useHttp from "hooks/use-http";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import RemoveUserFromPhone from "../Phone/RemoveUserFromPhone";
import { FlexContainer } from "pages/Chat/ChatStyles";
import UserItem from "./UserItem";

const UserPhoneAssignedList  = ({phoneId}) => {
    const {sendRequest : getUserList , data , status , error} = useHttp(getUserListOfAssignedPhone,false,false);
    const [usrList , setUsrList] = useState([]);
    const token = useSelector(state => state.user.token);
    const [userRemove, setUserRemove] = useState(false);
    
    useEffect(() => {
        getUserList({phoneId,token});
    },[userRemove,phoneId,token]);

    useEffect(() => {
        if(status === 'completed'){
            if(data){
               setUsrList(data);
            }
            else if(error){
                toast.error(error);
            }
        }
    },[status,data,error])

    return (
        <>
        {usrList.length > 0 ? 
            usrList.map(user => 
                <div key={user.userId}>
                    <FlexContainer display="flex" justifyContent="space-between">
                        <UserItem image={user.profilePic} name={user.fullName} email={user.email}/>
                        <RemoveUserFromPhone token={token} phoneId={phoneId} userId={user.userId} onUserRemoved={() => setUserRemove(prev => !prev)}/>
                    </FlexContainer>
                </div>)
            : <p>{"No user assigned yet"}</p>
        }
        </>
    )

}
export default UserPhoneAssignedList;