import { CAMPAIGN } from "constants/Endpoints";
import { CROWDFUNDING_ENDPOINT } from "constants/Endpoints";
import fetch from "core/fetch";

export const AddBonus = async({payload, token}) => {

    const response = await fetch(`${CROWDFUNDING_ENDPOINT}${CAMPAIGN}/add/bonus`,{
        method : 'POST',
        body : JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const getBonus = async({campaign_id,token}) => {

    const response = await fetch(`${CROWDFUNDING_ENDPOINT}${CAMPAIGN}/${campaign_id}/bonus`,{
        method : 'GET',
        headers: {
            'Authorization' : 'Bearer ' + token,
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}