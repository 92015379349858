import { WEBSOCKET_EVENT_TYPE } from "constants/ChatConstants";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MessageAPI } from "services/IChat/MessageAPI";
import { IChatAction } from "store/IChat/Ichat-slice";
import { IGroupAction } from "store/IChat/igroup-slice";
import { IThreadAction } from "store/IChat/ithread-slice";
import { appAction } from "store/app-slice";
import { notify } from "util/AppNotification";
import { formatGroupChat } from "util/IChat/IChatUtil";

const useIevent = () => {

    const token = useSelector(state => state.user.token);
    const userId = useSelector(state => state.user.id);
    const iChat = useSelector(state => state.chatVariables.iChat);
    const notificationSettings = useSelector(state => state.userDetails.details?.userSettings);
    const groupFetched = useSelector(state => state.Igroup.fetched);
    const dispatch = useDispatch();

    const triggerNotification = useCallback((payload) => {
        // Firts check if desktop notifications are enable...
        if(Boolean(notificationSettings?.icSettings?.notification_enabled)){
            const sound =  notificationSettings?.icSettings?.notification_sounds.find(itm => itm.enabled);
            const notificationPayload = {
                from : payload?.sender?.fullName,
                body : payload?.text,
                sound : sound?.sourceUrl,
                muted : false
            }
            notify(notificationPayload);
        }
    },[notificationSettings])

    const onEventReceive = useCallback(async(type,payload) => {
        // Process the event
        if(type === WEBSOCKET_EVENT_TYPE.NEW_MESSAGE){
            try{
                triggerNotification(payload);
            }
            catch(error){
                console.log(error);
            }
            if(payload?.groupChat){
                processGpChat(payload);
            }
            else{
                processNewMessage(payload);
            }
            markMessageStatus(payload);
        }
        else if(type === WEBSOCKET_EVENT_TYPE.MESSAGE_ACK) {
            processMessageAck(payload);
        }
        else if(type === WEBSOCKET_EVENT_TYPE.ADDED_TO_GROUP && payload?.group?.adminId !== userId) {
            //TODO:: notify group addition...
            dispatch(appAction.setSnackbarMessage(`You’ve been added to group: ${payload.group.chatName}`));
            dispatch(appAction.openSnackbar());
            const formatChat = formatGroupChat(payload.group,userId);
            dispatch(IGroupAction.addGroup(formatChat));
        }
    },[groupFetched,iChat]);

    const processNewMessage = (payload) => {
        if(payload.senderId === userId){
            return;
        }
        //It's a reply message
        // dispatch(appSettingsAction.setSnackbarMessage(payload.text));
        // dispatch(appSettingsAction.openSnackbar());
        if(payload.parentMessage) {
            dispatch(IThreadAction.addReply({parentId : payload.parentMessage.messageId,reply : payload}));
             //end user...
             dispatch(IChatAction.updateReplyCounts({chatId : payload.chatId,message : payload}));
        }
        else{
            if(payload.newChat){
                dispatch(IChatAction.addNewChatWithMsg({chatId : payload.chatId, chat : payload, message : payload}));
            }
            else{
                dispatch(IChatAction.updateMessage({message : payload, chatId : payload.chatId}));
            }
        }
    }

    const processMessageAck = (payload) => {
        dispatch(IChatAction.updateMessageAck(payload));
    }

    const processGpChat = (payload) => {
        if(payload.senderId === userId){
            return;
        }
        if(payload.parentMessage) {
            try{
                //TODO: Logical error..
                dispatch(IThreadAction.addReply({parentId : payload.parentMessage.messageId,reply : payload}));
            }
            catch(e){}
            //end user...
            dispatch(IGroupAction.updateReplyCounts({chatId : payload.chatId,message : payload}));
        }
        else{
            dispatch(IGroupAction.updateMessage({message : payload,chatId : payload.chatId}))
        }
    }
    
    const markMessageStatus = (payload) => {
        // Call the endpoint to mark status as delivered....
        //    "chatId" : 1,
        // "messageIds" : [33],
        // "status" : "read",
        // "userId" : 1,
        // "senderId" : 616
        const ackPayload = {
            chatId : payload.chatId,
            messageIds : [payload.messageId],
            status : 'delivered',
            userId : payload.message?.recipientUserId,
            senderId : payload?.senderId
        }
        MessageAPI.markMessageStatus({token,payload : ackPayload});
    }

    return {onEventReceive}
}
export default useIevent;