import EditIcon from "@mui/icons-material/Edit";
import { Tooltip } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { useState } from "react";
import AppModal from "ui/AppModal";
import EditCarrierForm from "./EditCarrierForm";

const CarrierAction = ({carrier,onEdited}) => {
    
    const [showEditModel, setShowEditModel] = useState(false);

    return(
        <>
            <MDBox display="flex" flexDirection="row">
                <Tooltip title="Edit the number."> 
                    <EditIcon
                        onClick={() => {setShowEditModel(true)}}
                        style={{ marginRight: "8px", cursor: "pointer", width:'1.5rem', height:'1.5rem' }}
                    />
                </Tooltip>
            </MDBox>
            {showEditModel && 
                <AppModal
                    rootStyle = {
                        {
                        "&.MuiModal-root":{
                            width:'90%',
                            left: '10%'
                        }
                        }
                    }
                    customStyle={{ 
                        width: "100%",
                        height: "100%",
                        overflowY: "auto",
                        border: "unset",
                    }}
                    onModalClose={() => setShowEditModel(false)}    
                >
                { carrier && <EditCarrierForm onEdited={(res) => {
                    setShowEditModel(false);
                    onEdited(res);
                }} carrier={carrier}/>}
                </AppModal>}
        </>
    )
}
export default CarrierAction;