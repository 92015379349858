import React from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import { useState } from "react";
import MDBox from "lib/components/MDBox";
import {Tooltip} from "@mui/material";
import SettingMenu from "./SettingMenu";

const CampaignSetting = ({campaign, onCampaignDeleted}) => {

  const [action, setAction] = useState("");

  return (
    <>
      <MDBox
        mt={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Tooltip title="Campaign Settings">
          <SettingsIcon
            onClick={() => setAction("SETTING_TABLE")}
            sx={{ width: "1.2rem", height: "1.2rem" }}
            style={{ cursor: "pointer", fontSize: "40px!important" }}
          />
        </Tooltip>
      </MDBox>
      {
        action === "SETTING_TABLE"&&(
          <SettingMenu onClose={() => setAction("")} campaign={campaign} onCampaignDeleted={onCampaignDeleted}/>
        )
      }
    </>
  );
};

export default CampaignSetting;
