import { Settings } from "@mui/icons-material";
import { Icon, Tooltip } from "@mui/material";
import { useState } from "react";
import AppModal from "ui/AppModal";
import IconButton from "ui/IconButton";
import BulkFundraiserSettingMenu from "./BulkFundraiserSettingMenu";

const BulkFundraiserSettings = ({fundraisers,campaignId,onBulkSettingsUpdated}) => {
 
    const [showModal, setShowModal] = useState(false);

    return (
      <>
        {/* <Tooltip title="Settings"> */}
          <IconButton onClick={() => setShowModal(true)}>
            <Icon sx={{ fontWeight: "bold" }}>settings</Icon>
              &nbsp;&nbsp;{"Bulk setting"}
          </IconButton>
          {/* <Settings
            onClick={() => setShowModal(true)}
            sx={{ width: "1.5rem", height: "1.5rem" }}
            style={{ cursor: "pointer", fontSize: "40px!important" }}
          /> */}
        {/* </Tooltip> */}

        {showModal && (
          <AppModal
            rootStyle={{
              "&.MuiModal-root": {
                width: "80%",
                left: "20%",
              },
            }}
            customStyle={{
              width: "100%",
              height: "100%",
              overflowY: "auto",
              border: "unset",
            }}
            onModalClose={() => setShowModal(false)}
            heading = {" "}
          >
            <BulkFundraiserSettingMenu onBulkSettingsUpdated={onBulkSettingsUpdated} fundraisers={fundraisers} campaignId={campaignId}/>
          </AppModal>
        )}
      </>
    );
}
export default BulkFundraiserSettings;