// Images
// import bgImage from "assets/images/bg-sign-in-basic.webp";
import FundraiserLogin from "components/Auth/FundraiserLogin";

import NormalLogin from "components/Auth/NormalLogin";
import { useState } from "react";

//TODO:Load all the assets used in the APP from S3, it will reduce bundle size.

const LOGIN = {
  FUNDRAISER : 'fundraiser',
  NORMAL_USER : 'normal_user'
}
const Login = () => {

  const[currentLogin, setCurrentLogin] = useState(LOGIN.NORMAL_USER);

  return (
      <>
        {currentLogin === LOGIN.NORMAL_USER && <NormalLogin switchLogin={ (res) => setCurrentLogin(res)}/>}
        {currentLogin === LOGIN.FUNDRAISER && <FundraiserLogin switchLogin={ (res) => setCurrentLogin(res)}/>}
      </>
  );
}

export default Login;
