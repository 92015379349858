import { Box, Icon, Tooltip, Typography } from "@mui/material";
import { getKeywords } from "api/settings";
import { AddKeywords } from "api/settings";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import AddKeyword from "./AddKeyword";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';      
import { removeKeywords } from "api/settings";
import Swal from "sweetalert2";

const KeywordBasedResponse = () => {

    const [modalOpen,setModalOpen] = useState(false);

    const [keywords, setKeywords] = useState([]);

    const token = useSelector(state => state.user.token);
    const user = useSelector(state => state.user);

    const {sendRequest : addKeywordsFn, data : addedKeyword, status: keywordAddStatus, error : addKeywordErr} = useHttp(AddKeywords,false);
    
    const {sendRequest : getKeywordsFn , data : fetchedKeywords, status : fetchStatus, error: fetchedErr} 
    = useHttp(getKeywords,false);
    
    const {sendRequest : deleteKeyword , data : deletedMsg, status : delelteStatus , error : deleteErr}
    = useHttp(removeKeywords, false);

    const onKeywordDeleteHandler = (id) => {
      Swal.fire({
        title: 'Are you sure want to delete?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        customClass: {
          container : {
            zIndex : '10000 !important'
          }
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const keywordToDelete = keywords.find(ky => ky.id === id);
          const filteredArr = keywords.filter(ky => !(ky.id === id));
          deleteKeyword({reply : keywordToDelete,token});
          setKeywords(filteredArr);
        }
      })
    } 

    // const onKeywordResponseChange = (index,e) => {
    //     // setKeywordMessage(e.target.value);
    // }
    const onKeywordAddedHandler = useCallback((data) => {
      const payload = {
        ...data,
        company : {
          companyId : user.company?.company_id
        }
      }
        setModalOpen(false);
        addKeywordsFn(payload);
    },[user.company])
    
   useEffect(() => {
    if(user)
      getKeywordsFn({token,companyId : user.company.company_id});
   },[user])

   useEffect(() => {
      if(fetchStatus === 'completed'){
        if(fetchedKeywords){
          const k1 = fetchedKeywords?.filter(ky => (ky.keyword.toLowerCase() === 'info' || ky.keyword.toLowerCase() === 'help'));
          const k2 = fetchedKeywords?.filter(ky => !(ky.keyword.toLowerCase() === 'info' || ky.keyword.toLowerCase() === 'help'));
          setKeywords([...k1,...k2]);
        }
        else{
          toast.error(fetchedErr);
        }
      }
   },[fetchStatus,fetchedKeywords,fetchedErr]);

   useEffect(() => {
    if(keywordAddStatus === 'completed'){
      if(addedKeyword){
        setKeywords(prev => [...prev,addedKeyword]);
      }
      else{
        if(addKeywordErr)
        toast.error("We are not able to add this keyword, make sure it is does not already exists.");
      }
    }
   }, [addedKeyword,keywordAddStatus,addKeywordErr])
  return (
    <Box sx={{ width: "100%", p: 2, height: "100%" }}>
      <MDBox display="flex" flexDirection="row" justifyContent="flex-end">
        <MDButton  onClick={() => {setModalOpen(true);}} variant="gradient" color="info">
          <Icon sx={{ fontWeight: "bold" }}>add</Icon>
          &nbsp;Keyword
        </MDButton>
      </MDBox>
      {keywords.length === 0 && <MDBox p={2}>
        <MDTypography
          variant="h6"
          fontWeight="medium"
        >
          No keyword based response set yet.
        </MDTypography>
      </MDBox>}
      {keywords && keywords.length > 0 && 
        keywords.map( (keyword,index) => <MDBox key={index} p={2}>
            <MDTypography
            variant="h6"
            fontWeight="medium"
            textTransform="capitalize"
            >
            {keyword.keyword}
            </MDTypography>
            {keyword?.group && <Typography fontSize={"12px"}>
              {`Group mapped : ${keyword.group.name}`}{keyword.group.category ? `(${keyword.group.category.name})`: ''}
              </Typography>}
            <MDInput
            style={{ width: "60%", margin: "4px" }}
            type="text"
            label=""
            multiline
            disabled={true}
            value={keyword.reply ? keyword?.reply : keyword?.group?.name}
            // onChange={onKeywordResponseChange(null,keyword.id)}
            />  
            <Tooltip title="Delete this entry">
              <DeleteForeverIcon onClick={onKeywordDeleteHandler.bind(null,keyword.id)} style={{color:'#a89c9c',cursor:'pointer'}}/>
            </Tooltip>
        </MDBox>)
      }
      {modalOpen && <AddKeyword onClose={() => setModalOpen(false)} onAdded={onKeywordAddedHandler}/>}  
    </Box>
  );
};
export default KeywordBasedResponse;
