import { Edit, Info } from "@mui/icons-material";
import { Divider,Tooltip } from "@mui/material";
import { updateCompany } from "api/company";
import { getAppProducts } from "api/product";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import style from "./Company.module.css";
import { generatePrivacyPolicyUrl } from "util/CompanyUtils";
import AppConstants from "constants/AppConstants";
import { memo } from "react";
import ProductSubscriptions from "./ProductSubscriptions";

let logo = undefined;

const EditCompanyForm = ({company,onCompanyEdited,onHipaaSubscribed,onProductSubscribed,onProductUnsubscribed}) => {

    const [currCompany , setCurrCompany] = useState();
    const token = useSelector(state => state.user.token);
    const imageRef = useRef();
    const[nameValidation, setNameValidation] = useState('');
    const[dlcValidation, setDlcValidation] = useState('');
    const [contactValidation, setContactValidation] = useState('');

    const {sendRequest , data , status, error} = useHttp(updateCompany,false);
    
    const onLogoSelected = (e) => {
      imageRef.current.src = URL.createObjectURL(e.target.files[0]);
      logo = e.target.files[0];
    }

    const onFormSubmitHandler = (event) => {
      event.preventDefault();
      if(currCompany?.company_name == ''){
        setNameValidation('Please enter a valid name.');
        return;
      }

      if(currCompany?.ten_dlc_name == ''){
        setDlcValidation('Please enter a valid name.');
        return;
      }
      
      else if(currCompany?.company_contact == '' || !(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/).test(currCompany?.company_contact)){
        setContactValidation("Please enter a valid contact number.");
        return;
      }
      else{
        const d = new FormData();
        if(logo){
          d.append("logo",logo);
        }
        d.append("company",JSON.stringify(currCompany))
        sendRequest({d,token});
      }
    }

    useEffect(() => {
      if(company){
        setCurrCompany(company);
      }
    },[company]);
    
    useEffect(() => {
      if(status === 'completed'){
        if(error == null){
          toast.success("Company updated successfully.");
          onCompanyEdited(data);
        }
        else{
          toast.error(error);
          onCompanyEdited();  
        }
      }
    } , [status,error])   
    return (
      <MDBox display="flex" flexDirection="column">
        <MDBox pt={4} pb={3} px={3} onSubmit={onFormSubmitHandler}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                error={nameValidation !== ""}
                type="text"
                label="Name"
                variant="standard"
                value={currCompany?.company_name}
                onChange={(e) => {
                  setCurrCompany(prv => {
                    return {
                      ...prv,
                      company_name : e.target.value,
                      privacy_policy_url : generatePrivacyPolicyUrl(e.target.value,currCompany.company_id)
                    }
                  })
                }}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                error={dlcValidation !== ""}
                type="text"
                label="10 DLC Name"
                variant="standard"
                value={currCompany?.ten_dlc_name}
                onChange={(e) => {
                  setCurrCompany(prv => {
                    return {
                      ...prv,
                      ten_dlc_name : e.target.value
                    }
                  })
                }}
                fullWidth
              />
            </MDBox>            
            <MDBox mb={2}>
              <MDInput
                error={contactValidation !== ""}
                type="text"
                label="10 DLC Company Number"
                variant="standard"
                value={currCompany?.company_contact}
                onChange={(e) => {
                  setCurrCompany(prv => {
                    return {
                      ...prv,
                      company_contact : e.target.value
                    }
                  })
                }}
                fullWidth
              />
            </MDBox>            
            <MDBox mb={2} display="flex">
                <Tooltip title={AppConstants.LOGO_PURPOSE_TEXT}>
                  <Info sx={{cursor:'pointer',marginRight:'12px'}}/>
                </Tooltip>
              <img ref={imageRef} style={{height:'100px', width:'100px',objectFit:'contain'}} src={company?.company_logo}/>
                <MDBox className={style['image-upload']}>
                  <label for="file-input">
                    <Tooltip title="Edit logo">
                      <Edit sx={{cursor:"pointer"}}/>
                    </Tooltip>
                  </label>
                  <input id="file-input" type="file" onChange={onLogoSelected} />
                </MDBox>
                
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                Update
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
          {/*** SUBSCRIBED FEATURES */}
          <Divider component={"div"}/>
          {company && <ProductSubscriptions company={company} onProductUnsubscribed={onProductUnsubscribed} onHipaaSubscribed={onHipaaSubscribed} onProductSubscribed={onProductSubscribed}/>}  
      </MDBox>
    );
}
export default memo(EditCompanyForm);