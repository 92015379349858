import { BASE_URL } from "constants/Endpoints";
import fetch from "core/fetch";

export const getGroupLog = async({companyId,token}) => {
    const response = await fetch(`${BASE_URL}/group/logs/${companyId}`,{
        method : 'GET',
        headers :{
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + token,
        }
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;
}
export const exportGroupLog = async ({token,companyId}) => {
    const response = await fetch(`${BASE_URL}/group/logs/${companyId}/export`,{
        method : 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.blob();
    const link = document.createElement("a");
    link.download = "logs.xlsx";
    link.href = URL.createObjectURL(
        new Blob([data], { type: "xlsx" })
      );
      link.click();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}