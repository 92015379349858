import authPopup from 'services/AuthPopup/LoginInfoPopup';

const { fetch: originalFetch } = window;

window.fetch = async (...args) => {
  const handlePopup = authPopup();
  let [resource, config] = args;
  let response = await originalFetch(resource, config);
  if (!response.ok && response.status === 401) {
    let error = {
      response: { status: response?.status },
      request: { responseURL: response?.url },
    };
    handlePopup(error);
  }
  return response;
};
export default fetch;
