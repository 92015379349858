/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import MDInput from "lib/components/MDInput";
import MDButton from "lib/components/MDButton";

import { login } from "api/user";
import useHttp from "hooks/use-http";
import { useDispatch } from "react-redux";

import logo from "assets/images/logos/BLIQ/longtaglines-01.webp";

import { Card, Divider, IconButton, InputAdornment, Tooltip, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useMediaQuery } from "react-responsive";
import AppConstants from "constants/AppConstants";

const NormalLogin = () => {
  
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [emailValue,setEmailValue] = useState('')
  const [rememberMe, setRememberMe] = useState(true);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const {sendRequest:loginFn, status, data: userData , error} = useHttp(login, false);
  const [httpErr , setHttpErr] = useState('');
  const [passwordValidation, setPasswordValidation] = useState('');
  const [emailValidation, setEmailValidation] = useState('');
  const [passVisible, setPassVisible] = useState(false);
  const [capsOn, setCapsON] = useState(false);
  
  /// auth related
  const emailRef = useRef();
  const passwordRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginFormSubmitHandler = (event) => {
    event.preventDefault();
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    if(email === '' || email.trim().length === 0){
      setEmailValidation('Please enter valid email.');
      return;
    }
    if( password === '' || password.trim().length === 0){
      setPasswordValidation('Password field can not empty.');
      return;
    }
    setEmailValue(email.trim())
    loginFn({email : email.trim(),password : password.trim()});
  }
  const passwordKeyDown = (event) =>{
    if(event.getModifierState("CapsLock")){
      setCapsON(true);
    }else{
      setCapsON(false);
    }
  }

  useEffect(() => {
    const t = setTimeout(()=>{
      setEmailValue(emailRef?.current?.value)
    },5000);
    return () => {
      if(t)
        clearTimeout(t);
    }
  },[]);

  useEffect(() => {
    if(status === 'completed'){
      if(error === null){
        setHttpErr('');
        // console.log(userData)
        const data = {
          rememberMe,
          token : userData.token,
          emailAddress : userData.emailAddress,
          phoneNumber : userData.phoneNumber
        }
        navigate("/2FA/authenticate" ,{state : data});
      }
      else{
        setHttpErr(error);
      }
    }
  },[status,error,userData,dispatch])

  return (
    <MDBox width="100%" height={"100vh"} display="flex" flexDirection={isMobile ? "column" : "row"} justifyContent="center" alignItems="center" backgroundColor={"white!important"}>
      {!isMobile && <MDBox  m={2} width="60%" height={"100%"} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
        <Card width="100%" textAlign={"center"} sx={{
          boxShadow: '1rem 0.25rem 1.375rem -0.0625rem rgba(0, 0, 0, 0.1),0rem 1.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06)',
          padding: '16px',
          borderRadius: '0px 50px 0px 50px',
          maxWidth: '600px'
        }}>
          <MDTypography textAlign="center" color="black" variant="h3">{AppConstants.HOME_PAGE_TITLE}</MDTypography>
          <Typography p={2} textAlign="center" fontSize={"18px"}>{AppConstants.HOME_PAGE_TEXT1}</Typography>
          <Typography p={2} textAlign="center" fontSize={"18px"}>{AppConstants.HOME_PAGE_TEXT2}</Typography>
          <Typography p={2} textAlign="center" fontSize={"18px"}>{AppConstants.HOME_PAGE_TEXT3} <b>{AppConstants.HOME_PAGE_TEXT31}</b>{AppConstants.HOME_PAGE_TEXT32}</Typography>
        </Card>
      </MDBox>}
      {!isMobile && <MDBox height={"100%"}>
        <Divider
          orientation="vertical"
          component="div"
          sx={{
            "&.MuiDivider-root": {
              backgroundColor: "#e9d5e9",
              height: "100%",
            },
          }}
        />
      </MDBox>}

      <MDBox m={2} width={isMobile ? "auto": "40%"} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <MDBox maxHeight="200px" maxWidth="200px">
          <img height={"100%"} width={"100%"} src={logo}/>
        </MDBox>
        <MDTypography textAlign="center" variant="h4" fontWeight="medium" color="black" mb={3}>
          Login to your account
        </MDTypography>
        <MDBox onSubmit={loginFormSubmitHandler} width={isMobile ? "auto": "65%"}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="email" label="Email" onChange={(v)=>setEmailValue(v)} fullWidth inputRef={emailRef} />
              <MDTypography variant="overline" fontWeight="medium" color="error" mt={1}>
              {emailValidation}
            </MDTypography>             
            </MDBox>
            <MDBox mb={2}>
              <Tooltip title={'CapsLock On'} open={capsOn} arrow>
              <MDInput 
                type={passVisible ? 'text' : "password"} 
                label="Password" 
                fullWidth 
                onKeyDown={passwordKeyDown}
                inputRef={passwordRef}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setPassVisible(prev => !prev)}
                        edge="end"
                      >
                        {passVisible ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                }}/>
              </Tooltip>
              <MDTypography variant="overline" fontWeight="medium" color="error" mt={1}>
              {passwordValidation}
            </MDTypography>                 
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox display="flex" alignItems="center" justifyContent="center" textAlign="center">
                <MDTypography variant="subtitle2" fontWeight="regular" color="error" mt={1}>
                  {httpErr}
                </MDTypography>                 
            </MDBox>             
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                sign in
              </MDButton>                
            </MDBox>        
            <MDBox display="flex" alignItems="center" justifyContent="center">
                <MDTypography variant="subtitle2" fontWeight="regular" color="error" mt={2}>
                  <Link to="/forgot/password" state={{email:emailValue}}>Forgot Password?</Link>
                </MDTypography>                 
            </MDBox>                     
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient                  
                >
                  <a href="https://bliqmessaging.com/plans" target="_blank">
                    Sign up
                  </a>                  
                </MDTypography> 
              </MDTypography>
            </MDBox> 
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <MDTypography
                component="a"
                target="_blank"
                href="https://www.bliqmsg.com/t&c/Bliq-Messaging"
                variant="button"
                sx={{fontWeight:500}}
                color="info"
                textGradient
              >
                Privacy policy
              </MDTypography>
            </MDBox>                       
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default NormalLogin;
