import { Info } from "@mui/icons-material";
import { Checkbox, FormControlLabel, FormGroup, Tooltip, Typography } from "@mui/material";
import { createCompany } from "api/company";
import { getAppProducts } from "api/product";
import ChooseFileGeneric from "components/CommonUI/ChooseFileGeneric";
import AppConstants from "constants/AppConstants";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import { memo } from "react";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
let companyLogo;
const AddCompanyForm = ({token,onCompanyAdded}) => {

    const {sendRequest , data , status, error} = useHttp(createCompany,false);
    const {sendRequest : getAppProductsFn, status : productStatus, data : productData} = useHttp(getAppProducts,false);
    const nameRef = useRef();
    const contactRef = useRef();
    const dlcRef = useRef();

    const[nameValidation, setNameValidation] = useState('');
    const[dlcValidation, setDlcValidation] = useState('');
    const [contactValidation, setContactValidation] = useState('');
    const [hipaaCompliance, setHipaaCompliance] = useState(false);
    const logoSelected = (file) => {
      companyLogo = file;
    }
    let subscribedFeatures = [];
    const onFormSubmitHandler = (event) => {
      event.preventDefault();
      const name = nameRef.current.value;
      const contact = contactRef.current.value;
      const dlcName = dlcRef.current.value;
      if(name === ''){
        setNameValidation('Please enter a valid name.');
        return;
      }
      else if(dlcName === ''){
        setDlcValidation('Please enter a valid name.');
        return;
      }
      else if(contact == '' || !(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/).test(contact)){
        setContactValidation("Please enter a valid contact number.");
        return;
      }
      else{
        const payload = {
          companyName : name,
          tenDlcName : dlcName,
          companyContact : contact,
          hipaaCompliance : hipaaCompliance,
          products_subscribed : subscribedFeatures
        }
        const d = new FormData();
        d.append("logo",companyLogo);
        d.append("company",JSON.stringify(payload));
        sendRequest({d,token});
      }
    }
    const onProductSubscribed = (subscribed,prod) => {
      if(subscribed){
        subscribedFeatures.push(prod);
      }
      else{
        subscribedFeatures = subscribedFeatures.filter(i => i.product_id !== prod.product_id);
      }
    }
    useEffect(() => {
      getAppProductsFn(token);
    },[])


    useEffect(() => {
      if(status === 'completed'){
        if(error == null){
          toast.success("Company created successfully.");
          onCompanyAdded(data);
        }
        else{
          toast.error(error);
          onCompanyAdded();  
        }
      }
    } , [status,error]);
    
    return (
      <MDBox display="flex" flexDirection="column">
        <MDBox pt={4} pb={3} px={3} onSubmit={onFormSubmitHandler}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                error={nameValidation !== ""}
                type="text"
                label="Name"
                variant="standard"
                inputRef={nameRef}
                fullWidth
              />
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                error={dlcValidation !== ""}
                type="text"
                label="10 DLC Name"
                variant="standard"
                inputRef={dlcRef}
                fullWidth
              />
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                error={contactValidation !== ""}
                type="text"
                label="10 DLC Company Number"
                variant="standard"
                inputRef={contactRef}
                fullWidth
              />
            </MDBox>

            <MDBox mt={1} display="flex" flexDirection="column">
              <MDBox display="flex">
                <Typography variant="subtitle2" fontWeight="400" fontSize={'0.9rem'} color="#7b809a;"> Choose Logo </Typography>
                <Tooltip title={AppConstants.LOGO_PURPOSE_TEXT}>
                  <Info sx={{cursor:'pointer',marginLeft:'8px'}}/>
                </Tooltip>
              </MDBox>
              <ChooseFileGeneric onFileSelected={logoSelected}/>
            </MDBox>

            <MDBox display="flex" flexDirection="column" sx={{
              marginTop:'16px'
            }}>
              <Typography variant="h6">Features you wish to subscribe for</Typography>
                <MDBox>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox checked={hipaaCompliance} onChange={(e) => {setHipaaCompliance(e.target.checked)}} sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}/>} label={"HIPAA Compliance"}/>
                    </FormGroup>
                </MDBox>
              {productStatus === 'completed' && productData?.length > 0 && <FormGroup>
                {productData.map(p => <FormControlLabel key={p.product_id} control={<Checkbox value={p} onChange={(e) => onProductSubscribed(e.target.checked,p)} sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}/>} label={p.name}/>)}
              </FormGroup>}
            </MDBox>

            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                Create
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    );
}
export default memo(AddCompanyForm);