import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import Loader from "ui/Loader";
import style from './Company.module.css';
const ReceiptPDF = ({url}) => {
    return (
        <Document loading={<Loader/>} file={url}>
            <Page className={style['react-pdf__Page__canvas']} height={300} scale={2} pageNumber={1} />
        </Document>
    )
}
export default ReceiptPDF;