import React, { useCallback } from "react";
import { useEffect, useState} from "react";
import MDBox from "lib/components/MDBox";
import {Button, Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Tooltip, Typography} from "@mui/material";
import MessageTable from "./MessageTable";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Info } from "@mui/icons-material";
import { NOTIFICATION_MEDIUM } from "constants/AppConstants";
import AppConstants from "constants/AppConstants";
import CreateCampaignMessage from "./CreateCampaignMessage";
import { CrowdfundingAPI } from "services/Crowdfunding/CrowdfundingAPI";
import Swal from "sweetalert2";
import { loaderAction } from "store/loader-slice";

export default function SettingTable({campaign}) {

  const dispatch = useDispatch();

  const [settings, setSettings] = useState({});
  const [messages, setMessages] = useState([]);
  
  const [showSaveBtn,setShowSaveBtn] = useState(false);

  const token = useSelector(state => state.user.token);

  const onNoticationOptionChange = (e) => {
    if(e.target.checked){
      setSettings(prev => {
        return {
          ...prev,
          notification_medium : NOTIFICATION_MEDIUM.SMS
        }
      })
    }
    else{
      setSettings(prev => {
        return {
          ...prev,
          notification_medium : NOTIFICATION_MEDIUM.NONE
        }
      })
    }
    setShowSaveBtn(true);
  }

  const onMediumSelected = (e) => {
    setSettings(prev => {
      return {
        ...prev,
        notification_medium : e.target.value
      }
    });
    setShowSaveBtn(true);
  }

  const updateSettings = useCallback(() => {
    const payload = {
      settings : {...settings},
      campaign_id : campaign?.campaign_id
    }
    dispatch(loaderAction.show());
    CrowdfundingAPI.updateCampaignSetting({payload}).then(res => {
      setSettings(res.data?.settings);
      dispatch(loaderAction.hide());
      setShowSaveBtn(false);
    }).catch(e => {
      console.log(e);
      dispatch(loaderAction.hide());
    })
  },[settings,campaign])

  const onUIAccessCheck = useCallback((e) => {
    const isBulkUIAccessPossible = campaign?.fundraisers?.find(item => item?.fundraiser_email === null || item?.fundraiser_email?.trim().length === 0);
    if(isBulkUIAccessPossible){
      toast.error("Bulk Access is not possible as all fundraisers does not have valid email address.");
      return;
    }
    else{
      setSettings(prev => {
        return {
          ...prev,
          ui_access : e.target.checked
        }
      });
      CrowdfundingAPI.giveUIAccessBulk({token, uiAccess: e.target.checked,campaign_id : campaign?.campaign_id}).then(res => {
        const accessGivenTo = res.data;
        if(accessGivenTo.status === 'success'){
          let success = ``;
          let failed = ``;
          for(const a in accessGivenTo['access_given']) {
            if(a.status === 'success'){
              success += `<p>${a} : ${accessGivenTo['access_given'][a]["message"]}</p>`
            }
            else{
              failed += `<p>${a} : ${accessGivenTo['access_given'][a]["message"]}</p>`
            }
          }
          Swal.fire({
            title : "UI access report",
            html : success + '<b>' + failed
          })
        }
        else{
          toast.error("UI access not given.");
          setSettings((prev) => {
            return {
              ...prev,
              ui_access: false,
            };
          });
        }
      }).catch(e => {
        setSettings((prev) => {
          return {
            ...prev,
            ui_access: false,
          };
        });
        toast.error(AppConstants.REQUEST_FAIL);
      })
    }
  },[campaign])
  
  useEffect(() => {
    if(campaign?.settings){
      const s = Boolean(campaign.settings) ? campaign.settings : {
        campaign_id : campaign.campaign_id,
        settings : {
          ui_access: false, 
        }
      };
      setSettings(s);
    }
    if(campaign?.messages){
      setMessages(campaign.messages);
    }
  },[campaign]);

  return (
    <>
      <div
        style={{
          display: "flex",
        }}
      >
        <input
          value={Boolean(settings?.ui_access)}
          checked={Boolean(settings?.ui_access)}
          onChange={onUIAccessCheck}
          style={{
            height: "24px",
            width: "24px",
            marginRight: "20px",
            borderRadius: "15px",
            marginBottom: "25px",
          }}
          type="checkbox"
          id="UI"
        />
        <label
          style={{
            fontSize: "20px",
          }}
          htmlFor="UI"
        >
          <Typography variant="subtitle1"> UI Access{" "}
            <Tooltip title={"UI access will enable the fundraiser to login."}>
              <Info sx={{cursor:'pointer'}}/>
            </Tooltip>
          </Typography>
        </label>
        <br></br>
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <input
          checked={
            Boolean(settings?.notification_medium) && settings?.notification_medium !== NOTIFICATION_MEDIUM.NONE
          }
          value={settings?.notification_medium}
          onChange={onNoticationOptionChange}
          style={{
            height: "24px",
            width: "24px",
            marginRight: "20px",
            borderRadius: "15px",
            marginBottom: "25px",
          }}
          type="checkbox"
          id="fund"
        />
        <label style={{ fontSize: "20px" }} htmlFor="fund">
        <Typography variant="subtitle1">{" "}
          Set the default notification type here. You can customize each fundraiser’s notifications under his settings.
        </Typography>
        </label>
        <br></br>
      </div>
      {(Boolean(settings?.notification_medium) && settings?.notification_medium !== NOTIFICATION_MEDIUM.NONE) && (
        <MDBox display="flex" flexDirection="column">
          <MDBox display="flex">
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Notification Type
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={settings?.notification_medium}
                onChange={onMediumSelected}
              >
                <FormControlLabel value="SMS" control={<Radio />} label="SMS" />
                <FormControlLabel value="EMAIL" control={<Radio />} label="EMAIL"/>
                <FormControlLabel value="ALL" control={<Radio />} label="BOTH" />
              </RadioGroup>
            </FormControl>
          </MDBox>
        </MDBox>
      )}
      {showSaveBtn && <MDBox display="flex" justifyContent="flex-start">
        <Button
          onClick={updateSettings}
          variant="contained"
          sx={{
            borderRadius: "0px",
            color: "white!important",
            border: "unset",
            marginLeft: "12px",
            height: "32px",
            minHeight: "unset",
          }}                
        >{AppConstants.SAVE_BTN}</Button>
      </MDBox>}
      <Divider
        component="div"
        sx={{
          "&.MuiDivider-root": {
            backgroundColor: "#e9d5e9",
          },
        }}
      />
      <div
        style={{
          marginTop: "20px",
          marginLeft: "16px",
        }}
      >
        <MDBox display="flex" justifyContent="flex-end" alignItems="center">
          <CreateCampaignMessage
            campaignId={campaign?.campaign_id}
            onMessageCreated={(res) => {
              setMessages((prev) => [res, ...prev]);
            }}
          />
        </MDBox>
      </div>
      <MessageTable messages={messages} campaignId={campaign?.campaign_id} />
    </>
  );
}
