import * as React from 'react';
import Drawer from '@mui/material/Drawer';

const AppDrawer = ({children,openDrawer,placement,style,onClose}) => {

  const [open, setOpen] = React.useState(openDrawer);

  const toggleDrawer = (val) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(val);
    onClose();
  };

  React.useEffect(() => {
    setOpen(openDrawer)
  },[openDrawer])
  
  return (
      <Drawer
        anchor={placement ? placement : 'right'}
        open={Boolean(open)}                                    
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx:{...style,"&.MuiDrawer-paper": {
            backgroundColor :  "#f0f2f5 !important"                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
          }}
        }}
      >
        {typeof openDrawer === 'boolean' ? children : openDrawer}
      </Drawer>
  );
}
export default AppDrawer;