import { useDispatch, useSelector } from "react-redux";
import { ActionSnackbarStyles } from "./ActionSnackbarStyles";
import { appAction } from "store/app-slice";

const ActionSnackbar = () => {

    const dispatch = useDispatch();
    const snackBarMessage = useSelector(state => state.app.snackBarMessage);
    const openSnackBar = useSelector(state => state.app.openSnackBar);

    return(
        <ActionSnackbarStyles
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
            open={openSnackBar}
            message={snackBarMessage}
            onClose={() => dispatch(appAction.setCloseSnackBar())}
            autoHideDuration={5000}
        />
    )
}
export default ActionSnackbar;