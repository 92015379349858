import { Card, Icon } from "@mui/material";
import { getBonus } from "api/bonus";
import { AddBonus } from "api/bonus";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import TimelineItem from "lib/layouts/Timeline/TimelineItem";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import AddButton from "ui/AddButton";
import AppModal from "ui/AppModal";
import { getProperDate } from "util/UtilityFunctions";
const GoalTimeline = ({campaign}) => {

    const [addBonus, setAddBonus] = useState(false);
    const [amount, setAmount] = useState('');
    const token = useSelector(state => state.user.token);
    const [bonuses,setBonuses] = useState([]);
    const {sendRequest : addBonusFn, status : bonusAddedStatus, data : bonusAdded} = useHttp(AddBonus,false);
    const {sendRequest : fetchBonusFn, status , data, error} = useHttp(getBonus,false);
    const onModalClose = () => {
        setAddBonus(false);
    }

    const onBonusAmountChange = (e) => {
        setAmount(e.target.value);
    }

    const onBonusAdded = () => {
        const payload = {
            bonus_amount : amount,
            campaign : {
                campaign_id : campaign?.campaign_id
            }
        }
        addBonusFn({payload,token})
    }

    useEffect(() => {
        if(campaign){
            fetchBonusFn({campaign_id : campaign.campaign_id,token});
        }
    },[campaign]);

    useEffect(() => {
        if(bonusAddedStatus === 'completed'){
            if(bonusAdded){
                toast.success("Bonus amount added successfully.");
                setBonuses(prev => [...prev,bonusAdded]);
                setAddBonus(false);
            }
        }
    },[bonusAddedStatus,bonusAdded])
    useEffect(() => {
        if(status === 'completed'){
            if(data){
                setBonuses(data);
            }
        }
    },[status,data])
    return (
      <>
        <Card sx={{ height: "100%" }}>
          <MDBox display="flex" justifyContent="flex-end">
            <AddButton
              onClick={() => {
                setAddBonus(true);
              }}
              text={"Add bonus"}
              customStyle={{
                marginBottom: "12px!important",
              }}
            />
          </MDBox>

          <MDBox pt={3} px={3}>
            <MDTypography variant="h6" fontWeight="medium">
              Goal overview
            </MDTypography>
            {/* <MDBox mt={0} mb={2}>
                    <MDTypography variant="button" color="text" fontWeight="regular">
                    <MDTypography
                        display="inline"
                        variant="body2"
                        verticalAlign="middle"
                    >
                        <Icon
                        sx={{ color: ({ palette: { success } }) => success.main }}
                        >
                        arrow_upward
                        </Icon>
                    </MDTypography>
                    &nbsp;
                    <MDTypography variant="button" color="text" fontWeight="medium">
                        24%
                    </MDTypography>{" "}
                    this month
                    </MDTypography>
                </MDBox> */}
          </MDBox>
          <MDBox p={2}>
            <TimelineItem
              color="success"
              icon="done"
              title={`$${campaign?.goal_amount}, Initial goal`}
              dateTime={getProperDate(campaign?.createdAt)}
            />
            {
                bonuses?.length > 0 && bonuses?.map((b,indx) => {
                    if(indx === bonuses.length - 1){
                        return  <TimelineItem
                        color="primary"
                        key={b.id}
                        icon="attach_money"
                        title={`$${b.bonus_amount}, Bonus added`}
                        dateTime={getProperDate(b?.createdAt)}
                        lastItem
                      />
                    }
                    else{
                        return <TimelineItem
                            color="primary"
                            icon="attach_money"
                            key={b.id}
                            title={`$${b.bonus_amount}, Bonus goal`}
                            dateTime={getProperDate(b?.createdAt)}
                        />
                    }
                })
            }
          </MDBox>
        </Card>
        {addBonus && (
          <AppModal onModalClose={onModalClose} heading={"How much would you like to add to the existing goal?"}>
            <MDBox mt={1}>
                <MDBox margin={"8px"} padding={"12px 0px 0px 0px"}>
                    <MDInput
                        sx={{ marginRight: "8px" }}
                        fullWidth
                        onChange={onBonusAmountChange}
                        type="number"
                        label="Bonus amount"
                        value={amount}
                    />
                </MDBox>
                {amount && 
                    <MDBox margin={"12px"}>
                        <MDButton
                            onClick={onBonusAdded}
                            variant="gradient"
                            color="info"
                        >
                            Add
                        </MDButton>
                    </MDBox>
                }
            </MDBox>

          </AppModal>
        )}
      </>
    );
}
export default GoalTimeline;