import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

const AppAlert = ({onAllowed}) => {

const [open, setOpen] = React.useState(true);

return (
    <Box sx={{ width: "100%", height:'auto',maxHeight:'44px',position:'absolute',zIndex: 1000 }} id="global-alert">
      <Collapse in={open}>
        <Alert
          severity="warning"
          onClose={() => {}}
          action={
            <>
                <Button color="inherit" size="small" onClick={() => {
                    setOpen(false);
                    onAllowed()
                }}>
                    ALLOW
                </Button>
                <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                setOpen(false);
                }}>
                <CloseIcon fontSize="inherit" />
                </IconButton>
            </>
          }
        >
          {'We noticed that Desktop Notifications permissions are not enabled. Please allow browser permissions to receive notifications.'}
        </Alert>
      </Collapse>
    </Box>
  );
};
export default AppAlert;
