import { useState } from "react";
import { useSelector } from "react-redux";
import AdminShortcuts from "./AdminShortcuts";
import MyShortcuts from "./MyShorcuts";
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import AppConstants from "constants/AppConstants";

const AllShortcuts = ( ) => {
    const user = useSelector(state => state.user);
    const [companyShorcutCreated,setCompanyShorcutCreated] = useState();
    return (
        <>
            {
                user.company != null && user.company ? 
                <>
                    <MyShortcuts currentUser={user} onCompanyShorcutCreated = { (res) => setCompanyShorcutCreated(res)}/>
                    <AdminShortcuts currentUser={user} companyShorcutCreated={companyShorcutCreated}/>
                </>
                :
                <>
                    <MDBox display="flex" justifyContent="center" alignItems="center">
                        <MDTypography color={"info"}>
                        {AppConstants.ACTION_NOT_ALLOWED}
                        </MDTypography>
                    </MDBox>
                </>
            }
        </>
    )
}
export default AllShortcuts;