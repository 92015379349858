import { Typography } from "@mui/material";
import { getMessagePendingsRecipient } from "api/group";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const MessageDeliveryPending = ({messageId}) => {

    const {sendRequest, data} = useHttp(getMessagePendingsRecipient,false);
    const token = useSelector(state => state.user.token);

    useEffect(() => {
        if(messageId){
            sendRequest({messageId,token});
        }
    },[messageId,token,sendRequest])
    return (
      <MDBox display="flex" flexDirection="column">
        <MDBox display="flex" flexDirection="column">
          <MDBox display="flex" m={3} justifyContent="start" width="100%">
            <Typography variant="h6" sx={{ fontSize: "16px" }}>
              List of recipients outside of allowed hours. Message will be deliver in their timezone at 8:00 am.
            </Typography>
          </MDBox>
          {data?.map((r, i) => (
            <MDBox ml={3}>
                <Typography>{r.recipientPhone}</Typography>
            </MDBox>
          ))}
        </MDBox>
      </MDBox>
    );
}
export default MessageDeliveryPending;