import { BASE_URL } from "constants/Endpoints";
import fetch from "core/fetch";

export const setPrefereNotificationSound = async({payload,token,userId}) => {
    const response = await fetch(`${BASE_URL}/user/settings/${userId}/notification/tele/sound`,{
        method : 'POST',
        body : JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}