import IChatFooter from "./IChatFooter";

const ReplyWrapper = ({activeChat,onReplySend,messageState,setMessageState}) => {

    const replyHandle = (payload) => {
        onReplySend(payload);
    }

    return(
        <IChatFooter messageState={messageState} setMessageState={setMessageState} onMessageSend={replyHandle} onTyping={() => {}} activeChat={activeChat}/>
    )
}
export default ReplyWrapper;