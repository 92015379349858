import { API_V1_SMS } from "constants/Endpoints";
import { BASE_URL } from "constants/Endpoints";
import axios from "core/axios"

export const BapiAPI = {

    getBapiListForCompany : async ({companyId,token}) => {
        return await axios.get(`${BASE_URL}${API_V1_SMS}/phone/api/${companyId}`,{
            headers : {
                'Authorization': 'Bearer ' + token,
            }
        });
    },

    createPhoneBapi : async ({payload,token}) => {
        return await axios.post(`${BASE_URL}${API_V1_SMS}/phone/api`,JSON.stringify(payload),{
            headers : {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            }
        });
    },
    revokeApi : async({id,token}) => {
        return await axios.delete(`${BASE_URL}${API_V1_SMS}/phone/api/${id}`,{
            headers : {
                'Authorization': 'Bearer ' + token,
            }
        });
    }
}