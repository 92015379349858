import { Button, Checkbox, Tooltip, Typography } from "@mui/material";
import { LeftMessageBubble } from "pages/Chat/ChatStyles";
import { Message } from "pages/Chat/ChatStyles";
import { DateTimeInfo } from "pages/Chat/ChatStyles";
import { FlexContainer } from "pages/Chat/ChatStyles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReceivedBy from "./ReceivedBy";
import parse from 'html-react-parser';

import { searchTextFormated ,searchTextByTagFormated, searchTextByUserFormated} from "util/UtilityFunctions";
import Tags from "components/Company/Tags";
import ShowTag from "./ShowTag";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MessageMenu from "components/Message/MessageMenu";
import { WhatsApp } from "@mui/icons-material";
import { LMessageText,MessageLinkText,UrlLinK } from "pages/Chat/ChatStyles";
import { ChatAPI } from "services/Chat/ChatAPI";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReadBy from "./ReadBy";
import { APP_COLOR } from "constants/Color";
import MediaMessage from "components/CommonUI/MediaMessage";
import { useLongPress } from 'use-long-press';
import useOuterClick from "hooks/use-outer-click";
import useWAT from "hooks/use-wat";
import AppConstants from "constants/AppConstants";
import MessageUrlLink from './Util/MessageUrlLink';
import MDBox from "lib/components/MDBox";
import { ConversationAPI } from "services/ConversationAPI";
const SingleLeftMessage = ({
  message,
  checkboxSelected,
  check,
  searchActive,
  onEventPerform,
  showReadBy
}) => {

    const rightNavChecked = useSelector(state => state.chatVariables.rightNavChecked);

    const [checkForWAT] = useWAT()
    const [showTag, setShowTag] = useState(false);
    const [isPressMoving, setIsPressMoving] = useState(false);
    const [currMessage, setCurrMessage] = useState(message);
    const [checked,setChecked] = useState(false);
    const currRef = useRef('mesaage'+message.id)
    const bubbleRef = useOuterClick('mesaage_bubble'+message.id,showTag,() => {setShowTag(false)});
    const [openMessageMenu, setOpenMessageMenu] = useState(null);
    const [parentMessage,setParentMessage] = useState(undefined);
    const [isHovered, setIsHovered] = useState(false);
    const token = useSelector(state => state.user.token);
    const callback = useCallback(event => {
      if(!openMessageMenu && !isPressMoving){
        if(event.target.className.includes('MuiBackdrop-root')){
          setOpenMessageMenu(null)
        }else{
          setOpenMessageMenu(event.target)
        }
      }
      if(isPressMoving){
        setOpenMessageMenu(null)
      }
    }, []);
    const OnLongPress = useLongPress(callback, {
      onStart: event => {setIsPressMoving(false)},
      onMove: event =>{setIsPressMoving(true)},
      onFinish: event => {setIsPressMoving(false)},
      filterEvents: event => true, // All events can potentially trigger long press (same as 'undefined')
      threshold: 500, // In milliseconds
      captureEvent: true, // Event won't get cleared after React finish processing it
      cancelOnMovement: 25, // Square side size (in pixels) inside which movement won't cancel long press
      cancelOutsideElement: true, // Cancel long press when moved mouse / pointer outside element while pressing
      detect: 'pointer', // Default option
    });
    const onCheckHandler = (event) => {
      setChecked(event.target.checked);
      onEventPerform("CHECKBOX",{checked : event.target.checked,id: message.id});
    }
    const onTagChange = (data) => {
      setShowTag(false);
    }
    const onEventPerformInMessageMenu = useCallback((action,data) => {
      if(action === 'OPEN_TAG'){
        setShowTag(true);
        setOpenMessageMenu(null);
      }
      if(action === 'POPOVER_CLOSED'){
        setOpenMessageMenu(null);
      }
      if(action === 'MARK_MESSAGE_UNREAD') {
        setOpenMessageMenu(null);
        const payload = {
          message_id : message.id,
          phone_id : message.sendByPhone.phoneId,
          recipient_id : message.sendTo.id,
          phone : message.sendByPhone.phone
        }
        ChatAPI.markParticularMessageAsUnread({payload,token}).then(res => {
          toast.success("Marked as unread");
        }).catch(e => toast.error(e));
      }    
      if(action === 'SHARE_MESSAGE_LINK'){
        setOpenMessageMenu(null);
      }  
    },[message]);

    useEffect(() => {
      if(message && message.parentWamid){
        ConversationAPI.getWaParentMessage({token,wamid : message.parentWamid}).then(res => {
          setParentMessage(res.data);
        }).catch(e => {console.log(e)})
      }
    },[message])
    /// select check box
    useEffect(()=> {
      setChecked(check || rightNavChecked);
    },[checkboxSelected,check,rightNavChecked])

    useEffect(() => {
      if(searchActive.search){
        let formated;
        if(searchActive.criterion === 'NORMAL_SEARCH')
          formated = searchTextFormated(message.message,searchActive.pattern);
        else if(searchActive.criterion === 'SEARCH_BY_TAG'){
          formated = searchTextByTagFormated(message,searchActive.pattern);
        }
        else if(searchActive.criterion === 'SEARCH_BY_USER'){
          formated = searchTextByUserFormated(message,searchActive.pattern);
        }

        if(formated === ''){
          setCurrMessage(undefined);
        }
        else{
            setCurrMessage(prev => {
            const val = {
              ...prev, message:formated
            }
            return val;
          });
          }
      }
      else
        setCurrMessage(message);
    },[message,searchActive]) 

    useEffect(() => {
      if(!openMessageMenu){
        setIsHovered(false);
      }
    },[openMessageMenu])  
    let _longPress = {}  
    if(window.innerWidth < 700){
      _longPress = OnLongPress()
    }
    return(
      <>
        {currMessage ? 
        <FlexContainer
          ref={currRef}
          alignItems="center"
          justifyContent="flex-start"
          height="auto"
          id={"message_" + currMessage?.id}
          data-message-id={currMessage?.id}
          spacing="10px"
          marginBottom = "10px"
          {..._longPress}
        >
        { (checkboxSelected || rightNavChecked) && <div>
          <Checkbox className="custom-checkbox" checked={checked} onChange={onCheckHandler}/>
        </div>}
        <div key={'msg_div' + currMessage?.id} style={{marginTop:'8px',width:"100%"}} >
          <DateTimeInfo className="left-date-info">
            <ReceivedBy key={currMessage?.createdAt} recipientphone={currMessage.sendTo?.phone} countryCode={currMessage.sendTo?.countryCode ? currMessage.sendTo?.countryCode : "1"} date={currMessage.createdAt}/>
          </DateTimeInfo>
          <Message className="left-msg">
            {currMessage?.type === 'WHATSAPP' && 
              <Tooltip title={`received via WhatsApp`}>
                <FlexContainer
                  style={{
                    marginLeft: "4px",
                    fontSize: "14px",
                    color: "green",
                    cursor: "pointer",
                  }}
                >
                  <WhatsApp size="small" />
                </FlexContainer>
              </Tooltip>                
            }
            <LeftMessageBubble 
              ref={bubbleRef}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => {
                if(!openMessageMenu){
                  setIsHovered(false)
                }
              }}
              chatImage={false}
            >
            {
              (currMessage?.messageAttchement || (currMessage?.media && currMessage.media.length > 0)) &&
              <MediaMessage message={currMessage}/>
            }
            {isHovered && <><MoreVertIcon key={currMessage?.id} style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: 'pointer',
                    color:'#000',
                    marginTop:'3px',
                    height:'18px',
                    width:'18px',
                    background:'#ffffffde',
                    borderRadius:'50%',
                    paddingRigth:'2px',
                    boxShadow: '0px 1px 2px 2px #ffffffde'
                  }}
                  onClick={(e) => setOpenMessageMenu(e.currentTarget)}
              />
              </>
            }     
            <LMessageText>
              {parentMessage && <MDBox sx={{padding:'4px',borderRadius:'5%',background:'#1A73E8',marginBottom:'2px'}}>
                <Typography fontSize={"12px"} color={"white!important"}>{parentMessage?.message}</Typography>
              </MDBox>}
              <MessageLinkText>
                <UrlLinK component={MessageUrlLink} regex={AppConstants.URL_REGEX}>{Boolean(currMessage?.message) && parse(checkForWAT(message))}</UrlLinK>
              </MessageLinkText>
            </LMessageText>
              {currMessage.importId && currMessage.importId !== 0 && <span style={{fontSize:'12px',color:APP_COLOR.SECONDARY_BUTTON,fontStyle:'italic'}}>{"Imported message"}</span>}         
              <Tags message={currMessage} onTagChange={onTagChange} onClose={() => {setShowTag(false);}} showTagInput={showTag}/>
                {!showTag && <ShowTag tags={currMessage?.tags}/>}   
                {openMessageMenu && <MessageMenu message={message} messageId={currMessage?.id} onEventPerform={onEventPerformInMessageMenu} targetEle={openMessageMenu}/>}                         
            </LeftMessageBubble>
          </Message>
          {showReadBy && currMessage.readByUser && <ReadBy name={currMessage?.readByUser?.fullName} image={currMessage?.readByUser?.profilePic} date={currMessage?.seenAt}/>}
        </div>
        </FlexContainer>
        :
        <></>
        }
      </>
    )
} 
export default React.memo(SingleLeftMessage);