import { Send, Undo } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MessageExpireTime from "./MessageExpireTime";
import { useDispatch, useSelector } from "react-redux";
import { timeoutAction } from "store/timeout-slice";
import { memo, useCallback, useEffect } from "react";
import { useState } from "react";
import { messageAction } from "store/message-slice";
import { TELECOM_CONSTANTS } from "constants/ChatConstants";

const MessageActions = ({message,setMessageState}) => {
    const dispatch = useDispatch();
    const time = useSelector(state => state.userDetails.details?.userSettings?.teleUserSettings?.messageDelayTime);
    const timeout = useSelector((state) => state.timeout);
    const decreaseVal = time ? Math.round(100/time) : 5;
    const [progress, setProgress] = useState(100);
    const [currentTime,setCurrentTime] = useState(time ? time : 5);

    const onUndo = useCallback(() => {
        // Clear the timer....
        const messageId = message.tempId;
        const rId = message.sendTo.id;
        dispatch(messageAction.removeConversation({message : message, recipientId : rId}));
        const msgObj = timeout[TELECOM_CONSTANTS.pendingRecipientMsgs]?.[rId]?.[messageId];
        if(msgObj){
            console.log(message)
            clearTimeout(msgObj.id);
            dispatch(timeoutAction.removeTimer({recipientId : rId, messageId}));
            setMessageState(prev => {
                return{
                    ...prev,
                    message : message?.message,
                    // attachement : message?.messageAttchement
                }
            })
        }
    },[message,timeout,dispatch])

    const sendNow = useCallback(() => {
        console.log("clicked send now...");
        const messageId = message.tempId;
        const rId = message.sendTo.id;
        const msgObj = timeout[TELECOM_CONSTANTS.pendingRecipientMsgs][rId][messageId];
        if(msgObj){
            clearTimeout(msgObj.id);
            dispatch(timeoutAction.removeTimer({recipientId : rId, messageId}));
            const newPayload = {...message, status : 'sending'};
            // update the tatus of the message 
            dispatch(messageAction.updateMessageStatusToSent(newPayload));
            msgObj.trigger();
        }
    },[message,timeout,dispatch])

    useEffect(() => {
      if(progress <= 0){
        try{
            clearInterval(timer);
        }catch{}
      }
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress - decreaseVal));
        setCurrentTime(prev => prev - 1);
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }, [progress,setCurrentTime,setProgress]);

    return(
        <MDBox key={message?.tempId} width="100%" display="flex" justifyContent="flex-end">
            {currentTime > 0 && <MDBox sx={{marginRight:'8px'}} display="flex" alignItems="center" justifyContent="flex-end">
                <MDBox key={message?.tempId}>
                    {/**
                     * 5 secs => 100% decrease value = 20
                     * 10 secs => 100% decrease value = 10
                     * decrease * time == 100
                     * decrease = 100/time
                     */}
                    <MessageExpireTime key={message?.tempId}  currenttime={currentTime} progress={progress}/>
                </MDBox>
                <MDBox display="flex" justifyContent="center" alignItems="center">
                    <MDBox onClick={sendNow} sx={{marginRight:"6px"}}>
                        <Tooltip title="Send message now.">
                            <Send sx={{cursor:'pointer',width:'18px',height:'18px'}}/>
                        </Tooltip>
                    </MDBox>
                    <MDBox onClick={onUndo}>
                        <Tooltip title="Cancel message.">
                            <Undo sx={{cursor:'pointer',width:'18px',height:'18px'}}/>
                        </Tooltip>
                    </MDBox>
                </MDBox>
            </MDBox>}
        </MDBox>
    )
}
export default memo(MessageActions);