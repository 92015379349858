import { Button, TextField } from "@mui/material";
import Accordian from "components/util/Accordian";
import MDBox from "lib/components/MDBox";
import { useCallback, useState } from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { UserSettingsAPI } from "services/UserSettingsAPI";
import { userDetailAction } from "store/user-details-slice";

const MySignature = () => {

   const dispatch = useDispatch();
   const userId = useSelector(state => state.user.id);
   const token = useSelector(state => state.user.token);
   const initialSignature = useSelector(state => state.userDetails.details.userSettings);

   const [updateBtn,setUpdateBtn] = useState(false);

   const [signature,setSignature] = useState({
        waSignature : initialSignature? initialSignature.waSignature : '',
        teleSignature: initialSignature? initialSignature.teleSignature : '',
        icSignature: initialSignature? initialSignature.icSignature : ''
    });

    const onTeleChange = (e) => {
        if(!updateBtn) {
            setUpdateBtn(true);
        }
        setSignature(prev => {
            return{
                ...prev,
                teleSignature : e.target.value
            }
        })
    }

    const onWaChange = (e) => {
        if(!updateBtn) {
            setUpdateBtn(true);
        }
        setSignature(prev => {
            return{
                ...prev,
                waSignature : e
            }
        })
    }

    const onIcChange = (e) => {
        if(!updateBtn) {
            setUpdateBtn(true);
        }
        setSignature(prev => {
            return{
                ...prev,
                icSignature : e
            }
        })
    }

    const onSave = useCallback(() => {
        UserSettingsAPI.setSignatures({
            payload : signature,
            userId,
            token
        }).then(res => {
            toast.success("Signature updated.");
            dispatch(userDetailAction.updateSignature(signature));
            setUpdateBtn(false);
        }).catch(e => console.log(e));
    },[signature]);

    return(
        <>
            <MDBox width="100%" m={2}>
                <Accordian heading={"SMS"}>
                    <MDBox width="100%" height="100%">
                        <TextField sx={{width:"100%"}} fullWidth multiline variant="outlined" value={signature.teleSignature} onChange={onTeleChange} />
                    </MDBox>
                    {updateBtn && <MDBox display="flex" justifyContent="flex-end" mt={2}>
                        <Button
                            onClick={onSave}
                            variant="contained"
                            sx= {{
                                borderRadius: "0px",
                                color: "white!important",
                                border: "unset",
                                marginLeft: "12px",
                                height: "32px",
                                minHeight: "unset",
                            }}                        
                        >
                            save
                        </Button>
                    </MDBox>}
                </Accordian>
            </MDBox>

            <MDBox width="100%" m={2}>
                <Accordian heading={"Whatsapp"}>
                    <MDBox width="100%" height="100%">
                        <ReactQuill theme="snow" value={signature.waSignature} onChange={onWaChange} />
                        <MDBox display="flex" justifyContent="flex-end" mt={2}>
                            <Button
                                onClick={onSave}
                                variant="contained"
                                sx= {{
                                    borderRadius: "0px",
                                    color: "white!important",
                                    border: "unset",
                                    marginLeft: "12px",
                                    height: "32px",
                                    minHeight: "unset",
                                }}                        
                            >
                                save
                            </Button>                        
                        </MDBox>
                    </MDBox>
                </Accordian>
            </MDBox>

            <MDBox width="100%" m={2}>
                <Accordian heading={"Internal chat"}>
                    <MDBox width="100%" height="100%">
                        <ReactQuill theme="snow" value={signature.icSignature} onChange={onIcChange} />
                        <MDBox display="flex" justifyContent="flex-end" mt={2}>
                            <Button
                                onClick={onSave}
                                variant="contained"
                                sx= {{
                                    borderRadius: "0px",
                                    color: "white!important",
                                    border: "unset",
                                    marginLeft: "12px",
                                    height: "32px",
                                    minHeight: "unset",
                                }}                        
                            >
                                save
                            </Button>                        
                        </MDBox>
                    </MDBox>
                </Accordian>

            </MDBox>
        </>
    )
}
export default MySignature;