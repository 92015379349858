import { MessageChat } from "pages/Chat/ChatStyles";
import { MessageWrapper } from "pages/Chat/ChatStyles";
import { MainChatPanel } from "pages/Chat/ChatStyles";
import IMessage from "./NChat/IMessage";
import OMessage from "./NChat/OMessage";
import MDBox from "lib/components/MDBox";
import Moment from "react-moment";
import NoContent from "components/Chat/NoContent";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { groupMessagesByDate } from "util/IChat/IMessageUtil";
import useIscrollToBottom from "hooks/IChat/use-iscroll-to-bottom";
import IThread from "./IThread";
import React from "react";
import { ichatVariablesAction } from "store/IChat/Ichat-variables-slice";
import AppConstants from "constants/AppConstants";
import { CALENDAR_STRING_IC } from "constants/ChatConstants";

const IConversation = ({setOpenSide}) => {

    const timezone = useSelector(state => state.userDetails?.details?.userSettings?.timezone)

    const dispatch = useDispatch();

    const currentUserId = useSelector(state => state.user.id);

    const currentActiveChat = useSelector(state => state.Ichat.currentActiveChat);
    const messages = useSelector(state => state.Ichat.conversation[currentActiveChat?.chatId]);

    const [currentMsgs,setCurrentMsgs] = useState([]);
    const [lastReadMessage,setLastReadMessage] = useState();
    
    const scrollRef = useIscrollToBottom(currentMsgs,currentActiveChat.chatId,currentActiveChat.groupChat,lastReadMessage);

    const onRepliesClick = (message) => {
        const sideBarContent = {
            open: true,
            content : <IThread key={message.messageId} message={message}/>
        };
        dispatch(ichatVariablesAction.updateRightSideBar(sideBarContent));
    }

    useEffect(() => {
        if(currentActiveChat && messages){
            const lastRead = messages.find(itm => (itm.status === 'delivered' && itm.senderId !== currentUserId));
            const tmpMsgs = groupMessagesByDate(messages);
            setLastReadMessage(lastRead?.messageId);
            setCurrentMsgs(tmpMsgs);
        }
    },[currentActiveChat,messages]);
    

    return(
        <>
            <MainChatPanel ref={scrollRef} style={{width:'100%',borderRight : "1px solid rgb(221, 221, 221)"}}>
                {messages?.length > 0 ? <MessageWrapper>
                    <MessageChat>
                        {Object.entries(currentMsgs).map(([date, messagesInGroup]) => {
                            return <MDBox key = {date}>
                                <MDBox key={date} sx={{fontSize : '14px'}} display="flex" justifyContent="center">
                                    <Moment tz={timezone} key={date} calendar={CALENDAR_STRING_IC}>{date}</Moment>
                                </MDBox> 
                            {messagesInGroup.map((message,index) => {
                        
                                if(message.senderId === currentUserId){
                                    return <OMessage onRepliesClick={onRepliesClick}  key={"omessage_" + message.messageId} message={message}/>
                                }
                                else return <IMessage onRepliesClick={onRepliesClick} key={"imessage_" + message.messageId} message={message}/>
                                
                            })} 
                            </MDBox>
                        })}
                    </MessageChat>
                
                </MessageWrapper> : 
                    <MDBox display="flex" justifyContent="center" alignItems="center" height="100%" width="100%">
                        <NoContent style={{height:'100%'}} heading={'h6'} message={AppConstants.START_CHAT}></NoContent>
                    </MDBox>
                }
            </MainChatPanel>
            {/* <MDBox width={"300px"}></MDBox> */}
        </>
    )
}
export default React.memo(IConversation);