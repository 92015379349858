/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import { Divider } from "@mui/material";

// Material Dashboard 2 React components


function History({ dateFrom, dateTo, id, price, noGutter }) {
  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      pr={1}
      mb={noGutter ? 0 : 1}
    >
      <MDBox lineHeight={1.125} width="100%">
        <MDBox display="flex" justifyContent="space-between">
          <MDTypography display="block" variant="button" fontWeight="medium">
            {dateFrom}
          </MDTypography>
          <Divider
            component="div"
            sx={{
              "&.MuiDivider-root": {
                backgroundColor: "#e9d5e9",
                width: "40%"
              },
            }}
            textAlign="center"
          ><div>TO</div></Divider>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {dateFrom}
          </MDTypography>
        </MDBox>
        <MDTypography variant="caption" fontWeight="regular" color="text">
          {id}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of History
History.defaultProps = {
  noGutter: false,
};

// Typechecking props for the History
History.propTypes = {
  date: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default History;
