import { useCallback } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChatAPI } from "services/IChat/ChatAPI";
import { MessageAPI } from "services/IChat/MessageAPI";
import { IChatAction } from "store/IChat/Ichat-slice";
import { formatChat } from "util/IChat/IChatUtil";

const useIchat = () => {

    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const currentActiveChat = useSelector(state => state.Ichat.currentActiveChat);
    const messages = useSelector(state => state.Ichat.conversation);

    useEffect(() => {
        console.log(currentActiveChat);
        if(currentActiveChat){
            const messageAlreadyExists = messages[currentActiveChat.chatId];
            if(!messageAlreadyExists && !currentActiveChat.newChat){
                fetchConversation(currentActiveChat.chatId);
            }
            
        }

    },[currentActiveChat,messages]);

    const fetchChat = useCallback(() => {
        const param = "?groupChat=false";
        ChatAPI.getUserChat({token : user.token,userId : user.id,param}).then(
            res => {
                const recipients = res.data.map(r =>  formatChat(r,user.id));
                dispatch(IChatAction.concateChat(recipients));
            }
        ).catch(ex => console.log(ex));
    },[user]);

    const fetchConversation = (chatId) => {
        MessageAPI.getChatMessages({token : user.token, chatId : chatId}).then(
            res => {
                const reverseArr = res.data.content;
                dispatch(IChatAction.addMessage({chatId : chatId, messages : reverseArr.reverse()}));
                //TODO: MARK MESSAGES AS DELIVERED......
                // try{
                //     if(reverseArr.length > 0) {
                //         const allMessagesWith = reverseArr.filter(itm  => itm.status === MESSAGE_STATUS.PENDING)
                //             .map(itm => itm.messageId);
                //             if(allMessagesWith > 0){
                //                 const ackPayload = {
                //                     chatId : payload.chatId,
                //                     messageIds : allMessagesWith,
                //                     status : 'delivered',
                //                     userId : payload.message?.recipientUserId,
                //                     senderId : payload?.senderId
                //                 }
                //                 MessageAPI.markMessageStatus({token,payload : ackPayload});    
                //             }
                //     }
                // }
                // catch{e => console.log(e)};
            }
        ).catch(e => console.log(e));
    }

    return {fetchChat}
}
export default useIchat;