import TimePicker from "components/util/TimePicker";
import useDebouncedWatch from "hooks/use-debounce-watch";
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import { memo, useCallback, useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { isFromInAMPMSmaller } from "util/UtilityFunctions";

const PickTime = ({onTimePick, initialValue = undefined}) => {

    const [currInitialValue,setCurrInitialValue] = useState(initialValue);

    const onFromTimeHandler = useCallback((time) => {
        // At any point FROM should always be smaller than the TO value            
        onTimePick({
            type : 'from',
            value : time
        });
        setCurrInitialValue(prev => {
            return{
                ...prev,
                from : time
            }
        });
    },[setCurrInitialValue])

    const onToTimeChangeHandler = useCallback((time) => { 

        onTimePick({
            type : 'to',
            value : time
        });
        setCurrInitialValue(prev => {
            return{
                ...prev,
                to : time
            }
        });
    },[setCurrInitialValue])

    useEffect(() => {
        setCurrInitialValue(initialValue)
    },[initialValue,setCurrInitialValue])

    useDebouncedWatch(() => {
        if(Boolean(currInitialValue.from) && Boolean(currInitialValue.to)){
            if(!isFromInAMPMSmaller(currInitialValue?.from,currInitialValue?.to)){
                toast.error("[TO] must be greater than [FROM].");
                return;
            }
        }
    },[currInitialValue])

    return(
        <MDBox marginLeft="6px" display="flex" alignItems="center">
            <MDTypography variant="button" fontWeight="regular" color="text">{"From"}</MDTypography>
            <MDBox sx={{ width: "auto", marginRight: "6px",marginLeft:"6px"}}>
                <TimePicker key={"from"} onSelected={onFromTimeHandler} initialValue={currInitialValue?.from}/>
            </MDBox>
            <MDTypography variant="button" fontWeight="regular" color="text">{"To"}</MDTypography>
            <MDBox sx={{ width: "auto", marginRight: "6px",marginLeft:"6px"}}>
                <TimePicker key={"to"} onSelected={onToTimeChangeHandler} initialValue={currInitialValue?.to}/>
            </MDBox>
        </MDBox>         
    )
}
export default memo(PickTime);