/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import MDInput from "lib/components/MDInput";
import MDButton from "lib/components/MDButton";

import { login } from "api/user";
import useHttp from "hooks/use-http";
import { useDispatch, useSelector } from "react-redux";
import { userAction } from "store/user-slice"; 

import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
const FundraiserLogin = ({switchLogin}) => {
const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const {sendRequest:loginFn, status, data: userData , error} = useHttp(login, false);
  const [httpErr , setHttpErr] = useState('');
  const [passwordValidation, setPasswordValidation] = useState('');
  const [emailValidation, setEmailValidation] = useState('');
  const [passVisible, setPassVisible] = useState(false);
  
  /// auth related
  const emailRef = useRef();
  const passwordRef = useRef();
  const isAuthenticated = useSelector((state) => state.user.isLoggedIn);
  const role = useSelector((state) => state.user.role);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginFormSubmitHandler = (event) => {
    event.preventDefault();
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    if(email === '' || email.trim().length === 0){
      setEmailValidation('Please enter valid email.');
      return;
    }
    if( password === '' || password.trim().length === 0){
      setPasswordValidation('Password field can not empty.');
      return;
    }
    loginFn({email,password});
  }
  useEffect(() => {
    if(isAuthenticated){
      if(role === 'USER'){
        navigate('/conversations');
      }
      else if( (isAuthenticated) && (role === 'ADMIN' || role === 'SUPER_ADMIN')){
        navigate('/admin/dashboard');
      }
      else{
        navigate('/404');
      }
    }
  }, [isAuthenticated,role,navigate])

  useEffect(() => {
    if(status === 'completed'){
      if(error === null){
        setHttpErr('');
        // console.log(userData)
        const user = {
          id: userData.userId,
          name : userData.userName,
          token : userData.token,
          isLoggedIn : userData.valid,
          role : userData.roles[0],
          email : userData.userEmail,
          credit: userData.totalCredit,
          company: userData?.company
        }
        dispatch(userAction.login(user));
      }
      else{
        setHttpErr(error);
      }
    }
  },[status,error,userData,dispatch])

  return (
    
    <Card>
        <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
        mx={2}
        mt={-3}
        p={2}
        mb={1}
        textAlign="center"
        >
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Fundraiser Sign in
        </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3} onSubmit={loginFormSubmitHandler}>
        <MDBox component="form" role="form">
            <MDBox mb={2}>
            <MDInput type="email" label="Email" fullWidth inputRef={emailRef} />
            <MDTypography variant="overline" fontWeight="medium" color="error" mt={1}>
            {emailValidation}
            </MDTypography>             
            </MDBox>
            <MDBox mb={2}>
            <MDInput 
                type={passVisible ? 'text' : "password"} 
                label="Password" 
                fullWidth 
                inputRef={passwordRef}
                InputProps={{
                endAdornment: <InputAdornment position="end">
                    <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setPassVisible(prev => !prev)}
                    edge="end"
                    >
                    {passVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                    </InputAdornment>
                }} 
            
            />
            <MDTypography variant="overline" fontWeight="medium" color="error" mt={1}>
            {passwordValidation}
            </MDTypography>                 
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
            <Switch checked={rememberMe} onChange={handleSetRememberMe} />
            <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
            >
                &nbsp;&nbsp;Remember me
            </MDTypography>
            </MDBox>
            <MDBox display="flex" alignItems="center" justifyContent="center" textAlign="center">
                <MDTypography variant="subtitle2" fontWeight="regular" color="error" mt={1}>
                {httpErr}
                </MDTypography>                 
            </MDBox>              
            <MDBox mt={4} mb={1}>
            <MDButton type="submit" variant="gradient" color="info" fullWidth>
                sign in
            </MDButton>                
            </MDBox>   
            <MDBox mt={2} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                <MDTypography
                  onClick = {() => switchLogin('normal_user')}
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                  sx={{
                    cursor: 'pointer'
                  }}
                >
                  Login as normal user
                </MDTypography>
              </MDTypography>
            </MDBox>                   
        </MDBox>
        </MDBox>
    </Card>
  );
}
export default FundraiserLogin;