import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import { useEffect, useState } from "react";
import useHttp from "hooks/use-http";
import { bulkFundraisersSettings } from "api/fundraiser";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const BulkFundraiserSettingMenu = ({fundraisers,campaignId,onBulkSettingsUpdated}) => {
    
    const token = useSelector(state => state.user.token);

    const [settings, setSettings] = useState();
    const [edit, setEdit] = useState(false);
    const {sendRequest, status,data,error} = useHttp(bulkFundraisersSettings,false);

    const onUIAccessCheck = (e) => {
        setEdit(true);
        setSettings((prev) => {
            return {
              ...prev,
              ui_access: e.target.checked,
            };
        });
    }
    const onNoticationOptionChange = (e) => {
        setEdit(true);
        setSettings((prev) => {
            return {
              ...prev,
              notification: e.target.value,
            };
        });
    } 
    const saveBulkSettings = () => {
        sendRequest({
            payload : {
                ui_access : settings.ui_access,
                fundraisers : fundraisers,
                campaign_id : `${campaignId}`,
                notification : settings.notification
            },
            token
        });
    }
    useEffect(() => {
        if(status === 'completed'){
            if(data){
                toast.success(data.message);
                onBulkSettingsUpdated(data.payload);
            }
            else{
                toast.error(error);
            }
        }
    },[status,data,error])
    return(
        <>
        <MDBox display="flex" flexDirection="column">
          <MDBox
            sx={{ padding: "12px" }}
            display="flex"
            flexDirection="row"
            justifyContent="space-around"
          >
            <MDTypography>Fundraisers Settings</MDTypography>
          </MDBox>
        </MDBox>
        <div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              value={Boolean(settings?.ui_access)}
              checked={Boolean(settings?.ui_access)}
              onChange={onUIAccessCheck}
              style={{
                height: "24px",
                width: "24px",
                marginRight: "20px",
                borderRadius: "15px",
                marginBottom: "25px",
              }}
              type="checkbox"
              id="UI"
            />
            <label
              style={{
                fontSize: "20px",
              }}
              htmlFor="UI"
            >
              UI Access{" "}
            </label>
            <br></br>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
          </div>
            <MDBox display="flex">
                <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">Fundraisers should notify everytime donation received via :</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={onNoticationOptionChange}
                >
                    <FormControlLabel value="sms" control={<Radio />} label="SMS" />
                    <FormControlLabel value="email" control={<Radio />} label="EMAIL" />
                    <FormControlLabel value="both" control={<Radio />} label="BOTH" />
                    <FormControlLabel value="none" control={<Radio />} label="NONE" />
                </RadioGroup>
                </FormControl>              
            </MDBox>
            {edit && (
                <MDBox display="flex" justifyContent="flex-start" onClick={saveBulkSettings} mt={2}>
                    <Button variant="contained" sx={{borderRadius:'0px', color: 'white!important', border:'unset',marginLeft:'12px', height:'32px',minHeight:'unset'}}>Save</Button>
                </MDBox>
            )}    
        </div>
      </>
    )
}
export default BulkFundraiserSettingMenu;