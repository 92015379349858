import { Checkbox, FormControlLabel, FormGroup, Tab, Tabs, TextField, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { useState } from "react";
import WCallToActionsBtn from "./WCallToActionBtns";

const HeaderOptions = [
    {name : 'Text', value : 'text'},
    {name : 'Image', value : 'image'},
    {name : 'Video', value : 'video'},
    {name : 'Document', value : 'document'}
]

const Btn = [
    {name : 'Call To Action', value : 'call'},
    {name : 'Quick Reply', value : 'reply'},
]
const WOptionalComponent = ({templateVariables,setTemplateVariables}) => {
    
    const [headerTabValue, setHeaderTabValue] = useState(0);
    const [btnValue, setBtnValue] = useState(0);
    const [optional, setOptional] = useState({
        header : false,
        footer : false,
        button : false
    });

    const handleSetHeaderTabValue = (event, newValue) => {
        setHeaderTabValue(newValue);
        if(newValue === 0){
            setTemplateVariables(prev => {
                return {
                    ...prev,
                    header : {

                        type : 'text',
                        value : ''
                    },
                }
            })
        }
        else if(newValue
             === 1){
            setTemplateVariables(prev => {
                return {
                    ...prev,
                    header : {
                        type : 'image',
                        value : ''
                    },
                }
            })
        }
        else if(newValue === 2){
            setTemplateVariables(prev => {
                return {
                    ...prev,
                    header : {
                        type : 'video',
                        value : ''
                    },
                }
            })
        }
        else if(newValue === 3){
            setTemplateVariables(prev => {
                return {
                    ...prev,
                    header : {
                        type : 'docs',
                        value : ''
                    },
                }
            })
        }
    }

    const handleSetBtnValue = (event, newValue) => {
        setBtnValue(newValue);
        if(newValue === 0){
            setTemplateVariables(prev => {
                return {
                    ...prev,
                    btn : {
                        type : 'call',
                        isPresent : true,
                        callActions : [],
                        reply : []                    
                    },
                }
            })  
        }
        else if(newValue === 1){
            setTemplateVariables(prev => {
                return {
                    ...prev,
                    btn : {
                        type : 'reply',
                        isPresent : true,
                        callActions : [],
                        reply : []                    
                    },
                }
            }) 
        }
    }
    return (
        <>
            <MDBox width="100%" padding="16px" display="flex" flexDirection="column">
                <MDBox display="flex" flexDirection="column">
                    <FormGroup>
                        <FormControlLabel
                            control={
                            <Checkbox
                                onChange={(e) => {
                                    setOptional(prev => {
                                        return {
                                            ...prev,
                                            header : e.target.checked
                                        }
                                    })
                                    if(!e.target.checked){
                                        setTemplateVariables(prev => {
                                            return {
                                                ...prev,
                                                header : {
                                                    type : '',
                                                    value :''
                                                }
                                            }
                                        })
                                    }
                                }}
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 32 }}}
                        />
                        }
                        label={"Header"}
                    />
                    {!optional.header && <Typography fontSize={"12px"}>{"Add a title or choose which type of media you'll use for header."}</Typography>}                    
                    </FormGroup>
                    {optional.header && <Tabs
                        value={headerTabValue}
                        onChange={handleSetHeaderTabValue}
                    >
                        <Tab label="Text"/>    
                        <Tab label="Image"/>
                        <Tab label="Video"/>
                        <Tab label="Document"/>
                    </Tabs>}
                    {optional.header && headerTabValue === 0 &&
                        <MDBox width="100%" mt={2}>
                            <Typography  fontSize={"12px"}>{"Add Header Text"}</Typography>
                            <TextField fullWidth variant="standard" label={"Header"} sx={{marginop:'6px'}} onChange={ e => setTemplateVariables(prev => {
                                return {
                                    ...prev,
                                    header : {
                                        type : 'text',
                                        value : e.target.value
                                    }
                                }
                            })}/>
                        </MDBox>
                    }
                    
                </MDBox>
                <MDBox mt={2}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                            <Checkbox
                                onChange={(e) => {
                                    setOptional(prev => {
                                        return {
                                            ...prev,
                                            footer : e.target.checked
                                        }
                                    })
                                    if(!e.target.checked){
                                        setTemplateVariables(prev => {
                                            return {
                                                ...prev,
                                                footer : ''
                                            }
                                        })
                                    }
                                }}
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 32 }}}
                        />
                        }
                        label={"Footer"}
                    />
                    {!optional.footer && <Typography fontSize={"12px"}>{"Add a short line of text to the bottom of your message template."}</Typography>}
                    </FormGroup>
                    { optional.footer && <MDBox width="100%" mt={2}>
                            <Typography fontSize={"12px"}>Add Footer Text</Typography>  
                            <TextField fullWidth variant="standard" label={"Footer"} onChange={e => setTemplateVariables(prev => {
                                return {
                                    ...prev,
                                    footer : e.target.value
                                }
                            })}/>         
                        </MDBox> 
                    }    
                </MDBox>
                <MDBox mt={2}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                            <Checkbox
                                onChange={(e) => {
                                    setOptional(prev => {
                                        return {
                                            ...prev,
                                            button : e.target.checked
                                        }
                                    })
                                    if(e.target.checked){
                                        setTemplateVariables(prev => {
                                            return{
                                                ...prev,
                                                btn : {
                                                    ...prev.btn,
                                                    isPresent : true
                                                }
                                            }
                                        })
                                    }
                                }}
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 32 }}}
                        />
                        }
                        label={"Button"}
                    />
                    {!optional.button && <Typography fontSize={"12px"}>{"Create buttons that let customers respond to your message or take action."}</Typography>}                    
                    </FormGroup> 
                    {optional.button && 
                        <Tabs
                            sx={{marginTop:"8px"}}
                            value={btnValue}
                            onChange={handleSetBtnValue}
                        >
                            <Tab label="Call To Action"/>    
                            <Tab label="Quick Reply"/>
                        </Tabs>
                    }    
                </MDBox> 
                {optional.button && btnValue === 0 && 
                    <MDBox display="flex" flexDirection="column">
                        <Typography sx={{marginTop:'8px'}} fontSize="12px">{"Create up to 2 buttons that let customers respond to your message or take action."}</Typography>
                        <WCallToActionsBtn onAdded={(res) => setTemplateVariables(prev => {
                            const rep = prev.btn.callActions;
                            rep[0] = res;
                            return {
                                ...prev,
                                btn : {
                                    ...prev.btn,
                                    callActions : rep
                                }
                            }
                        })} data={templateVariables.btn.callActions[0]}  />
                        <WCallToActionsBtn onAdded={(res) => setTemplateVariables(prev => {
                            const rep = prev.btn.callActions;
                            rep[1] = res;
                            return {
                                ...prev,
                                btn : {
                                    ...prev.btn,
                                    callActions : rep
                                }
                            }
                        })} data={templateVariables.btn.callActions[1]}  />
                    </MDBox>
                }

                {optional.button && btnValue === 1 && 
                    <MDBox display="flex" flexDirection="column">
                        <Typography sx={{marginTop:'8px'}} fontSize="12px">{"Create up to 3 buttons that let customers respond to your message or take action."}</Typography>
                        <TextField onChange={e => setTemplateVariables(prev => {
                            const rep = [...prev.btn?.reply];
                            rep[0] =  e.target.value;
                            return {
                                ...prev,
                                btn : {
                                    ...prev.btn,
                                    reply : rep
                            
                                }
                            }
                        })} value={templateVariables?.btn?.reply[0]} sx={{marginTop:'12px', marginBottom:'8px'}} variant="standard" label="Add Quick Reply"/>
                        <TextField onChange={e => setTemplateVariables(prev => {
                            const rep = [...prev.btn?.reply];
                            rep[1] =  e.target.value;
                            return {
                                ...prev,
                                btn : {
                                    ...prev.btn,
                                    reply : rep
                                }
                            }
                        })} value={templateVariables.btn?.reply[1]} sx={{marginTop:'12px', marginBottom:'8px'}} variant="standard" label="Add Quick Reply"/>
                        <TextField onChange={e => setTemplateVariables(prev => {
                            const rep = [...prev.btn?.reply];
                            rep[2] =  e.target.value;
                            return {
                                ...prev,
                                btn : {
                                    ...prev.btn,
                                    reply : rep
                                }
                            }
                        })} value={templateVariables.btn?.reply[2]} sx={{marginTop:'12px', marginBottom:'8px'}} variant="standard" label="Add Quick Reply"/>
                    </MDBox>                   
                }                
            </MDBox>
        </>
    )
}
export default WOptionalComponent;