import { DEFAULT_CUSTOM_DATE } from "constants/AutoReplyConstants";
import MDBox from "lib/components/MDBox";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { memo, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { isDateTimeSmaller } from "util/UtilityFunctions";

const CustomeDateRangeSchedule = ({initialDate,phoneId,setScheduledOptions,companyWide,scheduledOptions}) => {
    
    const [date,setDate] = useState(DEFAULT_CUSTOM_DATE);

    const tempSave =  useCallback(() => {
        if(companyWide) {
            setScheduledOptions(prev => {
                return{
                    ...prev,
                    companyWideSettings : {
                        ...prev.companyWideSettings,
                        customDate : date 
                    }
                }
            })
        }
        else{
            const phoneSettings = scheduledOptions?.phoneSettings?.map(item => {
                if(item?.phoneId === phoneId){
                    return {
                        ...item,
                        options : {
                            ...item.options,
                            customDate : date
                        }
                    }
                }   
                else return item;
            });
            setScheduledOptions(prev => {
                return{
                    ...prev,
                    phoneSettings : phoneSettings
                }
            })
        }
    },[companyWide,setScheduledOptions,date,phoneId,scheduledOptions]);

    const handleToChange = useCallback((e) => {

        const tempDate = e.target.value;
        const smaller = isDateTimeSmaller(date.from.value,tempDate);
        if(date.from === null || !smaller) {
            toast.warning("Select a valid date. [TO] can't be smaller than [FROM]");
            return;
        }
        setDate(prev => {
            return {
                ...prev,
                to : tempDate
            }
        });
        tempSave();
    },[date]);

    const handleFromChange = (e) => {
        
        const tempDate = e.target.value;
        
        setDate(prev => {
            return {
                ...prev,
                from : tempDate
            }
        });
        tempSave(tempDate);
    }

    useEffect(() => {
        if(initialDate) {
            setDate(initialDate);
        }
    },[initialDate]);

    return(
        <MDBox marginLeft="6px">
            <MDTypography variant="button" fontWeight="regular" color="text">{"From"}</MDTypography>
            <MDInput
                sx={{ width: "auto",marginRight: "6px",marginLeft:"6px"}}
                type="datetime-local"
                value={date.from}
                size="small" 
                onChange= {handleFromChange} 
            />
            <MDTypography variant="button" fontWeight="regular" color="text">{"To"}</MDTypography>
            <MDInput
                sx={{ width: "auto", marginRight: "6px",marginLeft:"6px"}}
                type="datetime-local"
                value={date.to}
                onChange= {handleToChange}                 
                size="small" 
            />
        </MDBox>         
    )
}
export default memo(CustomeDateRangeSchedule);