import { VIEWS } from "constants/ChatConstants";
import { useDispatch, useSelector } from "react-redux";
import NRecipientList from "./NChat/NRecipientList";
import BroadcastView from "./Broadcast/BroadcastView";
import { memo, useState, useRef } from "react";
import MDBox from "lib/components/MDBox";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import CreateBroadcast from "./Broadcast/CreateBroadcast";
import AppModal from "ui/AppModal";
import { groupAction } from "store/group-slice";
/**
 <GroupView
              key={groupViewRefresh}
              newGroups={newGroups}
              isCheckboxSelected={isCheckboxSelected}
              currentActivePhone={currentActivePhone}
              onEventPerform={onEventPerformInGroupView}
            /> 
 */
const RecipientList = ({ addRecipient, reloadRecipients }) => {

    const dispatch = useDispatch();
    const groupViewRef = useRef()
    const view = useSelector(state => state.chat.current_active_view);
    const currentActivePhone = useSelector(state => state.chat.current_active_phone);

    const [createGroup, setCreateGroup] = useState(false);

    const onGroupCreated = (res) => {
        dispatch(groupAction.addGroup({ group: res }));
        setCreateGroup(false);
        if (res?.groupId) {
            groupViewRef?.current?.openDetail(res)
        }
    }

    if (view === VIEWS.NORMAL_VIEW || view === VIEWS.SEARCH_RECIPIENT_VIEW || view === VIEWS.HIPAA_VIEW || view === VIEWS.SCHEDULED_VIEW || view === VIEWS.UNREAD_VIEW || view === VIEWS.MERGED_VIEW) {
        return <NRecipientList addRecipient={addRecipient} reloadRecipients={reloadRecipients} key={"recipient_list"} />
    }
    else
        return <>
            <MDBox display="flex" justifyContent="flex-end" sx={{ borderRight: '1px solid rgb(221, 221, 221)' }}>
                <Button onClick={() => setCreateGroup(true)}><Add /> Create a group</Button>
            </MDBox>
            <BroadcastView ref={groupViewRef} key={"group_view"}/>
            {createGroup &&
                <AppModal
                    closeOnClick={false}
                    customStyle={{ width: "500px" }}
                    onModalClose={() => {
                        setCreateGroup(false);
                    }}
                    heading={"Create Group"}
                >
                    <CreateBroadcast
                        currentPhone={currentActivePhone}
                        onGroupCreated={onGroupCreated}
                    />
                </AppModal>
            }
        </>
}
export default memo(RecipientList);