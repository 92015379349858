import { Close } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import MDBox from "lib/components/MDBox";
import React, { useEffect } from "react";

const AppDialog = ({style,title,content,show,onClose,footer,showCloseOption = true,fullWidth=true}) => {

      ///// Dialog state  
  const [open, setOpen] = React.useState(show);

  /// When dialog box is opened
  const handleClickOpen = () => {
    setOpen(true);
  };

  //// When dialog box is closed
  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  }
  useEffect(() => {
    setOpen(show)
  },[show])

    return(
        <>
            <Dialog
              style={{...style}}
              open={open}
              fullWidth={fullWidth}
              onClose={!showCloseOption ? handleClose : () => {}}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <MDBox display="flex" flexDirection="row" justifyContent="space-between" pl={2} pr={2} pt={2}>
                {title && <DialogTitle id="alert-dialog-title">
                    {title}
                  </DialogTitle>}
                  {showCloseOption && <Close onClick={handleClose} style={{cursor:"pointer",padding:"4px",height:'28px',width:'28px'}}/>}
              </MDBox>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                {footer}
              </DialogActions>
            </Dialog>
        </>
    )
}
export default AppDialog;