import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pendingRecipientMsgs : {},
    pendingBroadcastMsgs : {}
}

const timeoutSlice = createSlice({
    name : "timeout",
    initialState : initialState,
    reducers : {
        addTimer : (state,action) => {
            const {recipientId,messageId,timeoutObj} = action.payload;
            if(!state.pendingRecipientMsgs[recipientId]){
                state.pendingRecipientMsgs[recipientId] = {};
            }
            state.pendingRecipientMsgs[recipientId][messageId] = timeoutObj;
        },
        removeTimer : (state,action) => {
            const {recipientId,messageId} = action.payload;
            delete state.pendingRecipientMsgs[recipientId][messageId];
        },
        addBroadcastTimer : (state,action) => {
            const {groupId,messageId,timeoutObj} = action.payload;
            if(!state.pendingBroadcastMsgs[groupId]){
                state.pendingBroadcastMsgs[groupId] = {};
            }
            state.pendingBroadcastMsgs[groupId][messageId] = timeoutObj;
        },
        removeBroadcastTimer : (state,action) => {
            const {groupId,messageId} = action.payload;
            delete state.pendingBroadcastMsgs[groupId][messageId];
        }
    }
});

export const timeoutAction = timeoutSlice.actions;
export default timeoutSlice;
