import { LeftChatContent } from "pages/Chat/ChatStyles";
import Recipient from "./Recipient";
import MDBox from "lib/components/MDBox";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { memo } from "react";
import IGroupChat from "../GChat/IGroupChat";
import { IVIEWS } from "constants/ChatConstants";

const RecipientsList = ({recipients}) => {
    
    const currentActiveView = useSelector(state => state.ichatVariables.activeView);
    
    return(
        <LeftChatContent>
            {currentActiveView === IVIEWS.INORMAL_VIEW ?
                <MDBox diplay="flex" width="auto" height="auto">
                    {recipients?.length > 0 ? recipients.map(recipient => <Recipient key={recipient?.chatId} chat={recipient}  latestMessage={recipient?.latestMessage}/>) 
                    : <MDBox width="100%" height="calc(100vh - 114px)" display="flex" alignItems="center" justifyContent="center">
                        <Typography>{"No Contact found."}</Typography>
                    </MDBox>
                    }
                </MDBox>
            :
                <MDBox diplay="flex" width="auto" height="auto">
                    <IGroupChat/>                    
                </MDBox>
            }
        </LeftChatContent>
    )
}
export default memo(RecipientsList);