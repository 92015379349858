import { Button, Divider, TextField, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { useState } from "react";

const TransactionFilter = ({setFiltered,onFilterApply}) => {

    const [filterOption, setFilterOption] = useState({
        from : '',
        to : '',
        amount : ''
    })
    return (
      <>
        <MDBox width="300px">
          <Typography>Select Filters</Typography>
          {/* <MDBox mt={2}>
                <InputLabel id="demo-select-small">Fundraiser</InputLabel>
                <Select
                  labelId="demo-select-small"
                  style={{ width: "300px", height: "40px", marginTop: "8px" }}
                  id="demo-select-small"
                  label=""
                  // value={assingedPhone}
                  onChange= { (e) => {console.log(e.target.value);}}
                >
                  {activePhones.map(item => <MenuItem key={item.id} value={item}>{item.name}</MenuItem>)}
                </Select>               
              </MDBox>   */}
          {/* <MDBox mt={2}>
                <InputLabel id="demo-select-small">Payment Medium</InputLabel>
                <Select
                  labelId="demo-select-small"
                  style={{ width: "300px", height: "40px", marginTop: "8px" }}
                  id="demo-select-small"
                  label=""
                  // value={assingedPhone}
                  onChange= { (e) => {console.log(e.target.value);}}
                >
                {medium.map(item => <MenuItem key={item.id} value={item}>{item.name}</MenuItem>)}
                </Select>               
              </MDBox> */}
          <MDBox mt={2}>
            <MDBox>
                <Typography>From :</Typography>    
                <TextField
                    id="datetime-local"
                    label="Start"
                    type="datetime-local"
                    defaultValue={filterOption.from}
                    onChange={(e) => {
                        setFilterOption(prev => {
                            return{
                                ...prev,
                                from : e.target.value
                            }
                        })
                    }}
                    sx={{ width: 250, margin:'8px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </MDBox>
            
            <MDBox>
                <Typography>To :</Typography> 
                <TextField
                    id="datetime-local"
                    label="End"
                    type="datetime-local"
                    defaultValue={filterOption.to}
                    onChange={(e) => {
                        setFilterOption(prev => {
                            return{
                                ...prev,
                                to : e.target.value
                            }
                        })
                    }}
                    sx={{ width: 250, margin:'8px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </MDBox>
            <MDBox>
                <Typography>Amount :</Typography> 
                <TextField
                    label="Amount"
                    type="number"
                    defaultValue={filterOption.amount}
                    onChange={(e) => {
                        setFilterOption(prev => {
                            return{
                                ...prev,
                                amount : e.target.value
                            }
                        })
                    }}
                    sx={{ width: 250, margin:'8px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </MDBox>
          </MDBox>
          <MDBox mt={2} display="flex" justifyContent="flex-end">
            <Button
              onClick={() => {
                setFiltered(false);
                onFilterApply({ 
                  from : '',
                  to : '',
                  amount : ''
                });
              }}
              variant="outlined"
              sx={{
                borderRadius: "0px",
                color: "black!important",
                marginRight: "12px",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                borderRadius: "0px",
                color: "white!important",
                border: "unset",
              }}
              onClick={() => onFilterApply(filterOption)}
            >
              Apply
            </Button>
          </MDBox>
        </MDBox>
        <MDBox sx={{ marginRight: "12px" }}>
          <Divider
            orientation="vertical"
            component="div"
            sx={{
              "&.MuiDivider-root": {
                backgroundColor: "#e9d5e9",
                height: "100vh",
              },
            }}
          />
        </MDBox>
      </>
    );
}
export default TransactionFilter;