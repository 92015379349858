import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import { useState, useRef } from "react";
import AppModal from "ui/AppModal";

const ImageUpload = ({onClose,onAttachmentAdded}) => {
    const inputRef = useRef('inputRef')
    const imageInputChange = (e) => {
        console.log('imageInputChange',e)
        if (e.target.files && e.target.files[0]) {
            onFileUploadClick(e.target.files[0]);
          }
    }
    const onFileUploadClick = (attached = undefined) => {
        if(attached)
            onAttachmentAdded(attached);
    }
    const onInputClick = (event) =>{
        event.target.value = null
    }
    return(
        <AppModal onModalClose={() => onClose()} heading={'Choose Image'}>
            <MDBox display="flex" alignItems="center" justifyContent="center">
                <MDInput type="file" label="" onChange={imageInputChange} inputRef={inputRef} inputProps={{onclick:onInputClick}} />
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" justifyContent="center">
                {tempImage && <img style={{height:'300px', width:'300px',objectFit:'contain'}} src={tempImage}/>}
            </MDBox> */}
        </AppModal>
    )
}
export default ImageUpload;