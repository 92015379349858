import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useCallback } from "react";
import useIsend from "hooks/IChat/use-isend";

import useItyping from "hooks/IChat/use-ityping";
import { useEffect } from "react";
import { USER_QUEUE } from "constants/WsConstants";
import MDBox from "lib/components/MDBox";
import IChatFooter from "../IChatFooter";

const IChatFooterWrapper = ({messageState, setMessageState}) => {
    
    const currentActiveChat = useSelector(state => state.Ichat.currentActiveChat);
    const stompClient = useSelector(state => state.app.stompClient);
    const token = useSelector(state => state.user.token);
    const mounted = useRef(false);
    //custom HOOK..
    const {sendMessage} = useIsend();
    const { isTyping, startTyping, stopTyping } = useItyping();

    const onMessageSend = (payload) => {
        const newPayload = {token,payload};
        sendMessage(newPayload);
    }

    const handleOnTyping = (payload) => {
        if(payload === 'START_TYPING') {
            startTyping();
        }
        else {
            stopTyping()
        }
    }

    const buildPayload = useCallback(() => {
        return {
            type : 'typing',
            chatId : currentActiveChat.chatId,
            userId : currentActiveChat.recipientId,
            status : 'typing'
        }
    },[currentActiveChat])

    useEffect(() => {
        // inform the end user....
        if(!mounted.current){
            mounted.current = true;
            return;
        }
        const payload = buildPayload();
        if(!isTyping){
            payload.status = 'stopped';
        }
        stompClient?.send(USER_QUEUE(payload.userId),{},JSON.stringify(payload));
        
    },[isTyping]);

    return(
        <MDBox sx={{position:'relative'}}>
            {/* {currentActiveChat?.iEvent && currentActiveChat?.iEvent.type === WEBSOCKET_EVENT_TYPE.USER_TYPING || true && 
            <MDTypography sx={{position:'absolute',top:0,width:"100%",textAlign:'center',zIndex:99999}} fontSize={"14px"} color={"info"}>
                {`Typing...`}
            </MDTypography>} */}
            <IChatFooter key={"nmessage_"}
              messageState={messageState}
              setMessageState={setMessageState} onMessageSend={onMessageSend} onTyping={handleOnTyping} activeChat={currentActiveChat}/>
        </MDBox>
    )
}
export default React.memo(IChatFooterWrapper);