import { Button, Typography } from "@mui/material";
import NoContent from "components/Chat/NoContent";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userAction } from "store/user-slice";
import LogoutIcon from "@mui/icons-material/Logout";
import { appAction } from "store/app-slice";
import { chatVariablesAction } from "store/chat-variables-slice";
import { ichatVariablesAction } from "store/IChat/Ichat-variables-slice";
import { IVIEWS } from "constants/ChatConstants";

const NoPhoneUI = ({userDetails}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    return(
        <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection:'column',
          width: "100%",
          height: "100vh",
        }}
      >
        <NoContent
          heading={"h3"}
          style={{margin:'16px'}}
          message={`No Phone Number is associated with the user ${userDetails?.email}.`}
        >
          <Typography textAlign={"center"} m={2} mt={2} variant="h5">Click on the icon below to logout</Typography>
        <LogoutIcon
          sx={{fontSize:'38px',width:'40px', marginTop:'12px', height:'40px',cursor:'pointer'}}
          onClick={() => {
            dispatch(userAction.logout());
            navigate("/login");
          }}
        />
        <Typography>{"OR"}</Typography>
        <Button onClick={() => {
          dispatch(appAction.setOnlyIcAccess());
          dispatch(chatVariablesAction.setIchat(true));
          dispatch(ichatVariablesAction.setActiveView(IVIEWS.INORMAL_VIEW))
        }} sx={{fontSize:'22px'}}>Go to internal chat</Button>
        </NoContent>
      </div>
    )
}
export default NoPhoneUI;