import { Card, Checkbox, Divider, MenuItem, Select, TextField, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import brandLogo from "assets/images/logos/e.png";
import ReCAPTCHA from "react-google-recaptcha";
import GoalCompletionUI from "components/CommonUI/GoalCompletionUI";
import { getPercentage } from "util/UtilityFunctions";
import { toast } from "react-toastify";
import { CrowdfundingAPI } from "services/Crowdfunding/CrowdfundingAPI";
import SockJS from "sockjs-client";
import { Stomp } from '@stomp/stompjs';
import { BLIP_WS_URL } from "constants/Endpoints";
import { CAMPAIGN_FUNDRAISER_TOPIC } from "constants/WsConstants";
import { parseWSData } from "util/UtilityFunctions";
import { WEBSOCKET_EVENT_TYPE } from "constants/ChatConstants";
import  "./Cardknox.module.css";
import useDebouncedWatch from "hooks/use-debounce-watch";
import { DonationFormValidator } from "util/Crowdfunding/DonationFormValidator";
import { loaderAction } from "store/loader-slice";
import Swal from "sweetalert2";

const PAYMENT_INPUT_INITIAL = {
  xName : '',
  xCardNum: '',
  xExp: '',
  xAmount : '',
  donor_phone : '',
  xEmail : '',
  xCVV : '',
}

const RECURRING_PAYMENT_INITIAL = {
  recurring : false,
  end : 'never',
  number_of_payments : 12,
  frequency : 1
}

const CardKnoxProcessor = () => {

  const dispatch = useDispatch();

  const [fundraiserExists, setFundraiserExists] = useState(true);
  const{campaignName,fundraiserId} = useParams();
  
  const [recurringPayment, setRecurringPayment] = useState(RECURRING_PAYMENT_INITIAL);
  
  const [campaign, setCampaign] = useState();
  const [fundraiser,setFundraiser] = useState();
  const [paymentInput, setPaymentInput] = useState(PAYMENT_INPUT_INITIAL);
  const [isVerified, setIsVerified] = useState(false);
  
  const token = useSelector(state => state.user.token);
  
  const  handleRecaptcha = () => {
    setIsVerified(true);
  }

  const createStompClient = (url) => {
    const socket = new SockJS(url, {}, { CheckOrigin: () => false });
    return Stomp.over(socket);
  };

  const updateCampaignAfterDonation = (newPayload) => {
    setCampaign(prev => {
    return{
      ...prev,
      raised_so_far : prev.raised_so_far + (parseFloat(newPayload.donation_amount))
    }
    });
    setFundraiser(prev => {
    return{
      ...prev,
      raised_so_far : prev.raised_so_far + (parseFloat(newPayload.donation_amount))
    }
    })
  }

  const onDonationReceived = useCallback((res) => {
    const payload = parseWSData(res.body);
    if(payload.ws_type === WEBSOCKET_EVENT_TYPE.DONATION_RECEIVED){
      let str = "";
      if(payload?.status === "SUCCESS") {
        str =   `Successfully received donation of ${payload?.donation_amount} from ${payload?.donor_name} for fundraiser ${payload?.fundraiser_name} under ${payload.campaign?.campaign_name} campaign.`;
        updateCampaignAfterDonation(payload);
      }
      else if(payload?.status === "FAILED") {
        str = `
          Failed donation of ${payload?.donation_amount} from ${payload?.donor_name} for fundraiser ${payload?.fundraiser_name} under ${payload.campaign?.campaign_name} campaign.
        `
      } 
    }
  },[campaign,fundraiser]);

  const onPaymentSubmit = useCallback(() => {
    if(paymentInput.xAmount === '' || paymentInput.xCardNum === '' || paymentInput.xCVV === ''){
      toast.error("Please fill the form correctly.");
      return;
    }

    if(paymentInput.xAmount?.length > 0 && Boolean(campaign?.min_donation_amount) &&  paymentInput.xAmount < campaign.min_donation_amount){
      toast.error("Minimum donation amount is " + campaign.min_donation_amount);
      return;
    }

    const newInput = {
      ...paymentInput,
      xExp : paymentInput.xExp.replace(/\D/g, ''),
      recurring_payment : {...recurringPayment},
      fundraiser : {...fundraiser,phones : {phone : fundraiser?.assigned_phone?.phone}},
      campaign  : {
        ...campaign,
        company_id : campaign?.company?.company_id
      },
      fundraiser_name : fundraiser?.fundraiser_name? fundraiser?.fundraiser_name : 'Campaign'
    }
    dispatch(loaderAction.show());
    CrowdfundingAPI.processPayment({payload : newInput}).then(res => {
      const {data} = res;
      if(data?.xresult === "A" || data?.result === 'S' || data?.result === "s"){
        Swal.fire({
          icon: 'success',
          title: "Donation success",
          text: "Payment successful. Thank you for your donation.",
        });
        setPaymentInput(PAYMENT_INPUT_INITIAL);
        setRecurringPayment(RECURRING_PAYMENT_INITIAL);
      }
      if(data?.xresult === "E" || data?.Result === "E") {
        Swal.fire({
          icon: 'error',
          title: "Payment failed.",
          text: "Due to : " + data?.xerror,
        });
      }
      dispatch(loaderAction.hide());
    }).catch(e => {
      Swal.fire({
        icon: 'error',
        title: "Payment failed.",
        text: "Due to : " + e?.response?.data?.message,
      });
      dispatch(loaderAction.hide());
    }); 
  },[campaign,fundraiser,paymentInput,recurringPayment])

  useEffect(() => {
    let stmp = undefined;
    if(stmp === undefined && campaignName && fundraiser && fundraiser?.fundraiser_name){
      stmp = createStompClient(BLIP_WS_URL);
      stmp?.connect({},() => {
        stmp?.subscribe(CAMPAIGN_FUNDRAISER_TOPIC(campaignName,fundraiser?.fundraiser_name),onDonationReceived);
        console.log("connected...");
      });
    } 
    // return () => {
    //   console.log({stmp})
    //   if(Boolean(stmp)){
    //     stmp?.unsubscribe();
    //   }
    // }
  },[fundraiser,campaignName]);

  useEffect(() => {
    if(campaignName && fundraiserId){
      CrowdfundingAPI.getDonationData({fundraiser_id : fundraiserId,token}).then(res => {
        setCampaign(res.data.campaign);
        setFundraiser(res.data);
        if(!Boolean(res.data.campaign)){
          setFundraiserExists(false);
        }
        else if(res.goal_amount === 0){
          setFundraiserExists(false);
        }
      }).catch(e => {
        setFundraiserExists(false);
      });
    }
  },[campaignName,fundraiserId,token])

  useDebouncedWatch(() => {
    if(paymentInput.xAmount.length > 0 && campaign?.min_donation_amount && paymentInput.xAmount < campaign?.min_donation_amount) {
      toast.error("Minimum donation amount is " + campaign?.min_donation_amount);
    }
  },[paymentInput.xAmount,campaign]);

  useEffect(() => {
    document.title = 'WayMore Payments';
  }, []);

    if(!fundraiserExists){
      return (
        <>
          <MDBox
            display="flex"
            flexDirection="column"
            sx={{ height: "100vh", overflowY: "hidden"}}
          >
            <MDBox height="80px" width="100%" sx={{ backgroundColor: "#f5f5f5" }} display="flex" justifyContent="space-between">
              <MDBox display="flex" height="100%" flexDirection="row">
                <MDBox
                  display="flex"
                  ml={4}
                  p={4}
                  flexDirection="row"
                  alignItems="center"
                >
                  <img
                    src={brandLogo}
                    style={{ width: "40px", height: "40px" }}
                  />
                  <Typography variant="h4">
                    {"WayMore Payments"}
                    <Typography
                      sx={{
                        fontSize: "0.65rem",
                        height: "0px",
                        lineHeight: "unset!important",
                      }}
                    >
                      Powered by BLIQ
                    </Typography>
                  </Typography>
                </MDBox>
              </MDBox>
              <MDBox display="flex" alignItems="center">
                  <Typography sx={{padding:'12px'}}>{campaign?.company?.company_name}</Typography>
                </MDBox>
            </MDBox> 
            <MDBox height="100%" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
              <Typography variant="h4">{"This campaign is not active."}</Typography>
              <Typography mt={2} variant="h6">{"Please check that you have the correct URL."}</Typography>  
            </MDBox>
          </MDBox>
        </>
      )
    }
    else{
      return (
        <>
          <MDBox
            display="flex"
            flexDirection="column"
            sx={{ height: "100vh", overflowY: "hidden"}}
          >
            <MDBox height="80px" width="100%" sx={{ backgroundColor: "#f5f5f5" }} display="flex" justifyContent="space-between">
              <MDBox display="flex" height="100%" flexDirection="row">
                <MDBox
                  display="flex"
                  ml={4}
                  p={4}
                  flexDirection="row"
                  alignItems="center"
                >
                  <img
                    src={brandLogo}
                    style={{ width: "40px", height: "40px" }}
                  />
                  <Typography variant="h4">
                    {"WayMore Payments"}
                    <Typography
                      sx={{
                        fontSize: "0.65rem",
                        height: "0px",
                        lineHeight: "unset!important",
                      }}
                    >
                      Powered by BLIQ
                    </Typography>
                  </Typography>
                </MDBox>
              </MDBox>
              <MDBox display="flex" alignItems="center">
                  <Typography sx={{padding:'12px'}}>{campaign?.company?.company_name}</Typography>
                </MDBox>
            </MDBox>
            <MDBox sx={{backgroundColor:'#f9f9f9',backgroundImage: `url(${campaign?.company?.company_banner})`}}>
              <MDBox display="flex" flexDirection="row" p={6} sx={{}}>
                <MDBox
                  width="50%"
                  display="flex"
                  justifyContent="center"
                  // alignItems="center"
                >
                  <MDBox display="flex" alignItems="start" flexDirection="column">
                    <Card sx={{padding:'22px',minWidth: '400px'}}>
                      <Typography sx={{margin:'16px'}} variant="h4">
                        {campaign?.campaign_name}
                      </Typography>
                      {campaign && <MDBox display="flex" alignItems="center" flexDirection="column" justifyContent="center">
                        <Typography sx={{padding:'8px'}} fontSize={'24px'}>Campaign goal raised</Typography>
                        <Typography variant="h6">${campaign?.raised_so_far} of ${campaign?.goal_amount}</Typography>
                        <GoalCompletionUI  value={getPercentage(campaign.raised_so_far, campaign.goal_amount)} color={"info"}/>
                        </MDBox>}
                      <MDBox display="flex" flexDirection="column">
                        <MDBox sx={{margin:'16px'}}>
                          <Typography variant="h5">
                            Welcome to {fundraiser?.fundraiser_name}
                            {"'s"} donation page
                          </Typography>
                        </MDBox>
                      </MDBox>
                      {(fundraiser && fundraiser.goal_amount > 0) && <MDBox display="flex" alignItems="center" flexDirection="column" justifyContent="center">
                        <Typography  sx={{padding:'8px'}} fontSize={'24px'}>Fundraiser goal raised</Typography>
                        <Typography variant="h6">${fundraiser?.raised_so_far} of ${fundraiser?.goal_amount}</Typography>
                        <GoalCompletionUI  value={getPercentage(fundraiser?.raised_so_far, fundraiser?.goal_amount)} color={"success"}/>
                      </MDBox>}
                    </Card>
                  </MDBox>
                </MDBox>
                <MDBox sx={{ marginRight: "12px" }}>
                  <Divider
                    orientation="vertical"
                    component="div"
                    sx={{
                      "&.MuiDivider-root": {
                        backgroundColor: "#e9d5e9",
                        height: "100%",
                      },
                    }}
                  />
                </MDBox>
                <MDBox
                  width="50%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <MDBox
                    sx={{
                      overflowY: "auto",
                      height: "calc(100vh - 115px)",
                      padding: "12px",
                      "&::-webkit-scrollbar": {
                        width: "0.3em",
                        height: "0.4em",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderRadius: "4px",
                        outline: "1px solid slategrey",
                        backgroundColor: "#8b8b8b",
                      },
                    }}
                  >
                    <Card sx={{ padding: "22px", marginBottom: "16px" }}>
                      <Typography
                        sx={{
                          justifyContent: "center",
                          textAlign: "center",
                          marginBottom: "12px",
                        }}
                      >
                        Secure Payment Form{" "}
                      </Typography>
                      <MDBox mb={2}>
                        <MDInput
                          type="text"
                          label="Name"
                          value={paymentInput.xName}
                          fullWidth
                          onChange={(e) => {
                            setPaymentInput((prev) => {
                              return {
                                ...prev,
                                xName: e.target.value,
                              };
                            });
                          }}
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          type="text"
                          label="Phone Number"
                          value={paymentInput.donor_phone}
                          fullWidth
                          onChange={(e) => {
                            setPaymentInput((prev) => {
                              return {
                                ...prev,
                                donor_phone: e.target.value,
                              };
                            });
                          }}
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          type="email"
                          label="Email"
                          value={paymentInput.xEmail}
                          fullWidth
                          onChange={(e) => {
                            setPaymentInput((prev) => {
                              return {
                                ...prev,
                                xEmail: e.target.value,
                              };
                            });
                          }}
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          style={{ width: "100%" }}
                          label="Amount"
                          type={"number"}
                          error={paymentInput.xAmount?.length > 0 && paymentInput.xAmount < campaign?.min_donation_amount}
                          success={paymentInput.xAmount >= campaign?.min_donation_amount}
                          value={paymentInput.xAmount}
                          onChange={e => {
                            setPaymentInput((prev) => {
                              return {
                                ...prev,
                                xAmount: e.target.value,
                              };
                            });
                          }}
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          style={{ width: "100%" }}
                          type={"number"}
                          success={paymentInput.xCardNum?.length > 0 && DonationFormValidator.validCardNumber(paymentInput.xCardNum)}
                          error={paymentInput.xCardNum?.length > 0 && !DonationFormValidator.validCardNumber(paymentInput.xCardNum)}
                          value={paymentInput.xCardNum}
                          onChange={(e) => {
                            let val = e.target.value;
                            if(val.length > 16){
                              val = val.substring(0,16);
                            }
                            setPaymentInput((prev) => {
                              return {
                                ...prev,
                                xCardNum: val,
                              };
                            });
                          }}
                          label={"Card Number"}
                        />
                      </MDBox>
                      <MDBox mb={2} display="flex">
                        <MDBox>
                          <MDInput
                            label="MM/YY"
                            value={paymentInput.xExp}
                            success={paymentInput.xExp && DonationFormValidator.validExpiry(paymentInput.xExp)}
                            error={paymentInput.xExp?.length > 0 && paymentInput.xExp && !DonationFormValidator.validExpiry(paymentInput.xExp)} 
                            onChange={(e) => {
                              let value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                              let first2Digit = value.slice(0, 2);
                              let last2Digit =  value.slice(2, 4);
                              if (value.length > 2) {
                                value = first2Digit + '/' + last2Digit; 
                              }
                              setPaymentInput((prev) => {
                                return {
                                  ...prev,
                                  xExp: value,
                                };
                              });
                            }}
                          />
                        </MDBox>
                        <MDBox ml={2}>
                          <MDInput 
                            label="Cvv"
                            type={"number"}
                            value={paymentInput.xCVV}
                            success={DonationFormValidator.validCvv(paymentInput.xCVV,paymentInput.xCardNum)}
                            error={paymentInput?.xCardNum?.length > 0 && paymentInput?.xCVV?.length > 0 && !DonationFormValidator.validCvv(paymentInput.xCVV,paymentInput.xCardNum)}
                            onChange={(e) => {
                              let value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                              if (value.length <= DonationFormValidator.maxCvvInput(paymentInput?.xCardNum)) {
                                setPaymentInput((prev) => {
                                  return {
                                    ...prev,
                                    xCVV: e.target.value,
                                  };
                                });
                              }
                            }}
                          />
                        </MDBox>
                      </MDBox>
                      <MDBox display="flex" alignItems="center" ml={-1}>
                        <Checkbox
                          value={recurringPayment.recurring}
                          onChange={(e) => {
                            setRecurringPayment((prev) => {
                              return {
                                ...prev,
                                recurring: e.target.checked,
                              };
                            });
                          }}
                        />
                        <MDTypography
                          variant="button"
                          fontWeight="bold"
                          color="text"
                          sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                        >
                          &nbsp;&nbsp;Recurring donation&nbsp;
                        </MDTypography>
                      </MDBox>
                      {recurringPayment.recurring && (
                        <MDBox display="flex" flexDirection="column">
                          <MDBox
                            display="flex"
                            flexDirection="row"
                            mt={2}
                            alignItems="center"
                          >
                            <Typography
                              variant="button"
                              fontWeight={"400"}
                              color="text"
                            >
                              Every
                            </Typography>
                            <TextField
                              variant="outlined"
                              value={recurringPayment.frequency}
                              onChange={(e) => {
                                setRecurringPayment(prev => {
                                  return {
                                    ...prev,
                                    frequency : e.target.value
                                  }
                                })
                              }}
                              sx={{
                                height: "24px",
                                marginLeft: "12px",
                                width: "48px",
                                "& .MuiOutlinedInput-root": {
                                  height: "32px!important",
                                },
                              }}
                            />
                            <Select
                              sx={{
                                width: "150px",
                                marginTop: "8px",
                                height: "32px!important",
                                marginLeft: "12px",
                              }}
                              value={"month"}
                            >
                              <MenuItem value="month">Month(s)</MenuItem>
                            </Select>
                          </MDBox>
                          <MDBox display="flex" flexDirection="column" mt={2}>
                            <MDBox display="flex" flexDirection="column">
                              <Typography
                                variant="button"
                                fontWeight={"400"}
                                color="text"
                              >
                                End
                              </Typography>
                              <Select
                                value={recurringPayment.end}
                                onChange={(e) => {
                                  setRecurringPayment((prev) => {
                                    return {
                                      ...prev,
                                      end: e.target.value,
                                    };
                                  });
                                }}
                                sx={{
                                  height: "42px",
                                  "& .MuiSvgIcon-root": {
                                    display: "inline-block",
                                    fontSize: "24px!important",
                                  },
                                }}
                              >
                                <MenuItem value="never">Never</MenuItem>
                                <MenuItem value="number_of_payments">
                                  Number of Payments{" "}
                                </MenuItem>
                              </Select>
                              {recurringPayment.end === "number_of_payments" && (
                                <TextField
                                  sx={{
                                    marginTop: "12px",
                                  }}
                                  value={recurringPayment.number_of_payments}
                                  onChange={(e) => {
                                    setRecurringPayment((prev) => {
                                      return {
                                        ...prev,
                                        number_of_payments: e.target.value,
                                      };
                                    });
                                  }}
                                  type="number"
                                />
                              )}
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      )}
                      <MDBox mt={2} display="flex" justifyContent="center">
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}
                          onChange={handleRecaptcha}
                        />
                      </MDBox>
                      <MDBox mt={2} mb={1} onClick={onPaymentSubmit}>
                        <MDButton
                          type="button"
                          variant="gradient"
                          color="info"
                          fullWidth
                        >
                          Donate
                        </MDButton>
                      </MDBox>
                    </Card>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </>
      );
    }
}
export default CardKnoxProcessor;