import MDBox from "lib/components/MDBox";
import { Navbar } from "pages/Chat/ChatStyles";
import MDTypography from "lib/components/MDTypography";
import { Avatar, Tooltip } from "@mui/material";
import { Search } from "@mui/icons-material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { IChatAction } from "store/IChat/Ichat-slice";

const MiddleNavBar = ({OnSearchOpen}) => {
    
    const currentActiveChat = useSelector(state => state.Ichat.currentActiveChat);
    
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const [searchActive,setSearchActive] = useState(false);
    const dispatch = useDispatch();

    return (
        <Navbar borderLeft="none">
            <MDBox display="flex" width="100%" justifyContent="space-between" alignItems="center">
                {isMobile && <MDBox sx={{marginLeft:'12px'}}>
                    <ArrowBackIosIcon onClick={() => {
                        dispatch(IChatAction.updateCurrentActiveChat(undefined));
                    }}/>
                </MDBox>}
                <MDBox width="40%" ml={1} display="flex" alignItems="center" pl={1} pr={1} pb={1} pt={.5} sx={{cursor : 'pointer'}}>
                    <MDBox mr={2}>
                        <Avatar src={currentActiveChat?.profilePic} alt={currentActiveChat?.name} shadow="md" />
                    </MDBox>
                    <MDBox
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        justifyContent="center"
                    >
                        <MDTypography variant="button" fontWeight="medium">
                        {currentActiveChat?.name}
                        </MDTypography>
                        {currentActiveChat?.description && <MDTypography sx={{marginTop : '4px',height:'16px',overflow:'hidden',textOverflow:'ellipsis'}} variant="caption" color="text">
                            {currentActiveChat.description}
                        </MDTypography>}    
                    </MDBox>
                </MDBox>
                <MDBox mr={2} pl={1} pr={1} pb={1} pt={.5} display="flex" alignItems="center" height="100%" justifyContent="flex-start">
                    <MDBox display="flex" justifyContent="flex-start" alignItems="center">
                        <Tooltip title="Search chat"><Search onClick={OnSearchOpen} className="navbar-icon" sx={{height: "24px",width:"24px",marginRight:"8px",cursor:"pointer"}}/></Tooltip>
                        {/* <Tooltip title="Menu"><MoreVert className="navbar-icon" sx={{height: "24px",width:"24px",cursor:"pointer"}}/></Tooltip> */}
                    </MDBox>
                </MDBox>
            </MDBox>
        </Navbar>
    )
}
export default React.memo(MiddleNavBar);