import { MEDIA_TYPE } from "constants/ChatConstants";

export const getAsDataUrl = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};

export const isMediaSizeValid = async (dataSourceUrl, size) => {
  const response = await fetch(dataSourceUrl);
  const blob = await response.blob();

  const maxSize = size * 1024 * 1024; // 5MB in bytes
  const fileSize = blob.size;
  if (fileSize > maxSize) {
    return false;
  }
  else return true;
}
export const isValidSize = (file, size) => {

  const fileSize = file.size;
  const maxSize = size * 1024 * 1024;
  if (fileSize > maxSize) {
    return false;
  }
  else return true;
}

export const getBlobFromUrl = async (dataSourceUrl) => {
  const response = await fetch(dataSourceUrl);
  const blob = await response.blob();
  return blob;
}

export const getMediaType = async (url) => {
  // if(url instanceof File){
  //   url = URL.createObjectURL(url);
  // }
  console.log({url})
  if (url && url.indexOf('blob:') === 0) {
    try {
      // Fetch the Blob data
      const response = await fetch(url);
      const blob = await response.blob();
      const fileType = blob.type;
      return getFileType(fileType);
    } catch (error) {
      console.error('Error fetching file type:', error);
      return MEDIA_TYPE.UNKNOWN;
    }
  }
  else {
    const ext = getFileExtension(url);
    return getFileTypeFromUrl(ext);
  }
};

export const getFileTypeFromUrl = (ext) => {
  ext = ext.toLowerCase();
  const audioMimeTypes = ['mpeg', 'ogg', 'wav', 'webm', 'mp3', 'mpga'];
  const imageMimeTypes = ['jpeg', 'jpg', 'png', 'gif', 'webp'];
  const videoMimeTypes = ['mp4', 'webm', 'avi'];
  const docsMimeTypes = ['pdf', 'xls', 'txt', 'bmp', 'doc', 'docx', 'htm', 'html', 'ppt', 'pptx', 'tiff', 'txt', 'xls', 'xlsx']
  const vcfTypes = ['vcf']
  if (audioMimeTypes.includes(ext)) {
    return MEDIA_TYPE.AUDIO;
  } else if (imageMimeTypes.includes(ext)) {
    return MEDIA_TYPE.IMAGE;
  } else if (videoMimeTypes.includes(ext)) {
    return MEDIA_TYPE.VIDEO;
  }
  else if (vcfTypes.includes(ext)) {
    return MEDIA_TYPE.VCF;
  }
  else if (docsMimeTypes.includes(ext)) {
    return MEDIA_TYPE.DOCS;
  }
  else {
    return MEDIA_TYPE.UNKNOWN;
  }
}

export const getFileType = (fileType) => {
  // Define MIME types for common media types
  const audioMimeTypes = ['audio/mpeg', 'audio/ogg', 'audio/wav', 'audio/webm', 'audio/mp3'];
  const imageMimeTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/webp'];
  const videoMimeTypes = ['video/mp4', 'video/webm', 'video/avi', 'video/mkv'];
  const docsMimeTypes = ['application/pdf',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // XLSX
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DOCX
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PPTX
    'application/rtf',
    'application/json', // JSON files can be considered as structured documents
    'text/plain']
  const vsfMimeType = ['text/x-vcard']
  // Check if the file type matches any of the lists
  if (audioMimeTypes.includes(fileType)) {
    return MEDIA_TYPE.AUDIO;
  } else if (imageMimeTypes.includes(fileType)) {
    return MEDIA_TYPE.IMAGE;
  } else if (videoMimeTypes.includes(fileType)) {
    return MEDIA_TYPE.VIDEO;
  } else if (docsMimeTypes.includes(fileType)) {
    return MEDIA_TYPE.DOCS;
  } else if (vsfMimeType.includes(fileType)) {
    return MEDIA_TYPE.VCF;
  }
  else {
    return MEDIA_TYPE.UNKNOWN;
  }
}
export const getFileExtension = (url) => {
  const parts = url.split('.');
  return parts[parts.length - 1];
};
export const playSound = (url = "https://app--media.s3.amazonaws.com/system_default_sounds/message_sent.mp3") => {
  try {
    const audio = new Audio(url);
    audio.load()
    audio.play()
  } catch (e) {
    console.log(e)
  }
}