import { Avatar, Button, Chip, IconButton, InputAdornment, InputBase, Stack, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { useCallback, useEffect, useState } from "react";
import { ChatAPI } from "services/IChat/ChatAPI";
import { useDispatch, useSelector } from "react-redux";
import { Send } from "@mui/icons-material";
import IChatItem from "./IChatItem";
import MDTypography from "lib/components/MDTypography";
import { MessageAPI } from "services/IChat/MessageAPI";
import { IChatAction } from "store/IChat/Ichat-slice";
import { toast } from "react-toastify";

const MessageForward = ({message,setForwardModal,isTelecommunication = false}) => {

    const dispatch = useDispatch();

    const [selectedChats,setSelectedChats] = useState([]);
    const currUser = useSelector(state => state.user);

    const [allChats,setAllChats] = useState([]);

    const onSelected = (res) => {
        if(res.checked) {
            setSelectedChats(prev => [...prev,res.chat]);
        }
        else{
            const newSelected = selectedChats.filter(itm => itm.chatId !== res.chat.chatId);
            setSelectedChats(newSelected)
        }
    }

    const forwardMessage = useCallback(() => {
        const chatIds = selectedChats.map(itm => itm.chatId);
        const payload = {
            message : {...message,chatId : message?.chat?.chatId || message?.chatId},
            forwardedBy : currUser.id,
            forwardTo : chatIds,
            isTelecommunication : isTelecommunication
        }
        if(isTelecommunication) {
            const newMessagePayload = {
                text : message.message,
                messageId : message.id
            }
            payload.message = newMessagePayload;
        }
        MessageAPI.forwardMessage({token : currUser.token,payload}).then(res => {
            setForwardModal(false);
            toast.success("Forwarded.");
            const forwardedList = res.data;
            for(let i = 0; i < forwardedList.length; i++) {
                if(forwardedList[i].groupChat) {
                }
                else{
                    dispatch(IChatAction.addForwardedMessage({message: forwardedList[i]}));
                }
            }
        }).catch(e => console.log(e));
    },[message,currUser,selectedChats,isTelecommunication])

    // useEffect(() => {
    //     ChatAPI.getMyCompanyUsersExceptMe({token : currUser.token, userId : currUser.id,companyId : currUser.company.companyId})
    //         .then(res => setUsers(res.data)).catch(e => toast.error(e))
    // },[]);

    useEffect(() => {
        ChatAPI.getAllChat({token : currUser.token, userId : currUser.id}).then(res => {
            setAllChats(res.data);
        }).catch(e => console.log(e));
    },[]);

    return(
        <>
            <MDBox display="flex" flexDirection="column">
            {selectedChats?.length > 0 && <Typography fontSize={"14px"} sx={{marginBottom:'4px'}} color={"blue"}>{"Sending to"}</Typography>}
                <Stack direction="row" spacing={1} flexWrap={"wrap"}>
                    {selectedChats && 
                        selectedChats.map(chip => {
                            return(
                                <Chip
                                    key={"chip_" + chip?.chatId}
                                    avatar={<Avatar alt={chip.name} src={chip.profilePic} />}
                                    label={chip.name}
                                    variant="outlined"
                                    // onDelete={() => removeUser(chip)}
                                    sx={{margin:'4px'}}
                                />
                            )
                        })
                    }
                </Stack> 
                <MDBox sx={{height:'400px',overflow:'auto'}}>
                    {allChats.map(c => <IChatItem key={c.chatId} onSelected={onSelected} chat={c}/>)}  
                </MDBox> 
                <MDBox display="flex" alignItems="center" sx={{backgroundColor:'#f2f2f2',padding:'8px',marginBottom:'4px',height:'34px',overflow:'hidden'}}>
                    <MDTypography variant={"caption"} sx={{fontWeight:600,width:'20%',marginRight:'2px'}}>{"Message :"}</MDTypography>
                    <MDTypography variant="caption" sx={{height:'100%',textOverflow:'ellipsis',overflow:'hidden',whiteSpace: 'nowrap',width:'80%'}} fontSize={"14px"}>&nbsp;{ isTelecommunication ? message.message : message.text }</MDTypography>
                </MDBox>
                <MDBox display="flex" justifyContent="flex-end">
                    <Button
                        onClick={forwardMessage}
                        disabled={selectedChats.length === 0}
                        sx={{
                            borderRadius: "20px",
                            color: "white!important",
                            border: "unset",
                            width: "90px",
                            padding: "10px",
                            height: "35px",
                            backgroundColor: "#1876F2!important",
                        }}
                        variant="contained"
                        size="medium"
                        endIcon={<Send/>}
                    >
                        Send
                    </Button>
                </MDBox>
            </MDBox>
        </>
    )
}
export default MessageForward;