import { useEffect, useState, useCallback, memo } from "react";
import AppModal from "ui/AppModal";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import useHttp from "hooks/use-http";
import { createCampaignMsg } from "api/campaign";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import AppConstants from "constants/AppConstants";

const CampaignMessage = ({ onChange, campaignId, onMessageCreated }) => {

  const [action, setAction] = useState(1);
  const token = useSelector(state => state.user.token);
  const [messageFormValidation, setMessageFormValidaiton] = useState({
    title : '',
    percentage : '',
    message : ''
  });
  const [newMessage, setNewMessage] = useState({
    title : '',
    percentage : '',
    message : ''
  })

  const {sendRequest : createMsgFn, status, data, error} = useHttp(createCampaignMsg,false);

  const onMessageSubmit = useCallback(() => {
    console.log("Pyaar hai...")
    if(newMessage.title.trim() === 0 || newMessage.title === '' || newMessage.title.length >= 20){
      setMessageFormValidaiton(prev => {
        return{
          ...prev,
          title : "Title can not be empty."
        }
      });
      return;
    }
    if(newMessage.percentage === '' || !(AppConstants.TWO_DIGIT_REGEX).test(newMessage.percentage)){
      setMessageFormValidaiton(prev => {
        return{
          ...prev,
          percentage : "Enter a valid value."
        }
      });
      return;
    } 
    if(newMessage.message.trim() === 0 || newMessage.message === '' || newMessage.message.length > 200){
      setMessageFormValidaiton(prev => {
        return{
          ...prev,
          message : "Message can not be empty or more than 200 characters."
        }
      });
      return;
    }    
    /// Everything fine continue with message creation.... 
    const msgPayload = {
      token,
      campaign_id : campaignId,
      payload : newMessage
    }   
    createMsgFn(msgPayload);
  },[newMessage,createMsgFn])

  useEffect(() => {
    if (action === -1) {
      onChange();
    }
  }, [action]);

  useEffect(() => {
    if(status === 'completed'){
      if(data){
        onMessageCreated(data);
        setAction(-1);
      }
      else{
        toast.error("Something went wrong.");
      }
    }
  },[status, data, error])
  /*** Response use effects.. */
  return (
    <MDBox>
      {action === 1 && (
        <AppModal
          rootStyle = {
            {
              "&.MuiModal-root":{
                width:'30%',
                left: '70%',
                height:'100%',
                overflow: 'hidden'
              }
            }
          }
          customStyle={{ 
            width: "100%",
            height: "100%",
          }}          
          onModalClose={() => {
            setAction(-1);
          }}
          heading={"Message"}
        >
          <>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox mt={2} display="flex" flexDirection="column">
                <MDInput
                  type="text"
                  label="Title"
                  variant="standard"
                  placeholder="Enter a unique title"
                  fullWidth
                  onChange={(e) => {
                    setNewMessage(prev => {
                      return {
                        ...prev,
                        title : e.target.value
                      }
                    })
                  }}
                  value={newMessage?.title}
                />
                <MDTypography
                  variant="overline"
                  fontWeight="medium"
                  color="error"
                >
                  {messageFormValidation.title}
                </MDTypography>                  
              </MDBox>
              <MDBox mt={2} display="flex" flexDirection="column">
                <MDInput
                  type="text"
                  label="Goal percentage"
                  variant="standard"
                  placeholder="Enter a percentage value"
                  fullWidth
                  onChange={(e) => {
                    setNewMessage(prev => {
                      return {
                        ...prev,
                        percentage : e.target.value
                      }
                    })
                    if(Number(e.target.value) === 100){
                      setNewMessage(prev => {
                        return {
                          ...prev,
                          message : "Congratulations! You’ve helped us reach our goal! We have now added a Bonus Goal of ${Bonus amount}. Keep it up!"
                        }
                      })
                    }
                  }}
                  value={newMessage?.percentage}                      
                />
                <MDTypography
                  variant="overline"
                  fontWeight="medium"
                  color="error"
                >
                  {messageFormValidation?.percentage}
                </MDTypography>
              </MDBox>
              <MDBox mt={2} display="flex" flexDirection="column">
                <MDInput
                  type="text"
                  label="Message"
                  variant="standard"
                  fullWidth
                  multiline
                  sx={{maxHeight:'200px',overflow:'auto'}}
                  placeholder="Enter a  custom message"
                  onChange={(e) => {
                    setNewMessage(prev => {
                      return {
                        ...prev,
                        message : e.target.value
                      }
                    })
                  }}
                  value={newMessage?.message}                      
                />
                <MDTypography
                  variant="overline"
                  fontWeight="medium"
                  color="error"
                  sx={{lineHeight:'1.8'}}
                >
                  {messageFormValidation.message}
                </MDTypography>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={onMessageSubmit}
                >
                  Create Message
                </MDButton>
              </MDBox>
            </MDBox>
          </>
        </AppModal>
      )}
    </MDBox>
  );
};
export default memo(CampaignMessage);
