import { BASE_URL } from "constants/Endpoints";
import axios from "../core/axios";
import fetch from "core/fetch";

export const uploadZip = async(uploadData) => {

    const response = await fetch(`${BASE_URL}/chat/import`,{
        method : 'POST',
        body : uploadData.formData,
        headers: {
            // 'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + uploadData.token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const ImportChatAPI = {
    processMappedData : async({payload,token}) => {
        return await axios.post(`${BASE_URL}/chat/import/mapped`,JSON.stringify(payload),{
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + token,
            },
        })
    },
    getImportHistory : async({token}) => {
        return await axios.get(`${BASE_URL}/chat/import/history`,{
            headers: {
                'Authorization' : 'Bearer ' + token,
            },
        })
    }, 
    undoImport : async({token,importId}) => {
        return await axios.get(`${BASE_URL}/chat/import/history/undo/${importId}`,{
            headers: {
                'Authorization' : 'Bearer ' + token,
            },
        })
    }
} 