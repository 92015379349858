import { Tooltip } from "@mui/material";
import { TELE_MESSAGE_STATUS } from "constants/ChatConstants";
import { memo } from "react";
import ScheduleIcon from '@mui/icons-material/Schedule';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import DraftsIcon from '@mui/icons-material/Drafts';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { Cancel, WhatsApp } from "@mui/icons-material";
import PendingIcon from '@mui/icons-material/Pending';
import { useCallback } from "react";
import { getDateConvertedFromUTC } from "util/UtilityFunctions";
import MDBox from "lib/components/MDBox";
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from "react-redux";
import { ConversationAPI } from "services/ConversationAPI";
import { groupAction } from "store/group-slice";

const TeleMessageStatus = ({currMessage,setCurrMessage,setPendingRecipientModal}) => {

    const dispatch = useDispatch();

    const token = useSelector(state => state.user.token);
    
    const scheduleHandler = useCallback(() => {
        if(currMessage?.groupId != 0){
          Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to cancel this scheduled message?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes! Delete it.',
            cancelButtonText: 'No. Keep it scheduled.'
          }).then((result) => {
            if (result.isConfirmed) {
              ConversationAPI.unscheduleMessage({token,messageId: currMessage?.id}).then(res => {
                //remove message
                if(currMessage.groupId != 0){
                  dispatch(groupAction.deleteMessage({msgId : currMessage?.id, groupId : currMessage.groupId}));
                }
              }).catch(e => console.log(e));
            }
          })
        }
    },[currMessage])

    const messageStatus = useCallback((m) => { 
        switch(m?.status) {
          case TELE_MESSAGE_STATUS.scheduled :
            return <>
              {m.groupId === 0 ? 
                <Tooltip title={`This message is scheduled for ${getDateConvertedFromUTC(currMessage?.scheduledAt).format('lll')}`}>
                  <MDBox sx={{marginLeft: "4px", fontSize: "14px",cursor: "pointer"}}><ScheduleIcon size="small" /></MDBox>
                </Tooltip> :
                <Tooltip title={`This message is scheduled for ${getDateConvertedFromUTC(currMessage?.scheduledAt).format('lll')}. Click to cancel it.`}>
                  <MDBox sx={{marginLeft: "4px", fontSize: "14px",cursor: "pointer"}}>
                    <CancelScheduleSendIcon onClick={scheduleHandler} size="small" sx={{ marginLeft: "8px" }}/>
                  </MDBox>
                </Tooltip>        
              }
            </>;
          
          case TELE_MESSAGE_STATUS.failed : 
            return <>
              <Tooltip title={`Undelivered`}>
                <MDBox sx={{marginLeft: "4px",fontSize: "18px",color: "#d68686",cursor: "pointer",}}>
                  <ErrorIcon sx={{height:'1.2em',width:'1.2em'}} size="small" />
                </MDBox>
              </Tooltip>
            </>;
  
          case TELE_MESSAGE_STATUS.sending :
            if(m.type === 'SMS'){
              return<>
                <Tooltip title={`Sending`}>
                  <MDBox sx={{marginLeft: "4px",fontSize: "14px",color: "black", cursor: "pointer"}}>
                    <DoneIcon size="small" />
                  </MDBox>
                </Tooltip>
              </>
            }
            else if(m.type === 'WHATSAPP'){
              return <>
                <Tooltip title={`Sending`}>
                  <MDBox sx={{marginLeft: "4px",fontSize: "14px",color: "green",cursor: "pointer"}}>
                    <WhatsApp size="small" />
                    <DoneIcon sx={{color:'gray'}} size="small" />
                  </MDBox>
                </Tooltip> 
              </>;
            } 
            break;
          
          case TELE_MESSAGE_STATUS.sent : 
            if(m.type === 'SMS') {
              return <>
                  <Tooltip title={`Delivered via sms`}>
                    <MDBox sx={{marginLeft: "4px", fontSize: "14px",color: "black",cursor: "pointer"}}>
                      <DoneAllIcon size="small" />
                    </MDBox>
                  </Tooltip>
                </>
            }
            else if(m.type === 'WHATSAPP' && m.deliveredThroughWA) {
              return <>
                  <Tooltip title={m?.whatsappRead ? "Read on whatsapp" : "Delivered via WhatsApp"}>
                    <MDBox sx={{marginLeft: "4px",fontSize: "14px",color: "green",cursor: "pointer"}}>
                      <WhatsApp size="small" />
                      <DoneAllIcon sx={{color : m?.whatsappRead ? 'green' : 'gray'}} size="small" />
                    </MDBox>
                  </Tooltip>
              </>
            }
            break;
          case TELE_MESSAGE_STATUS.queued : 
          return <>
              <Tooltip title={`Message will be sent as soon as the recipient opts-in for HIPAA`}>
                <MDBox sx={{marginLeft: "4px",fontSize: "14px",color: "#d68686",cursor: "pointer"}}>
                  <DraftsIcon sx={{ color: "orange" }} size="small" />
                </MDBox>
              </Tooltip>
            </>;
          case TELE_MESSAGE_STATUS.partial : 
          return <>
                  <Tooltip title={`Sent as per area codes, partially delivered. Click to view the remaining recipients.`}>
                    <MDBox onClick={() => setPendingRecipientModal(true)}
                      sx={{
                        marginLeft: "4px",
                        fontSize: "14px",
                        color: "#d68686",
                        cursor: "pointer",
                      }}
                    >
                      <PendingIcon sx={{ color: "orange" }} size="small" />
                    </MDBox>
                  </Tooltip>
                </>;
  
          case TELE_MESSAGE_STATUS.scheduled_cancelled : 
          return <>
                  <Tooltip title={`Scheduled Cancelled`}>
                    <MDBox sx={{marginLeft: "4px",fontSize: "14px",color: "#d68686",cursor: "pointer",}}>
                      <Cancel size="small" />
                    </MDBox>
                  </Tooltip>
                </>
           default : 
            return <></>     
        }
    },[currMessage]);

      return(
        <>
            {messageStatus(currMessage)}
        </>
      )
}
export default memo(TeleMessageStatus);