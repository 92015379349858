import LockedAccountList from "components/Accounts/LockedAccountList";
import useHttp from "hooks/use-http";
import DashboardLayout from "lib/layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "lib/layouts/Navbars/DashboardNavbar";
import { useCallback, useEffect, useState } from "react";
import {getLockedUsers} from "api/user";
import { useSelector } from "react-redux";

const LockedAccounts = () => {
    const token = useSelector(state => state.user.token);
    const company = useSelector(state => state.user.company);
    const userId = useSelector(state => state.user.id);
    const [lockedUsers, setLockedUsers] = useState([]);
    const {sendRequest : getLockedUsersFn, status : userDataListStatus, data : userList} = useHttp(getLockedUsers,false);
    
    const onUserUnLocked = useCallback((usr) => {
        console.log({usr,userList});
        const newLockedUsers = userList?.filter(item => item.userId !== usr.userId);
        console.log({newLockedUsers})
        setLockedUsers(newLockedUsers);
    },[userList,setLockedUsers])

    const onAllAccountsUnlocked = () => {
        getLockedUsersFn({token,companyId : company?.company_id,userId});
    }

    //// retrieve users
    useEffect(() => {
        if(company?.company_id && userId && token){
            getLockedUsersFn({token,companyId : company?.company_id,userId});
        }
    },[company?.company_id,token,userId,getLockedUsersFn])

    useEffect(() => {
        if(userDataListStatus === 'completed'){
            if(userList){
                setLockedUsers(userList);
            }
        }
    },[userDataListStatus,userList,setLockedUsers]);

    return(
        <DashboardLayout>
            <DashboardNavbar />
            <LockedAccountList onAllAccountsUnlocked={onAllAccountsUnlocked} userData={lockedUsers} onUserUnLocked={onUserUnLocked} />
        </DashboardLayout>
   )
}
export default LockedAccounts;