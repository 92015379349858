import { Popover } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";

const IHover = ({target,onClose,children}) => {

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        setAnchorEl(null);
        onClose();
    }

    useEffect(() => {
        setAnchorEl(target)
    },[target])

    return(
        <>
        <Popover
          onClose={handleClose}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          sx={{
          "& .MuiPopover-paper": {
              backgroundColor: "white!important",
              display:'flex',
              width: '150px',
              justifyContent:'center',
              boxShadow: '2px 2px 6px 0px #ccc'
          },
          }}
          >
            {children}
        </Popover>
      </>
    )
} 
export default IHover;