import { Upload } from "@mui/icons-material";
import { Button} from "@mui/material";
import { BASE_URL } from "constants/Endpoints";
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { appAction } from "store/app-slice";
import WhatsappMediaImport from "./WhatsappMediaImport";

const WhatsappImport = ({totalSteps,onImported,onClose,currentActivePhone,onUploaded}) => {
    
    const dispatch = useDispatch();

    const token = useSelector(state => state.user.token);
    const userId = useSelector(state => state.user.id);
    const companyId = useSelector(state => state.user.company.company_id);

    const [steps,setSteps] = useState(0);
    const [file,setFile] = useState();

    const fileInputChange = e => {
        if (e.target.files && e.target.files[0]) {
            setFile(e.target.files);
        }
    }

    const onFileUploadClick = useCallback((e) => {
        e.preventDefault();
        if(file){
            if(!currentActivePhone.phoneId){
                toast.error("Select phone number for import.");
                return;
            }
            // Run background task
            // Create a new instance of the Web Worker
            const worker = new Worker("/worker/whatsappImportWorker.js");
            dispatch(appAction.setPreventRefresh({
                eventInProgress : true,
                message : 'Import is in progress. Are you sure want to leave?'
            }));
            const  snackBarState = {
                show: true,
                heading: `Processing ${file.length} chats...`,
                type: "info",
                content: <p>Preparing chat import....</p>,
                horizontal: 'center',
                vertical:'top',
                icon : <Upload/>
            };
            dispatch(appAction.setSnackbar(snackBarState))                
            // Define an event listener for messages from the worker
            worker.onmessage = (event) => {
                let d = event.data;
                if(d.phase === 'start') {
                    dispatch(appAction.setSnackbarContent(<p>Started chat processing....</p>))
                }

                else if(d.phase === 'finished') {
                    // Clean up the worker
                    dispatch(appAction.setSnackbar(undefined));
                    worker.terminate();
                    const result = d.data;
                    // when finished ask for media upload, show the reports as well
                    onImported();
                    const msg = result.success_count <= 1 ?`${result.success_count} chat processed.` : `${result.success_count} chats processed.` 
                    // show reports...
                    let contnt = {
                        show: true,
                        heading: msg,
                        type: "success",
                        content: <MDBox display="flex">
                        <a style={{cursor:'pointer',color:'white'}} onClick={() => {
                            dispatch(appAction.setChatDynamicComponent(<WhatsappMediaImport result={result}/>))
                            dispatch(appAction.setSnackbar(undefined));  
                        }}><Button variant="contained" fontSize={'16px'} color={"white"}>{"Next"}</Button></a>
                        </MDBox>,
                        horizontal: 'center',
                        vertical : 'top'
                    };
                    dispatch(appAction.setSnackbar(contnt));                
                }
                else if(d.phase === 'prepare_failed'){
                    toast.error(d.data);
                    dispatch(appAction.setSnackbar(undefined));  
                }
                else if(d.phase === 'error'){
                    toast.error(d.data);
                    dispatch(appAction.setSnackbar(undefined));  
                }
                else if(d.phase === "processing"){
                    dispatch(appAction.setSnackbarContent(<p>{d.data} Chats processed</p>))
                }

            };                
            
            // Send a message to the worker to start the file upload
            worker.postMessage({file,userId,phoneId : currentActivePhone.phoneId ,token,base_url : BASE_URL,companyId});
            onUploaded();
        }
        // onFileAdded();
    },[file,userId,token,companyId,currentActivePhone]);    
    
    return(
        <MDBox>
            <MDTypography variant={"h4"} sx={{textAlign:'center'}}>{`Step ${steps + 1} of ${totalSteps} `}</MDTypography>
            <input multiple style={{border:'2px solid #ddd',width:'100%', maxWidth:'300px'}} accept="text/html" type="file" label="" onChange={fileInputChange} />
            {file && 
                <MDBox style={{marginTop:'12px'}} display="flex" alignItems="center" justifyContent="center">
                    <Button 
                        onClick={onFileUploadClick} 
                        variant="contained"
                        sx={{
                            borderRadius: "0px",
                            color: "white!important",
                            border: "unset",
                            marginLeft: "12px",
                            height: "32px",
                            minHeight: "unset",
                        }}
                    >
                        upload
                    </Button>
                </MDBox>
            } 
        </MDBox>
    )

}
export default WhatsappImport;