import { Button, Grid, Paper, TextField, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { getVariablesCount } from "util/WhatsTemplateUtil";
import ChooseFileGeneric from "components/CommonUI/ChooseFileGeneric";
import { createWTemplate } from "api/whatsapp";
import { useDispatch, useSelector } from "react-redux";
import useHttp from "hooks/use-http";
import { toast } from "react-toastify";
import Preview from "./Preview";
import { amountOfCreditToBeUsed } from "util/ChatUtil";
import { sendTemplateMessage } from "api/whatsapp";
import { getAsDataUrl } from "util/MediaUtil";
import { isValidSize } from "util/MediaUtil";
import { messageAction } from "store/message-slice";
import { chatAction } from "store/chat-slice";

const SendTemplate = ({templateData,onTemplateSend}) => {

    const dispatch = useDispatch();

    const currUserDetails = useSelector(state => state?.userDetails?.details);
    const currentActivePhone = useSelector(state => state.chat.current_active_phone);
    const currentActiveRecipient = useSelector(state => state.chat.current_active_recipient);
    const currentMessageType = useSelector(state => state.chat.current_message_view);
    const [currTemplate, setCurrentTemplate] = useState();
    const token = useSelector(state => state.user.token);
    const [variables, setVariables] = useState({
        body : [],
        header : [],
    })
    
    const {sendRequest : sendWTemplateMessageFn,status, data , error} = useHttp(sendTemplateMessage,false);

    const handleTemplateSubmission = () => {
        const msgPayload = {
            tempId : uuidv4(),
            sendTo: {
              id: currentActiveRecipient.id,
            },
            sendByPhone: {
              phoneId: currentActivePhone?.phoneId,
            },
            sendByUser: {
              userId: currUserDetails.userId,
              fullName: currUserDetails.fullName,
              profilePic: currUserDetails.profilePic,
            },
            mediumUsed: "APP",
            tags: [],
            creditUsed: amountOfCreditToBeUsed(templateData.templateName),
            message: templateData.templateName,
            createdAt: new Date(),
            inbound: false,
            wMessageType : 'text',
            status: "sending",
            timeZone: "UTC",
            type : "WHATSAPP",
            templateId : templateData.id,
            templateParameters :  variables,
            templateName : templateData.templateName,
            wMessageType : 'template'
        };
        // Append to last
        // try{
        //     dispatch(messageAction.addConversation({message : msgPayload,recipientId : msgPayload.sendTo.id}));
        //     dispatch(chatAction.updateLatestMessage({message : msgPayload, recipientId : msgPayload.sendTo.id}));
        // }catch{}
        sendWTemplateMessageFn({payload : msgPayload,token});
    }

    const handleHeaderFile = (file) => {
        const isValid = isValidSize(file,4.5);
        if(!isValid){
            toast.error("The file can't be uploaded. Maximum allowed file size is 4.5MB");
            return;
        }
        if(["image","docs","video"].includes(currTemplate.header.type)){
            const header = Array.isArray(variables.header) ? variables.header : [];
            if(currTemplate.header.type === 'image' || currTemplate.header.type === 'docs'){
                getAsDataUrl(file).then(url => {
                   header[0] = {file : url,type:currTemplate.header.type};
                
                   setVariables(prev => {
                   
                        return{
                
                            ...prev,
                           header : header
                        }
                    })
                })
            }
            else if(currTemplate.header.type === 'video'){
                header[0] = {file : URL.createObjectURL(file),type:currTemplate.header.type};
                setVariables(prev => {
                    return{
                       ...prev,
                       header : header
                    }
                })
            }
        }
        
       setCurrentTemplate(prev => {
        return{
            ...prev,
            header : {
                ...prev.header,
                value : file
            }
        }
       })
    }

    const handleBody = (idx,e) => {
        const newBody = [...variables.body];
        newBody[idx].value = e.target.value;

        setVariables(prev => {
            return {
                ...prev,
                body : newBody
            }
        })
    }
    const handleHeader = (idx,e) => {

        const newHeader = [...variables.header];
        newHeader[idx].value = e.target.value;

        setVariables(prev => {
            return {
                ...prev,
                header : newHeader
            }
        })

    }

    useEffect(() => {

        const txt = JSON.parse(templateData.originalTemplate);

        let newText = txt?.body;

        for(const entry of variables.body){
            newText = newText.replace(entry.key,entry.value);
        }
        setCurrentTemplate(prev => {
            return{
                ...prev,
                body : newText
            }
        });
    },[variables.body])

    useEffect(() => {
        const txt = JSON.parse(templateData.originalTemplate);
        let newText = txt?.header?.value;

        for(const entry of variables?.header){
            newText = newText.replace(entry.key,entry.value);
        }
        setCurrentTemplate(prev => {
            return{
                ...prev,
                header : {
                    ...prev.header,
                    value : newText
                }
            }
        });
    },[variables.header])    

    useEffect(() => {
        if(templateData.originalTemplate){
            const parsedTemplate = JSON.parse(templateData.originalTemplate);
            console.log(parsedTemplate)
            const ans = getVariablesCount(parsedTemplate);
            setVariables(ans);
            setCurrentTemplate(parsedTemplate)
        }
    },[templateData]);

    useEffect(() => {
        if(status === 'completed'){
          if(data){
            try{
                dispatch(messageAction.addConversation({message : data,recipientId : data.sendTo.id}));
                dispatch(chatAction.updateLatestMessage({message : data, recipientId : data.sendTo.id}));
            }catch{}
            onTemplateSend(data);
          }
          else{
            toast.error("Something went wrong. Please try again.");
          }
        }
    },[status, data, error])

    return (
      <>
        <MDBox display="flex" flexDirection="column">
          <MDBox
            display="flex"
            justifyContent="start"
            flexDirection="column"
            padding="8px"
          >
            <Typography>Please fill the required parameters if any.</Typography>
            <Typography sx={{ fontSize: "12px" }}>
              {'Replace {{}} parameters with actual value.' }
            </Typography>
          </MDBox>
          <MDBox display="flex">
            <MDBox width="65%" display="flex" flexDirection="column">
                {
                    variables?.header && currTemplate &&  currTemplate?.header?.type === 'text' &&
                    <MDBox>
                        {variables?.header?.map((item,index) => {
                            return <MDBox key={"header_"+index} mt={2} display="flex" flexDirection="column">
                                <Typography fontSize={"14px"}>Header {item.key}</Typography>
                                <TextField variant="standard" onChange={handleHeader.bind(null,index)} />
                            </MDBox>
                        })}                       
                    </MDBox>
                }
                {
                    currTemplate && (["image","docs","video"].includes(currTemplate?.header?.type)) &&
                    <MDBox>
                        <Typography fontSize={"12px"}>Choose {currTemplate?.header?.type}</Typography>
                        <ChooseFileGeneric onFileSelected={handleHeaderFile}/>
                    </MDBox>
                }
                {variables.body &&
                    <MDBox mt={2}>
                        {variables.body.map((item,index) => {
                            return <MDBox key={"body_"+index} mt={2} display="flex" flexDirection="column">
                                <Typography fontSize={"14px"}>Body {item.key}</Typography>
                                <TextField variant="standard" onChange={handleBody.bind(null,index)} />
                            </MDBox>
                        })}
                    </MDBox>
                }             
            </MDBox>

            { currTemplate && <Preview currTemplate={currTemplate}/>}
          </MDBox>
          <MDBox display="flex" width="100%" justifyContent="flex-end">
            <MDBox display="flex">
              <Button
                onClick={handleTemplateSubmission}
                variant="contained"
                sx={{
                  borderRadius: "0px",
                  color: "white!important",
                  border: "unset",
                  marginLeft: "12px",
                  height: "32px",
                  minHeight: "unset",
                }}
              >
                Send
              </Button>
            </MDBox>
          </MDBox>
        </MDBox>
      </>
    );
}
export default SendTemplate;