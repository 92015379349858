import { setActivePhone } from "api/onlineStatus";
import { removeActivePhone } from "api/onlineStatus";
import { unscheduleEmailNotifications } from "api/settings";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useHttp from "./use-http";
import { chatAction } from "store/chat-slice";
import { useCallback } from "react";
import { USER_PHONE_QUEUE } from "constants/WsConstants";
const header = {
  // 'auto-delete': true,
  // 'durable' : false
  // "x-ha-queues" : true,
  ack: 'client'
};
const usePhoneStatus = (stompClient,onMessageReceiveEvent,onCurrentReplyEvent) => {
    const dispatch = useDispatch();
    const { sendRequest: removeActivePhoneStatusFn } = useHttp(removeActivePhone,false,false);
    const { sendRequest: setActivePhoneStatusFn } = useHttp(setActivePhone,false,false);
    const { sendRequest: unscheduleEmailNotificationsFn } = useHttp(unscheduleEmailNotifications,false,false);

    const currentActivePhone = useSelector(state => state.chat.current_active_phone);
    const token = useSelector(state => state.user.token);
    const currentUser = useSelector(state => state.userDetails.details);
    const currentActiveRecipient = useSelector(state => state.chat.current_active_recipient);
    
    const buildStatusPayload = useCallback(() => {
      return {
        user: {
          userId: currentUser?.userId,
          fullName: currentUser?.fullName,
          profilePic: currentUser?.profilePic,
        },
        recipientId: currentActiveRecipient?.id,
        phoneId: currentActivePhone?.phoneId,
        token: token,
        replying: true,
      };
    },[currentActivePhone,currentActiveRecipient,currentUser]);
  
    useEffect(() => {
      if (currentActivePhone && currentUser) {
        dispatch(chatAction.setCurrentActivePhone(currentActivePhone));
        const prevPhoneId = currentActivePhone.phoneId;
        setActivePhoneStatusFn({ token, phoneId: currentActivePhone.phoneId });
        //unschedule emais and unqueue the stale message for this user and phone
        unscheduleEmailNotificationsFn({phone: currentActivePhone.phone,token,userId : currentUser.userId});
        return () => {
          removeActivePhoneStatusFn({ token, phoneId: prevPhoneId });
        };
      }
    }, [currentActivePhone,currentUser]);

    //subscribe to all the numbers, available for logged in user
    useEffect(() => {
      const allSubcriptions = [];
      if(currentUser?.phones && stompClient) {
        for(const p of currentUser.phones) {
          try{
            const s = stompClient?.subscribe(`${USER_PHONE_QUEUE(currentUser.userId,p.phone)}`,onMessageReceiveEvent,{...header,userId:currentUser?.userId});
            allSubcriptions.push(s);
          }catch(e){
            console.log(e);
          }
        }
      }
      return () => {
        for(const ts of allSubcriptions) {
          ts?.unsubscribe();
        }
      }
    },[currentUser?.phones,stompClient]);

  // SUBSCRIBE FOR ACTIVE PHONE
    useEffect(() => {

      let realTimeTyping;
      if (stompClient && currentActivePhone && currentActiveRecipient) {    
        /// Subscribe for any user repliyng for the selected chat
        try{
          realTimeTyping = stompClient?.subscribe(`/topic/${currentActivePhone.phoneId}.${currentActiveRecipient?.id}`,onCurrentReplyEvent);
        }
        catch(e){console.log(e)};
      }
      return () => {
        if(realTimeTyping){
          const payload = buildStatusPayload();
          payload.replying = false;
          try{
            stompClient?.send(`/app/${currentActivePhone.phoneId}.${currentActiveRecipient?.id}`,{}, JSON.stringify(payload))
          }
          catch(e){}
          realTimeTyping?.unsubscribe();
        }
      };
    },[currentActivePhone, stompClient,currentActiveRecipient])    
}
export default usePhoneStatus;