import { Divider, Paper, Typography } from "@mui/material";
import { getPoolNumberDetails } from "api/numberpool";
import PhoneHistory from "components/PhoneHistory/PhoneHistory";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const Details = ({data,phone}) => {
    return (
      <>
        <MDBox mx={2} p={2}>
          <MDBox mb={3}>
            <Typography variant="h4">Current details</Typography>
          </MDBox>
          <MDBox>
            <Typography>
              Number :{" "}
              <Typography variant="subtitle1" sx={{display:'inline-block'}}>
                {data?.phones?.phone}
              </Typography>
            </Typography>
          </MDBox>          
          <MDBox>
            <Typography>
              Company :{" "}
              <Typography variant="subtitle1" sx={{display:'inline-block'}}>
                {data?.company?.company_name}
              </Typography>
            </Typography>
          </MDBox>
          <MDBox>
            <Typography>
              Fundraiser :{" "}
              <Typography variant="subtitle1" sx={{display:'inline-block'}}>{data?.fundraiser_name}</Typography>
            </Typography>
          </MDBox>
          <Divider variant="middle" />
        </MDBox>
      </>
    );
  };
  
  const NumberPoolDetails = ({phone}) => {
    
    const token = useSelector(state => state.user.token);
    
    const {sendRequest : getDetailsFn, status, data, error} = useHttp(getPoolNumberDetails,false);
    
    useEffect(() => {
      getDetailsFn({phoneId : phone.phoneId,token})
    },[])
    
    if(data){
      return <>
              <Details data={data} phone={phone}/>
              <MDBox mx={2} p={2}>
                <PhoneHistory phone={phone}/>
              </MDBox>
            </>
    }
    else{
      return <>   
                  <MDBox height="50%" display="flex" justifyContent="center" alignItems="center">
                      <Typography>No details found</Typography>
                  </MDBox>
                  <MDBox mx={2} p={2}>
                    <PhoneHistory phone={phone}/>
                  </MDBox>
                </>
    }
}
export default NumberPoolDetails