export const getVariablesCount = (input) => {
    const regex = /\{\{([^}]+)\}\}/g;
    const allVals = {
        header : [],
        body : []
    }
    for (const [variable, value] of Object.entries(input)) {   
        switch(variable){
            case "header" :
                if(value.type === 'text'){
                    const matches = value.value.match(regex);
                    allVals.header = formatMatchedVariables(matches);
                }
                break;

            case "body" :
                const matches1 = value.match(regex);
                allVals.body = formatMatchedVariables(matches1);;
                break;
                
            default:
                break;
        }
    }
    return allVals;
}

const formatMatchedVariables = (input) => {
    const ans = [];
    for(const val in input){
        const tmp = {
            key : input[val],
            value : input[val]
        }
        ans.push(tmp);
    }
    return ans;
} 