import { adminPhoneColumns } from "constants/Columns";
import Table from "ui/Table";
import AssignedDetails from "./AssignedDetails";
import PhoneActions from "./PhoneActions";
import { useEffect, useState } from "react";

const AdminPhoneCredentialsList = ({data,onPhoneAssigned,onDisaUpdated}) => {
    // const rows = [
    //     {
    //         phone: "Test",
    //         apikey : "zxtHJGIG@@@E@",
    //         assigned : "gulshan@gmail.com",
    //         action : <UserAssignment data={[]}/>
    //     }
    // ]
    const [rows,setRows] = useState([]);

    useEffect(() => {
        if(data && data?.length > 0){
            const newRow = data?.map(itm => {
                return {
                    phone: itm?.phone,
                    type : itm?.whatsappCred?.accessToken ? (itm.whatsappOnly ? 'WHATSAPP': 'SMS | WHATSAPP') : 'SMS', 
                    assigned : <AssignedDetails key={"assign_"+itm?.phoneId} phoneId={itm?.phoneId}/>,
                    action : <PhoneActions key={itm?.phoneId} onDisaUpdated={onDisaUpdated} onPhoneAssigned={(res) => {onPhoneAssigned(res)}} phone={itm}/>
                }
            });
            setRows(newRow)
        }
    },[data])

    return(
        <Table canSearch={true} heading={"Numbers"} columns={adminPhoneColumns?adminPhoneColumns : []} rows={rows ? rows : []} />
    )
}
export default AdminPhoneCredentialsList;