import { ChatBackgroundAPI } from "services/IChat/ChatBackgroundAPI";
import { store } from "store";
import { IChatAction } from "store/IChat/Ichat-slice";

export const userStatusService = ({companyId,token}) => {
    const intervalId = setInterval(() => {
    // Dispatch the fetchOnlineStatus action
        ChatBackgroundAPI.getAllUserStatus({companyId,token}).then(res => {
            store.dispatch(IChatAction.scheduledRecipientStatusUpdate(res.payload)); 
        }).catch(e => {
            console.log(e);
        })   
    }, 60000); // Set the interval time in milliseconds (e.g., 60 seconds)
    
    // Return the intervalId if you want to be able to stop the service later
    return intervalId;
};

// Function to stop the background service
export const stopUserStatusService = (intervalId) => {
    clearInterval(intervalId);
};