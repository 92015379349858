import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { chatAction } from "store/chat-slice";
import { timeoutAction } from "store/timeout-slice";
import { playSound } from "util/MediaUtil";
import { useCallback } from "react";
import { messageAction } from "store/message-slice";
import { SmsMessageAPI } from "services/Chat/SmsMessageAPI";
import { uploadAsset } from "util/upload-media-helper";
import { TELE_MESSAGE_STATUS } from "constants/ChatConstants";

const useSend = () => {
    
    const time = useSelector(state => state.userDetails.details?.userSettings?.teleUserSettings?.messageDelayTime);
    const outBoundNF = useSelector((state) => state.userDetails.details.userSettings.outboundSounds)
    const dispatch = useDispatch();
    const token = useSelector(state => state.user.token);

    const sendMessageFn = async({payload,token,file}) => {
      const newPayload = {
        ...payload
      }
      if(file && Boolean(file)){
        // upload media first
        try{
          const uploaded = await uploadAsset({file,token,domain : payload.domain});
          // when successfully uploaded, send message..
          const media = [{
            src : uploaded.asset.uri,
            uuid : uploaded.asset.uid
          }];
          newPayload["media"] = media;
          newPayload["messageAttchement"] = undefined;
          newPayload["message"] = Boolean(newPayload?.message) ? newPayload.message : ""
        }catch(ex){
          // mark it as failed 
          const newPayload = {...payload}
          newPayload.status = TELE_MESSAGE_STATUS.failed;
          dispatch(messageAction.updateConversation({recipientId : newPayload?.sendTo.id , message: newPayload}));
          return;
        }
      }
      SmsMessageAPI.createMessage({payload : newPayload,token}).then(res => {
        const messageSent = res.data;
        //// When message sent successfully
        try{
          playSound(outBoundNF[0]?.sourceUrl);
        }catch(e){console.log(e)}
        dispatch(chatAction.updateLatestMessage({recipientId : messageSent?.sendTo.id , message: messageSent}));
        dispatch(messageAction.updateConversation({recipientId : messageSent?.sendTo.id , message: messageSent}));
      }).catch(e => {
        console.log(e)
        const err = e?.response?.data;
        handleMessageFailure(err);
      })
    }

    const send = useCallback(({msgPayload,token,file = undefined}) => {
      if(msgPayload?.scheduledAt){
        sendMessageFn({payload : msgPayload,token,file});
      }
      else{
        if(time === 0){
          sendMessageFn({payload : msgPayload,token,file})
        }
        else{
          const id = setTimeout(() => sendMessageFn({payload : msgPayload,token,file}),(time ? time*1000 : 5000) );
          const payload = {
            messageId : msgPayload.tempId,
            recipientId : msgPayload.sendTo.id,
            timeoutObj : {
              id,
              trigger : () => (clearTimeout(id),sendMessageFn({payload : msgPayload,file,token}))
            }
          }
          dispatch(timeoutAction.addTimer(payload));
        }
      }
    },[time,outBoundNF]) 

    const handleMessageFailure = useCallback((sendMsgErr) => {
      // const tmpErr = JSON.parse(sendMsgErr);
      let errPayload = sendMsgErr?.payload;
      if(sendMsgErr?.type === 'whatsapp'){
      // Open pop up...
        Swal.fire({
          titleText: sendMsgErr?.message,
          text : 'Would you like to send via SMS instead?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          customClass: {
            container : {
              zIndex : '10000 !important'
            }
          }
        }).then((result) => {
          if (result.isConfirmed) {
            errPayload = {
              ...errPayload,
              type : 'SMS'
            }
            sendMessageFn({payload : errPayload,token});
          }
          else{
            dispatch(messageAction.removeConversation({message : errPayload, recipientId : errPayload.sendTo.id}));
          }
        }).catch(e => {
          console.log(e);
        })
      }
    },[token])

    return {send}
}
export default useSend;