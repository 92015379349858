import MDBox from "lib/components/MDBox";
import DetailsForm from "./DetailsForm";
import { Button } from "@mui/material";
import { CustomerAPI } from "services/CustomerAPI";
import { useDispatch, useSelector } from "react-redux";
import { loaderAction } from "store/loader-slice";

const CustomerDetails = ({customer}) => {
    const dispatch = useDispatch();
    const token = useSelector(state => state.user.token);
    const activatePlan = () => {
        dispatch(loaderAction.show());
        CustomerAPI.activateCustomer({token,id : customer.id}).then(res => {
            dispatch(loaderAction.hide());
        }).catch(err => {
            dispatch(loaderAction.hide());
            console.log(err);
        });
    }

    return(
        <MDBox display="flex" flexDirection="column">
            {customer && <>
                <DetailsForm customer={customer}/>
                {customer?.status==='pending' && <MDBox display="flex" justifyContent="flex-end">
                    <Button onClick={activatePlan}>Activate</Button>
                </MDBox>}
            </>}
        </MDBox>

    )
}
export default CustomerDetails;