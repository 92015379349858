import { BASE_URL } from "constants/Endpoints"
import axios from "../core/axios";

export const LoginAPI = {
    sendOTP : async({payload}) => {
        return await axios.post(`${BASE_URL}/auth/generate-fa`,JSON.stringify(payload),{
            headers: {
                'Content-Type': 'application/json',
            }            
        })
    },
    ValidateOTP : async({payload}) => {
        return await axios.post(`${BASE_URL}/auth/2FA/validate`,JSON.stringify(payload),{
            headers: {
                'Content-Type': 'application/json',
            }            
        })
    }
}