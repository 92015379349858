import axios from "../../core/axios";
import { COMPANY_USERS_EXCEPT_ME_WITH_SEARCH } from "constants/Endpoints"
import { TOGGLE_CHAT_MUTE } from "constants/Endpoints"
import { COMPANY_USERS_EXCEPT_GIVEN_IDS } from "constants/Endpoints"
import { REMOVE_PARTICIPANT } from "constants/Endpoints"
import { ADD_PARTICIPANT } from "constants/Endpoints"
import { GET_ALL_CHAT } from "constants/Endpoints"
import { CHAT_EXISTS } from "constants/Endpoints"
import { CHAT } from "constants/Endpoints"
import { COMPANY_USERS_EXCEPT_ME } from "constants/Endpoints"
//BLIP_MESSAGE = "//api/v1/blip"
export const ChatAPI = {
    getUserChat : async({token,userId,param}) => {
        return await axios.get(CHAT(userId) + param,{
            headers:{
                'Authorization': 'Bearer ' + token                
            }
        })
    },
    getAllChat : async({token,userId}) => {
        return await axios.get(GET_ALL_CHAT(userId),{
            headers:{
                'Authorization': 'Bearer ' + token                
            }
        }) 
    },
    getMyCompanyUsersExceptMe : async({token,companyId,userId}) => {
        return await axios.get(COMPANY_USERS_EXCEPT_ME(userId,companyId),{
            headers:{
                'Authorization': 'Bearer ' + token
            }
        })
    },
    searchMyCompanyUsersExceptMe : async({token,companyId,userId,pattern}) => {
        return await axios.get(COMPANY_USERS_EXCEPT_ME_WITH_SEARCH(userId,companyId,pattern),{
            headers:{
                'Authorization': 'Bearer ' + token
            }
        })
    },
    getChatByParticipants : async({token,authorId,userId}) => {
        return await axios.get(CHAT_EXISTS(authorId,userId),{
            headers:{
                'Authorization': 'Bearer ' + token
            }
        })
    },
    toggleMute : async({token,chatId,participantId,muted}) => {
        return await axios.get(TOGGLE_CHAT_MUTE(chatId,participantId,muted),{
            headers:{
                'Authorization': 'Bearer ' + token                
            }
        })
    },
    deleteChat : async({token,chatId}) => {
        return await axios.delete(CHAT(chatId),{
            headers : {
                'Authorization': 'Bearer ' + token  
            }
        })
    },
    getUsersExceptGivenIds : async({token,payload,companyId}) => {
        return await axios.post(COMPANY_USERS_EXCEPT_GIVEN_IDS(companyId),JSON.stringify(payload.ids),{
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',   
            }
        })
    },
    addParticipants : async({token,payload}) => {
        return await axios.post(ADD_PARTICIPANT,JSON.stringify(payload),{
            headers : {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json', 
            }
        })
    },
    deleteParticipants : async({token,payload}) => {
        return await axios.post(REMOVE_PARTICIPANT,JSON.stringify(payload),{
            headers : {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json', 
            }
        })
    }
}