import { Button, Divider, MenuItem, Select, TextField, Typography } from "@mui/material";
import { getUserCompany } from "api/company";
import { assignPhoneToCompany,getSettings, setSettings } from "api/poolSettings";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import AppModal from "ui/AppModal";

const PoolSettings = ({onClose}) => {

  const [settingEditing, setSettingEditing] = useState(false);
  const currUser = useSelector(state => state.user);
  const [generalSettings, setGeneralSettings] = useState();

  const {sendRequest : fetchCompanyList, data : companyList , status : fetchStatus} = useHttp(getUserCompany, true);
  const {sendRequest : assignPhoneFn, data : assignedRes, status :assignStatus, error} = useHttp(assignPhoneToCompany,true);
  const {sendRequest : getSettingsFn, data : settingsData, status : settingStatus} = useHttp(getSettings,false);
  const {sendRequest : setSettingsFn, data : setSettingsData, status : setSettingStatus} = useHttp(setSettings,false);
  const [assignPhone, setAssignPhone] = useState({company_id : '', quantity : 0})
  const onAssignPhoneSubmit = () => {
    if(assignPhone.company_id !== '' && assignPhone.quantity > 0){
        const payload = {
          token : currUser.token,
          ...assignPhone
        }
        assignPhoneFn(payload);
    }
    else{
      toast.error("Please select valid inputs.");
    }
  }
  
  const saveSettings = () => {
    setSettingsFn({token : currUser.token, settings: generalSettings})
  }

    //// retrieve companies
    useEffect(() => {
      fetchCompanyList({token : currUser.token,userId:currUser.id});
      getSettingsFn(currUser.token);
    },[])
    
    useEffect(() => {
      if(assignStatus === 'completed'){
        if(error === null){
          toast.success(assignedRes?.message);
        }
        else{
          toast.error("Something went wrong...")
        }
        setAssignPhone({
          company_id: '',
          quantity: 0
        })
      }
    },[assignedRes,assignStatus,error])

    useEffect(() => {
      if(settingStatus === 'completed'){
        setGeneralSettings(settingsData);
      }
    },[settingsData,settingStatus]);

    useEffect(() => {
      if(setSettingStatus === 'completed'){
        if(setSettingsData){
          toast.success("Settings saved successfully");
          setSettingEditing(false);
        }
      }
    },[setSettingsData,setSettingStatus]);

    return (
      <AppModal
        rootStyle={{
          "&.MuiModal-root": {
            width: "80%",
            left: "20%",
          },
        }}
        customStyle={{
          width: "100%",
          height: "100%",
          overflowY: "auto",
          border: "unset",
        }}
        onModalClose={onClose}
        heading={""}
      >
        <MDBox display="flex" justifyContent="center" width="100%">
          <Typography variant="h4">Settings</Typography>
        </MDBox>
        <MDBox
          display="flex"
          sx={{
            padding: "8px",
          }}
          width="300px"
          flexDirection="column"
        >
          <Typography
            sx={{ marginLeft: "12px", marginRight: "12px" }}
            variant="subtitle2"
          >
            Default limit of numbers allowed to purchase from Pool by Companies
          </Typography>
          <TextField
            sx={{ marginLeft: "12px", marginRight: "12px" }}
            type="number"
            variant="standard"
            onChange={(e) => {
              setGeneralSettings(prev => {
                return {...prev, pool_limit : e.target.value}
              });
              if(!settingEditing){
                setSettingEditing(true);
              }
            }}
            value={generalSettings?.pool_limit}
          />
        </MDBox>
        {settingEditing && (
          <MDBox display="flex" justifyContent="flex-start" onClick={saveSettings}>
            <Button variant="contained" sx={{borderRadius:'0px', color: 'white!important', border:'unset',marginLeft:'12px', height:'32px',minHeight:'unset'}}>Save</Button>
          </MDBox>
        )}
        <Divider
          component="div"
          sx={{
            "&.MuiDivider-root": {
              backgroundColor: "#e9d5e9",
            },
          }}
        />
        {/* <MDBox display="flex" justifyContent="center" flexDirection="column" sx={{marginTop:'24px'}}>
          <Typography variant="h5">Assign phone numbers to company</Typography>
          <MDBox display="flex" mt={2} mb={2} flexDirection="column">
                <MDBox>
                  <Typography sx={{width:'130px',display: 'block',marginLeft:'12px'}} variant="button">Select company: </Typography>
                  <Select
                    value={assignPhone.company_id}
                    label=""
                    style={{ width: "300px", height: "40px", marginLeft:'12px' }}
                    onChange={(e) => {
                      setAssignPhone(prev => {
                        return {
                          ...prev,
                          company_id : e.target.value
                        }
                      })
                    }}
                    displayEmpty
                    sx={{
                      "& .MuiSvgIcon-root": {
                        display: "inline-block",
                        fontSize: "24px!important",
                        right: "0",
                      },
                      "& .MuiSelect-iconOpen": {
                        fontSize: "24px!important",
                      },
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">
                      <em>Select company</em>
                    </MenuItem>
                    {companyList?.map((item) => (
                      <MenuItem key={item.companyId} value={item.companyId}>
                        {item.companyName}
                      </MenuItem>
                    ))}
                  </Select>   
                </MDBox>
                <MDBox mt={2}>
                  <Typography sx={{width:'130px',display: 'block',marginLeft:'12px'}} variant="button">Enter quantity: </Typography>
                  <TextField
                    onChange={(e) => {
                      setAssignPhone(prev => {
                        return {
                          ...prev,
                          quantity : e.target.value
                        }
                      })
                    }}
                    style={{ width: "300px", height: "40px", marginLeft:'12px'}}
                    variant="outlined"
                    type="number"
                    placeholder="quantity to assign..."
                    value = {assignPhone.quantity}
                  />         
                </MDBox>
                <MDBox onClick={onAssignPhoneSubmit} display="flex" width="300px" justifyContent="end" mt={3}>
                  <Button variant="contained" sx={{borderRadius:'0px', color: 'white!important', border:'unset',marginLeft:'12px', height:'32px',minHeight:'unset'}}>Assign</Button>
                </MDBox>
          </MDBox>
        </MDBox> */}
      </AppModal>
    );
}
export default PoolSettings;