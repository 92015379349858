import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import CompanyWideAutoReplyMessage from "./CompanyWideAutoReplyMessage";
import PhoneSpecificAutoReply from "./PhoneSpecificAutoReply";
import UserPhoneList from "../Phone/UserPhoneList";
import { useCallback } from "react";
import useDebouncedWatch from "hooks/use-debounce-watch";
import { CompanyAPI } from "services/CompanyAPI";
import { useSelector } from "react-redux";
import { AUTO_REPLY } from "constants/AppConstants";

const autoReplyMessage = {
  companyWide : true,
  reply : '',
  phone : [],
  // Time gap means the conversation which should be marked as dorminant...
  timeGap : 10
}

const AutoReplyMessage = ({initialData = undefined,phones}) => {

    const [isEditing, setIsEditing] = useState(false);
    const firstRender = useRef(true);
    const [currAutoReply,setCurrAutoReply] = useState(autoReplyMessage);
    const [selectedPhone,setSelectedPhone] = useState();
    const token = useSelector(state => state.user.token);
    
    const onPhoneSelected = useCallback((phone) => {
      //Check if phone exists...
      const findPhone = autoReplyMessage.phone.find(itm => itm?.phoneId === selectedPhone?.phoneId);
      // initialize the data
      if(!findPhone){
        const newPhone = {
          phoneId : phone.phoneId,
          reply : ''
        } 
        setCurrAutoReply(prev => {
          return {
            ...prev,
            phone : [...prev.phone,newPhone]
          }
        })
      }
      setSelectedPhone(phone);
    },[currAutoReply]);

    const onMessageTimeChangeHandler = (e) => {
      setCurrAutoReply(prev => {
        return{
          ...prev,
          timeGap : e.target.value
        }
      })
    }

    const saveAutoReply = useCallback(() =>  {
        CompanyAPI.updateAutoReply({token,payload : currAutoReply}).then(res => {
          setCurrAutoReply(res.data.autoReply);
          toast.success("Auto reply saved.");
          // setIsEditing(false);
        }).catch(e => toast.error(e));
    },[currAutoReply]);

    useDebouncedWatch(() => {
      if(firstRender.current) {
        firstRender.current = false;
        return;
      }
      if(currAutoReply) {
        setIsEditing(true);
      }
    },[currAutoReply]);

    useEffect(() => {
      if(initialData)
        setCurrAutoReply(initialData);
    },[initialData]);

    return (
      <Box sx={{ width: "100%", pt: 1, height: "100%" }}>    
        <MDBox p={1} display="flex" flexDirection="row" justifyContent="space-between">
          <MDTypography
            variant="h6"
            fontWeight="medium"
            textTransform="capitalize"
          >
            Auto Reply Content
          </MDTypography>
        </MDBox>         
        <MDBox sx={{width:'400px'}} p={1}>
          <Typography sx={{marginBottom:'8px'}} fontSize={"14px"}>{"Select phone to apply"}</Typography>
          {<UserPhoneList disabled={currAutoReply?.companyWide} nativeFetch={false} onPhoneSelected={onPhoneSelected}/>}
          <FormControlLabel onChange={(e) => {
            setCurrAutoReply(prev => {
              return {
                ...prev,
                companyWide : e.target.checked
              }
            })
          }} checked={currAutoReply ? currAutoReply.companyWide : false}  control={<Checkbox />} label="Apply auto reply to all company numbers" />
        </MDBox>        
        {
          currAutoReply.companyWide ? <CompanyWideAutoReplyMessage autoReply={currAutoReply} setAutoReply={setCurrAutoReply} />
          : <PhoneSpecificAutoReply selectedPhone={selectedPhone} autoReply={currAutoReply} setAutoReply={setCurrAutoReply}/>
        }
        <MDBox p={2}>
          <MDTypography
            variant="subtitle1"
            fontWeight="light"
            fontSize={'16px'}
          >
            {AUTO_REPLY.AUTO_REPLY_INACTIVE_INS}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" flexDirection="row">
          <MDInput
            style={{ width: "60%", margin: "8px" }}
            type="number"
            label="Enter minutes"
            value={currAutoReply.timeGap}
            onChange={onMessageTimeChangeHandler}
          />
        </MDBox>    
        <MDBox display="flex" flexDirection="row" justifyContent="flex-end" mb={2}>
          {isEditing && <MDButton onClick={saveAutoReply} variant="gradient" color="info" style={{marginLeft:'10px'}}>Save changes</MDButton>}
        </MDBox>    
      </Box>
    );
}
export default AutoReplyMessage;