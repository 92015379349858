import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useSelector } from "react-redux";
import { memo, useRef, useState } from "react";
import UserShortcuts from "components/Shortcuts/UserShortcuts";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import ImageUpload from "./ImageUpload";
import WApprovedTemplate from "components/Whatsapp/WApprovedTemplate";
import RecordUsingWave from "components/Audio/RecordUsingWave";
import { Box, Popover, SpeedDial, SpeedDialAction } from "@mui/material";
import usePopup from "hooks/use-popup";
import EmojiConvertor from "emoji-js";
import IEmoji from "components/IChat/IEmoji";
import { VIEWS } from "constants/ChatConstants";
import { Add } from "@mui/icons-material";

const MiniMessageIconsFunctionality = ({ onAttachmentAdded, messageState, onEmojiSelect, setMessageState, handleTemplateMessage, setAudioMessage }) => {
    const speedDialRef = useRef('speedDial')
    const [action, setAction] = useState("");
    const [openSpeedDial, setOpenSpeedDial] = useState(false);
    const { popoverId, anchorEl, openPopover, setAnchorEl, handleClosePopup } = usePopup();

    const view = useSelector(state => state.chat.current_active_view);
    const currentMsgView = useSelector(state => state.chat.current_message_view);

    const onTemplateSend = (res) => {
        handleTemplateMessage(res);
    }

    const onAttachmentAddedHandler = (data) => {

        setAction("");
        onAttachmentAdded(data);
    };

    const insertImageHandler = () => {
        setAction("IMAGE");
    };

    const addEmoji = (emoji) => {
        // console.log('emoji',emoji)
        // const emojiConvertor = new EmojiConvertor();
        // emojiConvertor.replace_mode = "unified";
        // const convertedEmoji = emojiConvertor.replace_colons(emoji?.colons);
        onEmojiSelect(emoji?.native)
        // setMessageState(prev => {
        //     return {
        //         ...prev,
        //         message: prev?.message + "" + convertedEmoji
        //     }
        // });
    }

    const handleOpen = () => setOpenSpeedDial(true);
    const handleClose = () => setOpenSpeedDial(false);

    return (
        <>
            <Box sx={{ height: 24, maxWidth: '32px', transform: "translateZ(0px)", flexGrow: 1 }}>
                <SpeedDial
                    ref={speedDialRef}
                    ariaLabel="chat icons speed dial for mini screens"
                    sx={{
                        height: 32, width: 32, position: 'absolute', top: 0, right: 0,
                        "& button": {
                            boxShadow: 'none !important',
                            backgroundColor: 'white !important'
                        }
                    }}
                    icon={<Add sx={{ color: '#344767', transform: 'scale(1.5)' }} />}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    open={openSpeedDial}
                >
                    <SpeedDialAction
                        key={'shortcuts'}
                        icon={<UserShortcuts style={{ transform: 'scale(1.5)' }} mobile={true} onShortcutSelected={(res) => {
                            const m = messageState.message + res?.trim();
                            setMessageState(prev => {
                                return {
                                    ...prev,
                                    message: m
                                }
                            })
                        }} />
                        }
                        tooltipTitle={'Quick reply'}
                    />
                    <SpeedDialAction
                        key={'audio'}
                        icon={
                            <RecordUsingWave sx={{ transform: 'scale(1.5)' }} setAudioMessage={setAudioMessage} />
                        }

                        title={"Audio"}
                    />

                    <SpeedDialAction
                        key={'attachement'}
                        title={'Attachement'}
                        icon={
                            view !== "GROUP_VIEW" && (<AttachFileIcon style={{ cursor: "pointer", transform: 'scale(1.5)' }} onClick={insertImageHandler} />)
                        }
                    />
                    <SpeedDialAction
                        key={'emoji'}
                        title={"Emojis"}
                        icon={
                            <SentimentSatisfiedAltIcon style={{ cursor: "pointer", marginLeft: "6px", transform: 'scale(1.5)' }} onClick={() => {
                                setAnchorEl({ getBoundingClientRect: () => { return speedDialRef?.current?.getBoundingClientRect() }, nodeType: 1 })
                            }} />
                        }
                    />
                    <SpeedDialAction
                        key={"Wa_template"}
                        title={"Whatsapp templates"}
                        icon={
                            view === VIEWS.NORMAL_VIEW && <WApprovedTemplate sx={{ transform: 'scale(1.5)' }} mobile={true} onTemplateSend={onTemplateSend} />
                        }
                    />
                </SpeedDial>
            </Box>
            <Popover
                id={popoverId}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClosePopup}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <IEmoji addEmoji={addEmoji} />
            </Popover>
            {action === "IMAGE" && (
                <ImageUpload onClose={() => setAction("")} onAttachmentAdded={onAttachmentAddedHandler} />
            )}
        </>
    )
}
export default memo(MiniMessageIconsFunctionality);