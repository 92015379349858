import { BASE_URL } from "constants/Endpoints";
import axios from "../../core/axios"

export const RecipientAPI = {
    getContactedRecipient : async({phoneId,token,userId,pageNo,pageSize,searchPattern=""}) => {
        return await axios.get(`${BASE_URL}/phone/${phoneId}/recipient?pageNo=${pageNo | 0}&pageSize=${pageSize | 40}&userId=${userId}`,{
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
              }
        });
    },
    searchContactedRecipient : async({phoneId,token,companyId,pageNo,pageSize,searchPattern="",userId}) => {
        return await axios.get(`${BASE_URL}/phone/${phoneId}/recipient/search?pageNo=${pageNo | 0}&pageSize=${pageSize | 40}&searchPattern=${searchPattern}&userId=${userId}&companyId=${companyId}`,{
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
              }
        });
    },
    getContactedRecipientHavingUnreadMsg : async({phoneId,token,pageNo,pageSize,userId}) => {
        return await axios.get(`${BASE_URL}/phone/${phoneId}/recipient/unread?pageNo=${pageNo | 0}&pageSize=${pageSize | 40}&userId=${userId}`,{
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
              }
        });
    },

    getMergedRecipient : async({userId,token,pageNo,pageSize}) => {
        return await axios.get(`${BASE_URL}/phone/${userId}/recipient/merge?pageNo=${pageNo | 0}&pageSize=${pageSize | 40}`,{
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
              }
        });
    },
    toggleMute : async({token,recipientId,muted}) => {
        return await axios.get(`${BASE_URL}/recipient/${recipientId}/mute?muted=${muted}`,{
            headers:{
                'Authorization': 'Bearer ' + token                
            }
        })
    },
    blockRecipient : async({token,recipientId,companyId}) => {
        return await axios.get(`${BASE_URL}/recipient/${recipientId}/block?companyId=${companyId}`,{
            headers:{
                'Authorization': 'Bearer ' + token                
            }
        })
    },
    deleteRecipient : async({token,recipientId,companyId}) => {
        return await axios.delete(`${BASE_URL}/recipient/${recipientId}/delete?companyId=${companyId}`,{
            headers:{
                'Authorization': 'Bearer ' + token                
            }
        })
    },
    unblockRecipient : async({token,recipientId,companyId}) => {
        return await axios.get(`${BASE_URL}/recipient/${recipientId}/unblock?companyId=${companyId}`,{
            headers:{
                'Authorization': 'Bearer ' + token                
            }
        })
    },
    editRecipientName : async({token,payload}) => {
        return await axios.post(`${BASE_URL}/recipient/update/name`,JSON.stringify(payload) ,{
            headers:{
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        })
    },
    syncContacts : async({token,companyId,phoneId}) => {
        return await axios.get(`${BASE_URL}/phone/sync/${companyId}/${phoneId}`,{
            headers:{
                'Authorization': 'Bearer ' + token                
            }
        })
    },
    createTag : async({token,payload}) => {
        return await axios.post(`${BASE_URL}/recipient/create/tag`,JSON.stringify(payload) ,{
            headers:{
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        })
    },
    removeTag : async({token,payload}) => {
        return await axios.post(`${BASE_URL}/recipient/remove/tag`,JSON.stringify(payload) ,{
            headers:{
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        })
    },
}