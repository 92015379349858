import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Tooltip, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopyToClipboard from "react-copy-to-clipboard";
import MDTypography from "lib/components/MDTypography";
import { memo, useCallback, useEffect, useState } from "react";
import AddLinkIcon from '@mui/icons-material/AddLink';
import { savefundraiserDonationLink } from "api/fundraiser";
import useHttp from "hooks/use-http";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateFundraiserMessage } from "api/fundraiser";
import { checkIfEmailAlreayInuse } from "api/fundraiser";
import { giveUIAccess } from "api/fundraiser";
import { updateNotificaiton } from "api/fundraiser";
import { _getFundraiserById } from "api/fundraiser";
import { OpenInNew } from "@mui/icons-material";


const FundraiserSettingMenu = ({campaign,fundraiser, onLinkGenerated}) => {
    const [settings, setSettings] = useState();
    const [isEditing, setIsEditing] = useState(false);
    const [edit, setEdit] = useState(false);
    const [message, setMessage] = useState('');
    const [fetchedFundraiser, setFetchedFundraiser] = useState();
    const [donationLink, setDonationLink] = useState();
    const {sendRequest, data, status, error} = useHttp(_getFundraiserById,false)
    const token = useSelector(state => state.user.token);
    
    const {sendRequest : updateFundraiserMessageFn, status : messageUpdateStatus,data : messageUpdateData} = useHttp(updateFundraiserMessage,false); 
    const {sendRequest : saveDonationLinkFn , status : savedStatus, data : savedData , error : linkSavedErr} = useHttp(savefundraiserDonationLink,false);
    const {sendRequest : isEmailAlreadyInUseFn, status : emailStatus, data : emailData,error : emailErr} = useHttp(checkIfEmailAlreayInuse,false);
    const {sendRequest : giveUIAccessFn, status : uiAccessStatus, data : uiAccessData} = useHttp(giveUIAccess,false);
    const {sendRequest : updateNotificaitonFn, status : notificationSavedStatus, data : notificationSavedData} = useHttp(updateNotificaiton,false);
    
    const saveNotificationChoice = () =>{
      updateNotificaitonFn({
        token,
        payload : {
          fundraiser_id : fetchedFundraiser.fundraiser_id,
          medium : settings.notification_medium
        }
      })
    }

    const generateDonationLink = useCallback(() => {
      const base1 = window.location.origin;
      const fundraiserName = fetchedFundraiser?.fundraiser_id;
      const base = base1 + `/donate/${fetchedFundraiser.campaign?.campaign_name}/${fundraiserName}`;
      setDonationLink(base);
      const payload = {
        donation_link : base,
        campaign_id : campaign.campaign_id,
        fundraiser_id : fetchedFundraiser?.fundraiser_id
      }
      const payloadData = {
        payload : payload,
        token 
      }
      saveDonationLinkFn(payloadData)
    },[campaign,fetchedFundraiser,token,saveDonationLinkFn,setDonationLink])
        
    const onUIAccessCheck = (e) => {
      // check if UIAccess can be given, check if it has mail.
      if(!fetchedFundraiser?.fundraiser_email || fetchedFundraiser?.fundraiser_email.length === 0){
        toast.error("This fundraiser does not have an email, Email is required to give UI access.");
        return;
      }
      else if(!fetchedFundraiser?.ui_access_action_performed){
        // check if email available..
        isEmailAlreadyInUseFn({email : fetchedFundraiser?.fundraiser_email, token});
      }
      else{
        setSettings((prev) => {
          return {
            ...prev,
            ui_access: e.target.checked,
          };
        });
        giveUIAccessFn({token, uiAccess: e.target.checked,fundraiser_id : fetchedFundraiser.fundraiser_id});
      }
    }

    const onNoticationOptionChange = (e) => {
      setSettings(prev => {
        return {
          ...prev,
          notification_medium : e.target.value
        }
      });
      setEdit(true);
    }
    
    const saveFundraiserMessage  = () => {
      updateFundraiserMessageFn({message,token,fundraiser_id : fetchedFundraiser.fundraiser_id});
    }

    useEffect(() => {
      if(fundraiser){
        sendRequest({token,id : fundraiser.fundraiser_id});
      }
    },[fundraiser,sendRequest])

    useEffect(() => {
      if(status === 'completed'){
        if(data){
          setFetchedFundraiser(data);
          if(data?.settings){
            const s = data?.settings ? data.settings: {};
            setSettings(s);
          }
          if(data?.message){
              setMessage(data.message);
          }
          if(data.donation_link){
            setDonationLink(data.donation_link);
          }
        }
      }
    },[status, data, error]);

    useEffect(() => {
      if(savedStatus === 'completed'){
        if(savedData){
          toast.success("Donation link generated successfully.")
          onLinkGenerated({fetchedFundraiser,donation_link : savedData.link});
        }
      }
    },[savedData,savedStatus]);

    useEffect(() => {
      if(emailStatus === 'completed'){
        if(emailData){
          if(Boolean(emailData.isEmailExists)){
            toast.error("The email address is already in use, Please update email address first.")
          }
          else{
            giveUIAccessFn({token, uiAccess: true,fundraiser_id : fetchedFundraiser.fundraiser_id})
          }
        }
        else if(emailErr) {
          toast.error("Action is not allowed. Please contact the administrator.")
        }
      }
    },[emailStatus,emailData,emailErr])

   useEffect(() => {
    if(notificationSavedStatus === 'completed'){
      if(notificationSavedData){
        toast.success(notificationSavedData.message);
        setEdit(false);
      }
    }
   },[notificationSavedStatus,notificationSavedData,setEdit])
    useEffect(() => {
      if(uiAccessStatus === 'completed'){
        if(uiAccessData){
          if(uiAccessData.status === 'success'){
            toast.success(uiAccessData.message);
          }
          else{
            toast.error("UI access not given");
          }
        }
      }
    },[uiAccessStatus,uiAccessData])

    useEffect(() => {
      if(messageUpdateStatus === 'completed'){
        if(messageUpdateData){
          toast.success("Message saved.");
          setIsEditing(false);
        }
      }
    },[messageUpdateStatus,messageUpdateData])

    return (
      <>
        <MDBox display="flex" flexDirection="column">
          <MDBox
            sx={{ padding: "12px" }}
            display="flex"
            flexDirection="row"
            justifyContent="space-around"
          >
            <MDTypography variant="h4">Fundraiser Settings</MDTypography>
          </MDBox>
        </MDBox>
        <MDBox mb={2} display="flex" flexDirection="row">
          {!donationLink && (
            <MDBox onClick={generateDonationLink} display="flex" alignItems="center">
                <Tooltip title="Click here to generate donation link for this campaign">
                  <AddLinkIcon
                    sx={{ height: "1.5rem", width: "1.5rem", cursor: "pointer",marginLeft:'8px' }}
                  />
                </Tooltip>
                <Typography fontSize={"18px"} sx={{margin:'4px'}}>{"Generate donation link."}</Typography>
              </MDBox>
          )}
          {donationLink && (
            <MDBox ml={2} width="90%">
              <Typography>Use this link for donation</Typography>
              <MDBox display="flex">
                <TextField
                  multiline
                  variant="standard"
                  type="text"
                  fullWidth
                  value={donationLink}
                />
                <CopyToClipboard
                  onCopy={() => {
                    toast.info("Link copied to clipboard");
                  }}
                  text={donationLink}
                >
                  <ContentCopyIcon
                    // onClick={generateDonationLink}
                    sx={{
                      height: "1.5rem",
                      width: "1.5rem",
                      cursor: "pointer",
                    }}
                  />
                </CopyToClipboard>
                <Tooltip title="Regenerate donation link.">
                  <AddLinkIcon m={1} onClick={generateDonationLink} sx={{ height: "1.5rem", width: "1.5rem", cursor: "pointer",marginLeft:'8px',marginRight:'8px' }}/>
                </Tooltip>
                {donationLink && <Tooltip title="open in new tab.">
                  <a href={donationLink} target="_blank"><OpenInNew m={1} sx={{ height: "1.5rem", width: "1.5rem", cursor: "pointer" }}/></a>
                </Tooltip>}
              </MDBox>
            </MDBox>
          )}
        </MDBox>
        <div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              value={Boolean(settings?.ui_access)}
              checked={Boolean(settings?.ui_access)}
              onChange={onUIAccessCheck}
              style={{
                height: "24px",
                width: "24px",
                marginRight: "20px",
                borderRadius: "15px",
                marginBottom: "25px",
              }}
              type="checkbox"
              id="UI"
            />
            <label
              style={{
                fontSize: "20px",
              }}
              htmlFor="UI"
            >
              UI Access{" "}
            </label>
            <br></br>
          </div>
          <MDBox display="flex">
            {settings && (
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Every time a donation is received, fundraiser should be notified via
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={onNoticationOptionChange}
                  value={settings?.notification_medium}
                >
                  <FormControlLabel
                    value="SMS"
                    control={<Radio />}
                    label="SMS"
                  />
                  <FormControlLabel
                    value="EMAIL"
                    control={<Radio />}
                    label="EMAIL"
                  />
                  <FormControlLabel
                    value="ALL"
                    control={<Radio />}
                    label="BOTH"
                  />
                  <FormControlLabel
                    value="NONE"
                    control={<Radio />}
                    label="NONE"
                  />
                </RadioGroup>
              </FormControl>
            )}
          </MDBox>
          {edit && (
            <MDBox
              display="flex"
              justifyContent="flex-start"
              onClick={saveNotificationChoice}
              mt={2}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: "0px",
                  color: "white!important",
                  border: "unset",
                  marginLeft: "12px",
                  height: "32px",
                  minHeight: "unset",
                }}
              >
                Save
              </Button>
            </MDBox>
          )}
          {/* <Divider
            component="div"
            sx={{
              "&.MuiDivider-root": {
                backgroundColor: "#e9d5e9",
              },
            }}
          /> */}
          <div
            style={{
              marginTop: "20px",
              marginLeft: "16px",
            }}
          >
            <MDBox
              style={{
                display: "flex",
                flexDirection: "column",
                width: "75%",
              }}
            >
              <MDBox display="flex" justifyContent="space-between">
                <h4>Message to be sent via IVR URL sender</h4>
                {isEditing && (
                  <Button
                    onClick={saveFundraiserMessage}
                    variant="contained"
                    sx={{
                      borderRadius: "0px",
                      color: "white!important",
                      border: "unset",
                      marginLeft: "12px",
                      height: "32px",
                      minHeight: "unset",
                    }}
                  >
                    Save
                  </Button>
                )}
              </MDBox>
              <TextField
                sx={{ marginTop: "2px" }}
                multiline
                variant="standard"
                type="text"
                placeholder="Write your message here..."
                value={message}
                onChange={(e) => {
                  setIsEditing(true);
                  setMessage(e.target.value);
                }}
              />
            </MDBox>
          </div>
        </div>
      </>
    );
}
export default memo(FundraiserSettingMenu);