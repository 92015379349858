import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChatAPI } from "services/IChat/ChatAPI";
import { IGroupAPI } from "services/IChat/IGroupAPI";
import { IGroupAction } from "store/IChat/igroup-slice";
import { formatGroupChat } from "util/IChat/IChatUtil";

const useIgroup = () => {

    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const currentActiveGroup = useSelector(state => state.Igroup.currentActiveGroup);
    const messages = useSelector(state => state.Igroup.conversation);

    useEffect(() => {
        if(currentActiveGroup){
            const messageAlreadyExists = messages[currentActiveGroup.chatId];
            if(!messageAlreadyExists){
                fetchConversation(currentActiveGroup.chatId);
            }
            
        }

    },[currentActiveGroup]);

    const fetchGroupChat = () => {
        const param = "?groupChat=true";
        ChatAPI.getUserChat({token : user.token,userId : user.id,param}).then(
            res => {
                const groups = res.data.map(r =>  formatGroupChat(r,user.id));
                dispatch(IGroupAction.concateGroup(groups));
                dispatch(IGroupAction.setFetchedToTrue());
            }
        ).catch(ex => console.log(ex));
    }

    const fetchConversation = (chatId) => {
        IGroupAPI.getGroupChatMessages({token : user.token, chatId : chatId}).then(
            res => {
                const reverseArr = res.data.content;
                if(reverseArr.length > 0)
                    dispatch(IGroupAction.addMessage({chatId : chatId, messages : reverseArr.reverse()}));
            }
        ).catch(e => console.log(e));
    }

    return {fetchGroupChat}
}
export default useIgroup;