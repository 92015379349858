import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "components/IChat/IChat.module.css";
import { chatVariablesAction } from "store/chat-variables-slice";
import { ConversationAPI } from "services/ConversationAPI";
import { chatAction } from "store/chat-slice";
import useDebouncedWatch from "./use-debounce-watch";

const useScrollToBottom = (messages, lastReadMessageId,scroll = true) => {
  const scrollRef = useRef(null);
  const dispatch = useDispatch();
  const [messageRead, setMessageRead] = useState([]);
  const currentUser = useSelector((state) => state.user);
  const currentActiveRecipient = useSelector((state) => state.chat.current_active_recipient);
  const currentActivePhone = useSelector(state => state.chat.current_active_phone);
  // Helper function to mark a message as read
  const markMessageAsRead = (message) => {
    setMessageRead((prev) => [...prev, message]);
  };

  //mark as read
  useDebouncedWatch(() => {
    console.log(messageRead);
    if (messageRead && messageRead.length > 0) {
      const ids = messageRead.map((itm) => itm.id);
      const newSet = new Set();
      for (const id of ids) {
        newSet.add(id);
      }
      const payload = {
        ids: [...newSet],
        userId: currentUser.id,
        phoneId : currentActivePhone?.phoneId,
        recipientId : currentActiveRecipient?.id,
        phone : currentActivePhone.phone
      };
      ConversationAPI.markAsReadConversation({payload,token: currentUser.token})
        .then((res) => {setMessageRead([])}).catch(e => console.log(e));
    }
  }, [messageRead, currentUser,currentActivePhone,currentActiveRecipient]);

  // Function to handle scroll events and mark messages as read
  const handleScroll = () => {
    if (scrollRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef?.current;
      // const isScrolledUp = Math.floor(scrollHeight - scrollTop) > clientHeight;
      const isScrolledUp  = Math.ceil(Math.abs(scrollTop)) > Math.ceil(clientHeight);
      // console.log( { scrollTop, scrollHeight, clientHeight,isScrolledUp })
      dispatch(chatVariablesAction.setScrollChatBottomBtn(isScrolledUp));
      messages?.forEach((message) => {
        const messageElement = scrollRef?.current.querySelector(`[data-message-id="${message.id}"]`);
        if (messageElement) {
        //   const messageTop = messageElement.offsetTop;
        //   const messageHeight = messageElement.clientHeight;
        //  // Check if the message is visible in the container and not already marked as read
        //   const t = (messageTop >= scrollTop) && (messageTop + messageHeight) <= (scrollTop + clientHeight);
        const messageRect = messageElement.getBoundingClientRect();

        // Check if the message is visible in the container
        const isVisible =
          messageRect.top >= 0 &&
          messageRect.bottom <= (window.innerHeight || document.documentElement.clientHeight);
      
          const shouldMark = isVisible && message.inbound && !message.messageRead;
          if (shouldMark) {
            markMessageAsRead(message);
          }
        }
      });
    }
  };

  useEffect(() => {
    // Calculate which messages are in the viewport
    let t = undefined;
    if(messages && messages.length > 0){
      t = setTimeout(() => {
        messages.forEach((message) => {
          const messageElement = scrollRef?.current.querySelector(`[data-message-id="${message.id}"]`);
          if (messageElement) {
            const { top, bottom } = messageElement.getBoundingClientRect();
            if (top >= 0 && bottom <= window.innerHeight) {
              // Message is fully in the viewport
              if(message.inbound && !message.messageRead){
                markMessageAsRead(message);
              }
            }
          }
        });
      },100);
    }
    return () => clearTimeout(t);
  },[messages,scrollRef]);


  // Function to scroll to the bottom of the container
  const scrollToBottom = () => {
    console.log("calling scroll to bottom...")
    setTimeout(() => {
      if (scrollRef?.current) {
        const containerElement = scrollRef?.current;
        containerElement.scrollTop = containerElement.scrollHeight;
        containerElement.className = style['smooth-scroll-container'];
        // Scrolled to bottom reduce count to zero
        if(currentActiveRecipient)
          dispatch(chatAction.updateUnreadCountToZeroForRecipient({recipientId : currentActiveRecipient?.id}));
      }
    }, 100);
  };

  // Helper function to find the index of the last read message in the message groups
  const findLastReadMessageIndex = () => {
    return messages?.findIndex((itm) => itm.id === lastReadMessageId);
  };

  useEffect(() => {
    if (lastReadMessageId) {
      // Find the index of the last read message in the messages array
      const lastReadMessageIndex = findLastReadMessageIndex();
      // console.log({ lastReadMessageIndex, lastReadMessageId });
      // If the last read message is found, scroll to it
      if (lastReadMessageIndex !== -1) {
        const lastReadMessageElement = document.getElementById("message_" + lastReadMessageId);
        if (lastReadMessageElement && scroll) {
          setTimeout(() => {
            lastReadMessageElement.scrollIntoView({ behavior: "smooth" });
           // lastReadMessageElement.classList.add(style["message_highlight"]);
          }, [500]);
        }
      } 
      //else {
        //if(scroll)
         // scrollToBottom();
      //}
    }
    // else {
   //   if(scroll)
       // scrollToBottom();
   // }
  }, [messages, lastReadMessageId,scroll,currentActiveRecipient]);

  // Add scroll event listener on mount
  useEffect(() => {
    scrollRef?.current.addEventListener("scroll", handleScroll);

    // Remove scroll event listener on unmount
    return () => {
      if (scrollRef?.current) {
        scrollRef?.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [messages, currentActiveRecipient]);

  return { scrollRef, scrollToBottom };
};
export default useScrollToBottom;
