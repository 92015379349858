import {useRef,useEffect } from 'react';
const useOuterClick = (refId,active,callback) =>{
    const callbackRef = useRef(); // initialize mutable ref, which stores callback
    const innerRef = useRef(refId); // returned to client, who marks "border" element
  
    // update cb on each render, so second useEffect has access to current value 
    useEffect(() => { callbackRef.current = callback; });
    
    useEffect(() => {
      document.addEventListener("click", handleClick);
      return () => document.removeEventListener("click", handleClick);
      function handleClick(e) {
        if (innerRef.current && callbackRef.current && typeof innerRef.current?.contains == 'function' && 
          !innerRef.current?.contains(e.target) && active
        ) {
          callbackRef.current(e);
        }
      }
    }, [active]); // no dependencies -> stable click listener
        
    return innerRef; // convenience for client (doesn't need to init ref himself) 
  }

  export default useOuterClick;