import { VolumeOff, VolumeUp } from "@mui/icons-material";
import { Button, Divider,Typography, FormControlLabel, FormGroup, Switch, Tooltip } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import { useCallback, useState } from "react";
import style from "../Notification.module.css";
import { useDispatch, useSelector } from "react-redux";
import { IUserSettingsAPI } from "services/IChat/IUserSettingsAPI";
import { toast } from "react-toastify";
import { userDetailAction } from "store/user-details-slice";
import { userSettings } from "constants/ChatConstants";

const Ichatsettings = () => {
    
    const dispatch = useDispatch();
    const [isUpdating, setIsUpdating] = useState(false);
    const user = useSelector(state => state.user);
    const userDetails = useSelector(state => state.userDetails.details);

    const [settings,setSettings] = useState(userDetails?.userSettings.icUserSettings ? userDetails?.userSettings.icUserSettings : userSettings);

    const handleOnReadSwitch = (e) => {
        const checked = e.target.checked;
        setSettings(prev => {
            return {
                ...prev,
                read_receipt : {
                    ...prev.read_receipt,
                    enabledByMe : checked
                }
            }
        }) 
        setIsUpdating(true);
    }

    const toggleMute = useCallback((value) => {
        //if it is enabling, check if we have browser permission if not, ask user to give
        let newSettings = undefined;
        if(!value) {
            if(Notification.permission !== "granted"){  
                Notification.requestPermission().then((permission) => {
                    if(permission === 'granted'){
                        newSettings = {
                            ...settings,
                            notification_enabled : value,
                        }
                        setSettings(newSettings);
                    }
                }); 
            }
            else{
                newSettings = {
                    ...settings,
                    notification_enabled : value,
                }
                setSettings(newSettings);
            }
        }
        else{
            newSettings = {
                ...settings,
                notification_enabled : value,
            }
            setSettings(newSettings);
        }
        IUserSettingsAPI.saveIcSettings({payload : {...newSettings,userId : user.id},token:user.token,userId : user.id}).then(res => {
            if(res.data){
                setSettings(res.data);
                toast.success("Settings saved successfully.");
                dispatch(userDetailAction.updateICsettings(res.data));
            }
        }).catch(e => toast.error("Something went wrong, please try again"));      
    },[user]);

    const updateSound = useCallback(() => {
        // call the endpoint to save.....
        //Save settings....
        IUserSettingsAPI.saveIcSettings({payload : settings,token:user.token,userId : user.id}).then(res => {
            if(res.data){
                setSettings(res.data);
                toast.success("Settings saved successfully.");
                dispatch(userDetailAction.updateICsettings(res.data));
                setIsUpdating(false);
            }
        }).catch(e => toast.error("Something went wrong, please try again"));        
    },[settings]);

    const onSoundChange = useCallback((item) => {
        const newSound = settings.notification_sounds.map(s => {
            if(s.name === item.name){
                return{
                    ...s,
                    enabled : true,
                }
            }
            else{
                return{
                    ...s,
                    enabled: false
                }
            }
        })
        setIsUpdating(true);
        setSettings(prev => {
            return{
                ...prev,
                notification_sounds : newSound
            }
        }) 
    },[settings])

    const notificationsLayout = (item) => {
        return <MDBox width="100%" key={item.name} display="flex" flexDirection="row" className={style['sound_container']}>
            <Switch sx={{marginLeft:'-10px'}} checked={item.enabled} onChange={onSoundChange.bind(null,item)} /> 
            <MDTypography fontSize={14} variant="subtitle2" sx={{ml: 1, mr:1,flex:1}}>
                {item.name}
            </MDTypography>
            <audio controls style={{height:'40px',maxWidth:'312px'}}>
                <source src={item.sourceUrl} type="audio/mpeg"/>
            </audio>
        </MDBox>  
    }    
    return(
        <>
            <MDBox display="flex" flexDirection="column" alignItems="center" width="100%">
                <MDBox display="flex" flexDirection="column"   width="100%">
                    <MDBox display="flex" flexDirection="column" justifyContent="space-between" width="100%">
                        {settings?.notification_enabled &&<>
                            <Tooltip title="Disable it.">
                                <Button onClick={() => toggleMute(false)} sx={{
                                    width:'100%',
                                    height:'22px',
                                    cursor:'pointer',
                                    backgroundColor: "#1876F2!important",
                                    color: "white!important",
                                    textTransform: 'unset'
                                }} startIcon={
                                    <VolumeUp/> 
                                }>
                                    Disable
                                </Button>

                            </Tooltip>
                            <MDTypography fontWeight={"small"} variant="button" color={"success"}>{"Internal chat notifications are enabled."}</MDTypography>
                        </> 
                        }
                        {!settings?.notification_enabled && 
                        <>
                            <Tooltip title="Enable now.">
                                <Button onClick={() => toggleMute(true)}
                                    sx={{
                                        width:'100%',
                                        height:'22px',
                                        cursor:'pointer',
                                        backgroundColor: "#1876F2!important",
                                        color: "white!important",
                                        textTransform: 'unset'
                                    }}
                                    startIcon={
                                        <VolumeOff/>
                                    }
                                >
                                    Enable
                                </Button>

                            </Tooltip>
                            <MDTypography fontWeight={"small"} variant="button" color={"warning"}>{"Internal chat notifications are disabled."}</MDTypography>
                        </>
                        }
                    </MDBox>
                    <MDBox onClick={() => setSettings(userSettings)} display="flex" justifyContent="flex-end" width="100%">
                        <Button>{"Reset settings to defaults"}</Button>
                    </MDBox>
                    <Divider></Divider>
                    {settings?.notification_sounds && 
                        <MDBox mt={1}>
                            <Typography fontSize={14} fontWeight={'medium'}>
                                What would you like to hear when you get a new message?
                            </Typography>                    
                        </MDBox>
                    }
                    {settings?.notification_sounds?.map(item => notificationsLayout(item))}
                    <Divider></Divider>
                </MDBox>
                <MDBox padding="12px" display="flex" flexDirection="column" justifyContent="flex-start" width="100%">
                    <MDBox display="flex" flexDirection="row" width="100%">
                        <FormGroup>
                            <FormControlLabel
                                disabled={!settings?.read_receipt?.enabledByAdmin}
                                label={"Read receipt"}
                                control={<Switch/>}
                                checked={settings?.read_receipt?.enabledByMe}
                                onChange={handleOnReadSwitch}
                            >
                            </FormControlLabel>
                        </FormGroup>           
                    </MDBox>
                    {!settings?.read_receipt?.enabledByAdmin && <MDTypography sx={{fontSize:'14px'}} variant={"button"} fontWeight={"light"} color={"error"}>{`Read receipt is disabled by ADMIN. Please contact ${settings?.read_receipt?.disabledBy?.fullName || 'admin'}.`}</MDTypography>}
                </MDBox>
                {isUpdating && 
                    <MDBox width="100%" display="flex" justifyContent="flex-end">
                        <Button onClick={updateSound} sx={{borderRadius: "0px",color: "white!important",border: "unset",}} variant="contained">update</Button>
                    </MDBox>    
                }                
            </MDBox>         
        </>
    )
}
export default Ichatsettings;