import { BASE_URL } from "constants/Endpoints";
import fetch from "core/fetch";

//// Login ino the app
export const  login = async (loginFormData) => {
    const response = await fetch(`${BASE_URL}/auth/login`,{
        method : 'POST',
        body : JSON.stringify(loginFormData),
        headers: {
            'Content-Type': 'application/json',
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

///// Create a new user
export const createUser = async (newUser) => {
    const response = await fetch(`${BASE_URL}/auth/users`,{
        method: 'POST',
        body : JSON.stringify(newUser),
        headers :{
            'Content-Type' : 'application/json',
        }
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;
}

///// Get user details
export const getUserDetails = async(token) => {
    const response = await fetch(`${BASE_URL}/users/details`,{
        method : 'GET',
        headers :{
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + token,
        }
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;
}
export const getLockedUsers = async(user) => {
    let payload = {
        ...user,
        token : undefined
    }
    const response = await fetch(`${BASE_URL}/users/locked`,{
        method : 'POST',
        headers :{
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + user.token,
        },
        body : JSON.stringify(payload)
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;
}
///// Get the users
export const getUsers = async (user) => {
    let payload = {
        ...user,
        token : undefined
    }
    const response = await fetch(`${BASE_URL}/users`,{
        method : 'POST',
        headers :{
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + user.token,
        },
        body : JSON.stringify(payload)
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;
}
export const getTotalUserCount = async(usr) => {
    let payload = {
        ...usr,
        token : undefined
    }
    const response = await fetch(`${BASE_URL}/users/count`,{
        method : 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + usr.token,
          },
        body : JSON.stringify(payload)
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
//// To generate reset code
export const resetCode = async(email) => {
    const response = await fetch(`${BASE_URL}/users/reset/code`,{
        method : 'POST',
        body : JSON.stringify(email),
        headers: {
            'Content-Type': 'application/json',
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

//// update the password
export const forgotPassword = async(newCredentials) => {
    const response = await fetch(`${BASE_URL}/users/reset/password`,{
        method : 'POST',
        body : JSON.stringify(newCredentials),
        headers: {
            'Content-Type': 'application/json',
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const uploadProfile = async(uploadData) => {

    const response = await fetch(`${BASE_URL}/users/update/image`,{
        method : 'POST',
        body : uploadData.formData,
        headers: {
            // 'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + uploadData.token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
export const updateCredit = async(credit) => {
    const payload = {
        ...credit,
        token : undefined
    }
    const response = await fetch(`${BASE_URL}/users/update/credit`,{
        method : 'POST',
        body : JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + credit.token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
export const unLockedUser = async(user) => {
    let payload = {
        ...user,
        token : undefined
    }
    const response = await fetch(`${BASE_URL}/users/unlock`,{
        method : 'POST',
        headers :{
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + user.token,
        },
        body : JSON.stringify(payload)
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;
}
export const unlockAllUsers = async(user) => {
    let payload = {
        ...user,
        token : undefined
    }
    const response = await fetch(`${BASE_URL}/users/unlock/all`,{
        method : 'POST',
        headers :{
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + user.token,
        },
        body : JSON.stringify(payload.userIds)
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;
}