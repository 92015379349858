import { Add, Close } from "@mui/icons-material";
import { Button } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDInput from "lib/components/MDInput";

const AddNumber = ({setDlcData,dlcData}) => {

    const onEnterNumberHandler = (idx,value) => {
        const newNumbers = dlcData?.numbers?.map((item,i) => {
            if(i === idx){
                const val = value;
                return val;
            }
            else return item
        })
        setDlcData(prev => {
            return{
                ...prev,
                numbers : newNumbers
            }
        }); 
    }

    const removeHandler = (idx) => {
        const filtered = dlcData?.numbers?.filter((item,i) => i !== idx);
        setTimeout(() => {
            setDlcData(prev => {
                return{
                    ...prev,
                    numbers: filtered
                }
            });
        },500);
    }

    const renderNumbers = () => {
        return dlcData?.numbers.map((item,index) =>
            <MDBox key={"md_"+index} display="flex" alignItems="center" mb={2}>
                <MDInput
                    key={index}
                    type="number"
                    onChange={(e) => {
                        onEnterNumberHandler(index,e.target.value);
                    }}
                    label="Number"
                    variant="standard"
                    fullWidth
                />
                <Close sx={{cursor:"pointer"}} onClick={removeHandler.bind(null,index)}/>
            </MDBox>         
        )
    }
    return(
        <MDBox>
            <Button onClick={() => {
             setDlcData(prev => {
                return{
                    ...prev,
                    numbers : [...prev.numbers,""]
                }
             });   
            }}><Add/> Add Number</Button>
            {dlcData?.numbers.length > 0 && renderNumbers()}
        </MDBox>
    )
}
export default AddNumber;