import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useSelector } from "react-redux";
import { useCallback, useRef, useEffect } from "react";
import UserShortcuts from "components/Shortcuts/UserShortcuts";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import WApprovedTemplate from "components/Whatsapp/WApprovedTemplate";
import RecordUsingWave from "components/Audio/RecordUsingWave";
import { Popover, Tooltip } from "@mui/material";
import usePopup from "hooks/use-popup";
import IEmoji from "components/IChat/IEmoji";
import { isValidSize } from "util/MediaUtil";
import { toast } from "react-toastify";
import { ERRORS } from "constants/AppConstants";

const MessageIconsFunctionality = ({onAttachmentAdded,messageState,onEmojiSelect,setMessageState,handleTemplateMessage,setAudioMessage}) => {
    
    // const [action, setAction] = useState("");
    const inputRef = useRef();
    const { popoverId, anchorEl, openPopover, handleOpenPopup, handleClosePopup } = usePopup();

    const view = useSelector(state => state.chat.current_active_view);
    const currentMsgView = useSelector(state => state.chat.current_message_view); 

    const onTemplateSend = (res) => {
        handleTemplateMessage(res);
    }

    const addEmoji = (emoji) => {
        // const emojiConvertor = new EmojiConvertor();
        // emojiConvertor.replace_mode = "unified";
        // const convertedEmoji = emojiConvertor.replace_colons(emoji?.colons);
        onEmojiSelect(emoji?.native)
        // setMessageState(prev => {
        //     return {
        //         ...prev,
        //         message: prev?.message + "" + convertedEmoji
        //     }
        // });
    }

    const handleClickImage = () => {
        if (inputRef.current) {
            inputRef.current.value = null;
            inputRef.current.click();
        }
    };

    const handleChangeFileInput = useCallback((event) => {
        if (event.target) {
            const target = event.target;
            const file = target.files?.[0];
            if (file) {
                if(isValidSize(file,5)){
                    onAttachmentAdded(file);
                }
                else{
                    toast.error(ERRORS.TELE_FILE_SIZE_ERR);
                }
            }
        }
    }, []);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.addEventListener("change", handleChangeFileInput);
        }
        return () => {
            if (inputRef.current) {
                inputRef.current.removeEventListener("change", handleChangeFileInput);
            }
        };
    }, []);

    return(
        <>
            <UserShortcuts onShortcutSelected = {(res) => {
                const m =  messageState.message + res?.trim(); 
                setMessageState(prev => {
                    return {
                    ...prev,
                    message : m
                    }
                })
            }} /> 
            <RecordUsingWave setAudioMessage={setAudioMessage}/>
            <input ref={inputRef} type="file" id="upload-input" hidden />
            <Tooltip title="Attach"><AttachFileIcon style={{ cursor: "pointer" }} onClick={handleClickImage}/></Tooltip> 
            <Tooltip title="Emoji">
                <SentimentSatisfiedAltIcon style={{ cursor: "pointer", marginLeft: "6px" }} onClick={handleOpenPopup}/>
            </Tooltip>
            <Popover
                id={popoverId}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClosePopup}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
            >
                <IEmoji addEmoji={addEmoji}/>
            </Popover>  
            {view === 'NORMAL_VIEW' && <WApprovedTemplate onTemplateSend={onTemplateSend} />} 
        </>
    )
}
export default MessageIconsFunctionality;