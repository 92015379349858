import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, IconButton, InputAdornment } from "@mui/material";
import { setEmailCred } from "api/settings";
import AppConstants from "constants/AppConstants";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const emailSettings = {
  email : '',
  name : '',
  password : ''
}
const EmailSetting = () => {

    const token = useSelector(state => state.user.token);
    
    const [isEditing, setIsEditing] = useState(false);
    const [email, setEmail] = useState(emailSettings?.email);
    const [name,setName] = useState(emailSettings?.name);
    const [password, setPassword] = useState(emailSettings?.password);
    const [passVisible, setPassVisible] = useState(false);

    const {sendRequest : updateCred , status, data, error} = useHttp(setEmailCred,false);

    const onNameChangeHandler = (event) => {
      setIsEditing(true);    
      setName(event.target.value);
    }

    const onPasswordChangeHandler = (event) => {
      setIsEditing(true);    
      setPassword(event.target.value);
    }
    const onEmailChangeHandler = (event) => {
      setIsEditing(true);    
      setEmail(event.target.value);
    }

    const onCredSave = useCallback(() => {
      if(email.trim().length === 0 || email === undefined || password.trim().length === 0 || password === undefined){
        toast.error("Please enter valid gmail credentials");
        return;
      }
      updateCred({name,email,password,token,id:emailSettings?.id});
      setIsEditing(false);
    },[emailSettings]);

    useEffect(() => {
      if(status === 'completed'){
        if(data){
          toast.success(AppConstants.UPDATED_SUCCESS);
        }
        else{
          toast.error(error);
        }
      }
    },[status,data,error])
  return (
    <Box sx={{ width: "100%", p: 2, height: "100%" }}>
      <MDBox display="flex" flexDirection="row" justifyContent="flex-end">
          {isEditing && <MDButton onClick={onCredSave} variant="gradient" color="info" style={{marginLeft:'10px'}}>Save changes</MDButton>}
      </MDBox>
      <MDBox p={2}>
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          Gmail Settings
        </MDTypography>
      </MDBox>
      <MDBox display="flex" flexDirection="column">
        <MDInput
          style={{ width: "50%", margin: "8px" }}
          type="text"
          label="Sender name"
          onChange={onNameChangeHandler}
          value={name}
        />
        <MDInput
          style={{ width: "50%", margin: "8px" }}
          type="email"
          label="Email"
          onChange={onEmailChangeHandler}
          value={email}
        />
        <MDInput
          style={{ width: "50%", margin: "8px" }}
          type={passVisible ? 'text' : "password"} 
          label="20 Digit App Password"
          onChange={onPasswordChangeHandler}
          value={password}
          InputProps={{
            endAdornment: <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setPassVisible(prev => !prev)}
                edge="end"
              >
                {passVisible ? <VisibilityOff /> : <Visibility />}
              </IconButton>
              {/* <VisibilityIcon 
                onClick={() => setPassVisible(prev => !prev)}
                sx={{
                  position: "absolute",
                  right: "10%",
                  top: "40%",
                  cursor:'pointer'
              }}/> */}
              </InputAdornment>
          }} 
        />      
      </MDBox>
    </Box>
  );
};
export default EmailSetting;
