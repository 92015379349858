//// Get recipient map for selection using checkboxes for future usecase...
export const getRecipientMap = (recipients) => {
  const currMap = {};
  for (const i of recipients) {
    const p = {
      selected: false,
      messageIds: [],
    }
    currMap[i.id] = p;
  }
  return currMap;
}

export const getCheckedRecipientMap = (recipients,checked) => {
  const currMap = {};
  for (const i of recipients) {
    const p = {
      selected: checked[i.id]?.selected?true:false,
      messageIds: [],
    }
    currMap[i.id] = p;
  }
  return currMap;
}

export const getAllCheckedRecipientMap = (recipients) => {
  const currMap = {};
  for (const i of recipients) {
    const p = {
      selected: true,
      messageIds: [],
    }
    currMap[i.id] = p;
  }
  return currMap;
}