import React, { useState } from 'react';
import { setMinutes, setHours } from 'date-fns';
import { useEffect } from 'react';
import { MenuItem } from '@mui/material';
import CustomSelect from 'ui/Select';
import { formatedTimeSlot } from 'util/UtilityFunctions';

//TAKE a constant date for comparison purpose as date does not have any impact on time slots...
// For example here we are considering 1-1-1970
const options = [];
for (let hours = 0; hours < 24; hours++) {
  for (let minutes = 0; minutes < 60; minutes += 15) {
    const time = setMinutes(setHours(new Date(1970,1,1), hours), minutes);
    options.push(time);
  }
}

const TimePicker = ({onSelected,initialValue = undefined}) => {
  
  console.log({initialValue})
  const [selectedTime, setSelectedTime] = useState();

  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedTime(value);
  };

  useEffect(() => {
    if(initialValue) {      
      const findIdx = options.findIndex(itm => itm === initialValue);
      setSelectedTime(options[findIdx]);
    }
    else{
      setSelectedTime(options[0]);
    }
  },[initialValue,setSelectedTime,onSelected]);

  useEffect(() => {
    if(selectedTime){
      onSelected(selectedTime);
    }
  },[selectedTime,onSelected]);

  return (
    <>
      {selectedTime && 
        <CustomSelect
          sx={{width : '100px',height: "32px"}}
          onChange={handleChange}
          value={selectedTime}
        >
          {options.map((itm,index) => <MenuItem key={index + itm} value={itm}>{formatedTimeSlot(itm)}</MenuItem>)}
        </CustomSelect>
      }
    </>
  );
};

export default TimePicker;
