import axios from "../../core/axios";
import { MESSAGE } from "constants/Endpoints"
import { GROUP_CHAT_MESSAGE } from "constants/Endpoints"
import { GROUP } from "constants/Endpoints"
import { CHAT_STRING } from "constants/Endpoints"
import { CREATE_GROUP } from "constants/Endpoints"

export const IGroupAPI = {
    createGroup : async({token,payload}) => {
        return await axios.post(CREATE_GROUP,JSON.stringify(payload),{
            headers:{
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',                
            }
        })
    },

    createGroupMessage : async({token,payload}) => {
        return await axios.post(CHAT_STRING + GROUP +  MESSAGE,JSON.stringify(payload),{
            headers:{
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',                
            }
        })
    },

    getGroupChatMessages : async({token,chatId}) => {
        return await axios.get(GROUP_CHAT_MESSAGE(chatId),{
            headers:{
                'Authorization': 'Bearer ' + token                
            }
        })
    },    
} 