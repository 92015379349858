import { Download } from '@mui/icons-material';
import { Avatar, Typography } from '@mui/material';
import fetch from 'core/fetch';
import MDBox from 'lib/components/MDBox';
import React, { useState, useEffect } from 'react';
import vCardParser from 'vcard-parser';

const VCFReader = ({ url }) => {
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const fetchVCF = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Failed to fetch VCF file');
        }
        const vcfText = await response.text();
        const parsedContacts = vCardParser.parse(vcfText);
        let _contacts = []
        parsedContacts?.fn?.map((_v,i)=>{
          if(_contacts?.[i]){
            _contacts[i].fn = _v.value
          }else{
            _contacts.push({fn:_v.value});
          }
        })
        parsedContacts?.tel?.map((_v,i)=>{
          if(_contacts?.[i]){
            _contacts[i].tel = _v.value
          }else{
            _contacts.push({tel:_v.value});
          }
        })
        parsedContacts?.email?.map((_v,i)=>{
          if(_contacts?.[i]){
            _contacts[i].email = _v.value
          }else{
            _contacts.push({email:_v.value});
          }
        })
        console.log('_contacts',_contacts)
        setContacts(_contacts);
      } catch (error) {
        console.error('Error fetching VCF file:', error);
      }
    };
    fetchVCF();
  }, [url]);

  return (
    <MDBox display="flex" flexDirection="column">
        <MDBox display="flex" justifyContent="space-between" alignItems="center" sx={{padding:'5px 10px'}}>         
            <a style={{textDecoration:'none',color:'black'}} href={url} target='_blank'><Download sx={{height:'18px',width:'18px'}}/></a>
            <Typography variant='h6' m={1} fontSize={'14px'}>Contacts</Typography>
        </MDBox>  
      <MDBox>
        {contacts?.map((contact, index) => (
          <MDBox display="flex" key={index} sx={{background:'lightgray',margin:'10px',padding:'5px',borderRadius:'10px',alignItems:'center'}}>
            <Avatar sx={{ bgcolor: 'rgb(24, 118, 242)',marginLeft:'5px' }}>{contact?.fn?.charAt(0)}</Avatar>
            <MDBox key={index} sx={{marginLeft:'10px'}}>
            <Typography variant='h6'>{contact?.fn}</Typography>
            <Typography fontSize={'12px'}>{contact?.email}</Typography>
            <Typography fontSize={'12px'}>{contact?.tel}</Typography>
            </MDBox>
            {/* Add more fields as needed */}
          </MDBox>
        ))}
      </MDBox>
    </MDBox>
  );
};

export default VCFReader;
