import { useState } from "react";
import CampaignMessage from "./CampaignMessage";
import MDBox from "lib/components/MDBox";
import AddButton from "ui/AddButton";

const CREATE_TYPE = {
    PARTICULAR_AMOUNT: "particular amount",
  };
  
  const CreateCampaignMessage = ({campaignId,onMessageCreated}) => {
    const [action, setAction] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
  
    const handleClick = (event) => {
      setAction(CREATE_TYPE.PARTICULAR_AMOUNT)
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    return (
      <>
        <MDBox
          sx={{}}
          mt={1}
          ml={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <AddButton onClick={handleClick} text={"Add message"}/>
        </MDBox>
       
        {action === CREATE_TYPE.PARTICULAR_AMOUNT && (
          <CampaignMessage  campaignId={campaignId} onChange={() => setAction("")} onMessageCreated={onMessageCreated} />
        )}
      </>
    );
};
export default CreateCampaignMessage;