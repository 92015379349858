import { Edit, NotificationsOff } from "@mui/icons-material";
import { Avatar, Badge, Icon, List, ListItem } from "@mui/material";
import { StyledBadge } from "components/util/OnlineBadge";
import { WEBSOCKET_EVENT_TYPE } from "constants/ChatConstants";
import MDBox from "lib/components/MDBox";
import { UserChatBlock } from "pages/Chat/ChatStyles";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import IHover from "../IHover";
import { useState } from "react";
import { ChatAPI } from "services/IChat/ChatAPI";
import { IGroupAction } from "store/IChat/igroup-slice";
import GroupsIcon from '@mui/icons-material/Groups';
import MDTypography from "lib/components/MDTypography";
import { CALENDAR_STRING } from "constants/ChatConstants";
import MoreVertIcon from '@mui/icons-material/MoreVert';

const Group = ({chat,latestMessage}) => {

    const timezone = useSelector(state => state.userDetails?.details?.userSettings?.timezone)

    const dispatch = useDispatch();
    const currentActiveGroup = useSelector(state => state.Igroup.currentActiveGroup);
    const token = useSelector(state => state.user.token);
    const draftsMessage = useSelector(
        (state) => state.draftsMessage?.draftsMessage
      );
    const [showoptions,setShowOptions] = useState(false);
      
    const [targetElement,setTargetElement] = useState(undefined);
  
    const toggleMute = (chatId,participantId,mute) => {
      ChatAPI.toggleMute({token,chatId,participantId,muted : mute});
      dispatch(IGroupAction.toggleMute({chatId,muted : mute}));
      onClose();
    }
  
    const onClose = () => {
      setTargetElement(undefined);
      setShowOptions(false);
    }
  
    const updateCurrentChat = () => {
        dispatch(IGroupAction.updateCurrentActiveGroup(chat));
    }
    
    return(
        <>
            <UserChatBlock 
                key={"chat_block" + chat?.chatId} 
                currentSelected={currentActiveGroup?.chatId === chat?.chatId} 
                onMouseEnter={() => setShowOptions(true)} 
                onMouseLeave={() => setShowOptions(false)}
                className="draft"
            >
            {chat?.unreadCount > 0  && <Badge
                sx={{ position: "absolute",right:25 , top:15}}
                color="secondary"
                badgeContent={chat?.unreadCount}
                />
            }  
            <MDBox onClick={updateCurrentChat} width="100%" key={chat?.chatId} component="li" display="flex" alignItems="center" py={1} mb={1}>
                <MDBox mr={2}>
                    <StyledBadge
                        overlap="circular"
                        invisible={!chat?.online}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                    >
                        <Avatar shadow="md"  sx={{bgcolor: "#1268db", width: 42, height: 42 }}>
                            <GroupsIcon />
                        </Avatar>
                    </StyledBadge>
                </MDBox>
                <MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                    sx={{maxWidth:'255px'}}
                >
                <MDTypography variant="button" fontWeight="medium">
                    {chat?.name}
                </MDTypography>
                {(draftsMessage[chat?.chatId]?.message?.length>0 || draftsMessage[chat?.chatId]?.attachement) &&
                      <MDTypography sx={{marginTop : '4px',height:'16px',overflow:'hidden',textOverflow:'ellipsis'}} variant="caption" color="text">
                       <MDTypography sx={{marginTop : '4px',height:'16px',overflow:'hidden',textOverflow:'ellipsis'}} variant="caption" color="text">
                        [Draft] 
                        {draftsMessage[chat?.chatId]?.message} {draftsMessage[chat?.chatId]?.attachement  
                        && <Icon>perm_media</Icon> } {draftsMessage[chat?.chatId]?.attachement  
                            && "file" }
                        </MDTypography>

                        <Edit sx={{color:"red", marginLeft:"10px" }}/> 
                      </MDTypography> }
                <MDBox display="flex">
                    <MDTypography sx={{marginTop : '4px',height:'16px',overflow:'hidden',textOverflow:'ellipsis'}} variant="caption" color="text">{chat?.latestMessage? chat?.latestMessage?.sender?.fullName : chat?.sender?.fullName} {" : "} </MDTypography>
                    <MDTypography sx={{marginTop : '4px',height:'16px',overflow:'hidden',textOverflow:'ellipsis'}} variant="caption" color="text">
                        &nbsp;{latestMessage ?`${latestMessage.text}` : 'No conversation history.'}
                    </MDTypography>
                </MDBox>
                {/* {chat.iEvent && chat.iEvent.type === WEBSOCKET_EVENT_TYPE.USER_TYPING && chat?.online &&<MDTypography fontSize={"12px"} color={"info"}>{"Typing..."}</MDTypography>} */}
                </MDBox>
            </MDBox>
            <MDBox ml="auto" mr={2} sx={{position:'relative'}}>
                {chat?.muted && <NotificationsOff sx={{height:'18px',width:'18px',position:'absolute',bottom:20,right:0}}/>}
                <MDTypography fontSize={"12px"} sx={{minWidth:'52px'}}>
                {latestMessage && <Moment tz={timezone} calendar={CALENDAR_STRING}>
                    {latestMessage?.createdAt}
                </Moment>}
                </MDTypography>
            </MDBox>
            {showoptions && 
            <>
                <MoreVertIcon onClick={e => setTargetElement(e.currentTarget)} sx={{position: "absolute",right:0,top:4}} key={chat?.chatId}/>
                <IHover onClose={onClose} target={targetElement}>
                {chat?.muted ? 
                <List onClick={() => {toggleMute(chat.chatId,chat.meParticipantId,false)}}>
                <ListItem 
                        sx={{
                        fontSize:'14px',
                        cursor:'pointer',
                        width : '100%'
                        }}>
                        <span style={{fontSize:'14px !important',fontWeight:'400'}}>{'Unmute'}</span>
                    </ListItem>
                </List>
                    : 
                    <List  onClick={() => {toggleMute(chat.chatId,chat.meParticipantId,true)}}> 
                    <ListItem 
                        sx={{
                        fontSize:'14px',
                        cursor:'pointer',
                        width : '100%'
                        }}>
                        <span style={{fontSize:'14px !important',fontWeight:'400'}}>{'Mute'}</span>
                    </ListItem>
                    </List>
                    }
                </IHover>
            </>
            } 
            </UserChatBlock>
      </>        
    )
}
export default Group;