import { Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { getAppProducts } from "api/product";
import AppConstants from "constants/AppConstants";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CompanyAPI } from "services/CompanyAPI";

const ProductSubscriptions = ({company,onHipaaSubscribed,onProductSubscribed,onProductUnsubscribed}) => {

    const token = useSelector(state => state.user.token);
    
    const [subscribedFeatures,setSubscribedFeatures] = useState([]);

    const {sendRequest : getAppProductsFn, status : productStatus, data : productData} = useHttp(getAppProducts,false);

    const onHipaaChange = useCallback((e) => {
        //call endpoint to update Hipaa status
        CompanyAPI.updateHipaaSubscription({token,companyId : company.company_id, subscribed : e.target.checked})
        .then(res =>{
            toast.success('Subscribed for HIPAA successfully.')
            onHipaaSubscribed(company.company_id,e.target.checked);
        }).catch(e => {
            toast.error("Something went wrong, Please try again later.");
        })
    },[company,token])

    const onProductSubscribedHandler = useCallback((subscribed,prod) => {
        //Either getting subscribed or unsubscribe
        let payload = {};
        if(!subscribed){
            // filter it and remove it
            payload = subscribedFeatures?.find(itm => itm.product?.product_id === prod.product_id);
            const newFeatures = subscribedFeatures?.filter(itm => itm.product?.product_id != prod.product_id);
            setSubscribedFeatures(newFeatures);
        }
        else{
            payload = {
                product : prod,
                subscribed
            }
        }
        // Call the endpoint and save the latest status
        CompanyAPI.updateSubscription({payload,token,companyId : company?.company_id}).then(res => {
            if(subscribed){
                toast.success("Subscribed successfully.");
                onProductSubscribed({companyId : company?.company_id, subscription : res.data});
            }
            else if(!subscribed){
                toast.success("Unsubscribed successfully.");
                onProductUnsubscribed({companyId : company?.company_id, subscription : res.data});
            }
        }).catch(e => {
            console.log(e);
            toast.error(AppConstants.SOMETHING_WENT_WRONG)
        })
    },[subscribedFeatures,company]);

    //2 scenario :: Either product already subscribed :: => 2 sub or unsub
    // OR first time getting subscribed :: :: => 2 sub or unsub
    const isSubscribed = (p) => {
        const d = subscribedFeatures?.find(f => f?.product?.product_id === p?.product_id);
        return d;
    }

    useEffect(() => {
        getAppProductsFn(token);
    },[]);

    useEffect(() => {
        if(company && company?.products_subscribed != null){
            setSubscribedFeatures(company.products_subscribed);
        }
    },[company])

    return(
        <>
            <MDBox display="flex" flexDirection="column" sx={{
              marginTop:'16px'
            }}>
              <Typography variant="h5" textAlign={"center"}>Features subscribed</Typography>
              <MDBox>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox defaultChecked={company?.hipaa_compliance}
                      onChange={onHipaaChange}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}/>} label={"HIPAA Compliance"}/>
                  </FormGroup>
              </MDBox>
              {productStatus === 'completed' && productData?.length > 0 && company && <FormGroup>
                {productData.map(p => <FormControlLabel key={p.product_id} control={<Checkbox value={p} defaultChecked={Boolean(isSubscribed(p))} onChange={(e) => onProductSubscribedHandler(e.target.checked,p)} sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}/>} label={p.name}/>)}
              </FormGroup>}
            </MDBox>        
        </>
    )
}
export default memo(ProductSubscriptions);