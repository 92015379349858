import { Button, Grid, Paper, TextField, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { useCallback, useEffect, useState } from "react";

import { getVariablesCount } from "util/WhatsTemplateUtil";
import ChooseFileGeneric from "components/CommonUI/ChooseFileGeneric";
import { createWTemplate } from "api/whatsapp";
import { useSelector } from "react-redux";
import useHttp from "hooks/use-http";
import { toast } from "react-toastify";
import Preview from "./Preview";
import { getAsDataUrl } from "util/MediaUtil";
import { isValidSize } from "util/MediaUtil";

const ExampleTemplate = ({templatePreviewVariables,onTemplateCreated,company,waCredentials}) => {

    const currUser = useSelector(state => state.user);

    const [currTemplate, setCurrentTemplate] = useState();

    const [variables, setVariables] = useState({
        body : [],
        header : [],
    })
    
    const {sendRequest : createWTemplateFn,status, data , error} = useHttp(createWTemplate,false);

    const handleTemplateSubmission = useCallback(() => {
      createWTemplateFn({
        token : currUser.token,
        template : {
            templatePreviewVariables,
            variables,
            companyId : company?.company_id,
            credentials: {...waCredentials}
        }
    });
    },[currUser,templatePreviewVariables,variables,waCredentials,company])

    const handleHeaderFile = (file) => {
        
        const isValid = isValidSize(file,4.5);
        if(!isValid){
            toast.error("The file can't be uploaded. Maximum allowed file size is 4.5MB");
            return;
        }
        if(["image","docs","video"].includes(currTemplate.header.type)){
            const header = Array.isArray(variables.header) ? variables.header : [];
            if(currTemplate.header.type === 'image' || currTemplate.header.type === 'docs'){
                getAsDataUrl(file).then(url => {
                   header[0] = {file : url,type:currTemplate.header.type};
                    setVariables(prev => {
                        return{
                           ...prev,
                           header : header
                        }
                    })
                })
            }
            else if(currTemplate.header.type === 'video'){
                header[0] = {file : URL.createObjectURL(file),type:currTemplate.header.type};
                setVariables(prev => {
                    return{
                       ...prev,
                       header : header
                    }
                })
            }
        }
        
       setCurrentTemplate(prev => {
        return{
            ...prev,
            header : {
                ...prev.header,
                value : file
            }
        }
       })
    }

    const handleBody = (idx,e) => {
        const newBody = [...variables.body];
        newBody[idx].value = e.target.value;

        setVariables(prev => {
            return {
                ...prev,
                body : newBody
            }
        })
    }
    const handleHeader = (idx,e) => {

        const newHeader = [...variables.header];
        newHeader[idx].value = e.target.value;

        setVariables(prev => {
            return {
                ...prev,
                header : newHeader
            }
        })

    }

    useEffect(() => {

        let newText = templatePreviewVariables?.body;

        for(const entry of variables.body){
            newText = newText.replace(entry.key,entry.value);
        }
        setCurrentTemplate(prev => {
            return{
                ...prev,
                body : newText
            }
        });
    },[variables.body])

    useEffect(() => {

        let newText = templatePreviewVariables?.header?.value;

        for(const entry of variables.header){
            newText = newText.replace(entry.key,entry.value);
        }
        setCurrentTemplate(prev => {
            return{
                ...prev,
                header : {
                    ...prev.header,
                    value : newText
                }
            }
        });
    },[variables.header])    

    useEffect(() => {
        if(templatePreviewVariables){
            const ans = getVariablesCount(templatePreviewVariables);
            setVariables(ans);
            setCurrentTemplate(templatePreviewVariables)
        }
    },[templatePreviewVariables]);

    useEffect(() => {
        if(status === 'completed'){
          if(data){
            toast.success("Template is submitted for approval.");
            onTemplateCreated(data);
          }
          else{
            toast.error(error);
          }
        }
    },[status, data, error])

    return (
      <>
        <MDBox display="flex" flexDirection="column">
          <MDBox
            display="flex"
            justifyContent="start"
            flexDirection="column"
            padding="8px"
          >
            <Typography> Add sample place-holders values.</Typography>
            <Typography sx={{ fontSize: "12px" }}>
                Please add sample values for this template here. WhatsApp uses sample values to understand the purpose of the template. (Do not include any customer information.) You must enter a sample value for each placeholder used in the template.
            </Typography>
          </MDBox>
          <MDBox display="flex">
            <MDBox width="65%" display="flex" flexDirection="column">
                {
                    variables.header && currTemplate &&  currTemplate.header.type === 'text' &&
                    <MDBox>
                        {variables.header.map((item,index) => {
                            return <MDBox key={"header_"+index} mt={2} display="flex" flexDirection="column">
                                <Typography fontSize={"14px"}>Header: Value for place-holder {item.key}</Typography>
                                <TextField variant="standard" onChange={handleHeader.bind(null,index)} />
                            </MDBox>
                        })}                       
                    </MDBox>
                }
                {
                    currTemplate && (["image","docs","video"].includes(currTemplate.header.type)) &&
                    <MDBox>
                        <Typography fontSize={"12px"}>Choose {currTemplate.header.type}</Typography>
                        <ChooseFileGeneric onFileSelected={handleHeaderFile}/>
                    </MDBox>
                }
                {variables.body &&
                    <MDBox mt={2}>
                        {variables.body.map((item,index) => {
                            return <MDBox key={"body_"+index} mt={2} display="flex" flexDirection="column">
                                <Typography fontSize={"14px"}>Body : Value for place-holder {item.key}</Typography>
                                <TextField variant="standard" onChange={handleBody.bind(null,index)} />
                            </MDBox>
                        })}
                    </MDBox>
                }             
            </MDBox>

            { currTemplate && <Preview currTemplate={currTemplate}/>}
          </MDBox>
          <MDBox display="flex" width="100%" justifyContent="flex-end">
            <MDBox display="flex">
              <Button
                onClick={handleTemplateSubmission}
                variant="contained"
                sx={{
                  borderRadius: "0px",
                  color: "white!important",
                  border: "unset",
                  marginLeft: "12px",
                  height: "32px",
                  minHeight: "unset",
                }}
              >
                Submit
              </Button>
            </MDBox>
          </MDBox>
        </MDBox>
      </>
    );
}
export default ExampleTemplate;