import { FormatPhoneNumber } from "./UtilityFunctions";

export const addRecipientToGroup = (groups,recipient,groupId) => {
    const newState = groups.map(item => {
        if(item.groupId === groupId){
            if(item?.recipientPhone?.length > 0){
                item.recipientPhone = [...item.recipientPhone,recipient]
            }
            else{
                item.recipientPhone = [recipient];
            }
        }
        return item;
    })
    return newState;
} 

export const getRecipientFormatted = (recipientList) => {
    if(recipientList?.length > 0){
        const len = recipientList?.length < 10 ? recipientList?.length : 10;
      let r = '';
      for(let i = 0; i < len; i++){
        const t = FormatPhoneNumber(recipientList[i]?.contact?.phone,recipientList[i]?.contact?.countryCode);
        r += t + ', ';
      }
      return r;
    }
    else return 'No recipients yet...';
}

export const removeRecipientFromGroup = (groups,recipientId,groupId) => {
    const newState = groups.map(item => {
        if(item.groupId === groupId){
            item.recipientPhone = item.recipientPhone.filter(r => r.id !== recipientId);
        }
        return item;
    })
    return newState;
} 

export const isPromotionalGroup = (group) => {
    return group.category?.keyword === 'promotional' || group.category?.keyword === 'controlled';
}