import { useEffect, useState } from "react";
import UserCellCallConfig from "./UserCellCallConfig";
import CustomSelect from "ui/Select";
import MDBox from "lib/components/MDBox";
import { MenuItem, Typography } from "@mui/material";

const UserCellCallConfigWrapper = ({user,onDisaUpdated}) => {

    const [phoneId,setPhoneId] = useState(user?.phones?.[0]?.phoneId);
    const [allConfig,setAllConfig] = useState({});
    const [phoneWideEnabled,setPhoneWideEnabled] = useState(false);
    useEffect(() => {
        if(user?.customCellConfig){
            const allKys = {};
            for(const ky of user?.customCellConfig){
                allKys[ky.phoneId] = ky.cellConfig 
            }
            setAllConfig(allKys);
        }
    },[user?.customCellConfig])

    useEffect(() => {
        const enabled = user?.phones?.find(itm => itm.phoneId === phoneId);
        if(enabled){
            setPhoneWideEnabled(enabled.cellConfig?.enabled);
        }
    },[phoneId])
    
    return(
        <MDBox display="flex" flexDirection="column" width="100%">
            {user.phones?.length > 0 ?
                <>
                    <CustomSelect value={phoneId} onChange={e => setPhoneId(e.target.value)} sx={{ width: "100%", height: "32px",marginBottom:'12px'}}>
                        {user.phones?.map(itm => <MenuItem key={itm.phoneId} value={itm?.phoneId}>{itm?.phone}</MenuItem>)}
                    </CustomSelect>
                    {
                        phoneWideEnabled ? 
                            <UserCellCallConfig key={phoneId} phoneId={phoneId} userId={user.userId} config={allConfig[phoneId]} onDisaUpdated={onDisaUpdated}/>
                        : 
                        <MDBox display="flex" justifyContent="center" alignItems="center">
                            <Typography fontWeight={600} fontSize={'14px'}>{"Can't set cellular calls for this user because cellular calls is not currently enabled in phone number settings."}</Typography>
                        </MDBox>
                    }
                </>
                :
                    <MDBox display="flex" justifyContent="center" alignItems="center">
                        <Typography fontWeight={600}>{"User does not have any phone number assigned."}</Typography>
                    </MDBox>
            }
        </MDBox>
    )
}
export default UserCellCallConfigWrapper;