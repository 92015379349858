import placeholder from "assets/images/image_placeholder.png";
import video_placeholder from "assets/images/play-button.svg";
import doc_placeholder from "assets/images/document-image.svg";
import style from "./Preview.module.css";
import MDBox from "lib/components/MDBox";
import { Grid, Paper } from "@mui/material";
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0.25),
    textAlign: 'center',
    color: '#0fa6f4',
}));

const Preview = ({ currTemplate }) => {
  return (
    <MDBox width="35%" padding={"12px"}>
      <div className={style["whatsapp-message"]}>
        <div className={style["whatsapp-avatar"]} />
        <div className={style["whatsapp-message-content"]}>
          <div
            className={style["whatsapp-sender"]}
            style={{ fontSize: "14px" }}
          >
            Message Preview
          </div>
          <div className={style["whatsapp-text"]}>
            {currTemplate?.header?.type === "text" && (
              <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                {currTemplate.header.value}
              </div>
            )}
            {currTemplate?.header?.type === "image" && (
              <div>
                <img
                  src={
                    currTemplate?.header?.value
                      ? URL.createObjectURL(currTemplate.header.value)
                      : placeholder
                  }
                  height="100"
                  width="200"
                  style={{ objectFit: "contain" }}
                />
              </div>
            )}
            {currTemplate?.header?.type === "docs" && (
              <div>
                <img
                  src={doc_placeholder}
                  height="100"
                  width="200"
                  style={{ objectFit: "contain" }}
                />
              </div>
            )}
            {currTemplate?.header?.type === "video" && (
              <div>
                {currTemplate?.header?.value ? (
                  <video
                    height="100"
                    width="200"
                    src={URL.createObjectURL(currTemplate?.header?.value)}
                  ></video>
                ) : (
                  <img
                    src={video_placeholder}
                    height="100"
                    width="200"
                    style={{ objectFit: "contain" }}
                  />
                )}
              </div>
            )}
            <div>{currTemplate?.body}</div>
            <div
              style={{
                marginTop: "6px",
                color: "#818181",
                fontSize: "14px",
                fontWeight: "100",
              }}
            >
              {currTemplate?.footer}
            </div>
          </div>
          <div style={{ width: "200px" }}>
            {currTemplate.btn?.type === "call" && (
              <Grid container>
                {currTemplate.btn?.callActions?.map((item) => {
                  return item.text !== "" ? (
                    <Grid item xs={12} sx={{ marginBottom: "8px" }}>
                      <Item>{item.text}</Item>
                    </Grid>
                  ) : undefined;
                })}
              </Grid>
            )}
            {currTemplate.btn?.type === "reply" && (
              <Grid container spacing={1}>
                {currTemplate.btn?.reply?.map((item) => {
                  return item !== "" ? (
                    <Grid sx={{ marginBottom: "8px" }} item xs={12}>
                      <Item>{item}</Item>
                    </Grid>
                  ) : undefined;
                })}
              </Grid>
            )}
          </div>
          <div className={style["whatsapp-timestamp"]}>Just now</div>
        </div>
      </div>
    </MDBox>
  );
};

export default Preview;