import AppConstants from 'constants/AppConstants';
import { addMinutes, compareAsc, format, isBefore, parse } from 'date-fns';
import moment from 'moment';
import 'moment-timezone';

export const FormatPhoneNumber = (phoneNumber,countryCode,format = true) => {
    ///// get 10 digits from the number
    //// (586)846-9685
    if(!phoneNumber) return;
    let finalRes = '';
    if(format){
        finalRes = '+'
        if(countryCode){
            finalRes += countryCode + " ";
        }
        else{
            finalRes += '1 ';
        }
    }
    let result = '';
    if(phoneNumber?.length <= 5){
        result = finalRes + phoneNumber;
        return result;
    }
    for(let i = 0; i < phoneNumber.length ;  i++){
        if(!isNaN(phoneNumber[i])){
            result += phoneNumber[i];
        }
    }
    for(let i = 0; i < result.length ;  i++){
        if(i === 2){
            finalRes += result[i] + '-';
        }
        else if(i === 6){
            finalRes += '-' + result[i];
        }
        else{
            finalRes += result[i];
        }
    }
    return finalRes;
}
export const getProperDateWithTime = (date) => {
    return moment(date).format('LT') + ', ' + moment(date).format('ll');
}
export const getProperDate = (date,pattern) => {
    if(!date) {
        return '';
    }
    if(pattern) {
        return moment(date).format(pattern);
    }
    //April12 , 2023.    
    return moment(date).format('LL');
    // Apr 12, 2023
    // return moment(date).format('ll');
}

export const getDateConvertedFromUTC = (date) => {
    return moment.utc(date).local();
}

export const checkIfDisaIsValid = (val) => {
    return val.includes(AppConstants.DISA_DESTINATION_PLACEHOLDER);
}

export const  searchTextFormated = (message,pattern) => {
    let originalMsg = message;
    message = message.toLowerCase();
    pattern = pattern.toLowerCase();
    if(message === '' || message.trim().length === 0){
        return '';
    }
    let text = '';
    let tempText = '';
    for(let i = 0; i < message.length; i++){
        let j = 0;
        let previ = i;
        if(message.charAt(i) === pattern.charAt(j)){
            tempText = `<mark>${originalMsg.charAt(i)}`;
            i++;
            j++;
            while( j < pattern.length && i < message.length && message.charAt(i) === pattern.charAt(j)){
                tempText += originalMsg.charAt(i);
                i++;
                j++;
            }
            tempText += '</mark>';
            if(j >= pattern.length){
                text += tempText;
                while(i < message.length){
                    text += originalMsg.charAt(i);
                    i++;
                }
                break;
            }        
            else{
                i = previ;
                tempText = '';
                text += originalMsg.charAt(i);
                continue;
            }    
        }
        //// Means the pattern character are exhausted
        else{
            text += originalMsg.charAt(i);
            // text = '';
        }
    }
    return text;
}

export const searchTextByTagFormated = (message,pattern) => {
    let originalMsg = message;
    let text = originalMsg.message;
    if(pattern === '') {
        return originalMsg.message;
    }
    for(let i = 0; i < message.tags.length; i++){        
        if(message.tags[i]?.name.toLowerCase().includes(pattern.toLowerCase())){
            text = '<mark>' + originalMsg.message + '</mark>';
            return text;
        }
    }
    return text;
}

export const searchTextByUserFormated = (message,pattern) => {
    let originalMsg = message;
    let text = originalMsg.message;
    if(pattern === '') return message.message;
    const searchIn = message?.sendByUser?.fullName.toLowerCase(); 
    pattern = pattern.toLowerCase();
    if(searchIn?.includes(pattern)){
         text = '<mark>' + message.message + '</mark>';
         return text;
    }
    return text;
}

export const isCurrentPhoneBlockedForTheGivenRecipient = (currentActivePhone,currentActiveRecipient,recipientList) => {
    const currentRecipient = recipientList?.find(item => item.id === currentActiveRecipient);
    const blockedList = currentRecipient?.blockedPhones?.filter(item => item.blockedPhone === currentActivePhone?.phone);
    if(blockedList?.length > 0){
        return true;
    }
    return false;
}
export const isRecipientAlreadyAdded = (recipientId,recipientList) => {
    const currentRecipient = recipientList?.find(item => item.id === recipientId);
    if(currentRecipient){
        return true;
    }
    else{
        return false;
    }
}
export const getPercentage = (raised_so_far,goal_amount) => {
    if(raised_so_far === undefined || goal_amount === undefined){
      return 0;
    }
    if(raised_so_far === 0 || goal_amount === 0){
        return 0;
      }
    const per = Number((Number(raised_so_far / goal_amount) * 100).toFixed(2));
    return per > 100 ? 100 : per; 
}

export const isDateTimeSmaller = (from,to) => {
    
    const date1 = new Date(from); // August 30, 2023
    const date2 = new Date(to); // September 15, 2023
    const comparisonResult = compareAsc(date1, date2);
    
    if (comparisonResult === -1) {
        return true;
    } else if (comparisonResult === 1) {
        return false;
    } else {
        return false;
    }
}

export const captializeFirstLetter = (name) => {
    return name?.charAt(0).toUpperCase() + name.slice(1);
}

export const isFromInAMPMSmaller = (from,to) => {
    return isBefore(new Date(from), new Date(to));
} 

export const add15MoreMins = (currentTimeString) => {
    // Parse the current time string
    if(!currentTimeString) {
        const initial = parse("12:00 AM", 'hh:mm aa', new Date(1970,1,1));
        return initial;
    }
    const currentTime = parse(currentTimeString, 'hh:mm aa', new Date(1970,1,1));

    // Add 15 minutes to the current time
    const newTime = addMinutes(currentTime, 15);

    // Format the new time back into a time string
    // const newTimeString = format(newTime, 'hh:mm aa');
    return newTime;
}
export const formatedTimeSlot = (time) => {
    return format(time, 'hh:mm aa');
}
export const formatAudioTimer = (time) => {
    const minutes = Math.floor(time / 6000);
    const seconds = Math.floor((time % 6000) / 100);
    return `${String(minutes).padStart(1, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

export function obfuscatedEmail(email) {
    const [username, host] = email.split('@');
    if(username.length < 4){
        return email;
    }
    const firstTwoLetters = username.slice(0, 2); // Get the first two letters of the username
    const lastLetter = username.slice(username.length - 1);
    const obfuscatedUsername = firstTwoLetters + '*'.repeat(username.length - 3) + lastLetter; // Replace the remaining characters with '*'
    return obfuscatedUsername + '@' + host;
}
export function obfuscatedNumber(number) {
    if(!number){
        return '';
    }
    const firstTwoLetters = number.slice(0, 2); // Get the first two letters of the username
    const lastTwoLetters = number.slice(number.length - 2);
    let obfuscatedValue = firstTwoLetters + '*'.repeat(number.length - 4); // Replace the remaining characters with '*'
    obfuscatedValue += lastTwoLetters;
    return obfuscatedValue;
}

export const parseWSData = (res) => {
    try{
        return JSON.parse(res);
    }
    catch(e) {
        console.log(e);
    }
};
export const sortByString = (a,b) => {
    let fa = a.toLowerCase();
    let fb = b.toLowerCase();
    if (fa < fb) {
        return -1;
    }
    if (fa > fb) {
        return 1;
    }
    return 0;
}

export const validateDate = (inputDate) => {
    // Parse the input date string to a Date object
    const enteredDate = new Date(inputDate);
    
    // Get the current date
    const currentDate = new Date();

    // Compare the entered date with the current date
    if (enteredDate < currentDate) {
    return false; // Date is smaller than today
    }

    return true; // Date is valid
}
export const formatUserName = (name) => {
    name = name.toLowerCase();
    name = name.replace(/[^a-zA-Z_0-9 ]/g, '');
    name = name.replace(" ","_");
    return name;
}
export const formatWAName = (name) => {
    name = name.toLowerCase();
    name = name.replace(/[^a-zA-Z_ ]/g, '');
    name = name.replace(" ","_");
    return name;
}