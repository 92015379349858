import DashboardLayout from "lib/layouts/LayoutContainers/DashboardLayout";
import { useCallback, useEffect, useState } from "react";
import AppModal from "ui/AppModal";
import AddCredentialsForm from "components/Phone/AddCredentialsForm";
import { useSelector } from "react-redux";
import PhoneCredentialsList from "components/Phone/PhoneCredentialsList";
import DashboardNavbar from "lib/layouts/Navbars/DashboardNavbar";
import AdminPhoneCredentialsList from "components/Phone/AdminPhoneCredentialsList";
import ArrowAddBtn from "ui/ArrowAddBtn";
import NotificationItem from "lib/layouts/Items/NotificationItem";
import UploadWhatsAppBridgeNumbers from "components/WhatsAppBridge/UploadWhatsAppBridgeNumbers";
import WhatsAppBridge from "components/WhatsAppBridge";
import MDBox from "lib/components/MDBox";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { ROLE } from "constants/AppConstants";
import { PhoneAPI } from "services/PhoneAPI";

const CREATE_TYPE = {
  PHONE: "phone",
  BRIDGE: "bridge",
  BULK_UPLOAD: 'bulk_upload',
  NONE: "none",
};
const PHONE_VIEW = {
  PHONE : 'phone',
  BRIDGE : 'bridge'
}

const Credentials = () => {
  const [phoneList,setPhoneList] = useState([]);
  const token = useSelector(state => state.user.token); 
  const company = useSelector(state => state.user.company);
  const [action, setAction] = useState(CREATE_TYPE.NONE);
  const userId = useSelector(state => state.user.id);
  const [phoneEdited, setPhoneEdited]  = useState(false);
  const [currentPhoneView, setCurrentPhoneView] = useState(PHONE_VIEW.PHONE)
  const role = useSelector(state => state.user.role);
  
  const onCellConfigUpdated = useCallback((res) => {
    const newList = phoneList.map(itm => {
      if(itm.phoneId === res.phoneId){
        itm.cellConfig = res.res;
      }
      return itm;
    });
    setPhoneList(newList);
  },[phoneList]);

  const onUnassignment = useCallback((res) => {
    const newList = phoneList.map(itm => {
      if(itm.phoneId === res.phone_id){
        itm.company = undefined;
      }
      return itm;
    });
    setPhoneList(newList);
  },[phoneList])

  useEffect(() => {
    const payload = {
      companyId : company?.companyId,
      userId : userId
    }
    PhoneAPI.getPhoneList({payload,token}).then(res => {
      setPhoneList(res.data);      
    }).catch(e => console.log(e))
  },[phoneEdited]);

  const onCredentialAdded = ( res) => {
    setAction(CREATE_TYPE.NONE);
    if(res){
      setPhoneList(prev => [res,...prev])
    }
  }

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
       {role === ROLE.SUPER_ADMIN &&
          <MDBox display="flex" flexDirection="row" justifyContent="space-between">
            <ArrowAddBtn
              text={"Add Number"}
              customStyle={{ marginBottom: "12px!important" }}
              menuItems={
                <>
                  <NotificationItem
                    key={"none"}
                    onClick={() => setAction(CREATE_TYPE.PHONE)}
                    title={"Create number"}
                  ></NotificationItem>
                  <NotificationItem
                    key={"bridge"}
                    onClick={() => setAction(CREATE_TYPE.BRIDGE)}
                    title={"Upload numbers for WhatsApp bridge"}
                  ></NotificationItem>
                  <NotificationItem
                    key={"bulk_upload"}
                    onClick={() => setAction(CREATE_TYPE.BULK_UPLOAD)}
                    title={"Upload numbers for conversations"}
                  ></NotificationItem>                  
                </>
              }
              onPopoverClose={() => setAction("")}
            />
            <MDBox display="flex" alignItems="center">
              <FormGroup onChange={(e) => {
                if(e.target.checked){
                  setCurrentPhoneView(PHONE_VIEW.BRIDGE);
                }
                else{
                  setCurrentPhoneView(PHONE_VIEW.PHONE);
                }
              }}>
                <FormControlLabel control={<Switch />} label="View WhatsApp Bridge numbers" />
              </FormGroup>
            </MDBox>          
          </MDBox>
        }
        {currentPhoneView === PHONE_VIEW.PHONE && phoneList &&
          (role === ROLE.SUPER_ADMIN ?
            <PhoneCredentialsList key={"phone_cred_list"} onUnassignment={onUnassignment} onCellConfigUpdated={onCellConfigUpdated} onPhoneAssigned={(res) => console.log(res)} data={phoneList} onPhoneEdited={()=> setPhoneEdited(true)}/>
            : <AdminPhoneCredentialsList key={"admin_phone_cred_list"} onCellConfigUpdated={onCellConfigUpdated} onPhoneAssigned={(res) => console.log(res)} data={phoneList}/>)
        }
        {currentPhoneView === PHONE_VIEW.BRIDGE && <WhatsAppBridge/>}

        {action === CREATE_TYPE.PHONE && (
          <AppModal
            rootStyle = {
              {
                "&.MuiModal-root":{
                  width:'35%',
                  left: '65%'
                }
              }
            }
            heading={"New Number"}
            customStyle = {{ 
              width: "100%",
              height: "100%",
              overflowY: "auto",
              border: "unset",
            }}                    
            onModalClose = {() => {
              setAction(CREATE_TYPE.NONE);
            }}
          >
            <AddCredentialsForm closeModal={() => setAction(CREATE_TYPE.NONE)} token={token} onCredentialAdded = {onCredentialAdded} />
          </AppModal>
        )}
        {action === CREATE_TYPE.BRIDGE && <UploadWhatsAppBridgeNumbers onUploaded={() => {setCurrentPhoneView(PHONE_VIEW.PHONE);setAction(CREATE_TYPE.NONE)}} onCancel={() => setAction(CREATE_TYPE.NONE)}/>}
      </DashboardLayout>
    </>
  );
};
export default Credentials;
