import { BASE_URL } from "constants/Endpoints";
import fetch from "core/fetch";

export const getActivePhoneList = async({token,payload}) => {
    const response = await fetch(`${BASE_URL}/phone/pool?${payload}`,{
        method : 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data;
} 
export const uploadCsvToPool = async(uploadData) => {

    const response = await fetch(`${BASE_URL}/phone/pool`,{
        method : 'POST',
        body : uploadData.formData,
        headers: {
            // 'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + uploadData.token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
export const getPoolNumberDetails = async({token, phoneId}) => {

    const response = await fetch(`${BASE_URL}/phone/pool/${phoneId}/details`,{
        method : 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const deleteNumbers = async(deletedData) => {

    const response = await fetch(`${BASE_URL}/phone/pool`,{
        method : 'DELETE',
        body : JSON.stringify(deletedData.payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + deletedData.token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}