import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { PhoneAPI } from "services/PhoneAPI";
import Table from "ui/Table";

export const phoneColumns = [
    { Header: "phone", accessor: "phone", width: "25%" },
    { Header: "company", accessor: "company", width: "25%" },
    { Header: "user", accessor: "apiuser", width: "20%" },
    { Header: "api key", accessor: "apikey", maxSize: "150px" },
    { Header: "Auth", accessor: "auth", width: "30%" },
  ]
const WhatsAppBridge = () => {

    const token = useSelector(state => state.user.token);

    const [rows,setRows] = useState([]);
    
    const formatRows = (data) => {
        const tmpRow = data?.map(phone => {
            const currentPhone = {
                phone: phone?.phone,
                company : phone?.company ? phone?.company.companyName : 'Available',
                apiuser : phone?.apiUsername,
                apikey : <div style={{
                    maxWidth:'200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                }}>{phone?.apiKey ? phone.apiKey : 'XXXX-xxxx-XXX.'}</div>,
                auth: <div style={{
                    maxWidth:'200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                }}>{phone?.basicAuthHeader}</div>,
            }
            return currentPhone;
        });
        setRows(tmpRow);
    }

    useEffect(() => {
        PhoneAPI.getBridgeNumbers({token}).then(res => {
            formatRows(res.data);
        }).catch(err => console.log(err));

    },[]);

    return(
        <>
            <Table canSearch={true} heading={"WhatsApp Bridge Numbers"} columns={phoneColumns?phoneColumns : []} rows={rows?rows : []} />
        </>
    )

}
export default WhatsAppBridge;