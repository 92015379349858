import { CircularProgress } from "@mui/material";
import style from "./Loader.module.css";
import ReactDOM from 'react-dom';


const Loader = () => {
  return (
    ReactDOM.createPortal(
        <div className={style["loader"]}>
            <CircularProgress color="inherit" />
      </div>,
      document.getElementById("loader")
    )
  );
};
export default Loader;
