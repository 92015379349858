import { VIEWS } from "constants/ChatConstants";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useHttp from "./use-http";
import { getGroupsConversation } from "api/group";
import { useCallback } from "react";
import { useState } from "react";
import { groupAction } from "store/group-slice";

const useGroup = () => {

    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(0);
    const currentActiveGroup = useSelector(state => state.group.current_active_group);
    const rConversationState = useSelector(state => state.group.conversation);
    const token = useSelector(state => state.user.token);
    const currentActiveView = useSelector(state => state.chat.current_active_view);

    const {sendRequest : getGroupConversationsFn , data : groupMessages, error : grpMsgFetchErr, status : grpMsgFetchStatus}
    = useHttp(getGroupsConversation,false);

    const fetchGroupChat = useCallback(() => {
        getGroupConversationsFn({token,group_id:currentActiveGroup?.groupId});
    },[currentActiveGroup]);

    useEffect(() => {
        if(currentActiveView === VIEWS.GROUP_VIEW && currentActiveGroup) {
            const messageExists = rConversationState[currentActiveGroup.groupId];
            if(!messageExists || messageExists.length === 0) {
                fetchGroupChat();
            }
        }
    },[currentActiveGroup,currentActiveView]);

    useEffect(() => {
        if(grpMsgFetchStatus === 'completed'){
          if(groupMessages){
            dispatch(groupAction.setGroupMessage({groupId : currentActiveGroup.groupId,messages : groupMessages}));
          }
        }
    },[grpMsgFetchErr,grpMsgFetchStatus,groupMessages]);    
} 
export default useGroup;