import { Icon } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDTypography from "lib/components/MDTypography";

const AddButton = ({text, onClick,customStyle}) => {
    return (
      <MDBox
        pt={2}
        px={2}
        sx={customStyle}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <MDButton onClick={onClick} variant="gradient" color="dark">
          <Icon sx={{ fontWeight: "bold" }}>add</Icon>
          &nbsp;{text}
        </MDButton>
      </MDBox>
    );
}
export default AddButton;