import { Typography } from "@mui/material";
import EnhancedTable from "components/CommonUI/EnhancedTable";
import MDBox from "lib/components/MDBox";

const UploadReport = ({rows,columns}) => {
    
    return(
        <MDBox display="flex" flexDirection="column" width="100%" mt={2}>
            <EnhancedTable showPagination={false} selectEnabled={false} showFilter={false} rows={rows} columns={columns}/>
            {rows.length === 0 && 
                <MDBox display="flex" justifyContent="center" alignItems="center" mt={2} width="100%" height="100%">
                    <Typography>
                        {"No data found"}
                    </Typography>
                </MDBox>    
            }
            <Typography fontWeight={600} fontSize={"16px"}>{"Total records processed " + rows?.length}</Typography>
        </MDBox>
    )
}
export default UploadReport;