import { Button, Divider, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDInput from "lib/components/MDInput";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { UserAPI } from "services/UserAPI";
import Swal from "sweetalert2";
import ModifyIcSettings from "./ModifyIcSettings";
import CustomSelect from "ui/Select";
import { loaderAction } from "store/loader-slice";
import { memo } from "react";

const EditUserForm = ({user,userEdited,onUserDeleted}) => {

    const dispatch = useDispatch();
    const [currUser,setCurrUser] = useState();
    const [editing,setEditing] = useState(false);
    const token = useSelector(state => state.user.token);
    const role = useSelector(state => state.user.role );
    const loggedInUserId = useSelector(state => state.user.id);

    const deleteUser = useCallback(() => {
      if(currUser.role === 'SUPER_ADMIN'){
        toast.error("User with role, SUPER ADMIN can not be deleted.");
        return;
      }
      if(currUser.userId === loggedInUserId){
        toast.error("Operation not permitted.");
        return;
      }
      Swal.fire({
        title: 'Are you sure want to delete?',
        text: 'All the conversations data (if any) associated with this user will not be deleted.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        customClass: {
          container : {
            zIndex : '10000 !important'
          }
        }
      }).then((result) => {
        if (result.isConfirmed) {
         UserAPI.deleteUser({token,email : currUser.email}).then(res => {
          toast.success("User has been deleted.")
          onUserDeleted(res.data);
         }).catch(e => {
          toast.error(e?.response?.data?.message);
         })
        }
      })
    },[currUser]);
    
    const updateUser = useCallback(() => {
        dispatch(loaderAction.show());
        UserAPI.updateUser({user : currUser,token}).then(res => {
          dispatch(loaderAction.hide());
          toast.success("User updated.")
          setEditing(false);
          userEdited(res.data);
        }).catch(err => {
          dispatch(loaderAction.hide());
          const str = "Update failed " + err?.response?.data?.message;
          toast.error(str);
        })
    },[currUser]);
    
    useEffect(() => {
        if(user){
          setCurrUser({
            fullName : user.fullName,
            contactNumber : user.contactNumber,
            role : user.roles[0]?.name,
            email : user.email,
            userId : user.userId
          });
        }
    },[user]);

    return (
      <>
      {currUser && 
        <MDBox width="100%" display="flex" flexDirection="column">        
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Name"
              variant="standard"
              fullWidth
              value={currUser?.fullName ? currUser.fullName: ''}
              onChange={(e) => {
                  if(!editing){
                   setEditing(true);
                  }
                  setCurrUser(prev => {
                      return{
                          ...prev,
                          fullName : e.target.value
                      }
                  })
              }}
            />
          </MDBox>

          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Email"
              variant="standard"
              fullWidth
              value={currUser?.email ? currUser.email: ''}
              onChange={(e) => {
                if(!editing){
                 setEditing(true);
                }
                setCurrUser(prev => {
                    return{
                        ...prev,
                        email : e.target.value
                    }
                })
            }}              
            />   
          </MDBox>       
          <MDBox mb={2}>
            <MDBox display="flex" flexDirection="column">  
              <MDInput
                type="text"
                label="Contact Number"
                variant="standard"
                fullWidth
                value={currUser?.contactNumber ? currUser.contactNumber: ''}
                onChange={(e) => {
                    if(!editing){
                      setEditing(true);
                    }
                    setCurrUser(prev => {
                        return{
                            ...prev,
                            contactNumber : e.target.value
                        }
                    })
                }}
              />
            </MDBox>
          </MDBox>

          <MDBox mb={2}>
            {
              currUser && currUser?.role !== 'SUPER_ADMIN' &&
              <>
                <InputLabel id="demo-select-small">Role</InputLabel>
                <CustomSelect
                labelId="demo-select-small"
                id="demo-select-small"
                value={currUser?.role}
                label="Role"
                onChange={(e) => {
                  if(!editing){
                    setEditing(true)
                  }
                  setCurrUser(prev => {
                    return{
                      ...prev,
                      role : e.target.value
                    }
                  })
                }}
                sx={{width: "300px", height: "40px",marginTop:'8px'}} 
                >
                  <MenuItem key={'admin'} value={'ADMIN'}>ADMIN</MenuItem>
                  <MenuItem key={'user'} value={'USER'}>USER</MenuItem>
                </CustomSelect>
              </>         
            }
          </MDBox>

          {editing && (
            <MDBox
              display="flex"
              justifyContent="flex-start"
              onClick={updateUser}
              mt={2}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: "0px",
                  color: "white!important",
                  border: "unset",
                  marginLeft: "12px",
                  height: "32px",
                  minHeight: "unset",
                }}
              >
                Save
              </Button>
            </MDBox>
          )}        
        </MDBox>
      }
        {(role === 'SUPER_ADMIN' || user.roles[0].name === 'USER') && <ModifyIcSettings user={user}/>}
        <Divider sx={{borderWidth:'2px'}} component={"div"} variant="horizontal"/>
        <Typography variant="h6" textAlign={"left"}>Actions</Typography>
        <MDBox
          display="flex"
          justifyContent="flex-start"
          onClick={deleteUser}
          mt={2}
        >
          <Button
            variant="contained"
            sx={{
              borderRadius: "0px",
              color: "white!important",
              border: "unset",
              marginLeft: "12px",
              height: "32px",
              backgroundColor: 'red!important',
              minHeight: "unset",
              "&:hover":{
                background: "#fff!important",
                color:'black!important'
              }
            }}
          >
            Delete user
          </Button>
        </MDBox>      
      </>
    );

}
export default memo(EditUserForm);