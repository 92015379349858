import { Avatar } from "@mui/material";
import { StyledBadge } from "components/util/OnlineBadge";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDTypography from "lib/components/MDTypography";
import { memo } from "react";

const Contact = ({info,onActionClick,actionText,online = false}) => {

    return(
        <MDBox key={info.fullName} component="li" display="flex" alignItems="center" py={1} mb={1}>
            <MDBox mr={2}>
                <StyledBadge
                    overlap="circular"
                    invisible={!online}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                >
                    <Avatar src={info?.profilePic} alt={info?.fullName} shadow="md" />
                </StyledBadge>
            </MDBox>
            <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
            <MDTypography variant="button" fontWeight="medium">
                {info?.fullName}
            </MDTypography>
            <MDTypography variant="caption" color="text">
                {info?.email}
            </MDTypography>
            </MDBox>
            <MDBox ml="auto">
                <MDButton variant="text" color="info" onClick={() => onActionClick(info)}>
                    {actionText}
                </MDButton>
            </MDBox>
        </MDBox>
    )
}
export default memo(Contact);