import { Card, Container} from "@mui/material";

const SettingCard = ({ children }) => {
  return (
    <Card
      sx={{
        mt: 2,
      }}
    >
      <Container maxWidth="sm">{children}</Container>
    </Card>
  );
};
export default SettingCard;
