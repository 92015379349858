export const CrowdfundingUtil = {
    formatCurrency : (number) => {
        if (number === '') return '';
        const parsedNumber = parseFloat(number,10);
        if (isNaN(parsedNumber)) return '';
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0
        }).format(parsedNumber);
    }
} 