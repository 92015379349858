import React from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import { useState } from "react";
import MDBox from "lib/components/MDBox";
import {Tooltip} from "@mui/material";
import FundraiserSettingMenu from "components/Fundraiser/FundraiserSettingMenu";
import AppModal from "ui/AppModal";

const FundraiserSettings = ({fundraiser,campaign, onLinkGenerated}) => {
  const [action, setAction] = useState("");

  return (
    <div>
      <MDBox
        sx={{}}
        mt={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Tooltip title="Settings">
          <SettingsIcon
               onClick={() => setAction("SETTING_TABLE")}
            sx={{ width: "1.2rem", height: "1.2rem" }}
            style={{ cursor: "pointer", fontSize: "40px!important" }}
          />
        </Tooltip>
      </MDBox>
      {
        action === "SETTING_TABLE" && (
          <AppModal
            rootStyle = {
              {
                "&.MuiModal-root":{
                  width:'50%',
                  left: '50%'
                }
              }
            }
            customStyle={{ 
              width: "100%",
              height: "100%",
              overflowY: "auto",
              border: "unset",
            }}
            heading={" "}
            onModalClose={() => setAction("")}          
          >
            <FundraiserSettingMenu onLinkGenerated = {onLinkGenerated} fundraiser={fundraiser} campaign={campaign}/>
          </AppModal>
        )
      }
    </div>
  );
};

export default FundraiserSettings;
