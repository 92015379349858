import { LeftChatPanel } from "pages/Chat/ChatStyles";
import { useSelector } from "react-redux";
import LeftChatPanelContent from "../LeftChatPanelContent";
import MDBox from "lib/components/MDBox";
import NoContent from "../NoContent";
import MDButton from "lib/components/MDButton";
import MDTypography from "lib/components/MDTypography";
import { FormatPhoneNumber } from "util/UtilityFunctions";
import { memo, useState } from "react";
import { CircularProgress, Typography } from "@mui/material";
import SelectCountryCode from "components/util/SelectCountryCode";
import { VIEWS } from "constants/ChatConstants";

const NRecipientList = ({addRecipient}) => {

    const currentActiveView = useSelector(state => state.chat.current_active_view);
    const storedRecipientList = useSelector(state => state.chat[currentActiveView]);
    const searchActive = useSelector(state => state.chatVariables.recipientSearchState);
    const recipientContainerLoader = useSelector(state => state.chatVariables.recipientContainerLoader);
    const [selectedCode,setSelectedCode] = useState("1");

    if(recipientContainerLoader){
        return (<MDBox width="100%" height="100%" sx={{borderRight:'1px solid #ddd'}} display="flex" justifyContent="center" alignItems="center"><CircularProgress/></MDBox>)
    }
    else return(
        <MDBox display="flex" flexDirection="column" height="100%">          
            <LeftChatPanel>
                {storedRecipientList?.recipients?.length > 0 ? <LeftChatPanelContent />: 
                <MDBox sx={{borderRight:"1px solid #ddd",width: "100%",paddingLeft:'50px', display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>                 
                    <NoContent heading={"h6"} message={currentActiveView == VIEWS.UNREAD_VIEW ? "You have no unread messages.":'You have no conversations to display.'}> 
                    {currentActiveView !== VIEWS.UNREAD_VIEW && <Typography fontSize={12}>Enter a phone number above to start a conversation.</Typography>} 
                    {searchActive.search && 
                        <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            <SelectCountryCode customStyle={{width:'142px',border: 'unset',marginBottom:'2px',cursor:'pointer',color:'#1A73E8',fontWeight:600}} onSelected={res => setSelectedCode(res)}/> 
                            <MDBox sx={{marginTop:'8px'}} onClick={() => {addRecipient(searchActive.pattern,selectedCode)}}>
                                <MDButton variant="text" color="info">
                                    {"Start chat"} 
                                    <MDTypography variant="button" fontWeight="medium" sx={{marginLeft:'8px'}}>
                                    +{selectedCode}&nbsp;{FormatPhoneNumber(searchActive.pattern,undefined,false)}
                                    </MDTypography>
                                </MDButton>
                            </MDBox>
                        </MDBox>
                    } 
                    </NoContent>
                </MDBox>
                }
        </LeftChatPanel>        
        </MDBox>
    )
}
export default memo(NRecipientList);