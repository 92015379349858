import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";

const NoContent = ({ message, heading,children, style }) => {
  return (
    <MDBox
      sx={{ ...style, width: "100%" }}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      {message && <MDTypography sx={{textAlign:'center'}} variant={heading} fontWeight="medium">
        {message}
      </MDTypography>}
      {children}
    </MDBox>
  );
};
export default NoContent;
