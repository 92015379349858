import { BASE_URL } from "constants/Endpoints";
import fetch from "core/fetch";

export const individualChatExport = async ({token,exportData}) => {

    const response = await fetch(`${BASE_URL}/sms/individual/export`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
        },
        body: JSON.stringify(exportData),
    });
    const data = await response.blob();
    const link = document.createElement("a");
    link.download = "conversations.xlsx";
    link.href = URL.createObjectURL(
        new Blob([data], { type: "xlsx" })
    );
    link.click();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data;
}
export const bulkChatExport = async ({ exportData, token }) => {

    const response = await fetch(`${BASE_URL}/sms/bulk/export`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(exportData),
    });
    const data = await response.blob();
    const link = document.createElement("a");
    link.download = "SMS export.xlsx";
    link.href = URL.createObjectURL(
        new Blob([data], { type: "xlsx" })
    );
    link.click();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data;
}
export const bulkAllChatExport = async ({ exportDatas, token, allPhones }) => {
    let _data = []
    for (let i = 0; i < exportDatas?.length; i++) {
        const response = await fetch(`${BASE_URL}/sms/bulk/export`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(exportDatas[i]),
        });
        const data = await response.blob();
        const link = document.createElement("a");
        const _phone = allPhones.find(_i=>_i.phoneId == exportDatas[i].phone_id);
        link.download = `SMS_export_${_phone.phone}.xlsx`;
        link.href = URL.createObjectURL(
            new Blob([data], { type: "xlsx" })
        );
        link.click();
        if (!response.ok) {
            throw new Error('Could not call the endpoint.');
        }
       
    }
 
    return _data;
}
export const individualChatDelete = async (exportData) => {

    const response = await fetch(`${BASE_URL}/sms/individual/delete`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization' : 'Bearer ' + exportData.token,
        },
        body: JSON.stringify(exportData),
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data;
}
export const individualGroupChatDelete = async ({ payload, token }) => {

    const response = await fetch(`${BASE_URL}/sms/individual/group/delete`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(payload),
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data;
}
export const bulkChatDelete = async ({ payload, token }) => {

    const response = await fetch(`${BASE_URL}/sms/bulk/delete`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(payload),
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data;
}
export const markAsRead = async ({ payload, token }) => {

    const response = await fetch(`${BASE_URL}/sms/bulk/read`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(payload),
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data;
}
export const markAsUnRead = async ({ payload, token }) => {

    const response = await fetch(`${BASE_URL}/sms/bulk/unread`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(payload),
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data;
}