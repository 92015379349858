export const buildExportArr = (activeCheckbox) => {
    const exportArr = [];
    for (const item in activeCheckbox) {
        const tmp = {
          recipient_id: item,
          selected: activeCheckbox[item].selected,
          messageIds: activeCheckbox[item].messageIds,
        };
        exportArr.push(tmp);
    }
    return exportArr;
}