import MDBox from "lib/components/MDBox";
import MDProgress from "lib/components/MDProgress";
import MDTypography from "lib/components/MDTypography";
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));
const GoalCompletionUI = ({color, value, withBorder = false}) => {
    return(
        <>
            <MDBox sx={{height:'42px'}} display="flex" alignItems="center" justifyContent="center">
                <MDTypography variant="caption" color="text" fontWeight="medium">
                    {value}%
                </MDTypography>
                <MDBox ml={0.5} width="9rem">
                    {withBorder ? <BorderLinearProgress variant="determinate"  value={value}/>:
                    <MDProgress variant="gradient" color={color} value={value} />
                }
                </MDBox>
            </MDBox>
        </>
    )
}
export default GoalCompletionUI;