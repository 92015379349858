import { createSlice } from "@reduxjs/toolkit";

const initialUserDetailsStates = {
    details : undefined,
    userCache : undefined
}

const UserDetailsSlice = createSlice({
    name : 'userDetails',
    initialState: initialUserDetailsStates,
    reducers : {
        setUserDetails : (state,action) => {
            state.details = action.payload;
        },
        updateICsettings : (state,action) => {
            state.details.userSettings.icUserSettings = action.payload;
        },
        updateTeleSettings : (state,action) => {
            state.details.userSettings.teleUserSettings = action.payload;
        },
        updateTimezone : (state,action) => {
            state.details.userSettings.timezone = action.payload;
        },
        updateSignature : (state,action) => {
            const {waSignature,teleSignature,icSignature} = action.payload;
            state.details.userSettings.waSignature = waSignature;
            state.details.userSettings.teleSignature = teleSignature;
            state.details.userSettings.icSignature = icSignature
        },
        updateSecondAuthNumber : (state,action) => {
            const {number} = action.payload;
            state.details.secondAuthNumber = number;
        },

        updateProfilePic : (state,action) => {
            const {uri} = action.payload;
            state.details.profilePic = uri; 
        },
        updatePhones : (state,action) => {
            const {phoneId,count} = action.payload;
            const newPhones = state.details.phones.map(item => {
                if(item.phoneId === phoneId) {
                  return {
                    ...item,
                    unread : count === 0 ? 0 : item.unread + count
                  }
                }
                else return item;
            });
            state.details.phones = newPhones;
        },
        setUserCache : (state,action) => {
            state.userCache = action.payload;
        },
        setOutBoundNFSound : (state,action) => {
            state.details.userSettings.outboundSounds = action.payload;
        },
    }
})
export const userDetailAction = UserDetailsSlice.actions;
export default UserDetailsSlice;