import { BASE_URL } from "constants/Endpoints";
import fetch from "core/fetch";

///// Create a new user
export const createWTemplate = async ({template,token}) => {
    const response = await fetch(`${BASE_URL}/wa/template`,{
        method: 'POST',
        body : JSON.stringify(template),
        headers :{
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + token,
        }
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;
}

export const getWASettings = async ({companyId,token}) => {
    const response = await fetch(`${BASE_URL}/company/${companyId}/wa/settings`,{
        method: 'GET',
        headers :{
            'Authorization' : 'Bearer ' + token,
        }
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;
}

export const updateWASetting = async ({wa,token}) => {
    const response = await fetch(`${BASE_URL}/company/wa/settings`,{
        method: 'POST',
        body : JSON.stringify(wa),
        headers :{
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + token,
        }
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;
}

export const getWACredentials = async ({companyId,token}) => {
    const response = await fetch(`${BASE_URL}/company/${companyId}/wa/cred`,{
        method: 'GET',
        headers :{
            'Authorization' : 'Bearer ' + token,
        }
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;
}

export const syncWATemplates = async ({companyId,token,payload}) => {
    const response = await fetch(`${BASE_URL}/${companyId}/wa/template/sync`,{
        method: 'POST',
        headers :{
            'Authorization' : 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
        body : JSON.stringify(payload)
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;
}

export const sendTemplateMessage = async({payload,token}) => {
    ///Token is undefined
    const response = await fetch(`${BASE_URL}/sms/phone/recipient/wa/template`,{
        method : 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
        body : JSON.stringify(payload),
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
} 