import NoContent from "components/Chat/NoContent";
import MDBox from "lib/components/MDBox";
import web_update from "assets/images/web_update.jpg";
import { Button, Typography } from "@mui/material";
import { Refresh } from "@mui/icons-material";

const HardRefresh = () => {
    
    return(
        <NoContent style={{height: "100%",backgroundColor:"#fff",minHeight:'100%'}}>
            <MDBox display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
                <Typography variant="h4">{"We just got updated."}</Typography>
                <MDBox maxWidth='500px'>
                    <img alt="Update available" src={web_update} height={"100%"} width={"100%"}/>
                </MDBox>
            </MDBox>
            <MDBox display="flex" width="100%" justifyContent="center" alignItems="center">
                <Button
                    sx={{
                        color: "white!important",
                        border: "unset",
                        padding: "10px",
                        height: "35px",
                        backgroundColor: "#1876F2!important",
                    }}
                    variant="contained"
                    size="large"
                    endIcon={<Refresh/>}
                    onClick={() => window.location.reload(true)}                
                >
                    Refresh now
                </Button>
            </MDBox>
        </NoContent>
    )
}
export default HardRefresh;