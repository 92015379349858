import { BASE_URL } from "constants/Endpoints";
import { USER_NOTIFICATIONS } from "constants/Endpoints";
import axios from "../core/axios";

export const UserAPI = {
    updateUser : async({user,token}) => {
        return await axios.post(`${BASE_URL}/users/update`,JSON.stringify(user),{
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        });
    },

    uploadOutbountNotification : async({userId, files,token}) => {
        console.log('check its first  -> ', files)
        return await axios.post(`${BASE_URL}/user/settings/${userId}/upload/outbound/notifications`,files,{
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'multipart/form-data',
              }
        });
    },
    updateSecondAuthNumber : async({user,token}) => {
        return await axios.post(`${BASE_URL}/users/update/2FA`,JSON.stringify(user),{
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        });
    },
    deleteUser : async({email,token}) => {
        return await axios.post(`${BASE_URL}/users/remove/user`,JSON.stringify({email}),{
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }            
        })
    },
    getUserPendingNotifications : async({userId,token}) => {
        return await axios.get(USER_NOTIFICATIONS(userId),{
            headers: {
                'Authorization': 'Bearer ' + token
            }            
        })        
    },
    updateUserCellConfig :  async({phoneId,userId,payload,token}) => {
        return await axios.post(`${BASE_URL}/user/settings/${userId}/${phoneId}/cell-config`,JSON.stringify(payload),{
            headers : {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        })
    },

    ///// Get the users
    getUsers : async({company_id,user_id,token}) => {
        return await axios.post(`${BASE_URL}/users`,JSON.stringify({company_id,user_id}),{
            headers : {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        })
    },
}