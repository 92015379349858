import MDBox from "lib/components/MDBox";
import Ins from "assets/images/access_instruction_img.jpg";
import { Typography } from "@mui/material";
import AppConstants from "constants/AppConstants";

const SheetInsUI = ({}) => {

    return(
        <MDBox height="400px" display="flex" alignItems="center" flexDirection="column" maxWidth="400px" sx={{maxHeight:'400px' ,overflow:'auto'}}>
            <Typography color={'red'}>{"Permission required"}</Typography>
            <Typography sx={{marginTop:'8px'}} fontSize={"14px"}>{AppConstants.SHEET_PERMISSION_ERR}</Typography>
            <img src={Ins} height={'300px'} width={'300px'} style={{objectFit:'contain'}}/>
        </MDBox>
    )
}
export default SheetInsUI;