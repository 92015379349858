import { Add } from "@mui/icons-material";
import { Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { getGroups } from "api/group";
import { getPhoneList } from "api/phoneCredentials";
import CreateBroadcast from "components/Chat/Broadcast/CreateBroadcast";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { groupAction } from "store/group-slice";
import AppModal from "ui/AppModal";

const KeywordGroupMap = ({selectedGroup,onGroupSelected}) => {
    
    const dispatch = useDispatch();
    const [groupValue,setGroupValue] = useState(selectedGroup?.groupId?.toString() || '');
    const currUser = useSelector(state => state.user);
    const {sendRequest : getGroupsFn, data : groupsData, status : gstatus, error : gerror} = useHttp(getGroups,false)
    const {sendRequest : fetchPhoneList, data : phoneList , status : fetchStatus} = useHttp(getPhoneList, true);
    const [createNewGroup, setCreateNewGroup] = useState(false);
    const [currentSelectedPhone,setCurrentSelectedPhone] = useState(undefined);

    const onGroupCreated = (res) => {
        if(res) {
            setCreateNewGroup(false)
            groupsData.push(res);
            onGroupSelected(res)
            dispatch(groupAction.addGroup({group:res}));
            setGroupValue(res?.groupId)
        }
    }
    const onPhoneChange = (e) => {
        if(e.target.value) {
            setCurrentSelectedPhone(e.target.value);
            getGroupsFn({phoneId :e.target.value.phoneId,token: currUser.token});
        }
    }
    useEffect(() => {
        fetchPhoneList({token : currUser.token,companyId : currUser.company?.company_id,userId : currUser.id});
    },[]);

    return (
        <>
            <MDBox display="flex" flexDirection="column" width="100%">
                <MDBox>
                    <FormControl fullWidth sx={{marginRight:'8px'}}>
                        <InputLabel id="phone-label">Choose number</InputLabel>
                        <Select
                            labelId="phone-label"
                            id="choose-phone"
                            sx={{
                                height:'40px',
                                marginRight:'8px'
                            }}
                            label="Choose carrier"
                            onChange={onPhoneChange}
                        >
                            { phoneList ? phoneList?.map(item => <MenuItem key={item.phoneId} value={item}>{item.phone}</MenuItem>) : <MenuItem>No phone available</MenuItem>}
                        </Select>                    
                    </FormControl>
                </MDBox>
                <MDBox mt={2}>
                    <FormControl fullWidth sx={{marginRight:'8px'}}>
                        <InputLabel id="phone-label">Choose group</InputLabel>
                        <Select
                            labelId="phone-label"
                            id="choose-phone"
                            sx={{
                                height:'40px',
                                marginRight:'8px'
                            }}
                            value={groupValue}
                            label="Choose carrier"
                            onChange={(e) => {
                                setGroupValue(e.target.value)
                                onGroupSelected(groupsData?.find(_i=>_i.groupId == e.target.value))}
                            }
                        >
                            {groupsData ? groupsData?.map(item => <MenuItem key={item.groupId} value={item.groupId}>{item.name}</MenuItem>) : <MenuItem>No group available</MenuItem>}
                            <MenuItem value="">
                                <MDBox display="flex" justifyContent="flex-end">
                                    <Button onClick={() => {
                                        if(currentSelectedPhone) {
                                            setCreateNewGroup(true)
                                        }
                                        else{
                                            toast.warning("Please select phone number.")
                                        }
                                    }}><Add/> Create group</Button>
                                </MDBox>                             
                            </MenuItem>
                        </Select>                    
                    </FormControl>
                </MDBox>           
            </MDBox>
            {createNewGroup && <AppModal
                closeOnClick={false}
                customStyle={{ width: "500px" }}
                onModalClose={() => {
                    setCreateNewGroup(false);
                }}
                heading={"Create Group"}
                >
                <CreateBroadcast
                    currentPhone={currentSelectedPhone}
                    onGroupCreated={onGroupCreated}
                />
            </AppModal>}
        </>
    )
}
export default KeywordGroupMap;