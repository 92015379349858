import { BASE_URL } from "constants/Endpoints";
import axios from "../core/axios";

export const AuthAPI = {
    getUserDetails : async (token) => {
        return await axios.get(`${BASE_URL}/users/details`,{
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
    },
    resetPassword : async({email,token,data}) => {
        return await axios.post(`${BASE_URL}/user/welcome-password?token=${token}&email=${email}`,JSON.stringify(data),{
            headers : {
                "Content-Type": "application/json"
            }
        });
    }
}