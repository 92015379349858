import { BASE_URL } from "constants/Endpoints";
import fetch from "core/fetch";
export const assignPhoneToCompany = async(phone) => {
    const payload = {
        ...phone,
        token: undefined
    }
    const response = await fetch(`${BASE_URL}/company/assign/phone`,{
        method : 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + phone.token,
          },
        body : JSON.stringify(payload)  
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
export const setSettings = async({token,settings}) => {

    const response = await fetch(`${BASE_URL}/phone/settings`,{
        method : 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
        body : JSON.stringify(settings)  
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
export const getSettings = async(token) => {

    const response = await fetch(`${BASE_URL}/phone/settings`,{
        method : 'GET',
        headers: {
            'Authorization' : 'Bearer ' + token,
          }  
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
export const getGeneralPrivacySettings = async(token) => {

    const response = await fetch(`${BASE_URL}/phone/settings/privacy-policy`,{
        method : 'GET',
        headers: {
            'Authorization' : 'Bearer ' + token,
          }  
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
export const setGeneralPrivacySettings = async({token,privacyPolicy}) => {

    const response = await fetch(`${BASE_URL}/phone/settings/privacy-policy`,{
        method : 'POST',
        body : JSON.stringify(privacyPolicy),
        headers: {
            'Authorization' : 'Bearer ' + token,
            'Content-Type': 'application/json'
        }  
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
export const setTermsAndConditions = async({token,termsAndConditions}) => {

    const response = await fetch(`${BASE_URL}/phone/settings/terms-conditions`,{
        method : 'POST',
        body : JSON.stringify(termsAndConditions),
        headers: {
            'Authorization' : 'Bearer ' + token,
            'Content-Type': 'application/json'
          }  
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const getTermsAndConditions = async(token) => {

    const response = await fetch(`${BASE_URL}/phone/settings/terms-conditions`,{
        method : 'GET',
        headers: {
            'Authorization' : 'Bearer ' + token,
          }  
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const acceptTermsAndConditions = async({token,userId}) => {

    const response = await fetch(`${BASE_URL}/users/${userId}/accept/terms-conditions`,{
        method : 'GET',
        headers: {
            'Authorization' : 'Bearer ' + token,
          }  
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}