import { BASE_URL } from "constants/Endpoints";
import axios from "../core/axios";
import { BULK_UPLOAD_USER } from "constants/Endpoints";

export const BulkAPI = {
    processSheetUrl : async({token,payload}) => {
        return await axios.post(`${BASE_URL}/sms/bulk/upload/sheet`,JSON.stringify(payload),{
            headers : {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            }
        });
    },
    bulkUploadUser : async({token,formData}) => {
        return await axios.post(`${BULK_UPLOAD_USER}`,formData,{
            headers : {
                'Authorization': 'Bearer ' + token,
                'Content-Type':'multipart/form-data'
            }
        });
    }
}