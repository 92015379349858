import { createSlice } from "@reduxjs/toolkit";

const initialOutBoundNFSoundStates = {
   
    outBoundNFSound:[]
}

const OutBoundNFSoundSlice = createSlice({
    name : 'outBoundNFSound',
    initialState: initialOutBoundNFSoundStates,
    reducers : {
     
        setOutBoundNFSound : (state,action) => {
            state.outBoundNFSound.outBoundNFSound = [action.payload];
        }
    }
})
export const outBoundNFSoundAction = OutBoundNFSoundSlice.actions;
export default OutBoundNFSoundSlice;