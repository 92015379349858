import { CircularProgress } from "@mui/material";
import { LeftChatContent } from "pages/Chat/ChatStyles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import AssignRecipient from "components/RecipientAssignment/AssignRecipient";
import AppModal from "ui/AppModal";
import { chatAction } from "store/chat-slice";
import MDBox from "lib/components/MDBox";
import LeftChatPanelRecipient from "./LeftChatPanel/LeftChatPanelRecipient";
import { chatVariablesAction } from "store/chat-variables-slice";

const LeftChatPanelContent = () => {
  const currentActiveView = useSelector(state => state.chat.current_active_view);
  const currentActivePhone = useSelector(state => state.chat.current_active_phone);
  const storedRecipientList = useSelector(state => state.chat[currentActiveView]);
  const [currRecipientList, setCurrRecipientList] = useState(storedRecipientList?.recipients);
  const activeCheckbox = useSelector(state => state.chatVariables.activeCheckbox);
  const currentActiveRecipient = useSelector(state => state.chat.current_active_recipient);
  const dispatch = useDispatch();
  const [assignRecipient,setAssignRecipient] = useState(null);

  useEffect(() => {
    // whenever this changed check if there is no selection then close the nav menu
    let anySelected = false;
    for(const itm in activeCheckbox){
        if(activeCheckbox[itm].selected){
          anySelected = true;
        }
    }
    if(!anySelected){
      dispatch(chatVariablesAction.setIsCheckboxSelected(false));
    }
  },[activeCheckbox]);

  const onAssignmentDone = (res) => {
    setAssignRecipient(null);
  }

  useEffect(() => {
    console.log("render in leftchatpanel due to recipient store change")
    setCurrRecipientList(storedRecipientList.recipients);
  },[storedRecipientList.recipients]);
  return (

    <LeftChatContent id="scrollableRecipient">
      <InfiniteScroll
        style={{overflowX:'hidden'}}
        dataLength={currRecipientList.length}
        next={() => {
          dispatch(chatAction.updateCurrentPage({view : currentActiveView}))
        }}
        hasMore={storedRecipientList?.currentPage < storedRecipientList?.totalPages && storedRecipientList?.totalPages > 1}
        loader={<MDBox width="100%" sx={{overflow:'hidden'}} display="flex" justifyContent="center"><CircularProgress/></MDBox>}
        scrollableTarget="scrollableRecipient"
      >
        <MDBox className="scrollableRecipient" diplay="flex" width="auto" height="auto">
          {Array.isArray(currRecipientList) && currRecipientList?.map((recipient, index) => <LeftChatPanelRecipient key={"left_chat_panel" + recipient?.id} recipient={recipient} setAssignRecipient={setAssignRecipient} selected={currentActiveRecipient?.id === recipient?.id}/>)}
        </MDBox>
      </InfiniteScroll>
          {assignRecipient &&         
            <AppModal
              heading={"Assign recipient to user"}
              onModalClose={() => {
                setAssignRecipient(null);
            }}
          >
          <AssignRecipient onAssignmentDone={onAssignmentDone} currentActivePhone={currentActivePhone} recipient={assignRecipient}/>
            </AppModal>}
    </LeftChatContent>

  );
};

export default React.memo(LeftChatPanelContent);
