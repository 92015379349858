import { InputAdornment, TextField, Tooltip } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MDBox from "lib/components/MDBox";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useHttp from "hooks/use-http";
import { updateCredit } from "api/user";
import { toast } from "react-toastify";
import { Close } from "@mui/icons-material";
import { CompanyAPI } from "services/CompanyAPI";
import AppConstants from "constants/AppConstants";

const creditState = {
    TEXT:'text',
    INPUT:'input'
}
const CompanyCredit = ({credit,companyId}) =>{

    const [guState, setGuState] = useState(creditState.TEXT);
    const [currentCredit, setCurrentCredit] = useState(credit);
    const token = useSelector(state => state.user.token);
    const role = useSelector(state => state.user.role);

    const onCreditChange = (e) => {
        setCurrentCredit(e.target.value);
    }

    const onEnter = (e) => {
        if(e.keyCode == 13 && e.shiftKey == false){
            creditUpdateHandler();
        }
    }
    const creditUpdateHandler = useCallback(() => {
        if(parseInt(currentCredit) <= AppConstants.CREDIT_AMOUNT_THRESHHOLD){
            setGuState(creditState.TEXT);
            const payload = {
                company_id: companyId,
                credit : parseInt(currentCredit)
            }
            CompanyAPI.updateCompanyCredit({token,payload}).then(res => {
                toast.success("Credit updated successfully.");
            }).catch(e => {
                toast.error("Something went wrong.");
            })
        }
        else toast.error(AppConstants.VALID_CREDIT_AMOUNT_WARNING)
        // updateCreditFn({token,user_id:userId,credit : currentCredit})
    },[currentCredit]);

    const onGUClickHanlder = () => {
        if(role === 'SUPER_ADMIN'){
            setGuState(creditState.INPUT)
        }
    }
    return (
       <MDBox display="flex" flexDirection="row">
        {guState === creditState.TEXT && <Tooltip title={`${role === 'SUPER_ADMIN' ? 'click to edit.' : ''}`}><MDTypography onClick={onGUClickHanlder}>{currentCredit}</MDTypography></Tooltip>}
        {guState === creditState.INPUT && 
            <MDBox display="flex">
                <TextField 
                    onChange={onCreditChange} 
                    onKeyDown={onEnter}
                    value={currentCredit}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <CheckCircleIcon onClick={creditUpdateHandler} sx={{height:'24px',width:'24px',cursor:'pointer'}}/>
                            <Close sx={{height:'24px',width:'24px',cursor:'pointer',marginLeft:'8px'}} onClick={() => setGuState(creditState.TEXT)}/>
                        </InputAdornment>,
                      }}
                />
            </MDBox>
        }    
       </MDBox>
    )
}
export default CompanyCredit;