import { BASE_URL } from "constants/Endpoints";
import axios from "../core/axios";

export const UserSettingsAPI = {
    updateMessageDelay : async({payload,token}) => {
        return await axios.post(`${BASE_URL}/user/settings/update/message-delay`,JSON.stringify(payload),{
            headers : {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token
            }
        });
    },

    updateApiGeneratedMessageSettings : async({payload,token}) => {
        return await axios.post(`${BASE_URL}/user/settings/update/api-generated-conversation`,JSON.stringify(payload),{
            headers : {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token
            }
        });
    },

    setSignatures : async({payload,token,userId}) => {
        return await axios.post(`${BASE_URL}/user/settings/${userId}/update/signature`,JSON.stringify(payload),{
            headers : {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token
            }
        });
    },
    setUserCache : async({payload,token}) => {
        return await axios.post(`${BASE_URL}/user/settings/${payload.userId}/cache`,JSON.stringify(payload),{
            headers : {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token
            }
        });
    }
};