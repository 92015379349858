import { Button, TextField, Typography } from "@mui/material";
import AppConstants from "constants/AppConstants";
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { UserSettingsAPI } from "services/UserSettingsAPI";
import { userDetailAction } from "store/user-details-slice";

const MessageSettings = () => {

    const dispatch = useDispatch();
    const [isUpdating, setIsUpdating] = useState(false);
    const user = useSelector(state => state.user);
    const teleUserSettings = useSelector(state => state.userDetails.details.userSettings.teleUserSettings);
    const [messageDelay, setMessageDelay] = useState(teleUserSettings?.messageDelayTime ? teleUserSettings?.messageDelayTime : 5);
    const updateMessageSetting = () => {
        const payload = {
            time: messageDelay ? messageDelay : 5,
            userId: user.id
        }
        UserSettingsAPI.updateMessageDelay({ payload, token: user.token }).then(res => {
            toast.success(res.data.message);
            setIsUpdating(false);
            if (res.data.settings) {
                dispatch(userDetailAction.updateTeleSettings(res.data.settings));
            }
            else {
                const newSettings = { ...teleUserSettings, messageDelayTime: messageDelay };
                dispatch(userDetailAction.updateTeleSettings(newSettings));
            }
        }).catch(e => toast.error(e.message | e));
    }

    return (
        <MDBox display="flex" width="100%" flexDirection="column">
            <MDBox display="flex" width="100%" mt={2}>
                <Typography fontWeight={'medium'} fontSize={14}>Message settings</Typography>
            </MDBox>
            <MDBox display="flex" flexDirection="row" mt={2}>
                <TextField onChange={(e) => {
                    if (!isUpdating) {
                        setIsUpdating(true);
                    }
                    setMessageDelay(e.target.value);
                }} label="Time in seconds" value={messageDelay} />
                <MDBox display="flex" ml={1} flexDirection="column">
                    <Typography fontSize={"14px"}>{AppConstants.MESSAGE_DELAY}</Typography>
                    <MDTypography mb={1} fontSize={"12px"} color={"info"}>{AppConstants.DELAY_EXPLAIN}</MDTypography>
                </MDBox>

            </MDBox>
            {isUpdating &&
                <MDBox mt={2} width="100%" display="flex" justifyContent="flex-end">
                    <Button onClick={updateMessageSetting} sx={{ borderRadius: "0px", color: "white!important", border: "unset", }} variant="contained">update</Button>
                </MDBox>
            }
        </MDBox>
    )
}
export default MessageSettings;