import { Avatar } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { getDateConvertedFromUTC } from "util/UtilityFunctions";

const ReadBy = ({image,name,date}) => {

    const timezone = useSelector(state => state.userDetails?.details?.userSettings?.timezone)

    return(
        <MDBox component="li" display="flex" alignItems="center" mb={1}>
            <MDBox mr={1}>
                <Avatar
                    sx={{ width: 26, height: 26, objectFit: "fill" }}
                    src={image}
                    alt={name}
                    shadow="md"
                />
            </MDBox>
            <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="center"
            >
                <MDTypography variant="button" fontWeight="medium" fontSize={"12px"}>
                    {"Read by "}{name?.charAt(0).toUpperCase()}{name?.slice(1)} at <Moment tz={timezone} format={"LT"}>{getDateConvertedFromUTC(date)}</Moment> on (<Moment tz={timezone} format="ll">{getDateConvertedFromUTC(date)}</Moment>)
                </MDTypography>
            </MDBox>
        </MDBox>        
    )
}
export default ReadBy;