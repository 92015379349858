import { FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, Tooltip, Typography } from "@mui/material";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {updatePhone} from 'api/phoneCredentials';
import { NUMBER_TYPE } from "constants/phoneConstants";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ShowCarrier from "./Carrier/ShowCarrier";

let carrier_id = undefined;

const EditCredForm = ({phone,onCredentialEdited}) => {
    const [apiKeyValidation ,setApiKeyValidation] = useState('');
    const [phoneValidation, setPhoneValidation] = useState('');
    const [numberType,setNumberType] = useState(NUMBER_TYPE.SMS);

    const [apiUserNameValidation, setapiUserNameValidation] = useState('');
    const [apiBasicAuthHeader, setapiBasicAuthHeader] = useState('');
    const [accessTokenValidation, setAccessTokenValidation] = useState('');
    const [wabaIdValidation, setWabaIdValidation] = useState('');
    const [wabaPhoneIdValidation, setWabaPhoneIdValidation] = useState('');
    const token = useSelector(state => state.user.token);
    const phoneId = phone.phoneId;
    const phoneRef = useRef();
    const accessTokenRef = useRef();
    const wabaIdRef = useRef();
    const wabaPhoneIdRef = useRef();
    const throughputRef = useRef();

    /// API key is API token 
    const apikeyRef = useRef();
    const apiUsernameRef = useRef();
    const basicAuthHeaderRef = useRef();
  
    const {sendRequest , data , status, error} = useHttp(updatePhone,false);


    const onCarrierSelected = (id) => {
      carrier_id = id;
    }
    const onFormSubmitHandler = (event) => {
      event.preventDefault();
      let phone = phoneRef?.current?.value;
      phone 
      = phone.replace(/\D/g, "");
      const apikey = apikeyRef?.current?.value;
      const apiuser = apiUsernameRef?.current?.value;
      const apiHeader = basicAuthHeaderRef?.current?.value;
      const accessToken = accessTokenRef?.current?.value ? accessTokenRef.current.value : undefined;
      const wabaId = wabaIdRef?.current?.value;
      const wabaPhoneId = wabaPhoneIdRef?.current?.value;
      const throughputVal = throughputRef?.current?.value;
      if(!(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/).test(phone)){
        setPhoneValidation('This does not seems a valid number.');
        return;
      }
      if(apiuser === '' && numberType !== NUMBER_TYPE.WHATSAPP){
        setapiUserNameValidation('This cannot be empty. Please enter the user  value.');
        return;
      }
      if(apikey === '' && numberType !== NUMBER_TYPE.WHATSAPP){
        setApiKeyValidation('This cannot be empty. Please enter the key value.');
        return;
      }
      if(apiHeader === '' && numberType !== NUMBER_TYPE.WHATSAPP){
        setapiBasicAuthHeader('This cannot be empty. Please enter the header value.');
        return;
      }        
      if((phone === '' || apikey === '' )&& numberType !== NUMBER_TYPE.WHATSAPP){
        return;
      } 
      else{
        const newPhone = {
          phone,
          apiKey:apikey, 
          phoneId, 
          apiUsername:apiuser, 
          basicAuthHeader:apiHeader,
          token,
          throughput : throughputVal,
        }
        if(wabaPhoneId !== ''){
          newPhone.whatsappCred = {
            phoneNumberId : wabaPhoneId,
            accessToken : accessToken,
            waBusinessId : wabaId
          }
        }
        if(numberType === NUMBER_TYPE.WHATSAPP){
          newPhone.whatsappOnly = true;
        }
        if(carrier_id){
          newPhone.carrierConf = {
            id : carrier_id 
          }
        }
        sendRequest(newPhone);
      }
    }

    useEffect(() => {
      if(status === 'completed'){
        if(error == null){
          toast.success("Phone updated successfully.");
          onCredentialEdited(data);
        }
        else{
          toast.error(error);
          onCredentialEdited();  
        }
      }
    },[status,error,data])   
      
    useEffect(() => {
      apikeyRef.current.value = phone?.apiKey;
      apiUsernameRef.current.value = phone?.apiUsername;
      basicAuthHeaderRef.current.value = phone?.basicAuthHeader;
      phoneRef.current.value = phone?.phone
      throughputRef.current.value = phone.throughput
      carrier_id = phone?.carrierConf?.id;
      if(phone?.whatsappCred) {
        setNumberType(NUMBER_TYPE.WHATSAPP);
      }
      if(!phone?.whatsappOnly && phone?.whatsappCred){
        setNumberType(NUMBER_TYPE.BOTH);
      }
    },[phone])

    useEffect(() => {
      if(numberType !== NUMBER_TYPE.SMS){
        if (phone?.whatsappCred?.accessToken)
          accessTokenRef.current.value = phone?.whatsappCred?.accessToken;
        if (phone?.whatsappCred?.waBusinessId)
          wabaIdRef.current.value = phone?.whatsappCred?.waBusinessId;
        if (phone?.whatsappCred?.phoneNumberId && wabaPhoneIdRef.current) {
          wabaPhoneIdRef.current.value = phone.whatsappCred.phoneNumberId;
        }
      }
    }, [numberType,phone]);

  return (
    <MDBox display="flex" flexDirection="column">
      <MDBox pt={4} pb={3} px={3} onSubmit={onFormSubmitHandler}>
        <MDBox component="form" role="form">
          <MDBox mb={2}>
            <MDInput
              error={phoneValidation !== ""}
              type="tel"
              label="NUMBER"
              variant="standard"
              inputRef={phoneRef}
              fullWidth
            />
          </MDBox>
          <MDTypography
            variant="overline"
            fontWeight="medium"
            color="error"
            mt={1}
          >
            {phoneValidation}
          </MDTypography>
          <MDBox ml={1} width="100%" display="flex" justifyContent="start">
            <FormGroup>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) => {
                      setNumberType(e.target.value)
                  }}
                  value={numberType}
                  >
                  <FormControlLabel value={"SMS"} control={<Radio />} label="SMS"/>
                  <FormControlLabel value={"WHATSAPP"} control={<Radio />} label="WHATSAPP"/>
                  <FormControlLabel value={"BOTH"} control={<Radio />} label="BOTH"/>                        
                </RadioGroup>
              </FormControl>                
            </FormGroup>
            </MDBox>
          { numberType != NUMBER_TYPE.WHATSAPP && <MDBox>
            <MDBox mb={2}>
              <MDInput
                error={apiUserNameValidation !== ""}
                type="text"
                label="API USERNAME"
                variant="standard"
                inputRef={apiUsernameRef}
                fullWidth
              />
            </MDBox>
            <MDTypography
              variant="overline"
              fontWeight="medium"
              color="error"
              mt={1}
            >
              {apiUserNameValidation}
            </MDTypography>
      
            <MDBox mb={2}>
              <MDInput
                error={apiKeyValidation !== ""}
                type="text"
                label="API PASSWORD/TOKEN"
                variant="standard"
                inputRef={apikeyRef}
                fullWidth
              />
            </MDBox>
            <MDTypography
              variant="overline"
              fontWeight="medium"
              color="error"
              mt={1}
            >
              {apiKeyValidation}
            </MDTypography>

            <MDBox mb={2}>
              <MDInput
                error={apiBasicAuthHeader !== ""}
                type="text"
                label="BASIC AUTH HEADER"
                variant="standard"
                inputRef={basicAuthHeaderRef}
                fullWidth
              />
            </MDBox>
            <MDTypography
              variant="overline"
              fontWeight="medium"
              color="error"
              mt={1}
            >
              {apiBasicAuthHeader}
            </MDTypography>
            
            <MDBox mt={2} width="100%">
                <ShowCarrier labelText="CHOOSE CARRIER" selected={phone?.carrierConf?.id} onCarrierSelected={onCarrierSelected}/> 
            </MDBox>
            
            <MDBox mb={2}>
              <MDInput type="number" label="Messages allowed per minute" variant="standard" inputRef={throughputRef} fullWidth />
              <MDTypography fontSize={"12px"} color={"info"}>{"Value 0 indicates that there's no throughput limit set on phone number."}</MDTypography>
            </MDBox>                
          </MDBox>}

          {(numberType === NUMBER_TYPE.WHATSAPP || numberType === NUMBER_TYPE.BOTH) && 
              <MDBox display="flex" flexDirection="column">
                <MDBox mb={2}>
                  <MDInput error={accessTokenValidation !== ''} type="text" label="WA ACCESS TOKEN" variant="standard" inputRef={accessTokenRef} fullWidth />
                </MDBox> 
                <MDTypography variant="overline" fontWeight="medium" color="error" mt={1}>
                      {accessTokenValidation}
                </MDTypography>
                <MDBox mb={2}>
                  <MDInput error={wabaIdValidation !== ''} type="text" label="WA BUSINESS ID" variant="standard" inputRef={wabaIdRef} fullWidth />
                </MDBox> 
                <MDBox mb={2}>
                  <MDInput error={wabaPhoneIdValidation !== ''} type="text" label="WA PHONE NUMBER ID" variant="standard" inputRef={wabaPhoneIdRef} fullWidth />
                </MDBox>  
                <MDBox width="100%">
                  <Typography fontWeight={"600"} width={"100%"} fontSize={"12px"}>{"Find this value in your FB dashboard under whatsapp API section."}
                    <Tooltip title="Learn more">
                    <a href='https://developers.facebook.com/apps' target='_blank'><OpenInNewIcon sx={{height:'18px', width:'18px'}}/></a>
                    </Tooltip>
                  </Typography>
                  </MDBox> 
              </MDBox>
            }
          <MDBox mt={4} mb={1}>
            <MDButton type="submit" variant="gradient" color="info" fullWidth>
              Save
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};
export default EditCredForm;
