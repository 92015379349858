import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { APP_COLOR } from "constants/Color";
import { MESSAGE_VIEW } from "constants/ChatConstants";
import { useCallback, useState } from "react";
import MDBox from "lib/components/MDBox";
import { Badge, MenuItem, Select } from "@mui/material";
import { chatAction } from "store/chat-slice";
import { useEffect } from "react";
import { FormatPhoneNumber } from "util/UtilityFunctions";
import { memo } from "react";
import { VIEWS } from "constants/ChatConstants";
import { userDetailAction } from "store/user-details-slice";
import { groupAction } from "store/group-slice";
import { appAction } from "store/app-slice";
import { messageAction } from "store/message-slice";

const isAnyUnread = (phones) => {
    return phones.filter(item => item.unread > 0).length;
}

const PhonesDropDown = () => {

    const dispatch = useDispatch();

    const [availablePhones,setAvailablePhones] = useState([]);
    const currentUser = useSelector(state => state.userDetails.details);
    const activePhone = useSelector(state => state.chat.current_active_phone);
    const currentMessageType = useSelector(state => state.chat.current_message_view);
    const [currentActivePhone, setCurrentActivePhone] = useState();
    const currentActiveView = useSelector(state => state.chat.current_active_view);
    const unreadCount = useSelector(state => state.chat.unreadCount);
    
    const onPhoneChangeHandler = useCallback((e) => {
      const changedPhone = e.target.value;
      setCurrentActivePhone(changedPhone);
      if(changedPhone === 'ALL'){
        const imaginaryNumber = {
          phone : "ALL",
          phoneId : -100000
        }
        dispatch(chatAction.setCurrentActiveView(VIEWS.MERGED_VIEW));
        dispatch(appAction.setMergeViewActive(true));
        dispatch(chatAction.setCurrentActivePhone(imaginaryNumber));
      }
      else{
        dispatch(appAction.setMergeViewActive(false));
        dispatch(chatAction.resetChats());
        dispatch(messageAction.resetChats());
        dispatch(groupAction.resetGroup());
        const currPhone = currentUser.phones.filter(phone => phone.phone === changedPhone);
        dispatch(chatAction.setCurrentActiveView(VIEWS.NORMAL_VIEW))
        dispatch(chatAction.setCurrentActivePhone(currPhone[0]));
      }
      dispatch(chatAction.setCurrentActiveRecipient(undefined));
    },[currentUser.phones,currentActiveView]);

    useEffect(() => {
      if(activePhone && unreadCount === 0){
        // set phone unread count to zero
        dispatch(userDetailAction.updatePhones({count : 0,phoneId : activePhone.phoneId}));
      }
    },[unreadCount,activePhone]);

    useEffect(() => {
        if(currentUser.phones) {
          const mapPhones = currentUser.phones.map(item => {
            return{
              phone : item.phone,
              phoneId : item.phoneId,
              unread : item.unread
            }
          });
          setAvailablePhones(mapPhones);
        }
    },[currentUser.phones]);

    useEffect(() => {
      if(activePhone){
        setCurrentActivePhone(activePhone?.phone);
        dispatch(chatAction.setCurrentMessageView("ALL"))
      }
    }, [activePhone]);

    return(
        <MDBox display="flex">
        { currentActivePhone && availablePhones && <MDBox>
          <Badge badgeContent={isAnyUnread(availablePhones)} color="primary"></Badge>
          <Select
            value={currentActivePhone}
            label=""
            sx={{
              width: "148px",
              height: "36px",
              "& .MuiSvgIcon-root": {
                color: `${APP_COLOR.APP_SECONDARY_COLOR}`,
                display: "inline-block",
                fontSize:'18px!important'
              }
            }}
            SelectDisplayProps={{
              style:{
                color: `${APP_COLOR.APP_SECONDARY_COLOR}`}
            }}
            onChange={onPhoneChangeHandler}
          >
            {availablePhones.map((item) => (
              <MenuItem
                style={{ color: `${APP_COLOR.APP_SECONDARY_COLOR}`, }}
                key={item.phoneId}
                value={item.phone}
              >
                  <Badge
                     anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    badgeContent={item.unread}
                    sx={{
                      "& .MuiBadge-badge" : {
                        top: '20%!important',
                        left: '-6px!important'
                      }
                    }}
                    invisible={currentActivePhone=== item.phone}
                    color="primary">
                    {FormatPhoneNumber(item.phone)}
                  </Badge>
              </MenuItem>
            ))}
            <MenuItem  style={{ color: `${APP_COLOR.APP_SECONDARY_COLOR}`, }} key={-2} value={"ALL"}>{"ALL"}</MenuItem>
          </Select>
        </MDBox>}
        <MDBox sx={{marginLeft:'12px'}}>
          <Select
            value={currentMessageType}
            label=""
            onChange={e => {
              if(currentActiveView === 'GROUP_VIEW'){
                return;
              }
              const currentType = e.target.value;
              if(currentType === 'WHATSAPP'){
                console.log(activePhone)
                // BEFORE SETTING NEED TO CHECK IF IT IS ALSO AN WHATSAPP NUMBER
                // IF THIS NUMBER IS ALSO WHATSAPP NUMBER...
                  if(activePhone.whatsappCred){
                    dispatch(chatAction.setCurrentMessageView(currentType))
                  }
                  else{
                    toast.error("This number does not have any whatsapp account. Please contact the administrator.")
                  }
              }
              else if(currentType === 'SMS')
                dispatch(chatAction.setCurrentMessageView(currentType))
              else if(currentType === 'ALL') {
                if(activePhone.whatsappCred){
                  dispatch(chatAction.setCurrentMessageView(currentType))
                }
                else{
                  toast.error("This number does not have any whatsapp account. Please contact the administrator.")
                }
              }  
            }}
            sx={{
              width: "110px",
              height: "36px",
              "& .MuiSvgIcon-root": {
                color: `${APP_COLOR.APP_SECONDARY_COLOR}`,
                display: "inline-block",
                fontSize:'18px!important'
              },
            }}
            SelectDisplayProps={{
              style:{
                color: `${APP_COLOR.APP_SECONDARY_COLOR}`}
            }}
          >
            {MESSAGE_VIEW.map(item => <MenuItem style={{ color: `${APP_COLOR.APP_SECONDARY_COLOR}`, }} key={item} value={item}>{item}</MenuItem>)}
          </Select>
        </MDBox>
      </MDBox>
    )
}
export default memo(PhonesDropDown);