export const recipientExist = (recipientListState, phone) => {
    const tempList = [...recipientListState];

    if(!phone){
        return false;
    }
    const newArr = tempList.find(item => item.phone === phone);
    if(newArr){
        return true;
    }
    return false;
}

export const amountOfCreditToBeUsed = (message) => {
    if(!message || message?.length === 0) return 0;
    const creditUsed = Math.ceil(message.length/160);
    return creditUsed;
}

export const removeDeletedConversations = (conversation,deletedIds) => {
    ///// let assume conversation [2,4,5,6,8] Although it is not
    ////// deletedIds [4,6], it will be like this
    //// O(n) solution: 
    let map = {};
    deletedIds.forEach(id => map[id] = true);
    const newConversation = conversation.filter(item => {
        if(map[item.id] == true){
            return;
        }
        return item;
    })
    return newConversation;
}

export const removeDeletedRecipientConversationsLatestMessage = (recipientList,deletedIds) => {
    ///// let assume conversation [2,4,5,6,8]
    ////// deletedIds [4,6], it will be like this
    //// O(n) solution: 
    let map = {};
    deletedIds.forEach(id => map[id] = true);
    const newRecipientList = recipientList.filter(item => {
        let tmpItem = {...item};
        if(map[item.id] == true){
            tmpItem.latestMessage = null
        }
        return tmpItem;
    })
    return newRecipientList;
}