import { BASE_URL } from "constants/Endpoints";
import fetch from "core/fetch";
/*
{
    "From":"17845123658",
      "To": ["17845961236"],
    "Message":"kya problem h bhai"
  }
*/
///// To get all the contacted recipient for a specific phone
export const getRecipientForGivenPhone = async({phoneId,token,pageNo,pageSize}) => {
    const response = await fetch(`${BASE_URL}/phone/${phoneId}/recipient?pageNo=${pageNo | 0}&pageSize=${pageSize | 20}`,{
        method : 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const markMessageRead = async(recipient) => {
    const payload = {
        ...recipient,
        token : undefined
    }
    const response = await fetch(`${BASE_URL}/sms/phone/recipient/read`,{
        method : 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + recipient.token,
          },
        body : JSON.stringify(payload),
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const markWAMessageRead = async(recipient) => {
    const payload = {
        ...recipient,
        token : undefined
    }
    const response = await fetch(`${BASE_URL}/sms/phone/recipient/read/wa`,{
        method : 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + recipient.token,
          },
        body : JSON.stringify(payload),
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}