import { InputAdornment, TextField, Tooltip } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MDBox from "lib/components/MDBox";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useHttp from "hooks/use-http";
import { updateCredit } from "api/user";
import { toast } from "react-toastify";
import { Close } from "@mui/icons-material";
import AppConstants from "constants/AppConstants";

const creditState = {
    TEXT:'text',
    INPUT:'input'
}
const TotalCredit = ({credit,userId,companyId}) =>{

    const [guState, setGuState] = useState(creditState.TEXT);
    const [currentCredit, setCurrentCredit] = useState(credit);
    const token = useSelector(state => state.user.token);
    const role = useSelector(state => state.user.role);
    const {sendRequest : updateCreditFn, status, data,error} = useHttp(updateCredit,false);

    const onCreditChange = (e) => {
        setCurrentCredit(e.target.value);
    }
    const creditUpdateHandler = ( ) => {
        if(parseInt(currentCredit) <= AppConstants.CREDIT_AMOUNT_THRESHHOLD){
            setGuState(creditState.TEXT);
            updateCreditFn({token,user_id:userId,credit : currentCredit,company_id:companyId})
        }
        else toast.error(AppConstants.VALID_CREDIT_AMOUNT_WARNING);
    }
    const onEnter = (e) => {
        if(e.keyCode == 13 && e.shiftKey == false){
            creditUpdateHandler();
        }
    }
    const onGUClickHanlder = () => {
        if(role === 'SUPER_ADMIN' || role === 'ADMIN'){
            setGuState(creditState.INPUT)
        }
        else{
            toast.info("You do not have permissions to edit.")
        }
    }
    useEffect(() => {
        if(status === 'completed'){
            if(data){
                toast.success("Credit updated successfully.")
            }
            else{
                toast.error(error);
                setCurrentCredit(credit);
            }
        }
    },[status])
    return (
       <MDBox display="flex" flexDirection="row">
        {guState === creditState.TEXT && <Tooltip title="click to edit."><MDTypography onClick={onGUClickHanlder}>{currentCredit}</MDTypography></Tooltip>}
        {guState === creditState.INPUT && 
            <MDBox display="flex">
                <TextField 
                    onChange={onCreditChange} 
                    onKeyDown={onEnter}
                    value={currentCredit}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <CheckCircleIcon onClick={creditUpdateHandler} sx={{height:'24px',width:'24px',cursor:'pointer'}}/>
                            <Close sx={{height:'24px',width:'24px',cursor:'pointer',marginLeft:'8px'}} onClick={() => setGuState(creditState.TEXT)}/>
                        </InputAdornment>,
                      }}
                />
            </MDBox>
        }    
       </MDBox>
    )
}
export default TotalCredit;