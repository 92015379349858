export const MESSAGE_VIEW = [
    'SMS',
    'WHATSAPP',
    'ALL'
];

export const TELECOM_CONSTANTS = {
    pendingRecipientMsgs : 'pendingRecipientMsgs',
    pendingBroadcastMsgs : 'pendingBroadcastMsgs'
}
export const TELE_MESSAGE_STATUS = {
    scheduled: 'scheduled',
    failed : 'failed',
    sending : 'sending',
    queued : 'queued',
    partial : 'partial',
    scheduled_cancelled : 'scheduled_cancelled',
    sent : 'sent',
    read : 'read',
    prepare : 'prepare'
}

export const CALENDAR_STRING = {
    sameDay : 'LT',
    lastDay : '[Yesterday]',
    lastWeek: 'ddd',
    sameElse : 'L'
};

export const CALENDAR_STRING_IC = {
    sameDay : '[Today]',
    lastDay : '[Yesterday]',
    lastWeek: 'ddd',
    sameElse : 'L'
};

export const MESSAGE_STATUS = {
    READ : 'read',
    PENDING : 'pending',
    DELIVERED : 'delivered',
    SENT : 'sent'
}

export const MESSAGE_STATUS_TEXT = {
    READ : 'Read',
    PENDING : 'Pending',
    DELIVERED : 'Delivered',
    SENT : 'Sent'
}

export const WEBSOCKET_EVENT_TYPE = {
    ONLINE_STATUS : 'online_status',
    USER_TYPING: 'typing',
    NEW_MESSAGE : 'new_message',
    MESSAGE_ACK : 'message_ack',
    PHONE_NEW_MESSAGE : 'phone_new_message',
    ADDED_TO_GROUP : 'added_to_group',
    REPLYING_TO_RECIPIENT : 'replying_to_recipient',
    WEB_UPDATE : 'web_update',
    SMS_READ : 'sms_read',
    SMS_MESSAGE : 'sms_message',
    SMS_UNREAD : 'sms_unread', 
    CREDIT_UPDATE : 'credit_update',
    NEW_TAG : 'new_tag',
    REMOVE_TAG : 'remove_tag',
    CONTACT_BLOCKED : 'contact_blocked',
    CONTACT_UNBLOCKED : 'contact_unblocked',
    MESSAGE_FAILURE : 'message_failure',
    MESSAGE_STATUS : 'message_status',
    WA_MESSAGE_STATUS : 'wa_message_status',
    DONATION_RECEIVED : 'donation_received'
}
export const All_VIEWS = ['NORMAL_VIEW','SCHEDULED_VIEW','HIPAA_VIEW','MERGED_VIEW','UNREAD_VIEW'];

export const IVIEWS = {
    INORMAL_VIEW : 'INORMAL_VIEW',
    IGROUP_VIEW : 'IGROUP_VIEW',
}
export const VIEWS = {
    NORMAL_VIEW : 'NORMAL_VIEW',
    GROUP_VIEW : 'GROUP_VIEW',
    SCHEDULED_VIEW : 'SCHEDULED_VIEW',
    HIPAA_VIEW : 'HIPAA_VIEW',
    MERGED_VIEW : 'MERGED_VIEW',
    UNREAD_VIEW : 'UNREAD_VIEW',
    SEARCH_RECIPIENT_VIEW : 'SEARCH_RECIPIENT_VIEW',
    TELE_GROUP : "TELE_GROUP"
}
export const ACTIONS = {
    RELOAD_RECIPIENT : 'RELOAD_RECIPIENT'
}
export const MESSAGE_EVENTS = {
    FORWARDED : 'forwarded',
    CONVERSATION_ASSIGNED : 'conversation_assigned',
    CONVERSATION_UNASSIGNED : 'conversation_unassigned'
}

export const userSettings = {
    notification_enabled : false,
    read_receipt : {
        enabledByAdmin : true,
        enabledByMe : true,
        disabledBy : undefined
    },
    notification_sounds : [
        {name : 'No sound', custom : false, sourceUrl :null, enabled : true},
        {name : 'App default', custom : true, sourceUrl :'https://app--media.s3.amazonaws.com/system_default_sounds/sms_incoming.MP3', enabled : false}
    ],
}

export const MEDIA_TYPE_DOCS = []


export const MEDIA_TYPE = {
    AUDIO : 'audio',
    VIDEO : 'video',
    IMAGE : 'image',
    DOCS : 'docs',
    VCF : 'vcf',
    UNKNOWN : 'unknown'
}

export const TELE_NOTIFICATION_CHOICE = {
    all : 'all',
    assigned : 'assigned'
}

export const IMPORT_TYPE = {
    SMS : "SMS",
    WHATSAPP : "WHATSAPP"
}