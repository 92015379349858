import { Download } from '@mui/icons-material';
import { Avatar, Typography } from '@mui/material';
import MDBox from 'lib/components/MDBox';
import React, { useState } from 'react';
import Lightbox from "yet-another-react-lightbox";
// import {FileIcon,defaultStyles} from 'react-file-icon';
// import DocViewer, { DocViewerRenderers } from "@vvelediaz/react-doc-viewer";

const DocMessage = ({ url }) => {
    const [modal, setModal] = useState(false)
    const _extention = url.split(".")[url.split(".")?.length - 1]
    const _name = url.split("/")[url.split("/")?.length - 1]

    return (
        <MDBox display="flex" flexDirection="column">
            <MDBox sx={{ display: 'flex', width: '100%', cursor: 'pointer' }} onClick={() => { setModal(true) }}>
                {/* <MDBox sx={{ width: '80px', height: '90px', margin: 'auto', boxShadow: "4px 10px 5px #80808085", bordarRadius: '10px' }} ><FileIcon extension={_extention} {...defaultStyles[_extention]} /></MDBox> */}
            </MDBox>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" sx={{ padding: '5px 10px' }}>
                <a style={{ textDecoration: 'none', color: 'black' }} href={url} target='_blank'><Download sx={{ height: '18px', width: '18px' }} /></a>
                <Typography variant='h6' m={1} fontSize={'14px'}>{_name}</Typography>
            </MDBox>
            <MDBox>
                {/* <Lightbox
                    open={modal}
                    close={() => setModal(false)}
                    slides={[url]}
                    noScroll={{ disabled: true }}
                    render={{
                        buttonPrev: () => null,
                        buttonNext: () => null,
                        slide: ({ slide }) => <DocViewer prefetchMethod="GET" documents={[{ uri: slide }]} />
                    }}>
                </Lightbox>                 */}
            </MDBox>
        </MDBox>
    );
};

export default DocMessage;
