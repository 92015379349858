import { BASE_URL } from "constants/Endpoints";
import axios from "../core/axios";

export const ConversationAPI = {
    
    getWaParentMessage : async({token,wamid}) => {
        return await axios.post(`${BASE_URL}/sms/conversation/wa/message`,JSON.stringify({wamid}),{
            headers : {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token
            }
        });
    },

    getConversations : async(payload) => {
        let newPayload = {
            ...payload,
            token : undefined
        }
        return await axios.post(`${BASE_URL}/sms/phone/recipient/conversation?pageNo=${payload.pageNo | 0}&pageSize=${payload.pageSize | 60}`,JSON.stringify(newPayload),{
            headers : {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + payload.token
            }
        });
    },
    unassignConversation : async({token,payload}) => {
        return await axios.post(`${BASE_URL}/sms/conversation/unassign`,JSON.stringify(payload),{
            headers : {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token
            }
        });
    },

    markAsReadConversation : async({token,payload}) => {
        return await axios.post(`${BASE_URL}/sms/conversation/read`,JSON.stringify(payload),{
            headers : {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token
            }
        });
    },
    deleteConversation : async({token,messageId}) => {
        return await axios.delete(`${BASE_URL}/sms/conversation/${messageId}`,{
            headers : {
                'Authorization': 'Bearer ' + token
            }
        });        
    },
    unscheduleMessage : async({token,messageId}) => {
        return await axios.post(`${BASE_URL}/sms/message/unschedule`,JSON.stringify(messageId),{
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + token,
            },
        })
    },
    removeTag : async({token,tag}) => {
        return await axios.post(`${BASE_URL}/sms/conversation/remove/tag`,JSON.stringify(tag),{
            headers : {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token
            }
        });  
    },   
    createTag : async({token,payload}) => {
        return await axios.post(`${BASE_URL}/sms/conversation/create/tag`,JSON.stringify(payload),{
            headers : {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token
            }
        });  
    }
 }