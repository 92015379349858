import bgImage from "assets/images/bg-sign-in-basic.webp";
import ForgotPasswordEmail from "components/ForgotPasswordEmail";
import { Card } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import BasicLayout from "lib/layouts/authentication/components/BasicLayout";
import ResetPassword from "components/ResetPassword";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const ForgotPassword = () => {
  const {state}= useLocation()
  const [currentStep, setCurrentStep] = useState('email');  
  const [email,setEmail]  = useState();
  const onEmailSuccess = (data) => {
    setCurrentStep('resetting');
    setEmail(data);
  }
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Reset password
          </MDTypography>
        </MDBox>
        {currentStep === 'email' && <ForgotPasswordEmail email={state?.email || ''} onEmailSuccess={onEmailSuccess}/>}
        {currentStep=== 'resetting' && <ResetPassword email={email}/>}
      </Card>
    </BasicLayout>
  );
};
export default ForgotPassword;
