export const generatePrivacyPolicyUrl = (name) => {
    const splitNameWithSpace = name.split(" ");
    let url = `/t&c/`;
    for(let i = 0; i< splitNameWithSpace.length; i++){
        url += splitNameWithSpace[i];
        if(i !== splitNameWithSpace.length - 1){
            url += "-";
        }
    }
    return url;
}