/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import { useLocation, NavLink } from "react-router-dom";
// @mui material components
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import Collapse from '@mui/material/Collapse';
import Link from "@mui/material/Link";
import List from "@mui/material/List";
// Material Dashboard 2 React components
import MDBox from "lib/components/MDBox";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "lib/layouts/Sidenav/styles/sidenavCollapse";

// Material Dashboard 2 React context
import { useMaterialUIController } from "lib/context";
import { useState } from "react";

function SidenavCollapse({ icon, name, active, collapse, ...rest }) {
  const [open, setOpen] = useState(false)
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const location = useLocation();
  const collapseName = location.pathname.split("/").pop();
  const isCollapseble = collapse?.length > 0;
  return (
    <>
      <ListItem component="li" onClick={(e) => {       
        if (collapse?.length > 0) {
          e.stopPropagation()
          e.preventDefault()
          setOpen(!open);
        }
      }}>
        <MDBox
          {...rest}
          sx={(theme) =>
            collapseItem(theme, {
              active,
              transparentSidenav,
              whiteSidenav,
              darkMode,
              sidenavColor,
            })
          }
        >
          <ListItemIcon
            sx={(theme) =>
              collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
            }
          >
            {typeof icon === "string" ? (
              <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
            ) : (
              icon
            )}
          </ListItemIcon>

          <ListItemText
            primary={name}
            sx={(theme) =>
              collapseText(theme, {
                miniSidenav,
                transparentSidenav,
                whiteSidenav,
                active,
              })
            }
          />
          {isCollapseble ? open ? <Icon fontSize="small">keyboard_arrow_up</Icon> : <Icon fontSize="small">keyboard_arrow_down</Icon> : <></>}
        </MDBox>
      </ListItem>
      {isCollapseble ? <Collapse in={open} timeout="auto"
        unmountOnExit>
        <List key={name + "_list"}>{collapse.map(({ name, icon, key, href, route }) => {
          return href ? (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <MDBox
                {...rest}
                sx={(theme) =>
                  collapseItem(theme, {
                    active: key === collapseName,
                    transparentSidenav,
                    whiteSidenav,
                    darkMode,
                    sidenavColor,
                  })
                }
              >
                <ListItemIcon
                  sx={(theme) =>
                    collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
                  }
                >
                  {typeof icon === "string" ? (
                    <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
                  ) : (
                    icon
                  )}
                </ListItemIcon>

                <ListItemText
                  primary={name}
                  sx={(theme) =>
                    collapseText(theme, {
                      miniSidenav,
                      transparentSidenav,
                      whiteSidenav,
                      active: key === collapseName,
                    })
                  }
                />
              </MDBox>
            </Link>
          ) : (
            <NavLink key={key} to={route}>
              <MDBox
                {...rest}
                sx={(theme) =>
                  collapseItem(theme, {
                    active: key === collapseName,
                    transparentSidenav,
                    whiteSidenav,
                    darkMode,
                    sidenavColor,
                  })
                }
              >
                <ListItemIcon
                  sx={(theme) =>
                    collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active: key === collapseName })
                  }
                >
                  {typeof icon === "string" ? (
                    <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
                  ) : (
                    icon
                  )}
                </ListItemIcon>

                <ListItemText
                  primary={name}
                  sx={(theme) =>
                    collapseText(theme, {
                      miniSidenav,
                      transparentSidenav,
                      whiteSidenav,
                      active: key === collapseName,
                    })
                  }
                />
              </MDBox>
            </NavLink>
          );
        })}</List>
      </Collapse> : <></>}
    </>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
  collapse: PropTypes.any,
};

export default SidenavCollapse;
