import { Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useCallback, useEffect, useState } from "react";
import { AppTextEditor } from "ui/AppTextEditor";
import { generatePrivacyPolicyUrl } from "util/CompanyUtils";
import { getCompanyPrivacyPolicy } from "api/company";
import { useSelector } from "react-redux";
import useHttp from "hooks/use-http";
import { setCompanyPrivacyPolicy } from "api/company";
import { toast } from "react-toastify";

const PrivacyPolicy = ({company}) => {
    
    const { sendRequest, status, data, error } = useHttp(getCompanyPrivacyPolicy,false);
    const{sendRequest : setPrivacyPolicyFn, status : updateStatus,data : updatedData,error : updateErr} = useHttp(setCompanyPrivacyPolicy,false);

    const [companyPolicy, setCompanyPolicy] = useState();
    const currUser = useSelector(state => state.user);

    const onPolicySaved = useCallback((html) => {
        const p = {
            id : company?.company_id,
            privacyPolicy : html
        }
       setCompanyPolicy(html);
       setPrivacyPolicyFn({token:currUser.token,privacyPolicy : p})
    },[company,currUser]);

    const getPrivacyPolicyUrl = () => {
        return  `${window.location.origin}`+ generatePrivacyPolicyUrl(company.companyName);
    }

    useEffect(() => {
        if (company) {
          sendRequest({companyId : company.companyId,token : currUser.token});
        }
      }, [company]);

      useEffect(() => {
        if(status === 'completed'){
          if(data){
            const html = data.privacyPolicy ? data.privacyPolicy : data.companyWidePolicy;
            if(Boolean(html)){
                setCompanyPolicy(html);
            }
            else setCompanyPolicy('<p></p>')
          }
          else if(error){
            setCompanyPolicy('<p></p>')
          }
        }
      },[status,data,error])

      useEffect(() => {
        if(updateStatus === 'completed'){
            if(updatedData){
                toast.success("updated successfully.");
            }
        }
    },[updateErr,updateStatus,updatedData]);

    return(
        <>
            <MDBox display="flex" flexDirection="column" width="100%">
                <MDBox width="100%" display="flex" justifyContent="center">
                    <Typography variant="h5">Privacy Policy Settings</Typography>
                </MDBox>
                <MDBox width="100%" display="flex" justifyContent="center">
                    {company && <Typography fontSize={'12px'}>visit <a href={ company.privacyPolicyUrl ? company.privacyPolicyUrl : getPrivacyPolicyUrl()} target="_blank">{ company.privacyPolicyUrl ? company.privacyPolicyUrl : getPrivacyPolicyUrl()}</a></Typography>}
                </MDBox>
                <MDBox m={2} sx={{border:"2px dashed #c9c5c5",padding:'16px',height:"500px"}}>
                    {companyPolicy && <AppTextEditor onPolicySaved={onPolicySaved} initialContent={companyPolicy ? companyPolicy : ''}/>}
                </MDBox>
            </MDBox>
        </>
    )

}
export default PrivacyPolicy;