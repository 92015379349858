import { createSlice } from "@reduxjs/toolkit";

const initialStates = {
    activeCheckbox : {},
    isCheckboxSelected : false,
    allConversationSelected: false,
    rightNavChecked : false,
    iChat : false,
    scrollChatBottomBtn : false,
    recipientContainerLoader: false,
    recipientSearchState : {
        search: false,
        pattern: "",
    },
    favicon : undefined
};

const chatVariablesSlice = createSlice({
    name: "chatVariables",
    initialState : initialStates,
    reducers : {
        setRecipientContainerLoader : (state,action) => {
            state.recipientContainerLoader = action.payload;
        },
        setRightNavChecked : (state,action) => {
            state.rightNavChecked = action.payload;
        },
        setActiveCheckbox : (state,action) => {
            state.activeCheckbox = action.payload;
        },
        setAllConversationSelected : (state, action) => {
            state.allConversationSelected = action.payload;
        },
        pushId : (state,action) => {
            const {key,id} = action.payload;
            state.activeCheckbox[key].messageIds.push(id);
        },
        updateIds : (state,action) => {
            const {key,ids} = action.payload;
            state.activeCheckbox[key].messageIds = ids;
        },
        setIsCheckboxSelected : (state,action) => {
            state.isCheckboxSelected = action.payload
        },
        recipientSearch : (state,action) => {
            state.recipientSearchState = action.payload;
        },
        setIchat : (state,action) => {
            state.iChat = action.payload;
        },
        setFavico : (state,action) => {
            state.favicon = action.payload;
        },
        destroyFavico : (state,action) => {
            state.favicon = undefined
        },
        setScrollChatBottomBtn : (state,action) => {
            const scrollUp = action.payload;
            state.scrollChatBottomBtn = scrollUp;
        },
        reset : () => initialStates

    }
})

export const chatVariablesAction = chatVariablesSlice.actions;
export default chatVariablesSlice;