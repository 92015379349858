import { BASE_URL } from "constants/Endpoints";
import fetch from "core/fetch";

export const uploadCsv = async(uploadData) => {

    const response = await fetch(`${BASE_URL}/sms/bulk/upload`,{
        method : 'POST',
        body : uploadData.formData,
        headers: {
            // 'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + uploadData.token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const sendBlast = async(blastData) => {
    
    let payload = {...blastData, token: undefined}

    const response = await fetch(`${BASE_URL}/sms/bulk`,{
        method : 'POST',
        body : JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + blastData.token,
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const processBulkRecipientAdditionWithConsentOpt = async({payload,token}) => {

    const response = await fetch(`${BASE_URL}/sms/bulk/group/consent/process`,{
        method : 'POST',
        body : JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const sendBlastToIndividual = async({payload,token}) => {

    const response = await fetch(`${BASE_URL}/sms/bulk/individual`,{
        method : 'POST',
        body : JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
export const groupNameAvailable = async(groupData) => {

    const payload = {
        ...groupData,
        token : undefined
    }
    const response = await fetch(`${BASE_URL}/sms/bulk/group`,{
        method : 'POST',
        body : JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + groupData.token,
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}