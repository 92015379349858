import { Tooltip } from "@mui/material";
import MDBox from "lib/components/MDBox";
import EditIcon from "@mui/icons-material/Edit";
import AppModal from "ui/AppModal";
import { useState } from "react";
import EditUserForm from "./EditUserForm";

const EditUser = ({user,userEdited,onUserDeleted}) => {
    const [editing,setEditing] = useState(false);

    return(
        <>
            <MDBox display="flex" flexDirection="row">
                <Tooltip title="Edit.">
                <EditIcon
                    onClick={() => {
                        setEditing(true)
                    }}
                    style={{
                        marginRight: "8px",
                        cursor: "pointer",
                        width: "1.25rem",
                        height: "1.25rem",
                    }}
                />
                </Tooltip>            
            </MDBox>
            {editing && 
                <AppModal
                    onModalClose={() => setEditing(false)}
                    closeOnClick={true}
                    heading={"Edit user"}
                    rootStyle = {
                        {
                          "&.MuiModal-root":{
                            width:'40%',
                            left: '60%'
                          }
                        }
                      }
                    customStyle={{ 
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <EditUserForm user={user} onUserDeleted={(id) => {setEditing(false);onUserDeleted(id);}} userEdited={userEdited}/>
                </AppModal>
            }
        </>
    )
}
export default EditUser;