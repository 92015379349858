import { Close } from "@mui/icons-material";
import { Divider, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { useEffect, useState } from "react";
import ThreadMessage from "./ThreadMessage";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import useIsend from "hooks/IChat/use-isend";
import useIgroupsend from "hooks/IChat/use-igroup-send";
import React from "react";
import ReplyWrapper from "./ReplyWrapper";
import { MessageReplyAPI } from "services/IChat/MessageReplyAPI";
import { IThreadAction } from "store/IChat/ithread-slice";
import { ichatVariablesAction } from "store/IChat/Ichat-variables-slice";
import { IVIEWS } from "constants/ChatConstants";

const IThread = ({message}) => {

    const dispatch = useDispatch();

    const currentActiveChat = useSelector(state => state.Ichat.currentActiveChat);
    const currentActiveGroup = useSelector(state => state.Igroup.currentActiveGroup);
    const currentActiveView =  useSelector(state => state.ichatVariables.activeView);
    const replies = useSelector(state => state.Ithread.conversation[message.messageId]);
    const replyFetched = useSelector(state => state.Ithread.fetched);
    const token = useSelector(state => state.user.token);
    const [messageState, setMessageState] = useState({
        message: "",
        attachement: undefined,
    });
    //Normal message reply...
    const {sendReply} = useIsend();

    // Group message reply
    const{sendGroupReply} = useIgroupsend();

    const onReplySend = useCallback((payload) => {
        const newReply = {
            ...payload,
            parentMessage : {
                messageId : message.messageId
            }
        }
        if(currentActiveView === IVIEWS.INORMAL_VIEW) {
            sendReply({token,payload : newReply});
        }
        else if(currentActiveView === IVIEWS.IGROUP_VIEW){
            sendGroupReply({token,payload:newReply});
        }
    },[currentActiveView,message,sendReply,sendGroupReply,token]) 

    const handleOnTyping = (payload) => {
        console.log(payload)
    }

    useEffect(() => {

        if(!replies || !replyFetched){
            // Fetch the reply
            if(currentActiveView === IVIEWS.INORMAL_VIEW) {
                MessageReplyAPI.getReplies({token,messageId : message.messageId})
                .then(res => {
                    if(res.data && res.data.content){
                        const newReplies = res.data.content;
                        dispatch(IThreadAction.setReplies({parentId : message.messageId, replies : newReplies}))
                    }
                })
                .catch(e => console.log(e));
            }
            else {
                MessageReplyAPI.getGroupReplies({token,messageId : message.messageId})
                .then(res => {
                    if(res.data && res.data.content){
                        const newReplies = res.data.content;
                        dispatch(IThreadAction.setReplies({parentId : message.messageId, replies : newReplies}))
                    }
                })
                .catch(e => console.log(e));
            }
        }
    },[replies,message,currentActiveView]);

    return(
        <MDBox display="flex" flexDirection="column" height="100%" width="100%">
            <MDBox display="flex" justifyContent="space-between" height="50px" flexDirection="column" sx={{borderBottom: '1px solid #ddd'}}>
                <MDBox display="flex" justifyContent="space-between" padding="12px">
                    <Typography sx={{fontWeight : "600!important"}} fontSize={"16px"}>{"Thread"}</Typography>
                    <Close sx={{cursor:'pointer'}} onClick={() => dispatch(ichatVariablesAction.updateRightSideBar({open:false,content:undefined}))}/>
                </MDBox>
            </MDBox>
            <MDBox padding="16px" width="100%" sx={{
                overflowY:'auto',
                "&::-webkit-scrollbar": {
                    width: "0.3em",
                    height: "0.4em"
                  },
                  "&::-webkit-scrollbar-thumb" : {
                    borderRadius:" 4px",
                    backgroundColor: "#8b8b8b"
                  }    
                }}>
                {message && <ThreadMessage key={"thread_message" + message.messageId} message={message}/>}
                {message.repliesCount > 0 && <MDBox display="flex" width="100%" alignItems="center" justifyContent="center" marginBottom='12px'>
                    <Typography sx={{width:'30%',marginLeft:'8px',}} fontSize={"14px"}>{message.repliesCount} replies</Typography>
                    <Divider variant="middle" component="div" sx={{
                        width:'70%',  
                        marginLeft:'-8px',        
                        "&.MuiDivider-root": {
                            backgroundColor: "#e9d5e9"
                        }
                    }}/>

                </MDBox>}
                {replies?.length > 0 && 
                    replies.map(rply => <ThreadMessage key={"thread_message" + rply.messageId} message={rply}/>)
                }
                <MDBox key={message.messageId}>
                    <ReplyWrapper setMessageState={setMessageState} messageState={messageState} onReplySend={onReplySend} activeChat={currentActiveView === IVIEWS.INORMAL_VIEW ? currentActiveChat : currentActiveGroup}/>
                    {/* <IChatFooter key={"thread_" + message.messageId} onMessageSend={() =>{}} onTyping={handleOnTyping} activeChat={currentActiveView === ICHAT_CONSTANT.NORMAL_VIEW ? currentActiveChat : currentActiveGroup}/> */}
                </MDBox>
            </MDBox>
        </MDBox>
    )
}
export default React.memo(IThread);