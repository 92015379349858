import { Popper } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { useEffect, useState } from "react";

const SearchList = ({popoverTarget,children}) => {
    const id = "Contact-Search";

    const [openSearchList, setOpenSearchList] = useState(false);

    useEffect(() => {
        setOpenSearchList(popoverTarget);
    },[popoverTarget])
    
    return(
        <>
            <Popper 
                id={id}
                style={{
                    zIndex : 999999,
                    width: '100%',
                    minWidth: '30%',
                    maxWidth : '420px',
                    height:'calc(100% - 112px)',
                    overflow:'hidden',
                    borderRight:'1px solid rgb(221, 221, 221)'
                }}
                open={Boolean(openSearchList)}
                placement="bottom-start"
                anchorEl={openSearchList}
                // onClose={handleClose}
                >
                <MDBox sx={{backgroundColor: "#fff!important",width:"100%",padding:"12px",height:"100vh"}}>
                    {children}
                </MDBox>
            </Popper>    
        </>
    )
}
export default SearchList; 