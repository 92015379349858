import { BASE_URL } from "constants/Endpoints";

export function notify(payload,onClick = null) {
  if (!("Notification" in window)) {
      // Check if the browser supports notifications
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      // Check whether notification permissions have already been granted;
      // if so, create a notification
      const notification = new Notification(`Message from ${payload.from} to ${payload?.to}`,{
        body: payload.body,
        tag: payload.from
      });
      // …
    } else if (Notification.permission !== "denied") {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          const notification = new Notification(`Message from ${payload.from} to ${payload?.to}`,{
            body: payload.body,
            tag: payload.from
          });
        }
      });
    }
    try{
      if(Boolean(payload.muted) === false){
        const nsound = payload.sound ? payload.sound : `${BASE_URL}/upload/image/system_default.mp3`;
        playSound(nsound);
      }
    }
    catch(e){
      console.log(e);
    }
    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them anymore.
  }
  function playSound(url) {
    const audio = new Audio(url);
    audio.load()
    audio.play().catch(e => console.log(e));
  }