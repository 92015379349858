import { Typography } from "@mui/material";
import {ReactComponent as ErrorSvg} from "assets/svg/error.svg";
import MDBox from "lib/components/MDBox";
const FailureUI = ({title,desc}) => {
    return(
        <MDBox width="100%" alignItems="center" padding="24px" display="flex" flexDirection="column" justifyContent="center">
            <ErrorSvg/>
            <Typography>{title}</Typography>
            <Typography fontSize={"16px"}>{desc}</Typography>            
        </MDBox>
    )
}
export default FailureUI;