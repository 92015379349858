import { BASE_URL } from "constants/Endpoints"
import axios from "../../core/axios"

export const CompanyGroupAPI = {
    createGroup : async({token,payload}) => {
        return await axios.post(`${BASE_URL}/company/group`,JSON.stringify(payload),{
            headers:{
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',                
            }
        })
    },
    getCompanyGroups : async({companyId,token}) => {
        return await axios.get(`${BASE_URL}/company/group/${companyId}`,{
            headers : {
                'Authorization': 'Bearer ' + token,
            }
        });
    }
}