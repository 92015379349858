export const BLIP_GATEWAY = process.env.REACT_APP_BLIP_GATEWAY;
export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const API_V1 = "/api/v1";
export const BLIP_WS_URL = BLIP_GATEWAY + "/websocket";

export const BLIP_API = `${BLIP_GATEWAY}${API_V1}`;
export const BLIP = "/blip";

export const BLIP_MESSAGE = `${BLIP_API}${BLIP}`;


// SMS
export const SMS = "/sms";
export const API_V1_SMS = API_V1 + SMS;
// ChatApi
export const CHAT_STRING = `${BLIP_MESSAGE}/chat`;
export const ADD_PARTICIPANT = CHAT_STRING + "/participant/add";
export const REMOVE_PARTICIPANT = CHAT_STRING + "/participant/remove";
export const MUTE = "/mute";
export const LIST = "/list";
export const CHAT = (userId) => `${BLIP_MESSAGE}/chat/${userId}`; 
export const GROUP = "/group";
export const CHAT_EXISTS = (authorId,userId) => `${BLIP_MESSAGE}/chat/${authorId}/${userId}`;
export const TOGGLE_CHAT_MUTE = (chatId,participantId,muted) => `${BLIP_MESSAGE}/${chatId}${MUTE}/${participantId}?muted=${muted}`;
export const CREATE_GROUP = CHAT_STRING + GROUP;
export const REPLY = "/reply";
export const GET_ALL_CHAT = (userId) => `${CHAT(userId)}${LIST}`

// Crowd funding...
export const SETTINGS = "/settings";
export const CROWDFUNDING = "/crowdfunding";
export const CROWDFUNDING_ENDPOINT = BLIP_API + CROWDFUNDING;
export const CAMPAIGN = "/campaign";
export const CAMPAIGN_LIST = (id) => `${CROWDFUNDING_ENDPOINT}/${id}`;
export const GET_CAMPAIGN = (id) => `${CROWDFUNDING_ENDPOINT}${CAMPAIGN}/${id}`;
export const FUNDRAISER = "/fundraiser";
export const CROWDFUNDING_FUNDRAISER = CROWDFUNDING_ENDPOINT + FUNDRAISER;
export const CREATE_FUNDRAISER = CROWDFUNDING_ENDPOINT + FUNDRAISER;
export const GET_FUNDRAISER = (id) => `${CREATE_FUNDRAISER}/${id}`
export const BULK = "/bulk";
export const CAMPAIGN_BULK = CROWDFUNDING_ENDPOINT + BULK;
export const CREATE = "/create";
export const DELETE = "/delete";
export const CROWDFUNDING_SETTINGS = `${CROWDFUNDING_ENDPOINT}${SETTINGS}`;

export const DONATION = "/donation";
export const CAMPAIGN_DONATION = CROWDFUNDING_ENDPOINT + DONATION;
export const PDF = "/pdf";
export const CAMPAIGN_DONATION_PDF = CAMPAIGN_DONATION + PDF;
export const CAMPAIGN_DONATION_PDF_PREVIEW = CAMPAIGN_DONATION + PDF + "/initial/preview";
export const PROCESS_DONATION_PAYMENT = `${CAMPAIGN_DONATION}/payment`;

//Company
export const COMPANY = "/company";
export const USER_STATUS = (companyId) => `${BLIP_API}/websocket/users/${companyId}`;
//message API
export const MESSAGE = "/message";
export const FORWARD = "/forward";
export const CHAT_MESSAGE = (chatId) => CHAT(chatId) + MESSAGE;
export const GROUP_CHAT_MESSAGE = (chatId) => CHAT(chatId) + GROUP + MESSAGE;
export const CREATE_GROUP_REPLY = CHAT_STRING + GROUP + MESSAGE + REPLY;
export const ACK = "/ack";
export const MESSAGE_ACK = CHAT_STRING + MESSAGE + ACK;
export const GET_GROUP_MESSAGE_REPLY = (messageId) => `${BLIP_MESSAGE}${GROUP}${MESSAGE}/${messageId}${REPLY}`;
export const GET_MESAGE_REPLY = (messageId) => `${BLIP_MESSAGE}/${MESSAGE}/${messageId}${REPLY}`; 
export const FORWARD_MESSAGE = `${BLIP_MESSAGE}${MESSAGE}${FORWARD}`;

//websocket
export const USER_OFFLINE_STATUS = (userId,destination) => `${BLIP_API}/websocket/${userId}/status/offline?userId=${userId}&destination=${destination}`;

//UsersApi
export const DETAILS = "/details";
export const COMPANY_USERS_EXCEPT_ME = (userId,companyId) => `${BLIP_API}/users/${userId}/company/${companyId}`;
export const COMPANY_USERS_EXCEPT_ME_WITH_SEARCH = (userId,companyId,serachPattern) => `${BLIP_API}/users/${userId}/company/${companyId}/search?searchText=${serachPattern}`;
export const API_USER_UPLOAD_IMAGE = `${BLIP_MESSAGE}/user/upload`;
export const API_UPLOAD_ASSET = `${BLIP_API}/media/upload/asset`;
export const COMPANY_USERS_EXCEPT_GIVEN_IDS = (companyId) => `${BLIP_API}/users${COMPANY}/${companyId}`;
export const BULK_UPLOAD_USER = `${BASE_URL}/users/bulk/upload`;
// Notifications API
export const NOTIFICATIONS = "/notifications"
export const USER_NOTIFICATIONS = (userId) => `${BLIP_API}${NOTIFICATIONS}/${userId}`; 