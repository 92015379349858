import { useState } from "react";
import { useEffect } from "react";

const useItyping = () => {

    const [isTyping, setIsTyping] = useState(false);

    // Function to start typing indicator
    const startTyping = () => {
      setIsTyping(true);
    };
  
    // Function to stop typing indicator
    const stopTyping = () => {
      setIsTyping(false);
    };
  
    // Reset typing indicator on component unmount
    useEffect(() => {
      return () => {
        stopTyping();
      };
    }, []);

    return { isTyping, startTyping, stopTyping };
}
export default useItyping;