export const timezones = [
    {
        "utc": "UTC+00:00 GMT ",
        "name": "GMT ",
        "region": "UTC",
        "offset 1": "UTC+00:00",
        "offset 2": "UTC+00:00"
    },
    {
        "utc": "UTC+01:00 Central Europe ",
        "name": "Central Europe ",
        "region": "Europe/Zurich",
        "offset 1": "UTC+01:00",
        "offset 2": "UTC+02:00"
    },
    {
        "utc": "UTC+01:00 Morocco ",
        "name": "Morocco ",
        "region": "Africa/Casablanca",
        "offset 1": "UTC+01:00",
        "offset 2": "UTC+01:00"
    },
    {
        "utc": "UTC+01:00 W. Central Africa ",
        "name": "W. Central Africa ",
        "region": "Africa/Bangui",
        "offset 1": "UTC+01:00",
        "offset 2": "UTC+01:00"
    },
    {
        "utc": "UTC+01:00 W. Europe ",
        "name": "W. Europe ",
        "region": "Europe/Paris",
        "offset 1": "UTC+01:00",
        "offset 2": "UTC+02:00"
    },
    {
        "utc": "UTC+02:00 E. Europe ",
        "name": "E. Europe ",
        "region": "Europe/Bucharest",
        "offset 1": "UTC+02:00",
        "offset 2": "UTC+03:00"
    },
    {
        "utc": "UTC+02:00 Egypt ",
        "name": "Egypt ",
        "region": "Egypt",
        "offset 1": "UTC+02:00",
        "offset 2": "UTC+02:00"
    },
    {
        "utc": "UTC+02:00 FLE ",
        "name": "FLE ",
        "region": "Europe/Kiev",
        "offset 1": "UTC+02:00",
        "offset 2": "UTC+03:00"
    },
    {
        "utc": "UTC+02:00 Israel ",
        "name": "Israel ",
        "region": "Israel",
        "offset 1": "UTC+02:00",
        "offset 2": "UTC+03:00"
    },
    {
        "utc": "UTC+02:00 Libya ",
        "name": "Libya ",
        "region": "Libya",
        "offset 1": "UTC+02:00",
        "offset 2": "UTC+02:00"
    },
    {
        "utc": "UTC+02:00 South Africa ",
        "name": "South Africa ",
        "region": "Africa/Johannesburg",
        "offset 1": "UTC+02:00",
        "offset 2": "UTC+02:00"
    },
    {
        "utc": "UTC+03:00 Arab ",
        "name": "Arab ",
        "region": "Asia/Istanbul",
        "offset 1": "UTC+03:00",
        "offset 2": "UTC+03:00"
    },
    {
        "utc": "UTC+03:00 Arabic ",
        "name": "Arabic ",
        "region": "Asia/Kuwait",
        "offset 1": "UTC+03:00",
        "offset 2": "UTC+03:00"
    },
    {
        "utc": "UTC+03:00 E. Africa ",
        "name": "E. Africa ",
        "region": "Africa/Addis_Ababa",
        "offset 1": "UTC+03:00",
        "offset 2": "UTC+03:00"
    },
    {
        "utc": "UTC+03:00 Moskow",
        "name": "Moskow",
        "region": "Europe/Moscow",
        "offset 1": "UTC+03:00",
        "offset 2": "UTC+03:00"
    },
    {
        "utc": "UTC+03:30 Iran ",
        "name": "Iran ",
        "region": "Iran",
        "offset 1": "UTC+03:30",
        "offset 2": "UTC+04:30"
    },
    {
        "utc": "UTC+04:00 Dubai",
        "name": "Dubai",
        "region": "Asia/Dubai",
        "offset 1": "UTC+04:00",
        "offset 2": "UTC+04:00"
    },
    {
        "utc": "UTC+04:00 Volgograd",
        "name": "Volgograd",
        "region": "Europe/Volgograd",
        "offset 1": "UTC+04:00",
        "offset 2": "UTC+04:00"
    },
    {
        "utc": "UTC+04:30 Afghanistan ",
        "name": "Afghanistan ",
        "region": "Asia/Kabul",
        "offset 1": "UTC+04:30",
        "offset 2": "UTC+04:30"
    },
    {
        "utc": "UTC+05:00 Ekaterinburg ",
        "name": "Ekaterinburg ",
        "region": "Asia/Yekaterinburg",
        "offset 1": "UTC+05:00",
        "offset 2": "UTC+05:00"
    },
    {
        "utc": "UTC+05:00 West Asia ",
        "name": "West Asia ",
        "region": "Asia/Tashkent",
        "offset 1": "UTC+05:00",
        "offset 2": "UTC+05:00"
    },
    {
        "utc": "UTC+05:30 India",
        "name": "India",
        "region": "Asia/Calcutta",
        "offset 1": "UTC+05:30",
        "offset 2": "UTC+05:30"
    },
    {
        "utc": "UTC+05:30 Sri Lanka ",
        "name": "Sri Lanka ",
        "region": "Asia/Colombo",
        "offset 1": "UTC+05:30",
        "offset 2": "UTC+05:30"
    },
    {
        "utc": "UTC+05:45 Nepal ",
        "name": "Nepal ",
        "region": "Asia/Kathmandu",
        "offset 1": "UTC+05:45",
        "offset 2": "UTC+05:45"
    },
    {
        "utc": "UTC+06:00 Central Asia ",
        "name": "Central Asia ",
        "region": "Asia/Omsk",
        "offset 1": "UTC+06:00",
        "offset 2": "UTC+06:00"
    },
    {
        "utc": "UTC+06:30 Myanmar ",
        "name": "Myanmar ",
        "region": "Asia/Yangon",
        "offset 1": "UTC+06:30",
        "offset 2": "UTC+06:30"
    },
    {
        "utc": "UTC+07:00 Vietnam",
        "name": "Vietnam",
        "region": "Asia/Vientiane",
        "offset 1": "UTC+07:00",
        "offset 2": "UTC+07:00"
    },
    {
        "utc": "UTC+08:00 China ",
        "name": "China ",
        "region": "Asia/Chongqing",
        "offset 1": "UTC+08:00",
        "offset 2": "UTC+08:00"
    },
    {
        "utc": "UTC+08:00 North Asia East ",
        "name": "North Asia East ",
        "region": "Asia/Irkutsk",
        "offset 1": "UTC+08:00",
        "offset 2": "UTC+08:00"
    },
    {
        "utc": "UTC+08:00 Singapore ",
        "name": "Singapore ",
        "region": "Asia/Singapore",
        "offset 1": "UTC+08:00",
        "offset 2": "UTC+08:00"
    },
    {
        "utc": "UTC+08:00 Taipei ",
        "name": "Taipei ",
        "region": "Asia/Taipei",
        "offset 1": "UTC+08:00",
        "offset 2": "UTC+08:00"
    },
    {
        "utc": "UTC+08:00 W. Australia ",
        "name": "W. Australia ",
        "region": "Australia/West",
        "offset 1": "UTC+08:00",
        "offset 2": "UTC+08:00"
    },
    {
        "utc": "UTC+09:00 Korea ",
        "name": "Korea ",
        "region": "Asia/Seoul",
        "offset 1": "UTC+09:00",
        "offset 2": "UTC+09:00"
    },
    {
        "utc": "UTC+09:00 Tokyo ",
        "name": "Tokyo ",
        "region": "Asia/Tokyo",
        "offset 1": "UTC+09:00",
        "offset 2": "UTC+09:00"
    },
    {
        "utc": "UTC+09:00 Yakutsk ",
        "name": "Yakutsk ",
        "region": "Asia/Yakutsk",
        "offset 1": "UTC+09:00",
        "offset 2": "UTC+09:00"
    },
    {
        "utc": "UTC+09:30 AUS Central ",
        "name": "AUS Central ",
        "region": "Australia/Broken_Hill",
        "offset 1": "UTC+09:30",
        "offset 2": "UTC+10:30"
    },
    {
        "utc": "UTC+10:00 AUS Eastern ",
        "name": "AUS Eastern ",
        "region": "Australia/Sydney",
        "offset 1": "UTC+10:00",
        "offset 2": "UTC+11:00"
    },
    {
        "utc": "UTC+10:00 Guam",
        "name": "Guam",
        "region": "Pacific/Guam",
        "offset 1": "UTC+10:00",
        "offset 2": "UTC+10:00"
    },
    {
        "utc": "UTC+10:00 Tasmania ",
        "name": "Tasmania ",
        "region": "Australia/Tasmania",
        "offset 1": "UTC+10:00",
        "offset 2": "UTC+11:00"
    },
    {
        "utc": "UTC+10:00 Vladivostok ",
        "name": "Vladivostok ",
        "region": "Asia/Vladivostok",
        "offset 1": "UTC+10:00",
        "offset 2": "UTC+10:00"
    },
    {
        "utc": "UTC+11:00 Central Pacific ",
        "name": "Central Pacific ",
        "region": "Asia/Srednekolymsk",
        "offset 1": "UTC+11:00",
        "offset 2": "UTC+11:00"
    },
    {
        "utc": "UTC+12:00 Fiji ",
        "name": "Fiji ",
        "region": "Pacific/Fiji",
        "offset 1": "UTC+12:00",
        "offset 2": "UTC+13:00"
    },
    {
        "utc": "UTC+12:00 New Zealand ",
        "name": "New Zealand ",
        "region": "Pacific/Auckland",
        "offset 1": "UTC+12:00",
        "offset 2": "UTC+13:00"
    },
    {
        "utc": "UTC+13:00 Tonga ",
        "name": "Tonga ",
        "region": "Pacific/Tongatapu",
        "offset 1": "UTC+13:00",
        "offset 2": "UTC+14:00"
    },
    {
        "utc": "UTC−01:00 Azores ",
        "name": "Azores ",
        "region": "Atlantic/Azores",
        "offset 1": "UTC−01:00",
        "offset 2": "UTC+00:00"
    },
    {
        "utc": "UTC−01:00 Cabo Verde ",
        "name": "Cabo Verde ",
        "region": "Atlantic/Cape_Verde",
        "offset 1": "UTC−01:00",
        "offset 2": "UTC−01:00"
    },
    {
        "utc": "UTC−02:00 Mid-Atlantic ",
        "name": "Mid-Atlantic ",
        "region": "America/Noronha",
        "offset 1": "UTC−02:00",
        "offset 2": "UTC−02:00"
    },
    {
        "utc": "UTC−03:00 E. South America ",
        "name": "E. South America ",
        "region": "America/Buenos_Aires",
        "offset 1": "UTC−03:00",
        "offset 2": "UTC−03:00"
    },
    {
        "utc": "UTC−03:00 Greenland ",
        "name": "Greenland ",
        "region": "America/Godthab",
        "offset 1": "UTC−03:00",
        "offset 2": "UTC−02:00"
    },
    {
        "utc": "UTC−03:00 SA Eastern ",
        "name": "SA Eastern ",
        "region": "America/Argentina/Buenos_Aires",
        "offset 1": "UTC−03:00",
        "offset 2": "UTC−03:00"
    },
    {
        "utc": "UTC−03:30 Newfoundland and Labrador ",
        "name": "Newfoundland and Labrador ",
        "region": "Canada/Newfoundland",
        "offset 1": "UTC−03:30",
        "offset 2": "UTC−02:30"
    },
    {
        "utc": "UTC−04:00 Atlantic ",
        "name": "Atlantic ",
        "region": "Canada/Atlantic",
        "offset 1": "UTC−04:00",
        "offset 2": "UTC−03:00"
    },
    {
        "utc": "UTC−04:00 Pacific SA ",
        "name": "Pacific SA ",
        "region": "America/Santiago",
        "offset 1": "UTC−04:00",
        "offset 2": "UTC−03:00"
    },
    {
        "utc": "UTC−04:00 SA Western ",
        "name": "SA Western ",
        "region": "Brazil/West",
        "offset 1": "UTC−04:00",
        "offset 2": "UTC−04:00"
    },
    {
        "utc": "UTC−05:00 Eastern ",
        "name": "Eastern ",
        "region": "EST",
        "offset 1": "UTC−05:00",
        "offset 2": "UTC−05:00"
    },
    {
        "utc": "UTC−05:00 SA Pacific ",
        "name": "SA Pacific ",
        "region": "America/Rio_Branco",
        "offset 1": "UTC−05:00",
        "offset 2": "UTC−05:00"
    },
    {
        "utc": "UTC−05:00 U.S. Eastern ",
        "name": "U.S. Eastern ",
        "region": "America/New_York",
        "offset 1": "UTC−05:00",
        "offset 2": "UTC−04:00"
    },
    {
        "utc": "UTC−06:00 Canada Central ",
        "name": "Canada Central ",
        "region": "Canada/Central",
        "offset 1": "UTC−06:00",
        "offset 2": "UTC−05:00"
    },
    {
        "utc": "UTC−06:00 Central America ",
        "name": "Central America ",
        "region": "US/Central",
        "offset 1": "UTC−06:00",
        "offset 2": "UTC−05:00"
    },
    {
        "utc": "UTC−07:00 Mexico ",
        "name": "Mexico ",
        "region": "Mexico/BajaSur",
        "offset 1": "UTC−07:00",
        "offset 2": "UTC−06:00"
    },
    {
        "utc": "UTC−07:00 Mountain ",
        "name": "Mountain ",
        "region": "America/Denver",
        "offset 1": "UTC−07:00",
        "offset 2": "UTC−06:00"
    },
    {
        "utc": "UTC−07:00 US Mountain ",
        "name": "US Mountain ",
        "region": "America/Denver",
        "offset 1": "UTC−07:00",
        "offset 2": "UTC−06:00"
    },
    {
        "utc": "UTC−08:00 Mexico  2",
        "name": "Mexico  2",
        "region": "Mexico/BajaNorte",
        "offset 1": "UTC−08:00",
        "offset 2": "UTC−07:00"
    },
    {
        "utc": "UTC−08:00 Pacific ",
        "name": "Pacific ",
        "region": "America/Los_Angeles",
        "offset 1": "UTC−08:00",
        "offset 2": "UTC−07:00"
    },
    {
        "utc": "UTC−09:00 Alaskan ",
        "name": "Alaskan ",
        "region": "US/Alaska",
        "offset 1": "UTC−09:00",
        "offset 2": "UTC−08:00"
    },
    {
        "utc": "UTC−10:00 Hawaiian ",
        "name": "Hawaiian ",
        "region": "HST",
        "offset 1": "UTC−10:00",
        "offset 2": "UTC−10:00"
    },
    {
        "utc": "UTC−10:00 Hawaiian ",
        "name": "Hawaiian ",
        "region": "US/Hawaii",
        "offset 1": "UTC−10:00",
        "offset 2": "UTC−10:00"
    },
    {
        "utc": "UTC−11:00 Samoa ",
        "name": "Samoa ",
        "region": "Pacific/Samoa",
        "offset 1": "UTC−11:00",
        "offset 2": "UTC−11:00"
    },
]