import { Card, Typography } from "@mui/material";
import { getPhoneHistoryById } from "api/phoneHistory";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDTypography from "lib/components/MDTypography";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getProperDate } from "util/UtilityFunctions";
import History from "./History";

const PhoneHistory = ({phone}) => {
    const token = useSelector(state => state.user.token);
    const [histories, setHistories] = useState([]);
    const {sendRequest : phoneHistoryFn, status, data , error} = useHttp(getPhoneHistoryById,false); 
    
    useEffect(() => {
      phoneHistoryFn({token,phoneId : phone.phoneId});
    },[phone]);

    useEffect(() => {
      if(status === 'completed'){
        if(data){
          setHistories(data);
        }
      }
    },[status,data]);

    return (
        <Card sx={{ height: "100%", marginTop:'8px'}}>
          <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
            <MDTypography variant="h6" fontWeight="medium">
            Number History
            </MDTypography>
            {histories?.length > 0 && <MDButton variant="outlined" color="info" size="small">
              view all
            </MDButton>}
          </MDBox>
          <MDBox p={2}>
            <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                {histories?.length == 0 ? <MDBox justifyContent="center" display="flex" alignItems="center">
                  <Typography>No records found.</Typography>
                </MDBox> :  
                  histories.map(h => <History dateFrom={getProperDate(h?.assignedOn)} dateTo={getProperDate(h?.unassignedOn)} id={`#${h?.company?.companyName}`}/>)
                }
            </MDBox>
          </MDBox>
        </Card>
      );
}
export default PhoneHistory;