import { BASE_URL } from "constants/Endpoints";
import fetch from "core/fetch";

export const getOnlineStatus = async(key) => {
    const response = await fetch(`${BASE_URL}/sms/status/${key}`,{
        method : 'GET',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization' : 'Bearer ' + removeData.token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const saveOnlineStatus = async(recipient) => {
    const payload = {
        ...recipient,
        token :  undefined
    }
    const response = await fetch(`${BASE_URL}/sms/status`,{
        method : 'POST',
        body : JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + recipient.token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const removeOnlineStatus = async(payload) => {
    const newPayload = {
        ...payload,
        token :  undefined
    } 
    const response = await fetch(`${BASE_URL}/sms/status/remove`,{
        method : 'POST',
        body : JSON.stringify(newPayload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + payload.token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
export const removeActivePhone = async(removeData) => {
    const response = await fetch(`${BASE_URL}/current/phone/status/remove`,{
        method : 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + removeData.token,
          },
          body : JSON.stringify(removeData.phoneId),
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
export const setActivePhone = async(setData) => {
    const response = await fetch(`${BASE_URL}/current/phone/status`,{
        method : 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + setData.token,
          },
        body : JSON.stringify(setData.phoneId),
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}