import { FormControl, FormControlLabel, FormGroup, Radio, RadioGroup } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import AppModal from "ui/AppModal";
import KeywordGroupMap from "./Keywords/KeywordGroupMap";

const AddKeyword = ({onClose,onAdded}) => {

    const [isGroupAssociated, setGroupAssociated] = useState(false);

    const [error, setError] = useState('');
    const keywordRef = useRef();
    const responseRef = useRef();
    const user = useSelector(state => state.user); 
    const [selectedGroup,setSelectedGroup] = useState();

    const onGroupSelected = (gp) => {
        setSelectedGroup(gp);
    }
    const onKeywordAddClick = () => {
        let reply = '';
        if(!isGroupAssociated){
            reply = responseRef.current.value;
            if(reply.trim().length === 0){
                setError("please enter corresponding response");    
                return;
            }
        }
        const keyword = keywordRef.current.value;
        if(keyword.trim().length === 0){
            setError("Keyword filled can not be empty");
            return;
        }
        const payload = {
            reply,keyword : keyword.toLowerCase(),token : user.token,
        }
        if(selectedGroup){
            payload.group = {
                ...selectedGroup
            }
        }
        onAdded(payload);
    }
    return (
        <>
            <AppModal
                rootStyle = {
                    {
                      "&.MuiModal-root":{
                        width:'40%',
                        left: '60%'
                      }
                    }
                }
                customStyle={{ 
                    width: "100%",
                    height: "100%",
                }}
                onModalClose={() => onClose()}
                heading={"Create keyword and corresponding response"}>
                <MDBox
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                >
                <MDBox
                    width="100%"
                    display="flex"
                    mt={2}
                    alignItems="center"
                    justifyContent="center"
                >
                    <MDInput
                        fullWidth
                        type="text"
                        onChange={(e) => {
                            const tmpInput = e.target.value;
                            if (
                            tmpInput === "info" ||
                            tmpInput === "INFO" ||
                            tmpInput === "HELP" ||
                            tmpInput === "help"
                            ) {
                            responseRef.current.value = `Hi! This is the texting line for ${
                                user?.company?.company_name
                            }. For any further questions, please call ${
                                user?.company?.company_contact
                                ? user?.company?.company_contact
                                : "{number}"
                            }.`;
                            }
                        }}
                        label="Keyword"
                        inputRef={keywordRef}
                    />
                </MDBox>
                <MDBox ml={1} width="100%" display="flex" justifyContent="start">
                    <FormGroup>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={(e) => {
                                    const newVal = e.target.value === 'true';
                                    setGroupAssociated(newVal)
                                }}
                                value={isGroupAssociated}
                                >
                                <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label="Auto reply"
                                />
                                <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label="Associate with broadcast group"
                                />
                            </RadioGroup>
                        </FormControl>
                    </FormGroup>
                </MDBox>
                { !isGroupAssociated && <MDBox
                    width="100%"
                    display="flex"
                    style={{ marginTop: "12px" }}
                    alignItems="center"
                    justifyContent="center"
                >
                    <MDInput
                    fullWidth
                    inputRef={responseRef}
                    type="text"
                    label="Response"
                    multiline
                    />
                </MDBox>}
                <MDTypography
                    variant="overline"
                    fontWeight="medium"
                    color="error"
                    mt={1}
                >
                    {error}
                </MDTypography>
                {isGroupAssociated && <KeywordGroupMap selectedGroup={selectedGroup} onGroupSelected={onGroupSelected}/>}
                <MDBox
                    style={{ marginTop: "12px" }}
                    display="flex"
                    justifyContent="start"
                    width="100%"
                >
                    <MDButton
                    onClick={onKeywordAddClick}
                    size={"medium"}
                    color="info"
                    width="300px"
                    variant="contained"
                    >
                    Create
                    </MDButton>
                </MDBox>
                </MDBox>
            </AppModal>
        </>
    );
}
export default AddKeyword;