export const Audio = {
    askMicroPhonePermission : async() => {
        return await navigator.permissions.query({
            name: "microphone",
        });
    },
    checkMicrophoneAvailability : async() => {
        return await navigator.mediaDevices.getUserMedia({ audio: true });
    },
    getLocalStream: () => {
        navigator.mediaDevices
          .getUserMedia({ video: false, audio: true })
          .then((stream) => {
            window.localStream = stream;
            window.localAudio.srcObject = stream;
            window.localAudio.autoplay = true;
          })
          .catch((err) => {
            console.error(`you got an error: ${err}`);
        });
    }
      
}