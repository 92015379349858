import { CREATE_FUNDRAISER } from "constants/Endpoints";
import { GET_FUNDRAISER } from "constants/Endpoints";
import { CAMPAIGN_BULK } from "constants/Endpoints";
import fetch from "core/fetch";
export const uploadFundraisers = async(uploadData) => {

    const response = await fetch(`${CREATE_FUNDRAISER}/bulk`,{
        method : 'POST',
        body : uploadData.formData,
        headers: {
            // 'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + uploadData.token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const updateFundraisers = async(updateData) => {

    const response = await fetch(`${CREATE_FUNDRAISER}`,{
        method : 'PUT',
        body : JSON.stringify(updateData.payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + updateData.token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const getFundraiserById = async(id) => {

    const response = await fetch(`${GET_FUNDRAISER(id)}`,{
        method : 'GET',
        headers: {
            'Content-Type': 'application/json',
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
export const _getFundraiserById = async({id,token}) => {

    const response = await fetch(`${CREATE_FUNDRAISER}/${id}`,{
        method : 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token ,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const savefundraiserDonationLink = async ({payload,token}) => {
    const response = await fetch(`${CREATE_FUNDRAISER}/donation/link`,{
        method: 'POST',
        body : JSON.stringify(payload),
        headers :{
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + token ,
        }
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;  
}
export const updateFundraiserMessage = async({message,token,fundraiser_id}) => {

    const payload = {
        message : message
    }
    const response = await fetch(`${CREATE_FUNDRAISER}/${fundraiser_id}/message`,{
        method : 'PUT',
        body : JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const checkIfEmailAlreayInuse = async({token,email}) => {

    const response = await fetch(`${CREATE_FUNDRAISER}/uiaccess/feasible`,{
        method : 'POST',
        body : JSON.stringify(email),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
export const giveUIAccess = async({token,uiAccess,fundraiser_id}) => {

    const response = await fetch(`${CREATE_FUNDRAISER}/uiaccess/${fundraiser_id}`,{
        method : 'POST',
        body : JSON.stringify(uiAccess),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
export const bulkFundraisersSettings = async(payload) => {
    const response = await fetch(`${CAMPAIGN_BULK}/fundraisers/settings`,{
        method : 'POST',
        body : JSON.stringify(payload.payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + payload.token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data;
}
export const updateNotificaiton = async(payload) => {
    const response = await fetch(`${CREATE_FUNDRAISER}/settings/notification`,{
        method : 'POST',
        body : JSON.stringify(payload.payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + payload.token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data;
}

export const unassignPhone = async ({payload,token}) => {
    const response = await fetch(`${CREATE_FUNDRAISER}/unassign`,{
        method: 'POST',
        body : JSON.stringify(payload),
        headers :{
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + token ,
        }
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;  
}
export const unassignPhoneBulk = async ({payload,token}) => {
    const response = await fetch(`${CREATE_FUNDRAISER}/unassign/bulk`,{
        method: 'POST',
        body : JSON.stringify(payload),
        headers :{
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + token ,
        }
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;  
}