import { Settings, WhatsApp } from "@mui/icons-material";
import { Autocomplete, Box, Divider, List, Popover, Stack, TextField, Tooltip, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { useEffect, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import AppModal from "ui/AppModal";
import SendTemplate from "./SendTemplate";
import { WhatsappAPI } from "services/WhatsappAPI";
import { memo } from "react";
import Whatsapp from ".";
import { chatAction } from "store/chat-slice";

const WApprovedTemplate = ({onTemplateSend,mobile = false,sx={}}) => {
    const appDispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [templates, setTemplates] = useState([]);
    const[templateSetting,setTemplateSettings] = useState(false);
    const [showExampleModal, setShowExampleModal] = useState(undefined);
    const token = useSelector(state => state.user.token);
    const company = useSelector(state => state.user.company);
    const currentActivePhone = useSelector(state => state.chat.current_active_phone);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if(currentActivePhone){
            WhatsappAPI.getWATemplatesByPhone({token,phoneId : currentActivePhone.phoneId}).then(res => {
                setTemplates(res.data);
                appDispatch(chatAction.setWATemplates(res.data))
            }).catch(e => {
                console.log(e)
            })
        }
    },[currentActivePhone]);

    return(
        <>
            {
                mobile ? <WhatsApp style={{cursor:'pointer',marginLeft:'6px',...sx}} onClick={handleClick}/>                 
                    :
                <Tooltip title={"WA templates"}>
                    <WhatsApp style={{cursor:'pointer',marginLeft:'6px'}} onClick={handleClick}/>
                </Tooltip>
            }
            <Popover
                onClose={handleClose}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                sx={{
                    "& .MuiPopover-paper": {
                        backgroundColor: "white!important",
                    },
                }}
            >
                <MDBox width="300px" display="flex" flexDirection="column">
                    <MDBox display="flex" flexDirection="column">
                        <MDBox style={{padding:'4px'}} display="flex" justifyContent="space-between" flexDirection="row" alignItems="center">
                            <Typography varient="h6">WA Approved Templates</Typography>
                            <MDBox>   
                                <Tooltip title="Manage WA Templates">
                                    <Settings style={{
                                        cursor:'pointer',
                                        width:'20px',
                                        height:'20px'
                                    }}
                                        onClick={() => setTemplateSettings(true)}
                                    />
                                </Tooltip> 
                            </MDBox>
                        </MDBox>
                        { templates && <Stack>
                            <Autocomplete
                                id="wa-templates"
                                disableClearable
                                style={{
                                    padding:'4px'
                                }}
                                onChange={(event,val) => {
                                    // onShortcutSelected(val.value);
                                    handleClose();
                                    console.log(val);
                                    setShowExampleModal(val);
                                }}
                                renderOption={(props, option) => {
                                    return(
                                        <List {...props}>
                                            <Box width={"100%"}>
                                                {option.templateName}
                                                <Divider 
                                                    component={"div"}
                                                    sx={{
                                                        "&.MuiDivider-root": {
                                                            backgroundColor: "#e9d5e9",
                                                        },
                                                        width: "100%"
                                                    }}
                                                />
                                            </Box>
                                        </List>
                                    )
                                }}
                                options={templates}
                                getOptionLabel={option => option.templateName}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Search input"
                                        InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                        }}
                                    />
                                )}
                            />
                        </Stack>}                        
                    </MDBox>
                </MDBox>            
            </Popover>
            {showExampleModal && 
                <AppModal 
                onModalClose={() => setShowExampleModal(undefined)}
                customStyle={{minWidth:'700px'}}
                closeOnClick={false}
                heading={" "}
                >
                <SendTemplate onTemplateSend={ (res) =>  {onTemplateSend(res);setShowExampleModal(undefined)}} templateData={showExampleModal}/>
            </AppModal>}   

            {templateSetting &&  <AppModal
                rootStyle = {
                {
                    "&.MuiModal-root":{
                    width:'90%',
                    left: '10%'
                    }
                }
                }
                customStyle={{ 
                width: "100%",
                height: "100%",
                overflowY: "auto",
                border: "unset",
                }}
                heading={""}
                onModalClose={() => setTemplateSettings(false)}        
            >
                <Whatsapp company={company}/>
            </AppModal>}                     
        </>
    )
}
export default memo(WApprovedTemplate);