import { Button, Divider, FormControl, Grid, MenuItem, Switch, Tooltip, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import { memo, useCallback, useState } from "react";
import PickTime from "./PickTime";
import { Add } from "@mui/icons-material";
import { add15MoreMins } from "util/UtilityFunctions";
import CustomSelect from "ui/Select";


/**
 [
  {name : "sunday",enabled : false, schedule : [{from:'',to:''}]},
  {name : "monday",enabled : false, schedule : [{from:'',to:''}]},
  {name : "tuesday",enabled : false, schedule : [{from:'',to:''}]},
  {name : "wednesday",enabled : false, schedule : [{from:'',to:''}]},
  {name : "thursday",enabled : false, schedule : [{from:'',to:''}]},
  {name : "friday",enabled : false, schedule : [{from:'',to:''}]},
  {name : "saturday",enabled : false, schedule : [{from:'',to:''}]},
]
 */

const AutoReplyRuleSettings = ({companyWide,setScheduledOptions,scheduleData,scheduledOptions,phoneId}) => {

  const [timeRanges,setTimeRanges] = useState(scheduleData);

  const handleOnSwitch = useCallback((e,index) => {

    const newRanges = timeRanges?.map((item,idx) => {
      if(index === idx){
        return {
          ...item,
          enabled : e.target.checked,
          schedule: [{from:null,to:null}]
        }
      }
      else return item
    }); 
    if(companyWide) {
      setScheduledOptions(prev => {
        return {
          ...prev,
          companyWideSettings : {
            ...prev.companyWideSettings,
            days : newRanges
          }
        }
      })
    }
    else{
      const phoneSettings = scheduledOptions.phoneSettings.map(item => {
        if(item?.phoneId === phoneId){
          return {
            ...item,
            options : {
              ...item.options,
              days : timeRanges
            }
          }
        }
        else return item;
      })
      if(phoneSettings) {
        setScheduledOptions(prev => {
          return{
            ...prev,
            phoneSettings : phoneSettings
          }
        });
      }
    }
    setTimeRanges(newRanges);
  },[timeRanges,companyWide,setScheduledOptions,scheduledOptions,phoneId]);

  const addMoreTimePicker = useCallback((index) => {
    const newRanges = timeRanges?.map((item,idx) => {
      if(idx === index){
        const lastToLable = item.schedule[item.schedule.length - 1].to;
        const more15Mins = add15MoreMins(lastToLable);
        return {
          ...item,
          schedule : [...item.schedule,{from:more15Mins,to:''}]
        }
      }
      else return item
    });
    setTimeRanges(newRanges);
    if(companyWide) {
      setScheduledOptions(prev => {
        return{
          ...prev,
          companyWideSettings : {
            ...prev.companyWideSettings,
            days : newRanges
          }
        }
      })
    }
    else
    {
      const phoneSettings = scheduledOptions.phoneSettings.map(item => {
        if(item?.phoneId === phoneId){
          return {
            ...item,
            options : {
              ...item.options,
              days : newRanges
            }
          }
        }
        else return item;
      })
      if(phoneSettings) {
        setScheduledOptions(prev => {
          return{
            ...prev,
            phoneSettings : phoneSettings
          }
        });
      }
    }           
  },[timeRanges,companyWide,setScheduledOptions,phoneId,scheduledOptions])

  const replicateSchedules = useCallback((value,parentIdx) => {
    const scheduledArr = timeRanges[parentIdx].schedule;
    let newTimeRanges = [];
    if(value === 'weekdays') {
      newTimeRanges = timeRanges?.map((item,indx) => {
          if(indx !== 0 && indx !== 6) {
            return {
              ...item,
              enabled : true,
              schedule : [...scheduledArr]
            }   
          }
          else return item;
      });
    }
    else if(value === 'everyday') {
      newTimeRanges = timeRanges?.map((item,indx) => {
        return {
          ...item,
            enabled : true,
            schedule : [...scheduledArr]
          }   
        });
    }
    if(companyWide) {
      setScheduledOptions(prev => {
        return{
          ...prev,
          companyWideSettings : {
            ...prev.companyWideSettings,
            days : newTimeRanges
          }
        }
      })
    }
    else
    {
      const phoneSettings = scheduledOptions.phoneSettings.map(item => {
        if(item?.phoneId === phoneId){
          return {
            ...item,
            options : {
              ...item.options,
              days : newTimeRanges
            }
          }
        }
        else return item;
      })
      if(phoneSettings) {
        setScheduledOptions(prev => {
          return{
            ...prev,
            phoneSettings : phoneSettings
          }
        });
      }
    }            
    setTimeRanges(newTimeRanges);
  },[timeRanges,scheduledOptions,setScheduledOptions,phoneId,companyWide])

  const onPicked = useCallback((parentIdx,index,payload) => {
    // parentIdx refers the day sunday -> 0 , monday -> 1 so on
    // index refers the obj in schedule
    let newTimeRanges = timeRanges[parentIdx].schedule[index];
    if(payload.type === 'from') {
      newTimeRanges = {
        ...newTimeRanges,
        from : payload.value
      }
    }
    else{
      newTimeRanges = {
        ...newTimeRanges,
        to : payload.value
      }
    }
    const newRange = timeRanges?.map((itm,ind) => {
      if(ind === parentIdx) {
        const newSchedule = itm.schedule.map((i,cidx)=> {
          if(cidx === index){
            return newTimeRanges;
          }
          else{
            return i;
          }
        })
        return {...itm,schedule : newSchedule}
      }
      else return itm;
    });
    if(companyWide) {
      setScheduledOptions(prev => {
        return{
          ...prev,
          companyWideSettings : {
            ...prev.companyWideSettings,
            days : newRange
          }
        }
      })
    }
    else
    {
      const phoneSettings = scheduledOptions.phoneSettings.map(item => {
        if(item?.phoneId === phoneId){
          return {
            ...item,
            options : {
              ...item.options,
              days : newRange
            }
          }
        }
        else return item;
      })
      if(phoneSettings) {
        setScheduledOptions(prev => {
          return{
            ...prev,
            phoneSettings : phoneSettings
          }
        });
      }
    } 
    setTimeRanges(newRange)           
  },[companyWide,timeRanges,setScheduledOptions,scheduledOptions,phoneId])
  
  const renderTimeSlots = (item,parentIdx) => {
    if(item.enabled) {
      return item.schedule?.map((obj,index) =>
        <MDBox key={index} display="flex" alignItems="center">
          <PickTime key={index} initialValue={obj} onTimePick={(payload) => onPicked(parentIdx,index,payload)}/>
        </MDBox>
      )
    }
    else return <MDBox key={parentIdx} height="40px"></MDBox>;
  }
  return(
      <>
        <MDBox display="flex" justifyContent="flex-start" width="100%" padding="8px"> 
          <MDBox display="flex" flexDirection="row" width="100%">
            <MDBox flex="flex" flexDirection="column" minWidth="204px">
              <MDBox flex="flex" flexDirection="column">
                <Typography fontSize={"14px"}>{"Send auto replies on these days"}</Typography>
              </MDBox>
                { timeRanges && timeRanges?.map((option,index) => {
                  return <Grid key={option.name} item xs={12} sx={{ ml: "auto" }}>
                    <MDBox display="flex" alignItems="center">      
                      <Switch key={option.name} checked={option.enabled} color="info" onChange={(e) => handleOnSwitch(e,index)}/>
                      <MDBox ml={1}>
                        <MDTypography variant="button" fontWeight="regular" color="text" sx={{textTransform:'uppercase'}}>
                          {option.name}      
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </Grid>
                })
              }
            </MDBox>
              <Divider variant="vertical"  component="div"
                sx={{
                  "&.MuiDivider-root": {
                  backgroundColor: "#e9d5e9",
                  margin: 'unset'
                },
              }}/>
              <MDBox flex="flex" flexDirection="column" width="100%">
                <MDBox flex="flex" flexDirection="column">
                  <MDBox display="flex" justifyContent="space-between">
                    <Typography fontSize={"14px"}>{"Sending times"}</Typography> 
                  </MDBox>
                </MDBox>
                {timeRanges?.map((itm,indx) => {
                  return <MDBox key={"timeslot_container_"+indx} display="flex" flexDirection="row" alignItems="center">
                      {renderTimeSlots(itm,indx)}
                      {itm.schedule.length >  0 &&  itm.enabled && <FormControl>
                        <CustomSelect
                          key={indx}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          sx={{height :'32px!important'}}
                          value={""}
                          displayEmpty={true}
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return <em style={{color:'#1A73E8'}}>Apply to</em>;
                            }
                            return selected;
                          }}                                      
                          onChange={(e) => replicateSchedules(e.target.value,indx)}
                        >
                          <MenuItem key={""} disabled value="">
                            <em>Apply to</em>
                          </MenuItem>                                      
                          <MenuItem key={"weekdays"} value={"weekdays"}>{"Apply to all weekday"}</MenuItem>
                          <MenuItem key={"everyday"} value={"everyday"}>{"Apply to everyday"}</MenuItem>
                        </CustomSelect>
                      </FormControl>}               
                      <MDBox minHeight="38px">
                        {(itm.enabled > 0 && itm.schedule.length < 3 ) && 
                          <Tooltip title="You can set a maximum of 3 time ranges per day">
                            <Button onClick={() => addMoreTimePicker(indx)} sx={{fontSize:'10px',padding: 'unset'}}><Add/>Add another time range</Button>                              
                          </Tooltip>
                        } 
                      </MDBox>
                  </MDBox>
                })}
              </MDBox>
          </MDBox>
        </MDBox>
    </>
  )
}
export default memo(AutoReplyRuleSettings);