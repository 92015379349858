import { BASE_URL } from "constants/Endpoints";
import axios from "../core/axios";

export const WhatsappAPI = {
    getWATemplates : async({token,payload}) => {
        return axios.post(`${BASE_URL}/get/wa/template`,JSON.stringify(payload),{
            headers : {
                'Authorization' : 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        })
    },

    getWATemplatesByPhone : async({token,phoneId}) => {
        return axios.get(`${BASE_URL}/${phoneId}/wa/template`,{
            headers : {
                'Authorization' : 'Bearer ' + token,
            }
        })
    }, 
}