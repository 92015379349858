import { Button, Divider, FormControlLabel, FormGroup, Switch, Typography } from "@mui/material";
import { userSettings } from "constants/ChatConstants";
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import { useCallback } from "react";
import { memo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { IUserSettingsAPI } from "services/IChat/IUserSettingsAPI";
import { userDetailAction } from "store/user-details-slice";

const ModifyIcSettings = ({user}) => {

    const dispatch = useDispatch();

    const [icSettings,setIcSettings] = useState();
    const token = useSelector(state => state.user.token);
    const currLoggedInUser = useSelector(state => state.user);
    const [showSaveBtn,setShowSaveBtn] = useState(false);

    const save = useCallback(() => {
        // Save the settings....
        const payload = {
            icSettings,
            userId : currLoggedInUser.id
        }
        IUserSettingsAPI.saveIcSettings({payload,token:currLoggedInUser.token,userId:currLoggedInUser.id}).then(res => {
            if(res.data){
                setIcSettings(res.data);
                toast.success("Settings saved successfully.");
                if(currLoggedInUser.id === user.userId){
                    dispatch(userDetailAction.updateICsettings(res.data));
                }
                setShowSaveBtn(false);
            }
        }).catch(e => toast.error("Something went wrong, please try again"));
    },[icSettings]);
    
    const handleOnSwitch = (e) => {
        const checked = e.target.checked;
        if(checked){
            setIcSettings(prev => {
                return {
                    ...prev,
                    read_receipt : {
                        ...prev.read_receipt,
                        enabledByAdmin : checked
                    }
                }
            })
        }
        else{
            setIcSettings(prev => {
                return {
                    ...prev,
                    read_receipt : {
                        ...prev.read_receipt,
                        enabledByAdmin : checked,
                        disabledBy : {
                            fullName : currLoggedInUser.name,
                            userId : currLoggedInUser.id
                        }
                    }
                }
            })
        }   
        setShowSaveBtn(true);
    }

    useEffect(() => {
        if(user){
        // Get IC Settings....
          IUserSettingsAPI.getIcSettings({token,userId : user.userId}).
          then(res => {
            if(res.data) {
              setIcSettings(res.data);
            }
            else{
              const ic = {...userSettings, userId : user.userId};
              setIcSettings(ic);
            }
          }).catch(e => {
            toast.error(e);
          })
        }
    },[user]);

    return(
        <>
            {icSettings &&
            <>
                <Divider sx={{borderWidth:'2px'}} component={"div"} variant="horizontal"/>
                <Typography variant="h6" textAlign={"left"}>Internal Chat Settings</Typography>
                <MDBox display="flex" flexDirection="row">
                    <MDBox display="flex" flexDirection="column">
                        <MDBox display="flex" alignItems="center">
                            <FormGroup>
                                <FormControlLabel
                                    label={"Read receipt allowed"}
                                    control={<Switch/>}
                                    checked={icSettings?.read_receipt?.enabledByAdmin}
                                    onChange={handleOnSwitch}
                                >
                                </FormControlLabel>
                            </FormGroup>           
                        </MDBox>
                    </MDBox>
                </MDBox>
                {showSaveBtn && 
                    <MDBox width="100%" display="flex" justifyContent="flex-end">
                        <Button onClick={save} sx={{borderRadius: "0px",color: "white!important",border: "unset",}} variant="contained">update</Button>
                    </MDBox>    
                }   
            </> 
            }
        </>
    )
}
export default memo(ModifyIcSettings);