import AllShortcuts from "components/Shortcuts/AllShortcuts";
import DashboardLayout from "lib/layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "lib/layouts/Navbars/DashboardNavbar";

const Shortcuts = () => {

    return (
      <DashboardLayout>
        <DashboardNavbar />
        <AllShortcuts/>
      </DashboardLayout>
    );
}
export default Shortcuts;