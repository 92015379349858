import GroupJoinedLogs from "components/Logs/GroupJoinedLogs";
import DashboardLayout from "lib/layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "lib/layouts/Navbars/DashboardNavbar";

const Logs = () => {

    return(
        <DashboardLayout>
            <DashboardNavbar />
            <GroupJoinedLogs/>
      </DashboardLayout>
    )
}
export default Logs;