import { createSlice } from "@reduxjs/toolkit";

const initialStates = {
    activeView : 'NORMAL_VIEW',
    rightSideBar : {
        open : false,
        content : undefined
    },
    showTour : false
};

const ichatVariablesSlice = createSlice({
    name: "ichatVariables",
    initialState : initialStates,
    reducers : {
        setActiveView : (state,action) => {
            state.activeView = action.payload;
        },
        updateRightSideBar : (state,action) => {
            const{open,content} = action.payload;
            state.rightSideBar.open = open;
            state.rightSideBar.content = content;
        },
        updateTour : (state,action) => {
            state.showTour = action.payload;
        }
    }
})

export const ichatVariablesAction = ichatVariablesSlice.actions;
export const ichatVariablesState = state => state.ichatVariablesAction;
export default ichatVariablesSlice;