import { BASE_URL } from "constants/Endpoints";
import fetch from "core/fetch";

export const getGroupLogs = async({token,group_id}) => {
    const response = await fetch(`${BASE_URL}/sms/logs`,{
        method : 'POST',
        body : JSON.stringify({"group_id":group_id}),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}