// eventSlice.js
import { createSlice } from '@reduxjs/toolkit';

const eventSlice = createSlice({
  name: 'event',
  initialState: {
    callback: null,
  },
  reducers: {
    storeEventCallback: (state, action) => {
      state.callback = action.payload.callback;
    },
    triggerEvent: (state,action) => {
      if (state.callback) {
        state.callback(...action.payload);
      }
    },
  },
});

export const { storeEventCallback, triggerEvent } = eventSlice.actions;

export default eventSlice.reducer;
