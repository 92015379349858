import { getAllCarrier } from "api/carrier";
import AddCarrier from "components/Carrier/AddCarrier";
import CarrierList from "components/Carrier/CarrierList";
import useHttp from "hooks/use-http";
import DashboardLayout from "lib/layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "lib/layouts/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddButton from "ui/AddButton";

const ACTION = {
    EDIT : 'edit',
    ADD : 'add',
    NONE : 'none'
}
const Carrier = () => {
    const [addCarrier, setAddCarrier] = useState(ACTION.NONE);
    const [carriers, setCarrier] = useState([]);
    const {sendRequest , data , status, error} = useHttp(getAllCarrier,false);
    const token = useSelector(state => state.user.token);

    const onNewCarrierCreated = (res) => {
        setAddCarrier(ACTION.NONE);
        setCarrier(prev => [res,...prev]);
    }

    const onEditClick = (c) => {

    }

    useEffect(() => {
        sendRequest(token);
    },[])

    useEffect(() => {
        if(status === 'completed'){
            if(data){
                setCarrier(data);
            }
        }
    },[data,status,error]);

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            {
                addCarrier === ACTION.ADD &&
                    <AddCarrier  onCreated={onNewCarrierCreated} onBack={() => setAddCarrier(ACTION.NONE)}/> 
            }
            {   addCarrier === ACTION.NONE && <>
                    <AddButton
                    onClick={() => {
                        setAddCarrier(ACTION.ADD);
                    }}
                    text={"Add carrier"}
                    />
                    <CarrierList data={carriers} onEditClick={onEditClick}/>
                </>
            }
        </DashboardLayout>    
    )
}
export default Carrier;