import { Button, Typography } from "@mui/material";
import EnhancedTable from "components/CommonUI/EnhancedTable";
import MDBox from "lib/components/MDBox";
import { useEffect, useState } from "react";

const columns = [
    { id : "1", key: "id", label: "id"},
    { id: "2", key : "name", label: "Name"},
    { id: "3", key : "email", label: "Email" },
    { id: "4", key : "contact", label: "Contact" },
    { id: "5", key : "role", label: "Role" },
    { id : "6", key : 'action', label : "Action" }
  ];
const GeneratePassword = ({users}) => {

    const [rows,setRows] = useState([]);
    const [selected,setSelected] = useState(undefined);

    const sendResetPasswordLink = (email) => {
        console.log(email);
    }

    useEffect(() => {
        if(users){
            const newUsers = users.map((item,index) => {
                return{
                    id : item.email,
                    name : item.name,
                    email : item.email,
                    contact : item.contact,
                    role : item.role,
                    action : <Button onClick={sendResetPasswordLink.bind(null,item.email)} sx={{margin:"0!important",padding:"0!important"}}>Send password link</Button>
                }
            });
            setRows(newUsers);
        }
    },[users]);

    return (
        <MDBox display="flex" flexDirection="column">
            {/* <Typography sx={{marginBottom : "12px"}}>{"Regenerate Password"}</Typography> */}
            {selected && <MDBox mb={2} display="flex" justifyContent="flex-end">
                <Button  variant="contained"
                    sx={{
                      borderRadius: "0px",
                      color: "white!important",
                      border: "unset",
                    }}>{"Send password link to selected"}</Button>
            </MDBox>}
            <EnhancedTable
                columns={columns}
                rows={rows}
                selectEnabled={true}
                heading={"Regenerate passwords"}
                showFilter={false}
                onSelected={(res) => setSelected(res)}
            />
        </MDBox>
    )

}
export default GeneratePassword;