import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "lib/context";
import {Provider} from 'react-redux';
import {store,persistor} from 'store/index';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer, toast } from 'react-toastify';
import { AppErrorHandler } from 'components/AppErrorHandler';
import App from 'App';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript


root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <Provider store={store} >
          <PersistGate loading={null} persistor={persistor}>
            <ToastContainer />
            <AppErrorHandler>
              <App />
            </AppErrorHandler>
          </PersistGate>
        </Provider>  
      </MaterialUIControllerProvider>
    </BrowserRouter>    
  </React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
