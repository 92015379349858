import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { useState } from "react";
import AddNumber from "./AddNumber";
import MDInput from "lib/components/MDInput";

const DlcInfo = ({dlcData,setDlcData}) => {
    const onSelectNumberChange = (e) => {
        setDlcData(prev => {
            return{
                ...prev,
                existingNumber : e.target.value
            }
        })
    }

    return(
        <MDBox>
            <MDBox>
                <Typography margin={'8px'} fontSize={'16px'}>{"Would you like to use your company\'s existing number in BLIQ?"}</Typography>
                <FormControl fullWidth sx={{marginRight:'8px'}}>
                    <InputLabel id="number-method">Select an option</InputLabel>
                    <Select
                        labelId="number-method"
                        id="select-number-method"
                        value={dlcData.existingNumber}
                        sx={{
                            height:'40px',
                            marginRight:'8px'
                        }}
                        label="Select an option"
                        onChange={onSelectNumberChange}
                    >
                        <MenuItem value={"yes"}>{"Yes! Please do."}</MenuItem>
                        <MenuItem value={"no"}>{"No. Please assign us a new number to use in BLIQ."}</MenuItem>
                    </Select>
                </FormControl>
            </MDBox>

            <MDBox>
                {dlcData.existingNumber === 'yes' && <AddNumber dlcData={dlcData} setDlcData={setDlcData}/>}
            </MDBox>

            {/* <MDBox>
                <Typography margin={'8px'} fontSize={'16px'}>{"Would you like us to assigne you a new phone number to use in BLIP?"}</Typography>
                <FormControl fullWidth sx={{marginRight:'8px'}}>
                    <InputLabel id="new-number-method">Select an option</InputLabel>
                    <Select
                        labelId="new-number-method"
                        id="select-number-method"
                        value={dlcData.assignNewNumber}
                        sx={{
                            height:'40px',
                            marginRight:'8px'
                        }}
                        label="Select an option"
                        onChange={(e) => {
                            setDlcData(prev => {
                                return{
                                    ...prev,
                                    assignNewNumber : e.target.value
                                }
                            })
                        }}
                    >
                        <MenuItem value={"yes"}>YES</MenuItem>
                        <MenuItem value={"no"}>NO</MenuItem>
                    </Select>
                </FormControl>
            </MDBox> */}

            <MDBox mb={2} mt={1}>
              <MDInput
                type="text"
                label="10DLC Company Name"
                variant="standard"
                value={dlcData.dlcName}
                onChange={(e) => {
                    setDlcData(prev => {
                        return{
                            ...prev,
                            dlcName : e.target.value
                        }
                    })
                }}
                fullWidth
              />
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                type="text"
                label="10 DLC Company Number"
                variant="standard"
                onChange={(e) => {
                    setDlcData(prev => {
                        return{
                            ...prev,
                            dlcNumber : e.target.value
                        }
                    })
                }}
                fullWidth
              />
            </MDBox>

            <MDBox>
                <Typography margin={'8px'} fontSize={'16px'}>{"Does your company need HIPAA Compliance?"}</Typography>
                <FormControl fullWidth sx={{marginRight:'8px'}}>
                    <InputLabel id="hipaa-method">Select an option</InputLabel>
                    <Select
                        labelId="hipaa-method"
                        id="hipaa-number-method"
                        value={dlcData.hipaaCompliance}
                        sx={{
                            height:'40px',
                            marginRight:'8px'
                        }}
                        label="Select an option"
                        onChange={(e) => {
                            setDlcData(prev => {
                                return{
                                    ...prev,
                                    hipaaCompliance : e.target.value
                                }
                            })
                        }}
                    >
                        <MenuItem value={"yes"}>YES</MenuItem>
                        <MenuItem value={"no"}>NO</MenuItem>
                    </Select>
                </FormControl>
                <Typography sx={{marginTop:'8px'}} fontWeight={"600"} fontSize={"12px"}>{"If yes, before sending a sms to a recipient, we would prompt them to consent to communication via SMS."}</Typography>
            </MDBox>

        </MDBox>
    )
}
export default DlcInfo;