export const groupMessagesByDate = (messages) => {
    return messages?.reduce((groups, message) => {
      const date = new Date(message.createdAt).toDateString();
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});
};

export const updateMessage_ = (messages,message) => {
  let foundMessage = false;
  const newMessages = messages?.map(item => {
    if(item.tempMessageId === message.tempMessageId){
      foundMessage =  true;
      return message;
    }
    else return item;
  });
  if(!foundMessage){
    newMessages?.push(message);
  }
  return newMessages;
}
  