import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { memo } from "react";
import { useCallback } from "react";
import { validateDate } from "util/UtilityFunctions";
import { CrowdfundingAPI } from "services/Crowdfunding/CrowdfundingAPI";
import { CrowdfundingUtil } from "util/Crowdfunding/CrowdfundingUtil";
import { loaderAction } from "store/loader-slice";

const CreateCampaignForm = ({ onCreate }) => {

  const dispatch = useDispatch();

  const currUser = useSelector((state) => state.user);

  const [campaignFormValidation, setCampaignFormValidation] = useState({
    nameValidation : '',
    goalAmtValidation : '',
    dateValidation : ''
  })

  const [formData,setFormData] = useState({
    campaign_name: '',
    goal_amount: '',
    completion_date: '',
    min_donation_amount : ''
  });

  const onFormSubmit = useCallback((event) => {
    event.preventDefault();
    if(formData.campaign_name === "") {
      setCampaignFormValidation(prev => {
        return{
          ...prev,
          nameValidation : 'Title can not be empty.'
        }
      });
      return;
    }
    if (!formData.goal_amount || formData.goal_amount === 0 || !(/^[0-9]*$/).test(formData.goal_amount.replace(/[^0-9]/g, '')) || formData.goal_amount.replace(/[^0-9]/g, '') > 10000000 || formData.goal_amount.replace(/[^0-9]/g, '') <= 0) {
      setCampaignFormValidation(prev => {
        return{
          ...prev,
          goalAmtValidation : 'Set a valid goal amount.'
        }
      })
      return;
    }
    if (!(/^[0-9]*$/).test(formData.min_donation_amount.replace(/[^0-9]/g, '')) || formData.min_donation_amount.replace(/[^0-9]/g, '') > 10000000 || formData.min_donation_amount.replace(/[^0-9]/g, '') <= 0) {
      setCampaignFormValidation(prev => {
        return{
          ...prev,
          minDonationValidation : 'Set a valid minimum donation amount.'
        }
      })
      return;
    }
    if(!formData.completion_date || formData.completion_date === 0 || !validateDate(formData.completion_date)){
      setCampaignFormValidation(prev => {
        return{
          ...prev,
          dateValidation : 'Completion date is required.'
        }
      })
      return;
    }
    let campaign = {
      payload: {
        ...formData,
        goal_amount : formData.goal_amount.replace(/[^0-9]/g, ''),
        completion_date: new Date(formData.completion_date).toISOString(),
        company_id: currUser.company?.company_id,
        min_donation_amount : formData.min_donation_amount.replace(/[^0-9]/g, ''),
      },
      token: currUser.token,
    };
    dispatch(loaderAction.show());
    CrowdfundingAPI.createCampaign(campaign).then(res => {
      onCreate(res.data);
      dispatch(loaderAction.hide());
    }).catch(error => {
      const err = error ? error : "Unable to create, Please try after sometime.";
      dispatch(loaderAction.hide());
      toast.error(err);  
    })
  },[currUser,formData]);

 
  return (
    <MDBox width="100%" display="flex" flexDirection="column">
      <MDBox pt={4} pb={3} px={3} onSubmit={onFormSubmit}>
        <MDBox component="form" role="form">
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Title"
              variant="standard"
              fullWidth
              onChange={e => {
                setFormData(prev => {
                  return{
                    ...prev,
                    campaign_name : e.target.value
                  }
                })
              }}
              value={formData.campaign_name}
            />
          </MDBox>
          <MDTypography
            variant="overline"
            fontWeight="medium"
            color="error"
            mt={1}
          >
            {campaignFormValidation.nameValidation}
          </MDTypography>
          <MDBox mt={2}>
            <MDInput
              type="text"
              label="Goal amount"
              variant="standard"
              fullWidth
              onChange={e => {
                const input = e.target.value;
                // Remove all non-numeric characters except the dot for decimal points
                const numericValue = input.replace(/[^0-9]/g, '');
                // // Format the numeric value
                const formattedValue = CrowdfundingUtil.formatCurrency(numericValue);
                setFormData(prev => {
                  return{
                    ...prev,
                    goal_amount : formattedValue
                  }
                })
              }}              
              value={formData.goal_amount}
            />
          </MDBox>
          <MDTypography
            variant="overline"
            fontWeight="medium"
            color="error"
          >
            {campaignFormValidation.goalAmtValidation}
          </MDTypography>
          <MDBox mt={2}>
            <MDInput
              type="text"
              label="Minimum donation amount"
              variant="standard"
              onChange={e => {
                const input = e.target.value;
                // Remove all non-numeric characters except the dot for decimal points
                const numericValue = input.replace(/[^0-9]/g, '');
                // // Format the numeric value
                const formattedValue = CrowdfundingUtil.formatCurrency(numericValue);
                setFormData(prev => {
                  return{
                    ...prev,
                    min_donation_amount : formattedValue
                  }
                })
              }}
              fullWidth
              value={formData.min_donation_amount}
            />
          </MDBox>   
          <MDTypography
            variant="overline"
            fontWeight="medium"
            color="error"
          >
            {campaignFormValidation.minDonationValidation}
          </MDTypography>                 
          <MDBox mt={2}>
            <MDTypography variant="button">Select completion date: </MDTypography>
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="date"
              label=""
              onChange={e => {
                setFormData(prev => {
                  return{
                    ...prev,
                    completion_date : e.target.value
                  }
                })
              }}                            
              variant="standard"
              fullWidth
              value={formData.completion_date}
            />
          </MDBox>
          <MDTypography
            variant="overline"
            fontWeight="medium"
            color="error"
          >
            {campaignFormValidation.dateValidation}
          </MDTypography>          
          <MDBox mt={4} mb={1}>
            <MDButton type="submit" variant="gradient" color="info" fullWidth>
              CREATE
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};
export default memo(CreateCampaignForm);
