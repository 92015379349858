import { BASE_URL } from "constants/Endpoints";
import fetch from "core/fetch";

export const assignConversation = async(payload) => {
    
    const newPayload = {
        ...payload,
        token : undefined
    }

    ///Token is undefined
    const response = await fetch(`${BASE_URL}/sms/conversation/assign`,{
        method : 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + payload.token,
          },
        body : JSON.stringify(newPayload),
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
