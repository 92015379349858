import { Snackbar, styled } from "@mui/material";

export const ActionSnackbarStyles = styled(Snackbar)(({theme}) => ({
    "& .MuiPaper-root": {
        border: "none"
    },
    "& .MuiSnackbarContent-root": {
        color: theme.palette.common.white,
        height: 44,
        padding: "0px 15px",
        backgroundColor: theme.palette.primary.main,
        "& .MuiSnackbarContent-message": {
            fontSize: 16,
            margin: "0 auto",
            textAlign: "center"
        }
    }
}));