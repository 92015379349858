// WebSocketUtility.js

import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import { appAction } from 'store/app-slice';

const RECONNECT_INTERVAL_MS = 5000; // 5 seconds

let reconnectInterval = RECONNECT_INTERVAL_MS;
let connectionClosed = false;
let timeout;
export const connectWebSocket = (userId,companyId,WS_URL,dispatch) => {
    const stomp = createStompClient(WS_URL);
    stomp.connect({userId : userId,companyId:companyId}, () => {
      // Reset the reconnect interval on successful connection
      reconnectInterval = RECONNECT_INTERVAL_MS;
      dispatch(appAction.updateStompClient(stomp));
    });

    stomp.onclose = () => {
      console.log("connection closed.....................")
    };

    stomp.onWebSocketClose = (event) => {
      console.log('WebSocket closed------------:', event);
      // Reconnect on connection closure
      if(!connectionClosed){
        scheduleReconnect(userId,companyId,WS_URL,dispatch);
      }
    };

};

const scheduleReconnect = (userId,companyId,WS_URL,dispatch) => {
  timeout = setTimeout(() => {
    console.log('Attempting to reconnect ' + WS_URL);
    connectWebSocket(userId,companyId,WS_URL,dispatch);
}, reconnectInterval);
  // Exponentially increase the reconnect interval (optional)
  reconnectInterval = Math.min(reconnectInterval * 2, 60000); // Cap at 1 minute
};

export const disconnect = () => {
  connectionClosed = true;
  if(timeout) {
    clearTimeout(timeout);
  }
}
const createStompClient = (url) => {
  const socket = new SockJS(url, {}, { CheckOrigin: () => false });
  return Stomp.over(socket);
};