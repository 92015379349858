import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import chromeMediaIcon from "assets/download.png";


const AudioPermission = () => {

    return(
        <MDBox display="flex" flexDirection="column">
            <MDTypography variant="h5">{"Allow Microphone"}</MDTypography>
            <p style={{fontSize:'18px'}}>
            {`To record Voice Message, BLIQ needs access to your
                microphone. Click`} 
                <span style={{display:'inline-block',textAlign:'center'}}>
                <img alt="Audio permission" src={chromeMediaIcon} style={{height:'22px'}}/>
                </span>{`in the URL bar and choose "Always allow
                bliqmsg.com to access your microphone"`}
            </p>
        </MDBox>        
    )
}
export default AudioPermission;