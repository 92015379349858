import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";


//Manage and send the chat messages....
const useWAT = () => {
  const waTemplates = useSelector((state) => state.chat.wa_templates);
  const checkForWAT = useCallback((message) => {
    const _template = waTemplates.find(_w => _w.templateName == message.message)
    if (_template) {
      const txt = JSON.parse(_template.originalTemplate);
      let newText = txt?.body

      for(const entry of message?.templateParameters.body){
          newText = newText.replace(entry.key,entry.value);
      }
      console.log('newText',newText)
      return newText
    } else {
      return message.message;
    }
  }, []);

  return [checkForWAT]
};
export default useWAT;
