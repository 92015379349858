import MDBox from "lib/components/MDBox";
import {ReactComponent as SuccessSvg} from "assets/svg/success.svg";
import { Typography } from "@mui/material";
import AppConstants from "constants/AppConstants";

const FinishProfile = ({data}) => {

    return(
        <MDBox display="flex" height="100%" flexDirection="column" justifyContent="center" alignItems="center">
             <SuccessSvg />
             <Typography>{AppConstants.WELCOME_BLIQ}</Typography>
             <Typography fontSize={"16px"}>{"We have received your application. A BLIQ expert will contact you soon."}</Typography>
        </MDBox>
    )
}
export default FinishProfile;