import {configureStore } from '@reduxjs/toolkit'
import loaderSlice from './loader-slice';
import userSlice from './user-slice';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import chatSlice from './chat-slice';
import AppSlice from './app-slice';
import UserDetailsSlice from './user-details-slice';
import chatVariablesSlice from './chat-variables-slice';
import timeoutSlice from './timeout-slice';
import IChatSlice from './IChat/Ichat-slice';
import ichatVariablesSlice from './IChat/Ichat-variables-slice';
import eventSlice from './IChat/event-slice';
import IGroupSlice from './IChat/igroup-slice';
import IThreadSlice from './IChat/ithread-slice';
import groupSlice from './group-slice';
import DraftsMessageSlice from './draft-message-slice'
import messageSlice from './message-slice';
import OutBoundNFSoundSlice from './outBoundNFSound';
//// By default local storage.  line -> 5 custom defined session storage;
const persistConfig = {
    key: 'user',
    storage,
}

const draftPersistConfig = {
    key: 'draft',
    storage,
}

const outboundPersistConfig = {
    key: 'outBound',
    storage,
}
const userPersistedReducer = persistReducer(persistConfig, userSlice.reducer);
const outBoundNFSoundPersistedReducer = persistReducer(outboundPersistConfig,OutBoundNFSoundSlice.reducer)

const draftsMessagePersistedReducer = persistReducer(draftPersistConfig, DraftsMessageSlice.reducer)

export const store = configureStore({
    reducer : {
        loader : loaderSlice.reducer,
        user : userPersistedReducer,
        outBoundNFSound: outBoundNFSoundPersistedReducer,
        chat : chatSlice.reducer,
        message: messageSlice.reducer,
        group : groupSlice.reducer,
        app : AppSlice.reducer,
        userDetails : UserDetailsSlice.reducer,
        chatVariables : chatVariablesSlice.reducer,
        ichatVariables : ichatVariablesSlice.reducer,
        timeout : timeoutSlice.reducer,
        Ichat : IChatSlice.reducer,
        event: eventSlice,
        Igroup : IGroupSlice.reducer,
        Ithread : IThreadSlice.reducer,
        draftsMessage:draftsMessagePersistedReducer,
    },
    middleware: [thunk]
});
export const persistor =  persistStore(store);
