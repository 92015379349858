import HardRefresh from "components/App/HardRefresh";
import { WEBSOCKET_EVENT_TYPE } from "constants/ChatConstants";
import { useDispatch } from "react-redux";
import { appAction } from "store/app-slice";

const useApp = () => {

    const dispatch = useDispatch();
    
    const onAppEvent = (payload) => {
        console.log(payload)
        if(payload.ws_type === WEBSOCKET_EVENT_TYPE.WEB_UPDATE){
            handleWebUpdateEvent(payload);  
        }
    };

    const handleWebUpdateEvent = (payload) => {
        // Match the version, if changed means there is update..
        dispatch(appAction.setAnnouncement(<HardRefresh/>));
    }
    return {onAppEvent}
}
export default useApp;