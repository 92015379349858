import Table from "ui/Table";
import { columns } from "constants/Columns";
import MDBox from "lib/components/MDBox";
import MDBadge from "lib/components/MDBadge";
import MDTypography from "lib/components/MDTypography";
import TotalCredit from "./TotalCredit";
import { useDispatch, useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import {
  Button,
  Divider,
  Icon,
  List,
  ListItem,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import AppDrawer from "ui/AppDrawer";
import { useCallback, useState } from "react";
import MDAvatar from "lib/components/MDAvatar";
import { getProperDate } from "util/UtilityFunctions";
import EditUser from "./EditUser";
import { useEffect } from "react";
import { captializeFirstLetter } from "util/UtilityFunctions";
import AssignPhoneV2 from "components/Phone/AssignPhoneV2";
import { RemoveCircle, Settings } from "@mui/icons-material";
import AppModal from "ui/AppModal";
import UserCellCallConfigWrapper from "./UserCellCallConfigWrapper";
import AppConstants from "constants/AppConstants";
import UserItem from "./UserItem";
import UserRole from "./UserRole";

import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { UserAPI } from "services/UserAPI";
import { ROLE } from "constants/AppConstants";
import { WARNINGS } from "constants/AppConstants";
import { loaderAction } from "store/loader-slice";

const userDetails = (user) => {
  return (
    <>
      <MDBox mx={2} p={2}>
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDAvatar src={user.profilePic} name={user.fullName} size="md" />
          <MDBox ml={2} lineHeight={1}>
            <Typography display="block" variant="h5" fontWeight="medium">
              {captializeFirstLetter(user.fullName)}
            </Typography>
            <Typography variant="subtitle1">{user.email}</Typography>
          </MDBox>
        </MDBox>
        <Divider variant="middle" />
        <Paper elevation={16}>
          <MDBox display="flex" flexDirection="column" p={3}>
            <MDBox display="flex" alignItems="center" textAlign="center">
              <Typography varient="h5">Profile Details</Typography>
            </MDBox>
            <MDBox>
              <Typography variant="body2" gutterBottom>
                Contact number : {user?.contactNumber}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Company : {captializeFirstLetter(user?.company?.companyName)}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Role : {user?.roles[0]?.name}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Credit Available : {user?.totalCredit ? user?.totalCredit : 0}
              </Typography>
            </MDBox>
            <Divider />
            <MDBox>
              <Typography varient="h6">Numbers Assigned :</Typography>
              <List
                sx={{
                  bgcolor: "background.paper",
                }}
              >
                {user.phones?.map((item) => {
                  const phoneList = (
                    <ListItem key={item?.phoneId} disablePadding>
                      <ListItemText primary={item?.phone} />
                    </ListItem>
                  );
                  return phoneList;
                })}
              </List>
            </MDBox>
          </MDBox>
        </Paper>
      </MDBox>
    </>
  );
};
const UserList = ({ data,refetch }) => {
  
  const dispatch = useDispatch();

  const currUser = useSelector((state) => state.user);

  const [customConfig,setCustomConfig] = useState(undefined);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [rows,setRows] = useState([]);
  const [assignPhone,showAssignPhone] = useState(false);

  const onUserEdited = useCallback((res) => {
    const newUsers = data.map(user => {
      if(user.userId === res.userId){
        return res;
      }
      else return user;
    });
    formatUser(newUsers);
  },[data]);

  const onUserDeleted = useCallback((res) => {
    const newUsers = data.filter(itm => itm.userId !== parseInt(res.id));
    formatUser(newUsers);
  },[data]);

  const removeUserHandler = (usr) => {
    if(usr?.roles[0]?.name === ROLE.SUPER_ADMIN){
      toast.error("User with role, SUPER ADMIN can not be deleted.");
      return;
    }
    if(currUser.id === usr.id){
      toast.error("Operation not permitted.");
      return;
    }
    Swal.fire({
      title: WARNINGS.REMOVE_USER_WARN,
      text: WARNINGS.REMOVE_USER_WARN_DESC,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      customClass: {
        container : {
          zIndex : '10000 !important'
        }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(loaderAction.show());
        UserAPI.deleteUser({token : currUser.token,email : usr.email}).then(res => {
          dispatch(loaderAction.hide());          
          toast.success("User has been removed.");
          onUserDeleted(res.data);
         }).catch(e => {
          dispatch(loaderAction.hide());
          toast.error(e?.response?.data?.message);
         })
      }
    })
  }

  const formatUser = (tmpData) => {
    const newRows = tmpData?.filter(user => user.active).map((user) => {
      const currentUser = {
        user: (
          <MDBox
            width="238px"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <UserItem
              image={user.profilePic}
              name={
                currUser.id === user.userId
                  ? `${captializeFirstLetter(user.fullName)} (You)`
                  : captializeFirstLetter(user.fullName)
              }
              email={user.email}
            >
            <Tooltip placement="right" title="View details">
              <MDBox
                sx={{ marginLeft: "8px",height:'20px' }}
                onClick={showUserDetails.bind(null, user)}
              >
                <Icon
                  sx={{
                    height: "20px",
                    width: "20px",
                    fontSize: "20px!important",
                    color: "lightgray",
                    cursor: "pointer",
                  }}
                >
                  <InfoIcon />
                </Icon>
              </MDBox>
            </Tooltip>
            </UserItem>
          </MDBox>
        ),
        role: <UserRole title={user.roles[0]?.name} description={user?.company?.companyName?.toUpperCase()} />,
        status: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={user.online ? 'Online': 'Offline'}
              color={user.online ? "success" : "error"}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        total_credit: (
          <TotalCredit
            credit={user?.totalCredit ? user?.totalCredit : 0}
            userId={user?.userId}
            companyId={user?.company?.companyId}
          />
        ),
        created_at: (
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {getProperDate(user.createdAt,'L')}
          </MDTypography>
        ),
        action: <MDBox display="flex">
              <EditUser user={user} userEdited={onUserEdited} onUserDeleted={onUserDeleted}/>
              <Settings
                onClick = {() => setCustomConfig(user)} 
                sx={{
                  marginRight: "8px",
                  cursor: "pointer",
                  width: "1.25rem",
                  height: "1.25rem",
                }}
              />
              <Tooltip title={"Remove user from company"}>
                <RemoveCircle
                  onClick={() => removeUserHandler(user)}
                  sx={{
                    marginRight: "8px",
                    cursor: "pointer",
                    width: "1.25rem",
                    height: "1.25rem",
                  }}
                />
              </Tooltip>
            </MDBox>
      };
      return currentUser;
    });
    setRows(newRows);
  }

  const onDisaUpdated = useCallback((res) => {
    const newUsers = [];
    for(const u of data){
      if(u.userId === res.userId){
        const newP = [];
        for(const p of u.customCellConfig){
          if(p.phoneId === res.phoneId){
            p.cellConfig = res.res.cellConfig;
          }
          newP.push(p);
        }
        u.customCellConfig = newP;
      }
      newUsers.push(u);
    }
    formatUser(newUsers);
  },[data])

  const showUserDetails = (usr) => {
    const usrComp = userDetails(usr);
    setOpenDrawer(usrComp);
  };
  
  useEffect(() => {
    if(data){
      formatUser(data);
    }
  },[data]);

  return (
    <>
      <MDBox display="flex" justifyContent="flex-end" alignItems="center">
        <Button onClick={() => showAssignPhone(true)} variant="contained" sx={{
          borderRadius: "0px",
          color: "white!important",
          border: "unset",
          marginLeft: "12px",
          height: "32px",
          minHeight: "unset",          
        }}> {"Assign Numbers"}</Button>
      </MDBox>
      <Table
        heading={"Users"}
        canSearch={true}
        columns={columns ? columns : []}
        rows={rows ? rows : []}
      >
        <MDBox display="flex" justifyContent="flex-end" width="100%" sx={{paddingRight:'24px'}}>
          <Button variant="outlined" sx={{color:'black!important'}} onClick={refetch} fontSize={"14px"}>{"Fetch user status"}</Button>
        </MDBox>
      </Table>
      <AppDrawer
        openDrawer={openDrawer}
        style={{
          margin: "unset !important",
          height: "100% !important",
          borderRadius: "unset!important",
          width: "35%",
        }}
        onClose={() => setOpenDrawer(null)}
      />
      {assignPhone && <AssignPhoneV2 onClose={() => showAssignPhone(false)}/>}
      {Boolean(customConfig) && 
        <AppModal
          key={customConfig?.userId}
          onModalClose={() => setCustomConfig(undefined)}
          closeOnClick={true}
          heading={AppConstants.MOBO_APP_CELL_CALL}
          rootStyle = {
              {
                "&.MuiModal-root":{
                  width:'40%',
                  left: '60%'
                }
              }
            }
          customStyle={{ 
            width: "100%",
            height: "100%",
          }}        
        >
          <UserCellCallConfigWrapper key={customConfig?.userId} user={customConfig} onDisaUpdated={onDisaUpdated}/>
        </AppModal>
      }
    </>
  );
};
export default UserList;
