import { FlexContainer } from "pages/Chat/ChatStyles";
import { SearchInput } from "pages/Chat/ChatStyles";
import { AddPhoneontainer } from "pages/Chat/ChatStyles";
import { SearchOuterWrapper } from "pages/Chat/ChatStyles";
import SearchIcon from "@mui/icons-material/Search";
import { NavbarOuterWrapper } from "pages/Chat/ChatStyles";

const ISearch = ({onSearchClose}) => {

    return(
        <NavbarOuterWrapper>
            <FlexContainer
                height="auto"
                justifyContent="space-between"
                paddingLeftRight="10px"
                className="full-width-searchbar"
            >
                <SearchOuterWrapper>
                    <AddPhoneontainer>
                        <SearchInput placeholder="Search..."/>
                    </AddPhoneontainer>
                    <SearchIcon
                        fontSize="medium"
                        className="navbar-icon icon-inside-search"
                    />                
                </SearchOuterWrapper>
            </FlexContainer> 
        </NavbarOuterWrapper>
    )
}
export default ISearch;