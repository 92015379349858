import {  RemoveCircle } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import AppConstants from "constants/AppConstants";
import { WARNINGS } from "constants/AppConstants";
import MDBox from "lib/components/MDBox";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CompanyAPI } from "services/CompanyAPI";
import { loaderAction } from "store/loader-slice";
import Swal from "sweetalert2";

const PhoneCompany = ({phoneId,company,onUnassignment = () => {}}) => {

    const dispatch = useDispatch();

    const userId = useSelector(state => state.user.id);
    const token = useSelector(state => state.user.token);
    
    const handleNumberUnAssignment = useCallback(() => {
        Swal.fire({
            title: WARNINGS.UNASSIGN_COMPNAY_PHONE_WARN,
            text:WARNINGS.PHONE_UNASSIGNED_FROM_COMPANY_WARN_DESC,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            customClass: {
                container : {
                    zIndex : '10000 !important'
                }
            }
        }).then((result) => {
            if (result.isConfirmed) {
                if(userId && company && phoneId){
                    dispatch(loaderAction.show());
                    const payload = {
                        unassigning_from : company?.company_id,
                        phone_id : phoneId,
                        user_id : userId
                    }
                    CompanyAPI.removeNumberFromCompany({payload,token}).then(res => {
                        dispatch(loaderAction.hide());
                        toast.success(res.message);
                        onUnassignment(res);
                    }).catch(e => {
                        dispatch(loaderAction.hide());
                        toast.error(AppConstants.SOMETHING_WENT_WRONG)
                        console.log(e);
                    })
                }
            }
        })
    },[phoneId,company,userId,token]);

    return(
        <>
            {company ?
                <MDBox display="flex" alignItems="center" justifyContent="flex-end">
                    <Tooltip title={`Unassign number from ${company?.company_name}`}>
                        <RemoveCircle sx={{height:'22px',width : '22px',margin : "0 2px 0 2px",cursor:"pointer"}} onClick = {handleNumberUnAssignment}/>
                    </Tooltip>
                    <span>{company?.company_name}</span>
                </MDBox>
                :
                <p>{"Available"}</p> 
            }
        </>
    )
}
export default PhoneCompany;