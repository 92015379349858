import { Download, Info } from "@mui/icons-material";
import { Popover, TextField, Tooltip, Typography } from "@mui/material";
import { uploadCsv } from "api/bulkBlast";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import AppModal from "ui/AppModal";
import BulkBlastTable from "./BulkBlastTable";
import { useCallback } from "react";
import { BulkAPI } from "services/BulkAPI";
import AppConstants from "constants/AppConstants";
import usePopup from "hooks/use-popup";
import SheetInsUI from "components/util/SheetInsUI";

const BulkUpload = ({onClose,onCsvAdded, currentPhone}) => {
    
    const { popoverId, anchorEl, openPopover, handleOpenPopup, handleClosePopup } = usePopup();

    const [file,setFile] = useState();
    
    const [fileErr, setFileErr] = useState('');

    const [loadedExcel, setLoadedExcel] = useState(false);
    
    const token = useSelector(state => state.user.token);

    const [url,setUrl] = useState();

    const [excelList, setExcelList] = useState([]);

    const {sendRequest : uploadCsvFn , status, error, data} = useHttp(uploadCsv,false);


    const onScheduleSend = () => {
        
    }
//https://docs.google.com/spreadsheets/d/1o54JLdiPYlByTwqd61GNBZyK5YecXAJ3eG_wXamhSOE/edit#gid=0
    const process = (payload) => {
        BulkAPI.processSheetUrl(payload).then(res => {
            console.log(res)
            setLoadedExcel(true);
            setExcelList(res.data);
        }).catch(e => {
            handleOpenPopup();
        });
    }

    const processSheetUrl = useCallback(() => {
        const payload = {url}
       process({token,payload});
    },[url]);
    

    const onSendHandler = (type) => {
        setLoadedExcel(false);
        onCsvAdded(type);
    }

    const imageInputChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            // console.log(e.target.files);
        }
        setFile(e.target.files[0]);
    }
    const onFileUploadClick = () => {
        if(file){
            const formData = new FormData();
                formData.append('file',file);
                uploadCsvFn({formData,token});            
            // onCsvAdded(file);
        }
    }

    useEffect(() => {
        if(status === 'completed'){
            if(data){
                setLoadedExcel(true);
                setExcelList(data);
            }
            else{
                toast.error(error);
            }
        }
    },[status,error,data])
    return(
         <>
            <AppModal customStyle={{width:'40%'}} closeOnClick={false} onModalClose={() => onClose()} heading={'Upload a CSV file'}>
                <MDBox display="flex" flexDirection="column">
                    <Typography fontSize={'14px'}>{AppConstants.BROADCAST_INSTRUCTION}</Typography>
                </MDBox>
                <MDBox width="100%" display="flex" alignItems="center" justifyContent="flex-start" flexDirection="row" mt={4}>
                    <MDBox width="100%" display="flex" justifyContent="space-between">
                        <MDInput sx={{width:'90%'}} type="file" label="" onChange={imageInputChange} />
                        <Tooltip title="Download the bulk template">
                            <a style={{textDecoration:'none',marginLeft:'6px'}} href={`https://app--media.s3.amazonaws.com/system_default_sounds/bulk.xlsx`}><Download/></a>
                        </Tooltip>
                    </MDBox>
                </MDBox>
                <MDTypography variant="subtitle2" fontWeight="regular" color="error" mt={1}>
                        <span>{fileErr}</span>
                </MDTypography>  
                {file && <MDBox style={{marginTop:'12px'}} display="flex" alignItems="center" justifyContent="center">
                    <MDButton onClick={onFileUploadClick}  size={'medium'} color='info' variant="outlined">
                        upload
                    </MDButton>
                </MDBox>}

                {/***OR PLACE THE SHEET URL */}
                <Typography variant="h6" textAlign={"center"}>OR</Typography>
                <MDBox mt={2}>
                    <MDBox display="flex" justifyContent="space-between">
                        <TextField onChange={e => setUrl(e.target.value)} fullWidth variant="standard" type="text" placeholder={AppConstants.GOOGLE_SHEET_URL_PLACEHOLDER}/>
                        <MDBox display="flex" width="40px" height='40px'>
                            <Tooltip title="Make sure the access is allowed.">
                                <Info sx={{cursor:'pointer'}}/>
                            </Tooltip> 
                        </MDBox>
                    </MDBox>
                    {url && <MDBox style={{marginTop:'12px'}} display="flex" alignItems="center" justifyContent="center">
                    <MDButton onClick={processSheetUrl}  size={'medium'} color='info' variant="outlined">
                        Retrieve
                    </MDButton>
                </MDBox>}
                </MDBox>
            </AppModal>
            {
                loadedExcel && 
                <AppModal closeOnClick={false} customStyle={{width:'85%'}} onModalClose={() => setLoadedExcel(false)} heading={'Bulk Blast'}>
                        {excelList && <BulkBlastTable currentPhone={currentPhone} list={excelList} onSend={onSendHandler} onScheduleSend={onScheduleSend}/>}
                    </AppModal>
            }
            <Popover
                id={popoverId}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClosePopup}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                sx={{
                    "& .MuiPopover-paper": {
                      backgroundColor: "white!important",
                      display: "flex",
                      justifyContent: "center",
                      border: '1px solid rgb(221, 221, 221) rgb(221, 221, 221) rgb(245, 245, 245)'
                    },
                }}                
            >
               <SheetInsUI/>
            </Popover>  
         </>   
    )
}
export default BulkUpload;