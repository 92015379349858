import React, { useCallback } from 'react'
import { useEffect } from "react";
import AppModal from "ui/AppModal";
import ChooseFile from 'components/CommonUI/ChooseFile';
import useHttp from 'hooks/use-http';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { uploadFundraisers } from 'api/fundraiser';
import AppConstants from 'constants/AppConstants';

const AddBulkFundraiser = ({ onUploaded, onCancel, campaign}) => {

  const {sendRequest : uploadCsvFn , status, error, data} = useHttp(uploadFundraisers,false);
  const currUser = useSelector(state => state.user);
  
  const onFileSelected = useCallback((file) => {
    if(file){
      const formData = new FormData();
      formData.append('file',file);
      formData.append("companyId",currUser.company?.company_id);
      formData.append("campaignId", campaign.campaign_id)
      uploadCsvFn({formData,token:currUser.token});            
    }
  },[currUser,campaign]);

  useEffect(() => {
    if(status === 'completed'){
        if(data){
            if(data.message && data.message !== ''){
                toast.warn(data.message);
            }
            else{
              toast.success("System has automatically assigned phone number for this fundraiser.")
            }
            onUploaded(data);
        }
        else{
            toast.error(error);
        }
    }
  },[status,error,data]);

    return (
      <AppModal
        onModalClose={onCancel}
        heading={"Choose csv file."}
      >
        <ChooseFile onFileSelected={onFileSelected} templateTooltipMsg="Download template" showTemplate={true} templateUrl= {AppConstants.FUNDRAISER_BULK_TEMPLATE} />
      </AppModal>
    )
  }



  export default AddBulkFundraiser