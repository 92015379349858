import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Mic } from "@mui/icons-material";
import { useRef, useState } from "react";
import IEmoji from "./IEmoji";
import { Popover } from "@mui/material";
import usePopup from "hooks/use-popup";
import EmojiConvertor from "emoji-js";
import { useEffect } from "react";
import { useCallback } from "react";

const IMessageIconsFunctionality = ({messageState,setMessageState}) => {

    const { popoverId, anchorEl, openPopover, handleOpenPopup, handleClosePopup } = usePopup();

    const inputRef = useRef();

    const handleClickImage = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const handleMedia = () => {

    }

    const addEmoji = (emoji) => {
        // console.log('emoji',emoji)
        // const emojiConvertor = new EmojiConvertor();
        // emojiConvertor.replace_mode = "unified";
        // const convertedEmoji = emojiConvertor.replace_colons(emoji?.colons);
        setMessageState(prev => {
            return {
                ...prev,
                message: prev?.message + "" + emoji?.native
            }
        });
    }

    const handleChangeFileInput = useCallback((event) => {
        if (event.target) {
            const target = event.target;
            const file = target.files?.[0];
            if (file) {
                setMessageState(prev => {
                    return{
                        ...prev,
                        attachement : file
                    }
                })
            }
        }
    }, []);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.addEventListener("change", handleChangeFileInput);
        }
        return () => {
            if (inputRef.current) {
                inputRef.current.removeEventListener("change", handleChangeFileInput);
            }
        };
    }, []);

    return(
        <>
            <AttachFileIcon style={{ cursor: "pointer" }} onClick={handleClickImage}/>
            <input ref={inputRef} type="file" id="ic-upload-input" hidden />
            <SentimentSatisfiedAltIcon  style={{ cursor: "pointer", marginLeft: "6px" }} onClick={handleOpenPopup}/>
            <Popover
                id={popoverId}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClosePopup}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
            >
                <IEmoji addEmoji={addEmoji}/>
            </Popover>
            <Mic style={{ cursor: "pointer",margin:"0 6px 0 6px" }}/>
        </>
    )
}
export default IMessageIconsFunctionality;