import { FormControl, InputLabel, MenuItem, Typography } from "@mui/material";
import { SelectStyle } from "components/ImportChat/MappedColumns";
import { CARRIER_INS } from "constants/AppConstants";
import MDBox from "lib/components/MDBox";
import MDInput from "lib/components/MDInput";
import { useCallback, useState } from "react";

const AUTH_OPTION = {
    NO_AUTH : {name : 'No Auth', key : 'no_auth'},
    BASIC_AUTH : {name : 'Basic Auth', key :'basic_auth' },
    BEARER_TOKEN : {name : 'Bearer Token', key : 'bearer_token'},
}
const AuthParams = () => {

    const [authOption,setAuthOption] = useState(AUTH_OPTION.NO_AUTH);

    const onChangeAuthOptionChange = (e) => {
        setAuthOption(e.target.value);
    }

    const renderFieldsForAuthOptions = useCallback(() => {
        if(authOption.key === 'no_auth'){
            return <MDBox display="flex" width="100%" justifyContent="center" alignItems="center">
                <Typography>{CARRIER_INS.NO_AUTH}</Typography>
            </MDBox>
        }
        else if(authOption.key === 'basic_auth'){
            return <MDBox display="flex" width="100%" flexDirection="column" justifyContent="center" alignItems="center">
                    <MDInput  fullWidth type="text" label="User Name" />
                    <MDInput  fullWidth type="text" label="Password" />
            </MDBox>
        }
        else if(authOption.key === 'bearer_token'){
            return <MDBox display="flex" width="100%" justifyContent="center" alignItems="center">
                <MDInput  fullWidth type="text" label="Bearer Token" />
            </MDBox>
        }

    },[authOption]);

    return (
        <>
            <MDBox display="flex" width="40%" mt={1}>
                <MDBox display="flex" margin={"8px"} width="100%">
                    <MDBox display="flex" flexDirection="column" width="100%">
                        <FormControl fullWidth>
                            <InputLabel id="auth">Authorization options</InputLabel>
                            <SelectStyle
                                labelId="auth"
                                id="auth-select"
                                label={"Authorization options"}
                                sx={{
                                    height:'40px',
                                    margin:'8px 0px 0px 0px',
                                    width: '100%',
                                }}
                                onChange={onChangeAuthOptionChange}
                                value={authOption}
                            >
                                <MenuItem key={AUTH_OPTION.NO_AUTH.key} value={AUTH_OPTION.NO_AUTH}>{AUTH_OPTION.NO_AUTH.name}</MenuItem>
                                <MenuItem key={AUTH_OPTION.BASIC_AUTH.key} value={AUTH_OPTION.BASIC_AUTH}>{AUTH_OPTION.BASIC_AUTH.name}</MenuItem>
                                <MenuItem key={AUTH_OPTION.BEARER_TOKEN.key} value={AUTH_OPTION.BEARER_TOKEN}>{AUTH_OPTION.BEARER_TOKEN.name}</MenuItem>
                            </SelectStyle>
                        </FormControl>
                    </MDBox>
                </MDBox>
            </MDBox>
            <MDBox display="flex" width="60%" flexDirection="row">
                {renderFieldsForAuthOptions()}
            </MDBox>
        </>
    )
}
export default AuthParams;