//@media only screen and (max-width: 600px)  {...}
//What this query really means, is “If [device width] is less than or equal to 600px, then do {…}”

//@media only screen and (min-width: 600px)  {...}
//What this query really means, is “If [device width] is greater than or equal to 600px, then do {…}”
import {LinkItUrl as Linkify,LinkIt} from 'react-linkify-it';
import styled, { css } from "styled-components";
const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
}
export const minDevice = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`
};

export const maxDevice = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`
};


//BACKGROUND COLOR CAN BE => #fffbfb, #fdfdfd
export const ChatMainWrapper = styled.div`
  // height: ${(props) => props.isShowAlert? "calc(100vh - 45px)": "100vh"}; // add isShowAlert prop on the ChatMainWrapper when alert is visible
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  // background-color: #ccc;
  display: flex;
  justify-content: flex-start;
  
  overflow-y : hidden;
  overflow-x : auto;
  
  @media ${maxDevice.tablet} {
    overflow : hidden;
    flex-direction: column;
  }
`;

export const VerticalSeparator = styled.div`
  width: 1px;
  height: 100%;
  background-color: #ddd;
`;

export const ChatLeftPanel = styled.div`
  
  @media ${minDevice.laptop} {
    max-width: ${(props) => (props.isCustomCheckbox ? "455px" : "420px")};
    overflow: hidden;
  }
  // height: calc(100% - 112px);
  background-color: #fff;
  position: relative;
  overflow: hidden;
  min-width: 30%;

  @media ${maxDevice.tablet} {
    width: 100%;
    height : 100%
  }
`;

export const ChatRightPanel = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  @media ${minDevice.tablet} {
    overflow: hidden;
  }
`;

export const Navbar = styled.div`
  background-color: ${(props) => props.bgColor || "#fff"};
  height: ${(props) => props.height || "50px"};
  border-top: ${(props) => props.borderTop || "1px solid #ddd"};
  border-bottom: ${(props) => props.borderBottom || "1px solid #ddd"};
  border-left: ${(props) => props.borderLeft || "1px solid #ddd"};
  border-right: ${(props) => props.borderRight || "1px solid #ddd"};
  color: #555;
  padding: ${(props) => props.padding || "0px"};
  .navbar-icon {
    color: #4485be;
    &:hover {
      cursor: pointer;
    }
  }

  .navbar-checkbox {
    &.Mui-checked .MuiSvgIcon-root {
      border-color: #fff !important;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"),
        linear-gradient(195deg, #4485be, #4485be) !important;
    }
    .MuiSvgIcon-root {
      width: 17px;
      height: 17px;
      border-radius: 4px;
      margin-bottom: 2px;
    }
  }
  .export-chat-icons {
    min-width: 100px;
    line-height: 14px;
  }
  .user-settings-icon {
    height: 40px;
    width: 40px;
    img {
      height: 40px;
      width: 40px;
    }
  }

  @media ${maxDevice.tablet}{
    height: 86px;
    display: flex;
    flex-direction : row;
    justify-content: space-between;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  height: ${(props) => props.height || "100%"};
  justify-content: ${(props) => props.justifyContent || "center"};
  align-items: ${(props) => props.alignItems || "center"};
  flex-direction: ${(props) => props.fxLayout || "row"};
  padding-top: ${(props) => props.paddingTopBottom || "0px"};
  padding-bottom: ${(props) => props.paddingTopBottom || "0px"};
  padding-left: ${(props) => props.paddingLeftRight || "0px"};
  margin-bottom: ${(props) => props.marginBottom || "0px"};
  padding-right: ${(props) => props.paddingLeftRight || "0px"};
  > div,
  > img,
  > label,
  > svg {
    :not(:last-child) {
      margin-right: ${(props) => props.spacing || "0px"};
    }
  }
`;
export const LeftChatContent = styled.div`
  margin-left: 50px;
  height: calc(100% - 112px);
  // height: 100%;
  width:  ${props=>props.width||'100%'}; // Tried to resolve, but then group view disturbed
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid #ddd;
  .account-user-icon {
    height: 40px;
    width: 40px;
    overflow: hidden;
    border-radius: 50px;
    // color: #d3d3db;
  }
  &::-webkit-scrollbar {
    width: 0.3em;
    height: 0.4em;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    // outline: 1px solid slategrey;
    // background-color: #ff0606;
    background-color: #8b8b8b;
  }
  .chat-date-panel {
    max-width: 70px;
  }
  @media ${maxDevice.tablet} {
    height: 100%;
  }
`;

export const UserChatBlock = styled.div`
  display: flex;
  height: auto; /* Changed height from 65px to auto**/
  width: auto;
  align-items: center;
  position:relative;
  background-color: ${(props) =>
    props.currentSelected ? "#d6d3d35e" : "#fff"};
  padding: 4px 8px 0px 8px; //7px 10px 7px 20px;
  margin: 2px;
  &:hover {
    cursor: pointer;
    background-color: #d6d3d35e;
  }
`;

export const DetailsBlock = styled.div`
  margin-left: 8px;
  width: 290px;
  padding-top: 5px;
`;

export const ProfileIcon = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #1cbb1cad;
`;

export const UserName = styled.div`
  font-size: 14px;
  font-weight: ${props => props.fontWeight || "400"};
  color: #555;
  text-align: left;
`;

export const StyledLabel = styled.div`
  font-size: ${(props) => props.fontSize || "16px"};
  font-weight: ${(props) => props.fontWeight || "400"};
  color: ${(props) => props.color || "#555"};
  text-align: ${(props) => props.textAlign || "left"};
`;

export const MainChatPanel = styled.div`
  // height: calc(100% - 120px);
  // height : 450px;
  background-color: #fff;
  overflow: auto;
  flex: 1 1 0;
  &::-webkit-scrollbar {
    width: 0.3em;
    height: 0.4em;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    outline: 1px solid slategrey;
    // background-color: #ff0606;
    background-color: #8b8b8b;
  }
  //Will apply till max tablet, after will not
  // @media ${maxDevice.tablet} {
  //   background-color: green;
  // }
`;
export const SidePanel = styled.div`
  padding-bottom: 8px;
  background-color: transparent;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0.3em;
    height: 0.4em;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    outline: 1px solid slategrey;
    // background-color: #ff0606;
    background-color: #8b8b8b;
  }
`;
export const MessageWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  margin: 25px 10px;
  height: calc(100% - 50px);
  // height: 100%;
  // overflow-y: hidden;
  border: var(--border);
  border-radius: 5px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  background: var(--msg-wrapper-bg);
`;

export const MessageChat = styled.div`
  flex: 1;
  // overflow-y: auto;
  padding: 10px;
  padding-left: 6%;
  padding-right: 6%;
  &::webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #ddd;
  }
  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
  }
  .custom-checkbox {
    top: 10px;
  }
`;

export const DateTimeInfo = styled.div`
  color: #8d8b8b;
  font-size: 12px;
  display: block;
  text-align: center;
  // margin-bottom: 4px;
  &.left-date-info {
    text-align: left;
  }
  &.right-date-info {
    text-align: right;
  }
  .MuiAvatar-img {
    height: 26px;
    width: 26px;
  }
`;
export const MessageLinkText = styled(Linkify)`
text-decoration-line: underline ;
font-style: italic;
color: ${(props) => props.inbound ? 'blue!important' : 'white!important' };
`

export const UrlLinK = styled(LinkIt)`
text-decoration-line: underline ;
font-style: italic;
color: 'blue';
`


export const Message =styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 4px;
  font-size: 13px;
  &.right-msg {
    line-height: 0.75;
    font-size: 13px !important;
    flex-direction: row-reverse;
    // background-color: #e0f2f1;
    // color: #004d40;
  }
  &.left-msg {
    line-height: 0.75;
    font-size: 13px !important;
  }
  &.msg:last-of-type {
    margin: 0;
  }
`;

export const LeftMessageBubble = styled.div`
  //efefef
  background: #f8f8f8 !important;
  color: #555;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-left: 5px solid #ff0606;
  position: relative;
  max-width: 550px;
  min-width: 88px;
  padding: ${(props) => (props.chatImage ? "0px" : "6px")};
  border: ${(props) => (props.chatImage ? "none" : "1px #ddd")};
  // border-radius: 10px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 6px;
`;

export const RightMessageBubble = styled.div`
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 5px solid #ff0606;
  position: relative;
  min-width: 88px;
  max-width: 550px;
  padding: ${(props) => (props.chatImage ? "0px" : "6px")};
  background-color: ${(props) => (props.chatImage ? "#f8f8f8" : "#1876F2")};
  color: #fff;
  border: ${(props) => (props.chatImage ? "none" : "1px #ddd")};
  // border-radius: 10px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
`;

export const MessageText = styled.div`
  text-align: left;
  line-height: 1.35;
  padding: 4px;
  color: ${(props) => props.attachmentWithText && "#423d4d"};
  padding: ${(props) => props.attachmentWithText && "12px"};
  @media (max-width: 760px) {
    user-select: none;
  }
`;

export const LMessageText = styled.div`
  text-align: left;
  line-height: 1.35;
  padding: 4px;
  color: ${(props) => props.attachmentWithText && "#423d4d"};
  padding: ${(props) => props.attachmentWithText && "12px"};
  @media (max-width: 760px) {
    user-select: none;
  }
`;

export const ChatBottomPanel = styled.div`
 
  width: 100%;
  background-color: #fff;
  .chat-footer {
    height: 50px;
    padding: 10px 20px;
    background-color: #fff;
  }
  @media (min-width: 768px) {
    padding-left: 6%;
    padding-right: 6%;
  }
`;

export const ChatHeader = styled.div`
  height: 26px;
  background-color: #efefef;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 400;
  color: #555;
  padding-right: 26px;
  .tag-block {
    margin-right: 10px;
  }
  .bottom-panel-checkbox {
    .MuiSvgIcon-root {
      border: 0.0625rem solid #555;
    }
  }
`;

export const ChatEditor = styled.div`
  height: calc(100% - 79px);
  textarea {
    min-height: 76px;
    width: calc(100% - 420px);
    font-size: 16px;
    color: #555;
    font-family: sans-serif;
    padding: 10px;
    outline: none;
    border: none;
    overflow-y: auto;
    resize: none;
  }
`;

export const HorizontalSeparator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ddd;
`;

export const SendCustomDropdown = styled.div`
  position: relative;
  transition: all 20s ease-in;

  ul.custom-time-menu.dropdown-menu {
    position: absolute;
    min-width: 200px;
    border: 1px solid #aaa;
    left: 0px;
    bottom: 35px;
    top: auto;
    list-style-type: none;
    transition: all 20s ease-in;
    display: none; //// displ
    border-radius: 4px;
    padding: 5px 0px;
    background-color: #fff;

    li.send-dropdown-menu {
      background-color: #fff;
      a {
        color: #555;
        padding: 4px 12px;
        font-size: 14px;
        &:focus {
          outline: none;
        }
      }
      &:hover {
        background-color: #bbb;
        cursor: pointer;
        a {
          color: #fff;
        }
      }
    }

    &:before {
      position: absolute;
      display: inline-block;
      content: "";
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 10px solid #ddd;
      bottom: -10px;
      left: 88px;
    }
    &:after {
      position: absolute;
      display: inline-block;
      content: "";
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 7px solid #fff;
      bottom: -7px;
      left: 90px;
    }
  }

  &:hover {
    ul.custom-time-menu.dropdown-menu {
      display: block;
    }
  }
`;
export const SendButton = styled.button`
  height: 32px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  width: 110px;
  border: 1px solid #4485be;
  background-color: #4485be;
  &:hover {
    background-color: #fff;
    color: #4485be;
    cursor: pointer;
  }
`;

export const StaticSideBar = styled.div`
  width: 50px;
  // position: fixed;
   position: absolute;
  // height: calc(100vh - 5px);
  height: 100%;
  border-right: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${FlexContainer} {
    padding: 20px 0px 0px;
  }
`;

export const SideBarIcons = styled.div`
  height: 40px;
  width: 40px;
  border: 1px solid transparent;
  border-radius: 50%;
  background-color: ${(props) =>
    props.currentSelected ? "#008dff29" : "#fff"};
  padding: 6px;
  text-align: center;
  position: relative;
  &:hover {
    // border: 1px solid #008dff29;
    background-color: #008dff29;
    cursor: pointer;
    border-radius: 50%;
  }
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  .sidebar-icons {
    height: 20px;
    color: rgb(68, 133, 190); //#555;
    &:hover {
      cursor: pointer;
    }
  }
  .contact-side-icon {
    margin-bottom: 0px;
  }
  .chat-side-icon {
    margin-bottom: 5px;
  }
  .group-side-icon {
    margin-bottom: 2px;
  }
  .bottom-settled-icons {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(4px, 27%);
  }
`;
export const LeftChatPanel = styled.div`
  height: 100%;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  &::-webkit-scrollbar {
    width: 0.3em;
    height: 0.4em;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    outline: 1px solid slategrey;
    // background-color: #ff0606;
    background-color: #8b8b8b;
  }
`;

export const UnreadBadge = styled.div`
  height: 5px;
  width: 10px;
  border-radius: 20px;
  color: #fff;
  font-weight: 400;
  background-color: #ff0606c7;
  position: absolute;
  top: 5px;
  right: 0px;
  height: 14px;
  width: 20px;
  font-size: 10px;
  text-align: center;
`;

export const SearchContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  .search-icon {
    position: absolute;
    top: 9px;
    left: 4px;
    font-size: 20px;
    color: #4485be;
  }
`;

export const AddPhoneontainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SearchInput = styled.input`
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 30px;
  font-size: 16px;
  color: #555;
  padding-left: 25px;
  padding-right: 10px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #aaa;
    font-size: 14px;
  }
`;

export const AddPhoneInput = styled.input`
  background-color: #fff;
  border: 1px solid ${(props) => (props.error ? "red" : "#ddd")};
  border-radius: 4px;
  height: 30px;
  font-size: 16px;
  color: #555;
  padding-left: 25px;
  padding-right: 10px;
  &:focus {
    outline: none;
  }
`;

export const MessageImage = styled.div`
  cursor:pointer;
  .left-image-bubble {
    border-radius: 4px;
  }
`;
export const NavbarOuterWrapper = styled.div`
  .full-width-searchbar {
    padding-top: 13px;
    padding-bottom: 13px;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    input {
      width: 100%;
      background-color: #f5f5f5;
      height: 35px;
      padding-left: 30px;
      border: 1px solid #efefef;
      border-radius: 6px;
    }
  }
`;
export const SearchOuterWrapper = styled.div`
  position: relative;
  width: 100%;
  .cross-inside-search {
    position: absolute;
    right: 6px;
    top: 8px;
    height: 18px;
    width: 19px;
    color: #4485be;
  }
  .icon-inside-search {
    position: absolute;
    left: 6px;
    top: 8px;
    height: 19px;
    width: 19px;
    color: #4485be;
  }
  .filter-icon-inside-search {
    position: absolute;
    cursor: pointer;
    right: 8px;
    top: 8px;
    height: 19px;
    width: 19px;
    color: #4485be;    
  }
`;

export const ChatFooter = styled.div`
  width: 100%;
  background-color: #fff;
  display: inline-flex;
  align-items: flex-end;
  padding: 0px 15px 15px 15px;
`;
export const ChatEditorWrapper = styled.div`
  background-color: #fff;
  border-radius: 20px;
  box-shadow: -4px 2px 6px 0px #979797;
  width: 100%;
  position: relative;
  display: flex;
  padding: 10px;
  border: 1px solid #ddd;
  max-height: 39vh;
  min-height: 1.47em;
  white-space: pre-wrap;
  word-break: break-word;
  border: none;
  align-items: flex-end;
  > div {
    border-radius: 20px;
  }
`;
export const CustomChatEditor = styled.div`
  width: 100%;
  background-color: #fff;
  // position: static;
  max-height: 39vh;
  min-height: 1.47em;
  white-space: pre-wrap;
  word-break: break-word;
  border: none;
  font-size: 16px;
  overflow-y: auto;
  padding-left: 5px;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 0.3em;
    height: 0.4em;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #ccc;
  }
  &:focus-visible {
    outline: none;
  }
`;
export const ChatIcons = styled.div`
  width: 120px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 3px;
  > img:not(:last-child) {
    margin-right: 5px;
  }
  @media ${maxDevice.tablet}{
    max-width:40px;
  }
`;