import { Edit } from "@mui/icons-material";
import { Avatar, Badge, Button, Divider, Typography } from "@mui/material";
import { uploadProfile } from "api/user";
import ImageUpload from "components/Chat/ImageUpload";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UserAPI } from "services/UserAPI";
import { IChatAction } from "store/IChat/Ichat-slice";
import { chatAction } from "store/chat-slice";
import { chatVariablesAction } from "store/chat-variables-slice";
import { loaderAction } from "store/loader-slice";
import { userDetailAction } from "store/user-details-slice";
import { userAction } from "store/user-slice";
import { disconnect } from "util/WebSocketUtility";
import Swal from "sweetalert2";
import AppConstants from "constants/AppConstants";
import Cropper from "react-easy-crop";

const ProfileSettings = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const stompClient = useSelector(state => state.app.stompClient);

  const currentUser = useSelector(state => state.userDetails.details);
  const token = useSelector(state => state.user.token);
  const [editPhoto,setEditPhoto] = useState(false);
  const [saveAuthNumber,setSaveAuthNumber] = useState(false);
  const [editUser,setEditUser] = useState(false);
  const [currentUserState, setCurrentUserState] = useState({
    fullName : currentUser.fullName,
    email : currentUser.email,
    userId : currentUser.userId,
    profilePic : currentUser.profilePic,
    contactNumber : currentUser.contactNumber,
    secondAuthNumber : currentUser?.secondAuthNumber ? currentUser?.secondAuthNumber : currentUser.contactNumber
  });

  const [editPhotoCase, setEditPhotoCase] = useState({ type: "" });
  const [profileCrop, setProfileCrop] = useState({
    crop: { x: 0, y: 0 },
    zoom: 1,
    rotation: 0,
    croppedAreaPixels: null,
  });

  const [selectImage, setSelectImage] = useState({ imageSrc: null, type: "" });

  
  const {sendRequest: updateProfilePicFn,status,error,data: uploadedRes} = useHttp(uploadProfile, false);

  const saveUser = useCallback(() => {
    let userData = { ...currentUserState, profilePic:currentUser?.profilePic }
    if(userData.fullName.trim().length === 0 || userData.email.trim().length === 0 || !(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/).test(userData.contactNumber)){
      toast.error("Please provide valid data.");
      return;
    }
    dispatch(loaderAction.show());
    UserAPI.updateUser({user : userData,token}).then(res => {
        dispatch(loaderAction.hide());
        //if email changed logout....
        toast.success("Updated successfully.");
        if(userData.email !== currentUser.email){
          Swal.fire({
            title: 'Email is changed. you need to login again',
            html: 'Logging out...',
            timer: 3000,
            showConfirmButton: false
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              logout();
            }
          })
        }
        // dispatch
      }).catch(e => {
        dispatch(loaderAction.hide());
        toast.error(e?.response?.data?.message | 'Something went wrong. Please try later.')
      });
    setEditUser(false);
  },[currentUserState,token,currentUser])

  const saveAuthNumberFn = useCallback(() => {
    let userData = { ...currentUserState, profilePic:currentUser?.profilePic }
    if(!(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/).test(userData.secondAuthNumber)){
      toast.error("Please provide valid number.");
      return;
    }
    dispatch(loaderAction.show());
    UserAPI.updateSecondAuthNumber({user : userData,token}).then(res => {
        dispatch(loaderAction.hide());
        toast.success("Updated successfully.");
        //dispatch to update in store
        dispatch(userDetailAction.updateSecondAuthNumber({number : res.data.secondAuthNumber}));
      }).catch(e => {
        dispatch(loaderAction.hide());
        toast.error(e?.response?.data?.message | 'Something went wrong. Please try later.')
      });
    setSaveAuthNumber(false);
  },[currentUserState.secondAuthNumber,token]);

  const closeCrop = () => {
    setProfileCrop({
      crop: { x: 0, y: 0 },
      zoom: 1,
      rotation: 0,
      croppedAreaPixels: null,
    });
  };

  const selectProfilePic = (file) => {
    setEditPhoto(false);
    closeCrop();
    setSelectImage({ imageSrc: URL.createObjectURL(file), type: file?.type });
  };

  const logout = useCallback(() => {
    localStorage.removeItem("blip_user_data");
    dispatch(userAction.logout());
    stompClient?.disconnect();
    disconnect();
    dispatch(userAction.logout());
    dispatch(chatAction.reset());
    dispatch(chatVariablesAction.reset());
    dispatch(IChatAction.reset());
    navigate("/login",{ replace: true });;
  },[dispatch,stompClient]);

  useEffect(() => {
    if (status === "completed") {
      if (uploadedRes) {
        // Dispatch profile picture change
        toast.success(AppConstants.UPDATE_SUCCESSFUL);
        dispatch(userDetailAction.updateProfilePic({ uri: uploadedRes.uri }));
      }
    }
  }, [status]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setProfileCrop((pre) => {
      return {
        ...pre,
        croppedAreaPixels: croppedAreaPixels,
      };
    });
  }, []);

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
  }

  function rotateSize(width, height, rotation) {
    const rotRad = getRadianAngle(rotation);

    return {
      width:
        Math.abs(Math.cos(rotRad) * width) +
        Math.abs(Math.sin(rotRad) * height),
      height:
        Math.abs(Math.sin(rotRad) * width) +
        Math.abs(Math.cos(rotRad) * height),
    };
  }
  async function getCroppedImg(
    { imageSrc, type },
    pixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
  ) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      return null;
    }

    const rotRad = getRadianAngle(rotation);

    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
      image.width,
      image.height,
      rotation
    );

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    ctx.drawImage(image, 0, 0);

    const data = ctx.getImageData(
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height
    );

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.putImageData(data, 0, 0);

    return new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        resolve(file);
      }, type);
    });
  }

  const uploadProfilePic = useCallback(
    async (type) => {
      try {
        const croppedImage = await getCroppedImg(
          selectImage,
          profileCrop?.croppedAreaPixels,
          profileCrop?.rotation
        );
        setCurrentUserState((prev) => {
          return {
            ...prev,
            profilePic: URL.createObjectURL(croppedImage),
          };
        });
        if (type === "preview") {
          setEditPhotoCase({ type: type });
          return;
        }
        setSelectImage({ imageSrc: null, type: "" });
        setEditPhotoCase({ type: "" });
        const formData = new FormData();
        formData.append("image", croppedImage);
        formData.append("userId", JSON.stringify(currentUserState.userId));
        updateProfilePicFn({ formData, token });
      } catch (e) {
        console.error(e);
      }
    },
    [
      profileCrop?.croppedAreaPixels,
      profileCrop?.rotation,
      selectImage?.imageSrc,
    ]
  );

  return (
    <>
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox mb={2} display="flex" width="100%" justifyContent="center">
          <MDTypography fontSize={'24px'}>My profile</MDTypography>
        </MDBox>
        {/**Avtaar */}
        <MDBox display="flex" justifyContent="center">
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              !selectImage?.imageSrc && (
                <Edit
                  onClick={() => {
                    setEditPhoto(true);
                  }}
                  sx={{
                    height:'22px',
                    width:'22px',
                    cursor:'pointer',
                    position: "absolute",
                    left: "20px",
                  }}
                />
              )
            }
          >
            <MDBox>
              {editPhotoCase.type !== "preview" && selectImage?.imageSrc ? (
                <MDBox>
                  <MDBox
                    style={{
                      width: "200px",
                      height: "200px",
                      margin: "0 auto",
                      textAlign: "center",
                    }}
                  >
                    <Cropper
                      style={{
                        containerStyle: {
                          width: "200px",
                          height: "200px",
                          left: "auto",
                          right: "auto",
                        },
                      }}
                      image={selectImage.imageSrc}
                      crop={profileCrop?.crop}
                      zoom={profileCrop?.zoom}
                      aspect={1}
                      onCropChange={(e) =>
                        setProfileCrop((pre) => {
                          return {
                            ...pre,
                            crop: e,
                          };
                        })
                      }
                      onCropComplete={onCropComplete}
                      onZoomChange={(e) =>
                        setProfileCrop((pre) => {
                          return {
                            ...pre,
                            zoom: e,
                          };
                        })
                      }
                      cropShape="round"
                    />
                  </MDBox>
                </MDBox>
              ) : (
                <Avatar sx={{height:'120px',width:'120px', margin: "0 auto 10px"}} alt="Travis Howard" src={currentUserState.profilePic} /> )}

              {selectImage?.imageSrc && (
                <MDBox
                  sx={{
                    padding: "10px 0 0",
                  }}
                >
                  <Button
                    onClick={() => {
                      if (editPhotoCase.type === "preview") {
                        setEditPhotoCase({ type: "" });
                        return;
                      }
                      closeCrop();
                      setCurrentUserState((prev) => {
                        return {
                          ...prev,
                          profilePic: currentUser.profilePic,
                        };
                      });
                      setSelectImage({ imageSrc: null, type: "" });

                    }}
                  >
                    {editPhotoCase.type === "preview" ? "Back" : "Cancel"}
                  </Button>
                  <Button
                    onClick={() => uploadProfilePic(editPhotoCase.type === "preview"?"upload":"preview")}
                    sx={{
                      borderRadius: "0px",
                      color: "white!important",
                      border: "unset",
                    }}
                    variant="contained"
                  >
                    {editPhotoCase.type === "preview" ? "Save" : "Preview"}
                  </Button>
                </MDBox>
              )}
            </MDBox>
          </Badge>
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            label="Name"
            variant="standard"
            fullWidth
            value={currentUserState.fullName}
            onChange={e => {
              if(!editUser){
                setEditUser(true);
              }
              setCurrentUserState(prev => {
                return{
                  ...prev,
                  fullName : e.target.value
                                }
              })
            }}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            label="Email"
            variant="standard"
            fullWidth
            value={currentUserState.email}
            onChange={e => {
              if(!editUser){
                setEditUser(true);
              }
              setCurrentUserState(prev => {
                return{
                  ...prev,
                  email : e.target.value
                            }
              })
            }}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDBox display="flex" flexDirection="column">
            <MDInput
              type="text"
              label="Contact Number"
              variant="standard"
              fullWidth
              value={currentUserState?.contactNumber ? currentUserState.contactNumber : ''}
              onChange={e => {
                if(!editUser){
                  setEditUser(true);
                }
                const tmpPhone = e.target.value;
                setCurrentUserState(prev => {
                  return{
                    ...prev,
                    contactNumber :  tmpPhone.replace(/\D/g, "")
                                    }
                })
              }}
            />
          </MDBox>
        </MDBox>
        {/**Save user btn */}
        <MDBox display="flex" width="100%" justifyContent="flex-end">
          {editUser && <>
              <Button onClick={() => {
                  setEditUser(false);
                  setCurrentUserState(prev => {
                    return{
                      ...prev,
                      fullName : currentUser.fullName,
                      email : currentUser.email,
                      userId : currentUser.userId,
                      contactNumber : currentUser.contactNumber,
                      secondAuthNumber : currentUser?.secondAuthNumber ? currentUser?.secondAuthNumber : currentUser.contactNumber
                    }
                  })
                  // setCurrentUserState({
                  //   fullName : currentUser.fullName,
                  //   email : currentUser.email,
                  //   userId : currentUser.userId,
                  //   profilePic : currentUser.profilePic,
                  //   contactNumber : currentUser.contactNumber,
                  //   secondAuthNumber : currentUser?.secondAuthNumber ? currentUser?.secondAuthNumber : currentUser.contactNumber
                  // })
                }}>{"Cancel"}</Button>
              <Button onClick={saveUser} sx={{borderRadius: "0px",color: "white!important",border: "unset",}} variant="contained">save</Button>
            </>}
        </MDBox>
        {/**2FA setting */}
        <MDBox display="flex" justifyContent="center">
          <MDTypography fontSize={'16px'}>2FA Settings</MDTypography>
        </MDBox>
        <MDBox display={"flex"} flexDirection={"column"}>
          <MDBox mb={2}>
            <MDBox display="flex" flexDirection="column">
              <MDInput
                type="text"
                label="Second Factor Number"
                variant="standard"
                fullWidth
                value={currentUserState?.secondAuthNumber}
                onChange={(e) => {
                  if(!saveAuthNumber){
                    setSaveAuthNumber(true);
                  }
                  let tmpPhone = e.target.value;
                  tmpPhone = tmpPhone.replace(/\D/g,"");
                  setCurrentUserState(prev => {
                    return{
                      ...prev,
                      secondAuthNumber : tmpPhone
                                        }
                  })
                }}
              />
              <MDBox mt={2} display="flex" width="100%" justifyContent="flex-end">
                {saveAuthNumber && <>
                    <Button onClick={() => {
                        setSaveAuthNumber(false);
                        setCurrentUserState(prev => {
                          return{
                            ...prev,
                            secondAuthNumber : currentUser?.secondAuthNumber ? currentUser?.secondAuthNumber : currentUser.contactNumber
                                            }
                        })
                      }}>{"Cancel"}</Button>
                    <Button onClick={saveAuthNumberFn} sx={{borderRadius: "0px",color: "white!important",border: "unset",}} variant="contained">save</Button>
                  </>}
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
      {editPhoto && <ImageUpload
          onClose={() => setEditPhoto(false)}
          onAttachmentAdded={selectProfilePic}
        />}
    </>
  )
}
export default ProfileSettings;