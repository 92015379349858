import { useEffect, useState, useRef, useCallback } from "react";
import AppModal from "ui/AppModal";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { useSelector } from "react-redux";
import { createFundRaiser } from "api/campaign";
import { toast } from "react-toastify";
import { CrowdfundingUtil } from "util/Crowdfunding/CrowdfundingUtil";

const AddIndividualFundRaiser = ({onChange, campaign, onFundraiserCreated}) => {
  const currUser = useSelector((state) => state.user);
  /// Email and phone is optional so we will not put any validation for that.
  const [fundRaiserFormValidation, setFundRaiserFormValidation] = useState({
    nameValidation : '',
    goalAmtValidation : '',
    numberValidation : ''
  })
  const [formData,setFormData] = useState({
    fundraiser_email : '',
    fundraiser_number : '',
    goal_amount : '',
    fundraiser_name : '',
  });

  const {
    sendRequest : createFundraiserFn,
    data : createdData,
    status : createdStatus,
    error : createdError,
  } = useHttp(createFundRaiser, false);

  const onFormSubmit = useCallback((event) => {
    event.preventDefault();
    // const password = passwordRef.current.value;
    if(formData.fundraiser_name?.trim() === "") {
      setFundRaiserFormValidation(prev => {
        return{
          ...prev,
          nameValidation : 'Title can not be empty.'
        }
      });
      return;
    }
    if (!formData.goal_amount || !(/^[0-9]*$/).test(formData.goal_amount.replace(/[^0-9]/g, '')) || formData.goal_amount.replace(/[^0-9]/g, '') > 10000000 || formData.goal_amount.replace(/[^0-9]/g, '') <= 0) {
      setFundRaiserFormValidation(prev => {
        return{
          ...prev,
          goalAmtValidation : 'Set a valid goal amount.'
        }
      });
      return;
    }    
    if(!formData.fundraiser_number || !(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/).test(formData.fundraiser_number)){
      setFundRaiserFormValidation(prev => {
        return{
          ...prev,
          numberValidation : 'Please enter a valid number.'
        }
      });
      return;
    }
    /// Seems fine call the API to save the Fundraiser....
    const fundRaiser = {
      payload : {
        ...formData,
        goal_amount : formData.goal_amount.replace(/[^0-9]/g, ''),
        company_id : currUser?.company.company_id,
        campaign : campaign,
      },
      token : currUser.token
    }
    createFundraiserFn(fundRaiser);
    
  },[currUser,formData]);

  useEffect(() => {
    if(createdStatus === 'completed'){
      if(createdData){
        onFundraiserCreated(createdData)
      }
      else{
        toast.error("Not able to create, Please try again..")
      }
    }
  },[createdData, createdStatus, createdError])
  return (
    <>
      <AppModal
        // customStyle={{width:'60%'}}
        onModalClose={onChange}
        rootStyle = {
          {
            "&.MuiModal-root":{
              width:'40%',
              left: '60%'
            }
          }
        }
        customStyle={{ 
            width: "100%",
            height: "100%",
        }}
        heading={"Create fundraiser"}
      >
        <>
          <MDBox width="100%" display="flex" flexDirection="column">
            <MDBox pt={4} pb={3} px={3} onSubmit={onFormSubmit}>
              <MDBox component="form" role="form">
                <MDBox mb={1}>
                  <MDInput
                    type="text"
                    label="Name"
                    variant="standard"
                    value={formData.fundraiser_name}
                    onChange={e => {
                      setFormData(prev => {
                        return{
                          ...prev,
                          fundraiser_name : e.target.value
                        }
                      })
                    }}
                    fullWidth
                  />
                </MDBox>
                <MDTypography
                  variant="overline"
                  fontWeight="medium"
                  color="error"
                >
                  {fundRaiserFormValidation.nameValidation}
                </MDTypography>
                <MDBox mt={2}>
                  <MDInput
                    type="email"
                    label="Email"
                    value={formData.fundraiser_email}
                    onChange={e => {
                      setFormData(prev => {
                        return{
                          ...prev,
                          fundraiser_email : e.target.value
                        }
                      })
                    }}
                    variant="standard"
                    fullWidth
                  />
                </MDBox>
                <MDBox mt={2}>
                  <MDInput
                    type="text"
                    label="Contact Number"
                    value={formData.fundraiser_number}
                    onChange={e => {
                      setFormData(prev => {
                        return{
                          ...prev,
                          fundraiser_number :  e.target.value
                        }
                      })
                    }}
                    variant="standard"
                    fullWidth
                  />
                </MDBox>
                <MDTypography
                  variant="overline"
                  fontWeight="medium"
                  color="error"
                >
                  {fundRaiserFormValidation.numberValidation}
                </MDTypography>                
                <MDBox mt={2}>
                  <MDInput
                    type="text"
                    value={formData.goal_amount}
                    onChange={e => {
                      const input = e.target.value;
                      // Remove all non-numeric characters except the dot for decimal points
                      const numericValue = input.replace(/[^0-9]/g, '');
                      // // Format the numeric value
                      const formattedValue = CrowdfundingUtil.formatCurrency(numericValue);
                      setFormData(prev => {
                        return{
                          ...prev,
                          goal_amount : formattedValue
                        }
                      })
                    }}
                    label="Goal amount"
                    variant="standard"
                    fullWidth
                  />
                </MDBox>
                <MDTypography
                  variant="overline"
                  fontWeight="medium"
                  color="error"
                >
                  {fundRaiserFormValidation.goalAmtValidation}
                </MDTypography>
                <MDBox mt={4} mb={1}>
                  <MDButton
                    type="submit"
                    variant="gradient"
                    color="info"
                    fullWidth
                  >
                    Create
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </>
      </AppModal>
    </>
  );
};
export default AddIndividualFundRaiser;
