import { Checkbox, FormControlLabel, FormGroup, InputLabel, MenuItem, Select } from "@mui/material";
import { timezones } from "constants/timezones";
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { userDetailAction } from "store/user-details-slice";

//
// PT	Pacific Time	UTC -8:00 / -7:00	Sat, 03:17:51
// MT	Mountain Time	UTC -7:00 / -6:00	Sat, 04:17:51
// CT	Central Time	UTC -6:00 / -5:00	Sat, 05:17:51
// ET	Eastern Time	UTC -5:00 / -4:00	Sat, 

const LocaleSettings = () => {

    const dispatch = useDispatch();

    const [local,setLocal] = useState({
        region : '',
        autoTimezone : true
    })

    useEffect(() => {
        setLocal(prev => {
            return{
                ...prev,
                region : Intl.DateTimeFormat().resolvedOptions().timeZone
            }
        })
    },[]);

    return(
        <MDBox display="flex" flexDirection="column" alignItems="center" width="100%">
            <MDBox display="flex" flexDirection="column" justifyContent="center" width="100%" alignItems="center">
                <MDBox m={2} display="flex" justifyContent="flex-start" flexDirection="column" width="100%">
                    <MDTypography variant="h6">Language</MDTypography>
                    <Select
                        sx={{
                            height:'38px',
                            '& .MuiSvgIcon-root': {
                                color: 'black!important',
                                display : 'inline-block',
                                fontSize:'22px!important',
                                right: '0'
                              },
                              '& .MuiSelect-iconOpen':{
                                  fontSize:'22px!important'
                              }
                        }}
                        value={"English(US)"}
                    >
                        <MenuItem value={"English(US)"}>English (US)</MenuItem>
                    </Select>
                </MDBox>

                <MDBox m={2} display="flex" justifyContent="flex-start" flexDirection="column" width="100%">
                    <MDTypography variant="h6">Time zone</MDTypography>
                    <MDBox>
                        <FormGroup>
                            <FormControlLabel onChange={e => {
                                setLocal(prev => {
                                    return{
                                        ...prev,
                                        autoTimezone : e.target.checked
                                    }
                                });
                            }} control={<Checkbox defaultChecked />} label="Set time zone automatically" />    
                        </FormGroup>                        
                    </MDBox>
                    <Select
                        disabled={local.autoTimezone}
                        onChange={e => {
                            setLocal(prev => {
                                return{
                                    ...prev,
                                    region : e.target.value
                                }
                            });
                            dispatch(userDetailAction.updateTimezone(e.target.value));
                        }}
                        sx={{
                            height:'38px',
                            '& .MuiSvgIcon-root': {
                                color: 'black!important',
                                display : 'inline-block',
                                fontSize:'22px!important',
                                right: '0'
                              },
                              '& .MuiSelect-iconOpen':{
                                  fontSize:'22px!important'
                              }
                        }}
                        value={local.region}
                    >
                        {timezones.map((item,index) => <MenuItem key={index} value={item.region}>{`${item.name} (${item.utc})`}</MenuItem>)}
                    </Select>
                </MDBox>

            </MDBox>
        </MDBox>
    )
}
export default LocaleSettings;