export const SEND = "/app";
export const TOPIC = "/topic";
export const QUEUE = "/queue";
export const COMPANY_ONLINE_STATUS = (companyId) => `${TOPIC}/company.${companyId}`;
export const USER_QUEUE = (userId) => `${QUEUE}/user.${userId}`;
export const USER_SESSION = (userId,sessionId) => `${QUEUE}/user.${userId}.session.${sessionId}`;
export const USER_PHONE_QUEUE = (userId,phone) => `${QUEUE}/user.${userId}.phone.${phone}`;
export const USER_ANNOUNCEMENT = (userId) => `${QUEUE}/user.${userId}.announcement`;
export const USER_TYPING_INDICATOR = (userId) => `${SEND}/user.${userId}`; 
export const CAMPAIGN_TOPIC = (campaign) => `${TOPIC}/donation.${campaign}`;
export const CAMPAIGN_FUNDRAISER_TOPIC = (campaign,fundraiser) => `${TOPIC}/donation.${campaign}.${fundraiser}`;