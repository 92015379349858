import { Icon, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import EditCredForm from "../EditCredForm";
import { useEffect, useState } from "react";
import InfoIcon from '@mui/icons-material/Info';
import { useSelector } from "react-redux";
import AppModal from "ui/AppModal";
import MDBox from "lib/components/MDBox";
import AppDrawer from "ui/AppDrawer";
import NumberPoolDetails from "./NumberPoolDetail";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Swal from "sweetalert2";
import useHttp from "hooks/use-http";
import { unassignPhone } from "api/fundraiser";
import { toast } from "react-toastify";
const ACTIONS = {
    DELETE : 'delete',
    EDIT : 'edit',
    NONE : 'none'
} 
const PoolActions = ({onPhoneEdited, phone, onIndUnassign}) => {

    const [action, setAction] = useState(ACTIONS.NONE);
    const role = useSelector(state => state.user.role);
    const token = useSelector(state => state.user.token);
    const [openDrawer, setOpenDrawer] = useState(false);
    const {sendRequest : unassignPhoneFn, status : unassignStatus, data : unassignData} = useHttp(unassignPhone,false);
    const onCredentialEdited = (res) => {
        if(res){
            setAction(ACTIONS.NONE);
            onPhoneEdited(res);
        }
    }
    const showNumberDetails = (e) => {
        e.stopPropagation();
        setOpenDrawer(true);
    }
    const unassignNumber = (phone) => {
        Swal.fire({
            title: 'Do you want to unassign this number?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Unassign it!'
          }).then((result) => {
            if (result.isConfirmed) {
                const p = {
                    token,
                    payload : {
                        number : phone?.phone,
                        companyId : phone?.company?.company_id
                    }
                }
                unassignPhoneFn(p);
            }
        })
    }
    
    useEffect(() => {
        if(unassignStatus === 'completed'){
            if(unassignData){
                toast.success(unassignData.message);
                onIndUnassign(unassignData.number)
            }
        }
    },[unassignStatus,unassignData]);

    return(
        <>
            <MDBox display="flex" flexDirection="row">
                {
                    phone?.status === 'INUSE' && <Tooltip title={'Unassign from company'}>
                        <RemoveCircleOutlineIcon onClick={unassignNumber.bind(null,phone)} style={{ cursor: "pointer", width:'1.2rem', height:'1.2rem' }}/>
                    </Tooltip>
                }
                <Tooltip title={'View details'}>
                    <MDBox onClick={showNumberDetails}>
                        <Icon
                        sx={{
                            height: "20px",
                            width: "40px",
                            fontSize: "20px!important",
                            cursor: "pointer",
                        }}
                        >
                        <InfoIcon />
                        </Icon>
                    </MDBox>
                </Tooltip>
                {role ==='SUPER_ADMIN' && 
                    <Tooltip title="Edit the number."> 
                        <EditIcon
                            onClick={() => {setAction(ACTIONS.EDIT)}}
                            style={{ marginRight: "8px", cursor: "pointer", width:'1.5rem', height:'1.5rem' }}
                        />
                    </Tooltip>
                }                
            </MDBox>
            {action === ACTIONS.EDIT && role === 'SUPER_ADMIN' && (
                <AppModal
                    rootStyle = {
                        {
                        "&.MuiModal-root":{
                            width:'35%',
                            left: '65%'
                        }
                        }
                    }
                    customStyle = {{ 
                    width: "100%",
                    height: "100%",
                    overflowY: "auto",
                    border: "unset",
                    }}  
                    heading={"Edit number"}
                    onModalClose={() => setAction(ACTIONS.NONE)}
                >
                    <EditCredForm phone={phone} onCredentialEdited={onCredentialEdited}/>
                </AppModal>
            )} 
        <AppDrawer openDrawer={openDrawer} style={{
                margin:'unset !important',
                height:'100% !important',
                borderRadius:'unset!important',
                width:'35%'
            }}
            onClose = {() => setOpenDrawer(null)}
        >
            <NumberPoolDetails phone={phone}/>
        </AppDrawer>              
        </>
    )
}
export default PoolActions;