
import { CAMPAIGN_DONATION } from "constants/Endpoints";
import fetch from "core/fetch";

export const getTransactionsByCampaign = async ({token,campaignId,filterPayload}) => {
    const response = await fetch(`${CAMPAIGN_DONATION}/transactions/${campaignId}`,{
        method : 'POST',
        body : JSON.stringify(filterPayload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
export const getTransactionsByCampaignAndFundraiser = async ({token,campaignId, fundraiserId,filterPayload}) => {
    const response = await fetch(`${CAMPAIGN_DONATION}/transactions/campaign/${campaignId}/fundraiser/${fundraiserId}`,{
        method : 'POST',
        body : JSON.stringify(filterPayload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const exportTransaction = async ({token,campaignId}) => {
    const response = await fetch(`${CAMPAIGN_DONATION}/transactions/campaign/${campaignId}/export`,{
        method : 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.blob();
    const link = document.createElement("a");
    link.download = "transactions.xlsx";
    link.href = URL.createObjectURL(
        new Blob([data], { type: "xlsx" })
      );
      link.click();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}