import {lockedColumns as columns} from "constants/Columns";
import Table from "ui/Table";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import useHttp from "hooks/use-http";
import { unLockedUser } from "api/user";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, ButtonGroup, Tooltip } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { unlockAllUsers } from "api/user";

const LockedAccountList = ({onAllAccountsUnlocked,userData,onUserUnLocked}) => {

    const token = useSelector(state => state.user.token);
    
    const {sendRequest : unlockUserFn, status, error, data} = useHttp(unLockedUser,false);
    const [userIds, setUserIds] = useState([]);
    const [rows,setRows] = useState([]);

    const {sendRequest : unlockAllAccounts, status:unlockAllStatus} = useHttp(unlockAllUsers,false);
    
    const unlockAccount = (id) => {
        unlockUserFn({token,userId : id});
    }

    const unlockAll = () => {
        if(userIds.length === 0) return;
        unlockAllAccounts({token,userIds});
    }
    
    useEffect(() => {
        if(unlockAllStatus === 'completed'){
            if(error){
                toast.error(error);
            }
            else{
                toast.success("Unlocked all accounts successfully.")
                onAllAccountsUnlocked();
            }
        }
    },[unlockAllStatus,error]);
    
    useEffect(() => {
        if(userData) {
            const ids = userData?.map(item => item.userId);
            setUserIds(ids);
            const tmRow = userData?.map((user,index) => {
                return {
                    "sno" : index + 1,
                    "email" : user.email,
                    "status" : "locked",
                    "action" : <Tooltip title="Click to unlock"><LockOpenIcon style={{cursor:'pointer'}} onClick={unlockAccount.bind(null,user.userId)}/></Tooltip>
                }
            });
            setRows(tmRow);
        }
    },[userData,setRows])

    useEffect(() => {
        if(status === 'completed'){
            if(error){
                toast.error(error);
            }
            else{
                toast.success("Account unlocked successfully.");
                onUserUnLocked(data);
            }
        }
    },[status,error,data,onUserUnLocked])
    
    return (
        <>
            <MDBox display="flex" justifyContent="end" onClick={unlockAll}>
                <ButtonGroup>
                    <Button variant="contained">
                        <span style={{color:'white',marginRight:'12px'}}>unlock all</span> <LockOpenIcon style={{
                            color:'white',
                            height:'18px',
                            width:'18px'
                        }}/>
                    </Button>
                </ButtonGroup>
            </MDBox>
            <Table 
                heading={"Locked Accounts"} 
                canSearch={true} 
                columns={columns ? columns : []} 
                rows={rows ? rows : []}
            />
        </>
    )
}
export default LockedAccountList;