import { BASE_URL } from "constants/Endpoints";
import fetch from "core/fetch";

export const AddRecipient = async(recipient) => {
    const payload = {
        ...recipient,
        token : undefined
    }
    const response = await fetch(`${BASE_URL}/phone/recipient`,{
        method : 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + recipient.token,
          },
        body : JSON.stringify(payload),
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}